import { Button, IconQuestionCircle, Modal, ModalProps } from "@mh/components";

interface ActionConfirmationModalProps extends Omit<ModalProps, "size"> {
  action: string;
  confirmActionLabel: string;
  cancelActionLabel: string;
  onConfirm: () => Promise<void>;
}

export const ActionConfirmationModal = ({
  action,
  confirmActionLabel,
  cancelActionLabel,
  onConfirm,
  ...modalProps
}: ActionConfirmationModalProps) => {
  return (
    <Modal size={"md"} {...modalProps}>
      <div css={{ display: "flex", gap: "16px" }}>
        <IconQuestionCircle
          css={(theme) => ({
            width: "32px",
            height: "32px",
            color: theme.color.primary
          })}
        />
        <span>{`Are you sure you want to ${action}?`}</span>
      </div>
      <Modal.Actions sticky>
        <Button onClick={modalProps.onHide} variant="primary-outline">
          {cancelActionLabel}
        </Button>
        <Button onClick={onConfirm} variant="primary">
          {confirmActionLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
