import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Order, Script, OscarAPI } from "@mh/api";
import { useBasket } from "@mh/basket";
import { Button } from "@mh/components";
import { ContactUsModal } from "@mh/messaging";

import { Status } from "./Status";
import { isColdChainProduct } from "../utils";

/**
 * Same as an `Order`, but with a limited set of `status` keys.
 * As the `OrderStatus` component is extended to support more statuses they should
 * be added to this type definition.
 * Eventually once all statuses are supported, this type is removed and `Order` can be used directly.
 */
interface SupportedOrder extends Order {
  status: Extract<
    NonNullable<Order["status"]>,
    | "Bad Address"
    | "Being Processed"
    | "Cold Chain - Being Processed"
    | "Completed"
    | "Compounding in Progress"
    | "Paid"
    | "Payment failed"
    | "Waiting"
  >;
}

interface OrderStatusProps {
  script: Script;
  order: Order;
  qId: number;
}

const OrderStatusInvalidAddress = () => (
  <Status
    actions={
      <NavLink to="/profile">
        <Button css={{ width: "100%" }}>Update address</Button>
      </NavLink>
    }
    title="INVALID ADDRESS"
    titleVariant="danger"
  >
    <p>
      Our delivery partner was unable to validate your address. Please go to
      your profile to review and update your shipping address.
    </p>
  </Status>
);

const OrderStatusPaymentFailed = ({ script, qId }: OrderStatusProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const basket = useBasket();
  const navigate = useNavigate();

  const handleCheckout = async () => {
    if (!script?.product) {
      return;
    }
    setIsLoading(true);
    const scriptId = script.id;
    const questionnaireId = qId;
    const options = await OscarAPI.getOptions();
    const scriptIdOption = options.results.find(
      (option) => option.code === "script_id"
    );
    const questionnaireIdOption = options.results.find(
      (option) => option.code === "questionnaire_id"
    );

    // script ID and questionnaire ID options are required for adding products to an Oscar basket
    if (
      scriptIdOption &&
      questionnaireIdOption &&
      scriptId &&
      questionnaireId
    ) {
      // Clear out the basket from any potential previous checkout
      await basket.reset();
      // Quantity is the initial order + repeat amount
      await basket.addItem(script.product, 1, [
        { option: scriptIdOption.url, value: scriptId },
        { option: questionnaireIdOption.url, value: questionnaireId }
      ]);
      basket.basket.refresh();
      navigate("/checkout");
    }
    setIsLoading(false);
  };

  return (
    <Status
      actions={
        <Button disabled={isLoading} onClick={handleCheckout} variant="primary">
          Retry payment
        </Button>
      }
      title="PAYMENT FAILED"
      titleVariant="danger"
    >
      <p>
        We encountered an issue while processing the payment for your recent
        order. This could be due to insufficient funds or a problem with your
        payment method. Use the button &apos;Retry payment&apos; to order your
        medication.
      </p>
    </Status>
  );
};

const OrderStatusBeingPrepared = ({ script }: OrderStatusProps) => (
  <Status
    title={
      isColdChainProduct(script.product.title)
        ? "AWAITING COURIER PICKUP"
        : "ORDER BEING PREPARED"
    }
  >
    <p>
      {isColdChainProduct(script.product.title)
        ? "The medication you ordered is best kept refrigerated. We dispense and dispatch these orders Monday-Thursday, to ensure that they remain temperature-regulated during transit."
        : "Your order is being prepared by the pharmacy."}
    </p>
  </Status>
);

const OrderStatusAwaitingCourierPickup = () => (
  <Status title="AWAITING COURIER PICKUP">
    <p>
      Your order needs to be shipped refrigerated and is waiting for our
      delivery partner to collect it (typically within 2 business days). Once
      your order is dispatched, you will receive an order update via SMS.
    </p>
  </Status>
);

const OrderStatusActive = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  return (
    <Status title="ACTIVE">
      <p>
        Your treatment plan has been issued. If you have any queries regarding
        your treatment or would like to request a change to your treatment plan,
        please{" "}
        <Button
          css={{ fontWeight: 600 }}
          onClick={() => setShowContactUsModal(true)}
          variant="primary-text"
        >
          contact our Patient Success team
        </Button>
        .
      </p>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </Status>
  );
};

const OrderStatusOnHold = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return (
    <Status title="ON HOLD" titleVariant="danger">
      <p>
        The pharmacy has placed your order on hold. A team member will be in
        touch with you to discuss the status of your order. If you require
        further assistance, please{" "}
        <Button
          css={{ fontWeight: 600 }}
          onClick={() => setShowContactUsModal(true)}
          variant="primary-text"
        >
          contact our Patient Success team
        </Button>
        .
      </p>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </Status>
  );
};

const ORDER_STATUS_MAP: Record<
  SupportedOrder["status"],
  (props: OrderStatusProps) => JSX.Element
> = {
  "Bad Address": OrderStatusInvalidAddress,
  "Being Processed": OrderStatusBeingPrepared,
  "Cold Chain - Being Processed": OrderStatusAwaitingCourierPickup,
  Completed: OrderStatusActive,
  "Compounding in Progress": OrderStatusBeingPrepared,
  Paid: OrderStatusBeingPrepared,
  "Payment failed": OrderStatusPaymentFailed,
  Waiting: OrderStatusOnHold
};

const isSupportedOrder = (order: Order): order is SupportedOrder =>
  !!order.status && Object.keys(ORDER_STATUS_MAP).includes(order.status);

export const OrderStatus = ({ script, order, qId }: OrderStatusProps) => {
  /** Return null for order statuses that are not yet supported. */
  if (!isSupportedOrder(order)) {
    return null;
  }

  return ORDER_STATUS_MAP[order.status]({ script, order, qId });
};

OrderStatus.isSupportedOrder = isSupportedOrder;
