import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { SizeProvider, Spinner } from "@mh/components";
import { ENV, Mode, SafeURLSearchParams } from "@mh/core";

import { QuestionnaireAPI } from "@mh/questionnaire/src/api";
import { QuestionnaireProvider } from "@mh/questionnaire/src/hooks";
import { QuestionPost } from "@mh/questionnaire/src/types";

import { QuestionnaireHeader } from "./QuestionnaireHeader";
import { QuestionComponent } from "./question/QuestionComponent";
import { QuestionnaireDebugPanel } from "./QuestionnaireDebugPanel";

import "./styles.scss";
import { ErrorDisplay } from "@mh/components/src/ErrorBoundary/ErrorDisplay";

const LoadingSpinner = () => (
  <div className="q-loading-spinner">
    <Spinner size="lg" />
    <br />
    Your questionnaire is loading, hold tight
  </div>
);

const getCookies = (): { [key: string]: string } => {
  // Modified from the get function in https://github.com/js-cookie/js-cookie/blob/main/src/api.mjs

  const response: { [key: string]: string } = {};

  const read = (value: string) => {
    let result = value;
    if (value[0] === '"') result = result.slice(1, -1);
    return result.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
  };

  const cookies = document.cookie ? document.cookie.split("; ") : [];
  cookies.forEach((cookie) => {
    const parts = cookie.split("=");
    const key = decodeURIComponent(parts[0]);
    const value = read(parts.slice(1).join("="));
    const sensitiveCookies = ["csrftoken"];
    if (!sensitiveCookies.includes(key)) response[key] = value;
  });

  return response;
};

export const QuestionnairePage = () => {
  const [loading, setLoading] = useState(true);
  const [loadResponse, setLoadResponse] = useState<QuestionPost | undefined>(
    undefined
  );

  const path = document.location.pathname;
  const searchParams = new SafeURLSearchParams(location.search);
  const categorySlug = path ? path.split("/").pop() : null;

  useEffect(() => {
    if (categorySlug === null || categorySlug === undefined) {
      setLoading(false);
    } else {
      QuestionnaireAPI.start(
        categorySlug,
        Object.fromEntries(searchParams),
        getCookies()
      )
        .then((response) =>
          response.status === 200 ? response.json() : undefined
        )
        .then((data) => setLoadResponse(data))
        .finally(() => setLoading(false));
    }
  }, []);

  if (loading) return <LoadingSpinner />;

  if (loadResponse === undefined) {
    return (
      <ErrorDisplay subtitle="There was an issue loading the questionnaire, please try again later" />
    );
  }

  return (
    <SizeProvider>
      <QuestionnaireProvider
        submission_uuid={loadResponse.submission_uuid}
        questionnaireUUID={categorySlug!}
        initialNodeUUID={loadResponse.node_key}
        initialPosition={loadResponse.position}
        initialQuestion={loadResponse.question}
        initialMaxPosition={loadResponse.max_position}
      >
        <div className="qv2 container">
          <hr
            css={(theme) => ({
              position: "absolute",
              height: "1px",
              top: "80px",
              left: "0",
              right: "0",
              background: "#F6F6F6",
              [theme.mq.md]: {
                display: "none"
              }
            })}
          />
          <Row className="top-row">
            <QuestionnaireHeader />
          </Row>
          <Row className="middle-row">
            <QuestionComponent />
          </Row>
          {ENV === Mode.DEV && <QuestionnaireDebugPanel />}
        </div>
      </QuestionnaireProvider>
    </SizeProvider>
  );
};
