import React, { CSSProperties } from "react";
import intlTelInput from "intl-tel-input";
// These imports handle making the number input work and display correctly
import "intl-tel-input/build/js/utils";
import "intl-tel-input/build/css/intlTelInput.css";

interface Props {
  value?: string;
  onChange: (ph: string | null) => void;
  style?: CSSProperties;
  className?: string;
  inputTestId?: string;
  autoFocus?: boolean;
}

interface State {
  plugin: intlTelInput.Plugin;
}

export class PhoneInput extends React.Component<Props, State> {
  mountElement(element: Element) {
    const intlInput = intlTelInput(element, {
      customContainer: " phone-outer w-100",
      initialCountry: "au",
      separateDialCode: true,
      formatOnDisplay: true,
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js"
    });

    // Set the initial state
    this.setState(() => ({
      plugin: intlInput
    }));
  }

  componentDidMount() {
    const element = document.querySelector(".phonenumber");
    element?.addEventListener("countrychange", () => {
      this.props.onChange(
        this.state.plugin.isValidNumber() ? this.state.plugin.getNumber() : null
      );
    });
    if (element) this.mountElement(element);
  }

  render() {
    return (
      <div style={this.props.style} className={this.props.className}>
        <input
          key="phone_input"
          id="phone_input"
          required
          className={"phonenumber form-control"}
          style={{ height: 38 }}
          type="text"
          defaultValue={this.props.value}
          onChange={(e) => {
            e.preventDefault();
            // Ensure the number does not start / end with anything unexpected
            if (e.target.value.length > 2) {
              const start = e.target.value[0]!;
              const end = e.target.value[e.target.value.length - 1];
              const numbers = "0123456789";
              if (!`+${numbers}`.includes(start) || !numbers.includes(end)) {
                this.props.onChange(null);
                return;
              }
            }

            const value = this.state.plugin.isValidNumber()
              ? this.state.plugin.getNumber()
              : null;
            this.props.onChange(value);
          }}
          // Tell password managers this isn't a password
          data-lpignore="true"
          // Attempt auto-fill
          autoComplete="tel"
          data-testid={this.props.inputTestId}
          ref={(input) => input && this.props.autoFocus && input.focus()}
        />
      </div>
    );
  }
}

export const PhoneField: React.FC<Props & { readOnly: boolean }> = ({
  readOnly,
  ...props
}) => {
  return readOnly ? <span>{props.value}</span> : <PhoneInput {...props} />;
};
