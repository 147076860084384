import { ComponentPropsWithoutRef } from "react";

export const IconCaretDown = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5 2.00024L7.53125 7.71899C7.34375 7.87524 7.15625 7.93774 7 7.93774C6.8125 7.93774 6.625 7.87524 6.46875 7.75024L0.46875 2.00024C0.15625 1.71899 0.15625 1.21899 0.4375 0.937744C0.71875 0.625244 1.21875 0.625244 1.5 0.906494L7 6.15649L12.4688 0.906494C12.75 0.625244 13.25 0.625244 13.5312 0.937744C13.8125 1.21899 13.8125 1.71899 13.5 2.00024Z"
      fill="currentColor"
    />
  </svg>
);
