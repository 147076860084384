import moment from "moment-timezone";
import type {
  BaseQuestionnaire,
  PatientQuestionnaire,
  PatientQuestionnaireRepeat,
  Script
} from "./interface";

type QuestionnaireProductLike = Pick<
  PatientQuestionnaire,
  "shop_product" | "repeat_shop_product"
>;

/**
 * Returns the most recent shop product associated with a questionnaire. The most recent shop product from a repeat
 * questionnaire, or the initially prescribed product if one exists.
 *
 * @param questionnaire The questionnaire whose product should be retrieved
 * @returns The most recently prescribed product
 */
export function getQuestionnaireProduct(
  questionnaire: QuestionnaireProductLike
) {
  return questionnaire.repeat_shop_product ?? questionnaire.shop_product;
}

/**
 * Extract a list of documents relevant to the patient.
 * Includes:
 *   - scripts with eRX links
 *   - patient documents
 *   - referrals
 *   - pathology requests
 *   - medical certificates
 *   - consultation order invoices
 * @param questionnaire The questionnaire to extract documents from.
 * @returns An array of document titles and URLs.
 */
export const getQuestionnaireDocuments = (questionnaire: BaseQuestionnaire) => {
  const scripts = questionnaire.script_set
    .filter((it) => it.erx_url)
    .map((it) => ({
      title: `eScript - ${it.product?.title}`,
      url: it.erx_url
    }));
  const patientDocuments =
    questionnaire.consultation?.patient_documents.map((it) => ({
      title: it.name,
      url: it.file
    })) ?? [];
  const referrals =
    questionnaire.consultation?.referrals.map((it) => ({
      title: "Referral",
      url: it.pdf
    })) ?? [];
  const pathologyRequests =
    questionnaire.consultation?.requests
      .filter((it) => it.file)
      .map((it) => ({ title: "Pathology Request", url: it.file })) ?? [];
  const medicalCert = questionnaire.medicalcert?.cert_file
    ? [
        {
          title: "Medical Certificate",
          url: questionnaire.medicalcert?.cert_file
        }
      ]
    : [];
  const consultSummary = questionnaire.consultation_order?.consult_summary
    ?.summary_file
    ? [
        {
          title: "Consultation Summary",
          url: questionnaire.consultation_order.consult_summary.summary_file
        }
      ]
    : [];
  const invoice = questionnaire.consultation_order?.invoice?.file
    ? [
        {
          title: "Consultation Invoice",
          url: questionnaire.consultation_order.invoice.file
        }
      ]
    : [];

  return [
    ...scripts,
    ...patientDocuments,
    ...referrals,
    ...pathologyRequests,
    ...medicalCert,
    ...consultSummary,
    ...invoice
  ];
};

export const checkEscriptRequired = (
  scriptSet: Script[] | undefined
): boolean => {
  const scriptWithoutEscript = scriptSet?.filter(
    (script) => script.id && !script.erx_url
  );
  return !!scriptWithoutEscript?.length;
};

/**
 * Compare the created_date's of two renewal questionnaires.
 * @param a The first renewal questionnaire to compare.
 * @param b The second renewal questionnaire to compare.
 * @returns -1 if the first created_date is before the second, 1 otherwise
 */
export const compareQuestionnairesByCreatedDate = (
  a: BaseQuestionnaire,
  b: BaseQuestionnaire
) => (a.created_date < b.created_date ? -1 : 1);

/**
 * Check if a renewal questionnaire is active or not.
 * @param q
 * @returns True is the renewal questionnaire is active, false otherwise.
 */
export const isActiveRenewalQuestionnaire = (
  q: PatientQuestionnaireRepeat
): boolean =>
  q.active &&
  (!q.activedate || moment(q.activedate).isSameOrBefore(moment(), "day"));

export const isPatientQuestionnaire = (
  questionnaire: BaseQuestionnaire
): questionnaire is PatientQuestionnaire =>
  "subscription_scripts" in questionnaire;

export const isPatientQuestionnaireRepeat = (
  questionnaire: BaseQuestionnaire
): questionnaire is PatientQuestionnaireRepeat => "activedate" in questionnaire;

export const isRenewalExcludedTreatments = (slug: string): boolean =>
  slug === "fill-a-script" || slug === "renew-a-script";
