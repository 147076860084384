import { FC } from "react";
import { useAsync, TinyProduct, OscarAPI, Price } from "@mh/api";
import { QuantitySelectorProps, Await, Flex } from "@mh/components";
import Form from "react-bootstrap/Form";

interface TreatmentSelectionAdditionalProps {
  product: TinyProduct;
  numSuppliesRemaining: number | null;
  orderOnDemand: boolean | undefined;
}

/**
 * Shows the dropdown options to order a treatment product, e.g. one which can have repeats
 * @param props Quantity value, callback to change it and the treatment product
 * @returns The dropdown options
 */
const TreatmentSelection: FC<
  Omit<QuantitySelectorProps, "range"> & TreatmentSelectionAdditionalProps
> = (props) => {
  const price = useAsync(
    OscarAPI.getProductPrice(props.product.id, { persistedPricing: true })
  );

  // product.max_repeats is the maximum number of a product a user is allowed to buy at one time.
  // e.g. high risk drugs are restricted to max_repeats=1.
  // maxNumAllowedToBuy is the minimum of product.max_repeats and the number of supplies remaining on the script
  let maxNumAllowedToOrder = props.product.max_repeats;
  if (
    props.numSuppliesRemaining !== null &&
    props.numSuppliesRemaining < maxNumAllowedToOrder
  ) {
    maxNumAllowedToOrder = props.numSuppliesRemaining;
  }

  return (
    <Await<[Price]> asyncs={[price.async]}>
      {([price]) =>
        [...Array(maxNumAllowedToOrder).keys()]
          .map((value) => value + 1)
          .map((value) => (
            <Flex
              key={value}
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              className="treatment-selection-item"
              onClick={() => props.onChange(value)}
            >
              <Flex alignItems="center" marginBetween="10px">
                <Form.Check
                  type="radio"
                  checked={value === props.value}
                  readOnly
                />
                <span css={{ fontSize: "14px", fontWeight: 500 }}>
                  {value} repeat{value !== 1 && "s"} (
                  {(props.product.pack_size * value).toFixed(0)} Tablets)
                  {/*
                    Only display this text if we are sure that orderOnDemand is false
                    e.g. do not display if orderOnDemand is undefined
                  */}
                  {props.orderOnDemand === false &&
                    ` billed every ${(props.product.duration! * value).toFixed(
                      0
                    )} days`}
                </span>
              </Flex>
              <span css={{ fontSize: "16px", fontWeight: 600 }}>
                ${(parseFloat(price.incl_tax) * value).toFixed(2)}
              </span>
            </Flex>
          ))
      }
    </Await>
  );
};

export default TreatmentSelection;
