import { API } from "@mh/api";

export const RemoteValidation = {
  /**
   * This is a remote check to ensure that the mobile provided verifies against the provided twilio credentials
   */
  mobile: async (number: string): Promise<boolean> =>
    API.v2()
      .unauthenticated()
      .url("/validate-phone-number/")
      .body({ phone_number: number })
      .post()
      .then((response) => (response.ok ? response.json() : undefined))
      .then((responseContent) => responseContent?.is_valid ?? false)
};
