import type { FC } from "react";
import { QuantitySelector, QuantitySelectorProps, Flex } from "@mh/components";

/**
 * Shows product ordering options and the total price
 * @param props Quantity value, callback to change it and the total price to display
 * @returns
 */
const Quantity: FC<QuantitySelectorProps & { totalPrice: string }> = (
  props
) => (
  <Flex alignItems="center" justifyContent="space-between" width="100%">
    <Flex alignItems="center" marginBetween="16px">
      <span css={{ fontWeight: 500 }}>Qty</span>
      <QuantitySelector
        value={props.value}
        range={props.range} // Placeholder limit
        onChange={props.onChange}
      />
    </Flex>
    <span css={{ fontWeight: 600 }}>${props.totalPrice}</span>
  </Flex>
);

export default Quantity;
