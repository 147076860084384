import { PropsWithChildren } from "react";
import { Button, IconCaretDown, IconSuccess } from "@mh/components";

interface MemberBenefitsProps {
  expanded: boolean;
  onExpandClick: () => void;
  membershipFee: string;
}

const benefits = [
  "<strong>FREE</strong> medical consultations",
  "<strong>FREE</strong> shipping on orders",
  "Discounts on your medication",
  "Priority access to clinicians",
  "Cancel anytime"
];

const BenefitSection = ({ children }: PropsWithChildren) => (
  <div
    css={{
      borderTop: "1px solid #CCC",
      fontSize: "14px",
      marginTop: "8px",
      paddingTop: "8px"
    }}
  >
    {children}
  </div>
);

const Row = ({ children }: PropsWithChildren) => (
  <div
    css={{
      display: "flex",
      justifyContent: "space-between"
    }}
  >
    {children}
  </div>
);

const benefitList = (
  <ul css={{ listStyleType: "none", padding: "0", margin: "0" }}>
    {benefits.map((item, index) => (
      <li
        key={index}
        css={{ display: "flex", alignItems: "center", gap: "3px" }}
      >
        <IconSuccess width={16} height={16} css={{ fill: "#000000" }} />
        <span dangerouslySetInnerHTML={{ __html: item }}></span>
      </li>
    ))}
  </ul>
);

export const MemberBenefits = ({
  expanded,
  onExpandClick,
  membershipFee
}: MemberBenefitsProps) => {
  const handleExpandClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onExpandClick();
  };

  return (
    <BenefitSection>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <span css={{ fontWeight: "700" }}>hubPass benefits</span>
        <Button
          css={{
            backgroundColor: "white",
            borderRadius: "50%",
            border: "none",
            width: "27px",
            height: "27px",
            padding: "0",
            marginBottom: "0 !important"
          }}
          onClick={handleExpandClick}
        >
          <IconCaretDown
            width={13}
            height={13}
            css={(theme) => ({
              color: theme.color.primary,
              ...(expanded && { transform: "rotateX(180deg)" })
            })}
          />
        </Button>
      </div>
      {expanded && (
        <>
          {benefitList}
          <BenefitSection>
            <Row>
              <span>Medical Consultation</span>
              <span>
                <strong>FREE</strong>
              </span>
            </Row>
            <Row>
              <span>
                Monthly fee<small>(Cancel anytime)</small>
              </span>
              <span>
                <strong>${membershipFee}</strong>
              </span>
            </Row>
          </BenefitSection>
          <BenefitSection>
            <Row>
              <span css={{ font: "600 16px Inter" }}>Total due today</span>
              <span css={{ font: "600 16px Inter" }}>${membershipFee}</span>
            </Row>
          </BenefitSection>
        </>
      )}
    </BenefitSection>
  );
};
