import { ComponentPropsWithoutRef } from "react";

export const IconGrid = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.25 2.03088C0.25 1.17151 0.914062 0.468384 1.8125 0.468384H3.6875C4.54688 0.468384 5.25 1.17151 5.25 2.03088V3.90588C5.25 4.80432 4.54688 5.46838 3.6875 5.46838H1.8125C0.914062 5.46838 0.25 4.80432 0.25 3.90588V2.03088ZM0.25 8.28088C0.25 7.42151 0.914062 6.71838 1.8125 6.71838H3.6875C4.54688 6.71838 5.25 7.42151 5.25 8.28088V10.1559C5.25 11.0543 4.54688 11.7184 3.6875 11.7184H1.8125C0.914062 11.7184 0.25 11.0543 0.25 10.1559V8.28088ZM5.25 14.5309V16.4059C5.25 17.3043 4.54688 17.9684 3.6875 17.9684H1.8125C0.914062 17.9684 0.25 17.3043 0.25 16.4059V14.5309C0.25 13.6715 0.914062 12.9684 1.8125 12.9684H3.6875C4.54688 12.9684 5.25 13.6715 5.25 14.5309ZM6.5 2.03088C6.5 1.17151 7.16406 0.468384 8.0625 0.468384H9.9375C10.7969 0.468384 11.5 1.17151 11.5 2.03088V3.90588C11.5 4.80432 10.7969 5.46838 9.9375 5.46838H8.0625C7.16406 5.46838 6.5 4.80432 6.5 3.90588V2.03088ZM11.5 8.28088V10.1559C11.5 11.0543 10.7969 11.7184 9.9375 11.7184H8.0625C7.16406 11.7184 6.5 11.0543 6.5 10.1559V8.28088C6.5 7.42151 7.16406 6.71838 8.0625 6.71838H9.9375C10.7969 6.71838 11.5 7.42151 11.5 8.28088ZM6.5 14.5309C6.5 13.6715 7.16406 12.9684 8.0625 12.9684H9.9375C10.7969 12.9684 11.5 13.6715 11.5 14.5309V16.4059C11.5 17.3043 10.7969 17.9684 9.9375 17.9684H8.0625C7.16406 17.9684 6.5 17.3043 6.5 16.4059V14.5309ZM17.75 2.03088V3.90588C17.75 4.80432 17.0469 5.46838 16.1875 5.46838H14.3125C13.4141 5.46838 12.75 4.80432 12.75 3.90588V2.03088C12.75 1.17151 13.4141 0.468384 14.3125 0.468384H16.1875C17.0469 0.468384 17.75 1.17151 17.75 2.03088ZM12.75 8.28088C12.75 7.42151 13.4141 6.71838 14.3125 6.71838H16.1875C17.0469 6.71838 17.75 7.42151 17.75 8.28088V10.1559C17.75 11.0543 17.0469 11.7184 16.1875 11.7184H14.3125C13.4141 11.7184 12.75 11.0543 12.75 10.1559V8.28088ZM17.75 14.5309V16.4059C17.75 17.3043 17.0469 17.9684 16.1875 17.9684H14.3125C13.4141 17.9684 12.75 17.3043 12.75 16.4059V14.5309C12.75 13.6715 13.4141 12.9684 14.3125 12.9684H16.1875C17.0469 12.9684 17.75 13.6715 17.75 14.5309Z"
      fill="currentColor"
    />
  </svg>
);
