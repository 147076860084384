import { useState, type FC } from "react";
import { DiscountCode, type DiscountCodeProps, Flex } from "@mh/components";

export const ExpandableDiscountCode: FC<DiscountCodeProps> = ({
  ...discountCodeProps
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Flex
      flexDirection="column"
      className="ExpandableDiscountCode"
      style={{ marginTop: 24 }}
    >
      <Flex alignItems="center" className="newpromo tab-label">
        <span>Promo Code</span>
        <span
          data-testid="expandable-discount-code-arrow"
          className="promoExpandIcon"
          css={{
            cursor: "pointer",
            rotate: isOpen ? "-90deg" : "90deg",
            transition: "rotate 350ms",
            marginLeft: 16
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {"❯"}
        </span>
      </Flex>
      {isOpen && (
        <div className="innerPromo">
          <DiscountCode {...discountCodeProps} />
        </div>
      )}
    </Flex>
  );
};
