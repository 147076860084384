import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import {
  OscarAPI,
  PatientAPI,
  PatientQuestionnaire,
  UserContext,
  type ProductRecommendation,
  type Async,
  createAsync,
  useFeatures
} from "@mh/api";
import { getFixedDiscount, useBasket } from "@mh/basket";
import {
  FreeDeliveryBanner,
  Spinner,
  Await,
  OrderDiscountBanner
} from "@mh/components";
import {
  getRecommendedProductsWithPrices,
  OTCProducts
} from "@mh/otc-products";
import { BrowsableProductsContainer } from "./components/BrowsableProductsContainer";
import { ClinicianLetter } from "./components/ClinicianLetter";
import { PharmacistNote } from "./components/PharmacistNote";
import { isInitialQuestionnaire, isPharmacyApproved } from "./utils";

export const TreatmentCompletePurchase = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questionnaire, setQuestionnaire] = useState<PatientQuestionnaire>();
  const [readChecked, setReadChecked] = useState<boolean>(false);
  const [questionsChecked, setQuestionsChecked] = useState<boolean>(false);
  /**
   * Upon acceptance, should the patient be taken through the wizard flow rather than straight to the regular checkout
   * page?
   */
  const [shouldShowCheckoutWizard, setShouldShowCheckoutWizard] =
    useState<boolean>(false);
  const [productRecommendations, setProductRecommendations] = useState<
    Async<ProductRecommendation[]>
  >(createAsync());
  const basket = useBasket();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { questionnaireId, step } = useParams();
  const { isFeatureActive } = useFeatures();

  const [subscriptionScript, approvedQuestionnaire] = useMemo(() => {
    if (!questionnaire) {
      return [null, null];
    }

    const subscriptionScript = questionnaire.subscription_scripts
      .sort((a, b) => (a.id < b.id ? -1 : 1))
      .filter((a) =>
        a.repeat_questionnaires.some(
          (repeat) => repeat.consultation?.status === "approve"
        )
      )
      .at(-1);
    const repeatQuestionnaire = subscriptionScript?.repeat_questionnaires
      .filter((a) => a.consultation?.status === "approve")
      .sort((a, b) => (a.id < b.id ? -1 : 1))
      .at(-1);
    if (repeatQuestionnaire) {
      return [subscriptionScript, repeatQuestionnaire];
    }

    // Check if a MH clinician has approved the questionnaire
    const isApprovedByClinician =
      questionnaire.consultation?.status === "approve";

    // Check if the pharmacy has approved the questionnaire, for Fill-a-script
    const isApprovedByPharmacy =
      isInitialQuestionnaire(questionnaire) &&
      isPharmacyApproved(questionnaire);

    if (isApprovedByClinician || isApprovedByPharmacy) {
      return [questionnaire.subscription_scripts.at(-1), questionnaire];
    }

    return [null, null];
  }, [questionnaire]);

  /** Fetch questionnaire and plan data. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [q] = await Promise.all([
          PatientAPI.getPatientQuestionnaire(questionnaireId!),
          user.data ? null : user.get()
        ]);
        setQuestionnaire(q);
      } catch (e) {
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };

    if (step) {
      /** Ensure the user is always sent to the first page of the flow, on first load. */
      navigate(`/treatments/${questionnaireId}/complete-purchase`);
    }

    fetchData();
  }, []);

  /** Populate clinician-recommended products */
  useEffect(() => {
    /** Loads clinician-recommended products for the treatment. If this fails, the products will not be displayed
     *
     * @param productId The treatment product ID
     * */
    const fetchClinicianRecommendedProducts = async (productId: number) => {
      try {
        const products = await OscarAPI.getProductRecommendations(
          productId,
          true
        );

        setProductRecommendations(createAsync(products));
      } catch (e) {
        // If this fails, it's not the end of the world. The generic OTC products will display instead.
        setProductRecommendations({ state: "failed" });
      }
    };

    if (approvedQuestionnaire?.script) {
      fetchClinicianRecommendedProducts(
        approvedQuestionnaire.script.product.id
      );
    }
  }, [approvedQuestionnaire]);

  /** Validate questionnaire for complete purchase. */
  useEffect(() => {
    if (!questionnaire) {
      return;
    }

    /** There must be a questionnaire that has been approved in some form */
    if (!approvedQuestionnaire) {
      navigate("/");
      return;
    }

    // Check if a MH clinician has approved the questionnaire
    const isApprovedByClinician =
      approvedQuestionnaire.consultation?.status === "approve";

    // Check if the pharmacy has approved the questionnaire, for Fill-a-script
    const isApprovedByPharmacy =
      isInitialQuestionnaire(approvedQuestionnaire) &&
      isPharmacyApproved(approvedQuestionnaire);

    /** Questionnaire must be approved by either a clinician, or a pharmacist in the case it's an Fill-a-script
     * questionnaire. */
    if (!isApprovedByClinician && !isApprovedByPharmacy) {
      navigate("/");
      return;
    }

    /** Script valid date must not be set, or in the future. */
    if (
      subscriptionScript?.script_expire &&
      moment.utc(subscriptionScript.script_expire) <= moment.utc()
    ) {
      navigate("/");
      return;
    }

    /** There must be a treatment/product to buy. */
    if (!approvedQuestionnaire.script?.product) {
      navigate("/");
      return;
    }

    /** If there is no doctor, navigate straight to the pharmacist note page. */
    if (!approvedQuestionnaire.consultation?.clinician) {
      navigate(`/treatments/${questionnaire.id}/complete-purchase/2`, {
        replace: true
      });
    }
  }, [questionnaire, subscriptionScript, approvedQuestionnaire]);

  useEffect(() => {
    // Load whether to show the treatment acceptance checkout wizard
    isFeatureActive("treatment_acceptance_checkout", false).then(
      setShouldShowCheckoutWizard
    );
  }, [isFeatureActive]);

  /**
   * Navigates the patient to the treatment acceptance checkout wizard, if the subscription exists.
   */
  const handleShowWizard = () => {
    if (subscriptionScript) {
      navigate(`/checkout/subscription/${subscriptionScript.id}/`);
    }
  };

  const handleCheckout = async () => {
    if (!questionnaire || !approvedQuestionnaire?.script?.product) {
      return;
    }

    setIsLoading(true);
    const scriptId = approvedQuestionnaire.script.id;
    const questionnaireId = questionnaire.id;
    const options = await OscarAPI.getOptions();
    const scriptIdOption = options.results.find(
      (option) => option.code === "script_id"
    );
    const questionnaireIdOption = options.results.find(
      (option) => option.code === "questionnaire_id"
    );

    // script ID and questionnaire ID options are required for adding products to an Oscar basket
    if (
      scriptIdOption &&
      questionnaireIdOption &&
      scriptId &&
      questionnaireId
    ) {
      // Clear out the basket from any potential previous checkout
      await basket.reset();
      // Quantity is the initial order + repeat amount
      await basket.addItem(approvedQuestionnaire.script.product, 1, [
        { option: scriptIdOption.url, value: scriptId },
        { option: questionnaireIdOption.url, value: questionnaireId }
      ]);
      basket.basket.refresh();
      navigate("/checkout");
    }
    setIsLoading(false);
  };

  const handlePharmacistNoteContinue = async () => {
    if (readChecked && questionsChecked) {
      if (shouldShowCheckoutWizard) {
        handleShowWizard();
      } else {
        await handleCheckout();
      }
    }
  };

  /**
   * Renders the product upselling below either the clinician or pharmacist note
   */
  const renderUpselling = (): JSX.Element | null => {
    if (!questionnaire || !approvedQuestionnaire) {
      return null;
    }

    /** Is this treatment given by a clinician, instead of a pharmacist? */
    const isClinician =
      (!step || step === "1") && approvedQuestionnaire.consultation?.clinician;

    /** Clinician-recommended products failed to load, or there are none */
    const noRecommendedProducts =
      productRecommendations.state === "failed" ||
      (productRecommendations.state === "success" &&
        productRecommendations.data.length === 0);

    const orderDiscount = getFixedDiscount(questionnaire.category);

    let banner: JSX.Element | null = null;

    if (orderDiscount) {
      // Show the specific discount banner if there is a discount
      banner = <OrderDiscountBanner discount={`${orderDiscount}% `} />;
    }

    if (!orderDiscount) {
      // If the category has loaded but doesn't have a discount, show the free delivery banner
      banner = <FreeDeliveryBanner />;
    }

    if (!isClinician || noRecommendedProducts) {
      // For a pharmacist-driven treatment or when there are no clinician-recommended products, just show regular OTC
      // upsells
      return (
        <>
          {banner}
          <OTCProducts
            loadProducts={() =>
              // @ts-ignore
              getRecommendedProductsWithPrices([questionnaire.category.id])
            }
            scrollable
          />
        </>
      );
    }

    return (
      <Await asyncs={[productRecommendations]} renderPending={<Spinner />}>
        {([recommendations]) => (
          <>
            {banner}
            <BrowsableProductsContainer recommendations={recommendations} />
          </>
        )}
      </Await>
    );
  };

  if (!approvedQuestionnaire || !approvedQuestionnaire.script) {
    return null;
  }

  if (
    isLoading ||
    !user.data ||
    !questionnaire ||
    !approvedQuestionnaire.script
  ) {
    return (
      <div
        css={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        flexFlow: "column nowrap",
        gap: "16px"
      }}
    >
      {(!step || step === "1") &&
        approvedQuestionnaire.consultation?.clinician && (
          <>
            <ClinicianLetter
              directions={
                approvedQuestionnaire.script.directions || "No directions given"
              }
              doctor={approvedQuestionnaire.consultation?.clinician}
              onClickContinue={() =>
                navigate(`/treatments/${questionnaire.id}/complete-purchase/2`)
              }
              treatmentTitle={approvedQuestionnaire.script.product.title}
              clinicianNote={
                approvedQuestionnaire?.doctor_feedback ||
                "No clinician note given"
              }
            />
          </>
        )}
      {step === "2" && (
        <PharmacistNote
          questionsChecked={questionsChecked}
          readChecked={readChecked}
          onChangeQuestions={setQuestionsChecked}
          onChangeRead={setReadChecked}
          script={approvedQuestionnaire.script}
          doctor={approvedQuestionnaire.consultation?.clinician ?? null}
          onClickContinue={handlePharmacistNoteContinue}
        />
      )}
      {renderUpselling()}
    </div>
  );
};
