import { ComponentPropsWithoutRef } from "react";

export const IconExit = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.5 13C5.75 13 6 13.25 6 13.5C6 13.7812 5.75 14 5.5 14H2.5C1.09375 14 0 12.9062 0 11.5V2.5C0 1.125 1.09375 0 2.5 0H5.5C5.75 0 6 0.25 6 0.5C6 0.78125 5.75 1 5.5 1H2.5C1.65625 1 1 1.6875 1 2.5V11.5C1 12.3438 1.65625 13 2.5 13H5.5ZM15.6875 6.3125C15.875 6.5 16 6.75 16 7C16 7.28125 15.875 7.53125 15.6875 7.71875L11.6875 11.7188C11.4062 12 10.9688 12.0938 10.5938 11.9375C10.2188 11.7812 10 11.4062 10 11V9H5.5C4.65625 9 4 8.34375 4 7.5V6.5C4 5.6875 4.65625 5 5.5 5H10V3C10 2.625 10.2188 2.25 10.5938 2.09375C10.9688 1.9375 11.4062 2.03125 11.6875 2.3125L15.6875 6.3125ZM11 11L14.9688 7L10.9688 3L11 6H5.5C5.21875 6 5 6.25 5 6.5V7.5C5 7.78125 5.21875 8 5.5 8H11V11Z"
      fill="currentColor"
    />
  </svg>
);
