export const IconMedication = () => (
  <svg
    width="71"
    height="71"
    viewBox="0 0 71 71"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="35.8652"
      cy="35.9429"
      r="35"
      css={(theme) => ({
        fill: `${theme.color.primary}`
      })}
    />
    <path
      d="M48.3652 15.9429H23.3652C21.959 15.9429 20.8652 17.1147 20.8652 18.4429V23.4429C20.8652 24.8491 21.959 25.9429 23.3652 25.9429V51.5679C23.3652 53.9897 25.3184 55.9429 27.7402 55.9429H43.9902C46.334 55.9429 48.3652 53.9897 48.3652 51.5679V25.9429C49.6934 25.9429 50.8652 24.8491 50.8652 23.4429V18.4429C50.8652 17.1147 49.6934 15.9429 48.3652 15.9429ZM44.6152 51.5679C44.6152 51.9585 44.3027 52.1929 43.9902 52.1929H27.7402C27.3496 52.1929 27.1152 51.9585 27.1152 51.5679V25.9429H44.6152V51.5679ZM47.1152 22.1929H24.6152V19.6929H47.1152V22.1929ZM29.6152 40.9429H33.3652V44.6929C33.3652 45.396 33.9121 45.9429 34.6152 45.9429H37.1152C37.7402 45.9429 38.3652 45.396 38.3652 44.6929V40.9429H42.1152C42.7402 40.9429 43.3652 40.396 43.3652 39.6929V37.1929C43.3652 36.5679 42.7402 35.9429 42.1152 35.9429H38.3652V32.1929C38.3652 31.5679 37.7402 30.9429 37.1152 30.9429H34.6152C33.9121 30.9429 33.3652 31.5679 33.3652 32.1929V35.9429H29.6152C28.9121 35.9429 28.3652 36.5679 28.3652 37.1929V39.6929C28.3652 40.396 28.9121 40.9429 29.6152 40.9429Z"
      fill="white"
    />
  </svg>
);
