import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Validation } from "@mh/core";

import { useQContext } from "@mh/questionnaire/src/hooks";

import { QuestionnaireContentComponentProps } from ".";

import {
  MedicareExpMonth,
  MedicareExpYear,
  MedicareIRN,
  MedicareNumber
} from "../../../../../../src/components/forms";

export const QCMedicare = (props: QuestionnaireContentComponentProps) => {
  const { setLocalResponseValidationError } = useQContext();
  const { initialNumber, initialExpiryYear, initialExpiryMonth, initialIrn } =
    props.initialValue ?? {};

  const [number, setNumber] = useState<string>(initialNumber ?? "");
  const [irn, setIRN] = useState<string>(initialIrn ?? "");

  const [expiryYear, setExpiryYear] = useState<string>(initialExpiryYear ?? "");
  const [expiryMonth, setExpiryMonth] = useState<string>(
    initialExpiryMonth ?? ""
  );

  useEffect(() => {
    setLocalResponseValidationError(null);

    const numberValidation = Validation.medicare().validate(number);
    if (number !== "" && numberValidation !== null) {
      setLocalResponseValidationError(numberValidation);
      props.onChange(null);
      return;
    }

    const IRNValidation = Validation.int({
      message: "Please enter a valid IRN"
    }).validate(irn);
    if (irn !== "" && IRNValidation !== null) {
      setLocalResponseValidationError(IRNValidation);
      props.onChange(undefined);
      return;
    }

    const expiryMonthValidation = Validation.required({
      name: "Expiry month"
    }).validate(expiryMonth);
    if (expiryMonth !== "" && expiryMonthValidation !== null) {
      setLocalResponseValidationError(expiryMonthValidation);
      props.onChange(null);
      return;
    }

    const expiryYearValidation = Validation.required({
      name: "Expiry Year"
    }).validate(expiryYear);
    if (expiryYear !== "" && expiryYearValidation !== null) {
      setLocalResponseValidationError(expiryYearValidation);
      props.onChange(null);
      return;
    }

    if (
      number !== "" &&
      irn !== "" &&
      expiryMonth !== "" &&
      expiryYear !== ""
    ) {
      props.onChange({ number, expiryYear, expiryMonth, irn });
    }
  }, [number, irn, expiryYear, expiryMonth]);

  return (
    <Row>
      <MedicareNumber
        id="id-verify"
        isEditing={true}
        isFetching={false}
        value={number}
        onChange={(e) => setNumber(e.currentTarget.value)}
        error={undefined}
        required={true}
      />
      <MedicareIRN
        id="id-verify"
        isEditing={true}
        isFetching={false}
        value={irn}
        onChange={(e) => setIRN(e.currentTarget.value)}
        error={undefined}
        required={true}
      />
      <div css={{ display: "flex", gap: "20px" }}>
        <MedicareExpMonth
          id="id-verify"
          isEditing={true}
          isFetching={false}
          value={expiryMonth}
          onChange={(e) => setExpiryMonth(e.currentTarget.value)}
          error={undefined}
          required={true}
        />
        <MedicareExpYear
          id="id-verify"
          isEditing={true}
          isFetching={false}
          value={expiryYear}
          onChange={(e) => setExpiryYear(e.currentTarget.value)}
          error={undefined}
          required={true}
        />
      </div>
    </Row>
  );
};
