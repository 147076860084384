import type { FC, ReactNode } from "react";
import { classNames } from "../utils";

export interface IndexPoint {
  active: boolean;
}

interface Props {
  points: IndexPoint[];
  onPointClick: (index: number) => void;
}

const IndexPoints: FC<Props> = (props) => {
  const renderPoint = (point: IndexPoint, index: number): ReactNode => {
    return (
      <div
        key={index}
        className={classNames(
          "index-point",
          point.active && "index-point-active"
        )}
        onClick={() => props.onPointClick(index)}
      />
    );
  };

  return <>{props.points.map(renderPoint)}</>;
};

export default IndexPoints;
