export function NoMessages() {
  return (
    <div className="w-100 text-center bg-white py-20">
      <span
        css={(theme) => ({
          fontFamily: theme.font.heading,
          fontSize: "16px",
          [theme.mq.md]: {
            fontSize: "22px"
          }
        })}
      >
        Messages will appear here when you have any
      </span>
    </div>
  );
}
