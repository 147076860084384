import React, {
  createContext,
  useContext,
  ReactNode,
  Context,
  useState,
  useEffect
} from "react";

import { ResponseActions } from "@mh/questionnaire/src/components";
import { Question, QuestionPost } from "@mh/questionnaire/src/types";
import { QuestionnaireAPI } from "@mh/questionnaire/src/api";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { User } from "@mh/api";
import { Toast } from "@mh/components";
import { Sentry } from "@mh/core";

export type QuestionResponseData = Record<number, any>;
export type QuestionResponseCheck = (
  question: Question,
  responseData: QuestionResponseData
) => boolean;
export interface QuestionnaireContextType {
  uuid: string;
  questionnaireUUID: string;
  nodeUUID: string[];
  position: number;
  maxPosition: number;
  question: Question;
  nextEnabled: boolean;
  responseData: QuestionResponseData;
  updateResponseData: (id: number, response: any, update: boolean) => void;
  isSubmittingQuestion: boolean;
  setIsSubmittingQuestion: (boolean: boolean) => void;
  localResponseValidationError: string | null;
  setLocalResponseValidationError: (error: string | null) => void;
  loadNextQuestion: (response: QuestionPost) => void;
  submitQuestion: () => void;
  goBack: () => void;
  questionTitleOverride: string | null;
  setQuestionTitleOverride: (v: string | null) => void;
  setBackButtonOverride: (v: (() => void) | null) => void;
}

// Creating a context for our Questionnaire state
const QuestionnaireContext: Context<QuestionnaireContextType | undefined> =
  createContext<QuestionnaireContextType | undefined>(undefined);

// Custom hook to access the Questionnaire state and its actions
export const useQContext = () => {
  const context = useContext(QuestionnaireContext);
  if (!context) {
    Sentry.captureMessage(
      "qContext must be used within a QuestionnaireProvider"
    );
    throw new Error("qContext must be used within a QuestionnaireProvider");
  }
  return context;
};

const loginUser = (navigate: NavigateFunction, authData: any) => {
  User.login(authData);
  navigate("/");
};

// Provider component to manage the Questionnaire state
export const QuestionnaireProvider: React.FC<{
  submission_uuid: string;
  questionnaireUUID: string;
  initialNodeUUID: string[];
  children: ReactNode;
  initialQuestion: Question;
  initialPosition: number;
  initialMaxPosition: number;
}> = ({
  submission_uuid: uuid,
  questionnaireUUID,
  initialNodeUUID,
  children,
  initialQuestion,
  initialPosition,
  initialMaxPosition
}) => {
  const [nodeUUID, setNodeUUID] = useState<string[]>(initialNodeUUID);
  const [position, setPosition] = useState<number>(initialPosition);
  const [maxPosition, setMaxPosition] = useState<number>(initialMaxPosition);
  const [question, setQuestion] = useState<Question>(initialQuestion);

  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const [responseValidChecks, _] = useState<QuestionResponseCheck[]>([
    ResponseActions.required
  ]);
  const [responseData, setResponseData] = useState<QuestionResponseData>({});
  const [isSubmittingQuestion, setIsSubmittingQuestion] =
    useState<boolean>(false);
  const [localResponseValidationError, setLocalResponseValidationError] =
    useState<string | null>(null);
  const [backButtonOverride, setBackButtonOverride] = useState<
    (() => void) | null
  >(null);

  const [questionTitleOverride, setQuestionTitleOverride] = useState<
    string | null
  >(null);

  // Helper functions
  const checksPass = (responseDataOverride?: any) =>
    responseValidChecks.reduce<boolean>(
      (isValid, check) =>
        isValid && check(question, responseDataOverride ?? responseData),
      true
    );

  const navigate = useNavigate();

  const updateQuestionAsync = async (
    apiCall: (uuid: string, response: any) => Promise<Response>,
    responseValueOverride?: any
  ) => {
    setIsSubmittingQuestion(true);
    Toast.dismiss();

    try {
      const response = await apiCall(questionnaireUUID, {
        node_key: nodeUUID,
        submission_uuid: uuid,
        response:
          (responseValueOverride ? responseValueOverride : responseData)[
            question.id
          ] ?? null
      });
      const responseJson = await response.json();

      if (!response.ok || responseJson === null) {
        throw responseJson[nodeUUID[nodeUUID.length - 1]];
      }

      if (response.status === 200) {
        // eslint-disable-next-line no-use-before-define
        loadNextQuestion(responseJson);
      } else {
        loginUser(navigate, responseJson);
      }
    } catch (error) {
      Toast.dismiss();
      Toast.error(`${error}`);
    } finally {
      setIsSubmittingQuestion(false);
    }
  };

  const submitQuestion = async (forceValue?: any) => {
    // Just in case we accidentally get to a submission attempt when we shouldn't
    if (!checksPass(forceValue)) return;
    await updateQuestionAsync(
      async (uuid, currentState) => QuestionnaireAPI.submit(uuid, currentState),
      forceValue
    );
  };

  const updateResponseData = (id: number, response: any, update: boolean) => {
    let newResponseData;
    if (response === null) {
      newResponseData = Object.fromEntries(
        Object.entries(responseData).filter(([key]) => Number(key) !== id)
      );
    } else {
      newResponseData = { ...responseData, [id]: response };
    }
    setResponseData(newResponseData);

    if (update) {
      submitQuestion(newResponseData);
    }
  };

  const loadNextQuestion = (response: QuestionPost) => {
    setNodeUUID(response.node_key);
    setPosition(response.position);
    setMaxPosition(response.max_position);
    setQuestion(response.question);
    setQuestionTitleOverride(null);
    setBackButtonOverride(null);
    if (response.question.default) {
      updateResponseData(
        response.question.id,
        response.question.default,
        false
      );
    }
  };

  useEffect(() => {
    if (checksPass()) {
      setNextEnabled(true);
      if (question.auto_next) {
        submitQuestion();
      }
    } else {
      setNextEnabled(false);
    }
  }, [responseData, position]);

  const goBack = () =>
    backButtonOverride
      ? backButtonOverride()
      : updateQuestionAsync(QuestionnaireAPI.back);

  const context: QuestionnaireContextType = {
    uuid,
    questionnaireUUID,
    nodeUUID,
    position,
    maxPosition,
    question,
    nextEnabled,
    responseData,
    updateResponseData,
    isSubmittingQuestion,
    setIsSubmittingQuestion,
    localResponseValidationError,
    setLocalResponseValidationError,
    loadNextQuestion,
    submitQuestion,
    goBack,
    questionTitleOverride,
    setQuestionTitleOverride,
    setBackButtonOverride
  };

  return (
    <QuestionnaireContext.Provider value={context}>
      {children}
    </QuestionnaireContext.Provider>
  );
};
