import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { BRAND } from "@mh/core";
import {
  isPatientQuestionnaire,
  isPatientQuestionnaireRepeat,
  isRenewalExcludedTreatments,
  Patient,
  PatientAPI,
  ThirdPartyAPI,
  useFeatures
} from "@mh/api";
import {
  BaseQuestionnaire,
  Doctor,
  IDVerification,
  Order,
  PatientQuestionnaire,
  PatientQuestionnaireRepeat,
  PatientSubscriptionScript,
  Script
} from "@mh/api";
import {
  Flex,
  HorizontalDivider,
  IconInformationCircle,
  Modal,
  SwitchButton,
  Toast,
  Tooltip
} from "@mh/components";
import {
  CancelStatus,
  ConsultationStatus,
  ImageUploadStatus,
  OrderStatus,
  OutOfStockStatus,
  ShippingStatus,
  RenewStatus,
  UnderAgeStatus,
  VerificationRequiredStatus
} from "./status";
import { ConsultSummary } from "./ConsultSummary";
import { BloodPressureStatus } from "./status/BloodPressureStatus";
import { SchedulingAction } from "../scheduling/SchedulingAction";
import {
  isInactiveTreatment,
  retrieveTreatmentCardRequirements
} from "./utils";

/** The minimum allowed age to use the service. */
const MINIMUM_AGE = 18;

interface TreatmentProps {
  questionnaire: BaseQuestionnaire;
  script: Script;
  subscription?: PatientSubscriptionScript;
  automaticallySendRepeats: boolean;
  setAutomaticallySendRepeats: (v: boolean) => void;
  isOrderProcessed: boolean;
}

const Header = ({ title, date }: { title: string; date: string }) => (
  <div
    css={{
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
      alignItems: "center"
    }}
  >
    <span css={{ fontSize: "20px" }}>{title}</span>
    <span
      css={{
        color: "#212529",
        fontSize: "14px"
      }}
    >
      {moment(date).format("Do MMM, YYYY")}
    </span>
  </div>
);

const Clinician = ({ doctor }: { doctor: Doctor }) => (
  <div
    css={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      gap: "8px"
    }}
  >
    <img
      width={32}
      height={32}
      src={
        doctor.user.profile_image ||
        doctor.profile_image ||
        "/assets/images/com/doctor.png"
      }
      css={{ borderRadius: "50%" }}
    />
    <span css={{ fontSize: "14px", fontWeight: "500" }}>
      {doctor.formal_name}
    </span>
  </div>
);

const getDispenseDate = (expiredDate: string | null | undefined) => {
  if (
    !expiredDate ||
    !moment(expiredDate).isValid() ||
    !moment(expiredDate).subtract(14, "days").isSameOrAfter(moment(), "day")
  )
    return "N/A";
  return moment(expiredDate).subtract(14, "days").format("Do MMM, YYYY");
};

const Treatment = ({
  script,
  questionnaire,
  subscription,
  automaticallySendRepeats,
  setAutomaticallySendRepeats,
  isOrderProcessed
}: TreatmentProps) => {
  const handleAutomaticallySendRepeats = async () => {
    try {
      if (subscription) {
        await PatientAPI.updatePatientSubscriptionScript(subscription.id, {
          order_on_demand: automaticallySendRepeats
        });
        setAutomaticallySendRepeats(!automaticallySendRepeats);
      } else {
        Toast.error("There was an error updating your treatment.");
      }
    } catch (error) {
      Toast.error("There was an error updating your treatment.");
    }
  };

  const [showConsultSummaryModal, setShowConsultSummaryModal] = useState(false);
  const repeatsRemaining = subscription
    ? Math.max(
        0,
        subscription.script_repeats - subscription.script_repeats_used
      )
    : 0;
  const dispenseDate = getDispenseDate(subscription?.supply_expire);
  return (
    <>
      <div
        css={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "2px",
          fontSize: "14px",
          padding: "16px",
          borderRadius: "8px",
          backgroundColor: "#F8F8F8"
        }}
      >
        <span
          css={(theme) => ({
            color: theme.color.primary,
            fontWeight: "600"
          })}
        >
          Treatment
        </span>
        <div css={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <span css={{ fontWeight: "600" }}>{script.product.title}</span>
        </div>
        <div
          data-testid="view-treatment-modal"
          css={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            cursor: "pointer"
          }}
          onClick={() => setShowConsultSummaryModal(true)}
        >
          <span css={{ fontSize: "12px", fontWeight: "500" }}>
            Learn more about my treatment
          </span>
          <IconInformationCircle
            css={(theme) => ({
              width: "20px",
              height: "20px",
              color: theme.color.primary
            })}
          />
        </div>
        {subscription?.patient_accepted &&
          subscription?.status !== "cancelled" && (
            <>
              <HorizontalDivider css={{ width: "100%" }} />
              <span
                css={(theme) => ({
                  color: repeatsRemaining
                    ? theme.color.primary
                    : theme.color.danger,
                  fontSize: "14px",
                  fontWeight: "600"
                })}
              >
                REPEATS REMAINING: {repeatsRemaining}
              </span>
              {subscription && repeatsRemaining > 0 && (
                <Flex
                  justifyContent="space-between"
                  width="100%"
                  alignItems="start"
                  css={{
                    fontSize: "16px",
                    fontWeight: "400"
                  }}
                >
                  <div
                    css={{
                      fontSize: "14px",
                      fontWeight: "500",
                      marginTop: "auto",
                      marginBottom: "auto"
                    }}
                  >
                    Automatically send repeats
                    <Tooltip
                      infoIcon={true}
                      iconWidth="12px"
                      maxWidth="450px"
                      text="Turn this on if you would like us to automatically deliver your repeats when they are due."
                    />
                  </div>
                  <div>
                    <SwitchButton
                      data-testid={"orderOnDemand"}
                      on={automaticallySendRepeats}
                      onClick={async () =>
                        await handleAutomaticallySendRepeats()
                      }
                      size="sm"
                    />
                    <span
                      css={(theme) => ({
                        display: "none",
                        [theme.mq.md]: { display: "inline-block" }
                      })}
                    >
                      {automaticallySendRepeats ? "Enabled" : "Disabled"}
                    </span>
                  </div>
                </Flex>
              )}
              {repeatsRemaining > 0 &&
                subscription?.supply_expire &&
                automaticallySendRepeats && (
                  <div
                    className="d-flex flex-row justify-content-between w-100"
                    css={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "135%",
                      paddingTop: "6px"
                    }}
                  >
                    <div>Next repeat dispense date</div>
                    <div>{dispenseDate}</div>
                  </div>
                )}
              {!isOrderProcessed &&
                repeatsRemaining > 0 &&
                !automaticallySendRepeats && (
                  <span
                    css={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "135%",
                      paddingTop: "6px"
                    }}
                  >
                    Add your medication to cart using the ‘Order repeat’ button
                  </span>
                )}
            </>
          )}
      </div>
      <Modal
        onHide={() => setShowConsultSummaryModal(false)}
        show={showConsultSummaryModal}
      >
        <Modal.Title
          customCss={{ marginBottom: "0px" }}
          onClose={() => setShowConsultSummaryModal(false)}
        >
          {script.product.title}
        </Modal.Title>
        <ConsultSummary script={script} questionnaire={questionnaire} />
      </Modal>
    </>
  );
};

interface ActiveTreatmentCardProps {
  patient?: Patient;
  /** Initial patient questionnaire. */
  initialQuestionnaire: PatientQuestionnaire;
  membershipsStatus: string | undefined;
  /** Callback triggered if a change/updare to the questionnaire data occurs. */
  onChange: () => void;
}

interface IsRenewStatusArguments {
  slug: string | undefined;
  renewalQuestionnaire: PatientQuestionnaireRepeat | undefined;
  latestScriptOrder: Order | undefined;
  subscription: PatientSubscriptionScript | undefined;
  questionnaire: BaseQuestionnaire | undefined;
}

/*
 * Check renewal date
 * 1. active date should be the past or today
 * 2. script_expire should be the past or today
 * 3. For supply_expire, if remaining repeat is 0, check supply_expire - 14 <= today
 */
const checkRenewalDate = (
  subscription: PatientSubscriptionScript | undefined,
  questionnaire: PatientQuestionnaireRepeat
): boolean => {
  if (
    !subscription ||
    !questionnaire.activedate ||
    !subscription.script_expire ||
    !moment(questionnaire.activedate).isValid() ||
    !moment(subscription.script_expire).isValid()
  )
    return false;

  const scriptRepeats = subscription.script_repeats ?? 0;
  const scriptRepeatsUsed = subscription.script_repeats_used ?? 0;
  const repeatsRemaining = scriptRepeats - scriptRepeatsUsed;
  if (
    moment(questionnaire.activedate).isSameOrBefore() &&
    (moment(subscription.script_expire).isSameOrBefore() ||
      (!repeatsRemaining &&
        moment(subscription.supply_expire).isValid() &&
        moment(subscription.supply_expire)
          .subtract(14, "days")
          .isSameOrBefore()))
  )
    return true;
  return false;
};

const isRenewStatus = ({
  slug,
  renewalQuestionnaire,
  latestScriptOrder,
  subscription,
  questionnaire
}: IsRenewStatusArguments): boolean => {
  return !!(
    slug &&
    !isRenewalExcludedTreatments(slug) &&
    renewalQuestionnaire &&
    !!isPatientQuestionnaireRepeat(renewalQuestionnaire) &&
    !renewalQuestionnaire.script &&
    !renewalQuestionnaire.consultation &&
    !!(
      latestScriptOrder?.status === "New Script Required" ||
      renewalQuestionnaire?.source === "mh_staff" ||
      (questionnaire?.consultation &&
        questionnaire?.consultation?.status === "decline") ||
      !!checkRenewalDate(subscription, renewalQuestionnaire)
    )
  );
};

export const ActiveTreatmentCard = ({
  patient,
  initialQuestionnaire,
  membershipsStatus,
  onChange = () => {}
}: ActiveTreatmentCardProps) => {
  const [status, setStatus] = useState<JSX.Element | null>();

  const { isFeatureActive } = useFeatures();

  const [hideClinician, setHideClinician] = useState<boolean>(false);
  const [isOrderProcessed, setIsOrderProcessed] = useState<boolean>(false);

  const {
    subscription,
    renewalQuestionnaire,
    questionnaire,
    previousQuestionnaire,
    latestScript,
    latestScriptOrder
  } = retrieveTreatmentCardRequirements(initialQuestionnaire);

  const [automaticallySendRepeats, setAutomaticallySendRepeats] =
    useState<boolean>(subscription ? !subscription.order_on_demand : false);

  const clinician =
    questionnaire.consultation?.clinician ||
    previousQuestionnaire?.consultation?.clinician;

  const verificationRequirementsSatisfied = (
    verification: IDVerification,
    ihiRequired: boolean
  ): boolean => {
    if (verification.nib_verified) return true;
    return ihiRequired
      ? verification.ihi_verified_and_active
      : verification.verified;
  };
  useEffect(() => {
    /**
     * This function determines which treatment status to show in the treament card
     * by setting the `status` prop.
     */
    const resolveStatus = async () => {
      setIsOrderProcessed(false);
      setHideClinician(false);
      if (patient?.dob) {
        const patientAge = moment().diff(moment(patient.dob), "years");
        if (patientAge < MINIMUM_AGE) {
          setStatus(<UnderAgeStatus minimumAge={MINIMUM_AGE} />);
          return;
        }
      }

      const nibTeleHealth = await isFeatureActive("nib_telehealth", true);
      if (questionnaire.category.id_verification_required) {
        const verification = await PatientAPI.checkVerification();
        if (
          verification &&
          !verificationRequirementsSatisfied(
            verification,
            questionnaire.ihi_required
          )
        ) {
          setStatus(
            <VerificationRequiredStatus
              verification={verification}
              ihiRequired={questionnaire.ihi_required}
              onVerificationChanged={onChange}
              productName={questionnaire.category.name}
            />
          );
          return;
        }
      }

      if (
        renewalQuestionnaire?.consultation &&
        questionnaire.consultation?.status === "decline"
      ) {
        setStatus(
          <ConsultationStatus
            initialQuestionnaire={initialQuestionnaire}
            questionnaire={questionnaire}
            subscription={subscription}
          />
        );
        return;
      }

      if (
        isPatientQuestionnaire(questionnaire) &&
        questionnaire.initial_contraceptive_pill &&
        (!questionnaire.blood_pressure_diastolic ||
          !questionnaire.blood_pressure_systolic)
      ) {
        setStatus(
          <BloodPressureStatus
            onUpdate={onChange}
            questionnaire={questionnaire}
          />
        );
        return;
      }

      if (
        !isRenewalExcludedTreatments(initialQuestionnaire.category.slug) &&
        initialQuestionnaire.category?.slug !== "digital-health-check" &&
        initialQuestionnaire.category?.slug !== "telehealth-consult" &&
        initialQuestionnaire.category?.slug !== "pre-travel-consult" &&
        questionnaire.script &&
        !questionnaire.script.product.availability.is_available_to_buy
      ) {
        setStatus(
          <OutOfStockStatus
            questionnaire={questionnaire}
            subscription={subscription}
            initialQuestionnaireId={initialQuestionnaire.id}
          />
        );
        return;
      }

      if (
        isRenewStatus({
          slug: initialQuestionnaire.category?.slug,
          renewalQuestionnaire,
          latestScriptOrder,
          subscription,
          questionnaire
        })
      ) {
        setStatus(
          <RenewStatus
            status={
              latestScriptOrder?.status === "New Script Required"
                ? "New Script Required"
                : "Follow-up required"
            }
            categoryName={initialQuestionnaire.category.slug}
            id={initialQuestionnaire.id}
          />
        );
        return;
      }

      if (
        latestScriptOrder &&
        questionnaire.consultation &&
        questionnaire.consultation.status
      ) {
        const shippingStatusProps =
          await ShippingStatus.fetchShippingStatusProps(
            latestScript!,
            latestScriptOrder
          );

        /** Only show shipping events if they were set in the last 7 days, and the order status is "Completed". */
        if (
          latestScriptOrder.status === "Completed" &&
          shippingStatusProps &&
          moment(shippingStatusProps.date).isSameOrAfter(
            moment().subtract(8, "days")
          )
        ) {
          setStatus(<ShippingStatus {...shippingStatusProps} />);
          setIsOrderProcessed(true);
          return;
        }

        if (
          OrderStatus.isSupportedOrder(latestScriptOrder) &&
          (moment(latestScriptOrder.date_placed).isSameOrAfter(
            moment().subtract(7, "days")
          ) ||
            latestScriptOrder?.status === "Payment failed")
        ) {
          setStatus(
            <OrderStatus
              script={latestScript!}
              order={latestScriptOrder}
              qId={initialQuestionnaire.id}
            />
          );
          setIsOrderProcessed(true);
          return;
        }
      }

      if (subscription?.status === "cancelled") {
        setStatus(
          <CancelStatus subscription={subscription} onChange={onChange} />
        );
        return;
      }

      if (ConsultationStatus.isSupportedQuestionnaire(questionnaire)) {
        const verification = await PatientAPI.checkVerification();
        setStatus(
          <ConsultationStatus
            initialQuestionnaire={initialQuestionnaire}
            onChange={onChange}
            questionnaire={questionnaire}
            subscription={subscription}
            nibIhiRequired={!verification?.ihi_verified_and_active}
            nibTeleHealth={nibTeleHealth && verification?.nib_verified}
            automaticallySendRepeats={automaticallySendRepeats}
          />
        );
        return;
      }

      if (
        (questionnaire.category.category_images.length > 0 &&
          !questionnaire.fulfilled_image_requirements) ||
        questionnaire.consultation?.status === "image_request_waiting"
      ) {
        setStatus(
          <ImageUploadStatus
            patientId={initialQuestionnaire.patient}
            categoryImageRequirements={
              initialQuestionnaire.category?.category_images
            }
            initialQuestionnaire={initialQuestionnaire}
            questionnaire={questionnaire}
            isImageRequestFromDoctor={
              questionnaire.consultation?.status === "image_request_waiting"
            }
          />
        );
        if (questionnaire.consultation?.status !== "image_request_waiting")
          setHideClinician(true);
        return;
      }

      if (questionnaire.category.is_teleconsult_required) {
        const [nibMembershipNumber] = await Promise.all([
          ThirdPartyAPI.getNIBMembershipNumber()
        ]);
        setStatus(
          <SchedulingAction
            initialQuestionnaire={initialQuestionnaire}
            currentQuestionnaire={questionnaire}
            // nib general telehealth enable flag, it will be removed when nib general telehealth is launched.
            nibTeleHealth={nibTeleHealth}
            nibMembershipNumber={nibMembershipNumber}
            membershipsStatus={membershipsStatus}
          />
        );
        setHideClinician(true);
      }
    };

    resolveStatus();
  }, [automaticallySendRepeats, initialQuestionnaire]);

  return isInactiveTreatment(
    questionnaire,
    initialQuestionnaire,
    subscription,
    latestScriptOrder?.date_placed
  ) ? (
    <></>
  ) : (
    <div
      css={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        padding: "24px 16px",
        backgroundColor: "white",
        border: `${
          BRAND === "hubhealth" ? "unset" : `1px solid ${theme.color.primary}`
        }`,
        borderRadius: "16px"
      })}
    >
      <Header
        title={
          initialQuestionnaire.category.display_name ||
          initialQuestionnaire.category.name
        }
        date={initialQuestionnaire.created_date}
      />
      <HorizontalDivider />
      {clinician && !hideClinician && (
        <>
          <Clinician doctor={clinician} />
          <HorizontalDivider />
        </>
      )}
      <div
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          [theme.mq.md]: {
            flexDirection: "row",
            gap: "24px"
          },
          ">*": {
            flex: 1
          }
        })}
      >
        {initialQuestionnaire.category.slug !== "telehealth-consult" &&
          initialQuestionnaire.category.slug !== "digital-health-check" &&
          initialQuestionnaire.category.slug !== "pre-travel-consult" &&
          latestScript &&
          !isRenewStatus({
            slug: initialQuestionnaire.category?.slug,
            renewalQuestionnaire,
            latestScriptOrder,
            subscription,
            questionnaire
          }) &&
          ConsultationStatus.isSupportedQuestionnaire(questionnaire) &&
          questionnaire?.consultation?.status !== "decline" && (
            <Treatment
              script={latestScript}
              questionnaire={questionnaire}
              subscription={subscription}
              automaticallySendRepeats={automaticallySendRepeats}
              setAutomaticallySendRepeats={setAutomaticallySendRepeats}
              isOrderProcessed={isOrderProcessed}
            />
          )}
        {status}
      </div>
    </div>
  );
};
