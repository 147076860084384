import { ComponentPropsWithoutRef, forwardRef, CSSProperties } from "react";
import { ClassNames } from "@emotion/react";
import { classNames } from "../utils";

type FlexProperties = Pick<
  CSSProperties,
  "flex" | "flexDirection" | "alignItems" | "justifyContent" | "flexWrap"
>;

interface Props extends ComponentPropsWithoutRef<"div"> {
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  marginBetween?: string | number;
}

/** Utility component for rendering a flex-box div without having constantly having to assign a class name */
const Flex = forwardRef<HTMLDivElement, Props & FlexProperties>(
  (props, ref) => {
    const {
      flex,
      flexDirection,
      alignItems,
      justifyContent,
      flexWrap,
      height,
      width,
      style,
      className,
      marginBetween,
      ...rest
    } = props;

    /** Gets a CSS class name for this Flex instance, if marginBetween is defined as a prop */
    function getMarginClass(): string | undefined {
      if (flexDirection === "column" && marginBetween) {
        return `
        > *:not(:last-child) {
          margin-bottom: ${marginBetween};
        }
      `;
      }

      if (marginBetween) {
        // flex-direction defaults to row
        return `
        > *:not(:last-child) {
          margin-right: ${marginBetween};
        }
      `;
      }
    }

    return (
      <ClassNames>
        {({ css }) => (
          <div
            ref={ref}
            {...rest}
            className={classNames(className, css(getMarginClass()))}
            style={{
              ...style,
              display: "flex",
              flex,
              flexDirection,
              flexWrap,
              alignItems,
              justifyContent,
              height,
              width
            }}
          >
            {props.children}
          </div>
        )}
      </ClassNames>
    );
  }
);

export default Flex;
