import { useEffect, useRef, useState } from "react";
import moment from "moment";
import momenttz from "moment-timezone";

import Cookies from "js-cookie";
import { API } from "@mh/api";
import { Address } from "components/LocationInput/types";

export function joinErrorMessages(
  errors: string[],
  baseMessage: string
): string {
  const { length } = errors;
  const end = errors.slice(length - 1, length);
  if (length === 1) {
    return `${baseMessage} ${end}`;
  }
  const start = errors.slice(0, length - 1).join(", ");
  return `${baseMessage} ${start} and ${end}`;
}

/**
 * Takes in an address and formats it as an address string
 *
 * @param address An dictionary of standardized address fields to format into a string
 * @returns An address string like "15 Prospect Street, Fortitude Valley, QLD, 4006"
 */
export function makeReadableAddress(address: Address): string {
  const keys: (keyof Address)[] = [
    "addressLine1",
    "addressLine2",
    "suburb",
    "state",
    "postcode"
  ];
  return keys
    .map((it) => (it === "state" ? address[it]?.toUpperCase() : address[it]))
    .filter((it) => !!it)
    .join(", ");
}

export const useEffectOnce = (effect: () => void | (() => void)) => {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [_, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};

export const getCookies = (): { [key: string]: string } | null => {
  if (Cookies) {
    const knownCookies: string[] = [
      "_gcl_aw",
      "_gcl_dc",
      "_fbp",
      "_fbc",
      "_ga",
      "_gcl_au",
      "irclickid"
    ];

    const cookieValues: [string, string | undefined][] = knownCookies.map<
      [string, string | undefined]
    >((k) => [k, Cookies.get(k)]);

    const providedCookies = cookieValues.filter(
      ([_, v]) => v !== undefined
    ) as [string, string][];

    if (providedCookies.length > 0) {
      return Object.fromEntries<string>(providedCookies);
    }
  }
  return null;
};

export const sendTrackingCookie = async () => {
  const providedCookies = getCookies();

  if (providedCookies) {
    API.v2().url("/tracking/").body(providedCookies).post();
  }
};

export const currentTimeIsBetween = (start: number, end: number): boolean => {
  const startTime = moment().set("hour", start).set("minute", 0);
  const endTime = moment().set("hour", end).set("minute", 59);
  return moment().isBetween(startTime, endTime);
};

interface Options {
  id: number;
  title: string;
  matched: string;
}

export const createOptions = (
  opList: { title: string; value: string }[]
): Options[] => {
  const options: Options[] = [];

  opList.forEach((val, index) => {
    options.push({
      id: index,
      title: val.title,
      matched: val.value
    });
  });

  return options;
};

export const checkLocalDayIsSameAsSydney = (): boolean =>
  momenttz.tz("Australia/Sydney").day() === momenttz().day();

export const getInitials = (fullName: string): string => {
  const names = fullName.split(" ");
  return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
};

const sydneyTZ = "Australia/Sydney";

export const nowIsDuringCallingTime = (
  start: number | null,
  end: number | null
): boolean => {
  const startTime = momenttz
    .tz(sydneyTZ)
    .set("hour", start ?? 8)
    .set("minute", 0);
  const endTime = momenttz
    .tz(sydneyTZ)
    .set("hour", end ?? 20)
    .set("minute", 0);
  return momenttz().local().isBetween(startTime, endTime);
};

const convertENVToArray = (envString: string) => {
  const result = envString
    ? envString.split(";").map((ele) => {
        return JSON.parse(ele);
      })
    : false;
  return result;
};

export const getBrandFromRedirectUrl = (redirectUrl: string) => {
  const brands = import.meta.env.VITE_BRANDMAP
    ? convertENVToArray(import.meta.env.VITE_BRANDMAP)
    : "";
  if (!brands) return null;
  const brand = brands.find((b: any) => redirectUrl.includes(b.url));
  return brand;
};
