const plural = (i: number, s: string, postfix: string) =>
  i > 1 ? `${s}${postfix}` : s;

const characterPlural = (i: number) => plural(i, "character", "s");

export const ErrorMessages = {
  form: {
    required: (name?: string) => `'${name ?? "Field"}' is required`,
    int: "Please enter a whole number",
    num: "Please enter a number",
    pattern: (name?: string) => `Please enter a valid ${name ?? "input"}`,
    minLen: (number: number, name?: string) =>
      `${
        name ?? "Input"
      } must be at least ${number.toString()} ${characterPlural(number)} long`,
    maxLen: (number: number, name?: string) =>
      `${
        name ?? "Input"
      } must be less than ${number.toString()} ${characterPlural(number)} long`,
    eqLen: (number: number, name?: string) =>
      `${
        name ?? "Input"
      } must be exactly ${number.toString()} ${characterPlural(number)} long`,
    medicare: "Provided medicare card number is invalid",
    dva: "Provided DVA number is invalid.",
    match: (otherName: string, name?: string) =>
      `${name ?? "Input"} must match ${otherName}`,
    notMatch: (otherName: string, name?: string) =>
      `${name ?? "Input"} must not match ${otherName}`
  }
};
