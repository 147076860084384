import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ValidationResult } from "@mh/core";
import {
  EditableFormCheckbox,
  EditableFormCheckboxProps,
  EditableFormControl,
  EditableFormControlProps,
  EditableFormSelect,
  EditableFormSelectProps
} from "@mh/components";

import { EditableProfileCard } from "./ProfileCard";

interface ProfileFieldProps {
  /* Label displayed above the input field. */
  label: JSX.Element | string;
  /* Help/description displayed below the input field, only shown in edit mode. */
  help?: string | null;
  /* Error displayed below the input field, only shown in edit mode. */
  error?: ValidationResult;
  children?: React.ReactNode;
  /* Whether the field should display as required. */
  required?: boolean;
  /* flex direction */
  flexDirection?: string;
  /* field class name */
  fieldClass?: string;

  rows?: number;
}

type ProfileControlProps = EditableFormControlProps & ProfileFieldProps;

type ProfileSelectProps = EditableFormSelectProps & ProfileFieldProps;

type ProfileCheckboxProps = EditableFormCheckboxProps & ProfileFieldProps;

export const ProfileField = (props: ProfileFieldProps) => {
  const { children, label, help, error, required, flexDirection, fieldClass } =
    props;

  let labelClassName = "text-nowrap";
  if (required) labelClassName += " required-asterisk";

  return (
    <Form.Group
      className={`w-100 d-flex ${flexDirection ?? "flex-column"} mb-4 ${
        fieldClass && fieldClass !== "undefined" ? fieldClass : ""
      }`}
    >
      <Form.Label className={labelClassName}>{label}</Form.Label>
      {children}
      {help && <Form.Text className="text-muted">{help}</Form.Text>}
      {error && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export const ProfileControl = (props: ProfileControlProps) => {
  const {
    label,
    help,
    error,
    isInvalid,
    required,
    flexDirection,
    fieldClass,
    ...formControlProps
  } = props;
  return (
    <ProfileField
      label={label}
      help={help}
      error={error}
      required={required}
      flexDirection={flexDirection}
      fieldClass={fieldClass}
    >
      <EditableFormControl
        {...formControlProps}
        isInvalid={isInvalid || !!error}
      />
    </ProfileField>
  );
};

export const ProfileCheckbox = (props: ProfileCheckboxProps) => {
  const { label, help, error, required, ...formControlProps } = props;
  return (
    <ProfileField label={label} help={help} error={error} required={required}>
      <EditableFormCheckbox {...formControlProps} />
    </ProfileField>
  );
};

export const ProfileSelect = (props: ProfileSelectProps) => {
  const {
    children,
    label,
    help,
    error,
    isInvalid,
    required,
    fieldClass,
    ...profileSelectProps
  } = props;

  let labelClassName = "text-nowrap";
  if (required) labelClassName += " required-asterisk";

  return (
    <Form.Group
      className={`w-100 d-flex flex-column mb-4 ${
        fieldClass && fieldClass !== "undefined" ? fieldClass : ""
      }`}
    >
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <EditableFormSelect
        {...profileSelectProps}
        isInvalid={isInvalid || !!error}
      >
        {children}
      </EditableFormSelect>
      {help && <Form.Text className="text-muted">{help}</Form.Text>}
      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export type ProfileFormValues = Record<string, any>;

/* Validation errors in an object with the same keys as ProfileFormValues. */
export type ProfileFormError<T extends ProfileFormValues> = {
  [K in keyof Partial<T>]: T[K] extends ProfileFormValues
    ? ProfileFormError<T[K]>
    : ValidationResult;
};

type ProfileFormChildrenProps<T extends ProfileFormValues> = {
  /* ID given to the parent form component. */
  id: string;
  /* The editable values of the form fields. */
  values: T;
  /* Setter method for form values. */
  setValues: (v: T) => void;
  /* Validation errors, if any. */
  error: ProfileFormError<T> | null;
  /* Whether the form is in editing mode (true), or not. */
  isEditing: boolean;

  setIsEditing?: (v: boolean) => void;
};

export interface ProfileFormProps<T extends ProfileFormValues> {
  children: (props: ProfileFormChildrenProps<T>) => React.ReactNode;
  /* ID for the component. */
  id: string;
  /* The set of initial/default values to populate for the form fields with. */
  initialValues: T;
  /* Whether there is a pending network request occurring, e.g. on initial load or form submission. */
  isFetching: boolean;
  /* Callback function called when saving/submitting the form. */
  onSave: (values: T) => Promise<void | Partial<T> | (void | Partial<T>)[]>;
  /* Title of the form. */
  title: string;
  /* Optional validation function, will be called before `onSave`. If any errors are found, `onSave` is not called. */
  validate?: (values: T) => ProfileFormError<T>;
  /* Callback function called when cancelling the form. */
  onCancel?: () => void;
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
  /* value is true if it is from questionnaire */
  isQuestionnaire?: boolean | undefined;
  /* row for textarea */
  row?: number;
}

/**
 * Checks whether `error` actuallly contains any ValidationErrors.
 * @param error
 * @returns True if any value `error` is non-null, False otherwise.
 */
const hasError = <T extends ProfileFormValues>(
  error: ProfileFormError<T> | ValidationResult
): boolean => {
  if (error === null) {
    return false;
  }
  if (typeof error === "string") {
    return true;
  }
  return Object.entries(error).some(([_, value]) => hasError(value));
};

export const ProfileForm = <T extends ProfileFormValues>(
  props: ProfileFormProps<T>
) => {
  const {
    children,
    id,
    initialValues,
    isFetching,
    onSave,
    title,
    validate,
    onCancel,
    isEditingCallback,
    isQuestionnaire
  } = props;

  const [values, setValues] = useState<T>(initialValues);
  const [error, setError] = useState<ProfileFormError<T> | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (isEditingCallback) isEditingCallback(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const cancel = () => {
    setValues(initialValues);
    setIsEditing(false);
    setError(null);
    onCancel && onCancel();
  };

  const save = async () => {
    const error = validate ? validate(values) : null;

    if (hasError(error)) setError(error);
    else {
      // All validators returned null, we are clear to call the onSave
      let result = await onSave(values);

      // If result is not an array make it an array
      if (!Array.isArray(result)) result = [result];

      if (result.every((v) => v === undefined)) {
        setIsEditing(false);
        setError(null);
      } else {
        // backend error has occured, set error to the result
        setError(
          result.reduce(
            (previous, current) =>
              current !== undefined ? { ...previous, ...current } : previous,
            {}
          ) as ProfileFormError<T>
        );
      }
    }
  };
  return (
    <EditableProfileCard
      id={id}
      title={title}
      disabled={isFetching}
      isEditing={isEditing}
      isLoading={isFetching}
      onEdit={() => setIsEditing(true)}
      onSave={save}
      onCancel={cancel}
      isQuestionnaire={isQuestionnaire}
    >
      {({ isEditing }) => (
        <>
          {children({ values, setValues, error, isEditing, id, setIsEditing })}
        </>
      )}
    </EditableProfileCard>
  );
};

ProfileForm.Control = ProfileControl;
ProfileForm.Select = ProfileSelect;
