import { useCallback, useEffect, useRef, useState } from "react";
import type { QueryParam, GetRequest } from "../utils";

import { Async, createAsync } from "./async";

type Refresh = () => void;

/** Generic async function which takes in variable parameters and returns some data */
type AsyncRequest<ResponseShape> = (...args: any[]) => Promise<ResponseShape>;

type DataSource<
  Data,
  Params extends {},
  QueryParams extends { [queryParam: string]: QueryParam }
> = GetRequest<Data, Params, QueryParams> | AsyncRequest<Data> | Promise<Data>;

/** A structure containing some resolved async data, and actions to refresh and manually update it */
export interface UseAsync<Data, Error = any> {
  /**
   * {@link Async} marking whether the data requested has not resolved yet, was successful (with resolved data) or
   * rejected
   */
  async: Async<Data, Error>;
  /** Callback to reload the data. Useful when you want to call the provided data source function again */
  refresh: Refresh;
  /** Manually overrides data provided in async */
  setData: (data: Data) => void;
}

/**
 * A hook which takes in some kind of asynchronous input (function or {@link Promise}) and resolves it to some kind of
 * accesible data.
 *
 * @param dataSource A {@link GetRequest}, generic async function or {@link Promise} which resolves to some data
 * @param params URL parameters used in the case when dataSource is a {@link GetRequest}
 * @param initialQueryParams Query parameters used in the case when dataSource is {@link GetRequest}
 * @returns Resolved hook data of type {@link UseAsync}
 */
export function useAsync<
  /** Return type of either the async function, or Promise */
  Data,
  /** URL params used in the case the function is a GetRequest */
  Params extends {} = {},
  /** Query params used in the case the function is a GetRequest */
  QueryParams extends { [queryParam: string]: QueryParam } = {}
>(
  dataSource: DataSource<Data, Params, QueryParams>,
  params?: Params,
  initialQueryParams?: QueryParams
): UseAsync<Data, any> {
  /** Is this useAsync call set to make an API request? */
  const isFetching = useRef<boolean>(true);
  const [result, setResult] = useState<Async<Data>>(createAsync());

  useEffect(() => {
    /** Calls the given API method */
    const performRequest = async (): Promise<void> => {
      const promisedData: Promise<Data> =
        dataSource instanceof Promise
          ? dataSource
          : dataSource(params, initialQueryParams);

      promisedData
        .then((result) => setResult({ state: "success", data: result }))
        .catch((error) => setResult({ state: "failed", error }));
    };

    if (!isFetching.current) {
      // Don't reload if we have no reason to
      return;
    }

    isFetching.current = false;
    performRequest();
  });

  const refresh = useCallback<Refresh>(() => {
    isFetching.current = true;

    // Force a re-render to retrigger the useEffect call above
    // The spread operator here guarantees the result async has a different memory address, which causes a state change
    // and thus a rerender
    setResult((async) => ({ ...async }));
  }, []);

  const setData = useCallback<UseAsync<Data>["setData"]>((data: Data) => {
    setResult({ state: "success", data });
  }, []);

  return { async: result, refresh, setData };
}
