import { useState } from "react";
import { BundleItem, type PackStep as IPackStep } from "@mh/api";
import { Button, SafeImg, Spinner } from "@mh/components";

import type { TreatmentAcceptanceStepProps } from "../types";
import { WizardStep, TitleStyle, SubTitleStyle } from "./WizardStep";
import { SelectableBox } from "./SelectableBox";

interface PackStepProps extends TreatmentAcceptanceStepProps {
  step: IPackStep;
}

interface BundleBoxProps {
  /** ID of the bundle. Used for the test ID selector */
  id: number | null;
  /** Name of the bundle */
  name: string;
  /** URL of the bundle's image. Null when the product does not have an image. */
  image: string | null;
  /** Bundle items to display */
  items: BundleItem[];
  /** Price string */
  price: string;
  /** Is this bundle currently selected? */
  isSelected: boolean;
  /** Fires when this box is clicked */
  onClick: () => void;
}

const BundleBox = ({
  id,
  name,
  image,
  items,
  price,
  isSelected,
  onClick
}: BundleBoxProps) => {
  let productList = <></>;

  const hasMultipleItems = items.length > 1;

  if (hasMultipleItems) {
    productList = (
      <ul>
        {items.map((item) => (
          <li key={item.product.id} css={SubTitleStyle}>
            {item.product.title}
          </li>
        ))}
      </ul>
    );
  }

  if (!hasMultipleItems) {
    const [item] = items;

    productList = <span css={SubTitleStyle}>{item.product.title}</span>;
  }

  return (
    <SelectableBox
      isSelected={isSelected}
      onClick={onClick}
      data-testid={`bundle-box-${id}`}
      css={{
        flexFlow: "column"
      }}
    >
      <div
        css={(theme) => ({
          display: "flex",
          alignItems: "flex-end",
          gap: "8px",
          [theme.mq.md]: {
            flex: 1,
            flexFlow: "row",
            alignItems: "flex-start"
          },
          "& > img, & > svg": {
            // Defined as an img/svg selector as <SafeImg> doesn't support the `css` prop
            flex: "0 0 92px",
            height: "92px",
            width: "92px",
            objectFit: "cover",
            [theme.mq.md]: {
              flex: "0 0 120px",
              height: "120px",
              width: "120px"
            }
          }
        })}
      >
        {image && <SafeImg imagePath={image ?? undefined} />}
        <div css={{ display: "flex", flexFlow: "column", flex: 1 }}>
          <div
            css={[
              TitleStyle,
              {
                marginBottom: "4px"
              }
            ]}
          >
            {name}
          </div>
          {productList}
        </div>
      </div>
      <hr />
      <div css={{ display: "flex", justifyContent: "flex-end" }}>
        <b css={{ color: "#262626", fontSize: "20px", fontWeight: "600" }}>
          {price}
        </b>
      </div>
    </SelectableBox>
  );
};

export const PackStep = ({ step, onContinue }: PackStepProps) => {
  /**
   * The ID of the currently selected pack. Can be null, which maps to the "Medication only" pack
   *
   * Defaults to undefined, which means no pack has been selected.
   */
  const [selectedId, setSelectedId] = useState<number | null | undefined>(
    undefined
  );

  /** True if the step is submitting */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * Handles the "Next" button click. Calls the `onContinue` callback with the selected pack's ID.
   *
   * If the "Medication only" pack is selected (with ID 0), the ID will be sent as null to denot that no bundle was
   * selected.
   */
  const handleContinue = () => {
    setIsSubmitting(true);

    // A bundle with ID 0 is the fake "Medication only" bundle
    const bundleId = selectedId === 0 ? null : selectedId;

    try {
      onContinue({ pack: bundleId });
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  /** All available bundles the patient can select */
  const { packs: bundles, prices } = step.data;

  /** True if a pack has not been selected */
  const isNextDisabled = isSubmitting || selectedId === undefined;

  return (
    <WizardStep
      heading="Choose your treatment"
      footer={
        <Button
          variant="primary"
          disabled={isNextDisabled}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          onClick={handleContinue}
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            <div>
              Next
              <span css={{ paddingLeft: "16px" }}>
                <img src="/assets/images/com/rightArrow.svg" />
              </span>
            </div>
          )}
        </Button>
      }
    >
      <div css={{ display: "flex", flexFlow: "column", gap: "16px" }}>
        {bundles.map((bundle) => {
          let price = "-";

          if (bundle.id in prices) {
            const priceValue = parseFloat(prices[bundle.id].incl_tax);

            price = Intl.NumberFormat("en-AU", {
              style: "currency",
              currency: "AUD"
            }).format(priceValue);
          }

          const isSelected = selectedId === bundle.id;

          return (
            <BundleBox
              key={`pack-${bundle.id}`}
              id={bundle.id}
              name={bundle.name}
              image={bundle.image}
              items={bundle.items}
              price={price}
              isSelected={isSelected}
              onClick={() => {
                // NOTE: bundle.id can be null if the patient has selected a "Medication only" bundle
                setSelectedId(bundle.id);
              }}
            />
          );
        })}
      </div>
    </WizardStep>
  );
};
