import { ReactNode } from "react";

interface PhoneBlockProps {
  mobileClass?: string; // mobile input field class
  type?: string; // question type
  accountAttrClass?: string | undefined; // input label class
  phoneField?: ReactNode; // phone section
}

export const PhoneBlock = (props: PhoneBlockProps) => (
  <div
    className={`col-12 ${
      props?.mobileClass
        ? props?.mobileClass
        : props?.type !== "bypass"
        ? "col-lg-5"
        : "col-lg-6"
    } pt-4`}
  >
    <label htmlFor="signup_phone">
      <div
        className={`h2-sm asterisk ${
          props?.accountAttrClass ? props?.accountAttrClass : ""
        }`}
      >
        Mobile Number
      </div>
    </label>
    {props.phoneField}
  </div>
);
