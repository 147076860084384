export const NotificationDot = () => (
  <div
    css={{
      backgroundColor: "#FF0018",
      width: "6px",
      height: "6px",
      borderRadius: "50%"
    }}
    data-testid="notification-dot"
  />
);
