import { ComponentPropsWithoutRef } from "react";

export const IconCircleChevronLeft = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4941 0.408203C16.002 0.408203 20.4941 4.90039 20.4941 10.4082C20.4941 15.9551 16.002 20.4082 10.4941 20.4082C4.94727 20.4082 0.494141 15.9551 0.494141 10.4082C0.494141 4.90039 4.94727 0.408203 10.4941 0.408203ZM12.6035 13.9238L9.12695 10.4082L12.6035 6.93164C13.1113 6.46289 13.1113 5.64258 12.6035 5.17383C12.1348 4.66602 11.3145 4.66602 10.8457 5.17383L6.4707 9.54883C6.23633 9.7832 6.11914 10.0957 6.11914 10.4082C6.11914 10.7598 6.23633 11.0723 6.4707 11.3066L10.8457 15.6816C11.3145 16.1895 12.1348 16.1895 12.6035 15.6816C13.1113 15.2129 13.1113 14.3926 12.6035 13.9238Z"
      fill="currentColor"
    />
  </svg>
);
