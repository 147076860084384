import moment from "moment-timezone";
import {
  BaseQuestionnaire,
  compareQuestionnairesByCreatedDate,
  DoctorStatus,
  isPatientQuestionnaireRepeat,
  PatientQuestionnaire,
  PatientQuestionnaireRepeat,
  PatientSubscriptionScript
} from "@mh/api";

const getPreviousQuestionnaireForNewRepeat = (
  currentQuestionnaire: PatientQuestionnaire | PatientQuestionnaireRepeat,
  initialQuestionnaire: PatientQuestionnaire,
  subscriptionScripts: PatientSubscriptionScript
) => {
  if (
    !subscriptionScripts ||
    !isPatientQuestionnaireRepeat(currentQuestionnaire) ||
    subscriptionScripts?.repeat_questionnaires?.length === 0
  )
    return null;

  return subscriptionScripts?.repeat_questionnaires?.length === 1
    ? initialQuestionnaire
    : subscriptionScripts.repeat_questionnaires
        .filter((q) => q.active && !!q.consultation)
        .sort((a, b) => (a.created_date < b.created_date ? -1 : 1))
        ?.at(-2);
};

const isProductSaxenda = (displayName: string | undefined): boolean =>
  displayName?.toLowerCase().includes("saxenda") ?? false;

const isProductOzempic = (displayName: string | undefined): boolean =>
  displayName?.toLowerCase().includes("ozempic") ?? false;

const isProductMounjaro = (displayName: string | undefined): boolean =>
  displayName?.toLowerCase().includes("mounjaro") ?? false;

const isProductSemaglutide = (displayName: string | undefined): boolean =>
  displayName?.toLowerCase().includes("semaglutide") ?? false;

const isProductTirzepatide = (displayName: string | undefined): boolean =>
  displayName?.toLowerCase().includes("tirzepatide") ?? false;

const isProductWegovy = (displayName: string | undefined): boolean =>
  displayName?.toLowerCase().includes("wegovy") ?? false;

export const isColdChainProduct = (displayName: string | undefined): boolean =>
  (isProductSaxenda(displayName) ||
    isProductOzempic(displayName) ||
    isProductMounjaro(displayName) ||
    isProductSemaglutide(displayName) ||
    isProductTirzepatide(displayName) ||
    isProductWegovy(displayName)) ??
  false;

export const retrieveTreatmentCardRequirements = (
  initialQuestionnaire: PatientQuestionnaire
) => {
  /** Get the latest subscription. */
  const subscription = initialQuestionnaire.subscription_scripts
    .sort((a, b) => (a.start_date < b.start_date ? -1 : 1))
    .at(-1);

  const renewalQuestionnaires =
    subscription?.repeat_questionnaires.sort(
      compareQuestionnairesByCreatedDate
    ) ?? [];

  /**
   * The most recent repeat questionnaire
   */
  const renewalQuestionnaire = renewalQuestionnaires?.at(-1);

  /**
   * Get most recent repeat questionnaire with consultation, or the initial questionnaire if none exists.
   */
  const questionnaire =
    renewalQuestionnaires
      .filter((q: PatientQuestionnaireRepeat) => !!q.consultation)
      .at(-1) || initialQuestionnaire;

  /**
   * Get previous questionnaire if current questionnaire to show previous medication and clinitian details
   */
  const previousQuestionnaire = getPreviousQuestionnaireForNewRepeat(
    questionnaire,
    initialQuestionnaire,
    subscription!
  );

  /** The most recent script. */
  const latestScript = questionnaire.script || previousQuestionnaire?.script;

  /** The most recent order for the current script, if any. */
  const latestScriptOrder = latestScript?.orders.at(-1);

  return {
    subscription,
    renewalQuestionnaire,
    questionnaire,
    previousQuestionnaire,
    latestScript,
    latestScriptOrder
  };
};

const isOtcTypeTreatments = (slug: string): boolean => {
  return (
    slug === "thrush" ||
    slug === "morning-after-pill" ||
    slug === "fertility" ||
    slug === "otc"
  );
};

const isSingleSubscriptionTypeTreatments = (
  slug: string,
  status: DoctorStatus | null | undefined,
  subscription: PatientSubscriptionScript | undefined
): boolean => {
  if (
    (status === "approve" &&
      (slug === "medicalcertificates" ||
        (slug === "renew-a-script" && !subscription))) ||
    slug === "telehealth-consult" ||
    slug === "pre-travel-consult" ||
    slug === "digital-health-check"
  )
    return true;

  return false;
};

export const isInactiveTreatment = (
  questionnaire: BaseQuestionnaire,
  initialquestionnaire: PatientQuestionnaire,
  subscription: PatientSubscriptionScript | undefined,
  orderDate: string | undefined
): boolean => {
  if (!initialquestionnaire.category?.slug) return true;
  if (isOtcTypeTreatments(initialquestionnaire.category.slug)) return true;

  // Show 14 days for fill-a-script when
  // 1. repeat reaming is 0 and last order date is within 14 days or
  // 2. script is expired
  if (
    initialquestionnaire.category.slug === "fill-a-script" &&
    ((subscription &&
      subscription.script_expire &&
      moment(subscription.script_expire).isBefore(moment())) ||
      (subscription?.script_repeats &&
        subscription.script_repeats > 0 &&
        subscription?.script_repeats_used &&
        subscription.script_repeats_used >= 0 &&
        subscription.script_repeats - subscription.script_repeats_used <= 0 &&
        orderDate &&
        moment
          .duration(
            moment().startOf("day").diff(moment(orderDate, "YYYY-MM-DD"))
          )
          .asDays() <= 14))
  )
    return true;

  if (
    initialquestionnaire.category.slug === "digital-health-check" &&
    initialquestionnaire.skip_consult
  )
    return true;

  if (
    !isSingleSubscriptionTypeTreatments(
      initialquestionnaire.category.slug,
      questionnaire.consultation?.status,
      subscription
    )
  )
    return false;

  const visibleDate =
    initialquestionnaire.category.slug !== "digital-health-check" &&
    initialquestionnaire.category.slug !== "telehealth-consult" &&
    initialquestionnaire.category.slug !== "pre-travel-consult"
      ? -1
      : questionnaire.consultation?.status === "after_hours"
      ? 2
      : 7;

  return (
    !!questionnaire.consultation?.clinician_dt &&
    moment
      .duration(
        moment()
          .startOf("day")
          .diff(moment(questionnaire.consultation?.clinician_dt, "YYYY-MM-DD"))
      )
      .asDays() > visibleDate
  );
};
