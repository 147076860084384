import { ComponentPropsWithoutRef } from "react";

export const IconInformationCircle = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 3.5C9.80469 3.5 12.5 6.19531 12.5 9.5C12.5 12.8281 9.80469 15.5 6.5 15.5C3.17188 15.5 0.5 12.8281 0.5 9.5C0.5 6.19531 3.17188 3.5 6.5 3.5ZM6.5 14.375C9.17188 14.375 11.375 12.1953 11.375 9.5C11.375 6.82812 9.17188 4.625 6.5 4.625C3.80469 4.625 1.625 6.82812 1.625 9.5C1.625 12.1953 3.80469 14.375 6.5 14.375ZM7.4375 11.375C7.74219 11.375 8 11.6328 8 11.9375C8 12.2656 7.74219 12.5 7.4375 12.5H5.5625C5.23438 12.5 5 12.2656 5 11.9375C5 11.6328 5.23438 11.375 5.5625 11.375H5.9375V9.875H5.75C5.42188 9.875 5.1875 9.64062 5.1875 9.3125C5.1875 9.00781 5.42188 8.75 5.75 8.75H6.5C6.80469 8.75 7.0625 9.00781 7.0625 9.3125V11.375H7.4375ZM6.5 8C6.07812 8 5.75 7.67188 5.75 7.25C5.75 6.85156 6.07812 6.5 6.5 6.5C6.89844 6.5 7.25 6.85156 7.25 7.25C7.25 7.67188 6.89844 8 6.5 8Z"
      fill="currentColor"
    />
  </svg>
);
