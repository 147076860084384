import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  ADDRESS_STATE_OPTIONS,
  LocationAutocomplete,
  setShippingAddressFromAddressAutoComplete,
  ShippingAddressValues
} from "@mh/components";

import "./styles.scss";

export interface AddressProps {
  autoFocus?: boolean;
  value: any;
  onChange: (value: any) => void;
}

export const Address = (props: AddressProps) => {
  const [showManual, setShowManual] = useState(false);
  const [address, setAddress] = useState<ShippingAddressValues>(
    props.value ?? {
      line1: "",
      line2: "",
      suburb: "",
      state: "",
      postcode: ""
    }
  );
  useEffect(() => props.onChange(address), [address]);

  return (
    <div className="address-container">
      <div>
        <LocationAutocomplete
          autoFocus={props.autoFocus}
          onSelectedLocation={(location) =>
            setShippingAddressFromAddressAutoComplete(location, setAddress)
          }
        />
        <a className="manual-link" onClick={() => setShowManual(!showManual)}>
          Enter your address manually
        </a>
      </div>
      {showManual && (
        <div className="manual-form">
          <Form.Control
            placeholder="Address Line 1"
            data-testid={"address-line-1"}
            value={address.line1}
            onChange={(e) =>
              setAddress({ ...address, line1: e.currentTarget.value })
            }
          />
          <Form.Control
            placeholder="Address Line 2"
            data-testid={"address-line-2"}
            value={address.line2}
            onChange={(e) =>
              setAddress({ ...address, line2: e.currentTarget.value })
            }
          />
          <Form.Control
            placeholder="Suburb"
            data-testid={"suburb"}
            value={address.suburb}
            onChange={(e) =>
              setAddress({ ...address, suburb: e.currentTarget.value })
            }
          />
          <Form.Select
            placeholder="State"
            data-testid={"state"}
            value={address?.state}
            onChange={(e) =>
              setAddress({ ...address, state: e.currentTarget.value })
            }
          >
            <option disabled value=""></option>
            {ADDRESS_STATE_OPTIONS.map(([value, display], index) => (
              <option key={`${index}_${value}`} value={value}>
                {display}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            placeholder="Postcode"
            data-testid={"postcode"}
            value={address?.postcode}
            onChange={(e) =>
              setAddress({ ...address, postcode: e.currentTarget.value })
            }
          />
        </div>
      )}
    </div>
  );
};
