import React, {
  Context,
  ReactNode,
  createContext,
  useContext,
  useState
} from "react";
import { QCListSelectedResponse } from "./QCBaseList";
import { Sentry } from "@mh/core";

export interface QCListContextType {
  options: string[];
  setOptions: (v: string[]) => void;
  selectedOptions: QCListSelectedResponse;
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<QCListSelectedResponse>
  >;
  otherValue: string | null;
  setOtherValue: React.Dispatch<React.SetStateAction<string | null>>;
}

// Creating a context for our Questionnaire state
export const QCListContext: Context<QCListContextType | undefined> =
  createContext<QCListContextType | undefined>(undefined);

// Custom hook to access the Questionnaire state and its actions
export const useQCListContext = () => {
  const context = useContext(QCListContext);
  if (!context) {
    Sentry.captureMessage(
      "useQCListContext must be used within a QuestionnaireProvider"
    );
    throw new Error(
      "useQCListContext must be used within a QuestionnaireProvider"
    );
  }
  return context;
};

export const QCListProvider: React.FC<{
  children: ReactNode;
  initialOptions: string[];
  initialOptionsSelection: QCListSelectedResponse;
  initialOtherValue?: string;
}> = ({
  children,
  initialOptions,
  initialOptionsSelection,
  initialOtherValue
}) => {
  const initialFalseState = {
    ...initialOptions.reduce((acc, option) => ({ ...acc, [option]: false }), {})
  };

  const [options, setOptions] = useState<string[]>(initialOptions);
  const [selectedOptions, setSelectedOptions] =
    useState<QCListSelectedResponse>(
      initialOptionsSelection ?? { ...initialFalseState }
    );
  const [otherValue, setOtherValue] = useState<string | null>(
    initialOtherValue ?? null
  );

  const context: QCListContextType = {
    options,
    setOptions,
    selectedOptions,
    setSelectedOptions,
    otherValue,
    setOtherValue
  };

  return (
    <QCListContext.Provider value={context}>{children}</QCListContext.Provider>
  );
};
