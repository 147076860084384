import { ComponentPropsWithoutRef } from "react";

export const IconRemoveFromCart = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 19"
    fill="none"
    {...props}
  >
    <path
      d="M17.8383 13.7359H7.78678C7.41058 13.7359 7.07863 13.493 6.96863 13.1373L3.71531 2.6223H0.855556C0.383045 2.6223 0 2.24362 0 1.77648C0 1.30935 0.383045 0.930664 0.855556 0.930664H4.34843C4.72463 0.930664 5.05658 1.17354 5.16658 1.52902L8.41989 12.0442H17.2698L19.8572 5.95652H18.3854C17.9129 5.95652 17.5299 5.57784 17.5299 5.1107C17.5299 4.64357 17.9129 4.26488 18.3854 4.26488H21.1445C21.4309 4.26488 21.6986 4.40674 21.857 4.6426C22.0154 4.87846 22.0443 5.17716 21.9333 5.4384L18.6269 13.2178C18.4935 13.5317 18.1828 13.7359 17.8383 13.7359Z"
      fill="#FF0018"
    />
    <path
      d="M7.05949 18.9304C7.89111 18.9304 8.56527 18.2639 8.56527 17.4418C8.56527 16.6196 7.89111 15.9531 7.05949 15.9531C6.22787 15.9531 5.55371 16.6196 5.55371 17.4418C5.55371 18.2639 6.22787 18.9304 7.05949 18.9304Z"
      fill="#FF0018"
    />
    <path
      d="M18.2333 18.9304C19.0649 18.9304 19.7391 18.2639 19.7391 17.4418C19.7391 16.6196 19.0649 15.9531 18.2333 15.9531C17.4017 15.9531 16.7275 16.6196 16.7275 17.4418C16.7275 18.2639 17.4017 18.9304 18.2333 18.9304Z"
      fill="#FF0018"
    />
    <path
      d="M15.2234 5.89476H8.6036C8.13109 5.89476 7.74805 5.51608 7.74805 5.04894C7.74805 4.58181 8.13109 4.20312 8.6036 4.20312H15.2234C15.6959 4.20312 16.079 4.58181 16.079 5.04894C16.079 5.51608 15.6959 5.89476 15.2234 5.89476Z"
      fill="#FF0018"
    />
  </svg>
);
