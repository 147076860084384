import { FC, useMemo } from "react";
import type { BasketLine, ProductAvailability, RouterMethods } from "@mh/api";
import { Await, Flex } from "@mh/components";
import { useBasket, OrderSummary } from "@mh/basket";

interface Props {
  /** Optional mapping of product IDs to their availabilities */
  productAvailabilities?: Record<number, ProductAvailability>;
  /**
   * Optional callback to fire when an item's quantity is changed
   * @param index The index of the item whose quantity has been changed
   * @param quantity The new quantity
   */
  onQuantityChange?: (index: number, quantity: number) => void;
  /**
   * Optional callback to fire when an item's quantity is changed and an error occurs
   * @param index The index of the item whose quantity has been changed
   * @param quantity The new quantity
   * @param error The error which occurred
   */
  onQuantityChangeError?: (index: number, quantity: number, error: any) => void;
  /**
   * Optional callback to fire when an item is removed
   * @param lineId The index of the removed item
   */
  onItemRemove?: (lineId: number) => void;

  /**
   * Optional mapping from basket line id to order on demand value
   */
  lineIdToOrderOnDemand?: { [lineId: number]: boolean } | undefined;

  /**
   * Optional callback to set mapping from basket line id to order on demand value
   * @param lindId basket line id
   * @param orderOnDemand
   */
  setLineIdToOrderOnDemand?: React.Dispatch<
    React.SetStateAction<
      | {
          [lineId: number]: boolean;
        }
      | undefined
    >
  >;

  routerMethods: RouterMethods | null;
}

const BasketItems: FC<Props> = (props) => {
  const basket = useBasket();

  const availabilities = useMemo<Record<number, ProductAvailability>>(
    () => props.productAvailabilities ?? {},
    [props.productAvailabilities]
  );

  const handleQuantityChange = async (index: number, quantity: number) => {
    try {
      await basket.setQuantity(index, quantity);

      if (props.onQuantityChange) {
        props.onQuantityChange(index, quantity);
      }
    } catch (e: any) {
      if (props.onQuantityChangeError) {
        props.onQuantityChangeError(index, quantity, e);
      }
    }
  };

  const handleItemRemove = (lineId: number) => {
    basket.removeItem(lineId);

    if (props.onItemRemove) {
      props.onItemRemove(lineId);
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width="100%"
      marginBetween="2rem"
    >
      <Await<[BasketLine[]]> asyncs={[basket.lines]}>
        {([lines]) => (
          <>
            {lines.map((line, index) => (
              <Flex key={line.id} flexDirection="column" width="100%">
                <OrderSummary
                  line={line}
                  outOfStock={
                    availabilities[line.product.id]?.is_available_to_buy ===
                    false
                  }
                  onChange={(quantity) => handleQuantityChange(index, quantity)}
                  onRemove={() => handleItemRemove(line.id)}
                  lineIdToOrderOnDemand={
                    props.lineIdToOrderOnDemand ?? undefined
                  }
                  setLineIdToOrderOnDemand={
                    props.setLineIdToOrderOnDemand ?? undefined
                  }
                  routerMethod={
                    (props.routerMethods &&
                      props.routerMethods[line.product.id]) ||
                    null
                  }
                />
              </Flex>
            ))}
          </>
        )}
      </Await>
    </Flex>
  );
};

export default BasketItems;
