import { useState } from "react";
import type {
  ShippingMethodStep as IShippingMethodStep,
  ShippingMethod
} from "@mh/api";
import { Button, Spinner } from "@mh/components";

import type { TreatmentAcceptanceStepProps } from "../types";
import { SelectableBox } from "./SelectableBox";
import { WizardStep, TitleStyle, SubTitleStyle } from "./WizardStep";

interface ShippingMethodStepProps extends TreatmentAcceptanceStepProps {
  step: IShippingMethodStep;
}

/**
 * Renders each shipping method available as part of this checkout and handles when a user selects one.
 */
export const ShippingMethodStep = ({
  step,
  onContinue
}: ShippingMethodStepProps) => {
  const [selectedMethod, setSelectedMethod] = useState<ShippingMethod | null>(
    null
  );

  /** True if the step is submitting */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /** Fired when the next button is clicked, only if a shipping method has been selected */
  const handleContinue = () => {
    if (selectedMethod) {
      setIsSubmitting(true);

      try {
        onContinue({ shipping_method: selectedMethod.code });
      } catch (e) {
        setIsSubmitting(false);
      }
    }
  };

  /** The next button is disabled if no shipping method has been selected */
  const isNextDisabled = isSubmitting || selectedMethod === null;

  return (
    <WizardStep
      heading="How do you want to receive your order?"
      footer={
        <Button
          variant="primary"
          disabled={isNextDisabled}
          css={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            [theme.mq.md]: {
              width: "260px !important"
            }
          })}
          onClick={handleContinue}
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            <div>
              Proceed to payment
              <span css={{ paddingLeft: "16px" }}>
                <img src="/assets/images/com/rightArrow.svg" />
              </span>
            </div>
          )}
        </Button>
      }
    >
      {step.data.shipping_methods.map((method) => {
        // Check if this method is currently selected
        const isSelected = selectedMethod?.code === method.code;

        // Find the price to display
        const price = parseInt(method.price.incl_tax);

        const isFree = price === 0;

        const priceDisplay = isFree
          ? "Free"
          : Intl.NumberFormat("en-AU", {
              style: "currency",
              currency: "AUD"
            }).format(price);

        return (
          <SelectableBox
            key={method.code}
            isSelected={isSelected}
            css={{ flexFlow: "column" }}
            onClick={() => setSelectedMethod(method)}
          >
            <div
              css={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              <div css={TitleStyle}>{method.name}</div>
              <div
                css={(theme) => ({
                  fontSize: "20px",
                  fontWeight: "bold",
                  color:
                    isSelected && priceDisplay === "Free"
                      ? theme.color.primary
                      : "auto"
                })}
              >
                {priceDisplay}
              </div>
            </div>
            <span css={SubTitleStyle}>{method.description}</span>
          </SelectableBox>
        );
      })}
    </WizardStep>
  );
};
