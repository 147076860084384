import { useState, FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SetupIntent } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import {
  useAsync,
  asyncIsSuccess,
  CheckoutAPI,
  ShippingMethod,
  Basket,
  BasketAPI,
  StripeAPI,
  StripeSetupIntent,
  responseIsException,
  OscarAPI,
  PatientAPI,
  ProductAvailability,
  SubscriptionPriceAPI,
  TinyProductWithImages,
  PatientQuestionnaire,
  type Category,
  createAsync,
  type Async,
  RouterMethods,
  Product,
  type BasketMembershipPricing
} from "@mh/api";
import { useBasket, BasketItems, getFixedDiscount } from "@mh/basket";
import {
  Await,
  DisplayHeading,
  FreeDeliveryBanner,
  OrderDiscountBanner,
  Heading,
  PriceDisplay,
  ShippingAddress,
  Toast
} from "@mh/components";
import { Sentry, sendDataLayer } from "@mh/core";
import {
  getRecommendedProductsWithPrices,
  OTCProducts
} from "@mh/otc-products";

import Payment from "./Payment";

/**
 * Collates all availabilities for products in a list
 *
 * @param products Products to load availabilities for
 * @returns A mapping of product IDs to their availability
 *  */
const loadAllProductsAvailability = (
  products: TinyProductWithImages[]
): Promise<Record<number, ProductAvailability>> =>
  Promise.all(
    products.map((product) =>
      OscarAPI.getProductAvailability(product.id).then((availability) => ({
        [product.id]: availability
      }))
    )
  ).then((mapping) =>
    mapping.reduce((current, mapping) => ({ ...current, ...mapping }), {})
  );

const Checkout: FC = () => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const stripeSetupIntent = useAsync(StripeAPI.getSetupIntent);
  const basket = useBasket();
  const { shippingMethods } = basket;

  const [routerMethods, setRouterMethods] = useState<RouterMethods | null>(
    null
  );
  // Temporary error message when a payment fails
  const [error, setError] = useState<string | null>(null);
  const [isApplyingDiscount, setIsApplyingDiscount] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
  const [discountError, setDiscountError] = useState<string | null>(null);
  const [availabilities, setAvailabilities] = useState<
    Record<number, ProductAvailability>
  >({});
  const [lineIdToOrderOnDemand, setLineIdToOrderOnDemand] = useState<
    { [lineId: number]: boolean } | undefined
  >(undefined);
  const [questionnaire, setQuestionnaire] = useState<PatientQuestionnaire>();
  /**
   * The category this treatment uses. Assumed at most one treatment per basket.
   * If no basket lines exist linked to a questionnaire, the resulting async's value will be null
   */
  const [questionnaireCategory, setQuestionnaireCategory] = useState<
    Async<Category | null>
  >(createAsync());

  const [isMembershipSelected, setIsMembershipSelected] =
    useState<boolean>(false);
  const [membershipFee, setMembershipFee] = useState<string>("");
  const [membershipProduct, setMembershipProduct] = useState<
    Product | undefined
  >();
  const [membershipPricing, setMembershipPricing] = useState<
    BasketMembershipPricing | undefined
  >();
  /** when pt edit shipping address, prevent payment, but should show the message */
  const [isShippingEditStatus, setIsShippingEditStatus] =
    useState<boolean>(false);

  /** Are any basket line's products out of stock? */
  const hasOutOfStockItem = useMemo<boolean>(
    () =>
      Object.values(availabilities).some(
        (availability) => !availability.is_available_to_buy
      ),
    [availabilities]
  );

  const fetchMembershipFee = async () => {
    const membershipProducts = await OscarAPI.getMembershipProducts();
    const monthlyMembership = membershipProducts.find(
      (product) => product.slug === "membership-fee-monthly"
    );
    if (monthlyMembership) {
      setMembershipProduct(monthlyMembership);
      const price = await OscarAPI.getProductPrice(monthlyMembership.id);
      setMembershipFee(price.incl_tax);
    }
  };

  const fetchMembershipPricing = async (basketId: number) => {
    try {
      if (basketId !== undefined) {
        const response = await BasketAPI.getMembershipPricing(basketId);
        if (response) {
          setMembershipPricing(response);
        }
      }
    } catch (e) {
      Toast.error("Failed to calculate member discounts");
    }
  };

  useEffect(() => {
    const setInitialLineIdToOrderOnDemand = async (): Promise<void> => {
      if (asyncIsSuccess(basket.basket.async)) {
        const lineIdToOrderOnDemandFromBe: { [lineId: number]: boolean } =
          await SubscriptionPriceAPI.getOrderOnDemand(
            basket.basket.async.data.id
          );
        setLineIdToOrderOnDemand(lineIdToOrderOnDemandFromBe);
      }
    };

    if (asyncIsSuccess(basket.basket.async) && !isFirstLoad) {
      setIsFirstLoad(true);
      setInitialLineIdToOrderOnDemand();
    }
  }, [basket.basket.async]);

  useEffect(() => {
    if (
      asyncIsSuccess(stripeSetupIntent.async) &&
      responseIsException(stripeSetupIntent.async.data)
    ) {
      // Don't show the checkout if Stripe wasn't initialised properly
      navigate("/");
    }
  }, [stripeSetupIntent.async, navigate]);

  useEffect(() => {
    const fetchQuestionnaire = async (questionnaireId: string) => {
      const questionnaire =
        await PatientAPI.getPatientQuestionnaire(questionnaireId);
      setQuestionnaire(questionnaire);

      /** Load the category for this questionnaire */
      try {
        const category = await OscarAPI.getCategory(questionnaire.category.id);
        setQuestionnaireCategory(createAsync(category));
      } catch (e) {
        setQuestionnaireCategory({ state: "failed" });
      }
    };

    if (!asyncIsSuccess(basket.lines)) {
      return;
    }

    if (basket.lines.data.length === 0) {
      // If there are no items in the basket, redirect to the homepage
      navigate("/");
    }

    const questionnaireIdAttribute = basket.lines.data
      .find((line) =>
        line.attributes.find(
          (attribute) => attribute.option_code === "questionnaire_id"
        )
      )
      ?.attributes.find(
        (attribute) => attribute.option_code === "questionnaire_id"
      );
    if (questionnaireIdAttribute && questionnaireIdAttribute.value) {
      fetchQuestionnaire(questionnaireIdAttribute.value);
    } else {
      // There are no basket lines linked to a questionnaire, so set the resulting questionnaire category to null
      setQuestionnaireCategory(createAsync(null));
    }
    CheckoutAPI.getRouterMethods().then((routerMethods) =>
      setRouterMethods(routerMethods)
    );
    if (membershipProduct && asyncIsSuccess(basket.basket.async)) {
      fetchMembershipPricing(basket.basket.async.data.id);
    }
  }, [basket.lines, navigate]);

  useEffect(() => {
    if (asyncIsSuccess(basket.basket.async)) {
      fetchMembershipPricing(basket.basket.async.data.id);
    }
  }, []);

  useEffect(() => {
    /**
     * Loads the availabilities of all products in the basket's lines
     * @param products Products to map to availabilities
     */
    const reloadAvailabities = async (
      products: TinyProductWithImages[]
    ): Promise<void> => {
      const availabilities = await loadAllProductsAvailability(products);
      setAvailabilities(availabilities);
    };

    // Reload availabilities when basket lines change
    if (asyncIsSuccess(basket.lines)) {
      reloadAvailabities(basket.lines.data.map((line) => line.product));
    }
  }, [basket.lines]);

  useEffect(() => {
    // Clear the error message after a while
    if (error) setTimeout(() => setError(null), 3000);
  }, [error]);

  useEffect(() => {
    if (discountError !== null) {
      setTimeout(() => setDiscountError(null), 5000);
    }
  }, [discountError]);

  useEffect(() => {
    if (
      asyncIsSuccess(basket.lines) &&
      basket.lines.data.length > 0 &&
      asyncIsSuccess(shippingMethods) &&
      shippingMethods.data.length > 0 &&
      shippingMethods.data[0]?.price.incl_tax
    ) {
      const medicationInfo = basket.lines.data.find(
        (line) => line.product.product_class?.slug !== "otc"
      );
      if (medicationInfo?.product?.title && medicationInfo?.price_incl_tax) {
        try {
          sendDataLayer("viewedPrice", {
            medicationName: medicationInfo.product.title,
            priceDisplayed: medicationInfo.price_incl_tax,
            shippingPrice: shippingMethods.data[0].price.incl_tax
          });
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    }
  }, [basket.lines, shippingMethods]);

  useEffect(() => {
    fetchMembershipFee();
  }, []);

  const handleDiscountCodeApplied = async (code: string): Promise<void> => {
    setIsApplyingDiscount(true);
    if (asyncIsSuccess(basket.basket.async)) {
      const voucherResponse = await BasketAPI.addVoucher({ vouchercode: code });

      if (voucherResponse.isFailure) {
        setDiscountError(voucherResponse.failure);
      }
      fetchMembershipPricing(basket.basket.async.data.id);
      basket.basket.refresh();
    }
    setIsApplyingDiscount(false);
  };

  /**
   * Submits the checkout after the customer setup has been completed.
   *
   * Refresh the basket context after a succesful order has been placed
   *
   * @param setupIntent The resolved setup intent object from the Payment component
   */
  const handleCheckout = async (setupIntent: SetupIntent): Promise<void> => {
    if (
      !asyncIsSuccess(basket.basket.async) ||
      !asyncIsSuccess(shippingMethods) ||
      !asyncIsSuccess(stripeSetupIntent.async)
    ) {
      throw new Error(
        "Basket, shipping methods or setup intent have not loaded. The checkout cannot continue."
      );
    }
    if (isMembershipSelected) {
      try {
        await PatientAPI.createUserMembership();
      } catch (e) {
        if (e instanceof Response && e.status === 402) {
          Toast.error(
            "Sorry, we were unable to process your payment using the details we have on file. Please enter your card details below and try again."
          );
        } else {
          Toast.error(
            "Sorry, there was an error signing up to hubPass. Please try again. [Error 5014]"
          );
        }
        stripeSetupIntent.refresh();
        throw new Error("Checkout error");
      }
    }
    try {
      const orderinfo = await CheckoutAPI.submitCheckoutOrder({
        basket: basket.basket.async.data.url,
        // Yes, we are aware that passing the setup intent BACK sucks. It's used to set the resolved setup intent's
        // payment method as the default one on a customer. Otherwise we'd have to listen on webhooks for a resolved
        // setup intent but that wouldn't work on staging or local environments.
        setup_intent_id: setupIntent.id
      });
      if (orderinfo?.number) {
        try {
          sendDataLayer("purchase", {
            questionnaire: questionnaire,
            basket: basket.basket.async.data,
            // @ts-ignore
            lines: basket.lines.data,
            // @ts-ignore
            shipping: Array.isArray(basket.shippingMethods.data)
              ? // @ts-ignore
                basket.shippingMethods.data[0]
              : // @ts-ignore
                basket.shippingMethods.data,
            order: { id: orderinfo?.number }
          });
        } catch (e) {
          Sentry.captureException(e);
          Sentry.captureMessage(
            // @ts-ignore
            `[purcahse event datalayer exception] : ${e?.message}`,
            (scope) => {
              scope.setExtra("initial_questionnaire_id", questionnaire?.id);
              return scope;
            }
          );
        }
      }
    } catch (e) {
      Sentry?.captureException(e);
      if (e instanceof Response && e.status === 402) {
        Toast.error(
          "Sorry, we were unable to process your payment using the details we have on file. Please enter your card details below and try again."
        );
        // future stories to be created to distinguish different types of error messages for payment failures
      } else {
        // orderResult will return Unauthorized when product is still remained after checking out and click checkout again
        Toast.error(
          "Sorry, there was an error processing your order. Please try again. [Error 5015]"
        );
      }
      stripeSetupIntent.refresh();
      throw new Error("Checkout error");
    }

    // Update the basket since it will have been cleared upon checkout submission
    basket.basket.refresh();

    Toast.success("Payment confirmed");

    let orderOnDemandError = "";
    lineIdToOrderOnDemand &&
      (await Promise.all(
        Object.entries(lineIdToOrderOnDemand).map(
          async ([lineId, orderOnDemandValue]) => {
            if (asyncIsSuccess(basket.basket.async)) {
              const success = await SubscriptionPriceAPI.setOrderOnDemand(
                basket.basket.async.data.id,
                parseInt(lineId),
                orderOnDemandValue
              );
              if (!success) {
                orderOnDemandError =
                  "Order was successful but there was an error processing 'Automatically send repeats'. Please change it in Manage my treatment";
              }
            }
          }
        )
      ));

    if (orderOnDemandError) {
      setError(orderOnDemandError);
      return;
    }

    // If the checkout succeeds, then the user should be moved to the treatments page
    navigate("/");
  };

  // The availabilities object is empty until the product availabilities have been loaded
  const availabilitiesHaveResolved = Object.keys(availabilities).length > 0;

  const orderDiscount =
    asyncIsSuccess(questionnaireCategory) && questionnaireCategory.data !== null
      ? getFixedDiscount(questionnaireCategory.data)
      : undefined;

  let banner: JSX.Element | null = null;

  if (orderDiscount) {
    // Show the specific discount banner if there is a discount
    banner = <OrderDiscountBanner discount={`${orderDiscount}%`} />;
  }

  if (
    asyncIsSuccess(questionnaireCategory) &&
    questionnaireCategory.data !== null &&
    !orderDiscount
  ) {
    // If the category has loaded but doesn't have a discount, show the free delivery banner
    banner = <FreeDeliveryBanner />;
  }

  return (
    <div css={{ display: "flex", flexFlow: "column", gap: "24px" }}>
      <DisplayHeading>Checkout</DisplayHeading>
      <Await<[ShippingMethod[], Basket]>
        asyncs={[shippingMethods, basket.basket.async]}
      >
        {([shippingMethods, basket]) => {
          const basketPrice = parseFloat(basket.total_incl_tax);

          let shippingPrice = 0;

          if (shippingMethods.length >= 0) {
            // Select maximum shipping price if shipping methods have actually loaded
            shippingPrice = Math.max(
              ...shippingMethods.map((method) =>
                parseFloat(method.price.incl_tax)
              )
            );
          }

          let totalPrice = 0;
          if (isMembershipSelected) {
            totalPrice =
              parseFloat(
                membershipPricing?.with_membership.basket.total_incl_tax ?? "0"
              ) + parseFloat(membershipFee ?? "0");
            shippingPrice = 0;
          } else {
            totalPrice = basketPrice + shippingPrice;
          }

          const memberSaving =
            membershipPricing?.savings_due_to_membership || "0.00";

          return (
            <div
              css={(theme) => ({
                display: "flex",
                flexFlow: "column",
                gap: "32px",
                [theme.mq.md]: {
                  flexFlow: "row"
                }
              })}
            >
              {/* Left column */}
              <div
                css={{
                  flex: 1,
                  display: "flex",
                  flexFlow: "column",
                  gap: "24px"
                }}
              >
                <Heading>Order overview</Heading>
                {hasOutOfStockItem && (
                  <h3 className="fw-bold mb-3 red">
                    There are items in your cart that are out of stock
                  </h3>
                )}
                <BasketItems
                  productAvailabilities={availabilities}
                  lineIdToOrderOnDemand={lineIdToOrderOnDemand}
                  setLineIdToOrderOnDemand={setLineIdToOrderOnDemand}
                  onQuantityChangeError={(i, q, e) => console.debug(e.message)}
                  routerMethods={routerMethods}
                />
              </div>
              {/* Right column */}
              <div
                css={{
                  flex: 1,
                  display: "flex",
                  flexFlow: "column",
                  gap: "24px"
                }}
              >
                {/* Error box */}
                {error && (
                  <b css={{ color: "red", fontWeight: "bold" }}>{error}</b>
                )}

                <ShippingAddress
                  compactView
                  isEditingCallback={setIsShippingEditStatus}
                  autoSave={true}
                />
                <PriceDisplay
                  subtotal={parseFloat(basket.total_incl_tax_excl_discounts)}
                  tax={parseFloat(basket.total_tax)}
                  total={totalPrice}
                  shipping={shippingPrice}
                  onDiscountCodeApplied={handleDiscountCodeApplied}
                  voucherDiscounts={basket.voucher_discounts}
                  isApplyingDiscount={isApplyingDiscount}
                  discountError={discountError}
                  membershipFee={membershipFee}
                  memberSaving={memberSaving}
                  displayMembershipPricing={true}
                  isMembershipSelected={isMembershipSelected}
                  onMembershipSelectChange={setIsMembershipSelected}
                />

                <Await<[StripeSetupIntent]>
                  // @ts-ignore
                  asyncs={[stripeSetupIntent.async, questionnaireCategory]}
                >
                  {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                  {([{ client_secret, last_4, exp_month, exp_year }]) => (
                    <Elements
                      stripe={stripe}
                      options={{ clientSecret: client_secret }}
                    >
                      <Payment
                        clientSecret={client_secret}
                        defaultCard={
                          last_4 && exp_month && exp_year
                            ? {
                                last4: last_4,
                                expiryMonth: exp_month,
                                expiryYear: exp_year
                              }
                            : undefined
                        }
                        disabled={
                          basket.lines.length === 0 ||
                          !availabilitiesHaveResolved ||
                          hasOutOfStockItem
                        }
                        onCheckout={handleCheckout}
                        totalPrice={totalPrice}
                        isShippingEditStatus={isShippingEditStatus}
                      />
                    </Elements>
                  )}
                </Await>
                {banner}
              </div>
            </div>
          );
        }}
      </Await>
      {questionnaire && (
        <OTCProducts
          loadProducts={() =>
            // @ts-ignore
            getRecommendedProductsWithPrices([questionnaire.category.id])
          }
          scrollable
        />
      )}
    </div>
  );
};

export default Checkout;
