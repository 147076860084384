import type { ChangeEvent } from "react";
import {
  Button,
  IconAddToCart,
  IconCheckCircle,
  IconMinus,
  IconPlus,
  IconRemoveFromCart,
  Spinner
} from "@mh/components";

import "./styles.scss";

interface Props {
  quantity: number;
  /** Fired when the selected quantity changes */
  onQuantityChange: (quantity: number) => void;
  /** Used to limit the max quantity */
  maxQuantity: number;
  totalPrice?: string;
  /**
   * Event to fire when the information button is pressed.
   * If this prop is undefined, no information button will render.
   */
  onInfoPressed?: () => void;
  handleCartButtonClick: () => void;
  isLoading: boolean;
  isAdded: boolean;
}

interface AdjustButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

function QuantityWithPrice({
  quantity,
  onQuantityChange,
  totalPrice,
  maxQuantity,
  handleCartButtonClick,
  isLoading,
  isAdded
}: Props) {
  /** Fires when the quantity value has changed
   * @param e The change event
   */
  function handleQuantityChange(e: ChangeEvent<HTMLInputElement>): void {
    const value = parseInt(e.target.value, 10);
    if (value) {
      onQuantityChange(value);
    }
  }

  function handleIncrease(): void {
    if (quantity < maxQuantity) {
      onQuantityChange(quantity + 1);
    }
  }

  function handleDecrease(): void {
    if (quantity > 1) {
      onQuantityChange(quantity - 1);
    }
  }

  // Products and prices are loaded in separate requests, so there is an
  // unlikely race condition where a product is deleted from the db between
  // these two requests, and we can't fetch a price.
  // If this happens (or if we can't load the price for any other reason)
  // just display no price.
  const priceString = totalPrice ? `$${totalPrice}` : "";

  const AdjustButton = ({ children, onClick }: AdjustButtonProps) => (
    <Button
      css={{
        backgroundColor: "#F0F0F0",
        borderRadius: "50%",
        border: "none",
        width: "20px",
        height: "20px",
        lineHeight: "20px",
        display: "flex",
        padding: "3.637px",
        justifyContent: "center",
        alignItems: "center",
        color: "#000000"
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );

  function renderBasket() {
    const IconComponent = isAdded ? IconRemoveFromCart : IconAddToCart;
    const buttonText = isAdded ? "Remove" : "Add to cart";
    return (
      <div css={{ marginLeft: "10px" }}>
        {isLoading ? (
          <div
            css={(theme) => ({
              [theme.mq.md]: {
                width: "45px"
              }
            })}
          >
            <Spinner size="md" />
          </div>
        ) : (
          <div data-testid="add-to-cart">
            <IconComponent
              onClick={handleCartButtonClick}
              css={(theme) => ({
                width: "22px",
                height: "22px",
                [theme.mq.md]: { display: "none" }
              })}
            />
            <Button
              variant="primary-outline"
              onClick={handleCartButtonClick}
              css={(theme) => ({
                display: "none",
                [theme.mq.md]: {
                  display: "block",
                  fontSize: "12px",
                  fontWeight: "500",
                  padding: "3px 8px",
                  background: "none",
                  ...(isAdded && { color: theme.color.danger, border: "none" })
                }
              })}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div
        css={{
          fontWeight: 500,
          borderBottom: "0.5px solid #D9D9D9",
          borderTop: "0.5px solid #D9D9D9",
          padding: "8px 0"
        }}
      >
        {priceString}
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          paddingTop: "8px",
          height: "35px"
        }}
      >
        {isAdded ? (
          <div css={{ display: "flex", gap: "8px" }}>
            <IconCheckCircle
              css={(theme) => ({
                width: "16px",
                height: "16px",
                color: theme.color.success
              })}
            />
            <span css={{ fontSize: "12px", fontWeight: "500" }}>
              Added to cart
            </span>
          </div>
        ) : (
          <div
            css={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: "8px",
              [theme.mq.md]: { width: "auto" }
            })}
          >
            <span css={{ fontWeight: "500", fontSize: "14px" }}>Qty</span>
            <AdjustButton onClick={handleDecrease}>
              <IconMinus
                css={{
                  width: "20px",
                  height: "20px"
                }}
              />
            </AdjustButton>
            <input
              type="number"
              value={quantity}
              className="noArrowInNumberInput"
              css={{
                width: "36px",
                height: "22px",
                border: "0.5px solid #ccc",
                borderRadius: "4px",
                textAlign: "center",
                padding: "0",
                fontSize: "16px",
                fontWeight: "500"
              }}
              onChange={handleQuantityChange}
            />
            <AdjustButton onClick={handleIncrease}>
              <IconPlus
                css={{
                  width: "20px",
                  height: "20px"
                }}
              />
            </AdjustButton>
          </div>
        )}
        <div>{renderBasket()}</div>
      </div>
    </div>
  );
}

export default QuantityWithPrice;
