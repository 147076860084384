import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { HOST } from "@mh/core";
import {
  Address,
  Button,
  IconArrowRight,
  IconHubPass,
  IconSuccess,
  PhoneInput,
  ShippingAddressValues,
  Toast
} from "@mh/components";
import {
  CreatePatientRequest,
  PatientDetails,
  PatientAPI,
  User
} from "@mh/api";
import { QuestionnaireDatePicker } from "../../components/questionnaire/QuestionnaireDatePicker";
import "./styles.scss";

type SignUpStep = "enter_email" | "enter_personal_details";

const initialPatient: PatientDetails = {
  firstName: "",
  lastName: "",
  dob: "",
  sex: "female",
  addressLine1: "",
  addressLine2: "",
  suburb: "",
  state: "",
  postcode: "",
  phone: ""
};

const termsLink = `https://${HOST}/terms-and-conditions/`;
const privacyLink = `https://${HOST}/privacy-policy/`;

const benefits: React.ReactNode[] = [
  <>
    <strong>FREE</strong> medical consultations
  </>,
  <>
    <strong>FREE</strong> express delivery on orders
  </>,
  <>Priority access to clinicians</>,
  <>Cancel anytime</>
];

export const HubPassSignUp = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [signUpStep, setSignUpStep] = useState<SignUpStep>("enter_email");
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [patient, setPatient] = useState<PatientDetails>(initialPatient);

  const handleFormFieldChange = (field: string, value: string) =>
    setPatient({ ...patient, [field]: value });

  const handleAddressChange = (address: ShippingAddressValues) => {
    setPatient({
      ...patient,
      addressLine1: address.line1,
      addressLine2: address.line2,
      suburb: address.suburb,
      state: address.state,
      postcode: address.postcode
    });
  };

  const isPatientDetailsFormValid = () =>
    patient.firstName &&
    patient.lastName &&
    patient.dob &&
    patient.addressLine1 &&
    patient.suburb &&
    patient.state &&
    patient.postcode &&
    patient.phone;

  const convertPatientToPayload = (
    patient: PatientDetails
  ): CreatePatientRequest => ({
    email,
    dob: patient.dob,
    first_name: patient.firstName,
    last_name: patient.lastName,
    sex_assigned_at_birth: patient.sex,
    address_line1: patient.addressLine1,
    address_line2: patient.addressLine2,
    suburb: patient.suburb,
    state: patient.state,
    postcode: patient.postcode,
    phone: patient.phone
  });

  const benefitList = (
    <div className="benefits-container">
      <ul className="benefits-ul">
        {benefits.map((item, index) => (
          <li key={index}>
            <IconSuccess width={16} height={16} className="icon" />
            {item}
          </li>
        ))}
      </ul>
    </div>
  );

  const welcome = (
    <div className="welcome">
      <div>Welcome to</div>
      <IconHubPass className="icon hubpass-icon" />
      {benefitList}
    </div>
  );

  const handlePatientDetailsSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    Toast.dismiss();
    setLoading(true);
    const payload = convertPatientToPayload(patient);
    const response = await PatientAPI.createPatient(payload);
    if (response.ok) {
      const jsonResponse = await response.json();
      User.login(jsonResponse.user_info);
      navigate("/purchase-hubpass");
    } else {
      Toast.error("Something went wrong, please try again [Error 4125]");
    }
    setLoading(false);
  };

  const loadingSpinner = (
    <span
      className="loadingouter position-relative"
      data-testid="spinner-testid"
    >
      <Spinner animation="border" className="loading" size="sm" />
    </span>
  );

  const renderButton = (
    text: string,
    disabled: boolean,
    isLoading: boolean
  ) => (
    <div className="button-container">
      <Button className="button" disabled={isLoading || disabled}>
        {isLoading ? (
          loadingSpinner
        ) : (
          <>
            {text} <IconArrowRight width={16} css={{ marginLeft: "12px" }} />
          </>
        )}
      </Button>
    </div>
  );

  const emailStep = (
    <div className="step">
      <form onSubmit={() => {}}>
        <div className="form-top">
          <div className="step-title">
            Step 1<span>/3</span>
          </div>
          <p className="instruction">
            To get started with hubPass, please enter your email address
          </p>
          <label className="email-label" htmlFor="email">
            Email
          </label>
          <input
            required
            id="email"
            type="email"
            placeholder="Enter your email"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {renderButton("Next", !email, loading)}
      </form>
    </div>
  );

  const personalDetailsStep = (
    <div className="step details">
      <form onSubmit={handlePatientDetailsSubmit}>
        <div className="form-top">
          <div className="step-title">
            Step 2<span>/3</span>
          </div>
          <p className="instruction">
            To get started with hubPass, please enter your email address
          </p>
          <label htmlFor="first-name">First Name</label>
          <input
            required
            id="first-name"
            className="input"
            placeholder="First Name"
            value={patient.firstName}
            onChange={(e) => handleFormFieldChange("firstName", e.target.value)}
          />
          <label htmlFor="last-name" css={{ marginTop: "16px" }}>
            Last Name
          </label>
          <input
            required
            id="last-name"
            className="input"
            placeholder="Last Name"
            value={patient.lastName}
            onChange={(e) => handleFormFieldChange("lastName", e.target.value)}
          />
          <div className="address">
            <Address
              onChange={handleAddressChange}
              value={{
                line1: patient.addressLine1,
                line2: patient.addressLine2,
                suburb: patient.suburb,
                state: patient.state,
                postcode: patient.postcode
              }}
            />
          </div>
          <label css={{ marginTop: "16px" }}>Date of birth</label>
          <QuestionnaireDatePicker
            className="dob"
            info={{
              dob_year: patient.dob.split("-")[0],
              dob_month: patient.dob.split("-")[1],
              dob_day: patient.dob.split("-")[2],
              onChange: (value) => handleFormFieldChange("dob", value)
            }}
            currentQuestion={{ type: "dob" }}
          />
          <label css={{ marginTop: "16px" }}>Sex assigned at birth</label>
          <div className="gender-selection">
            <button
              type="button"
              className={`gender-button ${
                patient.sex === "male" ? "selected" : ""
              }`}
              onClick={() => handleFormFieldChange("sex", "male")}
            >
              Male
            </button>
            <button
              type="button"
              className={`gender-button ${
                patient.sex === "female" ? "selected" : ""
              }`}
              onClick={() => handleFormFieldChange("sex", "female")}
              style={{ marginLeft: "16px" }}
            >
              Female
            </button>
          </div>
          <label css={{ marginTop: "16px" }}>Mobile number</label>
          <PhoneInput
            className="phone-input"
            onChange={(phone) => handleFormFieldChange("phone", phone || "")}
          />
          <div className="terms">
            By clicking “Create account” you agree to our{" "}
            <a href={termsLink} rel="noreferrer" target="_blank">
              Terms & Conditions
            </a>
            ,{" "}
            <a href={privacyLink} rel="noreferrer" target="_blank">
              Privacy Policy
            </a>{" "}
            and confirm you are over the age of 18.
          </div>
        </div>
        {renderButton(
          "Proceed to Payment",
          !isPatientDetailsFormValid(),
          loading
        )}
      </form>
    </div>
  );

  const renderForm = () => {
    const forms: Record<SignUpStep, React.ReactNode> = {
      enter_email: emailStep,
      enter_personal_details: personalDetailsStep
    };
    return forms[signUpStep];
  };

  return (
    <div className="hubpass-signup">
      {welcome}
      {renderForm()}
    </div>
  );
};
