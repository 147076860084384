import { ComponentPropsWithoutRef } from "react";

export const IconCircleSave = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.319336" width="32" height="32" rx="16" fill="currentColor" />
    <path
      d="M22.8438 11.4756C23.0312 11.6631 23.0312 12.0068 22.8438 12.1943L14.3438 20.6943C14.1562 20.8818 13.8125 20.8818 13.625 20.6943L9.125 16.1943C8.9375 16.0068 8.9375 15.6631 9.125 15.4756C9.3125 15.2881 9.65625 15.2881 9.84375 15.4756L14 19.6318L22.125 11.4756C22.3125 11.2881 22.6562 11.2881 22.8438 11.4756Z"
      fill="#F8F8F8"
    />
  </svg>
);
