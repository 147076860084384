import { CheckoutAPI, useAsync, Order, PaginatedResponse } from "@mh/api";
import { Await, Heading } from "@mh/components";

import { HistoryItem } from "./HistoryItem";

export const History = (): JSX.Element => {
  const orders = useAsync(CheckoutAPI.getOrders);

  return (
    <div className="my-history page">
      <div className="content">
        <div className="row">
          <Await<[PaginatedResponse<Order>]> asyncs={[orders.async]}>
            {([orders]) =>
              orders.count > 0 ? (
                <div css={{ display: "flex", flexFlow: "column", gap: "32px" }}>
                  {orders.results.map((order: Order) => (
                    <HistoryItem order={order} key={order.number} />
                  ))}
                </div>
              ) : (
                <div css={{ display: "flex", justifyContent: "space-around" }}>
                  <Heading>
                    You have no past orders. When you do, they will appear here.
                  </Heading>
                </div>
              )
            }
          </Await>
        </div>
      </div>
    </div>
  );
};
