import { useState } from "react";
import { Button } from "@mh/components";
import {
  uploadImage,
  isActiveRenewalQuestionnaire,
  isPatientQuestionnaireRepeat
} from "@mh/api";
import type {
  CategoryImageRequirement,
  PatientQuestionnaire,
  PatientQuestionnaireRepeat
} from "@mh/api";
import { UploadImagesModal } from "../UploadImagesModal";
import { Status } from "./Status";

interface ImageUploadStatusProps {
  patientId: number;
  categoryImageRequirements: CategoryImageRequirement[];
  initialQuestionnaire: PatientQuestionnaire;
  questionnaire: PatientQuestionnaire | PatientQuestionnaireRepeat;
  isImageRequestFromDoctor: boolean;
}

async function uploadImageHandler(
  selectedImages: Record<number, [File[], CategoryImageRequirement]>,
  patientId: number,
  questionnaireId: number,
  isImageRequestFromDoctor: boolean
): Promise<Set<number>> {
  const imagesErrors = new Set<number>();
  if (selectedImages) {
    const uploads = Object.entries(selectedImages).map(
      async ([_, [files, requirement]]) => {
        imagesErrors.add(requirement.id);
        const uploadedImages = Array.from(files).map(async (f) => {
          return uploadImage(
            patientId,
            questionnaireId,
            requirement.name,
            requirement?.id,
            f
          );
        });
        return await Promise.all(uploadedImages);
      }
    );
    (await Promise.all(uploads)).forEach((asyncResult) => {
      asyncResult.forEach((result) => {
        if (result?.image) {
          imagesErrors.delete(
            isImageRequestFromDoctor ? 0 : result.category_image_requirement
          );
        }
      });
    });
  } else {
    imagesErrors.add(-1);
  }
  return imagesErrors;
}

export const ImageUploadStatus = ({
  patientId,
  categoryImageRequirements,
  initialQuestionnaire,
  questionnaire,
  isImageRequestFromDoctor
}: ImageUploadStatusProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Status
      actions={
        <>
          <Button variant="primary" onClick={() => setShowModal(true)}>
            Upload images
          </Button>
        </>
      }
      title="IMAGES REQUIRED"
      titleVariant="danger"
    >
      <p>
        Please upload images to continue with your consultation. The images you
        share are confidential between you and your assigned clinician and are
        used only to assess your needs and provide a personalised treatment
        plan.
      </p>
      {showModal && (
        <UploadImagesModal
          imageRequirements={
            isImageRequestFromDoctor ? undefined : categoryImageRequirements
          }
          imageRequestInstructions={
            isImageRequestFromDoctor
              ? questionnaire?.consultation?.image_request_instructions
              : undefined
          }
          onHide={() => {
            setShowModal(false);
          }}
          onSubmit={(result) =>
            uploadImageHandler(
              result,
              patientId,
              initialQuestionnaire.id,
              isImageRequestFromDoctor
            )
          }
          show={showModal}
          isRepeat={
            isPatientQuestionnaireRepeat(questionnaire) &&
            isActiveRenewalQuestionnaire(questionnaire)
          }
        />
      )}
    </Status>
  );
};
