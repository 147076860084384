import { Fragment, useState } from "react";
import { Form } from "react-bootstrap";
import type {
  Basket,
  BasketLine,
  Bundle,
  Price,
  UserMembership,
  VoucherDiscount
} from "@mh/api";
import { DiscountCode, IconCaretRight, SafeImg } from "@mh/components";
import { LineSummary } from "./LineSummary";
import { MembershipPromo } from "./MembershipPromo";

interface BasketSummaryProps {
  /** The basket to show */
  basket: Basket;
  /** A bundle these products are being ordered through */
  bundle: Bundle | null;
  /** Basket lines */
  lines: BasketLine[];
  /** Shipping details */
  shipping: {
    /** Shipping method name that is being used */
    methodName: string;
    /** Shipping method price - this may have discounts taken into account */
    price: number;
    /** The discount applied to a regular shipping price */
    discount: number;
  };
  /** Details about hubPass membership and pricing */
  membership: {
    /** Is the patient currently a hubPass member? */
    isMember: boolean;
    /** A currently active hubPass membership, if the patient is a member */
    userMembership: UserMembership | null;
    /** The pricing of hubPass membership for the basket, to see if any discounts apply */
    saving: number;
    /** The pricing of hubPass membership itself */
    price: Price;
  };
  /** Whether the user membership checkbox is selected or not */
  isMembershipSelected: boolean;
  /** Mapping of line IDs to whether their subscription (if one exists) is order on demand */
  orderOnDemand: Record<number, boolean>;
  /** Callback to update whether the user membership is selected */
  onIsMembershipSelectedChange: (isSelected: boolean) => void;
  /** Callback to update the order on demand mapping */
  onOrderOnDemandChange: (value: Record<number, boolean>) => void;
  /** related to promo code */
  onDiscountCodeApplied: (code: string) => void;
  voucherDiscounts: VoucherDiscount[];
  isApplyingDiscount: boolean;
  discountError: string | null;
}

/**
 * Shows a pricing summary of a basket, its lines and their prices.
 *
 * It can optionally show membership discounts.
 */
export const BasketSummary = ({
  basket,
  bundle,
  lines,
  shipping,
  membership,
  isMembershipSelected,
  orderOnDemand,
  onIsMembershipSelectedChange,
  onOrderOnDemandChange,
  onDiscountCodeApplied,
  isApplyingDiscount,
  discountError
}: BasketSummaryProps) => {
  /** Is the discount code row expanded? */
  const [hasExpandedDiscountCode, setHasExpandedDiscountCode] =
    useState<boolean>(false);

  const handleOrderOnDemandChecked = (lineId: number, checked: boolean) => {
    onOrderOnDemandChange({ ...orderOnDemand, [lineId]: checked });
  };

  /** If the user is a hubPass member */
  const isMember = membership.userMembership?.status === "active";

  /** The price of all basket lines, with any product discounts applied */
  const subtotal = parseFloat(basket.total_incl_tax);

  /** Basket lines containing a medicine product */
  const medicineLines = lines.filter(
    (line) => line.product.product_class.slug === "medicine"
  );

  /** Basket lines not containing a medicine product */
  const otherLines = lines.filter(
    (line) => line.product.product_class.slug !== "medicine"
  );

  /** Has shipping been discounted? */
  const isShippingDiscounted = shipping.discount > 0;

  /** Price before discounts */
  const originalShippingPrice = shipping.price + shipping.discount;

  return (
    <div
      css={(theme) => ({
        display: "flex",
        flexFlow: "column nowrap",
        gap: "16px",
        borderRadius: "8px",
        paddingTop: "16px",
        paddingBottom: "16px",
        backgroundColor: theme.color.backgroundFaint
      })}
    >
      <div
        css={{
          display: "flex",
          flexFlow: "column",
          borderRadius: "4px",
          gap: "16px"
        }}
      >
        {bundle && (
          <div
            css={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              [theme.mq.md]: {
                paddingLeft: "24px",
                paddingRight: "24px"
              },
              "& > img": {
                height: "50px",
                width: "50px",
                borderRadius: "8px"
              }
            })}
          >
            <SafeImg imagePath={bundle.image} />
            <b>{bundle.name}</b>
            <h3 css={{ fontWeight: "bold" }}>
              {Intl.NumberFormat("en-AU", {
                style: "currency",
                currency: "AUD"
              }).format(subtotal)}
            </h3>
          </div>
        )}
        {medicineLines.map((line) => {
          const shouldToggleOrderOnDemand = line.id in orderOnDemand;
          return (
            <Fragment key={line.id}>
              <LineSummary
                line={line}
                orderOnDemand={
                  shouldToggleOrderOnDemand
                    ? {
                        checked: orderOnDemand[line.id],
                        setChecked: (value) => {
                          handleOrderOnDemandChecked(line.id, value);
                        }
                      }
                    : undefined
                }
              />
            </Fragment>
          );
        })}
        <hr />
        {otherLines.map((line) => (
          <LineSummary key={line.id} line={line} />
        ))}
      </div>
      {!isMember && (
        <>
          <div
            css={(theme) => ({
              paddingLeft: "16px",
              paddingRight: "16px",
              ".active > div:first-child": {
                position: "relative",
                "input[type=radio]": {
                  backgroundColor: "white",
                  boxShadow: "none"
                },
                "&:after": {
                  content: '""',
                  position: "absolute",
                  display: "inline-block",
                  width: "12px",
                  height: "12px",
                  left: "50%",
                  top: "50%",
                  backgroundColor: theme.color.primary,
                  borderRadius: "80px",
                  transform: "translate(-50%, -50%)"
                }
              }
            })}
          >
            <MembershipPromo
              saving={membership.saving}
              price={membership.price}
              isMembershipChecked={isMembershipSelected}
              onMembershipChecked={onIsMembershipSelectedChange}
            />
          </div>
          <div
            css={(theme) => ({
              paddingLeft: "16px",
              paddingRight: "16px",
              ".active > div:first-child": {
                position: "relative",
                "input[type=radio]": {
                  backgroundColor: "white",
                  boxShadow: "none"
                },
                "&:after": {
                  content: '""',
                  position: "absolute",
                  display: "inline-block",
                  width: "12px",
                  height: "12px",
                  left: "50%",
                  top: "25%",
                  backgroundColor: theme.color.primary,
                  borderRadius: "80px",
                  transform: "translate(-50%, -50%)",
                  [theme.mq.md]: {
                    top: "50%"
                  }
                }
              }
            })}
          >
            <div
              className={!isMembershipSelected ? "active" : ""}
              css={{
                display: "flex",
                gap: "8px",
                fontWeight: "600",
                paddingLeft: "16px",
                paddingRight: "16px"
              }}
            >
              <Form.Check
                type="radio"
                checked={!isMembershipSelected}
                onChange={() => {
                  onIsMembershipSelectedChange(false);
                }}
              />
              <span>Proceed without hubPass member savings</span>
            </div>
          </div>
          <hr />
        </>
      )}
      <div
        css={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          fontSize: "14px",
          paddingLeft: "16px",
          paddingRight: "16px",
          [theme.mq.md]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            fontSize: "16px"
          }
        })}
      >
        <span>
          Subtotal ({lines.length} item{lines.length !== 1 && "s"})
        </span>
        <span
          css={{
            fontWeight: "600"
          }}
        >
          {Intl.NumberFormat("en-AU", {
            style: "currency",
            currency: basket.currency ?? undefined
          }).format(subtotal)}
        </span>
      </div>
      <div
        css={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          fontSize: "14px",
          paddingLeft: "16px",
          paddingRight: "16px",
          [theme.mq.md]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            fontSize: "16px"
          }
        })}
      >
        <span>{shipping.methodName}</span>
        <div css={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {isShippingDiscounted && (
            <span css={{ color: "#888888", textDecoration: "line-through" }}>
              {Intl.NumberFormat("en-AU", {
                style: "currency",
                currency: basket.currency ?? undefined
              }).format(originalShippingPrice)}
            </span>
          )}
          <span
            css={{
              fontWeight: "600"
            }}
          >
            {Intl.NumberFormat("en-AU", {
              style: "currency",
              currency: basket.currency ?? undefined
            }).format(shipping.price)}
          </span>
        </div>
      </div>
      <hr />
      <div
        css={(theme) => ({
          display: "flex",
          flexFlow: "column",
          gap: "16px",
          width: "100%",
          paddingLeft: "16px",
          paddingRight: "16px",
          [theme.mq.md]: {
            paddingLeft: "24px",
            paddingRight: "24px",
            fontSize: "16px"
          }
        })}
      >
        <div
          css={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            fontSize: "14px",
            fontWeight: "500",
            [theme.mq.md]: {
              fontSize: "16px"
            }
          })}
        >
          <span css={{ color: "#888888" }}>
            Add a promo code or a gift card
          </span>
          <IconCaretRight
            css={{
              height: "16px",
              width: "16px",
              cursor: "pointer",
              transition: "rotate 100ms linear",
              rotate: hasExpandedDiscountCode ? "270deg" : "90deg"
            }}
            onClick={() =>
              setHasExpandedDiscountCode((isExpanded) => !isExpanded)
            }
          />
        </div>
        <div
          css={(theme) => ({
            display: "flex",
            alignItems: "center",
            height: hasExpandedDiscountCode ? "64px" : "0px",
            width: "100%",
            overflow: "hidden",
            transition: "height 150ms linear",
            fontSize: "14px",
            [theme.mq.md]: {
              fontSize: "16px"
            },
            button: {
              padding: "8px 16px",
              minWidth: "114px",
              marginLeft: "0px",
              borderRadius: "4px"
            },
            input: {
              marginRight: "0px !important",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              "&:hover, &:active, &:focus": {
                border: "unset",
                boxShadow: "unset",
                background: "transparent",
                transition: "unset"
              }
            }
          })}
        >
          {/* This doesn't match the design exactly */}
          <DiscountCode
            placeHolder="Promo Code"
            voucherDiscounts={basket.voucher_discounts}
            isNewCheckout={true}
            onApplied={onDiscountCodeApplied}
            isApplyingDiscount={isApplyingDiscount}
            discountError={discountError}
          />
        </div>
      </div>
    </div>
  );
};
