import type { ComponentPropsWithoutRef, FC } from "react";

const IconMasterCard: FC<ComponentPropsWithoutRef<"svg">> = (props) => (
  <svg
    width="50"
    height="34"
    viewBox="0 0 50 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_527_17599)">
      <path
        d="M45.8333 0H4.16667C1.86548 0 0 1.89879 0 4.24107V29.6875C0 32.0298 1.86548 33.9286 4.16667 33.9286H45.8333C48.1345 33.9286 50 32.0298 50 29.6875V4.24107C50 1.89879 48.1345 0 45.8333 0Z"
        fill="#252525"
      />
      <path
        d="M18.7507 27.567C24.5036 27.567 29.1673 22.82 29.1673 16.9643C29.1673 11.1086 24.5036 6.3616 18.7507 6.3616C12.9977 6.3616 8.33398 11.1086 8.33398 16.9643C8.33398 22.82 12.9977 27.567 18.7507 27.567Z"
        fill="#EB001B"
      />
      <path
        d="M31.2507 27.567C37.0036 27.567 41.6673 22.82 41.6673 16.9643C41.6673 11.1086 37.0036 6.3616 31.2507 6.3616C25.4977 6.3616 20.834 11.1086 20.834 16.9643C20.834 22.82 25.4977 27.567 31.2507 27.567Z"
        fill="#F79E1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0007 8.48137C27.5307 10.4157 29.1673 13.4955 29.1673 16.9643C29.1673 20.4331 27.5307 23.5128 25.0007 25.4472C22.4706 23.5128 20.834 20.4331 20.834 16.9643C20.834 13.4955 22.4706 10.4157 25.0007 8.48137Z"
        fill="#FF5F00"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="50" height="33.9286" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconMasterCard;
