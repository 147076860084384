import { ListQuestionTypeParams } from "@mh/questionnaire/src/types";

import { QuestionnaireContentComponentProps } from "..";
import { QCBaseList } from "./QCBaseList";
import { QCListProvider } from "./QCListContext";

export const QCSingleList = (props: QuestionnaireContentComponentProps) => (
  <QCListProvider
    initialOptions={
      (props.question.type_params as ListQuestionTypeParams)!.options!
    }
    initialOptionsSelection={props.initialValue?.options_selection ?? {}}
  >
    <QCBaseList
      {...props}
      checkType="radio"
      onSelect={(option, initialFalseState) => ({
        ...initialFalseState,
        [option]: true
      })}
    />
  </QCListProvider>
);
