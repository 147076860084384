interface EmailBlockProps {
  type?: string; // question type
  accountAttrClass?: string; // input label class
}

export const EmailBlock = (props: EmailBlockProps) => (
  <div
    className={`${
      props?.type === "bypass" ? "col-12" : "col-12 col-lg-11"
    } mt-3 v-center`}
  >
    <label htmlFor="signup_email">
      <div
        className={`h2-sm asterisk ${
          props?.accountAttrClass ? props?.accountAttrClass : ""
        }`}
      >
        Email
      </div>
    </label>
    <div>
      <input
        required={false}
        placeholder=""
        id="signup_email"
        className="ember-text-field ember-view"
        type="text"
      />
    </div>
  </div>
);
