import { API } from "@mh/api";
import {
  AvailableAppointments,
  ConsultationDetails,
  LiveWaitingTime,
  ScheduledBooking,
  Clinician
} from "./types";

export class SchedulingAPI {
  /* Book an appointment for the patient and questionnaire */
  static makeASAPBooking = async (
    data: ConsultationDetails
  ): Promise<Response> =>
    API.v2()
      .url("/questionnaire-booking/asap-booking/")
      .json()
      .body(data)
      .patch();

  /* Book after hours appointment for the patient and questionnaire */
  static makeAfterHoursBooking = async (
    consultationId: number
  ): Promise<Response> =>
    API.v2()
      .json()
      .url(`/schedules/request-after-hours/${consultationId}/`)
      .body({})
      .post();

  static createScheduledBooking = async (
    data: ScheduledBooking
  ): Promise<Response> =>
    API.v2().url("/schedules/bookings/").json().body(data).patch();

  static getAvailableClinicians = async (
    data: ConsultationDetails
  ): Promise<Clinician[]> =>
    API.v2()
      .url(
        `/schedules/available-clinicians/?${new URLSearchParams({
          "consultation-id": `${data.consultation_id}`
        })}`
      )
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static getAvailableAppointments = async (
    data: ConsultationDetails
  ): Promise<AvailableAppointments> =>
    API.v2()
      .url(
        `/schedules/available-appointments/?${new URLSearchParams({
          "consultation-id": `${data.consultation_id}`,
          "preferred-clinician-id": `${data.clinician_id ?? ""}`
        })}`
      )
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static rescheduleBooking = async (
    data: ScheduledBooking
  ): Promise<Response> =>
    API.v2()
      .url(`/schedules/bookings/${data.booking_id}/reschedule`)
      .json()
      .body(data)
      .patch();

  static clearBooking = async (data: ConsultationDetails): Promise<Response> =>
    API.v2().url("/schedules/clear-booking").json().body(data).patch();

  static getLiveWaitTime = async (
    categorySlug: string
  ): Promise<LiveWaitingTime> =>
    API.v2()
      .url(`/schedules/live-queue-time/?category_slug=${categorySlug}`)
      .cache({ cacheDuration: 600 }) // cache the results for 10minutes
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });
}
