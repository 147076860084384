import type { ReactNode } from "react";
import { Async, SuccessAsync, asyncIsSuccess } from "@mh/api";

type ToAsync<T extends any[]> = {
  [K in keyof T]: Async<T[K]>;
};

interface Props<T extends any[]> {
  /** Array {@link Async} whose data will conditionally rendered, mapped in the order they are passed in
   *
   * i.e. [Async<string>, Async<number>, Async<boolean>] will have children as an array of ([string, number, boolean]) => ReactNode
   */
  asyncs: ToAsync<T>;
  /** Renders the {@link Async}s' data, with types being the same as the order the async array is passed in */
  children: (data: [...T]) => ReactNode;
  /** Renders if any of the {@link Asyncs}s are pending */
  renderPending?: ReactNode;
}

/** Component which conditionally renders the data of the provided {@link Async}'s data, if the loading succeeded */
const Await = <T extends any[]>(props: Props<T>): JSX.Element | null => {
  if (props.asyncs.some((async) => !asyncIsSuccess(async))) {
    // Render a provided placeholder or nothing if any of the asyncs are pending or have failed
    return props.renderPending ? <>{props.renderPending}</> : null;
  }

  function getAsyncData<T>(async: SuccessAsync<T>): T {
    return async.data;
  }

  // Turn any[] into variadic tuple type [...T], so the return type for the above example goes from
  // (string | number | boolean)[] to [string, number, boolean]
  const data: [...T] = (props.asyncs as SuccessAsync<T>[]).map(
    getAsyncData
  ) as [...T];

  return <>{props.children(data)}</>;
};

export default Await;
