import { useEffect } from "react";
import { Form } from "react-bootstrap";

import { QuestionResponseData, useQContext } from "@mh/questionnaire/src/hooks";
import { ListQuestionTypeParams, Question } from "@mh/questionnaire/src/types";

import { QuestionnaireContentComponentProps } from "..";

import "./QCList.scss";
import { QCListOtherInput } from "./QCListOtherInput";
import { useQCListContext } from "./QCListContext";

export type QCListSelectedResponse = {
  [key: string]: boolean;
};

export interface QCListProps extends QuestionnaireContentComponentProps {
  onSelect: (
    option: string,
    initialFalseState: QCListSelectedResponse,
    prevSelectedOptions: QCListSelectedResponse
  ) => QCListSelectedResponse;
  checkType: "checkbox" | "radio";
  preventUpdatePropagation?: true;
}

export const shouldQCListIncrementOnClick = (
  question: Question,
  responseData: QuestionResponseData
): boolean =>
  question.auto_next === true &&
  ((question.type_params as ListQuestionTypeParams)?.other === false ||
    responseData[question.id]?.options_selection?.Other === false);

export const QCBaseList = (props: QCListProps) => {
  const { setLocalResponseValidationError } = useQContext();
  const {
    options,
    setOptions,
    selectedOptions,
    setSelectedOptions,
    otherValue,
    setOtherValue
  } = useQCListContext();

  const typeParams: ListQuestionTypeParams | undefined = props.question
    .type_params as ListQuestionTypeParams;
  const other: boolean = typeParams?.other ?? false;

  const initialFalseState = {
    ...options.reduce((acc, option) => ({ ...acc, [option]: false }), {})
  };

  if (
    other &&
    selectedOptions.Other === undefined &&
    !options.includes("Other")
  ) {
    setOptions([...options, "Other"]);
  }

  useEffect(() => {
    if (other && selectedOptions.Other === true && otherValue === "") {
      setLocalResponseValidationError("Please specify an other value");
    } else {
      setLocalResponseValidationError(null);
    }

    if (!props.preventUpdatePropagation) {
      props.onChange({
        options_selection: selectedOptions,
        other_value: otherValue
      });
    }
  }, [selectedOptions, otherValue]);

  const onSelect = (option: string) =>
    setSelectedOptions((prevSelectedOptions) =>
      props.onSelect(option, initialFalseState, prevSelectedOptions)
    );

  return (
    <div className="qc-list">
      {options.map((option, index) => (
        <div // Make the whole area clickable, not just the checkbox
          key={index}
          onClick={(e) => {
            onSelect(option);
            e.preventDefault();
          }}
        >
          <Form.Check
            className="qc-list-item"
            id={`${index}-${option}`}
            type={props.checkType}
          >
            <Form.Check.Input
              checked={selectedOptions[option] || false}
              type={props.checkType}
              onClick={(e) => {
                onSelect(option);
                e.stopPropagation();
              }}
            />
            <Form.Check.Label htmlFor={`${index}-${option}`}>
              {option}
            </Form.Check.Label>
          </Form.Check>
        </div>
      ))}
      {other && (
        <QCListOtherInput
          question={props.question}
          onChange={setOtherValue}
          initialValue={otherValue}
          show={selectedOptions.Other}
        />
      )}
    </div>
  );
};
