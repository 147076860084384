import Dropdown from "react-bootstrap/Dropdown";

interface ProductAvailability {
  is_available_to_buy: boolean;
}

interface Option {
  title: string;
  availability: ProductAvailability;
}

interface Question {
  type: string;
  options: Option[];
}

interface Props {
  currentTitle: string | undefined;
  currentQuestion: Question;
  dropDownChange: (param: Option) => (e: any) => void;
  preference: boolean;
}

export const MedicationSelectBox = ({
  currentTitle,
  currentQuestion,
  dropDownChange,
  preference
}: Props) => {
  const items = currentQuestion.options.map((option, index) => {
    return (
      <Dropdown.Item
        css={(theme) => ({
          "&.disabled": {
            background: "#e9ecef !important"
          },
          "::-webkit-scrollbar": {
            position: "absolute",
            right: "-10px",
            width: "100px"
          },
          padding: "12px",
          color: "#000000",
          lineHeight: "normal",
          fontSize: "14px",
          borderBottom:
            currentQuestion.options?.length - 1 === index
              ? ""
              : "1px #808285 solid",
          "&:hover": {
            backgroundColor: "#F0F0F0"
          },
          "&:active": {
            color: "#FFFFFF",
            backgroundColor: theme.color.primary
          },
          [theme.mq.md]: {
            borderBottom:
              currentQuestion.options?.length - 1 === index
                ? ""
                : "0.5px #808285 solid"
          }
        })}
        key={index}
        value={option.title}
        onClick={dropDownChange(option)}
      >
        <div className="text-wrap">{option.title}</div>
      </Dropdown.Item>
    );
  });

  return (
    <div>
      <Dropdown
        css={(theme) => ({
          maxWidth: "342px",
          width: "100% !important",
          marginLeft: "auto",
          marginRight: "auto",
          lineHeight: "normal",
          minHeight: "35px !important",
          height: "unset !important",
          marginBottom: "16px",
          [theme.mq.md]: {
            maxWidth: "431px !important",
            width: "100% !important",
            marginBottom: "24px"
          },
          [theme.mq.lg]: {
            width: "431px !important",
            maxWidth: "unset  !important"
          },
          button: {
            minHeight: "38px",
            padding: "8px",
            width: "100%",
            background: "white",
            textAlign: "left",
            color: "black",
            radius: "4px",
            "&:after": {
              position: "absolute",
              fontSize: "20px",
              right: "5px",
              top: "50%",
              transform: "translateY(-50%)",
              [theme.mq.md]: {
                right: "15px"
              }
            },
            "&.show": {
              "&:after": {
                transform: "translateY(-50%) rotate(180deg)"
              }
            },
            "&.show, &:hover, &:active": {
              background: "unset !important",
              color: "unset !important",
              border: "0.5px #737373 solid"
            }
          }
        })}
      >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          css={(theme) => ({
            borderRadius: "12px",
            lineHeight: "135% !important",
            borderColor: "#737373 !important",
            border: "0.5px solid !important",
            boxShadow: "none",
            [theme.mq.md]: {
              borderWidth: "0.5px"
            }
          })}
        >
          <div
            className="text-wrap"
            css={{
              minHeight: "24px",
              marginRight: "15px",
              lineHeight: "normal"
            }}
          >
            {preference && currentTitle === "Other" ? (
              <span
                css={{
                  color: "#737373"
                }}
              >
                Choose your brand
              </span>
            ) : (
              currentTitle ?? (
                <span
                  css={{
                    color: "#737373"
                  }}
                >
                  Please choose one
                </span>
              )
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          // @ts-ignore
          css={{
            position: "relative !important",
            width: "100%",
            overflowY: "auto",
            height: "60vh",
            "@media (max-width: 460px)": {
              height: "48vh"
            },
            transform: "none !important"
          }}
        >
          {items}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
