import React from "react";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import {
  Container,
  DisplayHeading,
  IconCircleEdit,
  IconCircleCancel,
  IconCircleSave,
  Theme
} from "@mh/components";
import { Interpolation } from "@emotion/react";

interface ProfileCardProps {
  children: React.ReactNode;
  isQuestionnaire?: boolean;
}

export const ProfileCard = ({
  children,
  isQuestionnaire
}: ProfileCardProps) => (
  <Container isWhiteBg={isQuestionnaire}>{children}</Container>
);

ProfileCard.Header = ({
  children,
  title,
  style
}: {
  children?: React.ReactNode;
  title: string;
  style?: Interpolation<Theme>;
}) => (
  <div
    className="d-flex flex-row align-items-center mb-2"
    css={[{ justifyContent: "space-between" }, style]}
  >
    <DisplayHeading size="sm" underline="none">
      {title}
    </DisplayHeading>
    <div>{children}</div>
  </div>
);

ProfileCard.HorizontalDivider = ({ bgColor }: { bgColor?: string }) => {
  return (
    <div
      className="w-100 mb-4"
      style={{ height: "1px", backgroundColor: `${bgColor ?? "#737373"}` }}
    />
  );
};

ProfileCard.VerticalDivider = () => (
  <div
    className="h-100 mx-4"
    style={{ width: "1px", backgroundColor: "#737373" }}
  />
);

ProfileCard.Group = ({ children }: { children: React.ReactNode }) => (
  <div className="w-100 d-flex flex-column justify-content-between flex-md-row gap-md-5">
    {children}
  </div>
);

interface EditableProfileCardProps {
  id: string;
  children: ({ isEditing }: { isEditing: boolean }) => React.ReactNode;
  disabled?: boolean;
  isEditing?: boolean;
  isLoading?: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  title: string;
  isQuestionnaire?: boolean;
}

export const EditableProfileCard = ({
  id,
  children,
  disabled,
  isEditing,
  isLoading,
  onCancel,
  onEdit,
  onSave,
  title,
  isQuestionnaire
}: EditableProfileCardProps) => (
  <ProfileCard isQuestionnaire={isQuestionnaire}>
    <ProfileCard.Header title={title}>
      <div className="text-nowrap">
        {isLoading && (
          <Spinner
            className="mr-1"
            variant="secondary"
            animation="border"
            size="sm"
          />
        )}
        {isEditing ? (
          isQuestionnaire ? (
            <ButtonGroup>
              <button
                onClick={onCancel}
                disabled={disabled}
                data-testid={`${id}-cancel-button`}
                css={(theme) => ({
                  border: "none",
                  background: "none",
                  marginBottom: "16px !important",
                  color: theme.color.primary
                })}
              >
                <IconCircleCancel width="32px" />
              </button>
              <button
                type="submit"
                onClick={onSave}
                disabled={disabled}
                data-testid={`${id}-save-button`}
                css={(theme) => ({
                  border: "none",
                  background: "none",
                  marginBottom: "16px !important",
                  color: theme.color.primary
                })}
              >
                <IconCircleSave width="32px" />
              </button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                onClick={onCancel}
                disabled={disabled}
                data-testid={`${id}-cancel-button`}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={onSave}
                disabled={disabled}
                data-testid={`${id}-save-button`}
              >
                Save
              </Button>
            </ButtonGroup>
          )
        ) : isQuestionnaire ? (
          <button
            data-testid={`${id}-edit-button`}
            onClick={onEdit}
            disabled={disabled}
            css={(theme) => ({
              border: "none",
              background: "none",
              marginBottom: "16px !important",
              color: theme.color.primary
            })}
          >
            <IconCircleEdit width="32px" />
          </button>
        ) : (
          <Button
            className="edit-button"
            variant="primary"
            data-testid={`${id}-edit-button`}
            onClick={onEdit}
            disabled={disabled}
          >
            Edit
          </Button>
        )}
      </div>
    </ProfileCard.Header>
    {children({ isEditing: !!isEditing })}
  </ProfileCard>
);
