import { PropsWithChildren, SyntheticEvent } from "react";
import { Interpolation } from "@emotion/react";
import { Button } from "../buttons";
import { HorizontalDivider } from "../dividers";
import { IconClose } from "../icons";
import { Overlay, OverlayProps } from "../Overlay";
import { Theme } from "../theme";
import { Size } from "../types";

const SIZE_MAP: Record<Size, `${number}px`> = {
  sm: "342px",
  md: "600px",
  lg: "1200px"
};

interface ModalTitleProps {
  /** If true, disables the close icon button. */
  disabled?: boolean;
  /**
   * Fired when the close icon button is clicked.
   * If not given, no close button is rendered.
   */
  onClose?: () => void;
  /** an optional subtitle could be rendered below the title. */
  subtitle?: string;

  /** an optional horizontal size. */
  horizontalSize?: "sm" | "md" | "lg";

  /** Custom button styling. */
  buttonCss?: Interpolation<Theme>;

  /** Custom styling. */
  customCss?: Interpolation<Theme>;

  /** no bottom divider */
  noDivider?: boolean;
}

const ModalTitle = ({
  children,
  disabled = false,
  onClose,
  subtitle,
  horizontalSize,
  buttonCss,
  customCss,
  noDivider
}: PropsWithChildren<ModalTitleProps>) => (
  <div
    css={[
      {
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "white"
      },
      customCss
    ]}
  >
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <span
          css={(theme) => ({
            color: theme.color.primary,
            fontFamily: theme.font.heading,
            fontSize: "20px"
          })}
        >
          {children}
        </span>
        {subtitle && (
          <span
            css={{
              color: "#808285",
              fontSize: "14px",
              fontWeight: 500
            }}
          >
            {subtitle}
          </span>
        )}
      </div>
      {onClose && (
        <Button
          data-testid="close-modal"
          css={buttonCss}
          disabled={disabled}
          onClick={onClose}
          variant="primary-plain"
        >
          <IconClose width={24} height={24} />
        </Button>
      )}
    </div>
    {!noDivider && <HorizontalDivider size={horizontalSize} />}
  </div>
);

interface ModalBodyProps {
  /** Optional size, determines the minimum width of the modal. */
  size?: Size;
  /** Optional color variant, add highlight to top border of the modal. */
  variant?: keyof Theme["color"];
  /** set mobile style to be the center position. */
  mobileVCenter?: boolean;
  /** Optional css style, for modal body */
  bodyCss?: Interpolation<Theme>;
}

const ModalBody = ({
  children,
  size = "md",
  variant,
  mobileVCenter,
  bodyCss
}: PropsWithChildren<ModalBodyProps>) => (
  <div
    css={[
      (theme) => ({
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        backgroundColor: "white",
        bottom: "0px",
        width: "100%",
        minHeight: "50%",
        maxHeight: "95%",
        borderTop: variant && `2px solid ${theme.color[variant]}`,
        borderRadius: "8px 8px 0px 0px",
        overflowX: "hidden",
        overflowY: "auto",
        [theme.mq.md]: {
          top: "50%",
          left: "50%",
          width: SIZE_MAP[size],
          maxWidth: "95%",
          minHeight: "auto",
          height: "fit-content",
          borderRadius: "8px",
          transform: "translate(-50%, -50%)"
        },

        ">:first-of-type": {
          paddingTop: "24px"
        },
        ">*": {
          paddingLeft: "24px",
          paddingRight: "24px"
        },
        ">:last-child": {
          paddingBottom: "24px"
        }
      }),
      mobileVCenter && {
        minHeight: "unset",
        maxHeight: "unset",
        width: "auto",
        top: "50%",
        bottom: "unset",
        left: "15px",
        right: "15px",
        transform: "translateY(-50%)",
        borderRadius: "8px"
      },
      bodyCss
    ]}
  >
    {children}
  </div>
);

interface ModalActionsProps {
  /** If true, will always show the actions. */
  sticky?: boolean;
  style?: Interpolation<Theme>;
}

const ModalActions = ({
  children,
  sticky = false,
  style
}: PropsWithChildren<ModalActionsProps>) => (
  <div
    css={[
      {
        marginTop: "auto",
        backgroundColor: "white"
      },
      sticky && {
        position: "sticky",
        bottom: 0,
        zIndex: 1
      },
      style
    ]}
  >
    <div
      css={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        gap: "16px",
        marginTop: "16px",
        [theme.mq.md]: {
          flexDirection: "row"
        },
        ">*": {
          width: "100%",
          [theme.mq.md]: {
            width: "auto"
          }
        }
      })}
    >
      {children}
    </div>
  </div>
);

export interface ModalProps extends OverlayProps {
  /** If true, disables mouse and keyboard events on the title and overlay. */
  disabled?: boolean;
  /** Triggeres on clicking the overlay or pressing the "Escape" key. */
  onHide: () => void;
  /** Optional size, determines the minimum width of the modal. */
  size?: ModalBodyProps["size"];
  /** Optional color variant, add highlight to top border of the modal. */
  variant?: ModalBodyProps["variant"];
  /** set mobile style to be the center position. */
  mobileVCenter?: ModalBodyProps["mobileVCenter"];
}

export const Modal = ({
  children,
  disabled = false,
  onHide,
  show,
  size = "md",
  variant,
  mobileVCenter,
  ...overlayProps
}: PropsWithChildren<ModalProps>) => {
  /** Trigger hide when overlay is clicked. */
  const handleOverlayOnClick = (e: SyntheticEvent) => {
    // Only close when the overlay itself is clicked, not it's children.
    if (!disabled && e.currentTarget === e.target) {
      onHide();
    }
  };

  /** Trigger hide when Escape key is pressed. */
  const handleOverlayOnKeyDown = (e: KeyboardEvent) => {
    if (!disabled && e.code === "Escape") {
      onHide();
    }
  };

  return (
    <Overlay
      {...overlayProps}
      onClick={handleOverlayOnClick}
      onKeyDown={handleOverlayOnKeyDown}
      show={show}
    >
      <ModalBody size={size} variant={variant} mobileVCenter={mobileVCenter}>
        {children}
      </ModalBody>
    </Overlay>
  );
};

Modal.Title = ModalTitle;
Modal.Body = ModalBody;
Modal.Actions = ModalActions;
