import { ComponentPropsWithoutRef } from "react";

export const IconNeverRunOut = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.53145 2.21016C9.57996 2.21016 12.1287 4.75891 12.1287 7.80742V18.952C12.1287 22.0005 9.57996 24.4993 6.53145 24.4993C3.43296 24.4993 0.93418 22.0005 0.93418 18.952V7.80742C0.93418 4.75891 3.43296 2.21016 6.53145 2.21016ZM10.5295 19.002V15.0039H2.5334V19.002C2.5334 21.2509 4.28254 23 6.53145 23C8.73037 23 10.5295 21.2509 10.5295 19.002ZM10.5295 13.4047V7.80742C10.5295 5.6085 8.73037 3.80938 6.53145 3.80938C4.28254 3.80938 2.5334 5.6085 2.5334 7.80742V13.4047H10.5295ZM21.724 8.60703C26.1219 8.60703 29.7201 12.2053 29.7201 16.6031C29.7201 21.051 26.1219 24.5992 21.724 24.5992C17.2762 24.5992 13.7279 21.051 13.7279 16.6031C13.7279 12.2053 17.2762 8.60703 21.724 8.60703ZM21.724 23C23.1733 23 24.5227 22.5002 25.5721 21.6507L16.6765 12.705C15.8269 13.8045 15.3271 15.1538 15.3271 16.6031C15.3271 20.1514 18.1758 23 21.724 23ZM26.7216 20.5512C27.5712 19.4517 28.1209 18.1024 28.1209 16.6031C28.1209 13.1048 25.2223 10.2063 21.724 10.2063C20.2248 10.2063 18.8754 10.756 17.776 11.6056L26.7216 20.5512Z"
      fill="currentColor"
    />
  </svg>
);
