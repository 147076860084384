let AddUserquestionsnew = [
  {
    id: 105,
    title: "What was your sex assigned at birth?",
    type: "button",
    ga_id: "A0006",
    questionType: "common",
    hideQuestion: "isGenderProvided",
    options: [
      {
        id: 100051,
        title: "Male",
        products: ["contraceptive_pill", "uti", "thrush"],
        end: true,
        isCustomizeMessage: true,
        message: {
          contraceptive_pill:
            "Unfortunately this treatment is not suitable. We recommend that you see your GP physically to discuss options.",
          uti: "Sorry, this service is not suitable for men as there may be other factors impacting your condition that need to be assessed. We recommend seeing a doctor in person to complete any necessary tests and prescribe treatment.",
          thrush:
            "Sorry, this service is not suitable for men as there may be other factors impacting your infection that need to be assessed. We recommend seeing a doctor in person to assess your symptoms and prescribe treatment.",
          default:
            "Unfortunately this treatment is not suitable. We recommend that you see your GP physically to discuss options."
        }
      },
      {
        id: 100052,
        title: "Female",
        products: ["ed", "pe"],
        end: true,
        message:
          "Unfortunately this treatment is not suitable. We recommend that you see your GP physically to discuss options."
      }
    ]
  },
  {
    id: 100053,
    ga_id: "A0006_1",
    sex: "female",
    title:
      "Are you currently pregnant, breastfeeding or planning to become pregnant in the near future?",
    subtitle:
      "It is critical that you answer this question correctly, as some medications are not safe to use while pregnant or breastfeeding",
    type: "button",
    options: [
      {
        id: 1,
        title: "I am pregnant",
        products: ["uti", "weight management"],
        end: true,
        isCustomizeMessage: true,
        message: {
          contraceptive_pill:
            "Unfortunately this treatment is not suitable. We recommend that you see your GP physically to discuss options.",
          uti: "Sorry, this service is not suitable for pregnant women as there may be other factors impacting your condition that need to be assessed. We recommend seeing a doctor in person to complete any necessary tests and prescribe treatment.",
          weight_management: {
            url: `{"type":"link", "text": "Find out what we treat", "data": "replace"}`,
            replace: {
              youly:
                "https://youly.com.au/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/",
              stagger:
                "https://stagger.com.au/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/",
              hubhealth:
                "https://hub.health/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/"
            }
          }
        }
      },
      {
        id: 2,
        title: "I am breastfeeding",
        products: ["weight management"],
        end: true,
        isCustomizeMessage: true,
        message: {
          weight_management: {
            url: `{"type":"link", "text": "Find out what we treat", "data": "replace"}`,
            replace: {
              youly:
                "https://youly.com.au/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/",
              stagger:
                "https://stagger.com.au/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/",
              hubhealth:
                "https://hub.health/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/"
            }
          }
        }
      },
      {
        id: 3,
        title: "I am planning to become pregnant"
      },
      {
        id: 4,
        title: "I could be pregnant (I’m not sure)",
        products: ["weight management"],
        end: true,
        isCustomizeMessage: true,
        message: {
          weight_management: {
            url: `{"type":"link", "text": "Find out what we treat", "data": "replace"}`,
            replace: {
              youly:
                "https://youly.com.au/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/",
              stagger:
                "https://stagger.com.au/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/",
              hubhealth:
                "https://hub.health/excluded-from-weight-loss-questionnaire-pregnant-or-breastfeeding/"
            }
          }
        }
      },
      {
        id: 5,
        title:
          "No - I am not pregnant, breastfeeding or planning to become pregnant"
      }
    ]
  },
  {
    id: 106,
    title: "What is your height in cms?",
    type: "input",
    ga_id: "A0007",
    questionType: "common",
    inputtype: "float",
    input_type: "number",
    placeholder: "cm",
    range: { min: 10, max: 300 }
  },
  {
    id: 107,
    ga_id: "A0008",
    title: "What is your weight in kilograms?",
    type: "input",
    questionType: "common",
    inputtype: "float",
    input_type: "number",
    placeholder: "kg",
    range: { min: 10, max: 300 }
  }
];

export default AddUserquestionsnew;
