import { getQuestionnaireProduct, type PatientQuestionnaire } from "@mh/api";

import { Flex } from "@mh/components";

interface MedicationBoxProps {
  singleRepeatPrice: number;
  preferredMonth: number;
  price: number;
  brandPill?: {
    title?: string;
    duration?: number;
  };
  shippingCost?: number;
  ptPlanInfo?: PatientQuestionnaire;
}

export const MedicationBox = (props: MedicationBoxProps) => {
  // A potential product that was selected in this questionnaire, and its associated price
  const selectedProduct = props?.brandPill;

  const productPrice =
    props.singleRepeatPrice > 0 && props.preferredMonth > 0
      ? props.singleRepeatPrice * props.preferredMonth
      : 0;

  // Use the selected product, or fall back to the existing product of a patient's subscription
  const product =
    selectedProduct ??
    (props?.ptPlanInfo ? getQuestionnaireProduct(props?.ptPlanInfo) : null);

  return (
    <Flex
      flexDirection="row"
      css={(theme) => ({
        padding: "0px",
        paddingTop: "24px",
        paddingBottom: "24px",
        marginBottom: "24px",
        [theme.mq.sm]: {
          paddingLeft: "24px",
          paddingRight: "24px"
        }
      })}
    >
      <Flex
        css={(theme) => ({
          marginRight: "8px",
          [theme.mq.sm]: {
            marginRight: "24px"
          }
        })}
      >
        <div
          css={(theme) => ({
            position: "relative",
            width: "38px",
            [theme.mq.sm]: {
              width: "56px"
            }
          })}
        >
          <div
            css={(theme) => ({
              position: "absolute",
              left: "0px",
              right: "0",
              top: "50%",
              bottom: "0",
              background: theme.color.primary,
              width: "38.5px",
              height: "38.5px",
              borderRadius: "100px",
              transform: "translateY(-50%)",
              [theme.mq.sm]: {
                left: "0",
                width: "56px",
                height: "56px"
              }
            })}
          >
            <div css={{ position: "relative" }}>
              <img
                css={(theme) => ({
                  width: "20px",
                  alignItems: "center",
                  textAlign: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, 30%)",
                  [theme.mq.sm]: {
                    width: "32px"
                  }
                })}
                src="/assets/images/com/px.png"
              ></img>
            </div>
          </div>
        </div>
      </Flex>
      <Flex flexDirection="column">
        <Flex>
          <div
            css={(theme) => ({
              fontSize: "18px",
              fontWeight: "600",
              [theme.mq.sm]: {
                fontSize: "20px"
              }
            })}
          >
            {product?.title ?? "N/A"}
          </div>
        </Flex>
        <Flex
          css={(theme) => ({
            marginTop: "8px",
            marginBottom: "8px",
            height: "1px",
            background: "var(--gray-500, #A6A6A6)",
            [theme.mq.sm]: {
              marginTop: "12px",
              marginBottom: "12px"
            }
          })}
        />
        <Flex flexDirection="column">
          <div
            css={{
              fontSize: "14px",
              fontWeight: "500"
            }}
          >
            {props?.brandPill?.duration &&
              props.preferredMonth &&
              `${
                props?.brandPill?.duration * props.preferredMonth
              } days supply`}
          </div>
          <div
            css={{
              fontWeight: "500"
            }}
          >
            ${productPrice > 0 ? productPrice.toFixed(2) : "N/A"} +{" "}
            {props?.shippingCost && props?.shippingCost > -1
              ? props.shippingCost
              : "N/A"}{" "}
            postage and handling
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};
