import { Flex } from "@mh/components";

import { Form } from "react-bootstrap";

export const MedicationInformationDates = (props: {
  started: string | null;
  setStarted: (v: string) => void;
  finished: string | null;
  setFinished: (v: string) => void;
}) => (
  <Flex className="field" flexDirection="column" width="100%">
    <div style={{ paddingBottom: 10 }}>
      <div>
        <div className="asterisk">Medication Commencement</div>
        <div className="subtitle">
          The date on which you started to take the medication
        </div>
      </div>
      <div>
        <Form.Control
          onChange={(event) => props.setStarted(event.target.value)}
          value={props.started ?? ""}
        />
      </div>
    </div>
    <div>
      <div>
        <div>Medication Cessation</div>
        <div className="subtitle">
          The date on which you stopped taking the medication, leave empty if
          you&apos;re still taking the medication
        </div>
      </div>
      <div>
        <Form.Control
          onChange={(event) => props.setFinished(event.target.value)}
          value={props.finished ?? ""}
        />
      </div>
    </div>
  </Flex>
);
