import { useEffect, useState } from "react";
import { NibInternational } from "../../../../../../../../src/components/questionnaire/NibInternational";
import { QuestionnaireContentComponentProps } from "../..";
import { useQContext } from "@mh/questionnaire/src/hooks";

const splashBg = "/assets/images/hubhealth/nib-med-cert-background.png";
const splashMobileBg = "/assets/images/hubhealth/nib-med-cert-background.png";
const termsLink = "https://hub.health/terms-and-conditions/";
const privacyLink = "https://hub.health/privacy-policy/";

export const QCSNIBIntl = (props: QuestionnaireContentComponentProps) => {
  const { submitQuestion } = useQContext();

  const [data, setData] = useState(undefined);
  const [complete, setComplete] = useState<boolean>(false);

  useEffect(() => {
    if (data && complete) {
      props.onChange(data);
      submitQuestion();
    }
  }, [data, complete]);

  return (
    <NibInternational
      code={"medicalcertificates"}
      detail={{
        // @ts-ignore
        bgfull: true,
        bg: splashBg,
        mobileBg: splashMobileBg,
        typecheck: true,
        typeset: "nibInternational",
        logo: "/assets/images/hubhealth/newniblogo.png",
        splashname: "nibinternational",
        splashclass_: "nibinternational-membership inner-content",
        splashinnerclass_: "col-lg-5",
        bttxt: "Submit",
        btloadtxt: "Verifying...",
        btloadcount: 15,
        footer: {
          outerclass_: "nibinternational-footer",
          innerclass_: "inner pl-sm-1 pr-sm-1 pl-6-cs pr-6-cs",
          btouterclass_: "",
          outertxtclass_: "nibinternational-footertext",
          txtclass_:
            "inner pt-4 pb-6 pl-sm-1 pr-sm-1 pl-6-cs pr-6-cs bblr-08 bbrr-08",
          txt: (
            <span>
              By clicking &quot;Submit&quot; you confirm you have read our{" "}
              <a href={termsLink} rel="noreferrer" target="_blank">
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a href={privacyLink} rel="noreferrer" target="_blank">
                Privacy Policy
              </a>
              .
            </span>
          )
        }
      }}
      footer={<></>}
      setMembership={() => {}}
      continueToQuestionnaire={() => setComplete(true)}
      setAllDetails={(data) => setData(data)}
      onVoucherApplied={() => {}}
      setFromParam={() => {}}
      setIsValidParam={() => {}}
      setProductEnv={() => {}}
      originProductenv={{ code: "TODO" }}
      setIsNibPatient={() => {}}
      firstName={props.initialValue?.first_name}
      lastName={props.initialValue?.last_name}
      email={props.initialValue?.email}
      membership={props.initialValue?.nib_membership_number}
      dob_day={
        props.initialValue?.dob
          ? (props.initialValue?.dob as string).split("-")[2]
          : ""
      }
      dob_month={
        props.initialValue?.dob
          ? (props.initialValue?.dob as string).split("-")[1]
          : ""
      }
      dob_year={
        props.initialValue?.dob
          ? (props.initialValue?.dob as string).split("-")[0]
          : ""
      }
    />
  );
};
