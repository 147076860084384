export const IconCash = () => (
  <svg
    width="71"
    height="71"
    viewBox="0 0 71 71"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="35.8652"
      cy="35.9714"
      r="35"
      css={(theme) => ({
        fill: `${theme.color.primary}`
      })}
    />
    <path
      d="M43.8652 32.9714C43.8652 35.7839 41.6152 37.9714 38.8652 37.9714C36.0527 37.9714 33.8652 35.7839 33.8652 32.9714C33.8652 30.2214 36.0527 27.9714 38.8652 27.9714C41.6152 27.9714 43.8652 30.2214 43.8652 32.9714ZM51.8652 21.9714C54.0527 21.9714 55.8652 23.7839 55.8652 25.9714V39.9714C55.8652 42.2214 54.0527 43.9714 51.8652 43.9714H25.8652C23.6152 43.9714 21.8652 42.2214 21.8652 39.9714V25.9714C21.8652 23.7839 23.6152 21.9714 25.8652 21.9714H51.8652ZM48.8652 24.9714H28.8652C28.8652 27.2214 27.0527 28.9714 24.8652 28.9714V36.9714C27.0527 36.9714 28.8652 38.7839 28.8652 40.9714H48.8652C48.8652 38.7839 50.6152 36.9714 52.8652 36.9714V28.9714C50.6152 28.9714 48.8652 27.2214 48.8652 24.9714ZM18.8652 42.4714C18.8652 44.9714 20.8652 46.9714 23.3652 46.9714H48.3652C49.1777 46.9714 49.8652 47.6589 49.8652 48.4714C49.8652 49.3464 49.1777 49.9714 48.3652 49.9714H23.3652C19.1777 49.9714 15.8652 46.6589 15.8652 42.4714V27.4714C15.8652 26.6589 16.4902 25.9714 17.3652 25.9714C18.1777 25.9714 18.8652 26.6589 18.8652 27.4714V42.4714Z"
      fill="white"
    />
  </svg>
);
