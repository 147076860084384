import React from "react";

import { PhoneField } from "@mh/components";
import { ProfileForm, ProfileField, ProfileSelect } from "../ProfileForm";

const SEX_OPTIONS: [string, string][] = [
  ["", ""],
  ["male", "Male"],
  ["female", "Female"]
];

const ATSI_OPTIONS: [string, string][] = [
  ["", ""],
  ["neither", "Neither Aboriginal or Torres Strait Islander"],
  ["aboriginal", "Aboriginal"],
  ["tsi", "Torres Strait Islander"]
];

function calculateAge(dobValue: string | undefined): number | string {
  if (!dobValue) {
    return "N/A";
  }

  try {
    const dob = new Date(dobValue);

    // Calculate the difference in milliseconds between the current date and the provided date of birth
    const diffMs = Date.now() - dob.getTime();
    // Create a new Date object representing the difference in milliseconds and store it in the variable age_dt (age Date object)
    const ageDt = new Date(diffMs);

    // Calculate the absolute value of the difference in years between the age Date object and the year 1970 (UNIX epoch)
    return Math.abs(ageDt.getUTCFullYear() - 1970);
  } catch (e) {
    return "N/A";
  }
}

export interface PersonalDetailsProps {
  values: any;
  setValues: any;
  error: any;
  isEditing: any;
  id: any;
  userIsFetching: any;
  patientIsFetching: any;
  isFetchingVerificationStatus: any;
  isUserVerified: any;
}

export const PersonalDetails = ({
  values,
  setValues,
  error,
  isEditing,
  id,
  userIsFetching,
  patientIsFetching
}: PersonalDetailsProps) => {
  return (
    <div
      css={{
        lineHeight: "20px !important"
      }}
    >
      <div className="d-flex flex-lg-row flex-column">
        <ProfileForm.Control
          required
          label="First name"
          fieldClass="first-name-field"
          data-testid={`${id}-firstname`}
          value={values.first_name}
        />
        <ProfileForm.Control
          label="Middle name(s)"
          fieldClass="middle-name-field"
          data-testid={`${id}-middlename`}
          error={error?.middle_name}
          value={values.middle_name}
        />
        <ProfileForm.Control
          label="Last name"
          fieldClass="last-name-field"
          data-testid={`${id}-lastname`}
          error={error?.last_name}
          value={values.last_name}
        />
      </div>
      <div
        className={`d-flex flex-lg-row flex-column relative ${
          isEditing && "edit-field"
        }`}
      >
        <ProfileForm.Control
          label="Email"
          fieldClass="email-field"
          value={values.email}
          data-testid={`${id}-email`}
        />
        <ProfileField
          label={"Mobile Number"}
          fieldClass="mobile-number-field"
          error={error?.phone}
          required
        >
          <PhoneField
            readOnly={!isEditing}
            inputTestId={`${id}-phone`}
            onChange={(value) => setValues({ ...values, phone: value ?? "" })}
            value={values.phone}
            className={error?.phone !== undefined ? "is-invalid" : undefined}
          />
        </ProfileField>
        <div className="w-100 d-flex flex-row">
          <ProfileField
            label={"Date of Birth"}
            fieldClass="date-of-birth-field"
          >
            <span>{values.dob.split("-").reverse().join("/")}</span>
          </ProfileField>
          <div className="w-100 d-flex flex-column age-field">
            <label className="text-nowrap required-asterisk form-label">
              Age
            </label>
            <span>{calculateAge(values?.dob)}</span>
          </div>
        </div>
      </div>
      <div className="d-flex flex-lg-row flex-column">
        <div
          className="d-flex flex-row"
          css={(theme) => ({
            [theme.mq.md]: {
              width: "48%"
            }
          })}
        >
          <ProfileSelect
            label="Sex"
            data-testid={`${id}-sex`}
            fieldClass="sex-field"
            value={values.gender}
          >
            {SEX_OPTIONS.map(([value, display], index) => (
              <option key={`${index}_${value}`} value={value}>
                {display}
              </option>
            ))}
          </ProfileSelect>
          <ProfileForm.Control
            label="Gender"
            fieldClass="gender-field"
            disabled={userIsFetching || patientIsFetching}
            error={error?.genderdetail}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({ ...values, genderdetail: e.currentTarget.value })
            }
            value={values.genderdetail}
          />
        </div>
        <ProfileForm.Control
          label="Ethnicity"
          fieldClass="ethnicity-field"
          data-testid={`${id}-ethnicity`}
          disabled={userIsFetching || patientIsFetching}
          error={error?.ethnicity}
          isEditing={isEditing}
          onChange={(e) =>
            setValues({ ...values, ethnicity: e.currentTarget.value })
          }
          value={values.ethnicity}
        />
        <ProfileSelect
          label="Aboriginal or Torres Strait Islander"
          fieldClass="atsi-field"
          data-testid={`${id}-aboriginal`}
          disabled={userIsFetching || patientIsFetching}
          error={error?.atsi}
          isEditing={isEditing}
          onChange={(e) =>
            setValues({ ...values, atsi: e.currentTarget.value })
          }
          value={values.atsi}
        >
          {ATSI_OPTIONS.map(([value, display], index) => (
            <option key={`${index}_${value}`} value={value}>
              {display}
            </option>
          ))}
        </ProfileSelect>
      </div>
      <div
        className="w-100"
        css={(theme) => ({
          height: "1px",
          backgroundColor: "#D9D9D9",
          marginTop: "8px",
          [theme.mq.md]: {
            marginTop: "16px"
          }
        })}
      />
    </div>
  );
};
