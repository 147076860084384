import { ComponentPropsWithoutRef } from "react";

export const IconCircleEdit = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.75" y="0.78418" width="32.5" height="32.5" rx="16.25" />
    <rect
      x="0.75"
      y="0.78418"
      width="32.5"
      height="32.5"
      rx="16.25"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M21.0625 9.75293C21.8438 8.97168 23.125 8.97168 23.9062 9.75293L24.2812 10.1279C25.0625 10.9092 25.0625 12.1904 24.2812 12.9717L17.4062 19.8467C17.125 20.1279 16.7812 20.2842 16.4375 20.3779L13.5938 21.0342C13.4375 21.0654 13.25 21.0342 13.125 20.9092C13 20.7842 12.9688 20.5967 13 20.4404L13.6562 17.5967C13.75 17.2529 13.9062 16.9092 14.1875 16.6279L21.0625 9.75293ZM23.1875 10.4717C22.8125 10.0654 22.1562 10.0654 21.7812 10.4717L20.9375 11.2842L22.75 13.0967L23.5625 12.2529C23.9688 11.8779 23.9688 11.2217 23.5625 10.8467L23.1875 10.4717ZM14.625 17.8154L14.1562 19.8779L16.1875 19.4092C16.375 19.3779 16.5625 19.2842 16.6875 19.1279L22.0312 13.7842L20.25 12.0029L14.9062 17.3467C14.75 17.4717 14.6562 17.6592 14.625 17.8154ZM15.5 11.0342C15.75 11.0342 16 11.2842 16 11.5342C16 11.8154 15.75 12.0342 15.5 12.0342H11.5C10.6562 12.0342 10 12.7217 10 13.5342V22.5342C10 23.3779 10.6562 24.0342 11.5 24.0342H20.5C21.3125 24.0342 22 23.3779 22 22.5342V18.5342C22 18.2842 22.2188 18.0342 22.5 18.0342C22.75 18.0342 23 18.2842 23 18.5342V22.5342C23 23.9404 21.875 25.0342 20.5 25.0342H11.5C10.0938 25.0342 9 23.9404 9 22.5342V13.5342C9 12.1592 10.0938 11.0342 11.5 11.0342H15.5Z"
      fill="currentColor"
    />
  </svg>
);
