import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { useQContext } from "@mh/questionnaire/src/hooks";
import { Question, QuestionContentType } from "@mh/questionnaire/src/types";

import "./styles.scss";

const hideNext = (q: Question): boolean =>
  [
    QuestionContentType.DEAD_END,
    QuestionContentType.MEDICATION_SELECTION
    // QuestionContentType.QUESTIONNAIRE_PAYMENT
  ].includes(q.type) || q.auto_next;

export const QuestionnaireNext = () => {
  const {
    position,
    maxPosition,
    question,
    nextEnabled,
    isSubmittingQuestion,
    submitQuestion
  } = useQContext();
  const hide = hideNext(question);
  const [showSpinner, setShowSpinner] = useState(isSubmittingQuestion);

  const lastQuestion = position === maxPosition;
  const text = lastQuestion ? "Submit" : "Next";

  useEffect(() => setShowSpinner(isSubmittingQuestion), [isSubmittingQuestion]);

  if (hide) return null;

  return (
    <button
      disabled={!nextEnabled || showSpinner}
      className="btn btn-primary btn-lg next-question"
      type="submit"
      onClick={() => {
        submitQuestion();
      }}
      style={{ width: "100%" }}
    >
      {showSpinner ? <Spinner size="sm" /> : text}
    </button>
  );
};
