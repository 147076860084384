import { PropsWithChildren, createContext, useContext, useState } from "react";
import { FeatureAPI } from "./api";
import { Feature } from "./types";

type FeaturesRecord = Record<Feature["name"], Feature["active"]>;

interface FeaturesContextValue {
  /**
   * Check the active state of a feature flag, by name.
   * This is an async function that will wait until the fetch completes before returning if the
   * fetch is in progess, or immediately if it is already complete.
   * @param name The name of the feature flag.
   * @param defaultValue A default value if the feature flag does not exist, or the fetch fails.
   * @returns True if the feature flag exists and is active, false if it exists and is not active,
   * or `defaultValue` if the flag does not exist or the fetch fails.
   */
  isFeatureActive: (
    name: Feature["name"],
    defaultValue: Feature["active"]
  ) => Promise<Feature["active"]>;
  /**
   * Refresh the set of feature flags from the backend.
   * Called once automatically when the context provider is first rendered.
   * @returns void
   */
  refreshFeatures: () => void;
}

const fetchFeatures = async (): Promise<FeaturesRecord> => {
  const featuresArray = await FeatureAPI.getFeatures();
  return Object.fromEntries(
    featuresArray.map((feature) => [feature.name, feature.active])
  );
};

const FeaturesContext = createContext<FeaturesContextValue>({
  isFeatureActive: async () => false,
  refreshFeatures: async () => ({})
});

export const FeaturesStore = ({ children }: PropsWithChildren) => {
  const [featuresPromise, setFeaturesPromise] = useState(fetchFeatures());

  const isFeatureActive = async (
    name: string,
    defaultValue: boolean = false
  ) => {
    try {
      const features = await featuresPromise;
      if (name in features) {
        return features[name];
      }
      console.warn(
        `Feature flag '${name}' does not exist, using default value ${defaultValue}`
      );
      return defaultValue;
    } catch {
      console.error(
        `Error fetching feature flags for '${name}', using default value: ${defaultValue}`
      );
      return defaultValue;
    }
  };

  const refreshFeatures = () => setFeaturesPromise(fetchFeatures());

  return (
    <FeaturesContext.Provider value={{ isFeatureActive, refreshFeatures }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export const useFeatures = () => useContext(FeaturesContext);
