import React from "react";
import { Flex, SwitchButton } from "@mh/components";
import { BasketLine } from "@mh/api";

interface OrderOnDemandSwitchProps {
  /** The product line */
  line: BasketLine;

  /**
   * Mapping from basket line id to order on demand value
   */
  lineIdToOrderOnDemand: { [lineId: number]: boolean };

  /**
   * Function to set mapping from basket line id to order on demand value
   *
   * @param lindId basket line id
   * @param orderOnDemand
   */
  setLineIdToOrderOnDemand: React.Dispatch<
    React.SetStateAction<
      | {
          [lineId: number]: boolean;
        }
      | undefined
    >
  >;
}

export const OrderOnDemandSwitch = ({
  lineIdToOrderOnDemand,
  line,
  setLineIdToOrderOnDemand
}: OrderOnDemandSwitchProps) => {
  return (
    <div>
      <Flex justifyContent="space-between" width="100%" alignItems="start">
        <div
          css={{
            fontSize: "14px",
            fontWeight: "500",
            marginTop: "auto",
            marginBottom: "auto"
          }}
        >
          Automatically send repeats
          <p
            css={{
              fontSize: "12px"
            }}
          >
            Turn this on if you would like us to automatically deliver your
            repeats when they are due.
          </p>
        </div>
        <div>
          <SwitchButton
            data-testid={"orderOnDemand"}
            on={!lineIdToOrderOnDemand[line.id]}
            onClick={() =>
              setLineIdToOrderOnDemand({
                ...(lineIdToOrderOnDemand ?? {}),
                ...{ [line.id]: !lineIdToOrderOnDemand[line.id] }
              })
            }
            size="sm"
          />
        </div>
      </Flex>
    </div>
  );
};
