import {
  ComponentPropsWithoutRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Interpolation, useTheme } from "@emotion/react";
import { Breakpoint, Theme } from "@mh/components";

import { Button, ButtonProps } from "./Button";
import { Modal } from "../Modal";
import { Overlay } from "../Overlay";

const DropdownMenuItem = (props: ComponentPropsWithoutRef<"div">) => (
  <div
    css={{
      padding: "12px",
      ":not(:last-child)": {
        borderBottom: "0.5px solid #D9D9D9"
      }
    }}
    {...props}
  />
);

interface DropdownButtonProps extends ButtonProps {
  dropdownContent: ReactNode;
  dropdownTitle?: string;
  /**
   * Show the content in a modal if the screen size is less than the given breakpoint.
   * If `false`, never show the content in a modal.
   */
  dropdownContentInModalBreakpoint?: Breakpoint | false;
  toggleDropDown?: boolean;
  modalBodyCss?: Interpolation<Theme>;
}

export const DropdownButton = ({
  active,
  dropdownContent,
  dropdownContentInModalBreakpoint = "md",
  dropdownTitle,
  onClick,
  modalBodyCss,
  ...buttonProps
}: DropdownButtonProps) => {
  const [showContent, setShowContent] = useState(false);
  const [dropdownContentTop, setDropdownContentTop] = useState<number>();
  const [dropdownContentLeft, setDropdownContentLeft] = useState<number>();
  const [dropdownContentMinWidth, setDropdownContentMinWidth] =
    useState<number>();
  const [dropdownContentMaxHeight, setDropdownContentMaxHeight] =
    useState<number>();
  const [dropdownContentZIndex, setDropdownContentZIndex] = useState<number>();

  const theme = useTheme();
  const breakpoint = dropdownContentInModalBreakpoint
    ? theme.mq[dropdownContentInModalBreakpoint]
    : "@media (min-width: 0px)";

  const buttonRef = useRef<HTMLButtonElement>(null);

  const overlayRef = useCallback<(node: HTMLDivElement) => void>((node) => {
    if (node) {
      const z = window.getComputedStyle(node).getPropertyValue("z-index");
      setDropdownContentZIndex(parseInt(z) + 1);
    }
  }, []);

  /** Hide content on window resize. The content will not line up with the button if it moves. */
  useEffect(() => {
    const onResize = () => setShowContent(false);
    addEventListener("resize", onResize);
    return () => {
      removeEventListener("resize", onResize);
    };
  }, []);

  const handleOnClick: NonNullable<ButtonProps["onClick"]> = (e) => {
    setShowContent(!showContent);
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    setDropdownContentTop(buttonRect?.bottom || 0);
    setDropdownContentLeft(buttonRect?.left || 0);
    setDropdownContentMinWidth(buttonRect?.width || 200);
    setDropdownContentMaxHeight(window.innerHeight - (buttonRect?.bottom || 0));

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <div>
      <Button
        active={active || showContent}
        onClick={handleOnClick}
        ref={buttonRef}
        {...buttonProps}
      />
      {showContent && (
        <>
          <Overlay
            css={{
              [breakpoint]: {
                backgroundColor: "#00000000"
              }
            }}
            onClick={() => setShowContent(false)}
            onKeyDown={() => setShowContent(false)}
            ref={overlayRef}
            show
          >
            <div
              css={{
                display: "block",
                [breakpoint]: {
                  display: "none"
                }
              }}
            >
              <Modal.Body bodyCss={modalBodyCss}>
                <Modal.Title onClose={() => setShowContent(false)}>
                  {dropdownTitle}
                </Modal.Title>
                <div onClick={() => setShowContent(false)}>
                  {dropdownContent}
                </div>
              </Modal.Body>
            </div>
            <div
              css={{
                display: "none",
                [breakpoint]: {
                  display: "block",
                  minWidth: dropdownContentMinWidth,
                  maxHeight: dropdownContentMaxHeight
                },
                position: "absolute",
                top: dropdownContentTop,
                left: dropdownContentLeft,
                backgroundColor: "white",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                overflow: "auto",
                zIndex: dropdownContentZIndex // Show above the overlay
              }}
              onClick={() => setShowContent(false)}
            >
              {dropdownContent}
            </div>
          </Overlay>
        </>
      )}
    </div>
  );
};

DropdownButton.MenuItem = DropdownMenuItem;
