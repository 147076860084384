import {
  type TinyProduct,
  type ProductLike,
  type BasketLine,
  type Category,
  asyncIsSuccess
} from "@mh/api";
import { type BasketState } from "./context";

// NOTE: The following filters out categories which shouldn't be ordered with repeats
// Ideally this should be figured out on the backend
// NOTE: ALL USAGE SHOULD BE CASED LOWER
export const NON_REPEAT_CATEGORIES: string[] = ["otc"];

export const isTreatment = (product: TinyProduct): boolean =>
  !NON_REPEAT_CATEGORIES.includes(product.product_class.slug.toLowerCase());

export interface ProductStatus {
  /** The given basket line if the product is present in a basket line */
  line: BasketLine | undefined;
  /** Is this product present in the basket? */
  isInBasket: boolean;
  /** Is this product being added to the basket? */
  isAdding: boolean;
  /** Is this product being removed from the basket? */
  isRemoving: boolean;
  /** Is this product being added to, or removed from the basket? */
  isPending: boolean;
}

/**
  * Determines the status of a product in the user's basket and its lines - whether it's present, being added or removed.

  * @param basket The current basket state, returned from the useBasket hook
  * @param product The product to check if it's present in the basket
*/
export const getProductStatus = (
  basket: Pick<BasketState, "lines" | "addingProducts" | "removingLines">,
  product: ProductLike
): ProductStatus => {
  /** The potential basket line which contains this product */
  const line = asyncIsSuccess(basket.lines)
    ? basket.lines.data.find((line) => line.product.id === product.id)
    : undefined;

  /** True if this product is present in the basket, false otherwise */
  const isInBasket = !!line;

  /** True if this product is being added to the basket, false otherwise */
  const isAdding = basket.addingProducts.has(product.id);

  /** True if this product is being removed from the basket, false otherwise */
  const isRemoving = line !== undefined && basket.removingLines.has(line.id);

  const isPending = isAdding || isRemoving;

  return { line, isInBasket, isAdding, isRemoving, isPending };
};

/**
 * Returns the fixed discount this category receives, or undefined if there is none.
 *
 * This is a temporary hardcoded value until the endpoints exist to query benefits
 *
 * @param category The category to query a potential discount for
 * @returns The fixed discount in dollars if one exists, or undefined if there is no discount
 */
export const getFixedDiscount = (category: Category): number | undefined => {
  if (category.slug === "uti") {
    return 10;
  }
};
