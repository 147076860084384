import { ComponentPropsWithoutRef } from "react";

export const IconMinus = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.75 12.0205C20.75 12.6768 20.2344 13.1455 19.625 13.1455H2.375C1.71875 13.1455 1.25 12.6768 1.25 12.0674C1.25 11.4111 1.71875 10.8955 2.375 10.8955H19.625C20.2344 10.8955 20.75 11.4111 20.75 12.0205Z"
      fill="currentColor"
    />
  </svg>
);
