import { BRAND_NAME, BRAND_TO_NAME } from "@mh/core";
import { Button } from "@mh/components";
import { Modal } from "../Modal";
import { getBrandFromRedirectUrl } from "../../../../src/utils";
import { LogoHubHealth, LogoStagger, LogoYouly } from "../logos";

interface RedirectModalProps {
  url: string;
  onClose: () => void;
}

export const RedirectModal = ({ url, onClose }: RedirectModalProps) => {
  const otherBrandNames = Object.values(BRAND_TO_NAME).filter(
    (brand) => brand !== BRAND_NAME
  );
  const userBrand = getBrandFromRedirectUrl(url);

  const logos = (
    <div
      css={{
        display: "flex",
        gap: "15px",
        justifyContent: "space-around",
        margin: "20px"
      }}
    >
      <LogoYouly width={130} />
      <LogoStagger width={110} />
      <LogoHubHealth width={210} />
    </div>
  );

  const addSearchParamsToUrl = (url: string) => {
    let urlSearch = "";
    const currentLocation = window.location.pathname + window.location.search;
    if (currentLocation.length > 1) {
      // if we get here, the location is more than just "/".
      // Include a redirect param so that after logging in to the other brand portal, the patient is redirected to the
      // same page they were on in this brand portal.
      // e.g. if they hit this model in the med certs questionnaire, the redirect param will be
      // "/questionnaire/?product=medicalcertificates"
      urlSearch = `/?${new URLSearchParams({ redirect: currentLocation })}`;
    }
    return `http://${url}${urlSearch}`;
  };

  const buttons = (
    <div className="buttons">
      <Button
        onClick={() => document.location.reload()}
        css={{
          backgroundColor: "#FFFFFF",
          borderColor: "#000000",
          color: "#000000"
        }}
      >
        Create a {BRAND_NAME} account
      </Button>
      <a href={addSearchParamsToUrl(userBrand.url)} css={{ width: "100%" }}>
        <Button className={userBrand.name} style={{ width: "100%" }}>
          Proceed to your {userBrand.displayname} account
        </Button>
      </a>
    </div>
  );

  return (
    <Modal onHide={onClose} show className="redirect-modal">
      <Modal.Title onClose={onClose}>Welcome Back</Modal.Title>
      <div>
        <p>
          You may be aware that {BRAND_NAME} is part of a group of companies
          that also includes {otherBrandNames.join(" and ")}. As you already
          have an account, you can just use the link below to proceed to{" "}
          {userBrand.displayname}; the pricing, and excellent service are the
          same as {BRAND_NAME}.
        </p>
        <p>
          If you’d rather proceed to {BRAND_NAME}, feel free to create a new
          account with the link below; you’ll just need to use an alternative
          email address.
        </p>
        <p>Thanks from the {BRAND_NAME} team</p>
      </div>
      {logos}
      {buttons}
    </Modal>
  );
};
