import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Conversation,
  ConversationsContext,
  MessagingAPI,
  PatientContext,
  UserContext
} from "@mh/api";
import {
  ConversationDetail,
  ConversationsPanel,
  NoMessages
} from "@mh/messaging";

type Params = {
  conversationId?: string;
};

export const Messaging = () => {
  const userContext = useContext(UserContext);
  const patientContext = useContext(PatientContext);
  const conversationsContext = useContext(ConversationsContext);
  const { data: conversations } = conversationsContext;
  /** This controls actions to do when a Conversation is first rendered via updating selection */
  const [firstConversationRender, setFirstConversationRender] =
    useState<boolean>(true);
  const navigate = useNavigate();
  const { conversationId } = useParams<Params>();
  /** This is the selected conversation ID pointer */
  const [selectedConversationId, setSelectedConversationId] = useState<
    number | null
  >(null);
  /** This is the FULL loaded selected conversation */
  const [conversation, setConversation] = useState<Conversation | null>(null);
  /** This is the list of conversations for the patient user */

  useEffect(() => {
    userContext.get();
    patientContext.get();
    conversationsContext.get();
    if (conversationId) {
      setSelectedConversationId(parseInt(conversationId));
    }
  }, []);

  const loadSelectedConversation = async (selectedConversationId: number) => {
    const response = await MessagingAPI.getConversation(selectedConversationId);
    if (response.ok) {
      const data = await response.json();
      setConversation(data);
    }
  };

  useEffect(() => {
    if (selectedConversationId) {
      navigate(`/messaging/conversation/${selectedConversationId}`);
      loadSelectedConversation(selectedConversationId);
      setFirstConversationRender(true); // reset this when another conversation is selected
      return;
    }
    setConversation(null);
  }, [selectedConversationId]);

  const renderConversations = (): JSX.Element => {
    if (!conversations || conversations.length <= 0) {
      return (
        <div className="row d-flex flex-row m-0">
          <NoMessages />
        </div>
      );
    }

    return (
      <div className="row d-flex h-100 flex-row m-0 justify-content-between">
        <ConversationsPanel
          conversations={conversations}
          conversationId={selectedConversationId}
          setConversationId={setSelectedConversationId}
          data-testid="conversations-panel"
        />
        {conversation && (
          <ConversationDetail
            conversation={conversation}
            loadSelectedConversation={loadSelectedConversation}
            setConversationId={setSelectedConversationId}
            isFirstRender={firstConversationRender}
            setIsFirstRender={setFirstConversationRender}
          />
        )}
      </div>
    );
  };

  return (
    <div
      className="d-flex flex-column w-100 overflow-scroll"
      css={{
        backgroundColor: "#FAFAFA",
        padding: "8px",
        borderRadius: "16px",
        maxHeight: "88vh"
      }}
    >
      {renderConversations()}
    </div>
  );
};
