import { BaseQuestionnaire, Script } from "@mh/api";

interface TreatmentDetailsProps {
  script: Script;
  questionnaire: BaseQuestionnaire;
}

export const TreatmentDetails = ({
  script,
  questionnaire
}: TreatmentDetailsProps) => {
  return (
    <>
      <div css={{ fontSize: "14px" }}>
        {questionnaire.consultation?.clinician?.formal_name}
      </div>

      <div css={{ paddingTop: "16px", fontWeight: "500" }}>
        Medication prescribed: {script.product.title}
      </div>

      {script.product.directions && (
        <div css={{ paddingTop: "16px", fontSize: "14px" }}>
          <div css={{ fontWeight: "600", paddingBottom: "4px" }}>
            Direction for use:
          </div>
          <div
            css={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{
              __html: script.product.directions || ""
            }}
          />
        </div>
      )}

      {questionnaire.doctor_feedback && (
        <div css={{ paddingTop: "16px", fontSize: "14px" }}>
          <div css={{ fontWeight: "600", paddingBottom: "4px" }}>
            Notes from your clinician:
          </div>
          <p>{questionnaire.doctor_feedback || "No clinician note given"}</p>
        </div>
      )}

      <div css={{ paddingTop: "16px", fontSize: "14px" }}>
        If you have any urgent concerns, please contact a medical professional
        as soon as possible. For any other questions, feel free to reach out via
        your patient portal at any time.
      </div>
    </>
  );
};
