import { ComponentPropsWithoutRef } from "react";

export const IconProfile = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 15C9.46875 15 10.8438 14.5625 11.9688 13.7812C11.8438 12.2188 10.5625 11 9 11H7C5.40625 11 4.125 12.2188 4 13.7812C5.125 14.5625 6.5 15 8 15ZM12.8438 13.0312C14.1562 11.75 15 9.96875 15 8C15 4.15625 11.8438 1 8 1C4.125 1 1 4.15625 1 8C1 9.96875 1.8125 11.75 3.125 13.0312C3.5625 11.3125 5.125 10 7 10H9C10.8438 10 12.4062 11.3125 12.8438 13.0312ZM8 4C9.375 4 10.5 5.125 10.5 6.5C10.5 7.90625 9.375 9 8 9C6.59375 9 5.5 7.90625 5.5 6.5C5.5 5.125 6.59375 4 8 4ZM8 8C8.8125 8 9.5 7.34375 9.5 6.5C9.5 5.6875 8.8125 5 8 5C7.15625 5 6.5 5.6875 6.5 6.5C6.5 7.34375 7.15625 8 8 8Z"
      fill="currentColor"
    />
  </svg>
);
