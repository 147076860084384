import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { IconCaretDown } from "../icons";

export interface DropDownItem {
  title: string;
  onClick: () => void;
}

export interface DropDownProps {
  title: string;
  items: DropDownItem[];
}

export const DropDown = ({ title, items }: DropDownProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const getDropDownIcon = (open: boolean) => (
    <IconCaretDown
      css={{
        width: "16px",
        height: "16px",
        marginLeft: "10px",
        transition: "all 0.3s ease",
        ...(open && { transform: "rotateX(180deg)" })
      }}
    />
  );

  const renderDropDownItem = (item: DropDownItem, index: number) => (
    <Dropdown.Item
      css={{ padding: "10px 8px", borderBottom: "1px solid #eee" }}
      key={index}
      onClick={item.onClick}
    >
      {item.title}
    </Dropdown.Item>
  );

  return (
    <Dropdown onToggle={() => setOpen(!open)}>
      <Dropdown.Toggle
        css={{
          backgroundColor: "transparent",
          color: "#000",
          borderRadius: "4px",
          ":after": {
            display: "none"
          }
        }}
      >
        {title}
        {getDropDownIcon(open)}
      </Dropdown.Toggle>
      <Dropdown.Menu css={{ padding: 0, borderRadius: "4px" }} show={open}>
        {items.map(renderDropDownItem)}
      </Dropdown.Menu>
    </Dropdown>
  );
};
