import { useState } from "react";
import { Form, Row } from "react-bootstrap";

import { QuestionnaireContentComponentProps } from ".";

export const QCShortText = (props: QuestionnaireContentComponentProps) => {
  const [value, setValue] = useState<string>(props.initialValue ?? "");

  return (
    <Row>
      <Form.Control
        onChange={(event) => {
          const v = event.target.value;
          props.onChange(v);
          setValue(v);
        }}
        value={value}
        autoFocus={true}
        placeholder={props.question.placeholder}
      />
    </Row>
  );
};
