import moment from "moment";
import Common from "../common/common";
import Commmodal from "../../components/common/commmodal";
import jQuery from "jquery";

import { createOptions } from "../../utils";
import COMMTXT from "../common/commontext";
import {
  breakClinicalDetailsIntoSeparateQuestions,
  loadProducts,
  objectIsProduct
} from "./utils";
import {
  User,
  API,
  PatientAPI,
  OexaAPI,
  getEmployeeHealthCheckScore
} from "@mh/api";
import {
  SafeURLSearchParams,
  sendDataLayer,
  Sentry,
  Validation,
  bmi
} from "@mh/core";
import { nibInternationalSearchParam } from "@mh/core/src/constants";
import { MapDeliveryOptions } from "../common/enums";

import {
  getNibInterContents,
  checkMembership,
  NibEligibilityStatus
} from "./NibInternational";

import { DobCustomEndPage, IsCustomEndPage } from "./CustomEndPage";
import { Login } from "@mh/components/src/login/Login";
import { Toast, isColdChainProduct, RedirectModal } from "@mh/components";

const inputmap = {
  address: [
    {
      name: "addressLine1",
      require: true,
      msg: "Address line 1 is a required field."
    },
    { name: "addressLine2", require: false },
    { name: "suburb", require: true, msg: "Suburb is a required field." },
    { name: "address_state", require: true, msg: "State is a required field." },
    {
      name: "postcode",
      title: "Postcode",
      require: true,
      msg: "Postcode is a required field.",
      number: true,
      length_: 4
    }
  ],
  dob: [
    {
      name: "date_picker",
      require: true,
      msg: "Please enter your date of birth."
    }
  ],
  date: [
    {
      name: "date_picker",
      require: true,
      msg: "Please select a date before continuing."
    }
  ],
  bloodPressure: [
    {
      name: "lowBlood",
      require: true,
      msg: "Diastolic (bottom number) is required.",
      number: true
    },
    {
      name: "highBlood",
      require: true,
      msg: "Systolic (top number) is required.",
      number: true
    }
  ],
  name: [
    {
      name: "first_name",
      require: true,
      msg: "Please enter your first name in the field provided",
      type: "name"
    },
    {
      name: "last_name",
      require: true,
      msg: "Please enter your last name in the field provided",
      type: "name"
    }
  ],
  email: [
    {
      name: "useremail",
      title: "Email",
      require: true,
      msg: "Please enter your email address.",
      type: "email"
    },
    {
      name: "confirmuseremail",
      title: "Confirm Email",
      msg: "The emails provided do not match.",
      type: "confirmemail",
      isValidFn: () => {
        const emailInput = document.getElementById("useremail");
        const confirmEmailInput = document.getElementById("confirmuseremail");
        if (!emailInput || !confirmEmailInput) {
          // If we happen to validate when either field is not present, just pass the check
          return true;
        }
        return (
          emailInput.value &&
          emailInput.value?.trim().toLowerCase() ===
            confirmEmailInput.value?.trim().toLowerCase()
        );
      }
    }
  ],
  singleEmail: [
    {
      name: "useremail",
      title: "Email",
      require: true,
      msg: "Please enter your email address.",
      type: "email"
    }
  ],
  emailAndName: [
    {
      name: "useremail",
      title: "Email",
      require: true,
      msg: "Please enter your email address.",
      type: "email"
    },
    {
      name: "first_name",
      require: true,
      msg: "Please enter your first name in the field provided",
      type: "name"
    },
    {
      name: "last_name",
      require: true,
      msg: "Please enter your last name in the field provided",
      type: "name"
    }
  ],
  weight: [
    {
      name: "thisinput",
      title: "Weight",
      require: true,
      msg: "Please enter your weight in the field provided. This is required for our medical team to determine the best treatment options for you."
    }
  ],
  height: [
    {
      name: "thisinput",
      title: "Height",
      require: true,
      msg: "Please enter your height in the field provided."
    }
  ],
  input: [
    {
      name: "thisinput",
      title: "Input",
      require: true,
      msg: "Please enter a response in the field provided."
    }
  ],
  token: [
    {
      name: "token",
      title: "Token",
      require: true,
      msg: "Please enter your token in the field provided.",
      type: "token"
    }
  ],
  medicare: [
    { name: "nomedicare", type: "variable" },
    {
      name: "medicare",
      require: true,
      msg: "Medicare card number is a required field"
    },
    {
      name: "medicareref",
      require: true,
      msg: "Medicare card reference number is a required field"
    },
    {
      name: "medicare-expiry-month",
      require: true,
      msg: "Medicare card expiry month is a required field"
    },
    {
      name: "medicare-expiry-year",
      require: true,
      msg: "Medicare card expiry year is a required field"
    }
  ],
  ihiNumber: [
    { name: "noIhi", type: "variable" },
    {
      name: "ihiNumber",
      require: true,
      msg: "IHI number is a required field"
    }
  ],
  signup: [
    {
      name: "first_name",
      require: true,
      msg: "Please enter your first name in the field provided",
      type: "name"
    },
    {
      name: "last_name",
      require: true,
      msg: "Please enter your last name in the field provided",
      type: "name"
    },
    {
      name: "useremail",
      title: "Email",
      require: true,
      msg: "Please enter your email address in the field provided",
      type: "email"
    },
    {
      name: "addressLine1",
      require: true,
      msg: "Address line 1 is a required field."
    },
    { name: "addressLine2", require: false },
    { name: "suburb", require: true, msg: "Suburb is a required field." },
    {
      name: "address_state",
      id: "address_state",
      require: true,
      msg: "State is a required field."
    },
    {
      name: "postcode",
      id: "postal_code",
      title: "Postcode",
      require: true,
      msg: "Postcode is a required field.",
      number: true,
      length_: 4
    },
    {
      name: "date_picker",
      require: true,
      msg: "Please select a date before continuing."
    }
  ],
  nibinternational: [
    {
      name: "first_name",
      require: true,
      msg: "Please enter your first name in the field provided",
      type: "name"
    },
    {
      name: "last_name",
      require: true,
      msg: "Please enter your last name in the field provided",
      type: "name"
    },
    {
      name: "date_picker",
      require: true,
      msg: "Please select a date before continuing."
    },
    {
      name: "useremail",
      title: "Email",
      require: true,
      msg: "Please enter your email address in the field provided",
      type: "email"
    },
    {
      name: "membership_number",
      require: true,
      msg: "Please enter your membership number in the field provided"
    }
  ]
};

const OpList = {
  waistCircumference: {
    by: "gender",
    ga_id: "A0006",
    female: [
      {
        title: "Size 12",
        value: "Size 12 (72-76cm)"
      },
      {
        title: "Size 14 (M-L)",
        value: "Size 14 (M-L) (77-80cm)"
      },
      {
        title: "Size 16 (L-XL)",
        value: "Size 16 (L-XL) (81-88cm)"
      },
      {
        title: "Size 18 (XL-2XL)",
        value: "Size 18 (XL-2XL) (89-93cm)"
      },
      {
        title: "Size 20 (3XL)",
        value: "Size 20 (3XL) (93-97cm)"
      },
      {
        title: "Size 22 (4XL)",
        value: "Size 22 (4XL) (98-101cm)"
      },
      {
        title: "Size 24 (5XL)",
        value: "Size 24 (5XL) (101-104cm)"
      },
      {
        title: "Size 26 (5XL+)",
        value: "Size 26 (105-109cm)"
      },
      {
        title: "Size 28 (5XL+)",
        value: "Size 28 (110-117cm)"
      },
      {
        title: "Size 30 (5XL+)",
        value: "Size 30 (118-125cm)"
      }
    ],
    male: [
      {
        title: "Size 32 (M)",
        value: "Size 32 (M) (78-82cm)"
      },
      {
        title: "Size 34 (L)",
        value: "Size 34 (L) (84-88cm)"
      },
      {
        title: "Size 36 (XL)",
        value: "Size 36 (XL) (88-92cm)"
      },
      {
        title: "Size 38 (2XL)",
        value: "Size 38 (2XL) (92-97cm)"
      },
      {
        title: "Size 40 (3XL)",
        value: "Size 40 (3XL) (97-102cm)"
      },
      {
        title: "Size 42 (3XL)",
        value: "Size 42 (3XL) (102-108cm)"
      },
      {
        title: "Size 44 (4XL)",
        value: "Size 44 (4XL) (108-112cm)"
      },
      {
        title: "Size 46 (5XL)",
        value: "Size 46 (5XL) (112-117cm)"
      },
      {
        title: "Size 48 (5XL+)",
        value: "Size 48 (6XL) (118-123cm)"
      },
      {
        title: "Size 50 (5XL+)",
        value: "Size 50 (7XL) (123-128cm)"
      }
    ]
  }
};

let lock = "";

const setValueToQuestion = (form, response, depth = 0) => {
  const resObj = Object.assign({}, response);
  const question = Object.assign({}, form);
  question.response_ = resObj;
  question.response = resObj.response;
  question.value = resObj.response;
  question.depth = depth;
  if (resObj.next) question.next = resObj.next;
  if (resObj.nextid) question.nextid = resObj.nextid;
  if (resObj.jump_) question.jump_ = resObj.jump_;
  if (resObj.jumpnext) question.jumpnext = resObj.jumpnext;
  if (resObj.replaceother) question.replaceother = resObj.replaceother;
  return question;
};

async function setOptions(form, env, info, fns, opts = null) {
  if (form.options && form.options.findIndex((obj) => obj && obj.next) > -1) {
    if (opts && opts.lookup) {
      form.lookup = opts.lookup;
      form.pid = form.pid
        ? `${form.pid}${form.pidsurf ? form.pidsurf : ""}`
        : opts && opts.lookup
        ? `${opts.lookup}_${opts.num ? opts.num : "0"}${
            form.pidsurf ? form.pidsurf : ""
          }`
        : "";
    }
    const options = [];
    for (let i = 0; i < form.options.length; i++) {
      const form_ = await setOptions(form.options[i], env, info, fns, {
        isoption: true,
        lookup: form.pid
          ? `${form.pid}${form.pidsurf ? form.pidsurf : ""}`
          : opts && opts.lookup
          ? `${opts.lookup}`
          : "",
        num: i
      });
      options.push(form_);
    }

    form.options = options;
    if (form.from_ === "db") {
      const products = !form.contraindications
        ? fns.productList
        : await loadProducts(
            env.name,
            null,
            true,
            undefined,
            form.contraindications
          );
      form = setDBOption(form, products);
    }
    return form;
  } else if (form.next) {
    if (opts && opts.lookup && !opts.isoption) {
      form.lookup = opts.lookup;
      form.pid = form.pid
        ? `${form.pid}${form.pidsurf ? form.pidsurf : ""}`
        : opts && opts.lookup
        ? `${opts.lookup}_${opts.num ? opts.num : "0"}${
            form.pidsurf ? form.pidsurf : ""
          }`
        : "";
    }
    const forms = [];
    for (let i = 0; i < form.next.length; i++) {
      const form_ = await setOptions(form.next[i], env, info, fns, {
        lookup: form.pid
          ? `${form.pid}`
          : opts && opts.lookup
          ? `${opts.lookup}`
          : "",
        num: i
      });
      forms.push(form_);
    }
    form.next = Array.isArray(forms) && forms.length > 0 ? forms : [form.next];
    return form;
  }
  if (
    form.from_ === "db" &&
    lock !== form?.ga_id &&
    (!form.options ||
      !Array.isArray(form.options) ||
      form.options.findIndex((option) => option?.price) <= -1)
  ) {
    lock = form.ga_id;
    const products = !form.contraindications
      ? fns.productList
      : await loadProducts(
          env.name,
          null,
          true,
          undefined,
          form.contraindications
        );
    form = setDBOption(form, products);
    lock = false;
  }
  if (opts && opts.lookup) {
    form.lookup = opts.lookup;
  }
  return form;
}

function setFromValuesFromProspect(
  qform,
  prospectform,
  newqform,
  depth,
  start_,
  qindex,
  next = 0,
  opts = null
) {
  let result = { start_, next, qindex };
  let lastqus = {};
  if ((qform.options && depth === 0) || (qform && depth > 0)) {
    const subforms = qform.options
      ? qform.options
      : Array.isArray(qform)
      ? qform
      : [qform];
    for (let i = 0; i < subforms.length; i++) {
      if (!prospectform[result.start_]) {
        break;
      }
      if (
        qform.coldchaindelivery &&
        ((qform.coldchaindelivery === "ok" && !opts.iscoldchain) ||
          (qform.coldchaindelivery === "nok" && opts.iscoldchain))
      ) {
        continue;
      }

      if (
        prospectform[result.start_].ga_id === qform.ga_id &&
        qform.type &&
        prospectform[result.start_].response
      ) {
        const tmp = setValueToQuestion(
          qform,
          prospectform[result.start_],
          depth
        );
        newqform.push(tmp);
        result.start_ += 1;
        if (depth === 0) {
          result.qindex++;
          lastqus = tmp;
        }
      }
      if (subforms[i].next) {
        for (let j = 0; j < subforms[i].next.length; j++) {
          result = setFromValuesFromProspect(
            subforms[i].next[j],
            prospectform,
            newqform,
            depth + 1,
            result.start_,
            result.qindex,
            next + 1,
            opts
          );
        }
      }
    }
  }
  if (depth === 0 && lastqus && lastqus.id) {
    result.lastqus = lastqus;
  }
  return result;
}

function isAccept(tmp, acceptSet) {
  let accept = false;
  let count = 0;
  for (let i = 0; i < tmp.total; i++) {
    if (tmp.checkvalues[i]) {
      count++;
    } else {
      break;
    }
  }
  if (count === tmp.total) accept = true;
  acceptSet(accept);
}

function qusrestructure(
  questionnaire_,
  nextobj,
  setSubindex,
  subindex_,
  depth,
  _nextid
) {
  const newqus = [];
  questionnaire_.map((qus) => {
    newqus.push(qus);
    if (qus.pid && qus.pid === nextobj.lookup) {
      nextobj.parent_ = qus;
      newqus.push(nextobj);
      setSubindex(subindex_ + 1);
    }
  });
  return newqus;
}

/**
 * Sets items loaded from the database as form options
 * @param {object} form The questionnaire question
 * @param {import("./utils").ProductWithPrice} products The products which have been loaded
 * @returns The updated form
 */
function setDBOption(form, products) {
  if (form.excludeProd) {
    products = products.find(
      (prod) =>
        form.excludeProd.findIndex(
          (exclude) =>
            prod.title?.toLowerCase() !== exclude.title?.toLowerCase() &&
            prod.id !== exclude.id
        ) > -1
    );
  }

  if (form.useoption) {
    const ops = [];
    for (let i = 0; i < form.options.length; i++) {
      for (let j = 0; j < products.length; j++) {
        if (!products[j]?.availability?.is_available_to_buy) {
          products[j].soldOut = true;
        }
        if (
          !form.options[i].nodb &&
          form.options[i].productnm &&
          ((Array.isArray(form.options[i].productnm) &&
            form.options[i].productnm.includes(products[j].title)) ||
            form.options[i].productnm === products[j].title)
        ) {
          products[j].productnm = Array.isArray(form.options[i].productnm)
            ? form.options[i].productnm.find((p) => p === products[j].title)
            : form.options[i].productnm;
          products[j].product = products[j].title;
          products[j].title = form.options[i].title;
          ops.push(products[j]);
          break;
        } else if (form.options[i]?.nodb) {
          ops.push(form.options[i]);
          break;
        }
      }
    }
    form.options = ops;
  } else {
    const prod = Array.isArray(products) ? products : [products];
    form.options = prod.map((product) => {
      if (!product?.availability?.is_available_to_buy) {
        product.soldOut = true;
      }
      return product;
    });
  }
  if (form.addoptions) {
    form.options = [...form.options, ...form.addoptions];
  }

  return form;
}

const checkQusCondition = (condtype, condObjs, questionnaires, opts) => {
  const results = [];
  // Additional action to be performed if the condition is met
  const actions = [];

  for (let i = 0; i < questionnaires.length; i++) {
    const providedResponses = questionnaires[i].response;

    const comparisonFunctions = {
      string: (a, b) => (b instanceof RegExp ? b.test(a) : a === b),
      regex: (a, b) => a === b,
      array: (a, b) => a.includes(b)
    };

    const type = Array.isArray(providedResponses)
      ? "array"
      : typeof providedResponses;
    const comparator =
      comparisonFunctions[type] !== undefined
        ? comparisonFunctions[type]
        : comparisonFunctions.array;

    for (let j = 0; j < condObjs.length; j++) {
      if (condObjs[j]?.ga_id && questionnaires[i].ga_id === condObjs[j].ga_id) {
        const conditionResponses = condObjs[j].response;

        let responseProvided = false;
        for (let k = 0; k < conditionResponses.length; k++) {
          const conditionResponse = conditionResponses[k];

          if (comparator(providedResponses, conditionResponse)) {
            responseProvided = true;
            break;
          }
        }

        results.push(responseProvided);
      }
    }
  }

  for (let i = 0; i < condObjs.length; i++) {
    const { field, act } = condObjs[i];
    let thisSuccess = false;
    if (field && opts) {
      switch (condObjs[i]?.type) {
        case "gt":
          thisSuccess = opts[field] < condObjs[i].value;
          break;
        case "lt":
          thisSuccess = opts[field] > condObjs[i].value;
          break;
        case "bool":
          thisSuccess = opts[field] === condObjs[i].value;
          thisSuccess ||= condObjs[i].value && opts[field];
          thisSuccess ||= !condObjs[i].value && !opts[field];
          break;
        case "exists":
          thisSuccess =
            opts[field] !== null &&
            opts[field] !== undefined &&
            opts[field] !== "";
          break;
      }
      results.push(thisSuccess);
      if (thisSuccess && act && opts[condObjs[i].act]) {
        actions.push(opts[condObjs[i].act]);
      }
    }
  }

  /*  
    results can be empty array, if
    1. field or ga_id do not exist because question is bypass (Usually, if it is prospect form)
    2. Wrong ga_id or field, in case, we need to fix the question
  */
  if (results.length === 0) {
    Sentry.captureMessage(
      "There are no condition match such as no fields or ga_ids: 3403"
    );
    return false;
  }

  const result =
    results.length === 1
      ? results[0]
      : results.reduce(
          {
            and: (a, b) => a && b,
            or: (a, b) => a || b
          }[condtype ?? "and"]
        );

  if (result) actions.forEach((action) => action());
  return result;
};

const getFiedlValueFromForm = (ga_id, form) => {
  const question = form.find((qus) => qus.ga_id === ga_id)?.response;
  const value = question
    ? Array.isArray(question)
      ? question[0]?.toLowerCase()
      : question?.toLowerCase()
    : false;
  return value;
};

const updateClinicalDetails = (
  updateField,
  value,
  clinicalDetails,
  setClinicalDetails,
  mergeValue
) => {
  let updateValue = value[0];
  if (mergeValue) {
    updateValue = `${clinicalDetails[updateField]}\n${value[0]}`;
  }
  const newClinicalDetails = { ...clinicalDetails, [updateField]: updateValue };
  setClinicalDetails(newClinicalDetails);
};

class Basequestionnaire extends Common {
  constructor() {
    super();
    this.setMedicationDataByToken = this.setMedicationDataByToken.bind(this);
    this.setEndPage = this.setEndPage.bind(this);
    this.setSubmitLoading = this.setSubmitLoading.bind(this);
    this.reCreateQuestionnaire = this.reCreateQuestionnaire.bind(this);
    this.setSubEnvInfo = this.setSubEnvInfo.bind(this);
    this.checkbyType = this.checkbyType.bind(this);
    this.redirectToStartByMalformed =
      this.redirectToStartByMalformed.bind(this);
    this.checkResponse = this.checkResponse.bind(this);
    this.checkParent = this.checkParent.bind(this);
    this.setBrandData = this.setBrandData.bind(this);
    this.addResponse = this.addResponse.bind(this);
    this.excludeQuestionnaire = this.excludeQuestionnaire.bind(this);
    this.onChangeMultiChoice = this.onChangeMultiChoice.bind(this);
    this.checkBeforeSubmit = this.checkBeforeSubmit.bind(this);
    this.gtmpro = this.gtmpro.bind(this);
    this.setGtmQus = this.setGtmQus.bind(this);
    this.getExtraFields = this.getExtraFields.bind(this);
    this.checkandsetupBasicInfo = this.checkandsetupBasicInfo.bind(this);
    this.setBasicQuestionnaireInfo = this.setBasicQuestionnaireInfo.bind(this);
    this.rehandleCheck = this.rehandleCheck.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.setResponseForm = this.setResponseForm.bind(this);
    this.hasPaymentInfo = this.hasPaymentInfo.bind(this);
    this.createButtonText = this.createButtonText.bind(this);
    this.checkSubmitValidation = this.checkSubmitValidation.bind(this);
    this.checkandSetQuestionnairedata =
      this.checkandSetQuestionnairedata.bind(this);
    this.setQuestionnaire = this.setQuestionnaire.bind(this);
    this.validation = this.validation.bind(this);
    this.getParameterByName = this.getParameterByName.bind(this);
    this.postpatientdata = this.postpatientdata.bind(this);
    this.createFullForm = this.createFullForm.bind(this);
    this.checkproduct = this.checkproduct.bind(this);
    this.getJsonProspectForm = this.getJsonProspectForm.bind(this);
  }

  async setMedicationDataByToken(data, slug, setMedicationList, setBrandpill) {
    if (data.shop_product?.id) {
      let products = await loadProducts(
        slug,
        true,
        true,
        data.shop_product.id,
        undefined
      );
      // for fill-a-script, we currently do not give patients the option to select a compounded medication
      products = products.filter((product) => !product.is_compounded);
      if (products && Array.isArray(products) && products.length > 0) {
        setMedicationList(products);
      } else {
        setBrandpill(data.shop_product);
      }
      return true;
    }
    return false;
  }

  setEndPage(param_, productenv, setData, opts = null) {
    const options = {
      target: "_blank",
      linkclass_: "btn btn-primary text-decoration-none endpagebt mt-3"
    };

    if (param_.obj_?.replace) {
      options.replace = param_.obj_?.replace[this.Config().FROM]
        ? param_.obj_?.replace[this.Config().FROM]
        : false;
    }

    const message =
      opts?.nomedicare && productenv.medicare?.end
        ? productenv.medicare?.message
        : param_.obj_ && param_.obj_.end
        ? param_.obj_.isCustomizeMessage
          ? param_.obj_.message[productenv.code]
          : param_.obj_.isdata && !param_.obj_.replace
          ? this.getRawDatafromText(param_.obj_.message, null, opts.reason_)
          : param_.obj_.message
        : param_.currentquestion_.message
        ? param_.currentquestion_.message
        : "";

    if (message?.url) {
      setTimeout(() => {
        window.location.replace(message.replace[this.Config().FROM]);
      }, 300);
      return false;
    }

    setData(
      <div className="inner-questionnaire-page" id="end-page">
        <h2 style={{ textAlign: "center" }}>
          {param_.obj_ && param_.obj_.end
            ? param_.obj_.endTitle
              ? param_.obj_.endTitle
              : ""
            : param_.currentquestion_.title
            ? param_.currentquestion_.title
            : ""}
        </h2>
        <h3 style={{ textAlign: "center" }}>
          {param_.obj_ && param_.obj_.isdata
            ? this.getDatafromText(message, null, null, "", options)
            : message}{" "}
        </h3>
        {param_?.obj_?.submessage && (
          <p
            className="qsubtitle text-center"
            css={{
              whiteSpace: "pre-wrap",
              fontSize: "16px",
              lineHeight: "135%"
            }}
          >
            {param_.obj_.submessage}
          </p>
        )}
        {productenv.addendtxt && (
          <h3 className="pt-3">{productenv.addendtxt}</h3>
        )}

        {param_?.obj_?.button && (
          <div className="d-flex flex-column">
            <button
              css={(theme) => ({
                maxWidth: "342px",
                width: "100% !important",
                padding: "8px !important",
                marginLeft: "auto",
                marginRight: "auto",
                lineHeight: "normal",
                minHeight: "35px !important",
                height: "unset !important",
                borderColor: `${theme.color.primary} !important`,
                borderRadius: "50px !important",
                [theme.mq.md]: {
                  maxWidth: "431px !important",
                  width: "100% !important"
                },
                [theme.mq.lg]: {
                  width: "431px !important",
                  maxWidth: "unset  !important"
                },
                "&:hover, &:active, &.active": {
                  color: "#fff !important",
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
                }
              })}
              className="btn btn-outline-primary mt-3 nounderline"
              key="link_end_button"
              onClick={async (e) => {
                e.preventDefault();
                try {
                  sendDataLayer("questionnaireRedirect", {
                    from: param_?.obj_?.gtmFrom,
                    to: param_?.obj_?.gtmTo
                  });
                } catch (e) {
                  Sentry.captureException(e);
                } finally {
                  location.href =
                    param_?.obj_?.goTo ??
                    `/questionnaire/?product=${param_?.obj_?.product}`;
                }
              }}
            >
              {param_?.obj_?.button}
            </button>
          </div>
        )}

        <br></br>
        <div className="footer">
          <div className="single-button"></div>
        </div>
      </div>
    );
  }

  setSubmitLoading(loader, release = false, id = null, btid = null) {
    const btloadersubmit = document.getElementById(id ?? "submit");
    const btloader = document.getElementById(btid ?? "loading");

    if (release) {
      loader.className = loader.className.replace(/show/g, "");
      btloadersubmit.style.display = "block";
      btloader.style.display = "none";
    } else {
      loader.className = "show";
      btloadersubmit.style.display = "none";
      btloader.style.display = "block";
    }
  }

  async reCreateQuestionnaire(
    object,
    questionnaire,
    questionnaireid,
    nextid,
    subindex,
    setQuestionnaireid,
    setNextId,
    setSubindex,
    qSet,
    opts
  ) {
    const isReCreate = checkQusCondition(
      object.cond,
      object.condobjs,
      questionnaire,
      opts
    );

    if (isReCreate) {
      const backIndex = questionnaire.findIndex(
        (qus) => qus?.ga_id === object.to
      );
      let nextid_ = nextid;
      let questionnaireid_ = questionnaireid;
      const subindex_ = subindex;
      let questionnaire_ = questionnaire;

      for (let i = questionnaire.length; i > backIndex + 1; i--) {
        // ToDo
        // For now is fine, because it only repeat a script.
        // However, need to add consider for subindex / subindex2
        if (nextid_ > 0 && questionnaire_[questionnaire_.length - 1].nextid) {
          nextid_ -= 1;
        } else {
          questionnaireid_ -= 1;
        }

        questionnaire_ = questionnaire_.slice(0, questionnaire_.length - 1);
      }
      setQuestionnaireid(questionnaireid_);
      setNextId(nextid_);
      setSubindex(subindex_);
      qSet(questionnaire_);
    }

    return isReCreate;
  }

  async setSubEnvInfo(from, productenv, opts) {
    if (
      !from ||
      !productenv.customBrand ||
      !productenv.customBrand.includes(this.Config().FROM)
    )
      return false;

    opts.setFromParam(from);
    opts.showIhiQuestionSet(["yes"]);

    const contents =
      from === nibInternationalSearchParam
        ? getNibInterContents(this.Config().FROM, productenv)
        : "";
    if (contents) {
      for (const property in contents) {
        if (contents[property]) {
          productenv[property] = contents[property];
        }
        if (property === "logo") {
          opts.setNewLogoClass(`${from}-logo`);
          opts.setNewLogo(productenv.logo);
        }
      }
    }
  }

  checkbyType(
    info,
    attr,
    value,
    values = null,
    result = {},
    fns = null,
    add = null
  ) {
    if (
      attr.type === "email" &&
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      result.vaild = false;
      result.msg = "Please enter a valid email address.";
      return result;
    }

    if (
      attr.type === "name" &&
      Validation.name().validate(value) &&
      (!add || (add && (!add.productenv || !add.productenv.escript)))
    ) {
      // #https://stackoverflow.com/a/47722835 regex for name validation with special chars
      result.vaild = false;
      result.msg = "Please enter a valid name.";
      return result;
    }
    if (attr.number && !fns.isNumber(value)) {
      result.vaild = false;
      result.msg = "The response to this question must be a number.";
      return result;
    }
    if (attr.length_ && value.length !== attr.length_) {
      result.vaild = false;
      result.msg = `The response provided must be ${attr.length_} characters long.`;
      return result;
    }
    if (
      info.range &&
      info.range.max &&
      !fns.checkNumberRage(value, info.range)
    ) {
      const min = info?.range?.min;
      const max = info?.range?.max;

      if (min && max) {
        result.msg = `Please enter a value between ${min} and ${max} (inclusive).`;
      } else if (min) {
        result.msg = `Please enter a value greater than or equal to ${min}.`;
      } else if (max) {
        result.msg = `Please enter a value less than or equal to ${max}.`;
      } else {
        result.msg = "The response to this question must be a number.";
      }

      result.vaild = false;
      return result;
    }

    if (attr.type === "token") {
      if (value.length < 8) {
        result.vaild = false;
        result.msg = `${attr.name} must be at least 8 characters long.`;
        return result;
      }
      if (value.length > 32) {
        result.vaild = false;
        result.msg = `eScript token exceeds max length of 32 characters.`;
        return result;
      }
      if (!/^[a-z0-9]+$/i.test(value)) {
        result.vaild = false;
        result.msg = `${attr.name} can only contain letters and numbers.`;
        return result;
      }
    }
    values ? (values[attr.name] = value) : "";
    result.vaild = true;

    return result;
  }

  redirectToStartByMalformed(
    isCheckRes = false,
    questionnaire = null,
    questionnairedata = null,
    productenv,
    useremail,
    setUnload,
    from = null
  ) {
    const isNotMalformed = this.checkResponse(
      isCheckRes,
      questionnaire,
      questionnairedata,
      productenv,
      useremail,
      from
    );

    if (!isNotMalformed) {
      setUnload(false);
      Toast.error(
        "Sorry, we were unable to process your request at this time. Please try again and contact our Patient Success team if the issue persists [Error 2111]."
      );
      setTimeout(() => {
        try {
          location.reload();
        } catch (err) {
          window.location.reload();
        }
      }, 1000);
      return false;
    }
  }

  checkResponse(
    isCheckRes = false,
    questionnaire = null,
    questionnairedata = null,
    productenv,
    useremail,
    from = null
  ) {
    if (!isCheckRes) return true;
    let notok = false;
    for (let i = 0; i < questionnairedata.length; i++) {
      const qus = questionnairedata[i];
      if (qus.must) {
        const question = questionnaire.find((q) => q.ga_id === qus.ga_id);
        const qusc = question?.response_?.response
          ? question.response_
          : question;
        if (!qusc) {
          notok = true;
        } else {
          if (
            !question ||
            question?.ga_id !== qusc?.ga_id ||
            (qusc.ga_id && qus.ga_id !== qusc.ga_id) ||
            qus.options.findIndex(
              (q) => qusc.response && qusc.response.includes(q.title)
            ) <= -1
          ) {
            try {
              Sentry.captureMessage(
                `[${productenv.name}] ${useremail} has mismatched answer or ga_id from the ${from}: ${question?.ga_id} ${qusc?.ga_id}-${qus?.ga_id}-${qus?.title}: 2111`
              );
            } catch (err) {}
            notok = true;
          }
        }
      }
      if (notok) {
        break;
      }
    }

    return !notok;
  }

  async checkParent(prodenv) {
    const result = await this.getPtQus("basequestionnaire");

    if (!result || result.iserror) {
      return {
        iserror: true,
        msg: "Something went wrong. Please contact us if this issue persists"
      };
    }

    let isparent = false;
    for (let i = 0; i < result.patientquestionnaire.length; i++) {
      if (
        result.patientquestionnaire[i].product &&
        result.patientquestionnaire[i].product.length > 0 &&
        result.patientquestionnaire[i].product.toLowerCase() === prodenv.parent
      ) {
        isparent = true;
        break;
      }
    }
    if (!isparent) {
      return {
        iserror: true,
        msg: "Something went wrong. Please contact us if this issue persists"
      };
    }
    return true;
  }

  setBrandData(info) {
    info.setBrandpillTitle(
      info.obj_.display_name
        ? info.obj_.display_name
        : info.obj_.title
        ? info.obj_.title
        : ""
    );
    info.setBrandpill(
      info.obj_.brand_pill
        ? info.obj_.brand_pill
        : info.obj_.title
        ? info.obj_.title
        : ""
    );
    info.setPrice(info.obj_.price);
    info.setDuration(info.obj_.duration);
    info.setProduct
      ? info.setProduct(null, {
          id: info.obj_.id,
          price: info.obj_.price,
          title: info.obj_.display_name
            ? info.obj_.display_name
            : info.obj_.title
            ? info.obj_.title
            : ""
        })
      : "";
  }

  addResponse(form, data) {
    form.response_ = {
      title: form.title,
      response: [data.option.title],
      value: [data.option.title],
      id: form.id,
      ga_id: form.ga_id ? form.ga_id : "",
      depth: form.depth ? form.depth : 0,
      next: false
    };
  }

  excludeQuestionnaire(name, productenv, questionnaires) {
    let result = [];
    if (
      productenv &&
      productenv.excludeque &&
      productenv.excludeque.findIndex((ex) => ex.from === name) > -1
    ) {
      for (let i = 0; i < questionnaires.length; i++) {
        let isexclude = false;
        for (let j = 0; j < productenv.excludeque.length; j++) {
          if (
            productenv.excludeque[j].from === name &&
            ((productenv.excludeque[j].id &&
              parseInt(productenv.excludeque[j].id) === questionnaires[i].id) ||
              (productenv.excludeque[j].ga_id &&
                parseInt(productenv.excludeque[j].ga_id) ===
                  questionnaires[i].ga_id) ||
              productenv.excludeque[j].id === "all")
          ) {
            isexclude = true;
            break;
          }
        }
        if (!isexclude) {
          result.push(questionnaires[i]);
        }
      }
    } else {
      result = questionnaires;
    }
    return result;
  }

  onChangeMultiChoice(e, obj = null, currentquestion_ = null) {
    if (e.target.checked && currentquestion_) {
      jQuery(`#outer-ck-${currentquestion_.id}-${e.target.id}`).addClass(
        "checked-bg"
      );
    } else {
      jQuery(`#outer-ck-${currentquestion_.id}-${e.target.id}`).removeClass(
        "checked-bg"
      );
    }

    const NONE_OF_THE_ABOVE_VARIATIONS = [
      "none of the above",
      "no",
      "none apply to me",
      "no, i have none of the above",
      "no, i haven’t",
      "nothing to report",
      "none",
      "no, i have not had weight loss surgery",
      "no preference",
      "i do not take any of these medications"
    ];
    const titlesMarkedAsMutuallyExclusive = currentquestion_.options
      .filter((option) => option?.isMutuallyExclusive)
      .map((option) => option.title.toLowerCase());
    const mutuallyExclusiveQuestionTitles = NONE_OF_THE_ABOVE_VARIATIONS.concat(
      titlesMarkedAsMutuallyExclusive
    );

    if (obj.txtarea && obj.txtarea === "yes") {
      const id_ = obj.tid ? obj.tid : "multi-other";
      if (e.target.checked) {
        jQuery(`#${id_}`).removeClass("d-none");
      } else {
        jQuery(`#${id_}`).addClass("d-none");
        jQuery(`#${id_}-text`).val("");
      }
    }

    const thisinput = document.getElementsByName("thisinput[]");
    let targetInNONE = false;
    let checkedInNONE = false;
    // check if thisinput[i] for thisinput[i].id == e.target.id is checked and thisinput[i].value in mutuallyExclusiveQuestionTitles
    // clear rest of the checkedboxes, keeping only thisinput[i]
    thisinput.forEach((checkbox) => {
      if (checkbox.id == e.target.id) {
        if (
          mutuallyExclusiveQuestionTitles.includes(checkbox.value.toLowerCase())
        ) {
          targetInNONE = true;
        }
      } else {
        if (
          mutuallyExclusiveQuestionTitles.includes(checkbox.value.toLowerCase())
        ) {
          checkedInNONE = true;
        }
      }
    });

    if (targetInNONE) {
      // clear everything except NONE
      thisinput.forEach((checkbox, index) => {
        if (checkbox.id != e.target.id) {
          thisinput[index].checked = false;
          jQuery(thisinput[index]).parent().removeClass("checked-bg");
        }
      });
    } else if (checkedInNONE) {
      // clear NONE
      thisinput.forEach((checkbox, index) => {
        if (
          mutuallyExclusiveQuestionTitles.includes(checkbox.value.toLowerCase())
        ) {
          thisinput[index].checked = false;
          jQuery(thisinput[index]).parent().removeClass("checked-bg");
        }
      });
    }
  }

  async checkBeforeSubmit(param, opts) {
    let errormsg = "";
    if (!param.isLoggedIn) {
      const email_ = opts?.email;
      if (!email_) {
        errormsg = "Please enter a valid email address.";
      } else {
        const result1 = await this.checkUserByEmail(
          email_,
          "questionnaire",
          opts?.noloading
        );
        if (!result1) {
          errormsg =
            "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 3548].";
          Sentry.captureMessage("Error while processing your request: 3548");
        }

        if (result1 && result1.authenticateUser) {
          errormsg =
            "A user already exists with the specified email address. Please log in or contact our Patient Success team if you need further assistance.";
        }
      }
    }
    return errormsg;
  }

  gtmpro(info) {
    this.logger(this.Config().STATE, "basequestionnaire", "gtmpro start", {
      info
    });
    const subindex_ = info.subindex > 1 ? info.subindex - 1 : 0;
    const currentquestion_ = info?.curView
      ? info.questionnaire
      : info.questionnaire[info.questionnaire.length - 1 - subindex_];
    if (import.meta.env.VITE_GID && currentquestion_) {
      if (!info.gaonce && currentquestion_.gtmstart) {
        this.setGtmQus("qstart", {
          currentquestion_,
          patientprospectid: info.patientprospectid,
          first_name: info.first_name ? info.first_name : "",
          last_name: info.last_name ? info.last_name : "",
          db: info.dob_year
            ? `${info.dob_year}${info.dob_month}${info.dob_day}`
            : "",
          suburb: info.suburb ? info.suburb : "",
          productenv: info.productenv,
          setGaonce: info.setGaonce,
          isnewuser_: info.isnewuser_ ? info.isnewuser_ : false,
          by: info.by ? info.by : false,
          _hasscript: info._hasscript ? info._hasscript : "",
          _scriptname: info._scriptname ? info._scriptname : "",
          patientId: info.patientid ? info.patientid : ""
        });
      }
      if (
        info.curView ||
        !info.prequs ||
        info.prequs !== currentquestion_.title
      ) {
        if (!info.curView) info.prequsSet(currentquestion_.title);
        this.setGtmQus("qproc", {
          event: info.curView ? "questionnaireQuestionViewed" : "questionnaire",
          currentquestion_,
          patientprospectid: info.patientprospectid,
          productenv: info.productenv,
          isnewuser_: !User.loggedIn(),
          _hasscript: info._hasscript ? info._hasscript : "",
          _scriptname: info._scriptname ? info._scriptname : "",
          patientId: info.patientid ? info.patientid : ""
        });
      }
    }
    this.logger(this.Config().STATE, "basequestionnaire", "gtmpro end");
  }

  setGtmQus(type_, info) {
    if (!info) return false;
    this.logger(this.Config().STATE, "basequestionnaire", "start setGtmQus", {
      info
    });
    const gtminfo = {
      userType: info.isnewuser_
        ? "New User"
        : info.by
        ? "Returning User"
        : info.productenv.once
        ? "Returning User - No Subscription"
        : "Returning User - New Product",
      productname: info.productenv.name ? info.productenv.name : "",
      uploadscript:
        info._hasscript || info.productenv.script
          ? info._scriptname
            ? "Prescription Uploaded"
            : "Prescription Treatments"
          : info.productenv.once
          ? "Non-Prescription Treatments"
          : "Prescription Treatments",
      scriptType:
        info._hasscript || info.productenv.script
          ? "Prescription Treatments"
          : info.productenv.once
          ? "Non-Prescription Treatments"
          : "Prescription Treatments"
    };

    if (info.patientprospectid) {
      gtminfo.prospectId = info.patientprospectid;
    }

    gtminfo.once = !!info.productenv.once;

    if (info.patientId) {
      gtminfo.patientId = info.patientId;
    }

    if (type_ === "qproc") {
      if (!info.currentquestion_) return false;
      const gaid =
        info.currentquestion_ && info.currentquestion_.ga_id
          ? info.currentquestion_.ga_id
          : info.currentquestion_.id
          ? info.currentquestion_.id
          : "";
      gtminfo.event = info.event;
      gtminfo.questionId = gaid;
      gtminfo.questionTitle = info.currentquestion_.title;
      gtminfo.questionType = info.currentquestion_.questionType
        ? info.currentquestion_.questionType
        : "Product";
    } else if (type_ === "qdisq") {
      const gaid =
        info.currentquestion_ && info.currentquestion_.ga_id
          ? info.currentquestion_.ga_id
          : info.currentquestion_.id
          ? info.currentquestion_.id
          : "";
      gtminfo.event = "questionnaireDisqualification";
      gtminfo.questionType = "common";
      gtminfo.questionId = gaid;
      gtminfo.questionTitle = info.currentquestion_.title;
      gtminfo.disqualificationReason = info.reason;
    } else if (type_ === "qstart" || type_ === "qcomp") {
      gtminfo.event = "ecommerce";
      if (type_ === "qcomp") {
        gtminfo.eventfrom = "Questionnaire Complete (Checkout)";
        gtminfo.paymentoption = info.paymentoption;
        gtminfo.questionnaireId = info.pqid;
        let paymentoption = "Delay Payment";
        if (
          (!info.showpayment || info.showpayment === "yes") &&
          info.productenv.subscription &&
          info.productenv.subscription === "yes"
        ) {
          paymentoption = "Pay Now";
        }
        if (info.productenv.once && info.productenv.once === "yes") {
          paymentoption = "Payment Not Available";
        }
        if (info.isConsult && info.isConsult === "consult") {
          paymentoption = "Pay Now";
          gtminfo.price = opts.consultNewFee ?? 0;
        }
        gtminfo.paymentoption = paymentoption;
        gtminfo.productname = `${gtminfo.productname}${
          info.productenv?.scriptonly
            ? info.script_only
              ? ": Script Only"
              : ": Subscription"
            : ""
        }`;
      } else {
        gtminfo.eventfrom = "Questionnaire Started (Add To Cart)";
      }
      (gtminfo.email = User.email() ?? ""), (gtminfo.fn = info.first_name);
      gtminfo.ln = info.last_name;
      gtminfo.db = info.db;
      gtminfo.city = info.suburb;
      gtminfo.productcode = info.productenv.code;
      if (type_ === "qstart" && info.setGaonce) {
        info.setGaonce(true);
      }
    }
    this.setGtm(type_, { ...gtminfo });
    this.logger(this.Config().STATE, "basequestionnaire", "end setGtmQus");
  }

  getExtraFields(getExtraFields, tokindexSet) {
    const temp = this.convertENVToArray(import.meta.env.VITE_OPTS);
    if (temp && temp.findIndex((t) => t.name === "qresponse") > -1) {
      const { fields } = temp.find((t) => t.name === "qresponse");
      if (fields) {
        getExtraFields(Array.isArray(fields) ? fields : [fields]);
      }
    }
    if (temp && temp.findIndex((t) => t.name === "qtokindex") > -1) {
      const tokindex = temp.find((t) => t.name === "qtokindex").value;
      tokindexSet(tokindex);
      return tokindex;
    }
    return -1;
  }

  async checkandsetupBasicInfo(
    setIsprospect,
    isprospect,
    user_email = null,
    setUserEmail,
    setFirstName,
    setLastName,
    dob_daySet,
    dob_monthSet,
    dob_yearSet,
    setAddressLine1,
    setAddressLine2,
    setSuburb,
    setState,
    setPostcode,
    setPh,
    smokingStatusSet,
    medicareSet,
    medicarerefSet,
    medicareexpSet,
    ihiSet,
    originmedicareSet,
    originmedicarerefSet,
    originmedicareexpSet,
    genderSet,
    setIsGenderProvided,
    productenv
  ) {
    if (!User.loggedIn()) {
      Toast.error("Please log in to access this page.");
      return false;
    } else if (User.loggedIn() && user_email && User.email() !== user_email) {
      Toast.error("Please log in using the correct email to access this page.");
      return false;
    }

    const userinfo = await this.getPtDetails(
      "basequestionnaire->checkandsetupBasicInfo"
    );

    if (
      userinfo.iserror ||
      !userinfo.patientProfiledetails ||
      !userinfo.patientProfiledetails.patient_id
    ) {
      if (userinfo.notoken) {
        User.logout();
        Toast.error("Please log in to access this page.");
      }
      return false;
    }
    if (!user_email) {
      if (userinfo && userinfo.patientProfiledetails && isprospect) {
        setUserEmail(User.email());
        await this.setBasicQuestionnaireInfo(
          userinfo.patientProfiledetails,
          setFirstName,
          setLastName,
          dob_daySet,
          dob_monthSet,
          dob_yearSet,
          setAddressLine1,
          setAddressLine2,
          setSuburb,
          setState,
          setPostcode,
          setPh,
          smokingStatusSet,
          medicareSet,
          medicarerefSet,
          medicareexpSet,
          ihiSet,
          originmedicareSet,
          originmedicarerefSet,
          originmedicareexpSet,
          genderSet,
          setIsGenderProvided,
          productenv
        );
        setIsprospect(false);
        return true;
      }
      Toast.error(
        "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 2439]."
      );
      Sentry.captureMessage("Error while processing your request: 2439");
      return false;
    }
    return true;
  }

  async setBasicQuestionnaireInfo(
    info__,
    setFirstName,
    setLastName,
    dob_daySet,
    dob_monthSet,
    dob_yearSet,
    setAddressLine1,
    setAddressLine2,
    setSuburb,
    setState,
    setPostcode,
    setPh,
    smokingStatusSet,
    medicareSet,
    medicarerefSet,
    medicareexpSet,
    ihiSet = null,
    originmedicareSet = null,
    originmedicarerefSet = null,
    originmedicareexpSet = null,
    genderSet = null,
    setIsGenderProvided = null,
    productenv = null
  ) {
    if (productenv?.addressCheck) {
      const { is_serviceable: isServiceable } =
        await PatientAPI.isAddressServiceable({
          line1: info__.addressLine1 ?? info__.address_line1,
          line2: info__.addressLine2 ?? info__.address_line2,
          suburb: info__.suburb,
          state: info__.state,
          postcode: info__.postcode
        });
      if (!isServiceable) {
        Toast.error("Sorry, we are unable to service this location.");
        return { error: true };
      }
    }

    setFirstName(info__.first_name);
    setLastName(info__.last_name);
    try {
      dob_daySet(info__.dob.split("-")[2]);
      dob_monthSet(info__.dob.split("-")[1]);
      dob_yearSet(info__.dob.split("-")[0]);
    } catch (e) {
      Toast.error(
        "The date of birth associated with your account is invalid. Please go to your profile to review and update this."
      );
      return { error: true };
    }

    setAddressLine1(info__.addressLine1 ?? info__.address_line1); // I don't know enough about the questionnaire to
    setAddressLine2(info__.addressLine2 ?? info__.address_line2); // know if we only need the fallback values
    setSuburb(info__.suburb);
    setState(info__.state);
    setPostcode(info__.postcode);

    smokingStatusSet(info__.smoking_status);
    setPh(info__.mobile);

    if (info__.gender) {
      genderSet(info__.gender);
      setIsGenderProvided(true);
    }

    if (info__.medicare) {
      medicareSet(
        info__.medicare.medicare_no ? info__.medicare.medicare_no : ""
      );
      medicarerefSet(info__.medicare.irn ? info__.medicare.irn : "");
      medicareexpSet(info__.medicare.expiry ? info__.medicare.expiry : "");
      originmedicareSet(
        info__.medicare.medicare_no ? info__.medicare.medicare_no : ""
      );
      originmedicarerefSet(info__.medicare.irn ? info__.medicare.irn : "");
      originmedicareexpSet(
        info__.medicare.expiry ? info__.medicare.expiry : ""
      );
    }
    if (info__.ihi) ihiSet(info__.ihi);

    return true;
  }

  async getPatientquestionnaires(from = null) {
    this.logger(
      this.Config().STATE,
      "basequestionnaire",
      "getPatientquestionnaires",
      "Start"
    );

    const result = await this.getPtQus(
      "basequestionnaire->getPatientquestionnaires"
    );

    if (!result || (result && result.iserror)) {
      return false;
    } else if (result.patientquestionnaire) {
      return result.patientquestionnaire;
    }
  }

  rehandleCheck(checkbts, checkbtsSet, acceptSet) {
    checkbtsSet(checkbts);
    isAccept(checkbts, acceptSet);
  }

  handleCheck(e, data, checkbts, checkbtsSet, acceptSet, from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "handleCheck",
      "Start"
    );

    const tmp = checkbts.total
      ? checkbts
      : { total: data.total, checkvalues: [] };
    for (let i = 0; i < data.total; i++) {
      if (data.no === i) {
        tmp.checkvalues.length >= tmp.total
          ? (tmp.checkvalues[i] = e.target.checked)
          : tmp.checkvalues.push(e.target.checked);
      } else {
        tmp.checkvalues.length >= tmp.total ? "" : tmp.checkvalues.push(false);
      }
    }
    checkbtsSet(tmp);
    isAccept(tmp, acceptSet);

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "handleCheck",
      "End"
    );
  }

  setResponseForm(form, _response, opts, from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "setResponseForm",
      "Start"
    );

    if (form?.response_) {
      if (opts?.submit) {
        if (
          form.response_.response &&
          Array.isArray(form.response_.response) &&
          form.response_.response?.includes("SKIP THIS QUESTION")
        )
          return;
        if (form?.type === "medication") {
          form.response_.response = [opts?.brand_pill];
          form.response_.value = [opts?.brand_pill];
        }
        if (form.replaceVal) {
          const replaceValue = form.options.find(
            (op) =>
              form?.response &&
              Array.isArray(form.response) &&
              op.title === form.response[0]
          )?.matched;
          if (replaceValue) {
            form.response_.response = [replaceValue];
            form.response_.value = [replaceValue];
          }
        }
      }

      if (form?.type === "clinicalDetails") {
        _response.push(
          ...breakClinicalDetailsIntoSeparateQuestions(
            form.id,
            form.response_.response,
            opts?.slug
          )
        );
      } else {
        const data = { ...form.response_ };
        if (form && form.type === "multichoice" && form.options) {
          data.multichoice_options = form.options.map((option) => option.title);
        }

        _response.push(data);
      }
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "setResponseForm",
      "End"
    );
  }

  async hasPaymentInfo(from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "hasPaymentInfo",
      "Start"
    );

    const cardinfo_ = await this.Fetch(
      `${this.Config().HOST}/${
        this.Config().API
      }/patients/me/payment-method?brand=${this.Config().FROM.trim()}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        })
      },
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "hasPaymentInfo",
      false,
      false,
      null,
      false,
      false,
      true
    );

    const cardinfo = this.getResult(cardinfo_, "getCardDetailNew");

    if (
      cardinfo &&
      cardinfo.getCardDetailNew &&
      cardinfo.getCardDetailNew.last4
    ) {
      this.logger(
        this.Config().STATE,
        `${from ? `${from} ->` : ""}basequestionnaire`,
        "hasPaymentInfo: true",
        "End"
      );
      return true;
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "hasPaymentInfo: false",
      "End"
    );
    return false;
  }

  getUid(cookie, from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getUid",
      "Start"
    );

    let uid = [];
    for (let i = 0; i < cookie.length; i++) {
      if (cookie[i].trim().startsWith("_ga=GA")) {
        const idobj_ = cookie[i].split(".");
        const removeheader = idobj_.splice(2, 2);
        uid.push(removeheader.join("."));
      }
    }

    if (uid.length < 1) {
      uid = null;
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getUid",
      "End"
    );
    return uid;
  }

  createButtonText(obj, from = null, opts = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "createButtonText",
      "Start"
    );

    let result = "";
    if (obj.html) {
      result = obj.html;
    } else if (obj.img) {
      result = (
        <div className="row">
          <div
            className={
              obj?.imgOuterStyle
                ? obj?.imgOuterStyle
                : "col-12 col-md-6 col-lg-4 mb-2"
            }
          >
            <img src={obj.img} className={obj?.imgStyle} />
          </div>
          {!obj?.hidetitle && (
            <div
              className={
                "col-12 col-md-6 col-lg-8 v-center text-md-start text-center"
              }
            >
              {obj.html
                ? obj.html
                : User.loggedIn() && obj.title_login
                ? obj.title_login
                : obj.title}
            </div>
          )}
        </div>
      );
    } else if (objectIsProduct(obj) && obj.pharmacy_preferred) {
      // Obscure this product name to a generic one
      result = "Happy to let the pharmacist decide";
    } else {
      result =
        User.loggedIn() && obj.title_login
          ? obj.title_login
          : obj.isdata
          ? this.getDatafromText(
              obj.title,
              null,
              null,
              obj.class_ ? obj.class_ : ""
            )
          : obj.title;
    }

    if (obj.addtxt) {
      result = [result].concat(<div>{obj.addtxt}</div>);
    }

    if (
      (opts?.showprice && obj?.price) ||
      (obj?.soldOut && !opts?.hideSoldOut)
    ) {
      let bottomContents = "";
      if (opts?.useOption && obj?.soldOut && !opts?.showprice) {
        bottomContents = (
          <span
            key="soldout-block"
            css={{
              color: "red",
              fontSize: "12px",
              paddingLeft: "5px"
            }}
          >
            (Sold Out)
          </span>
        );
      } else {
        bottomContents = (
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            {opts?.showprice && obj?.price && (
              <div
                key="price-block"
                css={{
                  fontWeight: "700"
                }}
              >
                ${parseFloat(obj.price)?.toFixed(2)}
              </div>
            )}
            {obj?.soldOut && (
              <div
                key="soldout-block"
                css={{
                  color: "red",
                  fontSize: "12px"
                }}
              >
                Sold Out
              </div>
            )}
          </div>
        );
      }
      result = [result].concat(bottomContents);
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "createButtonText",
      "End"
    );
    return result;
  }

  checkSubmitValidation(info, from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "checkSubmitValidation",
      "Start"
    );

    const typset = info?.typeset;

    if (
      info.first.length < 1 &&
      (!typset || typset.findIndex((t) => t.name === "first_name") > -1)
    ) {
      Toast.error("Please enter your first name.");
      return false;
    }

    if (
      info.last.length < 1 &&
      (!typset || typset.findIndex((t) => t.name === "last_name") > -1)
    ) {
      Toast.error("Please enter your last name.");
      return false;
    }

    if (
      info.dob &&
      (!typset || typset.findIndex((t) => t.name === "dob_day") > -1)
    ) {
      if (!info.dob_month || !info.dob_day || !info.dob_year) {
        Toast.error("Please enter your date of birth.");
        return false;
      }

      if (
        parseInt(info.dob_month) < 1 ||
        parseInt(info.dob_month) > 12 ||
        parseInt(info.dob_day) < 1 ||
        parseInt(info.dob_day) > 31 ||
        parseInt(info.dob_year) < 1000
      ) {
        Toast.error("Please enter a valid date of birth");
        return false;
      }

      const underAge =
        info.underAgeQuestion && info.productenv.age
          ? info.productenv.age
          : this.Config().MINAGE;
      if (
        moment().diff(
          `${info.dob_year}-${info.dob_month}-${info.dob_day}`,
          "years"
        ) < underAge
      ) {
        Toast.error(
          `Sorry, you need to be ${underAge} or over to use this service.`
        );
        return false;
      }
    }

    if (
      !info.nomobile &&
      (!typset || typset.findIndex((t) => t.name === "mobile") > -1)
    ) {
      if (info.mobile.length < 1) {
        Toast.error("Please enter your mobile number in the field provided.");
        return false;
      }
    }

    return true;
  }

  async checkandSetQuestionnairedata(_id, opts, from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "checkQuestionnairedata",
      "Start"
    );

    const result_ = await this.Fetch(
      `${this.Config().HOST}/${
        this.Config().API
      }/checkquestionnairedata/${_id}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        })
      },
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "checkQuestionnairedata",
      false,
      false,
      "questionnaire@questionnaire.com.au"
    );

    const result = this.getResult(result_, "checkQuestionnaireData");

    if (result && result.iserror && result.notoken) {
      return "logout";
    }

    if (result.iserror) {
      return { error: result.msg ? result.msg : "error" };
    }

    if (result.checkQuestionnaireData) {
      const values = result.checkQuestionnaireData;

      opts.dob_daySet(values.dob_day ? values.dob_day : "");
      opts.dob_monthSet(values.dob_month ? values.dob_month : "");
      opts.dob_yearSet(values.dob_year ? values.dob_year : "");
      opts.setAddressLine1(values.address_line1 ? values.address_line1 : "");
      opts.setAddressLine2(values.address_line2 ? values.address_line2 : "");
      opts.setSuburb(values.suburb ? values.suburb : "");
      opts.setState(values.state ? values.state : "");
      opts.setPostcode(values.postcode ? values.postcode : "");
      opts.weightSet(values.weight ? values.weight : 0);
      opts.heightSet(values.height ? values.height : 0);
      opts.setBrandpill(values.shop_product ?? null);
      opts?.setOriginBrandpill(values.shop_product ?? null);
      opts.setPh(
        values.phone ? values.phone : values.mobile ? values.mobile : ""
      );

      opts.setBmiValue(bmi(values?.weight ?? 0, values?.height ?? 0));

      result.info = values;
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "checkQuestionnairedata",
      "End"
    );
    return result;
  }

  setCurrentQuestionResponse(questionnaire_, currentindex, value, curqus) {
    const response = {
      ...questionnaire_[currentindex],
      ...{
        value,
        response: value,
        replaceother:
          curqus && curqus.replaceother ? curqus.replaceother : false,
        next: true
      }
    };
    return setValueToQuestion(
      questionnaire_[currentindex],
      response,
      questionnaire_[currentindex].depth ?? 0
    );
  }

  async setQuestionnaire(
    param_,
    qSet,
    setQuestionnaireid,
    setSubindex,
    setSubindex2,
    setNextId,
    nextid,
    value,
    questionnairedata,
    questionnaire_,
    questionnaireid,
    from = null,
    opts
  ) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "setQuestionnaire",
      "Start"
    );

    this.logger(
      this.Config().STATE,
      null,
      null,
      `questionnaireid: ${questionnaireid}, nextid: ${nextid} subindex: ${opts?.subindex_}`
    );
    this.logger(this.Config().STATE, null, null, {
      param_,
      opts,
      questionnairedata
    });
    this.logger(this.Config().STATE, null, null, {
      parent: questionnaire_[questionnaireid],
      current: questionnaire_[questionnaireid + nextid]
    });

    const questionnairedata_ = questionnairedata;
    let _nextid = nextid;
    let qid_ = questionnaireid;

    let subindex_ = opts.subindex_ > 0 ? opts.subindex_ - 1 : 0;
    const currentindex = questionnaireid + _nextid - subindex_;

    let end = false;
    let endmessage = false;
    let endsubmessage = false;
    let hideBack = false;

    this.logger(
      this.Config().STATE,
      null,
      null,
      `currentindex: ${currentindex}, questionnaireid: ${questionnaireid}, subindex__: ${
        opts.subindex_
      },  nextid: ${_nextid}, next+subindex_: ${_nextid + opts.subindex_}`
    );

    if (!questionnaire_[currentindex]) {
      Sentry.captureMessage(
        `undefined is not an object- questionnaireid: ${questionnaireid} _nextid:${_nextid} user_email: ${
          opts?.user_email
        } length: ${
          questionnaire_ && Array.isArray(questionnaire_)
            ? questionnaire_.length
            : "-1"
        } questionnaire: ${
          questionnaire_ && Array.isArray(questionnaire_)
            ? questionnaire_[questionnaire_.length - 1]?.title
            : ""
        }`
      );
    }

    questionnaire_[currentindex].value = value;
    questionnaire_[currentindex].response = value;
    questionnaire_[currentindex].response_ = {
      title: questionnaire_[currentindex].isdata
        ? this.getRawDatafromText(
            questionnaire_[currentindex].title,
            null,
            opts.reason_
          )
        : questionnaire_[currentindex].title,
      response: value,
      value,
      id: questionnaire_[currentindex].id,
      ga_id: questionnaire_[currentindex].ga_id
        ? questionnaire_[currentindex].ga_id
        : "",
      depth: questionnaire_[currentindex].depth
        ? questionnaire_[currentindex].depth
        : 0,
      next: true
    };

    if (opts?.addProgressBarCount > 0) {
      questionnaire_[currentindex].response_.addProgressBarCount =
        opts?.addProgressBarCount;
    }

    if (questionnaire_[currentindex].requireBloodpressure) {
      questionnaire_[currentindex].response_.requireBloodpressure = true;
    }

    if (questionnaire_[currentindex].nextid)
      questionnaire_[currentindex].response_.nextid =
        questionnaire_[currentindex].nextid;

    if (questionnaire_[currentindex].jump_)
      questionnaire_[currentindex].response_.jump_ =
        questionnaire_[currentindex].jump_;

    if (questionnaire_[currentindex].jumpnext)
      questionnaire_[currentindex].response_.jumpnext =
        questionnaire_[currentindex].jumpnext;

    // Set script_only & brand pill
    if (questionnaire_[currentindex]?.type === "medication")
      questionnaire_[currentindex].response_.script_only = opts?.script_only;
    if (
      questionnaire_[currentindex]?.type === "medication" ||
      questionnaire_[currentindex]?.sub_type === "brand" ||
      questionnaire_[currentindex]?.sub_type === "productSearch" ||
      questionnaire_[currentindex]?.useoption === "yes"
    )
      questionnaire_[currentindex].response_.brand_pill = opts?.brand_pill;

    if (questionnaire_[currentindex]?.origin) {
      questionnaire_[currentindex].response_.origin = true;
      opts?.setOriginBrandpill(opts?.brand_pill);
    }

    if (questionnaire_[currentindex]?.resetBrand)
      opts?.setBrandpill(opts?.brand_origin_pill);

    if (questionnaire_[currentindex]?.coldchain) {
      questionnaire_[currentindex].response_.iscoldchain =
        opts.iscoldchain ?? false;
      questionnaire_[currentindex].response_.coldchainqus = true;
    }

    if (
      questionnaire_[currentindex]?.type === "OfferProd" &&
      opts.categoryList &&
      Array.isArray(opts.categoryList)
    ) {
      questionnaire_[currentindex].response_.categoryList = opts.categoryList;
    }

    if (opts.addfields) {
      for (let i = 0; i < opts.addfields.length; i++) {
        questionnaire_[currentindex].response_[opts.addfields[i]] =
          questionnaire_[currentindex][opts.addfields[i]]
            ? questionnaire_[currentindex][opts.addfields[i]]
            : "";
      }
    }

    if (questionnaire_[currentindex].ckboxs) {
      questionnaire_[currentindex].response_.accept_ = Object.assign(
        opts.checkbts
      );
      opts.acceptSet("");
      opts.checkbtsSet("");
    }

    if (
      questionnaire_[currentindex].scoreid &&
      questionnaire_[currentindex].options &&
      value &&
      Array.isArray(value)
    ) {
      const scoreobj = questionnaire_[currentindex];
      const { score } = scoreobj.options.find(
        (option) => option.title === value[0] && option.score
      );
      questionnaire_[currentindex].response_.score = parseFloat(score);
      questionnaire_[currentindex].response_.scoreid = scoreobj.scoreid;
    }

    // set questionnaire_id and response_id for form response
    if (questionnaire_[currentindex].question_id) {
      questionnaire_[currentindex].response_.question_id =
        questionnaire_[currentindex].question_id;
      let responseId = null;

      // if question has options, find response_id from seleted option
      // Otherwise, if response_id exists in question itseft set response_id from question
      if (questionnaire_[currentindex].options) {
        responseId = questionnaire_[currentindex].options.find(
          (option) => option.title === value[0]
        )?.response_id;
      } else if (questionnaire_[currentindex].response_id)
        responseId = questionnaire_[currentindex].response_id;

      // if responseId exists, set response_id to response object
      if (responseId)
        questionnaire_[currentindex].response_.response_id = responseId;
    }

    if (
      param_ &&
      param_.currentquestion_ &&
      param_.currentquestion_.type === "address"
    ) {
      questionnaire_[currentindex].response_.address_line1 = opts.addressLine1;
      questionnaire_[currentindex].response_.address_line2 = opts.addressLine2;
      questionnaire_[currentindex].response_.suburb = opts.suburb;
      questionnaire_[currentindex].response_.state = opts.state;
      questionnaire_[currentindex].response_.postcode = opts.postcode;
    }

    if (
      questionnaire_[currentindex] &&
      questionnaire_[currentindex].version &&
      questionnaire_[currentindex].response_
    ) {
      questionnaire_[currentindex].response_.version =
        questionnaire_[currentindex].version;
    }

    if (questionnaire_[currentindex]?.updateField && value && value[0]) {
      updateClinicalDetails(
        questionnaire_[currentindex]?.updateField,
        value,
        opts.clinicalDetails,
        opts.setClinicalDetails,
        questionnaire_[currentindex]?.mergeValue
      );
    }

    if (questionnaire_[currentindex].getEmployeeHealthCheckScore) {
      opts.setBtLoading(true);
      const newResponse = questionnaire_
        .map((question) => {
          if (
            question.response &&
            !question.response.includes("SKIP THIS QUESTION")
          ) {
            return question.response_;
          }
        })
        .filter((t) => t);
      const result = await getEmployeeHealthCheckScore(newResponse);
      opts.setHealthCheckResult(result);
      opts.setBtLoading(false);
    }

    /*
    console.log("==========");
    console.log(questionnaire_);
    console.log(param_);
    console.log(
      `currentindex: ${currentindex}, questionnaireid: ${questionnaireid}, subindex__: ${
        opts.subindex_
      },  nextid: ${_nextid}, next+subindex_: ${_nextid + opts.subindex_}`
    );
    */

    if (
      param_ &&
      param_.currentquestion_ &&
      param_.currentquestion_.type === "multichoice" &&
      (!param_.currentquestion_.sub || param_.currentquestion_.sub !== "yes")
    ) {
      subindex_ = 0;
      setSubindex(0);

      let nextvalue = 0;
      const questionnaireselectobj =
        questionnaire_[questionnaire_.length - 1].options;
      const parent = questionnaire_[questionnaire_.length - 1];
      if (
        param_.currentquestion_.merge &&
        param_.currentquestion_.merge === "yes" &&
        Array.isArray(param_.currentquestion_.response) &&
        param_.currentquestion_.response.length > 1
      ) {
        let isselected = false;
        for (let k = 0; k < questionnaireselectobj.length; k++) {
          for (let j = 0; j < value.length; j++) {
            if (
              value[j] === questionnaireselectobj[k].title ||
              (questionnaireselectobj[k].txtarea === "yes" &&
                value[j].includes(questionnaireselectobj[k].title))
            ) {
              if (questionnaireselectobj[k].next) {
                const nextobj = questionnaireselectobj[k].next[0];
                if (
                  (nextobj.title.includes("[selected]") ||
                    (nextobj.origintitle &&
                      nextobj.origintitle.includes("[selected]"))) &&
                  isselected
                ) {
                  console.log("skip");
                } else {
                  if (questionnaireselectobj[k].morenext) {
                    for (
                      let o = 0;
                      o < questionnaireselectobj[k].next.length;
                      o++
                    ) {
                      const nextobj_ = questionnaireselectobj[k].next[o];
                      nextvalue += 1;
                      if (!isselected) {
                        isselected = true;
                      }
                      _nextid += 1;
                      setNextId(_nextid);
                      if (!nextobj_.next || !Array.isArray(nextobj_.next)) {
                        nextobj_.next = true;
                      }
                      nextobj_.depth =
                        parent && parent.depth ? parent.depth + 1 : 1;
                      nextobj_.nextid = _nextid;
                      nextobj_.nextvalue = nextvalue;
                      nextobj_.parent_ = { ...parent };
                      questionnaire_.push(nextobj_);
                    }
                  } else {
                    nextvalue += 1;
                    if (!isselected) {
                      isselected = true;
                    }
                    _nextid += 1;
                    setNextId(_nextid);
                    if (!nextobj.next || !Array.isArray(nextobj.next)) {
                      nextobj.next = true;
                    }
                    nextobj.depth =
                      parent && parent.depth ? parent.depth + 1 : 1;
                    nextobj.nextid = _nextid;
                    nextobj.nextvalue = nextvalue;
                    nextobj.parent_ = { ...parent };
                    const tmptitle = nextobj.title;
                    nextobj.origintitle = nextobj.origintitle
                      ? nextobj.origintitle
                      : tmptitle;
                    let replacestring = Array.isArray(
                      param_.currentquestion_.response
                    )
                      ? param_.currentquestion_.response.join(" , ")
                      : param_.currentquestion_.response;
                    if (parent && parent.exword) {
                      parent.exword.map((exw) => {
                        replacestring = replacestring.replace(`, ${exw}`, "");
                        replacestring = replacestring.replace(`${exw}`, "");
                      });
                    }
                    nextobj.title = param_.currentquestion_.hideselect
                      ? nextobj.title.replace("[selected]", "")
                      : nextobj.title.replace(
                          "[selected]",
                          replacestring?.toLowerCase()
                        );
                    questionnaire_.push(nextobj);
                  }
                  break;
                }
              }
            }
          }
        }
      } else {
        for (let k = 0; k < questionnaireselectobj.length; k++) {
          for (let j = 0; j < value.length; j++) {
            if (
              value[j] === questionnaireselectobj[k].title ||
              (questionnaireselectobj[k].txtarea === "yes" &&
                value[j].includes(questionnaireselectobj[k].title))
            ) {
              if (
                param_.currentquestion_.tempend &&
                questionnaireselectobj[k].end
              ) {
                end = true;
                endmessage = questionnaireselectobj[k].message;
                endsubmessage = questionnaireselectobj[k]?.submessage;
                hideBack = questionnaireselectobj[k]?.hideBack;
              }
              if (questionnaireselectobj[k].next) {
                if (questionnaireselectobj[k].morenext) {
                  for (
                    let o = 0;
                    o < questionnaireselectobj[k].next.length;
                    o++
                  ) {
                    const nextobj_ = questionnaireselectobj[k].next[o];
                    if (
                      !nextobj_.coldchaindelivery ||
                      (nextobj_.coldchaindelivery &&
                        nextobj_.coldchaindelivery === "ok" &&
                        opts.iscoldchain &&
                        ((opts.injectablesUnavailable &&
                          nextobj_.injectablesUnavailable) ||
                          (!opts.injectablesUnavailable &&
                            !nextobj_.injectablesUnavailable))) ||
                      (nextobj_.coldchaindelivery &&
                        nextobj_.coldchaindelivery === "nok" &&
                        !opts.iscoldchain)
                    ) {
                      nextvalue += 1;
                      _nextid += 1;
                      setNextId(_nextid);
                      if (!nextobj_.next || !Array.isArray(nextobj_.next)) {
                        nextobj_.next = true;
                      }
                      nextobj_.depth =
                        parent && parent.depth ? parent.depth + 1 : 1;
                      nextobj_.nextid = _nextid;
                      nextobj_.nextvalue = nextvalue;
                      nextobj_.parent_ = Object.assign(parent);
                      questionnaire_.push(nextobj_);
                    } else {
                      continue;
                    }
                  }
                } else {
                  nextvalue += 1;
                  const nextobj = questionnaireselectobj[k].next[0];
                  _nextid += 1;
                  setNextId(_nextid);
                  if (!nextobj.next || !Array.isArray(nextobj.next)) {
                    nextobj.next = true;
                  }
                  nextobj.depth = parent && parent.depth ? parent.depth + 1 : 1;
                  nextobj.nextid = _nextid;
                  nextobj.nextvalue = nextvalue;
                  nextobj.parent_ = { ...parent };
                  nextobj.issubindex = true;
                  const replacestring = Array.isArray(
                    param_.currentquestion_.response
                  )
                    ? param_.currentquestion_.response.join(" , ")
                    : param_.currentquestion_.response;
                  nextobj.title = param_.currentquestion_.hideselect
                    ? nextobj.title.replace("[selected]", "")
                    : nextobj.title.replace(
                        "[selected]",
                        replacestring?.toLowerCase()
                      );
                  questionnaire_.push(nextobj);
                  break;
                }
              }
            }
          }
        }
      }

      if (nextvalue >= 1) {
        questionnaire_[currentindex].response_.next = true;
        questionnaire_[currentindex].response_.nextvalue = nextvalue;
        subindex_ = nextvalue;
        setSubindex(nextvalue);
      } else if (nextvalue <= 0) {
        qid_ += 1;
        questionnaire_[currentindex].response_.next = false;
        questionnaire_.push(questionnairedata_[qid_]);
      }
      this.logger(
        this.Config().STATE,
        null,
        null,
        `1 qid_: ${qid_}, _nextid: ${_nextid} , subindex_: ${nextvalue}`
      );
    } else if (param_.next && Array.isArray(param_.next)) {
      questionnaire_[currentindex].response_.next = true;
      let nid = 0;
      if ((param_.next && param_.next.length > 1) || param_.isParentGaId) {
        for (let i = 0; i < param_.next.length; i++) {
          const nextobj = param_.next[i];
          nextobj.depth = param_ && param_.depth ? param_.depth + 1 : 1;
          nextobj.parent_ = { ...param_ };
          if (!nextobj.next || !Array.isArray(nextobj.next)) {
            nextobj.next = true;
          }

          _nextid += 1;
          nextobj.nextid = _nextid;

          if (nextobj.response_) nextobj.response_.nextid = _nextid;

          if (param_.isParentGaId) {
            nid = subindex_;
            const insertObjectIndex =
              questionnaire_.findIndex((q) => q.ga_id === nextobj.parentGaId) +
              1;
            questionnaire_.splice(insertObjectIndex, 0, nextobj);
          } else {
            questionnaire_.push(nextobj);
          }
          nid++;
          if (nextobj.index2) {
            setSubindex2(nid);
          } else {
            setSubindex(nid);
          }
          setNextId(_nextid);

          subindex_ = nid;
          console.log(`set subindex: ${nid}`);
        }
      } else {
        const nextobj = param_.next[0];
        if (!nextobj.next || !Array.isArray(nextobj.next)) {
          nextobj.next = true;
        }
        nextobj.depth = param_ && param_.depth ? param_.depth + 1 : 1;
        _nextid += 1;
        nextobj.nextid = _nextid;

        if (nextobj.response_) nextobj.response_.nextid = _nextid;

        if (opts.subindex_ > 1) {
          questionnaire_ = qusrestructure(
            questionnaire_,
            nextobj,
            setSubindex,
            opts.subindex_ - 1,
            nextobj.depth,
            _nextid
          );
        } else {
          questionnaire_.push(nextobj);
        }
        setNextId(_nextid);
      }
      this.logger(
        this.Config().STATE,
        null,
        null,
        `2 qid_: ${qid_}, _nextid: ${_nextid}`
      );
      this.logger(
        this.Config().STATE,
        null,
        null,
        `2 qid_: ${qid_}, _nextid: ${_nextid}, subindex_: ${
          opts.subindex_
        }, new subindex_: ${opts.subindex_ - 1}`
      );
    } else if (opts.subindex_ > 1) {
      if (
        param_.currentquestion_ &&
        param_.currentquestion_.pid &&
        param_.currentquestion_.next &&
        Array.isArray(param_.currentquestion_.next) &&
        param_.currentquestion_.next[0].id
      ) {
        const nextobj = param_.currentquestion_.next[0];
        if (!nextobj.next || !Array.isArray(nextobj.next)) {
          nextobj.next = true;
        }
        nextobj.depth = param_ && param_.depth ? param_.depth + 1 : 1;
        _nextid += 1;
        nextobj.nextid = _nextid;
        questionnaire_ = qusrestructure(
          questionnaire_,
          nextobj,
          setSubindex,
          opts.subindex_ - 1,
          nextobj.depth,
          _nextid
        );

        if (nextobj.response_) nextobj.response_.nextid = _nextid;

        setNextId(_nextid);
      } else {
        if (opts.subindex_ === 1) {
          setSubindex(0);
          subindex_ = 0;
        } else {
          setSubindex(opts.subindex_ - 1);
          subindex_ = opts.subindex_ - 1;
        }

        if (
          subindex_ > 1 &&
          param_.title &&
          questionnaire_[questionnaire_.length - 1 - (subindex_ - 1)]?.show &&
          !questionnaire_[
            questionnaire_.length - 1 - (subindex_ - 1)
          ]?.show.includes(param_.title)
        ) {
          const q___ =
            questionnaire_[questionnaire_.length - 1 - (subindex_ - 1)];
          q___.response = ["SKIP THIS QUESTION"];
          questionnaire_[
            questionnaire_.length - 1 - (subindex_ - 1)
          ].response_ = {
            title: q___.title,
            response: q___.response,
            value: q___.response,
            id: q___.id,
            ga_id: q___.ga_id ? q___.ga_id : "",
            depth: q___.depth ? q___.depth : 0,
            skip: q___.skip,
            next: false
          };
          questionnaire_[
            questionnaire_.length - 1 - (subindex_ - 2)
          ].jumpnext = true;
          questionnaire_[questionnaire_.length - 1 - (subindex_ - 2)].jump_ = 1;
          setSubindex(subindex_ - 1);
          subindex_ = subindex_ - 1;
        }
      }
      this.logger(
        this.Config().STATE,
        null,
        null,
        `3 qid_: ${qid_}, _nextid: ${_nextid} , subindex_: ${opts.subindex_}, new subindex_: ${subindex_}`
      );
    } else if (
      param_.currentquestion_ &&
      param_.currentquestion_.next &&
      Array.isArray(param_.currentquestion_.next) &&
      param_.currentquestion_.next[0].id
    ) {
      const nextobj = param_.currentquestion_.next[0];
      if (!nextobj.next && !Array.isArray(nextobj.next)) {
        nextobj.next = true;
      }
      nextobj.depth =
        param_ && param_.currentquestion_ && param_.currentquestion_.depth
          ? param_.currentquestion_.depth + 1
          : 1;
      _nextid += 1;
      nextobj.nextid = _nextid;

      if (nextobj.response_) nextobj.response_.nextid = _nextid;

      questionnaire_.push(nextobj);
      setNextId(_nextid);

      this.logger(
        this.Config().STATE,
        null,
        null,
        `3-4 qid_: ${qid_}, _nextid: ${_nextid}, subindex_: ${
          opts.subindex_
        }, new subindex_: ${opts.subindex_ - 1}`
      );
    } else {
      qid_ = questionnaireid + 1;
      questionnaire_[questionnaireid + _nextid].response_.next = false;
      const q_next = Object.assign({}, questionnairedata_[qid_]);

      if (q_next && q_next.sub_type === "chemist2u") {
        opts.setBtLoading(true);
        // Check if the patient's address is serviceable by C2U
        const { is_serviceable: isServiceable } =
          await PatientAPI.isAddressServiceable({
            line1: opts.addressLine1,
            line2: opts.addressLine2,
            suburb: opts.suburb,
            state: opts.state,
            postcode: opts.postcode
          });

        if (isServiceable) {
          q_next.type = "accordion-select";
          // Default to using four hour delivery. An arbitrary choice
          const text =
            COMMTXT.chemist2u_delivery_options[MapDeliveryOptions.FOUR_HOUR];
          // OTC V0 only has C2U delivery, no pick up option
          q_next.options = [
            {
              optionNo: MapDeliveryOptions.FOUR_HOUR,
              headerTitle: text,
              headerSubtitle: `$${opts?.ChemistShipCost ?? "TBD"}`,
              contentTitle: text.contentTitle,
              contentSubtitle: text.contentSubtitle
            }
          ];
        } else {
          // Not serviceable, end the questionnaire
          q_next.type = "end";
          if (opts.productenv.code === "morning_after_pill") {
            q_next.onlymessage =
              "Sorry, we are unable to service your location";
            q_next.onlysubmessage =
              "Unfortunately, our delivery partner is unable to provide express shipping to your address. To ensure patients can start treatment as soon as possible, we only service locations where we can deliver treatment within 24 hours. To enable you to start your treatment as soon as possible, we recommend seeing a doctor in person.";
          } else {
            q_next.content = (
              <div>Apologies, your address is not serviceable.</div>
            );
          }
        }
        opts.setBtLoading(false);
      }
      questionnaire_.push(q_next);

      if (q_next.type === "newMedicationDetail") {
        opts.setBtLoading(true);
        const data = await OexaAPI.lookupScript(
          opts.token,
          !User.loggedIn() ? opts.user_email : false
        );
        await this.setMedicationDataByToken(
          data,
          opts.productenv.slug,
          opts.setMedicationList,
          opts.setBrandpill
        );
        opts.setScriptToken(opts.token);
        opts.setEScriptStatus(data.status);
        opts.setEScriptName(data?.medicine_pack_name);
        opts.setEScriptRemaining(data?.supplies_remaining);
        if (data?.status !== "Found") opts.setUnload(false);
        opts.setBtLoading(false);
      }

      this.logger(
        this.Config().STATE,
        null,
        null,
        `4 qid_: ${qid_}, _nextid: ${_nextid}, subindex_: ${
          opts.subindex_
        }, new subindex_: ${opts.subindex_ - 1}`
      );
    }

    if (
      questionnaire_.length > 0 &&
      questionnaire_[questionnaire_.length - 1]
    ) {
      const tmp_ = questionnaire_[questionnaire_.length - 1];
      if (tmp_.settotalscore) {
        const { scorelist } = tmp_;
        const { scoreresult } = tmp_;
        let totalscore = 0;
        let resultstring = "";
        for (let i = 0; i < questionnaire_.length; i++) {
          for (let j = 0; j < scorelist.length; j++) {
            if (
              questionnaire_[i].response_ &&
              questionnaire_[i].response_.scoreid === scorelist[j]
            ) {
              totalscore += questionnaire_[i].response_.score;
              break;
            }
          }
        }
        for (let i = 0; i < scoreresult.length; i++) {
          if (
            scoreresult[i].minscore <= totalscore &&
            scoreresult[i].maxscore >= totalscore
          ) {
            resultstring = scoreresult[i].result.replace("[score]", totalscore);
          }
        }
        tmp_.response = [resultstring];
        tmp_.response_ = {
          title: tmp_.title,
          response: [resultstring],
          value: [resultstring],
          id: tmp_.id,
          ga_id: tmp_.ga_id ? tmp_.ga_id : "",
          depth: tmp_.depth ? tmp_.depth : 0,
          next: false
        };
      }

      if (questionnaire_[questionnaire_.length - 1].hidepatient) {
        qid_ += 1;
        const q_next = Object.assign({}, questionnairedata_[qid_]);
        q_next.jump_ = 1;
        questionnaire_.push(q_next);
      }

      let jump_ = 1;

      if (
        questionnaire_[questionnaire_.length - 1]?.existId &&
        (questionnaire_.findIndex(
          (qus) =>
            qus?.ga_id === questionnaire_[questionnaire_.length - 1].existId
        ) <= -1 ||
          questionnaire_.findIndex(
            (qus) =>
              qus?.ga_id ===
                questionnaire_[questionnaire_.length - 1].existId &&
              qus.response.includes(
                questionnaire_[questionnaire_.length - 1].existValue
              )
          ) > -1)
      ) {
        questionnaire_[questionnaire_.length - 1].response = [
          "SKIP THIS QUESTION"
        ];
        const q___ = questionnaire_[questionnaire_.length - 1];
        questionnaire_[questionnaire_.length - 1].response_ = {
          title: q___.title,
          response: q___.response,
          value: q___.response,
          id: q___.id,
          ga_id: q___.ga_id ? q___.ga_id : "",
          depth: q___.depth ? q___.depth : 0,
          next: false
        };
        qid_ += 1;
        const q_next = Object.assign({}, questionnairedata_[qid_]);
        q_next.jump_ = jump_;
        questionnaire_.push(q_next);
        jump_++;
      }

      if (
        questionnaire_[questionnaire_.length - 1]?.outForColdchainDelivery ===
          "nok" &&
        opts.iscoldchain
      ) {
        questionnaire_[questionnaire_.length - 1].response = [
          "SKIP THIS QUESTION"
        ];
        const q___ = questionnaire_[questionnaire_.length - 1];
        questionnaire_[questionnaire_.length - 1].response_ = {
          title: q___.title,
          response: q___.response,
          value: q___.response,
          id: q___.id,
          ga_id: q___.ga_id ? q___.ga_id : "",
          depth: q___.depth ? q___.depth : 0,
          next: false
        };
        qid_ += 1;
        const q_next = Object.assign({}, questionnairedata_[qid_]);
        q_next.jump_ = jump_;
        questionnaire_.push(q_next);
        jump_++;
      }

      if (
        questionnaire_[questionnaire_.length - 1]?.condobjs &&
        questionnaire_[questionnaire_.length - 1]?.to &&
        questionnaire_[questionnaire_.length - 1]?.to === "next"
      ) {
        if (
          checkQusCondition(
            questionnaire_[questionnaire_.length - 1].cond,
            questionnaire_[questionnaire_.length - 1]?.condobjs,
            questionnaire_,
            opts
          )
        ) {
          questionnaire_[questionnaire_.length - 1].response = [
            "SKIP THIS QUESTION"
          ];
          const q___ = questionnaire_[questionnaire_.length - 1];
          questionnaire_[questionnaire_.length - 1].response_ = {
            title: q___.title,
            response: q___.response,
            value: q___.response,
            id: q___.id,
            ga_id: q___.ga_id ? q___.ga_id : "",
            depth: q___.depth ? q___.depth : 0,
            skip: q___.skip,
            skip2: q___.skip2,
            next: false
          };
          qid_ += 1;
          const q_next = Object.assign({}, questionnairedata_[qid_]);
          q_next.jump_ = jump_;
          questionnaire_.push(q_next);
        }
      }

      if (
        questionnaire_[questionnaire_.length - 1]?.showcond &&
        questionnaire_[questionnaire_.length - 1]?.showcond === "field" &&
        questionnaire_[questionnaire_.length - 1]?.fieldtype === "array" &&
        (!opts[questionnaire_[questionnaire_.length - 1].field] ||
          opts[questionnaire_[questionnaire_.length - 1].field]?.length <= 0)
      ) {
        questionnaire_[questionnaire_.length - 1].response = [
          "SKIP THIS QUESTION"
        ];
        const q___ = questionnaire_[questionnaire_.length - 1];
        questionnaire_[questionnaire_.length - 1].response_ = {
          title: q___.title,
          response: q___.response,
          value: q___.response,
          id: q___.id,
          ga_id: q___.ga_id ? q___.ga_id : "",
          depth: q___.depth ? q___.depth : 0,
          next: false
        };
        qid_ += 1;
        const q_next = Object.assign({}, questionnairedata_[qid_]);
        q_next.jump_ = jump_;
        questionnaire_.push(q_next);
      }

      while (
        questionnaire_[questionnaire_.length - 1].skip ||
        questionnaire_[questionnaire_.length - 1].skipex
      ) {
        if (
          (questionnaire_[questionnaire_.length - 1].skipgid &&
            questionnaire_.findIndex(
              (qus) =>
                qus.response &&
                qus.ga_id &&
                qus.ga_id ===
                  questionnaire_[questionnaire_.length - 1].skipgid &&
                ((questionnaire_[questionnaire_.length - 1].skip &&
                  questionnaire_[questionnaire_.length - 1].skip.findIndex(
                    (re) => qus.response.includes(re)
                  ) > -1) ||
                  (questionnaire_[questionnaire_.length - 1].skipex &&
                    questionnaire_[questionnaire_.length - 1].skipex.findIndex(
                      (re) => qus.response.includes(re)
                    ) <= -1))
            ) > -1) ||
          (!questionnaire_[questionnaire_.length - 1].skipgid &&
            ((questionnaire_[questionnaire_.length - 1].skip &&
              questionnaire_[questionnaire_.length - 1].skip.findIndex((re) =>
                questionnaire_[questionnaire_.length - 2]?.response?.includes(
                  re
                )
              ) > -1) ||
              (questionnaire_[questionnaire_.length - 1].skipex &&
                questionnaire_[questionnaire_.length - 1].skipex.findIndex(
                  (re) =>
                    questionnaire_[
                      questionnaire_.length - 2
                    ]?.response?.includes(re)
                ) <= -1)))
        ) {
          questionnaire_[questionnaire_.length - 1].response = [
            "SKIP THIS QUESTION"
          ];
          const q___ = questionnaire_[questionnaire_.length - 1];
          questionnaire_[questionnaire_.length - 1].response_ = {
            title: q___.title,
            response: q___.response,
            value: q___.response,
            id: q___.id,
            ga_id: q___.ga_id ? q___.ga_id : "",
            depth: q___.depth ? q___.depth : 0,
            skip: q___.skip,
            next: false
          };
          if (questionnaire_[questionnaire_.length - 1].jump_)
            questionnaire_[questionnaire_.length - 1].response_.jump_ =
              questionnaire_[questionnaire_.length - 1].jump_;
          if (questionnaire_[questionnaire_.length - 1].jumpnext)
            questionnaire_[questionnaire_.length - 1].response_.jumpnext =
              questionnaire_[questionnaire_.length - 1].jumpnext;
          qid_ += 1;
          const q_next = Object.assign({}, questionnairedata_[qid_]);
          q_next.jump_ = jump_;
          questionnaire_.push(q_next);
          jump_++;
        } else {
          break;
        }
      }

      /*
        -skip2 is exist because skip1 is skip the question by one question.
          However, the questionnaire, currently only menopause, need to combine two questions' answer to skip the current question
  
        -So, skip2 is minor version of skip,
          but just remove unnecessary condition from the skip logic
       */
      while (questionnaire_[questionnaire_.length - 1].skip2) {
        if (
          questionnaire_[questionnaire_.length - 1].skipgid2 &&
          questionnaire_.findIndex(
            (qus) =>
              qus.response &&
              qus.ga_id &&
              qus.ga_id ===
                questionnaire_[questionnaire_.length - 1].skipgid2 &&
              questionnaire_[questionnaire_.length - 1].skip2 &&
              questionnaire_[questionnaire_.length - 1].skip2.findIndex((re) =>
                qus.response.includes(re)
              ) > -1
          ) > -1
        ) {
          questionnaire_[questionnaire_.length - 1].response = [
            "SKIP THIS QUESTION"
          ];
          const q___ = questionnaire_[questionnaire_.length - 1];
          questionnaire_[questionnaire_.length - 1].response_ = {
            title: q___.title,
            response: q___.response,
            value: q___.response,
            id: q___.id,
            ga_id: q___.ga_id ? q___.ga_id : "",
            depth: q___.depth ? q___.depth : 0,
            skip: q___.skip,
            skip2: q___.skip2,
            next: false
          };
          if (questionnaire_[questionnaire_.length - 1].jump_)
            questionnaire_[questionnaire_.length - 1].response_.jump_ =
              questionnaire_[questionnaire_.length - 1].jump_;
          if (questionnaire_[questionnaire_.length - 1].jumpnext)
            questionnaire_[questionnaire_.length - 1].response_.jumpnext =
              questionnaire_[questionnaire_.length - 1].jumpnext;
          qid_ += 1;
          const q_next = Object.assign({}, questionnairedata_[qid_]);
          q_next.jump_ = jump_;
          questionnaire_.push(q_next);
          jump_++;
        } else {
          break;
        }
      }

      let existSkipLoop = false;
      while (
        questionnaire_[questionnaire_.length - 1]?.skiploop &&
        !existSkipLoop
      ) {
        if (
          (questionnaire_[questionnaire_.length - 1].fieldvalue === "ok" &&
            opts[questionnaire_[questionnaire_.length - 1].field]) ||
          (questionnaire_[questionnaire_.length - 1].fieldvalue === "nok" &&
            !opts[questionnaire_[questionnaire_.length - 1].field]) ||
          (questionnaire_[questionnaire_.length - 1]?.fieldvalue2 &&
            ((questionnaire_[questionnaire_.length - 1]?.fieldvalue2 === "ok" &&
              opts[questionnaire_[questionnaire_.length - 1].field2]) ||
              (questionnaire_[questionnaire_.length - 1].fieldvalue2 ===
                "nok" &&
                !opts[questionnaire_[questionnaire_.length - 1].field2])))
        ) {
          questionnaire_[questionnaire_.length - 1].response = [
            "SKIP THIS QUESTION"
          ];
          const q___ = questionnaire_[questionnaire_.length - 1];
          questionnaire_[questionnaire_.length - 1].response_ = {
            title: q___.title,
            response: q___.response,
            value: q___.response,
            id: q___.id,
            ga_id: q___.ga_id ? q___.ga_id : "",
            depth: q___.depth ? q___.depth : 0,
            skip: q___.skip,
            skip2: q___.skip2,
            next: false
          };
          if (questionnaire_[questionnaire_.length - 1].jump_)
            questionnaire_[questionnaire_.length - 1].response_.jump_ =
              questionnaire_[questionnaire_.length - 1].jump_;
          if (questionnaire_[questionnaire_.length - 1].jumpnext)
            questionnaire_[questionnaire_.length - 1].response_.jumpnext =
              questionnaire_[questionnaire_.length - 1].jumpnext;
          qid_ += 1;
          const q_next = Object.assign({}, questionnairedata_[qid_]);
          q_next.jump_ = jump_;
          questionnaire_.push(q_next);
          jump_++;
        } else {
          existSkipLoop = true;
        }
      }

      if (
        questionnaire_[questionnaire_.length - 1]?.hideQuestion &&
        opts[questionnaire_[questionnaire_.length - 1]?.hideQuestion]
      ) {
        questionnaire_[questionnaire_.length - 1].response = [
          "SKIP THIS QUESTION"
        ];
        const q___ = questionnaire_[questionnaire_.length - 1];
        questionnaire_[questionnaire_.length - 1].response_ = {
          title: q___.title,
          response: q___.response,
          value: q___.response,
          id: q___.id,
          ga_id: q___.ga_id ? q___.ga_id : "",
          depth: q___.depth ? q___.depth : 0,
          next: false
        };
        qid_ += 1;
        const q_next = Object.assign({}, questionnairedata_[qid_]);

        if (
          questionnaire_[questionnaire_.length - 1].depth &&
          questionnaire_[questionnaire_.length - 1].depth >= 1
        ) {
          q_next.jumpnext = true;
        }
        q_next.jump_ = jump_;
        jump_++;
        questionnaire_.push(q_next);
      }

      // Skip email, name and dob questions if it is nib international
      if (
        opts.skipUserQuestionForNib &&
        opts.isNibPatient &&
        questionnaire_[questionnaire_.length - 1]?.skipForNib
      ) {
        while (questionnaire_[questionnaire_.length - 1].skipForNib) {
          let response = "";
          if (questionnaire_[questionnaire_.length - 1].ga_id === "A0001") {
            response = opts.user_email;
          } else if (
            questionnaire_[questionnaire_.length - 1].ga_id === "A0002"
          ) {
            response = `${opts.first_name}_${opts.last_name}`;
          } else if (
            questionnaire_[questionnaire_.length - 1].ga_id === "A0004"
          ) {
            response = `${opts.dob_day}_${opts.dob_month}_${opts.dob_year}`;
          }
          questionnaire_[questionnaire_.length - 1].response = response;
          const q___ = questionnaire_[questionnaire_.length - 1];
          questionnaire_[questionnaire_.length - 1].response_ = {
            title: q___.title,
            response: q___.response,
            value: q___.response,
            id: q___.id,
            ga_id: q___.ga_id ? q___.ga_id : "",
            depth: q___.depth ? q___.depth : 0,
            skip: q___.skip,
            skip2: q___.skip2,
            next: false
          };
          if (questionnaire_[questionnaire_.length - 1].jump_)
            questionnaire_[questionnaire_.length - 1].response_.jump_ =
              questionnaire_[questionnaire_.length - 1].jump_;
          if (questionnaire_[questionnaire_.length - 1].jumpnext)
            questionnaire_[questionnaire_.length - 1].response_.jumpnext =
              questionnaire_[questionnaire_.length - 1].jumpnext;
          qid_ += 1;
          const q_next = Object.assign({}, questionnairedata_[qid_]);
          q_next.jump_ = jump_;
          jump_++;
          questionnaire_.push(q_next);
        }
      }

      // bypass and add bypass index if
      // 1) bmi value is bigger or equal than bmi max value in question field
      // 2) gender is not equal in the question gender field
      // jumpnext: set true if it is sub question
      // jump_: so it will bypass question
      if (
        (!questionnaire_[questionnaire_.length - 1].end &&
          questionnaire_[questionnaire_.length - 1].bmi &&
          questionnaire_[questionnaire_.length - 1].bmi.max <= opts.bmiValue) ||
        (questionnaire_[questionnaire_.length - 1].sex &&
          opts?.gender?.toLowerCase() !==
            questionnaire_[questionnaire_.length - 1].sex) ||
        (questionnaire_[questionnaire_.length - 1].age &&
          (!opts.underAgeQuestion ||
            !opts.productenv.age ||
            (opts.productenv.age &&
              this.getAgebyDOB(
                `${opts.dob_year}-${opts.dob_month}-${opts.dob_day}`
              ) > this.Config().MINAGE)))
      ) {
        questionnaire_[questionnaire_.length - 1].response = [
          "SKIP THIS QUESTION"
        ];
        const q___ = questionnaire_[questionnaire_.length - 1];
        questionnaire_[questionnaire_.length - 1].response_ = {
          title: q___.title,
          response: q___.response,
          value: q___.response,
          id: q___.id,
          ga_id: q___.ga_id ? q___.ga_id : "",
          depth: q___.depth ? q___.depth : 0,
          next: false
        };
        qid_ += 1;
        const q_next = Object.assign({}, questionnairedata_[qid_]);
        if (
          questionnaire_[questionnaire_.length - 1].depth &&
          questionnaire_[questionnaire_.length - 1].depth >= 1
        ) {
          q_next.jumpnext = true;
        }
        q_next.jump_ = jump_;

        questionnaire_.push(q_next);
      }
    }

    if (
      !opts.productenv.noShowPayment &&
      ((opts.productenv.once && opts.productenv.once === "yes") ||
        (opts.productenv.subscription &&
          opts.productenv.subscription === "yes")) &&
      User.loggedIn() &&
      questionnaire_[questionnaire_.length - 1] &&
      questionnaire_[questionnaire_.length - 1].type === "signup" &&
      ((opts.showpayment && opts.showpayment === "yes") || !opts.showpayment)
    ) {
      if (await this.hasPaymentInfo()) {
        opts.setShowpayment("no");
      } else {
        opts.setShowpayment("yes");
      }
    }

    if (
      questionnaire_[questionnaire_.length - 1] &&
      questionnaire_[questionnaire_.length - 1].title
    ) {
      const newtitle = this.replaceString(
        questionnaire_[questionnaire_.length - 1].title,
        COMMTXT,
        opts
      );
      if (newtitle) questionnaire_[questionnaire_.length - 1].title = newtitle;

      if (
        questionnaire_[questionnaire_.length - 1].replaceOptions &&
        Array.isArray(questionnaire_[questionnaire_.length - 1].options)
      ) {
        questionnaire_[questionnaire_.length - 1].options = questionnaire_[
          questionnaire_.length - 1
        ].options.map((op) => {
          const newOpTitle = this.replaceString(op.title, COMMTXT, opts);
          if (newOpTitle) op.title = newOpTitle;
          return op;
        });
      }

      if (
        questionnaire_[questionnaire_.length - 1].from_ === "db" &&
        (!questionnaire_[questionnaire_.length - 1].options ||
          Array.isArray(questionnaire_[questionnaire_.length - 1].options)) &&
        !questionnaire_[questionnaire_.length - 1].noload
      ) {
        if (
          questionnaire_[questionnaire_.length - 1]?.sub_type ===
            "productSearch" &&
          !opts.brand_pill?.parent_id
        ) {
          questionnaire_[questionnaire_.length - 1].options = [opts.brand_pill];
        } else {
          opts.setBtLoading(true);
          const products = await loadProducts(
            opts.productenv.name,
            true,
            questionnaire_[questionnaire_.length - 1].showprice,
            questionnaire_[questionnaire_.length - 1]?.sub_type ===
              "productSearch" && opts.brand_pill?.parent_id
              ? opts.brand_pill?.parent_id
              : "",
            questionnaire_[questionnaire_.length - 1].contraindications
          );
          questionnaire_[questionnaire_.length - 1] = setDBOption(
            questionnaire_[questionnaire_.length - 1],
            products
          );
          opts.setBtLoading(false);
        }
      }

      if (
        questionnaire_[questionnaire_.length - 1].prefilled &&
        opts[questionnaire_[questionnaire_.length - 1].prefilled]
      ) {
        questionnaire_[questionnaire_.length - 1].value = [
          opts[questionnaire_[questionnaire_.length - 1].prefilled]
        ];
      }

      if (
        opts.by === "repeats" &&
        questionnaire_[questionnaire_.length - 1].requestchange &&
        opts?.requestchange
      ) {
        const curqus = questionnaire_[questionnaire_.length - 1];
        questionnaire_[questionnaire_.length - 1].response_ = {
          title: curqus.title,
          response: [questionnaire_[questionnaire_.length - 1].requestchange],
          value: [questionnaire_[questionnaire_.length - 1].requestchange],
          id: curqus.id,
          ga_id: curqus.ga_id ? curqus.ga_id : "",
          depth: curqus.depth ? curqus.depth : 0,
          skip: curqus.skip,
          next: false
        };
        questionnaire_[questionnaire_.length - 1].value = [
          questionnaire_[questionnaire_.length - 1].requestchange
        ];
        qid_ += 1;
        const q_next = Object.assign({}, questionnairedata_[qid_]);
        q_next.jump_ = 1;
        questionnaire_.push(q_next);
        if (opts?.productenv?.newRepeatMedication)
          opts?.setIsNewMedication(true);
      }

      if (questionnaire_[questionnaire_.length - 1]?.createOp) {
        const opList =
          OpList[questionnaire_[questionnaire_.length - 1].createOp];
        const fieldValue = opts[opList.by]?.toLowerCase();
        questionnaire_[questionnaire_.length - 1].options = createOptions(
          opList[fieldValue]
        );
      }
    }

    // Initialize end page flag before set up new end page flag
    if (
      questionnaire_[questionnaire_.length - 1] &&
      questionnaire_[questionnaire_.length - 1].end
    ) {
      questionnaire_[questionnaire_.length - 1].end = false;
      questionnaire_[questionnaire_.length - 1].onlymessage = false;
    }

    if (end) {
      questionnaire_[questionnaire_.length - 1].end = true;
      questionnaire_[questionnaire_.length - 1].onlymessage = endmessage;
      questionnaire_[questionnaire_.length - 1].onlysubmessage =
        endsubmessage ?? false;
      if (hideBack) opts?.setHideback(true);

      const reason =
        param_.currentquestion_ && param_.currentquestion_.response
          ? Array.isArray(param_.currentquestion_.response)
            ? param_.currentquestion_.response.join(",")
            : param_.currentquestion_.response
          : "";
      this.setGtmQus("qdisq", {
        isnewuser_: !User.loggedIn(),
        _hasscript: opts._hasscript ? opts._hasscript : "",
        _scriptname: opts._scriptname ? opts._scriptname : "",
        productenv: opts.productenv ? opts.productenv : "",
        patientprospectid: opts.patientprospectid ? opts.patientprospectid : "",
        patientId: opts.patientid ? opts.patientid : "",
        currentquestion_:
          param_ && param_.currentquestion_
            ? param_ && param_.currentquestion_
            : "",
        reason
      });
    }

    this.logger(this.Config().STATE, null, null, {
      qid_,
      _nextid,
      questionnaire_
    });
    setQuestionnaireid(qid_);
    qSet(questionnaire_);

    if (
      questionnaire_[questionnaire_.length - 1] &&
      ((questionnaire_[questionnaire_.length - 1].from &&
        questionnaire_[questionnaire_.length - 1].from === "scriptend") ||
        (questionnaire_[questionnaire_.length - 1].type &&
          questionnaire_[questionnaire_.length - 1].type === "signup") ||
        (questionnaire_[questionnaire_.length - 1].title &&
          questionnaire_[questionnaire_.length - 1].title === "All Done!") ||
        questionnaire_[questionnaire_.length - 1].end)
    ) {
      opts.isendset(true);
      if (
        (!opts.by || opts.by !== "repeats") &&
        ((opts.productenv.once && opts.productenv.once === "yes") ||
          (opts.productenv.subscription &&
            opts.productenv.subscription === "yes")) &&
        questionnaire_[questionnaire_.length - 1].type === "signup"
      ) {
        this.setGtm("viewPaymentPage", {
          event: "viewPaymentPage",
          product: opts.productenv.name,
          medicationName:
            opts.productenv.subscription && !opts.productenv.once
              ? "Consult"
              : opts.productenv.medication ??
                opts.brand_pill?.title ??
                opts.brand_pill,
          priceDisplayed:
            opts.productenv.subscription && !opts.productenv.once
              ? opts.consultfee
              : opts.price
        });
      }
    }

    if (
      questionnaire_[questionnaire_.length - 1] &&
      questionnaire_[questionnaire_.length - 1].title === "Do you smoke?"
    ) {
      // questionnaire_.length - 1 is the current question
      if (opts.smokingStatus) {
        // set current answer to stored smokingStatus
        if (questionnaire_[questionnaire_.length - 1].type === "multichoice") {
          questionnaire_[questionnaire_.length - 1].value = [
            questionnaire_[questionnaire_.length - 1].options[0].title
          ];
        } else {
          questionnaire_[questionnaire_.length - 1].value = [
            opts.smokingStatus
          ];
        }
      }
    }

    let smokeobj = "";
    if (
      questionnaire_[questionnaire_.length - 2] &&
      questionnaire_[questionnaire_.length - 2].title === "Do you smoke?"
    ) {
      smokeobj = questionnaire_[questionnaire_.length - 2];
    } else if (
      questionnaire_[questionnaire_.length - 3] &&
      questionnaire_[questionnaire_.length - 3].title === "Do you smoke?"
    ) {
      smokeobj = questionnaire_[questionnaire_.length - 3];
    }
    if (smokeobj && smokeobj.title && smokeobj.value) {
      if (Array.isArray(smokeobj.value) && smokeobj.value.length > 0) {
        opts.smokingStatusSet(smokeobj.value[0].includes("Yes") ? "Yes" : "No");
      } else {
        opts.smokingStatusSet(smokeobj.value.includes("Yes") ? "Yes" : "No");
      }
    }

    if (
      questionnaire_[questionnaire_.length - 1] &&
      questionnaire_[questionnaire_.length - 1].iscond
    ) {
      if (questionnaire_[questionnaire_.length - 1].iscond === "time") {
        const time_obj = await this.getServerTime("", "time");
        questionnaire_[questionnaire_.length - 1].time = time_obj;
      }
    }
    if (
      questionnaire_ &&
      questionnaire_.length >= 1 &&
      questionnaire_[questionnaire_.length - 1]?.checkcoldchain &&
      opts.addressLine1 &&
      opts.suburb
    ) {
      // check cold chain address
      // 1. if it is initial questionnaire
      // 2. if it is repeat questionnaire and
      //    brand_pill is cold chain product or medicaion question is Injectable medication .. answer
      if (
        opts?.by !== "repeats" ||
        (opts.brand_pill?.title &&
          isColdChainProduct(opts.brand_pill.title) &&
          questionnaire_.findIndex(
            (question) =>
              question?.ga_id === "WM0007" &&
              (question?.response?.includes("SKIP THIS QUESTION") ||
                question?.response?.includes(
                  "Injectable medication for weight loss"
                ))
          ) >= 0) ||
        questionnaire_.findIndex(
          (question) =>
            question?.ga_id === "WM0007" &&
            question?.response?.includes(
              "Injectable medication for weight loss"
            )
        ) >= 0
      ) {
        opts.setBtLoading(true);
        try {
          const coldChainResult = await PatientAPI.isAddressServiceable(
            {
              line1: opts.addressLine1,
              line2: opts.addressLine2,
              suburb: opts.suburb,
              state: opts.state,
              postcode: opts.postcode
            },
            true
          );
          if (!coldChainResult?.message) {
            opts.setIsColdChain(coldChainResult?.is_cold_chain_eligible);
          } else {
            opts.setIsColdChain(false);
          }
        } catch (e) {
          opts.setIsColdChain(false);
        }
        opts.setBtLoading(false);
      }
      // if it is repeat questionnaire and medicaion question isn't Injectable medication .. answer
      else {
        opts.setIsColdChain(true);
      }
    }

    if (questionnaire_ && questionnaire_.length >= 1) {
      if (questionnaire_[questionnaire_.length - 1]?.setUnload)
        opts?.setUnload(false);
      if (
        opts?.setProgressBarLength &&
        questionnaire_[questionnaire_.length - 1]?.addProgressBarCount
      )
        opts?.setProgressBarLength(
          opts?.progressBarLength +
            questionnaire_[questionnaire_.length - 1]?.addProgressBarCount
        );
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "setQuestionnaire",
      "End"
    );
    return { questionnaire_, qid_ };
  }

  validation(info, from = null, add) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "validation",
      "Start"
    );

    let result = { vaild: false, msg: "" };
    let names = inputmap[info.type];

    if (info.typecheck || add?.typecheck) {
      names =
        inputmap[add?.typecheck ? add?.typeset : info.typeset.toLowerCase()];
    }

    if (!names || add?.skipvalidation) {
      result.vaild = true;
      return result;
    }

    const values = {};

    if (names) {
      result.names = names;
    }

    for (let i = 0; i < names.length; i++) {
      if (
        names[i].type === "bypass" ||
        names[i]?.notrequired?.includes(add.productenv.code)
      )
        continue;
      const attr = names[i];
      const require_ = names[i].require ? names[i].require : false;
      const msg = names[i].msg ? names[i].msg : `${attr.name}`;

      if (names[i].type === "variable" && add[names[i].name]) {
        result.vaild = true;
        break;
      }

      const exclude = names[i].exclude
        ? names[i].checkbox
          ? document.getElementById(names[i].exclude).checked
          : document.getElementById(names[i].exclude).value
        : false;
      if (!exclude) {
        if (
          !require_ ||
          (require_ &&
            (attr.noid
              ? document.getElementsByName(attr.name)[0]
              : document.getElementById(attr.id ? attr.id : attr.name)
            ).value)
        ) {
          const value = attr.noid
            ? document.getElementsByName(attr.name)[0].value
            : document.getElementById(attr.id ? attr.id : attr.name).value
            ? document.getElementById(attr.id ? attr.id : attr.name).value
            : "";
          result = this.checkbyType(
            info,
            attr,
            value,
            values,
            result,
            this,
            add
          );
          if (!result.vaild) break;
        } else {
          result.vaild = false;
          result.msg = msg;
          break;
        }
      }
      if (attr.isValidFn && !attr.isValidFn()) {
        result.vaild = false;
        result.msg = msg;
        break;
      }
    }

    result.values = values;

    if (!result.vaild) {
      this.logger(
        this.Config().STATE,
        `${from ? `${from} ->` : ""}basequestionnaire`,
        "validation",
        "End: Not Vaild"
      );
      return result;
    }

    if (result.values.dob_month && result.values.dob_month.length === 1) {
      result.values.dob_month = `0${result.values.dob_month}`;
    }

    if (result.values.dob_day && result.values.dob_day.length === 1) {
      result.values.dob_day = `0${result.values.dob_day}`;
    }

    if (info.type === "dob" || info.type === "date") {
      const isvaliddate = this.isValidDate(
        result.values.date_picker,
        info.rangegroup,
        info.type,
        add,
        info.duration__ ? info.duration__ : "",
        { setData: add?.setData, isFuture: info?.isFuture }
      );

      if (
        !isvaliddate ||
        (isvaliddate && isvaliddate.success && isvaliddate.success === "no")
      ) {
        if (info.type === "dob") {
          this.setGtmQus("qdisq", {
            isnewuser_: !User.loggedIn(),
            _hasscript: null,
            productenv: add.productenv,
            _scriptname: null,
            patientprospectid: add?.patientprospectid,
            patientId: add?.patientid,
            currentquestion_: info,
            reason: isvaliddate.message
          });
        }

        if (IsCustomEndPage(info.type, add.productenv.code)) {
          add.setData(
            <DobCustomEndPage
              code={add.productenv.code}
              messageType={isvaliddate?.max ? "maxAge" : "minAge"}
            />
          );
          add.setHideback(true);
          return "bypass";
        }

        result.vaild = false;
        result.msg =
          isvaliddate && isvaliddate.message
            ? isvaliddate.message
            : `Please enter a valid date ${
                info.type === "dob" ? "of birth" : ""
              }.`;
      }
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "validation",
      "End"
    );
    return result;
  }

  async getParameterByName(name, url, option = "", from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getParameterByName",
      "Start"
    );

    const sURLVariables = url.split("&");
    let sParameterName;
    let i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] === name) {
        this.logger(
          this.Config().STATE,
          `${from ? `${from} ->` : ""}basequestionnaire`,
          "getParameterByName",
          "End"
        );
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getParameterByName",
      "End"
    );
    return false;
  }

  /**
   *
   * @param {string} plainCode
   * @returns {[exists: boolean, emailOrErrorMessage: string | Object]}
   */
  checkProspectUserExists = async (plainCode) =>
    API.v2()
      .url(`/prospect-user-exists/?code=${plainCode}`)
      .unauthenticated()
      .get()
      .then(async (resp) => {
        const exists = resp.ok;
        let emailOrErrorMessage;
        emailOrErrorMessage = await resp.text();
        if (exists && emailOrErrorMessage && emailOrErrorMessage.length > 0) {
          emailOrErrorMessage = JSON.parse(emailOrErrorMessage);
        }
        if (emailOrErrorMessage instanceof Object) {
          emailOrErrorMessage = emailOrErrorMessage.message;
        }
        return [exists, emailOrErrorMessage];
      })
      .catch((error) => [false, error]);

  /**
   *
   * @param {string} plainCode
   * @returns {Object} formData
   */
  getFormData = async (plainCode) => {
    const response = await this.Fetch(
      `${this.Config().HOST}/api/v2/get-patient-prospect/?code=${plainCode}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      },
      "",
      "getFormData",
      false,
      false,
      ""
    );

    const formData = response.data;

    return formData;
  };

  async postpatientdata(param, from = null, info) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "postpatientdata",
      "Start"
    );
    let thisdata = "";
    const response_ = [];
    let plainCode = param;

    if (!info.patientprospectid) {
      const create_response = await this.Fetch(
        `${this.Config().HOST}/api/v2/create-patient-prospect/`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json"
          }),
          body: JSON.stringify({
            email: info.user_email,
            product: info.product_,
            brand: this.capitalizeFirstLetter(this.Config().FROM).trim()
          })
        },
        `${from ? `${from} ->` : ""}basequestionnaire`,
        "postpatientdata",
        false,
        false,
        null,
        true,
        false,
        true
      );

      if (create_response?.iserror) return false;

      if (create_response !== true && create_response !== false) {
        plainCode = create_response;
        const formData = await this.getFormData(plainCode);
        if (formData?.id) {
          info.setpatientprospectid(formData.id);
          info.setPlainCode(plainCode);
        }
      }
    }

    const formdata_ = info.questionnaire_;
    if (formdata_ && Array.isArray(formdata_) && formdata_.length > 0) {
      for (let i = 0; i < formdata_.length; i++) {
        this.setResponseForm(formdata_[i], response_, {
          slug: info?.slug
        });
      }
    }

    if (plainCode) {
      const avoiderror = { ...formdata_[info.questionnaireid] };
      if (avoiderror.options) {
        delete avoiderror.options;
      }
      if (avoiderror.parent_) {
        delete avoiderror.parent_;
        avoiderror.parent_ = true;
      }
      if (avoiderror.next) {
        delete avoiderror.next;
        avoiderror.next = true;
      }
      let data_ = JSON.stringify(response_);
      let isnewjson = false;

      thisdata = {
        verification_code: plainCode,
        currentquestion: avoiderror,
        currentquestionindex: info.questionnaireid,
        fd: isnewjson ? data_ : JSON.stringify(response_),
        first_name: info?.first_name,
        at_payment_page:
          (formdata_?.length > 1 &&
            formdata_[formdata_.length - 1]?.type === "signup") ??
          false
      };
    }

    const result1_ = await this.Fetch(
      `${this.Config().HOST}/api/v2/update-patient-prospect/`,
      {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify(thisdata)
      },
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "postpatientdata_2",
      false,
      false,
      null,
      true,
      false,
      true
    );

    const result1 = this.getResult(result1_, "postPatientProspect");

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "postpatientdata",
      "End"
    );

    if (info.isCodeReturn && result1.postPatientProspect?.message) {
      return plainCode;
    }
    if (
      result1.postPatientProspect !== true &&
      result1.postPatientProspect !== false
    ) {
      return result1.postPatientProspect;
    }
    return null;
  }

  async checkproduct(email, param, option = "", from = null) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "checkproduct",
      "Start"
    );

    const result1_ = await this.Fetch(
      `${this.Config().HOST}/${
        this.Config().API
      }/getquestionnaire?${new URLSearchParams({
        email: email,
        product: param
      }).toString()}`,
      {
        method: "GET",
        headers: new Headers({ "Content-Type": "application/json" })
      },
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "checkproduct",
      false,
      false,
      "questionnaire@questionnaire.com.au",
      true,
      false,
      true
    );

    const result1 = this.getResult(result1_, "getquestionnaire");

    if (result1.iserror) {
      if (result1.notoken) {
        return "logout";
      }
      return false;
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "checkproduct",
      "End"
    );
    return result1;
  }

  async createFullForm(
    productdata,
    Userquestions,
    NewUserquestions,
    UserquestionsV3,
    UserquestionsV4,
    AddUserquestions,
    AddUserquestionsnew,
    AddUserquestionsnewv2,
    AfterUserquestions,
    extradata,
    productenv,
    setHasScript,
    setScriptQus,
    setScriptqusType,
    qProspectSet,
    setBrandpill,
    setPrice,
    setDuration,
    Proscpect_ = null,
    opts,
    from_ = null,
    from = null
  ) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "createFullForm",
      "Start"
    );

    let isvaild = true;
    let message = "";
    let qform = [];
    let prospectindex = 0;
    let prospectnextindex = 0;
    let prospectform_ = [];
    const Proscpect = Proscpect_;
    let isnewversion = false;
    let addcomindex = -1;
    let addcomafter = false;
    let addcombefore = false;

    if (productdata) {
      const productList = productenv.isLoadProducts
        ? await loadProducts(productenv.name, null, true, undefined)
        : [];
      for (let i = 0; i < productdata.default.length; i++) {
        let form = productdata.default[i];
        form = await setOptions(form, productenv, opts.info, {
          deliveryoptionSet: opts.deliveryoptionSet,
          isSameDay: opts.isSameDay ? opts.isSameDay : "",
          productList
        });
        if (form.setMedication) {
          setBrandpill(form.options[0].title);
          setPrice(form.options[0].price);
        }
        qform.push(form);
        if (form?.sub_type === "chemist2u" && opts.setIsChemist2u) {
          opts.isSameDay("no");
          opts.setIsChemist2u("yes");
          opts.deliveryoptionSet([
            {
              price: opts.ChemistShipCost,
              delivery_option: MapDeliveryOptions.FOUR_HOUR
            }
          ]);
        }
      }
      if (!qform || !Array.isArray(qform)) {
        Toast.error(
          "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 1238]."
        );
        Sentry.captureMessage("Error while processing your request: 1238");
        return false;
      }

      addcomafter = qform.find((q) => q.addcomafter)?.addcomafter;
      addcombefore = qform.find((q) => q.addcombefore)?.addcombefore;

      if (addcomafter || addcombefore) {
        addcomindex = qform.findIndex(
          (q) => q.ga_id === (addcomafter || addcombefore)
        );
        if (addcombefore) {
          addcomindex -= 1;
        }
        opts.setAddComIndex(addcomindex);
        if (productenv?.userQuestionVer == 3) {
          Userquestions = UserquestionsV3;
        } else if (productenv?.userQuestionVer == 4) {
          Userquestions = UserquestionsV4;
        } else {
          Userquestions = NewUserquestions;
        }
      }

      if (qform.findIndex((q) => q.ismust) > -1) {
        opts.setIsCheckRes(true);
      }

      // skip email question if user is logged in the loreal acne trial questionnaire
      if (productenv.code === "loreal_acne_trial" && User.loggedIn()) {
        qform = qform.slice(1);
      }

      if (Userquestions && !productenv.noaddqus) {
        if (
          productenv.exclude &&
          productenv.exclude.findIndex((ex) => ex === "AddUserquestions") > -1
        ) {
          if (productenv.name === "Fill-a-script") {
            if (!User.loggedIn()) {
              const beforeUserQuestions = qform.splice(0, 1);
              Userquestions[0].title = "What is your email address?";
              Userquestions[0].subtitle = "";
              qform = beforeUserQuestions.concat(Userquestions).concat(qform);
            }
          } else if (
            productenv.exclude &&
            productenv.exclude.findIndex((ex) => ex === "Userquestions") <= -1
          ) {
            if (productenv.forceMedicare) {
              qform = this.mergeArray(
                qform,
                User.loggedIn()
                  ? AfterUserquestions
                  : AfterUserquestions.concat(Userquestions),
                addcomindex
              );
            } else {
              if (addcomindex > -1) {
                if (!User.loggedIn()) {
                  qform = this.mergeArray(qform, Userquestions, addcomindex);
                }
              } else {
                qform = Userquestions.concat(qform);
              }
            }
          }
        } else {
          if (productenv.forceMedicare) {
            qform = this.mergeArray(
              qform,
              User.loggedIn()
                ? AfterUserquestions.concat(
                    productenv?.adduserv2
                      ? AddUserquestionsnewv2
                      : AddUserquestionsnew
                  )
                : Userquestions.concat(AfterUserquestions).concat(
                    productenv?.adduserv2
                      ? AddUserquestionsnewv2
                      : AddUserquestionsnew
                  ),
              addcomindex
            );
          } else {
            if (addcomindex > -1) {
              qform = this.mergeArray(
                qform,
                User.loggedIn()
                  ? productenv?.adduserv2
                    ? AddUserquestionsnewv2
                    : AddUserquestionsnew
                  : Userquestions.concat(
                      productenv?.adduserv2
                        ? AddUserquestionsnewv2
                        : AddUserquestionsnew
                    ),
                addcomindex
              );
            } else {
              if (
                productenv.exclude &&
                productenv.exclude.findIndex((ex) => ex === "Userquestions") >
                  -1
              ) {
                qform = [Userquestions[0]]
                  .concat([Userquestions[3]])
                  .concat(AddUserquestions)
                  .concat(qform);
              } else {
                qform = Userquestions.concat(AddUserquestionsnew).concat(qform);
              }
            }
          }
        }

        if (
          User.loggedIn() &&
          productenv.name !== "Fill-a-script" &&
          addcomindex < 0
        ) {
          qform = qform.concat(
            qform.splice(
              0,
              opts.tokindex && opts.tokindex > -1 ? opts.tokindex : 4
            )
          );
        }
      }

      if (
        productenv.splash &&
        (!from_ || (from_ != "repeats" && from_ != "checkin"))
      ) {
        const extra = await import("../../questionnaires/extraquestions.js");
        const splash = extra.default.find(
          (ex) => ex.type === "splash" && ex.product.includes(productenv.name)
        );

        if (productenv.splashdetail) {
          qform = qform.filter(
            (q) => q?.ga_id !== "A0001" && q?.ga_id !== "A00010"
          );
          for (const property in productenv.splashdetail) {
            splash[property] = productenv.splashdetail[property];
          }
        }
        qform = [splash].concat(
          qform[0]?.type === "splash" ? qform.slice(1) : qform
        );
        if (
          qform[0]?.splashname === "nibinternational" &&
          productenv.code === "telehealth_consult"
        ) {
          qform = qform.filter((q) => {
            if (q?.type !== "OfferProd") {
              if (q?.type === "medication") {
                delete q.next;
              }
              return q;
            }
          });
        }
      }

      if (opts?.category?.is_teleconsult_required) {
        // If this category requires teleconsult, we want to change the phone number question text
        const phoneNumberQuestionIndex = qform.findIndex(
          (q) =>
            q?.type === "mobile" &&
            q?.title === "What is your mobile number?" &&
            q?.subtitle ===
              "This will enable us to get in contact if there are any issues with your order."
        );
        if (phoneNumberQuestionIndex > -1) {
          qform[phoneNumberQuestionIndex].title =
            "What is your best contact number?";
          qform[phoneNumberQuestionIndex].subtitle =
            "Your clinician will complete a quick call with you about your treatment. You can choose a time that is convenient for you after payment.";
        }
      }

      if (from_ === "repeats" && !opts?.productId) {
        qform = qform.filter(
          (q) =>
            !q.requestchange &&
            !q?.title?.includes("you happy with your current medication")
        );
      }

      if (User.loggedIn() && qform[0]?.excludeLogin) {
        qform.shift();
      }
    } else {
      message = "This is wrong product!";
      isvaild = false;
      this.logger(
        this.Config().STATE,
        `${from ? `${from} ->` : ""}basequestionnaire`,
        "createFullForm",
        "End: This is wrong product!"
      );
      return { isvaild, message };
    }

    this.logger(this.Config().STATE, null, null, { qform });

    let istokenvaild = false;

    if (User.loggedIn()) {
      istokenvaild = await this.checkUserByEmail(
        User.email(),
        "basequestionnaire->createFullForm"
      );
      if (
        istokenvaild &&
        istokenvaild.authenticateUser &&
        istokenvaild.authenticateUser.redirect
      ) {
        if (opts && opts.setUnload) {
          opts.setUnload(false);
        }
        if (opts && opts.modaldataSet && opts.modalSet) {
          opts.modaldataSet(
            <RedirectModal
              url={istokenvaild.authenticateUser.redirect}
              onClose={opts.closeModal}
            />
          );
          opts.modalSet(true);
        }
        return { redirect: true };
      }
    }

    // replace text
    if (productenv?.replacetext) {
      qform = qform.map((qf) => {
        if (
          productenv.replacetext.findIndex(
            (rf) => qf.ga_id && rf.ga_id === qf.ga_id
          ) > -1
        ) {
          const txtObj = productenv.replacetext.find(
            (rf) => qf.ga_id && rf.ga_id === qf.ga_id
          );
          if (txtObj.title) {
            qf.title = txtObj.title;
          }
          if (txtObj.subtitle) {
            qf.subtitle = txtObj.subtitle;
          }
        }
        return qf;
      });
    }
    if (Proscpect?.isnewversion) {
      isnewversion = true;
    } else if (
      Proscpect &&
      Array.isArray(Proscpect) &&
      (!User.loggedIn() ||
        (istokenvaild &&
          istokenvaild.authenticateUser &&
          !istokenvaild.authenticateUser.redirect))
    ) {
      let prospectform = Proscpect;

      if (User.loggedIn()) {
        const removeindex = prospectform.findIndex(
          (pro) => pro.id === 104 || pro.id === 10003
        );
        this.logger(
          this.Config().STATE,
          null,
          null,
          `removeindex: ${removeindex}`
        );
        if (removeindex > -1) {
          prospectform.splice(0, removeindex + 1);
        }
      }

      this.logger(this.Config().STATE, null, null, { prospectform });

      if (
        productenv?.ver &&
        Proscpect?.length > 0 &&
        Array.isArray(Proscpect) &&
        Proscpect.findIndex(
          (pros) => pros.version && pros.version === productenv.ver
        ) <= -1 &&
        Proscpect.findIndex(
          (pros) => pros.id === 107 && pros.title.includes("weight")
        ) > -1
      ) {
        isnewversion = true;
        const startpoint = Proscpect.findIndex(
          (pros) => pros.id === 107 && pros.title.includes("weight")
        );
        prospectform = prospectform.slice(0, startpoint + 1);
      }

      if (prospectform.length > 0) {
        const newqform = [];
        let result = {
          start_: 0,
          next: 0,
          qindex: 0,
          lastqus: {},
          totalnext: 0
        };
        let sherpaindex = -1;
        let pre_result = {};

        this.logger(
          this.Config().STATE,
          null,
          null,
          `Start set value from prospect: ${qform.length}`
        );

        for (let i = 0; i < qform.length; i++) {
          if (result.start_ > prospectform.length) {
            break;
          }
          for (let j = i; j < qform.length; j++) {
            if (!prospectform[result.start_]) {
              break;
            }
            pre_result = Object.assign(result);
            if (qform[j].ga_id === prospectform[result.start_].ga_id) {
              if (
                prospectform[result.start_]?.response?.includes(
                  "SKIP THIS QUESTION"
                )
              ) {
                const tmp = setValueToQuestion(
                  qform[j],
                  prospectform[result.start_],
                  0
                );
                newqform.push(tmp);
                result.lastqus = tmp;
                result.start_++;
                result.qindex++;
              } else if (qform[j].options || qform[j]?.createOp) {
                if (qform[j]?.createOp) {
                  const opList = OpList[qform[j].createOp];
                  const fieldValue = getFiedlValueFromForm(
                    opList.ga_id,
                    prospectform
                  );
                  if (!fieldValue) break;
                  qform[j].options = createOptions(opList[fieldValue]);
                }

                let iscoldchain = false;
                let endQuestionnaire = false;
                if (prospectform[result.start_]?.coldchainqus) {
                  if (opts.injectablesUnavailable) {
                    endQuestionnaire = true;
                  }
                  iscoldchain = prospectform[result.start_]?.iscoldchain;
                  opts.setIsColdChain(iscoldchain);
                }

                if (endQuestionnaire) {
                  newqform.push(qform[j]);
                  result.start_++;
                  result.qindex++;
                  sherpaindex = result.qindex;
                  result.lastqus = qform[i - 1];
                } else {
                  result = setFromValuesFromProspect(
                    qform[j],
                    prospectform,
                    newqform,
                    0,
                    result.start_,
                    result.qindex,
                    0,
                    {
                      iscoldchain
                    }
                  );
                }
              } else {
                const tmp = setValueToQuestion(
                  qform[j],
                  prospectform[result.start_],
                  0
                );
                newqform.push(tmp);
                result.lastqus = tmp;
                result.start_++;
                result.qindex++;
              }
              if (result.start_ === pre_result.start_) {
                result = pre_result;
              } else {
                result.totalnext = pre_result.totalnext + result.next;
              }
              result.next = 0;
              i = j;
              break;
            }
          }
        }
        this.logger(
          this.Config().STATE,
          null,
          null,
          `prospectindex: ${prospectindex}`
        );
        this.logger(this.Config().STATE, null, null, { newqform, result });

        prospectform_ = newqform;
        prospectindex = result.qindex;
        prospectnextindex = result.totalnext;

        if (sherpaindex > 0) {
          prospectindex = sherpaindex;
          prospectform_ = prospectform_.slice(0, prospectindex);
        } else {
          for (let i = prospectform_.length - 1; i > 0; i--) {
            if (
              (prospectform_[i].depth === 0 &&
                prospectform_[i].ga_id &&
                prospectform_[i].ga_id === result.lastqus.ga_id) ||
              (prospectform_[i].id && prospectform_[i].id === result.lastqus.id)
            ) {
              break;
            } else {
              if (
                prospectform_[i] &&
                prospectform_[i].depth &&
                prospectform_[i].depth === 0
              ) {
                prospectindex--;
              }
              prospectform_.pop();
            }
          }
        }
      }
      this.logger(this.Config().STATE, null, null, "convert is done");
      this.logger(this.Config().STATE, null, null, { qform, prospectform_ });
    }

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "createFullForm",
      "End"
    );
    return {
      isvaild,
      qform,
      prospectform_,
      prospectindex: prospectindex > 1 ? prospectindex - 1 : prospectindex,
      prospectnextindex,
      isnewversion
    };
  }

  getJsonProspectForm = (formdata) => {
    if (User.loggedIn()) {
      return JSON.parse(formdata).filter(
        (f) => f.ga_id !== "A0001" && f.ga_id !== "A0002"
      );
    }
    return JSON.parse(formdata);
  };

  async getProspectForm(
    extradata,
    setUserEmail,
    setpatientprospectid,
    setPlainCode,
    setFirstName,
    setLastName,
    dob_daySet,
    dob_monthSet,
    dob_yearSet,
    setAddressLine1,
    setAddressLine2,
    setSuburb,
    setState,
    setPostcode,
    genderSet,
    modaldataSet,
    modalSet,
    setIsprospect,
    closeModal,
    setUnload,
    productenv,
    medicareSet,
    medicarerefSet,
    medicareexpSet,
    medicareexptempSet,
    nomedicareSet,
    setIsValidParam,
    setFromParam,
    setNewLogo,
    setNewLogoClass,
    setMembership,
    setBrandpillId,
    setConsultFee,
    setScriptOnly,
    setBrnadpill,
    medicareSelectedStatusSet,
    consultFee,
    setOriginBrandPill,
    heightSet,
    weightSet,
    setBmiValue,
    setHighBlood,
    setLowBlood,
    ihiSet,
    noIhiSet,
    showIhiQuestionSet,
    newPlainCode,
    setCategoryList,
    from = null
  ) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getProspectForm",
      "Start"
    );

    const qform_ = [];
    let isvaild = true;
    let message = "";
    let isnewversion = false;
    if ((extradata && extradata.split("=")[1]) || newPlainCode) {
      const params = new SafeURLSearchParams(extradata);
      const plainCode = params.get("code") || newPlainCode;

      if (plainCode && plainCode.length > 0) {
        let existuseremail;
        const [exists, emailOrErrorMessage] =
          await this.checkProspectUserExists(plainCode);
        if (exists) {
          existuseremail = emailOrErrorMessage;
        } else {
          Toast.error(
            `Sorry we encounted an error while processing this request. Error details: ${emailOrErrorMessage}.`
          );
          return false;
        }
        if (existuseremail) {
          if (!User.loggedIn()) {
            const userexist = await this.checkUserByEmail(existuseremail);
            let reentry = false;
            if (
              userexist &&
              userexist.authenticateUser &&
              !userexist.authenticateUser.redirect
            ) {
              reentry = true;
              modaldataSet(
                <Commmodal
                  modalSet={modalSet}
                  modaltype={"simple"}
                  header={" "}
                >
                  <Login userReentry={reentry} />
                </Commmodal>
              );
              modalSet(true);
              setIsprospect(true);
              return false;
            } else if (
              userexist &&
              userexist.authenticateUser &&
              userexist.authenticateUser.redirect
            ) {
              setUnload(false);
              modaldataSet(
                <RedirectModal
                  url={userexist.authenticateUser.redirect}
                  onClose={closeModal}
                />
              );
              modalSet(true);
              return false;
            }
          }
        }
        const getformdata = await this.getFormData(plainCode);
        if (getformdata && getformdata.formdetails) {
          setpatientprospectid(getformdata.id);
          setPlainCode(plainCode);
          let tmp_formdetail = getformdata.formdetails;

          const formdata__ = JSON.parse(tmp_formdetail);

          if (formdata__) {
            let firstname = "";
            let scriptOnly = false;
            let height = 0;
            for (let j = 0; j < formdata__.length; j++) {
              let skip = false;
              if (
                formdata__[j]?.ga_id === "A0001" ||
                formdata__[j]?.ga_id === "SPL0001" ||
                formdata__[j]?.ga_id === "A00010"
              ) {
                if (
                  User.loggedIn() &&
                  (formdata__[j]?.ga_id === "A0001" ||
                    formdata__[j]?.ga_id === "A00010")
                ) {
                  continue;
                }
                formdata__[j].noresponse = true;
                if (formdata__[j]?.ga_id === "SPL0001") {
                  let resdata = "";
                  let email = "";
                  let names = "";
                  let dob = "";
                  let membership = "";
                  let param = "";

                  resdata = formdata__[j].response.split(";");
                  email = resdata[0].split(":")[1]?.trim();
                  names = resdata[1].split(":")[1]?.trim();
                  dob = resdata[2].split(":")[1]?.trim();
                  membership = resdata[4].split(":")[1]?.trim();
                  param = resdata[5].split(":")[1]?.trim();

                  if (email && names && dob) {
                    if (email) {
                      setUserEmail(email);
                    }
                    const firstName = names.split("_")[0]?.trim();
                    setFirstName(firstName);
                    const lastName = names.split("_")[1]?.trim();
                    setLastName(lastName);
                    if (dob) {
                      dob_daySet(dob.split("_")[0]);
                      dob_monthSet(dob.split("_")[1]);
                      dob_yearSet(dob.split("_")[2]);
                    }

                    if (membership) {
                      const eligibilityStatus = (
                        await checkMembership(
                          membership?.trim(),
                          `${dob.split("_")[2]}-${dob.split("_")[1]}-${
                            dob.split("_")[0]
                          }`,
                          firstName,
                          lastName,
                          productenv.code
                        )
                      ).status;

                      if (eligibilityStatus !== NibEligibilityStatus.ELIGIBLE) {
                        this.showCountdown(
                          5,
                          "redirect",
                          `/questionnaire/?product=${productenv.code}&from=${nibInternationalSearchParam}&variant=worker`,
                          "Unable to confirm NIB membership. Redirecting to start of questionnaire."
                        );
                        return false;
                      }
                      setIsValidParam(nibInternationalSearchParam);
                      setMembership(membership);
                      await this.setSubEnvInfo(
                        param ? param?.trim() : "",
                        productenv,
                        {
                          setFromParam,
                          setNewLogo,
                          setNewLogoClass,
                          showIhiQuestionSet
                        }
                      );
                    }
                  } else {
                    message = "Form is not the new format!";
                    isvaild = false;
                    break;
                  }
                } else if (formdata__[j]?.ga_id === "A00010") {
                  try {
                    const values = JSON.parse(formdata__[j].response);
                    setUserEmail(values?.email);
                    setFirstName(values?.first_name);
                    setLastName(values?.last_name);
                  } catch (e) {
                    message = true;
                    isvaild = false;
                    break;
                  }
                } else {
                  setUserEmail(formdata__[j].response);
                }
              } else if (formdata__[j].ga_id === "A0002") {
                if (User.loggedIn()) continue;
                setFirstName(formdata__[j].response.split("_")[0]);
                setLastName(formdata__[j].response.split("_")[1]);
                formdata__[j].noresponse = true;
              } else if (formdata__[j].ga_id === "A0004") {
                if (User.loggedIn()) continue;
                dob_daySet(formdata__[j].response.split("_")[0]);
                dob_monthSet(formdata__[j].response.split("_")[1]);
                dob_yearSet(formdata__[j].response.split("_")[2]);
                formdata__[j].noresponse = true;
              } else if (!User.loggedIn() && formdata__[j].ga_id === "A0005") {
                if (User.loggedIn()) continue;
                setAddressLine1(formdata__[j].response.split("_")[0]);
                setAddressLine2(formdata__[j].response.split("_")[1]);
                setSuburb(formdata__[j].response.split("_")[2]);
                setState(formdata__[j].response.split("_")[3]);
                setPostcode(formdata__[j].response.split("_")[4]);
                formdata__[j].noresponse = true;
              } else if (!User.loggedIn() && formdata__[j].ga_id === "A0006") {
                if (User.loggedIn()) continue;
                if (formdata__[j].response) {
                  genderSet(
                    Array.isArray(formdata__[j].response) &&
                      formdata__[j].response.length > 0
                      ? formdata__[j].response[0]
                      : formdata__[j].response
                  );
                }
              } else if (
                formdata__[j]?.ga_id === "A0009" &&
                formdata__[j].title.includes("Medicare")
              ) {
                if (
                  formdata__[j].response &&
                  formdata__[j].response.includes("_") &&
                  formdata__[j].response.split("_").length >= 2
                ) {
                  medicareSet(formdata__[j].response.split("_")[0]);
                  medicarerefSet(formdata__[j].response.split("_")[1]);
                  medicareexpSet(formdata__[j].response.split("_")[2]);
                  medicareexptempSet(formdata__[j].response.split("_")[2]);
                } else {
                  nomedicareSet("nomedicare");
                  if (formdata__[j].response === "later") {
                    medicareSelectedStatusSet("later");
                  }
                  if (scriptOnly) {
                    setScriptOnly(false);
                  }
                }
              } else if (formdata__[j].ga_id === "TC0670") {
                if (
                  formdata__[j].response &&
                  !Array.isArray(formdata__[j].response) &&
                  formdata__[j].response?.trim()?.length >= 12
                ) {
                  ihiSet(formdata__[j].response.trim());
                } else {
                  noIhiSet(true);
                }
              } else if (formdata__[j]?.type === "medication") {
                if (!formdata__[j]?.response?.includes("SKIP THIS QUESTION")) {
                  const pId =
                    formdata__[j].response &&
                    Array.isArray(formdata__[j].response)
                      ? formdata__[j].response[0]
                      : formdata__[j].response;
                  setBrandpillId(pId);

                  if (formdata__[j]?.brand_pill) {
                    setBrnadpill(formdata__[j]?.brand_pill);
                  }
                }
                if (formdata__[j]?.script_only) {
                  setScriptOnly(true);
                  scriptOnly = true;
                }
              } else if (formdata__[j]?.ga_id === "RS0100") {
                setConsultFee(consultFee);
              } else if (formdata__[j]?.brand_pill) {
                setBrnadpill(formdata__[j]?.brand_pill);
                if (formdata__[j]?.origin)
                  setOriginBrandPill(formdata__[j]?.brand_pill);
              } else if (
                (formdata__[j]?.ga_id === "A0007" ||
                  formdata__[j]?.ga_id === "A0008") &&
                Array.isArray(formdata__[j].response) &&
                formdata__[j].response[0] &&
                parseFloat(formdata__[j].response[0])
              ) {
                const value = parseFloat(formdata__[j].response[0]);
                if (formdata__[j]?.ga_id === "A0007") {
                  height = parseFloat(value);
                  heightSet(height);
                } else {
                  weightSet(parseFloat(value));
                  setBmiValue(bmi(parseFloat(value), height));
                }
              } else if (
                formdata__[j]?.type === "bloodPressure" &&
                formdata__[j]?.response?.includes("_")
              ) {
                setLowBlood(formdata__[j].response.split("_")[0]);
                setHighBlood(formdata__[j].response.split("_")[1]);
              } else if (formdata__[j]?.categoryList) {
                setCategoryList(formdata__[j]?.categoryList);
              }

              !skip ? qform_.push(formdata__[j]) : "";
            }

            this.logger(
              this.Config().STATE,
              `${from ? `${from} ->` : ""}basequestionnaire`,
              "getProspectForm",
              "End: success"
            );
            return { isvaild, qform_, message, isnewversion };
          }
          message =
            "Something went wrong. Please try again. If this issue persists please contact us";
          isvaild = false;

          this.logger(
            this.Config().STATE,
            `${from ? `${from} ->` : ""}basequestionnaire`,
            "getProspectForm",
            "End: error"
          );
          return { isvaild, message };
        }
      }
      this.logger(
        this.Config().STATE,
        `${from ? `${from} ->` : ""}basequestionnaire`,
        "getProspectForm",
        "End: no prospect form"
      );
      return null;
    }
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getProspectForm",
      "End: no prospect form"
    );
    return null;
  }
}

export default new Basequestionnaire();
