import { toast, ToastOptions, TypeOptions } from "react-toastify";
import { IconCheckCircle, IconTriangleExclamation } from "../icons";

import "react-toastify/dist/ReactToastify.min.css";

const icons: Record<TypeOptions, JSX.Element | null> = {
  success: (
    <IconCheckCircle css={(theme) => ({ color: theme.color.success })} />
  ),
  warning: (
    <IconTriangleExclamation
      css={(theme) => ({ color: theme.color.warning })}
    />
  ),
  error: (
    <IconTriangleExclamation css={(theme) => ({ color: theme.color.danger })} />
  ),
  default: null,
  info: null
};

const defaultOptions: ToastOptions = {
  position: "top-center",
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: false,
  draggable: false,
  icon: ({ type }) => icons[type]
};

export type ToastType = "info" | "success" | "warning" | "error";

const info = (text: string, options?: ToastOptions) =>
  toast.info(text, { ...defaultOptions, ...options });
const success = (text: string, options?: ToastOptions) =>
  toast.success(text, { ...defaultOptions, ...options });
const warning = (text: string, options?: ToastOptions) =>
  toast.warning(text, { ...defaultOptions, ...options });
const error = (text: string, options?: ToastOptions) =>
  toast.error(text, { ...defaultOptions, ...options });

export const Toast = {
  info,
  success,
  warning,
  error,
  dismiss: toast.dismiss,
  generic: (type: ToastType, text: string, options?: ToastOptions) =>
    ({
      info,
      success,
      warning,
      error
    })[type](text, options)
};
