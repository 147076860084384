import { ReactNode } from "react";

import {
  Flex,
  Tooltip,
  IconClinician,
  IconNeverRunOut,
  IconNolock
} from "@mh/components";

interface BrandPillProps {
  price: string;
  title: string;
}

interface NewPaymentPageProps {
  /** Brand */
  brand: string;
  /** Consult Fee */
  consultFee: string;
  /** Brand Pill Information */
  brandPill: BrandPillProps;

  /** credit card section */
  card: ReactNode;
  /** promo code section */
  promo: ReactNode;
  /** next button section */
  nextButtonBlock: any;
}

export const NewPaymentPage = (props: NewPaymentPageProps) => {
  const ConsultFee = parseFloat(props.consultFee);
  const ProdPrice = parseFloat(props.brandPill?.price ?? 0);
  const ExpressShippingCost = 10.95;
  const Total = ConsultFee + ProdPrice + ExpressShippingCost;

  return (
    <div className="d-flex flex-sm-row flex-column NewPaymentPage">
      <Flex
        flexDirection="column"
        width="100%"
        css={(theme) => ({
          padding: "10px",
          paddingBottom: "0px",
          [theme.mq.md]: {
            padding: "32px"
          }
        })}
      >
        <Flex>
          <h3
            css={(theme) => ({
              fontSize: "18px !important",
              color: theme.color.primary,
              maxWidth: "unset",
              fontWeight: "400",
              borderBottom: "0.5px solid #737373",
              marginBottom: "0px !important",
              paddingBottom: "16px",
              [theme.mq.md]: {
                fontSize: "unset",
                borderBottom: "none",
                maxWidth: "320px",
                paddingBottom: "24px"
              }
            })}
          >
            Review treatment and pay for your consultation
          </h3>
        </Flex>
        <Flex
          css={(theme) => ({
            fontWeight: "500",
            maxWidth: "456px",
            borderBottom: "unset",
            paddingTop: "16px",
            paddingBottom: "16px",
            [theme.mq.md]: {
              borderBottom: "0.5px solid #737373",
              paddingBottom: "16px",
              paddingTop: "0"
            }
          })}
        >
          Order summary
        </Flex>
        <Flex
          flexDirection="column"
          css={(theme) => ({
            borderBottom: "0.5px solid #737373",
            paddingBottom: "16px",
            paddingTop: "0",
            [theme.mq.md]: {
              paddingTop: "16px"
            }
          })}
        >
          <div
            css={(theme) => ({
              color: theme.color.primary,
              fontWeight: "600",
              paddingBottom: "8px"
            })}
          >
            Pay now
          </div>
          <div className="d-flex flex-row justify-content-between">
            <Flex flexDirection="column">
              <div
                css={{
                  fontSize: "14px",
                  fontWeight: "600"
                }}
              >
                Medical Consultation
              </div>
              <div
                css={{
                  fontSize: "12px"
                }}
              >
                Book a time for your consultation or join the queue for an
                immediate call at the next step.
              </div>
            </Flex>
            <Flex>
              <div
                css={{
                  fontSize: "14px",
                  fontWeight: "700"
                }}
              >
                ${ConsultFee.toFixed(2)}
              </div>
            </Flex>
          </div>
        </Flex>
        <Flex
          flexDirection="column"
          css={{
            borderBottom: "0.5px solid #737373",
            paddingBottom: "16px",
            paddingTop: "16px"
          }}
        >
          <div
            css={(theme) => ({
              color: theme.color.primary,
              fontWeight: "600",
              paddingBottom: "8px"
            })}
          >
            Pay Later
            <Tooltip
              infoIcon={true}
              iconWidth="16px"
              maxWidth="196px"
              text="We'll notify you when your assigned clinician has issued your contraceptive pill and it is ready for purchase via your Patient Portal."
            />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <Flex flexDirection="column">
              <div
                css={(theme) => ({
                  fontSize: "14px",
                  maxWidth: "212px",
                  [theme.mq.md]: {
                    maxWidth: "unset"
                  }
                })}
              >
                {props.brandPill?.title}
              </div>
            </Flex>
            <Flex>
              <div
                css={{
                  fontSize: "14px",
                  fontWeight: "500"
                }}
              >
                ${ProdPrice.toFixed(2)}
              </div>
            </Flex>
          </div>
          <div
            className="d-flex flex-row justify-content-between"
            css={(theme) => ({
              paddingTop: "8px",
              [theme.mq.md]: {
                paddingBottom: "unset"
              }
            })}
          >
            <Flex flexDirection="column">
              <div
                css={{
                  fontSize: "14px"
                }}
              >
                Express Shipping
              </div>
            </Flex>
            <Flex>
              <div
                css={{
                  fontSize: "14px",
                  fontWeight: "500"
                }}
              >
                $10.95
              </div>
            </Flex>
          </div>
        </Flex>
        <Flex
          flexDirection="column"
          css={(theme) => ({
            borderBottom: "0.5px solid #737373",
            paddingTop: "16px",
            paddingBottom: "16px",
            [theme.mq.md]: {
              paddingBottom: "24px"
            }
          })}
        >
          <div className="d-flex flex-row justify-content-between">
            <Flex flexDirection="column">
              <div
                css={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingBottom: "8px"
                }}
              >
                Total
              </div>
            </Flex>
            <Flex>
              <div
                css={{
                  fontSize: "14px",
                  fontWeight: "500"
                }}
              >
                ${Total.toFixed(2)}
              </div>
            </Flex>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <Flex flexDirection="column">
              <div
                css={(theme) => ({
                  color: theme.color.primary,
                  fontWeight: "600"
                })}
              >
                Due Today
              </div>
            </Flex>
            <Flex>
              <div
                css={{
                  fontSize: "14px",
                  fontWeight: "600"
                }}
              >
                ${ConsultFee?.toFixed(2)}
              </div>
            </Flex>
          </div>
        </Flex>
        <div
          className="d-flex flex-sm-row flex-column justify-content-between"
          css={(theme) => ({
            paddingTop: "16px",
            [theme.mq.md]: {
              paddingTop: "24px"
            }
          })}
        >
          <Flex
            css={(theme) => ({
              paddingBottom: "16px",
              paddingRight: "auto",
              [theme.mq.md]: {
                paddingBottom: "unset",
                marginRight: "9px"
              }
            })}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              css={(theme) => ({
                color: theme.color.primary,
                paddingRight: "8px"
              })}
            >
              <IconNolock width="23.73px" fill="currentColor" />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="center"
              css={(theme) => ({
                fontSize: "16px",
                fontWeight: "400",
                [theme.mq.md]: {
                  fontSize: "12px",
                  fontWeight: "500"
                }
              })}
            >
              <div
                css={{
                  lineHeight: "normal"
                }}
              >
                No lock-in subscriptions
                <Tooltip
                  infoIcon={true}
                  iconWidth="12px"
                  maxWidth="196px"
                  text="Choose whether you want to order your contraceptive pill as you need it or set up a schedule so you always get your pill on time."
                />
              </div>
            </Flex>
          </Flex>
          <Flex
            css={(theme) => ({
              paddingBottom: "16px",
              [theme.mq.md]: {
                paddingBottom: "unset",
                paddingRight: "auto"
              }
            })}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              css={(theme) => ({
                color: theme.color.primary,
                paddingRight: "8px"
              })}
            >
              <IconNeverRunOut width="29px" fill="currentColor" />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="center"
              css={(theme) => ({
                fontSize: "16px",
                fontWeight: "400",
                [theme.mq.md]: {
                  fontSize: "12px",
                  fontWeight: "500"
                }
              })}
            >
              <div
                css={{
                  lineHeight: "normal"
                }}
              >
                Never run out of repeats
                <Tooltip
                  infoIcon={true}
                  iconWidth="12px"
                  maxWidth="196px"
                  text="Once you have used up all of your repeats we'll remind you to book in a renewal consultation so you never run out of your contraceptive pill."
                />
              </div>
            </Flex>
          </Flex>
          <Flex
            css={(theme) => ({
              paddingBottom: "24px",
              borderBottom: "0.5px solid #737373",
              [theme.mq.md]: {
                paddingBottom: "unset",
                borderBottom: "unset",
                paddingRight: "auto",
                marginLeft: "9px"
              }
            })}
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              css={(theme) => ({
                color: theme.color.primary,
                paddingRight: "8px"
              })}
            >
              <IconClinician width="29px" fill="currentColor" />
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="center"
              css={(theme) => ({
                fontSize: "16px",
                fontWeight: "400",
                paddingRight: "5px",
                [theme.mq.md]: {
                  fontSize: "12px",
                  fontWeight: "500"
                }
              })}
            >
              <div
                css={{
                  lineHeight: "normal"
                }}
              >
                Ongoing clinician support
                <Tooltip
                  infoIcon={true}
                  iconWidth="12px"
                  maxWidth="196px"
                  text="You will have access to unlimited follow-up consultations so you can always request a different contraceptive pill or discuss your treatment plan."
                />
              </div>
            </Flex>
          </Flex>
        </div>
      </Flex>
      <Flex
        className="Payment"
        flexDirection="column"
        width="100%"
        css={(theme) => ({
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "24px",
          paddingBottom: "50px",
          [theme.mq.md]: {
            padding: "32px",
            marginLeft: "24px"
          }
        })}
      >
        <div>{props.card}</div>
        <div
          css={{
            paddingBottom: "12px"
          }}
        >
          {props.promo}
        </div>
        <div className="submitButton">{props.nextButtonBlock}</div>
      </Flex>
    </div>
  );
};
