import moment from "moment";
import { Form } from "react-bootstrap";
import { Button, Flex } from "@mh/components";

interface QuestionnaireMedicareProps {
  medicareSelectedStatus: string | undefined;
  medicare: number | undefined;
  medicareref: number | undefined;
  medicareexptemp: string | undefined;
  medicareexp: string | undefined;
  nolater: string | undefined;
  next?: (e: any) => void;
  medicareSelectedStatusSet: (medicareStatus: string) => void;
  nomedicareSet: (nomedicare: boolean) => void;
  medicareexptempSet: (expiry: string) => void;
}

export const QuestionnaireMedicare = (props: QuestionnaireMedicareProps) => (
  <Flex
    flexDirection="column"
    css={(theme) => ({
      maxWidth: "342px",
      width: "100% !important",
      padding: "8px !important",
      marginLeft: "auto",
      marginRight: "auto",
      lineHeight: "normal",
      minHeight: "35px !important",
      height: "unset !important",
      [theme.mq.md]: {
        maxWidth: "431px !important",
        width: "100% !important"
      },
      [theme.mq.lg]: {
        width: "431px !important",
        maxWidth: "unset  !important"
      }
    })}
  >
    <Flex
      flexDirection="column"
      css={(theme) => ({
        marginBottom: props.medicareSelectedStatus === "yes" ? "0px" : "24px",
        [theme.mq.sm]: {
          marginBottom: props.medicareSelectedStatus === "yes" ? "8px" : "32px"
        }
      })}
    >
      <Button
        fullWidth
        variant="primary"
        id="yes"
        onClick={(e) => {
          e.preventDefault();
          props.medicareSelectedStatusSet("yes");
          props.nomedicareSet(false);
        }}
        css={(theme) => ({
          borderColor: theme.color.primary,
          background:
            props.medicareSelectedStatus === "yes"
              ? theme.color.primary
              : "white",
          color: props.medicareSelectedStatus === "yes" ? "white" : "black",
          "&:hover, &:active, &.active": {
            color: "#fff !important",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
          }
        })}
      >
        Yes
      </Button>
      {props.medicareSelectedStatus === "yes" && (
        <Flex
          flexDirection="column"
          css={(theme) => ({
            paddingTop: "24px",
            [theme.mq.sm]: {
              paddingTop: "32px"
            }
          })}
        >
          <div
            className="asterisk"
            css={{
              lineHeight: 1,
              fontSize: "14px",
              fontWeight: "500",
              paddingBottom: "4px"
            }}
          >
            Medicare No.
          </div>
          <input
            className="noArrowInNumberInput"
            key={"medicareSet"}
            name="medicare"
            placeholder="Medicare Number"
            id="medicare"
            defaultValue={props.medicare}
            type="number"
            pattern="\d*"
            onKeyDown={props.next}
            css={(theme) => ({
              height: "37px",
              borderRadius: "12px",
              lineHeight: "135% !important",
              padding: "8px",
              fontSize: "14px",
              border:
                "0.5px solid var(--components-divider-divider-background, #737373)",
              marginBottom: "16px",
              [theme.mq.sm]: {
                marginBottom: "24px"
              }
            })}
          />
          <div className="d-flex flex-column flex-lg-row">
            <Flex
              flexDirection="column"
              css={(theme) => ({
                width: "100%",
                [theme.mq.lg]: {
                  marginRight: "24px",
                  width: "130px"
                }
              })}
            >
              <div
                className="asterisk"
                css={{
                  lineHeight: 1,
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingBottom: "4px"
                }}
              >
                Reference No.
              </div>
              <input
                key={"medicareref"}
                name="medicareref"
                placeholder="Medicare Ref."
                id="medicareref"
                type="number"
                pattern="\d*"
                defaultValue={props.medicareref}
                onKeyDown={props.next}
                css={(theme) => ({
                  height: "37px",
                  borderRadius: "12px",
                  lineHeight: "135% !important",
                  padding: "8px",
                  fontSize: "14px",
                  border:
                    "0.5px solid var(--components-divider-divider-background, #737373)",
                  marginBottom: "16px",
                  [theme.mq.sm]: {
                    marginBottom: "24px"
                  }
                })}
              />
            </Flex>
            <Flex flexDirection="row" className="flex-fill">
              <Flex
                flexDirection="column"
                className="flex-fill"
                css={{
                  paddingRight: "24px"
                }}
              >
                <div
                  className="asterisk"
                  css={{
                    lineHeight: 1,
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingBottom: "4px"
                  }}
                >
                  Expiry
                </div>
                <input id="medicare-expiry" name="medicareexp" type="hidden" />
                <Form.Select
                  id="medicare-expiry-month"
                  onChange={(e) => {
                    const month = e.target.value;
                    // @ts-ignore
                    const year = document.getElementById(
                      "medicare-expiry-year"
                      // @ts-ignore
                    ).value;
                    const value = `${year}-${month}`;
                    props.medicareexptempSet(value);
                    if (month && year) {
                      // @ts-ignore
                      document.getElementById("medicare-expiry").value = value;
                    }
                  }}
                  value={
                    props.medicareexptemp
                      ? props.medicareexptemp.split("-")[1]
                      : props.medicareexp
                      ? props.medicareexp.split("-")[1]
                      : ""
                  }
                  css={(theme) => ({
                    height: "37px",
                    borderRadius: "12px",
                    lineHeight: "135% !important",
                    padding: "8px",
                    fontSize: "14px",
                    border:
                      "0.5px solid var(--components-divider-divider-background, #737373)",
                    marginBottom: "16px",
                    [theme.mq.sm]: {
                      marginBottom: "24px"
                    }
                  })}
                >
                  <option disabled value="">
                    Expiry month
                  </option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </Form.Select>
              </Flex>
              <Flex flexDirection="column" className="flex-fill">
                <div
                  className=""
                  css={{
                    lineHeight: 1,
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingBottom: "4px",
                    height: "18px"
                  }}
                ></div>
                <Form.Select
                  id="medicare-expiry-year"
                  onChange={(e) => {
                    // @ts-ignore
                    const month = document.getElementById(
                      "medicare-expiry-month"
                      // @ts-ignore
                    ).value;
                    const year = e.target.value;
                    const value = `${year}-${month}`;
                    props.medicareexptempSet(value);
                    if (month && year) {
                      // @ts-ignore
                      document.getElementById("medicare-expiry").value = value;
                    }
                  }}
                  value={
                    props.medicareexptemp
                      ? props.medicareexptemp.split("-")[0]
                      : props.medicareexp
                      ? props.medicareexp.split("-")[0]
                      : ""
                  }
                  css={(theme) => ({
                    height: "37px",
                    borderRadius: "12px",
                    lineHeight: "135% !important",
                    padding: "8px",
                    fontSize: "14px",
                    border:
                      "0.5px solid var(--components-divider-divider-background, #737373)",
                    marginBottom: "16px",
                    [theme.mq.sm]: {
                      marginBottom: "24px"
                    }
                  })}
                >
                  <option disabled value="">
                    Expiry year
                  </option>
                  {Array.from(Array(7)).map((_, index) => {
                    const year = moment().year() + index;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Form.Select>
              </Flex>
            </Flex>
          </div>
        </Flex>
      )}
    </Flex>
    <Flex
      flexDirection="column"
      css={(theme) => ({
        marginBottom: "24px",
        [theme.mq.sm]: {
          marginBottom: "32px"
        }
      })}
    >
      <Button
        fullWidth
        variant="primary"
        id="nomedicare"
        onClick={(e) => {
          e.preventDefault();
          props.medicareSelectedStatusSet("no");
          props.nomedicareSet(true);
        }}
        css={(theme) => ({
          borderColor: theme.color.primary,
          background:
            props.medicareSelectedStatus === "no"
              ? theme.color.primary
              : "white",
          color: props.medicareSelectedStatus === "no" ? "white" : "black",
          "&:hover, &:active, &.active": {
            color: "#fff !important",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
          }
        })}
      >
        No
      </Button>
    </Flex>
    {!props.nolater && (
      <Flex
        flexDirection="column"
        css={(theme) => ({
          marginBottom: "24px",
          [theme.mq.sm]: {
            marginBottom: "32px"
          }
        })}
      >
        <Button
          fullWidth
          variant="primary"
          id="askmelater"
          onClick={(e) => {
            e.preventDefault();
            props.medicareSelectedStatusSet("later");
            props.nomedicareSet(true);
          }}
          css={(theme) => ({
            borderColor: theme.color.primary,
            background:
              props.medicareSelectedStatus === "later"
                ? theme.color.primary
                : "white",
            color: props.medicareSelectedStatus === "later" ? "white" : "black",
            "&:hover, &:active, &.active": {
              color: "#fff !important",
              borderColor: theme.color.primary,
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
            }
          })}
        >
          Ask me later
        </Button>
      </Flex>
    )}
  </Flex>
);
