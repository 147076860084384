import { ComponentPropsWithoutRef } from "react";

export const IconCircleChevronUp = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 0C19.1094 0 24.5 5.39062 24.5 12C24.5 18.6562 19.1094 24 12.5 24C5.84375 24 0.5 18.6562 0.5 12C0.5 5.39062 5.84375 0 12.5 0ZM12.5 22.5C18.2656 22.5 23 17.8125 23 12C23 6.23438 18.2656 1.5 12.5 1.5C6.6875 1.5 2 6.23438 2 12C2 17.8125 6.6875 22.5 12.5 22.5ZM13.0156 7.73438L18.2656 12.9844C18.5469 13.2656 18.5469 13.7812 18.2656 14.0625C17.9844 14.3438 17.4688 14.3438 17.1875 14.0625L12.5 9.32812L7.76562 14.0625C7.48438 14.3438 6.96875 14.3438 6.6875 14.0625C6.40625 13.7812 6.40625 13.2656 6.6875 12.9844L11.9375 7.73438C12.0781 7.59375 12.2656 7.5 12.5 7.5C12.6875 7.5 12.875 7.59375 13.0156 7.73438Z"
      fill="currentColor"
    />
  </svg>
);
