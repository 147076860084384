import { useContext } from "react";
import { Patient, PatientContext } from "@mh/api";
import { Validation } from "@mh/core";
import { PhoneField } from "@mh/components";

import { ProfileField, ProfileForm } from "./ProfileForm";
import { ProfileCard } from "./ProfileCard";
import { NextOfKinNew } from "./QuestionnaireRepeat";

type NextOfKinValues = Pick<
  Patient,
  "nok_first_name" | "nok_last_name" | "nok_email" | "nok_phone"
>;

export interface NextOfKinProps {
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
  /* value is true if it is from questionnaire */
  isQuestionnaire?: boolean | undefined;
}

export const NextOfKin = ({
  isEditingCallback,
  isQuestionnaire
}: NextOfKinProps) => {
  const { isFetching, data: patient, update } = useContext(PatientContext);

  return (
    <ProfileForm<NextOfKinValues>
      title="Next of Kin"
      id="nextofkin"
      initialValues={{
        nok_first_name: patient?.nok_first_name || "",
        nok_last_name: patient?.nok_last_name || "",
        nok_email: patient?.nok_email || "",
        nok_phone: patient?.nok_phone || ""
      }}
      isFetching={isFetching}
      onSave={async (values) => {
        await update({
          nok_first_name: values.nok_first_name,
          nok_last_name: values.nok_last_name,
          nok_email: values.nok_email,
          nok_phone: values.nok_phone
        });
      }}
      validate={(values) => {
        let error = {
          nok_first_name: Validation.strLen(
            { gtEq: 2 },
            { name: "First name" }
          ).validate(values.nok_first_name),
          nok_last_name: Validation.strLen(
            { gtEq: 2 },
            { name: "Last name" }
          ).validate(values.nok_last_name),
          nok_email: Validation.email().validate(values.nok_email, {
            optional: true
          }),
          nok_phone: Validation.mobile().validate(values.nok_phone, {
            optional: true
          })
        };

        if (!values.nok_email && !values.nok_phone) {
          error = {
            ...error,
            nok_email: "One of email or phone must be set.",
            nok_phone: "One of email or phone must be set."
          };
        }
        return error;
      }}
      isEditingCallback={isEditingCallback}
      isQuestionnaire={isQuestionnaire}
    >
      {({ values, setValues, error, isEditing, id }) =>
        !isQuestionnaire ? (
          <>
            <ProfileCard.Group>
              <ProfileForm.Control
                label="First name"
                data-testid={`${id}-firstname`}
                disabled={isFetching}
                error={error?.nok_first_name}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({
                    ...values,
                    nok_first_name: e.currentTarget.value
                  })
                }
                value={values.nok_first_name}
              />
              <ProfileForm.Control
                label="Last name"
                data-testid={`${id}-lastname`}
                disabled={isFetching}
                error={error?.nok_last_name}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, nok_last_name: e.currentTarget.value })
                }
                value={values.nok_last_name}
              />
            </ProfileCard.Group>
            <ProfileCard.HorizontalDivider />
            <ProfileCard.Group>
              <ProfileForm.Control
                label="Email"
                data-testid={`${id}-email`}
                disabled={isFetching}
                error={error?.nok_email}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, nok_email: e.currentTarget.value })
                }
                value={values.nok_email}
              />
              <ProfileField label={"Phone"} error={error?.nok_phone}>
                <PhoneField
                  readOnly={!isEditing}
                  inputTestId={`${id}-phone`}
                  onChange={(value) =>
                    setValues({ ...values, nok_phone: value ?? "" })
                  }
                  value={values.nok_phone}
                  className={
                    error?.nok_phone !== undefined ? "is-invalid" : undefined
                  }
                />
              </ProfileField>
            </ProfileCard.Group>
          </>
        ) : (
          <>
            <NextOfKinNew
              values={values}
              setValues={setValues}
              error={error}
              isEditing={isEditing}
              id={id}
              isFetching={isFetching}
            />
          </>
        )
      }
    </ProfileForm>
  );
};
