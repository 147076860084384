/** Common RegExp patterns used in validating field inputs
 *
 * Note: before adding any regex remember that certain browsers limit the types of allowed regular expressions to
 * be used (looking at you apple), have a look at https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
 * which includes one such case.
 */

export const CommonRegEx: { [k: string]: RegExp } = {
  email:
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
  /**
   * The following regex has two cases, the single character which allows any character capitalized or lower, and the
   * '-' or '.' symbols. Otherwise for longer names it has to start and end with alpha characters, but can have one symbol
   * of '-', ' ', or ''' interspaced between alpha characters.
   */
  name: /^(([a-zA-Z-.])|([a-zA-Z]+(-? ?'?[a-zA-Z])+))$/
};
