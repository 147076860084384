import { FC, useEffect, useMemo, useState } from "react";
import { SetupIntent, PaymentMethodCreateParams } from "@stripe/stripe-js";
import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import { asyncIsSuccess, CheckoutAPI, useAsync } from "@mh/api";
import { Sentry } from "@mh/core";
import { Button, Flex, Heading, Toast } from "@mh/components";
import CardSelector, { Card } from "./Card";
import Form from "react-bootstrap/Form";

interface Props {
  /** The setup intent client secret */
  clientSecret: string;
  /** Default card payment method, if one exists. */
  defaultCard?: Card;
  /** If true, disables the payment */
  disabled?: boolean;
  /** Submits the checkout with a resolved setup intent that has been verified and submitted */
  onCheckout: (setupIntent: SetupIntent) => Promise<void>;
  /** Display the price on pay button */
  totalPrice: number;
  /** status for shipping whether it is edit or not */
  isShippingEditStatus?: boolean;
}

const Payment: FC<Props> = (props) => {
  const addresses = useAsync(CheckoutAPI.getAddresses);
  const stripe = useStripe();
  const elements = useElements();
  // Addresses may be removed, pending final decision upon OTC release
  const [isPaymentComplete, setIsPaymentComplete] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [setupIntent, setSetupIntent] = useState<SetupIntent | null>(null);
  const [willUseExistingCard, setWillUseExistingCard] = useState<boolean>(
    !!props.defaultCard
  );

  /** Disable the payment button until completely loaded. */
  const paymentDisabled =
    props.disabled ||
    !stripe ||
    !elements ||
    !setupIntent ||
    (!willUseExistingCard && !isPaymentComplete) ||
    isSubmitting;

  const defaultAddress =
    useMemo<PaymentMethodCreateParams.BillingDetails.Address | null>(() => {
      if (!asyncIsSuccess(addresses.async)) {
        return null;
      }

      // Try and get the first address. If none exist, just continue because payment isn't that necessary for Stripe
      const [address] = addresses.async.data.results;

      if (!address) {
        return null;
      }

      // This sucks. Split the URL and get the two-letter country code from the end
      // An example URL would be http://localhost:8000/shop/api/countries/AU/
      const splitUrl = address.country.split("/");
      // The second last element is the two-letter code i.e. "AU" or "NZ"
      const countryCode = splitUrl[splitUrl.length - 2];

      return {
        line1: address.line1,
        line2: address.line2,
        city: address.suburb,
        state: address.state,
        postal_code: address.postcode,
        country: countryCode
      };
    }, [addresses.async]);

  useEffect(() => {
    if (!stripe) return;

    stripe
      .retrieveSetupIntent(props.clientSecret)
      .then((result) => setSetupIntent(result.setupIntent ?? null));
  }, [stripe, props.clientSecret]);

  /**
   * Submits the checkout with the provided payment. This is done inside thie Payment component so we can access the
   * Stripe and Elements hooks
   */
  const handleCheckout = async (): Promise<void> => {
    if (props.isShippingEditStatus) {
      Toast.error("Finish updating your address to proceed with payment");
      return;
    }
    if (
      props.disabled ||
      stripe === null ||
      elements === null ||
      // If the user uses their previous card, then we don't need to validate their new card details
      (!willUseExistingCard && !isPaymentComplete) ||
      setupIntent === null
    ) {
      console.warn(
        "Cannot checkout as the checkout is disabled, Stripe or Elements are null, or payment details have not been filled"
      );
      return;
    }

    setIsSubmitting(true);

    let resolvedSetupIntent: SetupIntent | null = null;
    try {
      if (
        willUseExistingCard &&
        typeof setupIntent.payment_method === "string"
      ) {
        // Confirm with the supplied existing payment method ID
        resolvedSetupIntent = await stripe
          .confirmCardSetup(
            props.clientSecret,
            {
              payment_method: setupIntent.payment_method
              // Don't need to supply billing details as they're already attached to the payment method
            },
            { handleActions: false }
          )
          .then((r) => {
            if (r.error) {
              throw r;
            }
            return r.setupIntent ?? null;
          });
      } else {
        // Confirm without a supplied exsting payment method ID
        await elements.submit();
        resolvedSetupIntent = await stripe
          .confirmSetup({
            elements: elements,
            clientSecret: props.clientSecret,
            confirmParams: {
              payment_method_data: {
                billing_details: { address: defaultAddress ?? undefined }
              }
            },
            redirect: "if_required"
          })
          .then((r) => {
            if (r.error) {
              throw r;
            }
            return r.setupIntent ?? null;
          });
      }
    } catch (e) {
      Toast.error(
        "Sorry, we were unable to process your payment using the details we have on file. Please enter your card details below and try again."
      );
      Sentry?.captureException(e);
      Sentry.captureMessage(
        "payment exception from stripe intent [Error 5431]",
        (scope) => {
          // @ts-ignore
          scope.setExtra("Error", e?.error?.message);
          return scope;
        }
      );
      // Stripe setup failed
      setIsSubmitting(false);
      return;
    }

    if (resolvedSetupIntent === null) return;

    try {
      await props.onCheckout(resolvedSetupIntent);
    } catch (e) {
      // Order creation faled
      setIsSubmitting(false);
    }
  };

  return (
    <div
      css={(theme) => ({
        display: "flex",
        flexFlow: "column",
        gap: "16px",
        border: `1px solid ${theme.color.primary}`,
        borderRadius: "8px",
        padding: "16px"
      })}
    >
      <Heading>Payment method</Heading>
      {props.defaultCard && (
        <CardSelector
          card={props.defaultCard}
          selected={willUseExistingCard}
          onSelect={() => setWillUseExistingCard(true)}
        />
      )}
      <hr />
      <Flex
        className="card-option"
        marginBetween="0.5rem"
        onClick={() => setWillUseExistingCard(false)}
      >
        <Form.Check type="radio" checked={!willUseExistingCard} readOnly />
        <span>Add New Card</span>
      </Flex>
      {!willUseExistingCard && (
        <PaymentElement
          onChange={({ complete }) => setIsPaymentComplete(complete)}
          options={{
            wallets: { googlePay: "auto", applePay: "auto" }
          }}
        />
      )}
      <Button disabled={paymentDisabled} onClick={handleCheckout}>
        {!setupIntent && "-"}
        {setupIntent !== null && isSubmitting
          ? "Submitting..."
          : `Pay $${props.totalPrice.toFixed(2)}`}
      </Button>
    </div>
  );
};

export default Payment;
