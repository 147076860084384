import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OTPMethod,
  OTPRequestContent,
  User,
  UserAPI,
  OTPMethodNames
} from "@mh/api";
import { BRAND, SafeURLSearchParams } from "@mh/core";
import { Toast, ToastType } from "@mh/components";

import { LoginForm } from "./LoginForm";
import { OTPForm } from "./OTPForm";
import { RedirectModal } from "./RedirectModal";
import { ContactUs } from "./ContactUs";

import "./styles.scss";

/**
 * Conditionally import the stagger scss file. It underlines text links to ensure that they are visible to the patient
 */
if (BRAND === "stagger") {
  import("./stagger.scss");
}

interface LoginProps {
  userReentry?: boolean;
  userEmail?: string;
  isQuestionnaireModal?: boolean;
}

interface ToastMessage {
  type: ToastType;
  text: string;
}

const prompts: Record<string, ToastMessage> = {
  corporate_member_signed_up: {
    type: "success",
    text: "You have successfully enabled your hubPass membership! Please login to access your benefits."
  }
};

export const Login = ({
  userReentry,
  userEmail,
  isQuestionnaireModal
}: LoginProps) => {
  const location = useLocation();
  const searchParams = new SafeURLSearchParams(location.search);
  const redirectOnLogin = searchParams.get("redirect");
  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState(false);
  const [otpRequest, setOTPRequest] = useState<OTPRequestContent>({
    email: "",
    method: "sms"
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [emailUrlParam, setEmailUrlParam] = useState<string>("");

  useEffect(() => {
    const prompt = searchParams.get("prompt");
    if (prompt && prompts[prompt]) {
      const { type, text } = prompts[prompt];
      Toast.generic(type, text);
    }
    const emailParam = searchParams.get("email");
    if (emailParam) {
      setEmailUrlParam(emailParam);
    }
  }, []);

  const handleResponse = (request: OTPRequestContent, response: Response) => {
    const method = OTPMethodNames[request.method];
    if (response.ok) {
      // Do not bombard user with toasts if coming via Corporate Sign Up Route
      if (!emailUrlParam) {
        Toast.success(
          `If your email is linked to an account, an ${method} will be sent to you containing a single-use code. This code will remain valid for 5 minutes.`
        );
      }
      setOtpSent(true);
    } else {
      Toast.error(`Error sending ${method}`);
    }
  };

  const sendOTPRequest = async (request: OTPRequestContent) => {
    setLoading(true);
    try {
      const response = await UserAPI.getOTP(request);
      handleResponse(request, response);
    } catch (e) {
      Toast.error("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  const sendOTPHandler = (
    method: OTPMethod,
    newOtpRequest?: OTPRequestContent | undefined
  ) => {
    const content: OTPRequestContent = newOtpRequest
      ? { ...newOtpRequest, method: method }
      : { ...otpRequest, method: method };
    setOTPRequest(content);
    sendOTPRequest(content);
  };

  useEffect(() => {
    if (userEmail || emailUrlParam) {
      const emailValue = userEmail || emailUrlParam;
      setOTPRequest({ ...otpRequest, email: emailValue });
      sendOTPHandler("sms", { ...otpRequest, email: emailValue });
    }
  }, [userEmail, emailUrlParam]);

  const loginUser = (loginResponse: any) => {
    if (loginResponse.redirect) {
      setRedirectUrl(loginResponse.redirect);
      return;
    }

    if (!loginResponse.has_patient_details) {
      Toast.error("Please enter a valid code");
      return;
    }

    User.login(loginResponse);
    Toast.dismiss();

    if (redirectOnLogin) {
      navigate(redirectOnLogin);
    }
  };

  const verifyHandler = async (code: string) => {
    setLoading(true);
    try {
      const response = await UserAPI.validateOTP({
        email: otpRequest.email,
        token: code
      });

      if (response.ok) {
        loginUser(await response.json());
      } else {
        // Default error message is when the code doesn't match - HTTP 403 response
        let message = "Please enter a valid code";

        if (response.status === 401) {
          // HTTP 401: Code was valid but the user is disabled
          message =
            "We have deactivated your account. To reactivate your account, please contact patient support on help@hub.health";
        }

        Toast.error(message);
      }
    } catch (e) {
      Toast.error("Something went wrong, please try again");
    } finally {
      setLoading(false);
    }
  };

  const otpForm = (
    <>
      <OTPForm
        loading={loading}
        method={otpRequest.method}
        onSubmit={verifyHandler}
        onOtpRequest={sendOTPHandler}
        userReentry={userReentry}
        isQuestionnaireModal={isQuestionnaireModal}
      />
      {redirectUrl && (
        <RedirectModal url={redirectUrl} onClose={() => setRedirectUrl(null)} />
      )}
    </>
  );

  const loginForm = (
    <LoginForm
      loading={loading}
      otpRequest={otpRequest}
      onChange={(request) => setOTPRequest({ ...otpRequest, ...request })}
      onSubmit={sendOTPHandler}
      userReentry={userReentry}
    />
  );

  return (
    <div className="login-page">
      {otpSent || isQuestionnaireModal || emailUrlParam ? otpForm : loginForm}
      <ContactUs />
    </div>
  );
};
