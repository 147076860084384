export enum QuestionContentType {
  // NOTE: IF YOU ADD A NEW ITEM TO THIS ENUM, YOU MUST ALSO ADD A NEW COMPONENT TO
  // packages/questionnaire/src/components/question/content/index.tsx :: ComponentContentTypes
  ADDRESS = "address",
  BOOLEAN = "boolean",
  DATE = "date",
  DEAD_END = "dead_end",
  DVA = "dva",
  EMAIL = "email",
  FLOAT = "float",
  FULL_NAME = "full_name",
  INFO = "info",
  INTEGER = "integer",
  SINGLE_SELECTION_LIST = "single_selection_list",
  MULTI_SELECTION_LIST = "multi_selection_list",
  LONG_TEXT = "long_text",
  MEDICARE = "medicare",
  MOBILE = "mobile",
  QUESTIONNAIRE_PAYMENT = "questionnaire_payment",
  SHORT_TEXT = "short_text",
  SPLASH = "splash",
  PRODUCT_SEARCH = "product_search",
  MEDICATION_SELECTION = "medication_selection"
}
