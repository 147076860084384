import { PhoneField } from "@mh/components";

import { ProfileField, ProfileForm } from "../ProfileForm";

export interface NextOfKinNewProps {
  values: any;
  setValues: any;
  error: any;
  isEditing: any;
  id: any;
  isFetching: any;
}

export const NextOfKinNew = ({
  values,
  setValues,
  error,
  isEditing,
  id,
  isFetching
}: NextOfKinNewProps) => {
  return (
    <div className="next-of-kin">
      <div className="d-flex flex-row">
        <div className="w-100 d-flex flex-row">
          <ProfileForm.Control
            label="First name"
            fieldClass="first-name-field"
            data-testid={`${id}-firstname`}
            disabled={isFetching}
            error={error?.nok_first_name}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({ ...values, nok_first_name: e.currentTarget.value })
            }
            value={values.nok_first_name}
          />
          <ProfileForm.Control
            label="Last name"
            fieldClass="last-name-field"
            data-testid={`${id}-lastname`}
            disabled={isFetching}
            error={error?.nok_last_name}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({ ...values, nok_last_name: e.currentTarget.value })
            }
            value={values.nok_last_name}
          />
        </div>
      </div>
      <div
        className="d-flex flex-lg-row flex-column"
        css={(theme) => ({
          [theme.mq.md]: {
            span: {
              width: "155px !important"
            }
          }
        })}
      >
        <ProfileField
          label={"Phone"}
          fieldClass="phone-field"
          error={error?.nok_phone}
        >
          <PhoneField
            readOnly={!isEditing}
            inputTestId={`${id}-phone`}
            onChange={(value) =>
              setValues({ ...values, nok_phone: value ?? "" })
            }
            value={values.nok_phone}
            className={
              error?.nok_phone !== undefined ? "is-invalid" : undefined
            }
          />
        </ProfileField>
        <ProfileForm.Control
          label="Email"
          fieldClass="email-field"
          data-testid={`${id}-email`}
          disabled={isFetching}
          error={error?.nok_email}
          isEditing={isEditing}
          onChange={(e) =>
            setValues({ ...values, nok_email: e.currentTarget.value })
          }
          value={values.nok_email}
        />
      </div>
      <div
        className="w-100"
        css={(theme) => ({
          height: "1px",
          backgroundColor: "#D9D9D9",
          marginTop: "8px",
          [theme.mq.md]: {
            marginTop: "16px"
          }
        })}
      />
    </div>
  );
};
