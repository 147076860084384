import { ComponentPropsWithoutRef } from "react";

export const IconEmotionalWellbeing = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0002 17.1937C13.6821 17.1937 16.6668 14.2089 16.6668 10.527C16.6668 6.84512 13.6821 3.86035 10.0002 3.86035C6.31826 3.86035 3.3335 6.84512 3.3335 10.527C3.3335 14.2089 6.31826 17.1937 10.0002 17.1937Z"
      stroke="#9983A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.3335 11.1183C7.64399 11.5323 8.0466 11.8683 8.50945 12.0997C8.9723 12.3312 9.48268 12.4517 10.0002 12.4517C10.5176 12.4517 11.028 12.3312 11.4909 12.0997C11.9537 11.8683 12.3563 11.5323 12.6668 11.1183M7.34029 8.52637H7.33429M12.6676 8.52637H12.6616"
      stroke="#9983A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
