interface GenericQNameInputProps {
  outsideDivClass: string;
  id: string;
  label: string;
  accountClass?: string;
}

const GenericQNameInput = (props: GenericQNameInputProps) => (
  <div className={`${props.outsideDivClass} v-center`}>
    <label htmlFor={props.id}>
      <div className={`h2-sm asterisk ${props.accountClass ?? ""}`}>
        {props.label}
      </div>
    </label>
    <div>
      <input
        required={false}
        placeholder=""
        id={props.id}
        className="ember-text-field ember-view"
        type="text"
      />
    </div>
  </div>
);
interface QNameInputProps {
  type: string; // question type
  accountAttrClass?: string; // input label class
}

export const FirstNameBlock = (props: QNameInputProps) => (
  <GenericQNameInput
    id="signup_firstname"
    label="First Name"
    outsideDivClass={props?.type === "bypass" ? "col-12" : "col-12 col-lg-6"}
    accountClass={props?.accountAttrClass}
  />
);

export const LastNameBlock = (props: QNameInputProps) => (
  <GenericQNameInput
    id="signup_lastname"
    label="Last Name"
    outsideDivClass={props?.type === "bypass" ? "col-12" : "col-12 col-lg-5"}
    accountClass={props?.accountAttrClass}
  />
);
