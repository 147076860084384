import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { PatientContext } from "@mh/api";
import { Button, IconArrowRight, IconSuccess } from "@mh/components";

import "./GetYourMeds.scss";

const features = [
  "Prescription medications",
  "Over the counter products",
  "Fast delivery",
  "Trusted medical experts",
  "Quick and convenient access"
];

export const GetYourMeds = () => {
  const { data: patient } = useContext(PatientContext);

  const featureList = (
    <ul>
      {features.map((item, index) => (
        <li key={index}>
          <IconSuccess
            width={16}
            height={16}
            css={(theme) => ({ fill: theme.color.fourth })}
          />
          <span>{item}</span>
        </li>
      ))}
    </ul>
  );

  const getImageUrlForGender = (gender: string | undefined | null) => {
    // Default to "female" if gender is not explicitly "male"
    const imageGender = gender === "male" ? "male" : "female";
    return `/assets/images/com/medication-${imageGender}.png`;
  };

  return (
    <div className="medication">
      <div
        className="image"
        css={{
          backgroundImage: `url("${getImageUrlForGender(patient?.gender)}")`
        }}
      ></div>
      <div className="content">
        <div className="title">
          Get your meds and more without the hassle.
          <span> Skip the line, refill online.</span>
        </div>
        {featureList}
        <div className="buttons">
          <NavLink
            to="/questionnaire/?product=renew-a-script&version=new"
            state={{ isNavLink: true, from: window.location.pathname }}
          >
            <Button>
              <span>Need a script</span>
              <IconArrowRight width={16} />
            </Button>
          </NavLink>
          <NavLink
            to="/questionnaire/?product=fill-a-script"
            state={{ isNavLink: true, from: window.location.pathname }}
          >
            <Button className="have-script">
              <span>Have a script</span>
              <IconArrowRight width={16} />
            </Button>
          </NavLink>
          <NavLink to="/shop">
            <Button className="pharmacy">
              <span>Explore our online shop</span>
              <IconArrowRight width={16} fill="#000000" />
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
