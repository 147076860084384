import { Flex, DiscountCode, CheckoutPaymentType } from "../";
import { VoucherDiscount } from "@mh/api";
import { Heading } from "@mh/components";

interface Props {
  subtotal: number;
  tax: number;
  total: number;
  shipping?: number;
  onDiscountCodeApplied: (code: string) => void;
  voucherDiscounts: VoucherDiscount[];
  isApplyingDiscount: boolean;
  discountError: string | null;
  membershipFee?: string;
  memberSaving?: string;
  displayMembershipPricing?: boolean;
  isMembershipSelected?: boolean;
  onMembershipSelectChange?: (value: boolean) => void;
}

const PriceDisplay = ({
  subtotal,
  total,
  shipping,
  onDiscountCodeApplied,
  voucherDiscounts,
  isApplyingDiscount,
  discountError,
  membershipFee,
  memberSaving,
  displayMembershipPricing,
  isMembershipSelected,
  onMembershipSelectChange
}: Props) => {
  /** A gift card which may have been applied as part of a basket */
  const giftCardDiscount = voucherDiscounts
    ?.filter(
      ({ voucher }) =>
        voucher.is_gift_card &&
        voucher.remaining_value !== null &&
        voucher.remaining_value_after_usage !== null
    )
    .at(0);

  /** The discount, and remaining balance of a gift card which may have been applied as part of a basket */
  let giftCardDiscountValue: number | null = null;
  /** How much remains on the gift card, if one was applied */
  let remainingBalance: number | null = null;
  /** The total cost, ignoring a gift card discount */
  let totalExcludingDiscount = total;

  if (giftCardDiscount) {
    giftCardDiscountValue = parseInt(giftCardDiscount.amount);
    remainingBalance = giftCardDiscount.voucher.remaining_value_after_usage;
    totalExcludingDiscount = total + giftCardDiscountValue;
  }

  const wasGiftCardUsed =
    !!giftCardDiscount &&
    giftCardDiscountValue !== null &&
    remainingBalance !== null;

  return (
    <Flex flexDirection="column" marginBetween="16px" width="100%">
      <Heading>Billing summary</Heading>
      <Flex justifyContent="space-between">
        <span>Subtotal</span>
        <span>${subtotal.toFixed(2)}</span>
      </Flex>
      <Flex justifyContent="space-between">
        <span>Delivery Cost</span>
        <span data-testid="delivery-cost">
          {shipping !== undefined
            ? shipping === 0
              ? "FREE"
              : `$${shipping.toFixed(2)}`
            : "TBC"}
        </span>
      </Flex>
      {displayMembershipPricing && (
        <CheckoutPaymentType
          type={isMembershipSelected ? "membership-fee-monthly" : "pay"}
          membershipFee={membershipFee || "0"}
          onChange={() =>
            onMembershipSelectChange &&
            onMembershipSelectChange(!isMembershipSelected)
          }
          memberSaving={memberSaving || "0.00"}
        />
      )}
      <DiscountCode
        text="Promo code"
        onApplied={onDiscountCodeApplied}
        voucherDiscounts={voucherDiscounts}
        isApplyingDiscount={isApplyingDiscount}
        discountError={discountError}
        showDiscountAmount
      />
      <hr style={{ width: "100%" }} />
      {wasGiftCardUsed && (
        <>
          <div css={{ display: "flex", flexFlow: "column", gap: "16px" }}>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <b>Total</b>
              <b>
                {Intl.NumberFormat("en-AU", {
                  style: "currency",
                  currency: "AUD"
                }).format(totalExcludingDiscount)}
              </b>
            </div>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <b>Gift card payment</b>
              <b css={{ color: "var(--theme-danger)" }}>
                -
                {Intl.NumberFormat("en-AU", {
                  style: "currency",
                  currency: "AUD"
                }).format(giftCardDiscountValue!)}
              </b>
            </div>
            <div
              css={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <b>Remaining balance</b>
              <b>
                {Intl.NumberFormat("en-AU", {
                  style: "currency",
                  currency: "AUD"
                }).format(remainingBalance!)}
              </b>
            </div>
          </div>
          <hr style={{ width: "100%" }} />
        </>
      )}
      <Flex justifyContent="space-between">
        <span css={{ fontSize: "24px", fontWeight: 600 }}>Total</span>
        <span css={{ fontSize: "24px", fontWeight: 600 }}>
          ${total.toFixed(2)}
        </span>
      </Flex>
    </Flex>
  );
};

export default PriceDisplay;
