import { PropsWithChildren, useContext, useEffect, useState } from "react";
import moment from "moment";

import { prependBackendHostIfRequired } from "@mh/core";
import { type Order, UserContext, PatientContext } from "@mh/api";
import {
  Heading,
  DropDown,
  IconCircleChevronUp,
  IconCircleChevronDown
} from "@mh/components";

import { makeReadableAddress } from "../../utils";
import { Address } from "../../components/LocationInput/types";

interface OrderDetailsFieldProps {
  label: string;
}

const OrderDetailsField = ({
  children,
  label
}: PropsWithChildren<OrderDetailsFieldProps>) => (
  <div
    css={(theme) => ({
      display: "flex",
      flexFlow: "row",
      alignItems: "start",
      gap: "4px",
      padding: "12px 0px",
      [theme.mq.md]: {
        flexFlow: "column",
        padding: "0px",
        marginRight: "4px"
      }
    })}
  >
    <Heading
      css={(theme) => ({ width: "80px", [theme.mq.md]: { width: "auto" } })}
      size="sm"
    >
      {label}
    </Heading>
    <span>{children}</span>
  </div>
);

interface OrderItemProps {
  order: Order;
}

const downloadFile = (url: string, title: string) => {
  return {
    title: title,
    onClick: () => window.open(prependBackendHostIfRequired(url))
  };
};

export const HistoryItem = ({ order }: OrderItemProps) => {
  const userContext = useContext(UserContext);
  const patientContext = useContext(PatientContext);
  const [open, setOpen] = useState<boolean>(true);

  /** If the user/patient data has not been fetched yet, do it on first render. */
  useEffect(() => {
    if (!userContext.data) {
      userContext.get();
    }
    if (!patientContext.data) {
      patientContext.get();
    }
  }, []);

  let readableAddress = "";

  if (order.shipping_address) {
    // Try use the order address
    const address: Address = {
      addressLine1: order.shipping_address.line1,
      addressLine2: order.shipping_address.line2,
      suburb: order.shipping_address.suburb,
      state: order.shipping_address.state,
      postcode: order.shipping_address.postcode
    };
    readableAddress = makeReadableAddress(address);
  }

  const downloadList = order.consult_documents.map((doc) =>
    downloadFile(doc.url, doc.title)
  );
  if (order.consult_summary?.summary_file) {
    downloadList.push(
      downloadFile(order.consult_summary.summary_file, "Consult Summary")
    );
  }

  return (
    <div
      css={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-end",
        alignItems: "flex-start",
        gap: "8px",
        backgroundColor: "#F8F8F8",
        padding: "24px",
        borderRadius: "8px"
      }}
    >
      <div
        css={(theme) => ({
          width: "100%",
          display: "flex",
          flexFlow: "column",
          gap: "8px",
          cursor: "pointer",
          [theme.mq.md]: {
            cursor: "auto"
          }
        })}
        onClick={() => setOpen(!open)}
      >
        <div
          css={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <span>{moment(order.date_placed).format("D MMMM YYYY")}</span>
          {open ? (
            <IconCircleChevronUp
              css={(theme) => ({
                width: "24px",
                height: "24px",
                color: theme.color.primary,
                [theme.mq.md]: {
                  display: "none"
                }
              })}
            />
          ) : (
            <IconCircleChevronDown
              css={(theme) => ({
                width: "24px",
                height: "24px",
                color: theme.color.primary,
                [theme.mq.md]: {
                  display: "none"
                }
              })}
            />
          )}
        </div>
        <div
          css={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div css={{ marginRight: "4px" }}>
            {order.lines.map((line) => (
              <div key={line.url} css={{ fontSize: "20px" }}>
                {line.product.title}{" "}
              </div>
            ))}
          </div>
          <span>{order.status}</span>
        </div>
      </div>
      <div
        css={(theme) => ({
          width: "100%",
          display: open ? "flex" : "none",
          justifyContent: "space-between",
          flexFlow: "column",
          ">:not(:last-child)": {
            borderBottom: "1px solid #73737355"
          },
          [theme.mq.md]: {
            display: "flex",
            gap: "4px",
            flexFlow: "row",
            ">:not(:last-child)": {
              borderBottom: "none"
            }
          }
        })}
      >
        <OrderDetailsField label="Name">{`${userContext.data?.first_name} ${userContext.data?.last_name}`}</OrderDetailsField>
        <OrderDetailsField label="Address">
          {readableAddress || "-"}
        </OrderDetailsField>
        <OrderDetailsField label="No.">{order.number}</OrderDetailsField>
        {order.tracking_url && (
          <OrderDetailsField label="Track">
            <a href={order.tracking_url} target="_blank" rel="noreferrer">
              Track order
            </a>
          </OrderDetailsField>
        )}
        {order.consult_documents.length > 0 && (
          <OrderDetailsField label="">
            <DropDown items={downloadList} title="Download" />
          </OrderDetailsField>
        )}
        {order.medicalcert?.cert_file && (
          <OrderDetailsField label="Medical Certificate">
            <a
              href={order.medicalcert?.cert_file}
              target="_blank"
              rel="noreferrer"
            >
              Download medical certificate
            </a>
          </OrderDetailsField>
        )}
      </div>
    </div>
  );
};
