import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo
} from "react";
import jQuery from "jquery";

import { Flex, IconError, Toast } from "@mh/components";

import Commmodal from "../../components/common/commmodal";
import Com from "../../components/common/common";

const Common = new Com();

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { Sentry } from "@mh/core";

async function addreplacecardNew(tokeninfo) {
  const result1_ = await Common.Fetch(
    `${Common.Config().HOST}/${
      Common.Config().API
    }/patients/me/payment-method?brand=${Common.Config().FROM.trim()}`,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded"
      }),
      body: `token=${encodeURIComponent(tokeninfo.tokeninfo.token.id)}`
    },
    `creditcard`,
    "addreplacecard",
    false,
    false,
    null
  );

  const result1 = Common.getResult(result1_, "addCreditCardUnified");

  return result1;
}

function getCardElement(obj) {
  let isvaild = true;
  let msg = "";

  let number = obj.number;
  let cvc = obj.cvc;

  let expirymonth = obj.expiry.split("/")[0];
  let expiryyear = obj.expiry.split("/")[1];

  let cc_name = obj.name.split(" ");
  let cc_firstname = "",
    cc_lastname = "";

  if (cc_name[0]) {
    cc_firstname = cc_name[0];
  }

  if (cc_name[1]) {
    cc_lastname = cc_name[1];
  }

  if (expirymonth) {
    expirymonth = expirymonth.trim();
  }

  if (expiryyear) {
    expiryyear = expiryyear.trim();
  }

  if (
    !expirymonth ||
    !expiryyear ||
    expiryyear.length !== 2 ||
    expirymonth.length !== 2
  ) {
    isvaild = false;
    msg = "Invalid expiry date";
  }

  if (cvc.length !== 3) {
    isvaild = false;
    msg = "Invalid Credit Card CVC";
  }

  return {
    isvaild,
    msg,
    expirymonth,
    expiryyear,
    cc_firstname,
    cc_lastname,
    number,
    cvc
  };
}

async function getToken(stripe, elements) {
  const name = jQuery("#nameofcard").val();
  if (!name) {
    Toast.error(
      "Please enter the name of the cardholder in the field provided."
    );
    return false;
  }

  const cc_name = name.split(" ");
  let firstname = "";
  let lastname = "";

  if (cc_name[0]) {
    firstname = cc_name[0];
  }

  if (cc_name[1]) {
    lastname = cc_name[1];
  }

  const cardNumberElement = elements?.getElement(CardNumberElement);
  const tokeninfo = await stripe?.createToken(cardNumberElement);
  return { tokeninfo, firstname, lastname };
}

const MyCards = forwardRef((props, ref) => {
  const [data, setData] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: ""
  });

  const noaddbt = props && props.noaddbt ? true : false;
  const hidemodal = props && props.hidemodal ? true : false;
  const last4 = props && props.last4 ? props.last4 : false;
  const hideclose = props && props.hideclose ? props.hideclose : false;
  const { modalSet } = props;
  const { modaldataSet } = props;
  const { setRefresh } = props;
  const { refresh } = props;

  const { setAfterRefresh } = props;
  const { afterRefresh } = props;
  const { afterbtfn } = props;
  const { afterbtxt } = props;
  const { useafterbtfn } = props;
  const { disabledfn } = props;

  const [creditcardPayment, setcreditcardPayment] = useState(true);

  const { setIsSoftRefresh } = props;
  const { issoftrefresh_ } = props;

  const { halfdesign } = props;

  const { isShowNewPaymentPage } = props;

  //New Stripe Start
  let elStyle = props.inputstyle
    ? props.inputstyle
    : {
        base: {
          color: "#000000",
          lineHeight: "20px",
          fontSize: props?.hidemodal && !isShowNewPaymentPage ? "16px" : "14px"
        }
      };
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const stripe = useStripe();
  const elements = useElements();
  //const options = useOptions();

  const handleCardDetailsChange = (event) => {
    event.error ? setCheckoutError(event.error.message) : setCheckoutError();
    //console.log(event)
    //Need to show error message
  };
  //New Stripe End

  useImperativeHandle(ref, () => ({
    getPaymentType() {
      return creditcardPayment;
    },

    async getToken() {
      return await getToken(stripe, elements);
    },

    async getCardInfo() {
      return getCardElement(data);
    }
  }));

  const addreplacecardNew_ = async (e) => {
    e.preventDefault();
    if (props?.hiddenOverflow)
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    const tokeninfo = await getToken(stripe, elements);
    if (
      !tokeninfo ||
      !tokeninfo.tokeninfo ||
      !tokeninfo.tokeninfo.token ||
      !tokeninfo.tokeninfo.token.id ||
      tokeninfo.tokeninfo.error
    ) {
      Toast.error(
        "Sorry, we were unable to process your request using the card details provided. Please update these details and try again."
      );
      Sentry.captureMessage("Credit card unified generic processing error [1]");
      return false;
    }

    const paymentmethod = await addreplacecardNew(tokeninfo, props);

    if (
      paymentmethod &&
      paymentmethod.addCreditCardUnified &&
      paymentmethod.addCreditCardUnified &&
      (paymentmethod.addCreditCardUnified.success ||
        (paymentmethod.addCreditCardUnified.last4 &&
          paymentmethod.addCreditCardUnified.last4.length === 4))
    ) {
      modalSet ? modalSet(false) : "";
      modaldataSet ? modaldataSet("") : "";
      setRefresh ? setRefresh(!refresh) : "";
      issoftrefresh_ ? setIsSoftRefresh(false) : "";
      setAfterRefresh ? setAfterRefresh("ok") : "";
      useafterbtfn ? afterbtfn() : "";

      if (props.setShowPayment) {
        props.setShowPayment("no");
      }

      if (props && props.paymentStateSet) {
        props.expmonthSet(data.expiry.split("/")[0]);
        props.expyearSet(data.expiry.split("/")[1]);
        props.last4Set(data.number.substring(data.number.length - 4));
        props.paymentStateSet(null);
        Toast.success("Card details updated.");
      }
    } else {
      let message = "";
      try {
        message =
          paymentmethod &&
          paymentmethod.msg &&
          paymentmethod.msg.includes("message")
            ? JSON.parse(paymentmethod.msg)
            : "";
      } catch (e) {
        message = "";
      }

      const defaultMsg =
        "Sorry, we were unable to process your request using the card details provided. Please update these details and try again.";
      const msg =
        message &&
        message.addCreditCardUnified &&
        message.addCreditCardUnified.message
          ? message.addCreditCardUnified.message
          : defaultMsg;
      Toast.error(msg);

      if (msg === defaultMsg) {
        Sentry.captureMessage(
          "Credit card unified generic processing error [2]"
        );
      }
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    if (e.target.name === "expiry") {
      const exdate = e.target.value.split("/");
      if (exdate && Array.isArray(exdate) && exdate.length > 1) {
        const month_ = exdate[0].trim();
        const year_ = exdate[1].trim();
        e.target.value = `${month_}/${year_}`;
      } else if (e.target.value && e.target.value.length > 3) {
        const tmp = e.target.value.trim();
        e.target.value = `${tmp[0]}${tmp[1]}/${tmp[2]}${tmp[3] ? tmp[3] : ""}`;
      }
    }
  };

  useEffect(() => {
    jQuery("#credit-card").on("keypress change", function () {
      jQuery(this).val(function (index, value) {
        return value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
      });
    });
    if (props?.hiddenOverflow)
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
  });

  const modalHeader = props?.type === "decline" && (
    <div className="decline">
      <div className="d-flex justify-content-center bd-highlight mb-1">
        <IconError
          height="24px"
          css={(theme) => ({
            color: theme.color.success,
            paddingRight: "8px",
            marginTop: "auto",
            marginBottom: "auto"
          })}
        />
        <div className="text-center mt-3 mb-3">
          <div
            className="mb-1"
            css={{ color: "red", fontSize: "20px", fontWeight: "400" }}
          >
            Your card was declined.
          </div>
        </div>
      </div>
      <div
        css={(theme) => ({
          paddingTop: "16px",
          paddingBottom: "16px",
          borderTop: "0.5pt solid #737373",
          borderBottom: "0.5pt solid #737373",
          [theme.mq.md]: {
            textAlign: "center"
          }
        })}
      >
        <div
          css={(theme) => ({
            maxWidth: "350px",
            [theme.mq.md]: {
              marginLeft: "auto",
              marginRight: "auto"
            }
          })}
        >
          Please enter your new card details to process your payment.
        </div>
      </div>
    </div>
  );

  let card = (
    <div
      className={`position-relative ${
        props && props.outer_2 ? "" : "inner-content"
      } ${props.disabled && props.disabledgray ? "disabledinput" : ""} ${
        halfdesign ? "halfdesign" : ""
      } newcard`}
    >
      {modalHeader}
      {(!afterRefresh || afterRefresh !== "ok" || !afterbtfn || !afterbtxt) && (
        <div className="cardbox">
          <div className="cardmobilebg"></div>
          <div
            className={`row ${isShowNewPaymentPage ? "newpaymentheader" : ""}`}
          >
            {props?.newsubmitqus &&
              !isShowNewPaymentPage &&
              props.showSeparator && (
                <div className="col-6 col-lg-3 pt-4 pb-4">
                  <div className="bk-line"></div>
                </div>
              )}
            {props?.newsubmitqus && !isShowNewPaymentPage && (
              <div className="col-6 col-lg-9"></div>
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && props?.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.newsubmitqus
                  ? "col-12 col-lg-7"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-4  ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : `col-12 col-lg-4`
                  : "col-12 col-lg-6"
              }
                            ${
                              props?.newsubmitqus
                                ? ""
                                : "pt-9 pb-md-9 pl-5-cs pl-sm-075-cs"
                            }  _card new-card-header card-header-left`}
            >
              <div className={`inner `}>
                <div className="v-center">
                  {props?.from === "repeatquestionnaire" ? (
                    <div
                      css={(theme) => ({
                        fontWeight: "500",
                        fontSize: "20px",
                        paddingBottom: "16px",
                        [theme.mq.sm]: {
                          paddingBottom: "unset"
                        }
                      })}
                    >
                      Your Payment Details
                    </div>
                  ) : isShowNewPaymentPage ? (
                    <h3
                      css={{
                        fontWeight: "600"
                      }}
                    >
                      Your Payment Details
                    </h3>
                  ) : (
                    <h2 className="h2-sm strong">Your Payment Details</h2>
                  )}
                </div>
              </div>
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-1 col-lg-0 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-1 col-lg-0 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.type === "decline"
                  ? "col-12 col-lg-5"
                  : props?.newsubmitqus
                  ? "col-12 col-lg-4"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-4  ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : `col-12 col-lg-4`
                  : "col-12 col-lg-6"
              } 
                            ${
                              props?.newsubmitqus
                                ? ""
                                : "pb-9 pt-2 pt-md-9 pb-md-0 pr-5-cs pl-sm-075-cs"
                            }  _card new-card-header card-header-right`}
            >
              <div
                css={(theme) => ({
                  justifyContent: !props.hidemodal
                    ? "left !important"
                    : "unset",
                  [theme.mq.sm]: {
                    justifyContent: !props.hidemodal
                      ? "right !important"
                      : "unset"
                  }
                })}
                className={`inner img-group ${
                  isShowNewPaymentPage ? "newImgGroup" : ""
                }`}
              >
                <img
                  className={`pr-03 ${isShowNewPaymentPage ? "visa" : ""}`}
                  src="/assets/images/com/visa.jpg"
                ></img>
                <img
                  className={`pr-03 ${isShowNewPaymentPage ? "master" : ""}`}
                  src="/assets/images/com/master.jpg"
                ></img>
                <img
                  className={`pr-03 ${isShowNewPaymentPage ? "stripe" : ""}`}
                  src="/assets/images/com/stripe.jpg"
                ></img>
              </div>
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-1 col-lg-0 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {hidemodal ? (
              props?.outer_2 ? (
                <div
                  className={`col-md-0 col-lg-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
          </div>
          <div className="row">
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.newsubmitqus
                  ? "col-12 col-lg-11"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-8  ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : `col-12 col-lg-8`
                  : "col-12"
              } 
                            ${
                              props?.newsubmitqus
                                ? "pt-4 pb-1"
                                : "pb-3 pl-5-cs pr-5-cs pl-sm-075-cs"
                            } strong asterisk _card card-ele card-ele-title`}
            >
              <span className={``}>Name of card holder</span>
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.type === "decline"
                  ? "col-12"
                  : props?.newsubmitqus
                  ? "col-12 col-lg-11"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-8 ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : `col-12 col-lg-8`
                  : "col-12"
              } 
                            ${props?.newsubmitqus ? "" : "pl-5-cs"} ${
                              props?.newsubmitqus ? "" : "pr-5-cs pl-sm-075-cs"
                            } h-40 _card card-ele card-ele-input`}
            >
              <input
                type="text"
                name="name"
                id="nameofcard"
                className={`fullwidth ${
                  isShowNewPaymentPage ? "nameInput" : ""
                }`}
                placeholder="Name"
                onChange={handleInputChange}
              />
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.newsubmitqus
                  ? "col-12 col-lg-11"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-8 ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : "col-12 col-lg-8"
                  : "col-12"
              }
                            ${
                              props?.newsubmitqus
                                ? "pt-4 pb-1"
                                : "pt-6 pb-3 pl-5-cs pr-5-cs pl-sm-075-cs"
                            } strong asterisk _card card-ele card-ele-title`}
            >
              <span className={``}>Card number</span>
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.type === "decline"
                  ? "col-12"
                  : props?.newsubmitqus
                  ? "col-12 col-lg-11"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-8 ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : `col-12 col-lg-8`
                  : "col-12"
              } 
                            ${
                              props?.newsubmitqus || isShowNewPaymentPage
                                ? ""
                                : "pl-5-cs"
                            } ${
                              props?.type === "decline"
                                ? ""
                                : isShowNewPaymentPage
                                ? ""
                                : "pr-5-cs"
                            } pl-sm-075-cs h-40 _card card-ele card-ele-input`}
            >
              <CardNumberElement
                onChange={handleCardDetailsChange}
                options={{ placeholder: "XXXX XXXX XXXX XXXX", style: elStyle }}
              />
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.type === "decline"
                  ? "col-6"
                  : props?.newsubmitqus
                  ? "col-12 col-lg-5"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-11 col-lg-4 ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : "col-12 col-lg-4"
                  : "col-6"
              } 
                            ${
                              props?.newsubmitqus ? "pt-4 pb-1" : "pt-6 pb-3"
                            } ${
                              props?.newsubmitqus ? "" : "pl-5-cs pl-sm-075-cs"
                            } strong asterisk _card card-ele card-ele-title`}
            >
              <span className={``}>Expiry date</span>
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`col-1 col-lg-0 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            <div
              className={`${
                props?.type === "decline"
                  ? "col-6"
                  : props?.newsubmitqus
                  ? "col-12 col-lg-5"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-4 ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : "col-12 col-lg-4"
                  : "col-6 pt-sm-4-cs pb-sm-0-cs"
              } 
                            ${
                              props?.type === "decline"
                                ? "order-5"
                                : "order-lg-5"
                            } 
                            ${props?.newsubmitqus ? "" : "pl-5-cs"} ${
                              props?.newsubmitqus ? "" : "pr-5-cs pl-sm-075-cs"
                            } h-40 _card card-ele card-ele-input`}
            >
              <CardExpiryElement
                options={{ style: elStyle }}
                onChange={handleCardDetailsChange}
              />
            </div>
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`order-lg-4 col-1 ${
                    props && props.outer_2_class2 ? props.outer_2_class2 : ""
                  }`}
                ></div>
              ) : (
                <div className="order-lg-3 col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`order-lg-3 col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class1 : ""
                  }`}
                ></div>
              ) : (
                <div className="order-lg-3 col-lg-2"></div>
              )
            ) : (
              ""
            )}
            <div
              className={`${
                props?.type === "decline"
                  ? "col-6"
                  : props?.newsubmitqus
                  ? "col-12 col-lg-5"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-11 col-lg-4 ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : `col-12 col-lg-4`
                  : "col-6"
              } 
                            'order-lg-6' 
                            ${
                              props?.newsubmitqus
                                ? "pt-4 pb-1"
                                : "pt-6 pb-3 pr-5-cs pl-sm-075-cs"
                            } 
                            strong asterisk _card card-ele card-ele-title`}
            >
              <span className={``}>CVC</span>
            </div>
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`order-10 col-md-0 col-lg-1 ${
                  props && props.outer_2_class2 ? props.outer_2_class2 : ""
                }`}
              ></div>
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div className={`order-lg-10 col-md-0 col-lg-1`}></div>
            )}
            {props?.newsubmitqus && props?.type !== "decline" && (
              <div className="col-lg-1"></div>
            )}
            <div
              className={`${
                props?.type === "decline"
                  ? "col-6"
                  : props?.newsubmitqus
                  ? "col-12 col-lg-6"
                  : hidemodal
                  ? props && props.outer_2
                    ? `col-10 col-lg-4 ${
                        props && props.outer_2_class1
                          ? props.outer_2_class1
                          : ""
                      }`
                    : "col-12 col-lg-4"
                  : "col-6 pt-sm-4-cs pb-sm-0-cs"
              } 
                                order-last order-lg-5 ${
                                  props?.newsubmitqus
                                    ? ""
                                    : "pb-9 pr-5-cs pl-sm-075-cs"
                                } h-40 _card card-ele card-ele-input`}
            >
              <CardCvcElement
                options={{ style: elStyle }}
                onChange={handleCardDetailsChange}
              />
            </div>
            {!props?.newsubmitqus && hidemodal ? (
              props && props.outer_2 ? (
                <div
                  className={`order-lg-last col-1 ${
                    props && props.outer_2_class1 ? props.outer_2_class2 : ""
                  }`}
                ></div>
              ) : (
                <div className="order-lg-last col-lg-2"></div>
              )
            ) : (
              ""
            )}
            {!props?.newsubmitqus && props && props.outer_2 && (
              <div
                className={`order-last col-1 ${
                  props && props.outer_2_class1 ? props.outer_2_class1 : ""
                }`}
              ></div>
            )}
          </div>{" "}
        </div>
      )}
      {noaddbt ? (
        ""
      ) : afterRefresh && afterRefresh === "ok" && afterbtfn && afterbtxt ? (
        <div className={`row ${props && props.outer_2 ? "pb-6" : ""}`}>
          {hideclose ? (
            props && props.outer_2 ? (
              <div
                className={`col-1 ${
                  props && props.outer_2_class1 ? props.outer_2_class1 : ""
                }`}
              ></div>
            ) : (
              <div className="col-md-6 col-lg-4 column"></div>
            )
          ) : (
            ""
          )}
          {props && props.outer_2 && (
            <div
              className={`col-md-0 col-lg-1 ${
                props && props.outer_2_class2 ? props.outer_2_class2 : ""
              }`}
            ></div>
          )}
          <div
            className={
              !hideclose
                ? "col-12 col-lg-6"
                : props && props.outer_2
                ? `col-10 col-lg-8 pb-6 pt-3 ${
                    props && props.outer_2_class2 ? props.outer_2_class2 : ""
                  }`
                : "col-md-6 col-lg-4 column"
            }
          >
            <button
              className="btn btn-primary col-12"
              onClick={(e) =>
                props.disabled && props.disabledfn
                  ? disabledfn(e)
                  : afterbtfn(e)
              }
            >
              {afterbtxt}
            </button>
          </div>
          {props && props.outer_2 && (
            <div
              className={`col-md-0 col-lg-1 ${
                props && props.outer_2_class2 ? props.outer_2_class2 : ""
              }`}
            ></div>
          )}
          {!hideclose && (
            <div className={"col-12 col-lg-6"}>
              <button
                className="btn btn-primary col-12"
                onClick={() => modalSet(false)}
              >
                Close
              </button>
            </div>
          )}
          {hideclose ? (
            props && props.outer_2 ? (
              <div
                className={`col-1 ${
                  props && props.outer_2_class1 ? props.outer_2_class1 : ""
                }`}
              ></div>
            ) : (
              <div className="col-md-6 col-lg-4 column"></div>
            )
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className={`row ${props && props.outer_2 ? "pb-6" : "pb-3"}`}>
          {hideclose ? (
            props && props.outer_2 ? (
              <div
                className={`col-1 ${
                  props && props.outer_2_class1 ? props.outer_2_class1 : ""
                }`}
              ></div>
            ) : (
              <div className="col-md-6 col-lg-4 column"></div>
            )
          ) : (
            ""
          )}
          {props && props.outer_2 && (
            <div
              className={`col-md-0 col-lg-1 ${
                props && props.outer_2_class2 ? props.outer_2_class2 : ""
              }`}
            ></div>
          )}
          {!hideclose && (
            <div className={"col-12 col-lg-6"}>
              <button
                className="btn btn-primary col-12"
                css={(theme) => ({
                  backgroundColor: props?.type === "decline" ? "white" : "",
                  color: props?.type === "decline" ? theme.color.primary : ""
                })}
                onClick={(e) => {
                  e.preventDefault();
                  if (props?.hiddenOverflow)
                    document.getElementsByTagName("body")[0].style.overflow =
                      "auto";
                  modalSet(false);
                }}
              >
                Cancel
              </button>
            </div>
          )}
          {props && props.outer_2 && (
            <div
              className={`col-md-0 col-lg-1 ${
                props && props.outer_2_class2 ? props.outer_2_class2 : ""
              }`}
            ></div>
          )}
          <div
            className={
              !hideclose
                ? "col-12 col-lg-6 mt-4 mt-lg-0"
                : props && props.outer_2
                ? `col-10 col-lg-8 pb-6 pt-3 ${
                    props && props.outer_2_class2 ? props.outer_2_class2 : ""
                  }`
                : "col-md-6 col-lg-4 column"
            }
          >
            <button
              className="btn btn-primary col-12"
              onClick={
                props.disabled && props.disabledfn
                  ? (e) => disabledfn(e)
                  : addreplacecardNew_
              }
            >
              {props && props.bttxt ? props.bttxt : last4 ? "Update" : "Add"}
            </button>
          </div>
          {hideclose ? (
            props && props.outer_2 ? (
              <div
                className={`col-1 ${
                  props && props.outer_2_class1 ? props.outer_2_class1 : ""
                }`}
              ></div>
            ) : (
              <div className="col-md-6 col-lg-4 column"></div>
            )
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );

  return !hidemodal ? (
    <Commmodal modaltype={"simple"} class_={"z-top"} mobilewide={true}>
      {card}
    </Commmodal>
  ) : (
    <div>{card}</div>
  );
});

export default MyCards;
