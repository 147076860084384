import type { ComponentPropsWithoutRef } from "react";

export const IconClose = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 16 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.21 20.248C14.9287 20.5293 14.4131 20.5293 14.1318 20.248L7.94434 14.0137L1.70996 20.248C1.42871 20.5293 0.913086 20.5293 0.631836 20.248C0.350586 19.9668 0.350586 19.4512 0.631836 19.1699L6.86621 12.9355L0.631836 6.74805C0.350586 6.4668 0.350586 5.95117 0.631836 5.66992C0.913086 5.38867 1.42871 5.38867 1.70996 5.66992L7.94434 11.9043L14.1318 5.66992C14.4131 5.38867 14.9287 5.38867 15.21 5.66992C15.4912 5.95117 15.4912 6.4668 15.21 6.74805L8.97559 12.9355L15.21 19.1699C15.4912 19.4512 15.4912 19.9668 15.21 20.248Z"
      fill="currentColor"
    />
  </svg>
);
