export const LocalErrorDisplay = (error: {
  message?: string;
  stack?: string;
}) => (
  <div style={{ paddingLeft: 10, paddingTop: 10 }}>
    <h1>Unexpected Error! (Local Only)</h1>
    <h6>
      {"In production "}
      <span
        style={{
          backgroundColor: "#f0f0f0",
          color: "red"
        }}
      >
        packages/components/src/ErrorBoundary/ErrorDisplay.tsx
      </span>
      {" is shown"}
    </h6>
    <br />
    <h2>{error.message}</h2>
    <br />
    <p style={{ whiteSpace: "pre-wrap" }}>{error.stack}</p>
  </div>
);
