import { ComponentPropsWithoutRef } from "react";

export const IconCircleChevronDown = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1875 10.1582C17.4688 9.87695 17.9844 9.87695 18.2656 10.1582C18.5469 10.4395 18.5469 10.9551 18.2656 11.2363L13.0156 16.4863C12.875 16.627 12.6875 16.6738 12.5 16.6738C12.2656 16.6738 12.0781 16.627 11.9375 16.4863L6.6875 11.2363C6.40625 10.9551 6.40625 10.4395 6.6875 10.1582C6.96875 9.87695 7.48438 9.87695 7.76562 10.1582L12.5 14.8926L17.1875 10.1582ZM12.5 0.173828C19.1094 0.173828 24.5 5.56445 24.5 12.1738C24.5 18.8301 19.1094 24.1738 12.5 24.1738C5.84375 24.1738 0.5 18.8301 0.5 12.1738C0.5 5.56445 5.84375 0.173828 12.5 0.173828ZM12.5 22.6738C18.2656 22.6738 23 17.9863 23 12.1738C23 6.4082 18.2656 1.67383 12.5 1.67383C6.6875 1.67383 2 6.4082 2 12.1738C2 17.9863 6.6875 22.6738 12.5 22.6738Z"
      fill="currentColor"
    />
  </svg>
);
