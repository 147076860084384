import { PropsWithChildren } from "react";
import { NotificationDot } from "./NotificationDot";
import { NotificationText } from "./NotificationText";

interface NotificationOverlayProps extends PropsWithChildren {
  /**
   * If true, renders a notification dot.
   * If a non-empty string, renders the notification text containing the string.
   * Otherwise, won't render any notification.
   */
  notification?: boolean | string;
}

/** Renders a notification over the top-right corner of it's children. */
export const NotificationOverlay = ({
  children,
  notification
}: NotificationOverlayProps) => (
  <div
    css={{
      position: "relative",
      display: "inherit"
    }}
  >
    {children}
    {notification && (
      <div
        css={{
          position: "absolute",
          top: "-5px",
          right: "-5px"
        }}
      >
        {notification === true && <NotificationDot />}
        {typeof notification === "string" && (
          <NotificationText text={notification} />
        )}
      </div>
    )}
  </div>
);
