import React, { FC, PropsWithChildren, useState } from "react";

import { API, ConcreteAPI } from "../api";
import {
  createApiResourceContext,
  createApiResourceContextProvider
} from "./ApiResourceContext";
import { Conversation } from "@mh/api";

export const ConversationsContext = createApiResourceContext<Conversation[]>({
  isFetching: false,
  data: null,
  response: null,
  error: null,
  get: async () => {},
  update: async () => {}
});

const ContextProvider =
  createApiResourceContextProvider<Conversation[]>(ConversationsContext);

export const ConversationsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [api] = useState<ConcreteAPI>(
    API.v2().url("/message-broker/conversations")
  );
  return <ContextProvider api={api}>{children}</ContextProvider>;
};
