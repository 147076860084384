import React, { useState } from "react";

import { API, ConcreteAPI } from "../api";
import { UserDetail } from "../user";
import {
  createApiResourceContext,
  createApiResourceContextProvider
} from "./ApiResourceContext";

export const UserContext = createApiResourceContext<UserDetail>({
  isFetching: false,
  data: null,
  response: null,
  error: null,
  get: async () => {},
  update: async () => {}
});

const ContextProvider =
  createApiResourceContextProvider<UserDetail>(UserContext);

export const UserContextProvider = ({
  children,
  id
}: {
  children: React.ReactNode;
  id: string | number;
}) => {
  const [api] = useState<ConcreteAPI>(API.v1().url(`/users/${id}`));
  return <ContextProvider api={api}>{children}</ContextProvider>;
};
