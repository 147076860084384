import { PRIVACY_URL, TERMS_OF_USE_URL } from "../constants";

import "./styles.scss";

export const QuestionnaireEULA = () => (
  <p style={{ textAlign: "center", paddingTop: 10 }}>
    By clicking Next you agree to our{" "}
    <a
      className="btn-link"
      href={TERMS_OF_USE_URL}
      target="terms and conditions"
    >
      Terms &amp; Conditions
    </a>
    {", "}
    <a className="btn-link" href={PRIVACY_URL} target="privacy policy">
      Privacy Policy
    </a>
    {", "}
    and confirm that you are over the age of 18.
  </p>
);
