import moment from "moment";
import jQuery from "jquery";

import PRODCAT from "./productcats";
import { User } from "@mh/api";
import { Sentry, BRAND } from "@mh/core";
import {
  checkLocalDayIsSameAsSydney,
  nowIsDuringCallingTime
} from "../../utils";
import { Toast } from "@mh/components";

const DEV = "0";
const STAGING = "1";
const LIVE = "2";

const STATE = import.meta.env.VITE_STATE ? import.meta.env.VITE_STATE : LIVE;
const LOGTYPE = ["DEV", "STAGING", "INFO", "ERROR", "ALWAYS"];

const HOST =
  import.meta.env.VITE_API_URL ??
  (STATE === LIVE
    ? "https://api.midnighthealth.com.au"
    : STATE === STAGING
    ? "https://api.dev.midnighthealth.com.au"
    : "http://localhost:8000");
const API = "api/v1";
const LOGO = import.meta.env.VITE_LOGO
  ? import.meta.env.VITE_LOGO
  : "/assets/images/stagger/Stagger-Logo-Final-REVERSE.png";

const TIMEOUT = 60000;
const FROM = import.meta.env.VITE_TYPE ? import.meta.env.VITE_TYPE : "";
const PREIVEW = !!import.meta.env.VITE_PRODUCTREVIEW_IDENTIFIER;
const ENABLECOUPON = true;
const MINAGE = 18;

const errorPrefix =
  "Sorry, we ran into an error while processing your request. Error: ";

const FETCHEXERR = [
  {
    name: "response_none",
    text: `${errorPrefix}Unexpected end of JSON input.`,
    nosend: true
  },
  {
    name: "response_none",
    text: `${errorPrefix}The string did not match the expected pattern.`,
    nosend: true
  }
];

const MESSAGEMAP = [
  {
    name: "The user aborted a request",
    text: `${errorPrefix} Service not reachable.`,
    nosend: true
  },
  {
    name: "invalid_grant"
  },
  {
    name: "400"
  },
  {
    name: "401",
    text: `${errorPrefix} Session Expired.`
  },
  {
    name: "404",
    text: `${errorPrefix} We couldn't find your request.`
  },
  {
    name: "500",
    text: `${errorPrefix} Error 500.`
  },
  {
    name: "TypeError: Failed to fetch",
    text: `${errorPrefix} Fetch failure.`,
    nosend: true
  }
];

const EXCLUDEERRORTOSENDMAP = [
  {
    status: "401"
  },
  {
    status: 401
  },
  {
    msg: "Authentication credentials were not provided"
  },
  {
    msg: '"digitalid":false'
  },
  {
    msg: "Invalid credentials given"
  },
  {
    msg: "Your card has insufficient funds"
  },
  {
    msg: "Your card's security code is incorrect"
  },
  {
    msg: "expiration month is invalid."
  },
  {
    msg: "security code is incorrect"
  },
  {
    msg: "Your card number is incorrect"
  },
  {
    msg: "Your card was declined"
  },
  {
    msg: "parameter should be an integer"
  }
].map((v) => (v?.msg ? { ...v, msg: `${errorPrefix}${v.msg}` } : v));

async function checkCode(Common, code_, cid_, from = "") {
  const code__ = await Common.Fetch(
    `${Common.Config().HOST}/${
      Common.Config().API
    }/checkcode/?code=${code_}&categoryid=${cid_}&brand=${FROM}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    },
    `${from ? `${from} ->` : ""}MyPromoCode`,
    "_save",
    false,
    false,
    "",
    true,
    true,
    true
  );

  const code = Common.getResult(code__, "checkCode");

  return code.iserror ? false : code;
}

function reload_(e) {
  e.preventDefault();
  document.location.reload(true);
}

/** @typedef {import("@mh/api/src/oscar/types").ProductAvailability} ProductAvailability */

/**
 * Loads a patient plan from a questionnaire
 * @param {any} Base Base class of functoinality
 * @param {number} id ID of a questionnaire whose subscription should be loaded
 * @param {string} from What page this endpoint was called from
 * @param {boolean} noloading Show a loading status
 * @returns {Promise<{
 *   canceled_at: string;
 *   cancellation_reason: string | null;
 *   delivery_method: string | null;
 *   direction: string | null;
 *   display_name: string;
 *   doctor_name: string | null;
 *   latest_doctor_name: string | null;
 *   latest_doctor_prefix: string | null;
 *   doctor_note: string | null;
 *   doctor_review: string | null;
 *   next_refill_date: string | null;
 *   order_on_demand: "Yes" | "No",
 *   order_status: OrderStatus | null;
 *   preferred_months: number;
 *   preferred_price: string;
 *   preferred_quantity: number;
 *   price: number;
 *   product_availability: ProductAvailability | null;
 *   repeats_prescribed: number;
 *   repeats_remain: number;
 *   repeats_used: number;
 *   script_valid_date: string;
 *   shop_product: number;
 *   show_accept: true;
 *   is_error?: boolean;
 * }>} The subscription status based from the given questionnaire
 */
async function getPatientPlan(Base, id, from = null, noloading = false) {
  Base.logger(Base.Config().STATE, `${from}`, "getPatientPlan", "Start");
  const result2_ = await Base.Fetch(
    `${Base.Config().HOST}/${Base.Config().API}/getpatientplan/${id}/`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    },
    `${from}`,
    "getPatientPlan",
    false,
    false,
    null,
    false,
    true,
    true,
    noloading
  );

  const result2 = Base.getResult(result2_, "getpatientplan");

  Base.logger(Base.Config().STATE, `${from}`, "getPatientPlan", "End");
  return result2
    ? result2.getpatientplan
      ? result2.getpatientplan
      : result2
    : "";
}

export default class Common {
  constructor() {
    this.getprodcatinfo = this.getprodcatinfo.bind(this);
    this.checkCondition = this.checkCondition.bind(this);
    this.isActiveRepeat = this.isActiveRepeat.bind(this);
    this.showRedirectMessage = this.showRedirectMessage.bind(this);
    this.getPtDetails = this.getPtDetails.bind(this);
    this.getPtQus = this.getPtQus.bind(this);
    this.getResult = this.getResult.bind(this);
    this.mergeArray = this.mergeArray.bind(this);
    this.setPatientPlan = this.setPatientPlan.bind(this);
    this.getNewProductDetails = this.getNewProductDetails.bind(this);
    this.getAgebyDOB = this.getAgebyDOB.bind(this);
    this.getServerTime = this.getServerTime.bind(this);
    this.setPurchaseGtm = this.setPurchaseGtm.bind(this);
    this.setGtm = this.setGtm.bind(this);
    this.uploadImages = this.uploadImages.bind(this);
    this.checkPromoValidationByType =
      this.checkPromoValidationByType.bind(this);
    this.setPromocode = this.setPromocode.bind(this);
    this.getRawDatafromText = this.getRawDatafromText.bind(this);
    this.getDatafromText = this.getDatafromText.bind(this);
    this.checkUserByEmail = this.checkUserByEmail.bind(this);
    this.scrollTop_ = this.scrollTop_.bind(this);
    this.IsScrollBottom_ = this.IsScrollBottom_.bind(this);
    this.replaceString = this.replaceString.bind(this);
    this.readFileAsync = this.readFileAsync.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.isNumber = this.isNumber.bind(this);
    this.checkNumberRage = this.checkNumberRage.bind(this);
    this.convertENVToArray = this.convertENVToArray.bind(this);
    this.capitalizeFirstLetter = this.capitalizeFirstLetter.bind(this);
    this.setPatientStatus = this.setPatientStatus.bind(this);
    this.Fetch = this.Fetch.bind(this);
    this.Config = this.Config.bind(this);
    this.showCountdown = this.showCountdown.bind(this);
    this.sendDebugEmail = this.sendDebugEmail.bind(this);
    this.logger = this.logger.bind(this);
  }

  getprodcatinfo() {
    const prodcatinfo = [];
    for (let i = 0; i < PRODCAT.length; i++) {
      const procat = PRODCAT[i];
      if (
        procat.brand.includes(this.Config().FROM) &&
        (!procat.stage || procat.stage >= this.Config().STATE)
      ) {
        if (procat.convert) {
          for (let j = 0; j < procat.convert.length; j++) {
            procat[procat.convert[j]] = this.getRawDatafromText(
              procat[procat.convert[j]],
              null,
              this.Config()
            );
          }
        }
        if (
          this.Config().FROM !== "youly" &&
          procat.script &&
          procat.script === "true"
        ) {
          procat.script = "false";
        }
        prodcatinfo.push(procat);
      }
    }
    return prodcatinfo;
  }

  checkCondition(info, cond, newComp, opts = null) {
    if (info.iscond) {
      const { val } = cond;
      const comp = newComp ?? cond?.comp;

      if (info.iscond === "time") {
        if (
          (cond.cond === "eq" &&
            checkLocalDayIsSameAsSydney() &&
            nowIsDuringCallingTime(comp, comp + 1)) ||
          (cond.cond === "lt" &&
            checkLocalDayIsSameAsSydney() &&
            nowIsDuringCallingTime(comp, 24)) ||
          (cond.cond === "gt" &&
            checkLocalDayIsSameAsSydney() &&
            nowIsDuringCallingTime(0, comp))
        ) {
          return true;
        }
      } else {
        if (opts && opts[comp]) {
          if (cond.cond === "not" && opts[comp] !== val) {
            return true;
          } else if (cond.cond === "eq" && opts[comp] === val) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isActiveRepeat(cdate = null, dateFormat_ = null) {
    const dateFormat = dateFormat_ ? dateFormat_ : "DD/MM/YYYY";
    if (cdate && moment(cdate, dateFormat).isValid()) {
      const activedate = moment(cdate, dateFormat);
      if (moment() >= activedate) {
        return true;
      }
    }
    return false;
  }

  showRedirectMessage(
    url,
    msg = null,
    opts = null,
    timeout = 3000,
    redirectToastType = "error"
  ) {
    msg ? Toast.generic(redirectToastType, msg) : "";
    opts?.logout ? opts.logout() : "";

    setTimeout(() => {
      window.location.href = url;
    }, timeout);
  }

  async getPtDetails(from = null, noloading = false) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}Common`,
      "getPtDetails",
      "Start"
    );

    const result_ = await this.Fetch(
      `${this.Config().HOST}/${
        this.Config().API
      }/patientProfiledetails/?brand=${this.capitalizeFirstLetter(
        this.Config().FROM
      )}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        timeout: this.Config().TIMEOUT
      },
      `${from ? `${from} ->` : ""}Common`,
      "getPtDetails",
      false,
      false,
      null,
      false,
      true,
      false,
      noloading
    );

    const result = this.getResult(result_, "patientProfiledetails");

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getPtDetails",
      "End"
    );
    return result;
  }

  async getPtQus(from = null, noloading = false) {
    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}Common`,
      "getPtQus",
      "Start"
    );

    const result_ = await this.Fetch(
      `${this.Config().HOST}/${
        this.Config().API
      }/patientquestionnaires?brand=${this.capitalizeFirstLetter(
        this.Config().FROM
      )}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        })
      },
      `${from}->Common`,
      "getPtQus",
      false,
      false,
      null,
      false,
      true,
      false,
      noloading
    );

    const result = this.getResult(result_, "patientquestionnaire");

    this.logger(
      this.Config().STATE,
      `${from ? `${from} ->` : ""}basequestionnaire`,
      "getPtQus",
      "End"
    );
    return result;
  }

  /**
   *
   * @param {object} result
   * @param {string} name
   * @param {boolean} isobject
   * @param {boolean} iserrorbypass
   * @returns {any}
   */
  getResult(result, name = null, isobject = false, iserrorbypass = false) {
    if (!result) {
      return false;
    } else if (!iserrorbypass && (result.iserror || result.notoken)) {
      return result;
    } else if (
      name &&
      (!result ||
        !result[name] ||
        typeof result !== "object" ||
        (typeof result === "object" && !result.hasOwnProperty(name)) ||
        (isobject && typeof result[name] !== "object"))
    ) {
      if (
        !iserrorbypass &&
        !isobject &&
        result &&
        typeof result === "object" &&
        result.hasOwnProperty(name)
      ) {
        return result;
      }

      const newresult = {};

      if (result && result.results) {
        newresult[name] = result.results;
      } else {
        newresult[name] = result;
      }

      if (result && result.count) {
        newresult.meta = {};
        newresult.meta.count = result.count;
      }
      return newresult;
    }
    return result;
  }

  mergeArray(arr1, arr2, index) {
    const arr3 = [...arr1];
    arr3.splice(index, 0, ...arr2);
    return arr3;
  }

  async setPatientPlan(patientquestionnaire, from = null, noloading = false) {
    this.logger(
      this.Config().STATE,
      `${from}->Common`,
      "setPatientPlan",
      "Start"
    );
    if (!patientquestionnaire || !patientquestionnaire.id) return false;

    const patientplan = await getPatientPlan(
      this,
      patientquestionnaire.id,
      `${from}->Common->setPatientPlan`,
      noloading
    );
    const result = patientquestionnaire;
    if (
      patientplan &&
      !patientplan.iserror &&
      patientplan &&
      Object.keys(patientplan).length > 0 &&
      (patientquestionnaire?.product !== "Renew-A-Script" ||
        (patientquestionnaire?.product === "Renew-A-Script" &&
          patientplan.hasOwnProperty("canceled_at")))
    ) {
      const patientplan_ = patientplan;
      if (patientplan.brand_pill) {
        result.brand_pill = patientplan_.brand_pill;
      }
      result.image_request = patientplan_.image_request
        ? patientplan_.image_request
        : false;
      result.image_request_instructions =
        patientplan_.image_request_instructions
          ? patientplan_.image_request_instructions
          : false;
      result.has_images = patientplan_.has_images
        ? patientplan_.has_images
        : false;
      result.existplan = true;
      result.display_name = patientplan_.display_name;
      result.faq = patientplan_.faq;
      result.delivery_method = patientplan_.delivery_method;
      result.doctor_note = patientplan_.doctor_note;
      result.repeats_remain = patientplan_.repeats_remain
        ? patientplan_.repeats_remain
        : 0;
      result.doctor_review = patientplan_.doctor_review
        ? patientplan_.doctor_review
        : "";
      result.quantity = patientplan_.quantity ? patientplan_.quantity : 0;
      result.script_valid_date = patientplan_.script_valid_date
        ? patientplan_.script_valid_date
        : false;
      result.next_refill_date = patientplan_.next_refill_date
        ? patientplan_.next_refill_date
        : false;
      if (result.next_refill_date) {
        const validdate = moment(result.next_refill_date);
        if (!validdate.isValid()) {
          result.disable_next_refill_date = true;
        }
      }
      result.order_on_demand = patientplan_.order_on_demand
        ? patientplan_.order_on_demand
        : "No";
      result.repeats_used = patientplan_.repeats_used
        ? patientplan_.repeats_used
        : 0;
      result.repeats_prescribed = patientplan_.repeats_prescribed
        ? patientplan_.repeats_prescribed
        : 0;
      result.preferred_months = patientplan_.preferred_months
        ? patientplan_.preferred_months
        : 0;
      result.preferred_price = patientplan_.preferred_price
        ? patientplan_.preferred_price
        : 0;
      result.preferred_quantity = patientplan_.preferred_quantity
        ? patientplan_.preferred_quantity
        : 0;
      result.status = patientplan_.status ? patientplan_.status : -1;
      result.escript_token = patientplan_.escript_token
        ? patientplan_.escript_token
        : false;
      result.show_accept = patientplan_.show_accept
        ? patientplan_.show_accept
        : false;
      result.canceled_at = patientplan_?.canceled_at;
      result.cancellation_reason = patientplan_?.cancellation_reason;
      result.repeatexist = false;
      if (patientplan_.repeat_doctor_status) result.doctor_status = "approve";
      result.doctor_status_rep = false;
      if (patientplan_.last_repeat_questionnaire) {
        result.repeatexist = true;
        result.active_date =
          patientplan_.last_repeat_questionnaire?.active_date;
        if (patientplan_.last_repeat_questionnaire.doctor_status) {
          result.doctor_status =
            patientplan_.last_repeat_questionnaire.doctor_status;
          result.doctor_status_rep =
            patientplan_.last_repeat_questionnaire.doctor_status;
          result.doctor_dt = patientplan_.last_repeat_questionnaire.doctor_dt;
        }
        result.repeatQuestionnaireId =
          patientplan_.last_repeat_questionnaire.id;
        result.fulfilledImageRequirementRepeat =
          patientplan_.last_repeat_questionnaire.fulfilled_image_requirements;
      }
      // The ID of the subscription associated with this initial questionnaire, if one exists
      result.subscription_id = patientplan_.subscription_id;
      result.latest_doctor_name = patientplan_.latest_doctor_name;
      result.latest_doctor_prefix = patientplan_.latest_doctor_prefix;
      result.latest_doctor_profile_image_url =
        patientplan_.latest_doctor_profile_image_url;
      result.initial_status_decline = patientplan_.initial_status_decline;
      if (patientplan_.repeat_doctor_status)
        result.doctor_status = patientplan_.repeat_doctor_status;
      result.repeat_status_decline = patientplan_.repeat_status_decline;
    } else {
      result.nopatientplan = true;
    }
    // Both doctor and pharmacy subscriptions can have order_status
    result.order_status = patientplan.order_status;
    result.product_availability = patientplan.product_availability ?? null;

    this.logger(this.Config().STATE, `${from}->Common`, "setPatientPlan", {
      result
    });
    this.logger(
      this.Config().STATE,
      `${from}->Common`,
      "setPatientPlan",
      "Start"
    );

    return result;
  }

  async getNewProductDetails(from = null, noloading = false) {
    this.logger(
      this.Config().STATE,
      `${from}->Common`,
      "getNewProductDetails",
      "Start"
    );

    const result = await this.getPtQus(from, noloading);

    if (!result) {
      return false;
    } else if (result && result.iserror) {
      return result;
    }

    const newresult = {};
    const category = this.getprodcatinfo();
    for (let i = 0; i < result.patientquestionnaire.length; i++) {
      if (
        result.patientquestionnaire[i].product &&
        category.findIndex(
          (cat) =>
            cat.name.toLowerCase() ===
            result.patientquestionnaire[i].product.toLowerCase()
        ) > -1
      ) {
        if (!newresult.patientquestionnaire) {
          newresult.patientquestionnaire = [];
        }
        newresult.patientquestionnaire.push(result.patientquestionnaire[i]);
      }
    }
    if (
      newresult &&
      newresult.patientquestionnaire &&
      Array.isArray(newresult.patientquestionnaire) &&
      newresult.patientquestionnaire.length > 0
    ) {
      for (let i = 0; i < newresult.patientquestionnaire.length; i++) {
        newresult.patientquestionnaire[i] = await this.setPatientPlan(
          newresult.patientquestionnaire[i],
          `${from}->Common->getNewProductDetails`,
          noloading
        );
      }
    }

    this.logger(
      this.Config().STATE,
      `${from}->Common`,
      "getNewProductDetails",
      "End"
    );
    return newresult;
  }

  getAgebyDOB(dob) {
    const age = moment().diff(dob, "years", false);
    return age;
  }

  async getServerTime(type = null, from = null) {
    const result = await this.Fetch(
      `${this.Config().HOST}/${this.Config().API}/getservertime/`,
      {
        method: "GET"
      },
      `${from}=>common`,
      "getServerTime",
      false,
      false,
      null,
      false,
      true
    );

    if (!result || (result.success && !result.time)) {
      return false;
    }
    if (type === "time") {
      return moment(result.time).format("HH:mm");
    }
    return moment(result.time).format("YYYY-MM-DD HH:mm");
  }

  setPurchaseGtm(info) {
    if (import.meta.env.VITE_GID) {
      const products = this.getprodcatinfo();
      const productenv = products.find(
        (prod) =>
          prod.code.toLowerCase() === info.product.toLowerCase() ||
          prod.name.toLowerCase() === info.product.toLowerCase()
      );
      const perprice = info.productprice
        ? info.productprice
        : productenv.hidePayment && productenv.hidePayment === "yes"
        ? 0
        : info.questionnaireobj.price
        ? info.questionnaireobj.price
        : 0;
      const productname = `${
        info.product ? info.product : info.questionnaireobj.product
      }${
        productenv?.scriptonly
          ? info.script_only
            ? ": Script Only"
            : ": Subscription"
          : ""
      }`;

      const gtmobj = {
        event: "purchase",
        purchaseid: `${
          info.questionnaireId
            ? info.questionnaireId
            : info.questionnaireobj && info.questionnaireobj.id
            ? info.questionnaireobj.id
            : ""
        }_${new Date().valueOf()}`,
        scriptType: productenv.once
          ? "Non-Prescription Treatments"
          : "Prescription Treatments",
        deliveryOption: info.deliveryoption
          ? info.deliveryoption
          : "Free Delivery", // check info.questionnaireobj.delivery_method
        purchaseType: info.purchaseType,
        userType: info.userType,
        patientId: info.patientid,
        questionnaireId: info.questionnaireId
          ? info.questionnaireId
          : info.questionnaireobj && info.questionnaireobj.id
          ? info.questionnaireobj.id
          : "",
        repeatsRemaining: info.repeatsremain ? info.repeatsremain : 0,
        email: info.email,
        fn: info.firstname,
        ln: info.lastname,
        ph: info.ph,
        db: info.dob,
        ciry: info.suburb,
        productname,
        productcode: info.productcode
          ? info.productcode
          : info.questionnaireobj.product
          ? info.questionnaireobj.product.toLowerCase()
          : "",
        price: perprice,
        dimension6: info.dimension6
          ? info.dimension6
          : info.questionnaireobj.brand_pill
          ? info.questionnaireobj.brand_pill
          : "",
        dimension7: "", // Medication MIMS Package ID
        qty: info.quantity,
        total: info.total,
        tax: (parseFloat(info.total) / 11).toFixed(2),
        shipping: info.shipping ? info.shipping : 0,
        promo: info.promo ? info.promo : "",
        once: !!productenv.once
      };
      this.setGtm("purchase", gtmobj);
    }
  }

  setGtm(type, info, from = null) {
    this.logger(
      this.Config().STATE,
      `${from}->Common`,
      `setGtm Start: ${type}`,
      { info }
    );
    const gtmobj = {};
    gtmobj.event = info.event;
    if (type === "viewPaymentPage" || type === "viewTreatmentPlan") {
      gtmobj.questionnaireProduct = info.product;
      if (info.medicationName) {
        gtmobj.medicationName = info.medicationName;
      }
      if (info.priceDisplayed) {
        gtmobj.priceDisplayed = info.priceDisplayed;
      }
      if (info.event === "viewTreatmentPlan") {
        gtmobj.treatmentPlanPage = info.treatmentPlanPage;
        gtmobj.questionnaireId = info.id;
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ...gtmobj });
      this.logger(this.Config().STATE, `${from}->Common`, "setGtm end");
      return true;
    }
    gtmobj.userType = info.userType;
    if (type !== "qstartrepeat")
      gtmobj.prospectId = info.prospectId ? info.prospectId : "";
    if (info.patientId) {
      gtmobj.patientId = info.patientId;
    }
    if (gtmobj.event === "ecommerce" || gtmobj.event === "purchase") {
      gtmobj.advancedMatch = {
        em: info.email,
        fn: info.fn, // First name - lowercase
        ln: info.ln, // Last name - lowercase
        ph: info.ph, // Phone - digits only including country code
        db: info.db, // Date of Birth - digits only, YYYYMMDD
        ct: info.city, // City - lowercase with no spaces
        country: "au" // Country - lowercase two-letter country code
      };
      gtmobj.ecommerce = {
        currencyCode: "AUD"
      };
      if (gtmobj.event === "ecommerce") {
        gtmobj.ecommerceEvent = info.eventfrom;
      }
      gtmobj.scriptType = info.uploadscript
        ? info.uploadscript
        : info.once
        ? "Non-Prescription Treatments"
        : "Prescription Treatments";

      const products = [
        {
          name: info.productname ? info.productname : "",
          id: info.productcode ? info.productcode : "",
          price: info.price ? info.price : 0,
          brand: this.Config().FROM,
          category: info.once
            ? "Non-Prescription Treatments"
            : "Prescription Treatments", // 'Prescription Treatments' or 'Non-Prescription Treatments'
          quantity: info.qty ? info.qty : 1
        }
      ];

      if (type === "qstart") {
        gtmobj.ecommerce.add = {
          products
        };
      } else if (type === "qcomp") {
        gtmobj.questionnaireId = info.questionnaireId;
        const actionField = {
          step: info.step ? info.step : 1,
          option: info.paymentoption ? info.paymentoption : ""
        };
        gtmobj.ecommerce.checkout = {
          products,
          actionField
        };
      } else if (type === "purchase") {
        products[0].dimension6 = info.dimension6;
        products[0].dimension7 = info.dimension7;
        gtmobj.questionnaireId = info.questionnaireId;
        gtmobj.deliveryOption = info.deliveryOption;
        gtmobj.purchaseType = info.purchaseType;
        gtmobj.repeatsRemaining = info.repeatsRemaining;
        const actionField = {
          id: info.purchaseid, // Purchase ID
          affiliation: "Portal Purchase",
          revenue: info.total, // Total Revenue (inc. tax)
          tax: info.tax, // Total tax paid
          shipping: info.shipping,
          coupon: info.promo // Coupons used
        };
        gtmobj.ecommerce.purchase = {
          products,
          actionField
        };
      } else {
        gtmobj.ecommerce.products = products;
      }
    }

    if (type === "qproc") {
      gtmobj.scriptType = info.once
        ? "Non-Prescription Treatments"
        : "Prescription Treatments"; // 'Prescription Treatments', 'Non-Prescription Treatments' or 'Prescription Uploaded' (where info available)
      gtmobj.questionnaireProduct = info.productname; // Product name
      gtmobj.questionId = info.questionId; // Current question GA ID
      gtmobj.questionTitle = info.questionTitle; // Question title field
      gtmobj.questionType = info.questionType;
    } else if (type === "qbuy") {
      console.log("Buy");
    } else if (type === "qevent") {
      console.log("Event");
    } else if (type === "qdisq") {
      gtmobj.disqualificationReason = info.disqualificationReason;
      gtmobj.scriptType = info.scriptType;
      gtmobj.questionnaireProduct = info.productname;
      gtmobj.questionId = info.questionId;
      gtmobj.questionTitle = info.questionTitle;
      gtmobj.questionType = info.questionType;
    } else if (type === "qstartrepeat") {
      gtmobj.questionnaire = info.questionnaire;
      gtmobj.questionnaireVersion = info.questionnaireVersion;
      gtmobj.initialquestionnaireId = info.initialquestionnaireId;
      gtmobj.patientId = info.patientId;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ...gtmobj });
    // console.log(gtmobj);
    this.logger(this.Config().STATE, `${from}->Common`, "setGtm end");
  }

  /**
   * Uploads images for a treatment
   *
   * @param {number} pid The patient's ID
   * @param {number} qid The questionanire's ID
   * @param {number} categoryId The ID of the category this image is to be associated with
   * @param {Record<number, [File, CategoryImageRequirement]>} images The images to be uploaded
   * @param {boolean} customRequest if the image reuqest is from a clinican
   * @param {string} from Where this method was called from
   * @returns {set<number>} a record where for each entry an upload error occured
   */
  async uploadImages(pid, qid, images, customRequest, from = "") {
    // The api returns the category_image_requirement on successful sends
    // so we want to remove the successful sends from the set, which will
    // result in the failures remaining in the set
    const imagesErrors = new Set();

    const uploads = Object.entries(images).map(([_, [file, requirement]]) => {
      imagesErrors.add(requirement.id);

      const formData = new FormData();
      formData.append("questionnaire", qid);
      formData.append("name", requirement.name);
      formData.append("image", file);
      if (!customRequest) {
        formData.append("category_image_requirement", requirement.id);
      }

      return this.Fetch(
        `${this.Config().HOST}/${this.Config().API}/patients/${pid}/images`,
        { method: "POST", body: formData },
        `${from}=>common`,
        "uploadImages",
        false,
        false,
        null,
        false,
        true
      );
    });

    await (
      await Promise.all(uploads)
    ).forEach((asyncResult) => {
      const r = this.getResult(asyncResult, "patient", "object");
      if (!r.iserror) {
        if (customRequest) {
          imagesErrors.delete(0);
        } else {
          imagesErrors.delete(r.patient.category_image_requirement);
        }
      }
    });

    return imagesErrors;
  }

  async checkPromoValidationByType(inputcode, prodcode, opt = null) {
    let vaildcode = false;
    let message = "";
    let isshowmsg = false;
    let exist = false;
    let discountprice = 0;

    const code = await checkCode(this, inputcode, "");
    if (code) {
      if (code && code.checkCode && code.checkCode.status) {
        let codes = code.checkCode.promo_code_details;
        codes = codes ? (!Array.isArray(codes) ? [codes] : codes) : "";
        const products = this.getprodcatinfo();
        const prodenv = products.find((prod) => prod.CATID === prodcode);
        let prodonce = false;
        if (prodenv && prodenv.once) {
          prodonce = true;
        }
        if (codes && codes.findIndex((code) => code.code === inputcode) > -1) {
          for (let i = 0; i < codes.length; i++) {
            if (codes[i].code === inputcode) {
              if (
                codes[i].exclude &&
                codes[i].exclude.findIndex((excl_) => excl_ === prodcode) > -1
              ) {
                isshowmsg = true;
                message = "This code is not applicable to this product.";
              } else if (
                (codes[i].apply_to === "product" &&
                  opt.from === "questionnaire" &&
                  !prodonce) ||
                (codes[i].apply_to === "consult" &&
                  (prodonce || opt.from !== "questionnaire"))
              ) {
                isshowmsg = true;
                message = "This code is not applicable here";
              } else if (
                codes[i].start_date &&
                moment(codes[i].start_date).isValid() &&
                moment(codes[i].start_date).isAfter(
                  moment().format("YYYY-MM-DD")
                )
              ) {
                isshowmsg = true;
                message = "This code is invalid.";
              } else if (
                codes[i].expired &&
                moment(codes[i].expired).isValid() &&
                moment(codes[i].expired).isSameOrBefore(
                  moment().format("YYYY-MM-DD")
                )
              ) {
                isshowmsg = true;
                message = "This code is expired.";
              } else {
                exist = true;
                let msg = "";
                if (codes[i].type === "event") {
                  msg = codes[i].msg;
                } else {
                  msg = "Applied: AU -$";
                  codes[i].discountprice = parseFloat(
                    codes[i].discount
                  ).toFixed(2);
                  if (codes[i].type === "fixed") {
                    msg += `${codes[i].discountprice.toString()} ${
                      codes[i].msg
                    }`;
                    discountprice = codes[i].discountprice;
                  } else if (codes[i].type === "percent") {
                    if (!opt || !opt.price) {
                      msg += "No Price exist.";
                    } else {
                      codes[i].discountprice = (
                        (parseFloat(opt.price) *
                          parseFloat(codes[i].discount)) /
                        100
                      ).toFixed(2);
                      msg += `${codes[i].discountprice.toString()}. ${
                        codes[i].msg
                      }`;
                      discountprice = codes[i].discountprice;
                    }
                  }
                }
                if (
                  codes[i].custommsg &&
                  codes[i].custommsg.findIndex(
                    (list__) => list__.code === prodcode
                  ) > -1
                ) {
                  codes[i].custommsg.map((list__) => {
                    if (list__.code === prodcode) {
                      msg = list__.msg;
                    }
                  });
                }
                isshowmsg = true;
                message = msg;
                vaildcode = codes[i];
              }
              break;
            }
          }
        }
      } else {
        isshowmsg = false;
        exist = false;
        vaildcode = false;
        discountprice = 0;
      }
    } else {
      isshowmsg = false;
      exist = false;
      vaildcode = false;
      discountprice = 0;
    }
    return { exist, isshowmsg, message, vaildcode, discountprice };
  }

  /**
   *
   * @param {any} promo
   * @param {any} from
   * @param {any} prodcode
   * @param {any} setIsEventPromo
   * @param {any} price
   * @returns
   */
  async setPromocode(
    promo,
    from = null,
    prodcode = null,
    setIsEventPromo = null,
    price = ""
  ) {
    this.logger(
      this.Config().STATE,
      `${from}`,
      "setPromocode",
      `Start ${
        promo && promo.voucherpair && promo.voucherpair.voucher
          ? promo.voucherpair.voucher
          : ""
      }`
    );
    let setpromo = false;
    if (
      promo.voucherpair &&
      promo.voucherpair.setVoucher &&
      promo.voucherpair.voucher &&
      (!promo.voucherpair.vouchervalue ||
        promo.voucherpair.vouchervalue !== promo.voucherpair.voucher)
    ) {
      promo.voucherpair.setVoucher(promo.voucherpair.voucher);
      setpromo = true;
    }
    if (
      promo.voucherpair &&
      promo.voucherpair &&
      promo.voucherpair.voucher &&
      !promo.voucherpair.voucherfromurl
    ) {
      promo.voucherpair.setVoucherfromUrl(promo.voucherpair.voucher);
      setpromo = true;
    }
    if (setIsEventPromo && promo.voucherpair.voucher) {
      const checkcode = await this.checkPromoValidationByType(
        promo.voucherpair.voucher,
        prodcode,
        { setIsEventPromo, price }
      );
      if (checkcode && checkcode.vaildcode) {
        setpromo = true;
        if (checkcode.vaildcode.type === "event") {
          setIsEventPromo(true);
        }
      }
    }

    this.logger(
      this.Config().STATE,
      `${from}`,
      "setPromocode",
      `End ${setpromo ? promo.voucherpair.voucher : ""}`
    );
  }

  getRawDatafromText(
    content,
    fn = null,
    fields = null,
    class_ = null,
    opts = null
  ) {
    const re = /\{(.*?)\}/g;
    let raw = "";
    if (re.test(content)) {
      const regx = /\{(.*?)\}/g;
      const newcontent = "";
      let match = "";
      const arr = [];
      do {
        match = regx.exec(content);
        if (match) {
          arr.push(JSON.parse(`{${match[1]}}`));
        }
      } while (match);

      content
        .replace(regx, "|||")
        .split("|||")
        .map((txt, index) => {
          raw += txt;
          if (index < arr.length) {
            if (arr[index].type === "link") {
              opts && opts.isfield ? (raw += arr[index].text) : "";
            } else if (arr[index].type === "text") {
              arr[index].field === "yes"
                ? (raw += fields[arr[index].data])
                : (raw += arr[index].data);
            } else if (arr[index].type === "date") {
              arr[index].day === "0"
                ? (raw += moment().format(arr[index].format))
                : (raw += moment()
                    .add(parseFloat(arr[index].day), "days")
                    .format(arr[index].format));
            } else if (arr[index].type === "cond") {
              arr[index].cond === "eq"
                ? fields[arr[index].field]
                  ? (raw += arr[index].ok)
                  : (raw += arr[index].nok)
                : arr[index].cond === "comp"
                ? fields[arr[index].field] === arr[index].answer
                  ? (raw += arr[index].ok)
                  : (raw += arr[index].nok)
                : "";
            } else {
              raw += arr[index].text;
            }
          }
        });
      return raw;
    }
    return content;
  }

  getDatafromText(
    content,
    fn = null,
    fields = null,
    class_ = null,
    opts = null
  ) {
    const re = /\{(.*?)\}/g;
    if (re.test(content)) {
      const regx = /\{(.*?)\}/g;
      let newcontent = "";
      let match = "";
      const arr = [];
      do {
        match = regx.exec(content);
        if (match) {
          arr.push(JSON.parse(`{${match[1]}}`));
        }
      } while (match);

      newcontent = content
        .replace(regx, "|||")
        .split("|||")
        .map((txt, index) => {
          return (
            <span
              key={index}
              className={
                class_
                  ? `${class_}${opts && opts.classsuffix ? `_${index}` : ""}`
                  : ""
              }
            >
              {txt}
              {index < arr.length ? (
                arr[index].type === "link" ? (
                  <a
                    className={`${
                      opts && opts.linkclass_ ? opts.linkclass_ : "btn-link"
                    }`}
                    href={`${opts && opts.prefixlink ? opts.prefixlink : ""}${
                      opts && opts.isfield
                        ? fields[arr[index].data]
                        : arr[index].data === "replace"
                        ? opts.replace
                        : arr[index].data
                    }`}
                    target={opts && opts.target ? opts.target : "_self"}
                  >
                    {arr[index].text}
                  </a>
                ) : arr[index].type === "text" ? (
                  arr[index].field === "yes" ? (
                    fields[arr[index].data]
                  ) : (
                    arr[index].data
                  )
                ) : arr[index].type === "date" ? (
                  arr[index].day === "0" ? (
                    moment().format(arr[index].format)
                  ) : (
                    moment()
                      .add(parseFloat(arr[index].day), "days")
                      .format(arr[index].format)
                  )
                ) : arr[index].type === "cond" ? (
                  arr[index].cond === "eq" ? (
                    fields[arr[index].field] ? (
                      arr[index].ok
                    ) : (
                      arr[index].nok
                    )
                  ) : arr[index].cond === "comp" ? (
                    fields[arr[index].field] === arr[index].answer ? (
                      arr[index].ok
                    ) : (
                      arr[index].nok
                    )
                  ) : (
                    ""
                  )
                ) : (
                  <a
                    className="btn-link"
                    onClick={(e) => fn(e, arr[index].data)}
                  >
                    {arr[index].text}
                  </a>
                )
              ) : (
                ""
              )}
            </span>
          );
        });
      return newcontent;
    }
    return content;
  }

  async checkUserByEmail(email, from = "", noloading = false) {
    const userexist_ = await this.Fetch(
      `${this.Config().HOST}/${
        this.Config().API
      }/authenticateuser?email=${encodeURIComponent(email)}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json"
        })
      },
      "questionnaire",
      "checkUserByEmail",
      false,
      false,
      email,
      false,
      false,
      false,
      noloading
    );

    const userexist = this.getResult(userexist_, "authenticateUser");

    if (
      userexist &&
      userexist.authenticateUser &&
      (userexist.authenticateUser.exists || userexist.authenticateUser.redirect)
    ) {
      return userexist;
    } else if (!userexist || userexist.iserror) {
      return false;
    }
    return true;
  }

  scrollTop_() {
    setTimeout(() => {
      jQuery(window).scrollTop(0);
      if (
        (!jQuery("#img_upload_outer") ||
          jQuery("#img_upload_outer").length === 0) &&
        jQuery(".single-upload-box") &&
        jQuery(".single-upload-box").length > 0
      ) {
        jQuery(".single-upload-box").remove();
      }
    }, 100);
  }

  IsScrollBottom_(e) {
    const elem = jQuery(e.currentTarget);
    if (!elem || elem.length <= 0 || !elem[0].scrollHeight) return false;
    if (elem[0].scrollHeight - elem.scrollTop() - 150 <= elem.outerHeight()) {
      jQuery("#stickyblock").hide();
    } else {
      jQuery("#stickyblock").show();
    }
  }

  replaceString(origin, COMMTXT, opts) {
    let result = "";
    if (
      COMMTXT &&
      COMMTXT.replacestring &&
      Array.isArray(COMMTXT.replacestring) &&
      COMMTXT.replacestring.length > 0
    ) {
      const { replacestring } = COMMTXT;
      for (let i = 0; i < replacestring.length; i++) {
        if (
          origin.includes(replacestring[i].name) &&
          opts[replacestring[i].varname]
        ) {
          try {
            let replaced = opts[replacestring[i].varname];

            if (replacestring[i].key) {
              // If the supplied item was an object and we want to read a key from it, read the key
              replaced = replaced[replacestring[i].key];
            }

            result = origin.replaceAll(replacestring[i].name, replaced);
          } catch (e) {
            if (e instanceof TypeError) {
              result = origin.replace(
                new RegExp(`${replacestring[i].name}`),
                opts[replacestring[i].varname]
              );
            }
          }
          break;
        }
      }
    }
    return result;
  }

  readFileAsync(_data, name = "") {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(_data);
    });
  }

  isValidDate(
    date_,
    range = false,
    type = false,
    add = false,
    duration__ = null,
    opts = null
  ) {
    if (date_.length !== 10) {
      return false;
    }

    if (!moment(date_, "DD/MM/YYYY").isValid()) {
      return false;
    }

    const duration_ = opts?.isFuture
      ? moment(date_, "DD/MM/YYYY").diff(
          moment(),
          duration__ ? duration__ : "years"
        )
      : moment().diff(
          moment(date_, "DD/MM/YYYY"),
          duration__ ? duration__ : "years"
        );

    if (type === "dob") {
      if (moment(date_, "DD/MM/YYYY").isBefore("1900-01-01")) {
        return {
          success: "no",
          message: "Date of birth must be after 01/01/1900"
        };
      }
      if (
        add &&
        add.productenv &&
        ((add.underAgeQuestion && add.productenv.age) ||
          add.productenv.minAge ||
          add.productenv.maxAge)
      ) {
        const minAge =
          add.underAgeQuestion && add.productenv.age
            ? add.productenv.age
            : add.productenv.minAge ?? 0;
        if (
          (minAge > 0 && minAge > duration_) ||
          parseInt(add.productenv?.maxAge) < duration_
        ) {
          return {
            success: "no",
            message:
              parseInt(add.productenv?.maxAge) < duration_
                ? "Sorry, this service is not suitable for people over the age of 65 as there may be other factors impacting your condition that need to be assessed. We recommend seeing a doctor in person to complete any necessary tests and prescribe treatment."
                : `Sorry, you need to be ${add.productenv.age} or over to use this service.`,
            max: parseInt(add.productenv?.maxAge) < duration_,
            min: minAge > 0 && minAge > duration_
          };
        }
      }

      if (
        (!add.underAgeQuestion || !add.productenv.age) &&
        this.Config().MINAGE > duration_
      ) {
        return {
          success: "no",
          message: `Sorry, you need to be ${
            this.Config().MINAGE
          } or over to use this service.`
        };
      }
      return true;
    }

    if (range && (duration_ < range.min || duration_ > range.max)) {
      if (type === "date") {
        if (duration_ > range.max) {
          return {
            success: "no",
            message:
              "The date provided cannot be more than 10 days from the current date."
          };
        }
        return {
          success: "no",
          message: "Please enter a date in the past or today."
        };
      }
      return false;
    }
    return true;
  }

  isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  checkNumberRage(value, range = false) {
    if (!this.isNumber(value)) {
      return false;
    }
    if (!range) {
      return true;
    }
    return !(value < range.min || value > range.max);
  }

  capitalizeFirstLetter(value, isFrist = false) {
    let result = "";
    const words = value.split(" ");
    if (!isFrist && Array.isArray(words) && words.length > 0) {
      for (let i = 0; i < words.length; i++) {
        result +=
          words[i] && words[i].length > 0
            ? `${words[i].charAt(0).toUpperCase() + words[i].slice(1)} `
            : "";
      }
    } else {
      result =
        value && value.length > 0
          ? value.charAt(0).toUpperCase() + value.slice(1)
          : "";
    }
    return result;
  }

  convertENVToArray(envname) {
    const result = envname
      ? envname.split(";").map((ele) => {
          return JSON.parse(ele);
        })
      : false;
    return result;
  }

  /**
   *
   * @param {{
   *   id: number;
   *   canceled_at: string;
   *   cancellation_reason: string | null;
   *   delivery_method: string | null;
   *   direction: string | null;
   *   display_name: string;
   *   doctor_name: string | null;
   *   doctor_note: string | null;
   *   doctor_review: string | null;
   *   next_refill_date: string | null;
   *   order_on_demand: "Yes" | "No",
   *   order_status: OrderStatus | null;
   *   preferred_months: number;
   *   preferred_price: string;
   *   preferred_quantity: number;
   *   price: number;
   *   product: string;
   *   repeats_prescribed: number;
   *   repeats_remain: number;
   *   repeats_used: number;
   *   script_valid_date: string;
   *   shop_product: number;
   *   show_accept: true;
   *   transaction_data: object | null | undefined;
   * }} info
   * @returns {{
   *   status: string;
   *   isdoctor: boolean;
   *   ACTIONLIST: { [key: string]: number };
   *   action: number;
   * }} Status of a treatment
   */
  setPatientStatus(info) {
    const ACTIONLIST = {
      NONCURRENT: -1,
      UPLOAD: 0,
      VERIFYING: 10,
      VERIFIED: 20,
      PURCHASE: 30,
      PAYMENT_PENDING: 34,
      PAYMENT_FAILED: 35,
      PAYMENT_SUCCEEDED: 36,
      WAITING: 37,
      NEW_SCRIPT_REQUIRED: 38,
      DISPATCH: 40,
      ORDER_CANCELLED: 45,
      REPEAT: 50,
      REPEATREVIEW: 60,
      DECLINE: 100,
      CANCEL: 110,
      IGNORE: 150
    };
    let status = "";
    let isdoctor = false;
    let action = -100;
    let renewal = false;

    if (info.status && info.status.length > 1) {
      return {
        status: info.status,
        isdoctor,
        ACTIONLIST,
        action: ACTIONLIST.IGNORE
      };
    }

    // Non current
    if (info.non_current) {
      action = ACTIONLIST.NONCURRENT;
    } else if (
      !info.initial_status_decline &&
      (info.repeat_status_decline ||
        (!info.repeat_status_decline && info?.doctor_status === "decline") ||
        (info.canceled_at &&
          info.cancellation_reason &&
          !info.cancellation_reason?.trim()?.includes("Declined by doctor")))
    ) {
      if (
        info.canceled_at &&
        info.cancellation_reason &&
        !info.cancellation_reason.includes("Declined")
      ) {
        status = "cancel";
        isdoctor = true;
        action = ACTIONLIST.CANCEL;
      } else {
        status = "decline";
        isdoctor = true;
        action = ACTIONLIST.DECLINE;
      }
    }
    // repeat review
    else if (
      info.repeatexist &&
      info.active_date &&
      this.isActiveRepeat(info.active_date) &&
      info.doctor_review &&
      info.doctor_review.length > 2 &&
      info.doctor_review.toLowerCase() === "yes" &&
      (!info.doctor_status_rep ||
        info.doctor_status_rep !== "approve" ||
        info.doctor_status_rep !== "decline")
    ) {
      status = "repeatreview";
      isdoctor = true;
      action = ACTIONLIST.REPEATREVIEW;
    }
    // repeat
    // New: For fertility, have to by pass (not need repeat status but just start new questionnaire when repeat remain is 0)
    else if (
      info.repeatexist &&
      info.active_date &&
      this.isActiveRepeat(info.active_date) &&
      (!info.doctor_review ||
        info.doctor_review.length < 2 ||
        info.doctor_review.toLowerCase() !== "yes") &&
      (!info.doctor_status_rep ||
        info.doctor_status_rep !== "approve" ||
        info.doctor_status_rep !== "decline")
    ) {
      if (info.once && info.once === "yes") {
        status = "ignore";
        action = ACTIONLIST.IGNORE;
      } else {
        status = "repeat";
        if (!info.has_current_script) {
          isdoctor = true;
        }
        action = ACTIONLIST.REPEAT;
      }
    }
    // Waiting for uploading script
    else if (
      info.has_current_script &&
      !info.current_script &&
      !info.non_current &&
      !info.verified_script &&
      (!info.once || info.once !== "yes")
    ) {
      status = "upload";
      action = ACTIONLIST.UPLOAD;
    }
    // Waiting for pharmacy to verify
    else if (
      info.has_current_script &&
      !info.non_current &&
      !info.verified_script &&
      (!info.active_date ||
        (info.active_date && this.isActiveRepeat(info.active_date))) &&
      (!info.once || info.once !== "yes" || (info.both && info.both === "yes"))
    ) {
      status = "verifying";
      action = ACTIONLIST.VERIFYING;
    }
    // Pharmacy Verified Waiting for Purchase
    else if (
      !info.active_date &&
      info.order_status === null &&
      info.has_current_script &&
      info.verified_script &&
      !info.non_current &&
      // If a subscription has an order then this will be falsy
      !info.transaction_data
    ) {
      status = "verified";
      action = ACTIONLIST.VERIFIED;
    } else if (info.order_status === "Paid") {
      // Order has been paid but not started to be processed by the pharmacy
      status = "paid";
      action = ACTIONLIST.PURCHASE;
    } else if (info.order_status === "Bad Address") {
      // Order was paid but has a bad address
      status = "bad_address";
      action = ACTIONLIST.PURCHASE;
    } else if (info.order_status === "Error") {
      // Something went wrong when the order was being placed
      status = "error";
      action = ACTIONLIST.PURCHASE;
    }
    // Pharmacy Verified Purchased Waiting for Dispatch
    // New: by product env, if transaction_data but pharmacy_dt and once is yes from product env
    else if (
      // Old subscriptions without orders, still using transaction_data
      info.order_status === "Being Processed"
    ) {
      // Order is being processed by the pharmacy
      status = "being_processed";
      action = ACTIONLIST.PURCHASE;
    } else if (info.order_status === "Cold Chain - Being Processed") {
      status = "cold_chain_being_processed";
      action = ACTIONLIST.PURCHASE;
    } else if (info.order_status === "Compounding in Progress") {
      // Order is being compounded by the pharmacy
      status = "compounding_in_progress";
      action = ACTIONLIST.PURCHASE;
    } else if (info.order_status === "Waiting") {
      status = "waiting";
      action = ACTIONLIST.WAITING;
    } else if (info.order_status === "New Script Required") {
      status = "new_script_required";
      action = ACTIONLIST.NEW_SCRIPT_REQUIRED;
    }
    // Pharmacy Verified Purchased Dispatched
    // New: by product env, if transaction_data but pharmacy_dt and once is yes from product env
    else if (
      // Old questionnaires with no orders, still using transaction_data
      ((info.order_status === null && info.transaction_data) ||
        info.order_status === "Being Processed" ||
        info.order_status === "Waiting") &&
      info.pharmacy_dt &&
      ((info.has_current_script && info.verified_script && !info.non_current) ||
        (info.once && info.once === "yes"))
    ) {
      status = "dispatch";
      action = ACTIONLIST.DISPATCH;
    } else if (info.pharmacy_status === "decline") {
      status = "decline";
      action = ACTIONLIST.DECLINE;
    }
    // Waiting for Doctors Script
    else if (
      (!info.doctor_status || info.doctor_status !== "approve") &&
      !info.has_current_script &&
      !info.non_current &&
      !info.verified_script &&
      (!info.active_date ||
        (info.active_date && this.isActiveRepeat(info.active_date)))
    ) {
      if (info.once && info.once === "yes") {
        status = "ignore";
        action = ACTIONLIST.IGNORE;
      } else {
        status = "verifying";
        isdoctor = true;
        action = ACTIONLIST.VERIFYING;
      }
    }
    // Doctor Approved Waiting for Purchase
    else if (
      // There will be no order at this point, and therefore no order status. The API response sends order_status as
      // null if another repeat questionnaire has been made after the previous orders to restart the order process
      !info.active_date &&
      info.order_status === null &&
      info.doctor_status === "approve" &&
      !info.has_current_script &&
      !info.non_current &&
      !info.transaction_data
    ) {
      status = "verified";
      isdoctor = true;
      action = ACTIONLIST.VERIFIED;
    }
    // Doctor approved, pending async payment to clear
    else if (info.order_status === "Pending") {
      status = "payment_pending";
      isdoctor = true;
      action = ACTIONLIST.PAYMENT_PENDING;
    } else if (info.order_status === "Payment failed") {
      status = "payment_failed";
      isdoctor = true;
      action = ACTIONLIST.PAYMENT_FAILED;
    }
    // Doctor Approved Purchased Waiting for Pharmacy Dispatch
    else if (
      // Pre-OTC questionnaires which still have transaction_data but no Oscar order still have technically been
      // ordered and should display as such
      ((info.order_status === null && info.transaction_data) ||
        info.order_status === "Paid" ||
        info.order_status === "Being Processed") &&
      info.doctor_status === "approve" &&
      !info.has_current_script &&
      !info.non_current &&
      !info.pharmacy_dt
    ) {
      status = "purchase";
      isdoctor = true;
      action = ACTIONLIST.PURCHASE;
    } else if (info.order_status === "Cancelled") {
      // The order was deliberately cancelled by the patient
      status = "cancelled";
      action = ACTIONLIST.ORDER_CANCELLED;
    }
    // // Doctor Approved Purchased Pharmacy Dispatched and Renewal Upcoming
    else if (
      info.order_status === "Completed" &&
      info.active_date &&
      !this.isActiveRepeat(info.active_date) &&
      info.repeats_remain === 0
    ) {
      status = "dispatch";
      isdoctor = true;
      action = ACTIONLIST.DISPATCH;
      renewal = true;
    }
    // Doctor Approved Purchased Pharmacy Dispatched
    else if (
      // NOTE: Until all subscriptions have Oscar Orders, they will have no order_status
      info.order_status === "Completed" ||
      (!info.order_status &&
        info.active_date &&
        !this.isActiveRepeat(info.active_date)) ||
      // Pre-OTC check
      (info.transaction_data &&
        info.doctor_status === "approve" &&
        !info.has_current_script &&
        !info.non_current &&
        info.pharmacy_dt)
    ) {
      status = "dispatch";
      isdoctor = true;
      action = ACTIONLIST.DISPATCH;
    }

    return { status, isdoctor, action, ACTIONLIST, renewal, OPT: {} };
  }

  /**
   *
   * @param {any} resource
   * @param {{
   *   method: "GET" | "POST" | "PATCH" | "PATCH" | "DELETE" | "OPTIONS";
   *   headers?: HeadersInit;
   *   body?: BodyInit | null;
   *   redirect?: RequestRedirect;
   * }} options
   * @param {string | null} from
   * @param {string | null} name
   * @param {boolean} showmsg
   * @param {boolean} isfile
   * @param {string | null} username
   * @param {boolean} notsend
   * @param {boolean} islogout
   * @param {boolean} notoast
   * @param {boolean} disableload
   * @returns {any}
   */
  async Fetch(
    resource,
    options,
    from = null,
    name = null,
    showmsg = false,
    isfile = false,
    username = null,
    notsend = false,
    islogout = false,
    notoast = false,
    disableload = false
  ) {
    const { timeout = TIMEOUT } = options;

    let requestHeaders = {};
    if (options.headers !== undefined && options.headers !== null) {
      if (options.headers instanceof Headers) {
        [...options.headers.entries()].forEach(([k, v]) => {
          requestHeaders[k] = v;
        });
      } else {
        requestHeaders = options.headers;
      }
    }

    if (User.loggedIn()) {
      const user = User.info();
      // Apply some additional info if the user is not empty
      if (
        !Object.keys(requestHeaders)
          .map((k) => k.toLowerCase())
          .includes("authorization")
      ) {
        requestHeaders.Authorization = `Bearer ${user.token}`;
      }

      if (username === null || username === undefined) {
        username = user.email;
      }
    }

    // Add the brand header
    requestHeaders.Brand = BRAND;

    let errmsg = "";
    let result = {};
    let response = {};

    const loader = document.getElementById("cover-spin");
    loader && !disableload ? (loader.className = "show") : "";

    if (loader && !disableload) {
      setTimeout(() => {
        try {
          loader.className = loader.className.replaceAll("show", "");
        } catch (e) {
          if (e instanceof TypeError) {
            loader.className = loader.className.replace(/show/g, "");
          }
        }
      }, timeout);
    }

    const modifiedOptions = { ...options };
    let timeoutID = null;
    try {
      const controller = new AbortController();
      modifiedOptions.signal = controller.signal;
      timeoutID = setTimeout(
        () => controller.abort("Fetch request timeout exceeded"),
        timeout
      );
    } catch (e) {}

    try {
      response = await fetch(resource, {
        ...modifiedOptions,
        headers: requestHeaders
      });
    } catch (e) {
      errmsg = e;
    }

    if (timeoutID !== null) clearTimeout(timeoutID);

    if (!response || errmsg) {
      if (!errmsg) {
        errmsg =
          "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 0923].";
      }
      notoast ? "" : Toast.error(errmsg);
      result.url = resource;
      result.msg = errmsg;
      result.type = "noresponse";
      result.iserror = true;
      notsend = true;
    } else if (!response.ok) {
      try {
        const msg_ = await response.json();
        if (typeof msg_ === "object" && msg_ !== null) {
          result.msg = JSON.stringify(msg_);
        } else {
          result.msg = `${response.status}:${response.statusText}`;
        }
      } catch (error) {
        result.msg = `${response.status}:${response.statusText}`;
      }
      result.type = "status";
      result.iserror = true;
      result.status = response.status;
      result.url = response.url;
    } else {
      if (isfile) {
        result.img = await response.blob();
        result.src = URL.createObjectURL(result.img);
      } else {
        try {
          result = await response.json();
        } catch (e) {
          result.msg = e.message;
          result.error = true;
          if (
            !notsend &&
            e.message &&
            FETCHEXERR.findIndex((err) => e.message.includes(err.text)) > -1
          ) {
            notsend = true;
          }
        }
      }

      if (!result || result.error) {
        result = result ? result : {};
        result.iserror = true;
        result.url = resource;
        if (result.message) {
          result.msg = result.message;
        } else if (!result.msg) {
          result.msg = "unknown";
        }
        result.type = "result";
        notoast ? "" : Toast.error(result.error ? result.error : result.msg);
      } else if (showmsg) {
        for (const key in result) {
          if (result[key]) {
            if (result[key].success) {
              Toast.success(
                result[key].result
                  ? result[key].result
                  : result[key].message
                  ? result[key].message
                  : name
              );
            } else if (
              result[key].success !== undefined &&
              !result[key].success
            ) {
              Toast.error(
                result[key].result
                  ? result[key].result
                  : result[key].message
                  ? result[key].message
                  : name
              );
            } else if (response.ok) {
              Toast.info(
                result[key].result
                  ? result[key].result
                  : result[key].message
                  ? result[key].message
                  : name
              );
            }
          }
        }
      }
    }

    if (result.iserror) {
      const msg = response && response.status ? response : result;
      if (result && result.status && result.status === 401) {
        result.notoken = true;
      }
      if (
        MESSAGEMAP.findIndex(
          (err) =>
            (msg.status ? msg.status : msg.error ? msg.error : msg.msg)
              .toString()
              .includes(err.name) && err.nosend
        ) <= -1 &&
        !notsend &&
        (!islogout || (islogout && !result.notoken))
      ) {
        await this.sendDebugEmail(
          result,
          from,
          name,
          result.status ? result.status : null,
          username
        );
      }
    }

    try {
      loader && !disableload
        ? (loader.className = loader.className.replaceAll("show", ""))
        : "";
    } catch (e) {
      if (e instanceof TypeError) {
        loader && !disableload
          ? (loader.className = loader.className.replace(/show/g, ""))
          : "";
      }
    }

    return result;
  }

  Config() {
    return {
      HOST,
      API,
      STATE,
      LOGTYPE,
      LOGO,
      TIMEOUT,
      FROM,
      LIVE,
      PREIVEW,
      ENABLECOUPON,
      MINAGE
    };
  }

  showCountdown(
    count = 10,
    type = "redirect",
    url = "/",
    staticmsg = "",
    finishmsg = ""
  ) {
    const x = document.getElementById("countdown-wapper");
    x.className = "show";

    if (staticmsg) {
      document.getElementById("countdown-msg").innerHTML = staticmsg;
    }

    let timeleft = count;
    const downloadTimer = setInterval(() => {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        document.getElementById("modalcountdown").innerHTML = finishmsg
          ? finishmsg
          : type === "redirect"
          ? "Redirecting ..."
          : "Finished";
        setTimeout(() => {
          if (type === "redirect") {
            window.location.href = url;
          }
        }, 1000);
      } else {
        document.getElementById(
          "modalcountdown"
        ).innerHTML = `${timeleft} seconds remaining`;
      }
      timeleft -= 1;
    }, 1000);
  }

  async sendDebugEmail(result, from, name, status, username) {
    this.logger(STAGING, "Common", "sendDebugEmail", "Start");

    if (
      EXCLUDEERRORTOSENDMAP.findIndex(
        (list) =>
          (list.status && list.status === status) ||
          (result &&
            result.msg &&
            result.msg.length > 1 &&
            (typeof result.msg === "string" || result.msg instanceof String) &&
            list.msg &&
            result.msg.includes(list.msg))
      ) > -1 ||
      !result?.url
    ) {
      this.logger(
        STAGING,
        "Common",
        "sendDebugEmail",
        "End: exclude to send error message"
      );
      return false;
    }

    const data = {
      brand: this.Config().FROM.trim(),
      user: username,
      site: from,
      title: name,
      code: status,
      url: result.url,
      message: result.msg
    };

    Sentry.captureException(
      new Error(`"${data.url}" returned status ${data.code}: ${data.message}`),
      {
        extra: data
      }
    );

    this.logger(STAGING, "Common", "sendDebugEmail", "End");
  }

  logger(
    type_,
    component = null,
    functionname = null,
    message,
    logtype_ = null
  ) {
    const type = parseInt(type_);
    const logtype = logtype_ ? parseInt(logtype_) : 2;
    if (
      parseInt(type) < LIVE ||
      LOGTYPE[logtype] === "ERROR" ||
      LOGTYPE[logtype] === "ALWAYS"
    ) {
      let isNextline = false;
      if (
        typeof message === "object" ||
        (Array.isArray(message) && message.length > 0)
      ) {
        isNextline = true;
      }
      component
        ? console.log(
            LOGTYPE[logtype],
            `(${moment().format(
              "YYYY-MM-DD HH:MM"
            )})[${component}][${functionname}]:${!isNextline ? message : ""}`
          )
        : "";
      isNextline || !component ? console.log(message) : "";
    }
  }

  isDateInPast = (date) => moment(date).isBefore(moment(), "day");
}
