import { useCallback, useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import { SafeURLSearchParams } from "@mh/core";
import {
  type Category,
  OscarAPI,
  useAsync,
  type LinkedProduct,
  type PaginatedResponse,
  type Price,
  Product,
  type ProductAvailability as IProductAvailability,
  asyncIsSuccess,
  type Async,
  createAsync
} from "@mh/api";
import {
  Await,
  Breadcrumbs,
  Spinner,
  ProductAvailability
} from "@mh/components";
import {
  OTCProducts,
  getProductsWithPrices,
  type LoadProductsFnPaginated
} from "@mh/otc-products";

import { ShopProduct } from "./ShopProduct";
import "./styles.scss";

/**
 * Shop page component
 */
export const Shop = (): JSX.Element => {
  const location = useLocation();
  const { productId } = useParams<{ productId?: string }>();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const categories = useAsync(OscarAPI.getCategories, undefined, {
    forceAll: "true",
    otc: "true"
  });
  const [activeProduct, setActiveProduct] = useState<Product | null>(null);
  const [activeProductAvailability, setActiveProductAvailability] =
    useState<IProductAvailability | null>(null);
  const [activeProductPrice, setActiveProductPrice] = useState<Price | null>(
    null
  );
  /**
   * Represents the initial index of the {@link categories} variable which the OTCProducts will have its category
   * filter set to. If this value is 2, then the OTCProducts will start with the third category in the list selected.
   */
  const [initialCategoryIndex, setInitialCategoryIndex] = useState<
    Async<number>
  >(createAsync());

  useEffect(() => {
    if (!productId) {
      // Reset details of the active product
      setActiveProduct(null);
      setActiveProductPrice(null);
      setActiveProductAvailability(null);
      return;
    }

    // Load the detail view of the product
    OscarAPI.getProduct(parseInt(productId, 10)).then(setActiveProduct);
    // Load the price of the product
    OscarAPI.getProductPrice(parseInt(productId, 10), {
      persistedPricing: false
    }).then(setActiveProductPrice);
    OscarAPI.getProductAvailability(parseInt(productId, 10)).then(
      setActiveProductAvailability
    );
  }, [productId]);

  useEffect(() => {
    if (location.pathname === "/shop") {
      setActiveProduct(null);
      setActiveProductPrice(null);
      setActiveProductAvailability(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    const categorySlug = queryParams.get("category");

    if (!categorySlug || categories.async.state === "failed") {
      // Categories have loaded but no category query param is present OR categories failed to load, so don't specifcy
      // an initial category
      setInitialCategoryIndex(createAsync(-1));
      return;
    }

    if (!asyncIsSuccess(categories.async)) {
      // Just wait if categories are still loading
      return;
    }

    // Find the index of the category with the same slug as the query param, or -1 if no match is found
    const categoryIndex = categories.async.data.findIndex(
      (category) => category.slug === categorySlug
    );

    setInitialCategoryIndex(createAsync(categoryIndex));
  }, [queryParams, categories.async]);

  /**
   * Loads all OTC products for the given categories and pagination options
   */
  const loadProducts: LoadProductsFnPaginated<
    LinkedProduct & { price?: string }
  > = useCallback(async ({ categories, search, ...paginationOptions }) => {
    // Load the paginated products
    const products: PaginatedResponse<LinkedProduct & { price?: string }> =
      await OscarAPI.getProductsPaginated(
        {
          structure: ["standalone"],
          categories,
          search,
          productClass: "otc",
          orderByNumPurchases: true
        },
        paginationOptions
      );

    // Assign prices to products
    products.results = await getProductsWithPrices<LinkedProduct>(
      products.results
    );
    return products;
  }, []);

  const handleInfoPressed = (product: LinkedProduct): void => {
    navigate(`/shop/${product.id}`);
  };

  /** Reset the active product state when the search changes */
  const handleSearchChange = (): void => {
    if (activeProduct) {
      // If a search is performed while a product is being viewed, go back to the base page
      navigate("/shop");
    }
  };

  /**
   * Reset the active product state when the category changes
   *
   * @param category THe selected category, or null if the "All Products" category is selected
   */
  const handleCategoryChange = (category: Category | null): void => {
    let url = "/shop";

    if (category) {
      url += `?${new SafeURLSearchParams({ category: category.slug })}`;
    }

    navigate(url);
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        maxWidth: "100%",
        gap: "8px"
      }}
    >
      <Await<[Category[], number]>
        asyncs={[categories.async, initialCategoryIndex]}
        renderPending={
          <div
            css={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <Spinner />
          </div>
        }
      >
        {([categories, initialCategoryIndex]) => (
          <OTCProducts<LinkedProduct>
            loadProducts={loadProducts}
            paginated
            scrollable={false}
            categories={categories.filter(
              (category) =>
                !["renew-a-script", "fill-a-script"].includes(category.slug)
            )}
            hideSearchResults={!!activeProduct}
            clickableTitle
            initialCategoryIndex={initialCategoryIndex}
            onInfoPressed={handleInfoPressed}
            onSearch={handleSearchChange}
            onCategoryChange={handleCategoryChange}
            renderOverImage={(product) => (
              <ProductAvailability
                availability={product.availability}
                css={(theme) => ({
                  position: "absolute",
                  top: 10,
                  left: 10,
                  [theme.mq.md]: {
                    top: 20,
                    left: 20
                  }
                })}
              />
            )}
          >
            {({ categories, search, products, pagination }) => (
              <>
                {categories}
                <div
                  css={{ display: "flex", width: "100%", alignItems: "center" }}
                >
                  {activeProduct && (
                    <div
                      css={(theme) => ({
                        display: "none", // Hidden on mobile
                        flex: 3,
                        [theme.mq.md]: { display: "flex" }
                      })}
                    >
                      <Breadcrumbs
                        crumbs={[
                          { title: "OTC", to: "/shop" },
                          {
                            title: activeProduct.title,
                            to: `/shop/${activeProduct.id}`
                          }
                        ]}
                        includeHome
                      />
                    </div>
                  )}
                  <div css={{ flex: 1 }}>{search}</div>
                </div>
                {productId && activeProduct && (
                  <>
                    <ShopProduct
                      product={activeProduct}
                      availability={activeProductAvailability}
                      price={activeProductPrice}
                    />
                  </>
                )}
                {!productId && products}
                {!productId && pagination}
              </>
            )}
          </OTCProducts>
        )}
      </Await>
    </div>
  );
};
