import { ComponentPropsWithoutRef } from "react";
import { Interpolation } from "@emotion/react";
import { Theme } from "../theme";
import { Size } from "../types";

interface HeadingProps extends ComponentPropsWithoutRef<"span"> {
  color?: keyof Theme["color"];
  css?: Interpolation<Theme>;
  size?: Size;
}

const SIZE_MAP: Record<Size, `${number}px`> = {
  sm: "12px",
  md: "16px",
  lg: "20px"
};

export const Heading = ({
  color = "primary",
  css,
  size = "md",
  ...spanProps
}: HeadingProps) => (
  <span
    css={[
      (theme) => ({
        fontWeight: "600",
        color:
          color in theme.color
            ? theme.color[color as keyof Theme["color"]]
            : color,
        fontSize: SIZE_MAP[size]
      }),
      css
    ]}
    {...spanProps}
  />
);
