import { ComponentPropsWithoutRef } from "react";

export const LogoYouly = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 732.7 178.6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M111.1,175.6H43.3c4.6-23.2,3.7-52.7,2.3-70.5C37.9,78.7,18.8,36,0,2.7h81.5c4,24.3,13.7,51.6,23.9,77h2.6c.3-25.4-10.5-62-15.7-77h65.8c-19.7,38.5-33.6,63.6-49.3,102.4,0,17.8-2.3,47.2,2.3,70.5Z"
      fill="#e84c42"
    />
    <path
      d="M224.2,0c52.1,0,83.8,37.7,83.8,89s-31.6,89.3-83.8,89.3-83.5-37.7-83.5-89.3S172.4,0,224.2,0ZM224.2,158.1c10,0,12.5-35.2,12.5-69.1s-2.6-68.8-12.5-68.8-12.3,35.2-12.3,69.1,2.6,68.8,12.3,68.8Z"
      fill="#e84c42"
    />
    <path
      d="M396.9,175.6c7.4-7.9,17.9-24.9,18.5-39.1h-5.1c-4.8,26.8-29.9,42.1-55.3,42.1s-38.5-13.9-38.5-40.1,1.7-22.1,2.3-38c.9-23.2.6-77.6-2.3-97.8h67c-3.4,22.9-2.8,92-2.8,100.8,0,31.7,7.4,34.7,17.7,34.7h8.3c.3-29.2,1.1-125.4-15.7-135.5h80.9c-4.8,34.1-5.7,138.7,0,172.9h-74.9Z"
      fill="#e84c42"
    />
    <path
      d="M483.2,175.6c4.8-34.1,5.7-138.7,0-172.9h76.4c-9.7,38.8-13.4,83-11.4,126.2,15.7-1.4,44.2-9.3,44.2-20.2h3.7v66.9h-112.8Z"
      fill="#e84c42"
    />
    <path
      d="M685.7,175.6h-67.8c4.6-23.2,3.7-52.7,2.3-70.5-7.7-26.5-26.8-69.1-45.6-102.4h81.5c4,24.3,13.7,51.6,23.9,77h2.6c.3-25.4-10.5-62-15.7-77h65.8c-19.7,38.5-33.6,63.6-49.3,102.4,0,17.8-2.3,47.2,2.3,70.5Z"
      fill="#e84c42"
    />
  </svg>
);
