import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DigitalID,
  Modal,
  IconCheckCircle,
  Status
} from "@mh/components";
import { IDVerification, PatientAPI, PatientContext, User } from "@mh/api";
import { DIGITAL_ID } from "@mh/core";
import { MedicareModal } from "../../../../../src/components/IDVerification/Modals/MedicareModal";
import { DVAModal } from "../../../../../src/components/IDVerification/Modals/DVAModal";

export interface IDVerificationActionProps {
  verification: IDVerification;
  setRefresh: () => void;
}

export interface VerificationRequiredStatusProps {
  verification: IDVerification;
  ihiRequired: boolean;
  onVerificationChanged: () => void;
  productName: string;
}

export const VerificationRequiredStatus = ({
  ihiRequired,
  onVerificationChanged,
  ...props
}: VerificationRequiredStatusProps) => {
  const navigate = useNavigate();
  const patientContext = useContext(PatientContext);

  let buttons: JSX.Element[] = [];

  const [showModalMedicare, setShowModalMedicare] = useState<boolean>(false);
  const [showModalDVA, setShowModalDVA] = useState<boolean>(false);
  const [verification, setVerification] = useState<IDVerification>(
    props.verification
  );
  const [showModalFail, setShowModalFail] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [showAlertFailText, setShowAlertFailText] = useState<string>("");

  const cardStr = verification.id_cards.medicare ? "Medicare" : "DVA";

  const [text, setText] = useState<string>(
    verification.id_cards.medicare || verification.id_cards.dva
      ? `The ${cardStr} details you provided did not match some of the other details in your profile. To continue, update your details to ensure they match your ${cardStr} card - this may include your name, date of birth, address, ${cardStr} card details or sex.`
      : "We need to verify your identity to proceed with your consultation. Please select one of the following options to continue."
  );

  useEffect(() => {
    patientContext.get();
  }, []);

  const getButton = (
    idx: number,
    text: string,
    classname: string | null,
    action: () => void
  ): JSX.Element => (
    <div key={idx} className={classname ?? "col-12 col-lg-4 pb-2 pb-lg-0"}>
      <button
        className="btn btn-primary w-100"
        css={{ height: "50px" }}
        onClick={action}
      >
        {text}
      </button>
    </div>
  );

  const showSuccessFailModal = (
    newUpdatedVerification: IDVerification | null,
    failMessage: string
  ) => {
    const succesfullVerificationUpdate: boolean =
      (newUpdatedVerification &&
        newUpdatedVerification.verified &&
        (!newUpdatedVerification.id_cards.medicare ||
          !newUpdatedVerification.id_cards.dva)) ??
      false;

    setShowModalSuccess(succesfullVerificationUpdate);
    setShowModalFail(!succesfullVerificationUpdate);
    if (!succesfullVerificationUpdate) setShowAlertFailText(failMessage);
    if (newUpdatedVerification) setVerification(newUpdatedVerification);
  };

  if (verification.id_cards.medicare || verification.id_cards.dva) {
    buttons = [
      getButton(1, "Update Details", "col-12 col-lg-4", () =>
        navigate("/profile")
      )
    ];
  } else {
    buttons = [
      getButton(1, "Add Medicare Details", null, () =>
        setShowModalMedicare(true)
      ),
      getButton(2, "Add DVA Details", null, () => setShowModalDVA(true))
    ];
    if (!ihiRequired) {
      // If IHI is required for this treatment, we don't show the "verify with DigitalID" button
      // i.e. force patient to enter medicare card or DVA details.
      buttons.push(
        <div key="digitalidcheck" className="col-12 col-lg-4">
          <DigitalID
            key="digital-id"
            clientId={DIGITAL_ID.clientId}
            onComplete={async (code?: string) => {
              if (!code) return;

              const digitalIDResponse = await PatientAPI.verifyDigitalId({
                username: User.email()!,
                grantCode: code
              });
              if (digitalIDResponse.ok) {
                setShowModalSuccess(true);
                setShowModalFail(false);
              } else {
                let errorText =
                  "The details you provided via DigitalID did not match your profile. Please review and update your name and date of birth from the Profile section and complete the DigitalID process again. Alternatively, you can provide Medicare or DVA details to continue.";
                let errorAlertText =
                  "Verification failed. Please review your Profile page and ensure that your details exactly match the ID Document provided, then try again.";

                const body = await digitalIDResponse.json();

                if (body && body.error) {
                  errorText = body.error;
                  errorAlertText = body.error;
                }

                setShowModalSuccess(false);
                setShowModalFail(true);
                setText(errorText);
                setShowAlertFailText(errorAlertText);
              }
            }}
            sdk={DIGITAL_ID.sdk}
          />
        </div>
      );
    }
  }

  return (
    <>
      <Status
        actions={<>{buttons}</>}
        title="VERIFICATION REQUIRED"
        titleVariant="danger"
      >
        <p>{text}</p>
      </Status>
      {showModalMedicare && (
        <MedicareModal
          show={true}
          onHide={() => setShowModalMedicare(false)}
          onSubmitSuccess={async () => {
            setShowModalMedicare(false);
            const updatedVerification = await PatientAPI.checkVerification();
            showSuccessFailModal(
              updatedVerification,
              "Verification failed. Please ensure your Medicare details are correct and that they match your profile information."
            );
          }}
        />
      )}
      {showModalDVA && (
        <DVAModal
          show
          onHide={() => setShowModalDVA(false)}
          onSubmitSuccess={async () => {
            setShowModalDVA(false);
            const updatedVerification = await PatientAPI.checkVerification();
            showSuccessFailModal(
              updatedVerification,
              "Verification failed. Please ensure your DVA details are correct and that they match your profile information."
            );
          }}
        />
      )}
      {showModalSuccess && (
        <Modal
          show
          variant="success"
          size="sm"
          mobileVCenter={true}
          onHide={() => setShowModalSuccess(false)}
        >
          <div
            css={() => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
              textAlign: "center"
            })}
          >
            <IconCheckCircle
              css={(theme) => ({
                color: theme.color.success
              })}
              width={40}
              height={40}
            />
            <span>Verification successful.</span>
          </div>
          <Modal.Actions>
            <Button
              onClick={() => {
                setShowModalSuccess(false);
                onVerificationChanged();
              }}
              variant="primary"
              css={{
                width: "100% !important"
              }}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {showModalFail && (
        <Modal
          show
          variant="danger"
          size="sm"
          mobileVCenter={true}
          onHide={() => setShowModalFail(false)}
        >
          <span>{showAlertFailText}</span>
          <Modal.Actions>
            <Button
              fullWidth
              onClick={() => {
                setShowModalFail(false);
                navigate("/profile");
              }}
              variant="primary"
            >
              Go to your profile
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};
