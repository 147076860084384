import { ComponentPropsWithoutRef } from "react";

export const Select = (props: ComponentPropsWithoutRef<"select">) => (
  <select
    css={{
      height: "unset",
      backgroundColor: "transparent",
      border: "0.5px solid #73737377",
      borderRadius: "4px",
      padding: "4px 8px 4px 16px"
    }}
    {...props}
  />
);
