import { BRAND } from "@mh/core";
import { Flex, IconArrowLeft, LogoBrand } from "@mh/components";
import { useQContext } from "@mh/questionnaire/src/hooks";
import { QuestionContentType } from "@mh/questionnaire/src/types";

interface ProgressBarProps {
  position: number;
}

const BackButton = (props: { goBack: (() => void) | null }) => {
  const { question } = useQContext();

  const hide = [QuestionContentType.DEAD_END].includes(
    question.type as QuestionContentType
  );

  return (
    !hide && (
      <div
        css={(theme) => ({
          visibility: props.goBack ? "visible" : "hidden",
          position: "absolute",
          left: "0",
          top: "-39%",
          [theme.mq.md]: {
            position: "relative",
            marginRight: "24px",
            paddingBottom: "4px"
          }
        })}
        onClick={props.goBack ?? (() => {})}
      >
        <IconArrowLeft width={16} fill={"#4F4F4F"} />
      </div>
    )
  );
};

const ProgressLine = (props: ProgressBarProps) => (
  <div
    css={(theme) => ({
      marginLeft: "32px",
      width: "100%",
      background:
        BRAND === "hubhealth" ? theme.color.third : theme.color.secondary,
      height: "4px",
      borderRadius: "100px",
      marginTop: "6px",
      marginBottom: "6px",
      [theme.mq.md]: {
        marginLeft: "0px",
        marginTop: "auto",
        marginBottom: "auto"
      }
    })}
  >
    <div
      css={(theme) => ({
        width: `${props.position}%`,
        background: theme.color.primary,
        height: "4px",
        borderRadius: "100px"
      })}
    ></div>
  </div>
);

export const QuestionnaireHeader = () => {
  const { position, maxPosition, goBack } = useQContext();

  const questionCount: number = maxPosition;
  const handleGoBack = position === 1 ? null : goBack;
  const progressBarPosition = (position / questionCount) * 100;

  return (
    <div
      css={(theme) => ({
        paddingBottom: "24px",
        [theme.mq.md]: {
          paddingBottom: "80px"
        }
      })}
    >
      <Flex
        justifyContent="center"
        css={(theme) => ({
          paddingTop: "0px",
          paddingBottom: "21px",
          [theme.mq.md]: {
            paddingTop: "18.75px",
            paddingBottom: "33.9px"
          }
        })}
      >
        <LogoBrand
          css={(theme) => ({
            maxWidth: "135px",
            [theme.mq.md]: {
              maxWidth: "165px"
            }
          })}
        />
      </Flex>
      <Flex
        justifyContent="center"
        css={(theme) => ({
          position: "relative",
          marginTop: "16px",
          [theme.mq.md]: {
            marginTop: "0px",
            paddingTop: "18px",
            maxWidth: "628px",
            marginLeft: "auto",
            marginRight: "auto"
          }
        })}
      >
        <BackButton goBack={handleGoBack} />
        <ProgressLine position={progressBarPosition} />
      </Flex>
    </div>
  );
};
