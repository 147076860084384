import { useState } from "react";
import { type BrandStep, type TinyProduct } from "@mh/api";
import { Button, Spinner } from "@mh/components";

import type { TreatmentAcceptanceStepProps } from "../types";
import { SelectableBox } from "./SelectableBox";
import { WizardStep, TitleStyle, SubTitleStyle } from "./WizardStep";

interface ProductStepProps extends TreatmentAcceptanceStepProps {
  step: BrandStep;
}

/**
 * Renders each product available as part of this checkout and handles when a user selects one.
 */
export const ProductStep = ({ step, onContinue }: ProductStepProps) => {
  const { products, prices } = step.data;
  const [selectedProduct, setSelectedProduct] = useState<TinyProduct | null>(
    null
  );

  /** True if the step is submitting */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleContinue = () => {
    if (selectedProduct) {
      setIsSubmitting(true);

      try {
        onContinue({ product: selectedProduct.id });
      } catch (e) {
        setIsSubmitting(false);
      }
    }
  };

  /** The next button is disabled if no product has been selected */
  const isNextDisabled = isSubmitting || selectedProduct === null;

  return (
    <WizardStep
      heading="Which option would you prefer?"
      footer={
        <Button
          data-testid="product-step-next"
          variant="primary"
          disabled={isNextDisabled}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          onClick={handleContinue}
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            <div>
              Next
              <span css={{ paddingLeft: "16px" }}>
                <img src="/assets/images/com/rightArrow.svg" />
              </span>
            </div>
          )}
        </Button>
      }
    >
      {products
        .sort((a, b) => (a.title < b.title ? -1 : 1))
        .map((product, index) => {
          const isSelected = product.id === selectedProduct?.id;

          let priceDisplay = "-";

          const price = prices[product.id];

          if (price) {
            const inclTax = parseFloat(price.incl_tax);

            priceDisplay = Intl.NumberFormat("en-AU", {
              style: "currency",
              currency: "AUD"
            }).format(inclTax);
          }
          return (
            <SelectableBox
              data-testid={`product-list-${index}`}
              key={product.id}
              isSelected={isSelected}
              css={{ flexFlow: "column" }}
              onClick={() => {
                setSelectedProduct(product);
              }}
            >
              <div
                css={[
                  TitleStyle,
                  {
                    marginBottom: "4px"
                  }
                ]}
              >
                {product.title}
              </div>
              <span css={SubTitleStyle}>{product.parent_title}</span>
              <hr />
              <div css={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  css={{
                    color: "#262626",
                    fontSize: "20px",
                    fontWeight: "600"
                  }}
                >
                  {priceDisplay}
                </div>
              </div>
            </SelectableBox>
          );
        })}
    </WizardStep>
  );
};
