import { API } from "../../../api";
import {
  createRequest,
  extractPagination,
  Id,
  PaginatedResponse
} from "../../../utils";
import type {
  Basket,
  AddBasketLineRequest,
  BasketLine,
  UpdateBasketLineRequest,
  AddVoucherRequest,
  Failable
} from "../../basket";
import type { Voucher } from "../../voucher";

import type { BasketMembershipPricing } from "./types";

export class BasketAPI {
  /** Loads the current basket */
  static getBasket = createRequest<Basket>("/shop/api/basket/");

  /** Adds a product line to the current basket. Returns the updated basket. */
  static addProduct = async (payload: AddBasketLineRequest): Promise<Basket> =>
    API.url("/shop/api/basket/add-product/")
      .json()
      .body(payload)
      .post()
      .then((r) => r.json());

  /** Loads all product lines for the given basket */
  static getLines = extractPagination(
    createRequest<PaginatedResponse<BasketLine>, undefined, Id>(
      ({ id }) => `/shop/api/baskets/${id}/lines/`
    )
  );

  /** Updates an existing basket line. Uses the BasketLineDetail view */
  static updateLine = (
    basketId: number,
    lineId: number,
    payload: UpdateBasketLineRequest
  ): Promise<BasketLine> =>
    API.url(`/shop/api/baskets/${basketId}/lines/${lineId}/`)
      .body(payload)
      .json()
      .patch()
      .then((r) => r.json());

  static deleteLine = async (
    basketId: number,
    lineId: number
  ): Promise<Response> =>
    API.url(`/shop/api/baskets/${basketId}/lines/${lineId}/`).delete();

  /**
   * Adds a voucher to an Oscar basket line
   * @param payload The type of {@link AddVoucherRequest} containing a voucher's code
   * @returns The updated basket
   */
  static addVoucher = async (
    payload: AddVoucherRequest
  ): Promise<Failable<Voucher, string>> => {
    const response = await API.url("/shop/api/basket/add-voucher/")
      .body(payload)
      .post();
    const responseBody = await response.json();
    if (response?.status === 406) {
      const reason: string = responseBody?.reason
        ? responseBody.reason
        : responseBody?.non_field_errors[0];
      return { failure: reason, isFailure: true, isSuccess: false };
    }
    return { success: responseBody, isFailure: false, isSuccess: true };
  };

  static getMembershipPricing = async (
    basketId: number
  ): Promise<BasketMembershipPricing> =>
    API.url(`/shop/api/basket/${basketId}/membership-pricing/`)
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });
}
