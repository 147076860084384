import { forwardRef, useCallback, useImperativeHandle } from "react";
import { Stripe, type SetupIntentResult } from "@stripe/stripe-js";
import { useElements, PaymentElement } from "@stripe/react-stripe-js";

interface PaymentWrapperProps {
  /** The stripe setup */
  stripe: Stripe | null;
  /**
   * Callback for when the card input is changed
   * @param isComplete If the card input is complete or not
   * @returns void
   */
  onCompleteChange: (isComplete: boolean) => void;
}

export interface PaymentWrapperRef {
  /**
   * Confirm the setup intent given the component's surrounding <Elements>
   * @returns A promise that resolves to the result of the setup intent result, or null if the required Stripe setup is not
   * satisfied
   */
  confirm: () => Promise<SetupIntentResult | null>;
}

/**
 * A wrapper component around the PaymentElement which lets us confirm the setup intent provided by its surrounding
 * <Elements> component in a controlled manner
 */
export const PaymentWrapper = forwardRef<
  PaymentWrapperRef,
  PaymentWrapperProps
>(({ stripe, onCompleteChange }, ref) => {
  const elements = useElements();

  const confirm = useCallback<PaymentWrapperRef["confirm"]>(async () => {
    if (!stripe || !elements) return null;

    return stripe.confirmSetup({
      elements: elements,
      redirect: "if_required"
    });
  }, [stripe, elements]);

  useImperativeHandle(
    ref,
    () => ({
      confirm
    }),
    [confirm]
  );

  return (
    <PaymentElement
      options={{
        wallets: { googlePay: "auto", applePay: "auto" }
      }}
      onChange={({ complete }) => onCompleteChange(complete)}
    />
  );
});
