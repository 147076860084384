import { useEffect, useState, useRef } from "react";

import { BREAKPOINTS, MEDIA_QUERIES } from "./theme";
import { type Breakpoint } from "./types";

/** All possible breakpoint sizes, in pixels */
const SIZES: number[] = Object.values(BREAKPOINTS);

/** A reverse mapping of {@link BREAKPOINTS} - pixel sizes to their breakpoint */
// @ts-ignore
const SIZES_TO_BREAKPOINTS: Record<number, Breakpoint> = Object.keys(
  BREAKPOINTS
)
  .map((breakpoint) => ({
    [BREAKPOINTS[breakpoint as keyof typeof BREAKPOINTS]]: breakpoint
  }))
  .reduce((prev, curr) => ({ ...prev, ...curr }), {});

/** Returns the current {@link Breakpoint} size of the window */
export const useBreakpoint = (): Breakpoint => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>("sm");
  // Numeric representation of a breakpoint
  const currentSize = useRef<number>(0);
  // Mapping of screen sizes and the matchers that check if the breakpoint is matched
  const matchers = useRef<Record<number, MediaQueryList>>({});

  useEffect(() => {
    // Finds the new breakpoint size from the current window size

    /** True if an initial breakpoint has been matched */
    let hasMatched = false;

    // Every screen will likely match the small screen, so start by checking if it's a large monitor
    for (const breakpoint of Object.keys(
      BREAKPOINTS
    ).reverse() as Breakpoint[]) {
      const size = BREAKPOINTS[breakpoint];

      // Turn "@media (min-width: npx)" into "(min-width: npx)"
      const matcher = window.matchMedia(
        MEDIA_QUERIES[breakpoint].replace("@media ", "")
      );

      matcher.addEventListener("change", ({ matches }) => {
        if (matches && size > currentSize.current) {
          // Window has grown
          setBreakpoint(breakpoint);
          currentSize.current = size;
        }

        if (!matches && size === currentSize.current) {
          // Window has shrunk - the existing breakpoint match no longer matches
          for (let i = SIZES.indexOf(size) - 1; i >= 0; i--) {
            const size = SIZES[i];
            // Find the next-smallest breakpoint that matches the current window's size
            const matcher = matchers.current[size];
            if (matcher.matches) {
              // The new, smaller breakpoint
              const breakpoint = SIZES_TO_BREAKPOINTS[size];
              setBreakpoint(breakpoint);
              currentSize.current = size;
              break;
            }
          }
        }
      });

      // Keep track of this breakpoint matcher for later
      matchers.current[size] = matcher;

      // Do an initial size match
      if (!hasMatched && matcher.matches) {
        setBreakpoint(breakpoint);
        currentSize.current = size;
        // Mark this match as having occured so other smaller breakpoints aren't also checked
        hasMatched = true;
      }
    }
  }, []);

  return breakpoint;
};
