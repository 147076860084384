import { useTheme } from "@emotion/react";
import { IconMembership } from "@mh/components";

interface MembershipPanelProps {
  text: string;
  isCheckout?: boolean;
}

export const MembershipPanel = ({
  text,
  isCheckout = false
}: MembershipPanelProps) => {
  const theme = useTheme();

  return (
    <div
      css={[
        (theme) => ({
          display: "flex",
          flexDirection: isCheckout ? "row" : "column",
          width: isCheckout ? "100%" : "97%",
          gap: "10px",
          padding: "16px",
          backgroundColor: theme.color.background,
          margin: "5px",
          borderRadius: "10px",
          borderTop: `2px solid ${theme.color.primary}`,
          fontSize: isCheckout ? "16px" : "19px",
          fontFamily: "Inter",
          fontWeight: isCheckout ? "600" : "500",
          [theme.mq.md]: {
            width: "100%",
            flexDirection: "row",
            alignItems: "center"
          }
        })
      ]}
    >
      <div
        css={[
          (theme) => ({
            [theme.mq.md]: {
              ...(!isCheckout && { width: "150px" })
            }
          })
        ]}
      >
        <IconMembership width={70} height={70} fill={theme.color.primary} />
      </div>
      <div>{text}</div>
    </div>
  );
};
