import type { ComponentPropsWithoutRef } from "react";

interface Props extends ComponentPropsWithoutRef<"svg"> {
  size?: number;
}

export const IconSuccess = ({ size, ...rest }: Props) => (
  <svg
    width={size || "43"}
    height={size || "40"}
    viewBox="0 0 43 40"
    fill="#198754"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M21.5 2.5C15.1719 2.5 9.46875 5.85938 6.34375 11.25C3.14062 16.7188 3.14062 23.3594 6.34375 28.75C9.46875 34.2188 15.1719 37.5 21.5 37.5C27.75 37.5 33.4531 34.2188 36.5781 28.75C39.7812 23.3594 39.7812 16.7188 36.5781 11.25C33.4531 5.85938 27.75 2.5 21.5 2.5ZM21.5 40C14.3125 40 7.75 36.25 4.15625 30C0.5625 23.8281 0.5625 16.25 4.15625 10C7.75 3.82812 14.3125 0 21.5 0C28.6094 0 35.1719 3.82812 38.7656 10C42.3594 16.25 42.3594 23.8281 38.7656 30C35.1719 36.25 28.6094 40 21.5 40ZM29.8594 15.9375L19.8594 25.9375C19.3906 26.4062 18.5312 26.4062 18.0625 25.9375L13.0625 20.9375C12.5938 20.4688 12.5938 19.6094 13.0625 19.1406C13.5312 18.6719 14.3906 18.6719 14.8594 19.1406L19 23.2812L28.0625 14.1406C28.5312 13.6719 29.3906 13.6719 29.8594 14.1406C30.3281 14.6094 30.3281 15.4688 29.8594 15.9375Z" />
  </svg>
);
