import { BRAND } from "@mh/core";
import { API } from "../api";
import { createRequest, extractPagination, PaginatedResponse } from "../utils";
import type { UserAddress } from "../shop/address";
import type {
  AllowedMember,
  CheckAddressServiceabilityResponse,
  CreatePatientRequest,
  HealthcareTeamMember,
  IDVerification,
  PatientNotifications,
  PatientProfile,
  PatientQuestionnaire,
  PatientSubscriptionScript,
  PaymentMethodResult,
  TreatmentAcceptanceWizardData,
  TreatmentAcceptanceWizardStep,
  UserMembership
} from "./interface";

interface BrandQuery {
  brand: string;
}

export class PatientAPI {
  static loadPatientProfile = createRequest<
    PaginatedResponse<PatientProfile>,
    undefined,
    {},
    BrandQuery
  >("/api/v1/patientProfiledetails");

  static loadPatientQuestionnaires = extractPagination(
    createRequest<
      PaginatedResponse<PatientQuestionnaire>,
      undefined,
      {},
      BrandQuery
    >("/api/v1/patientquestionnaires")
  );

  static getPatientQuestionnaire = async (
    questionnaireId: number | string
  ): Promise<PatientQuestionnaire> =>
    API.v1()
      .url(`/patientquestionnaires/${questionnaireId}?brand=${BRAND}`)
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static updatePatientQuestionnaire = async (
    questionnaireId: number | string,
    questionnaireData: Partial<PatientQuestionnaire>
  ): Promise<PatientQuestionnaire> =>
    API.v1()
      .url(`/patientquestionnaires/${questionnaireId}?brand=${BRAND}`)
      .body(questionnaireData)
      .json()
      .patch()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static isAddressServiceable = async (
    address: Pick<
      UserAddress,
      "line1" | "line2" | "suburb" | "state" | "postcode"
    >,
    coldChain: boolean | undefined
  ): Promise<CheckAddressServiceabilityResponse> =>
    API.unauthenticated()
      .v2()
      .url(
        `/${
          coldChain ? "check-cold-chain-eligibility" : "check-serviceability"
        }/?line1=${address.line1}&line2=${address.line2}&suburb=${
          address.suburb
        }&state=${address.state}&postcode=${address.postcode}`
      )
      .get()
      .then((r) => r.json());

  static getConditions = async (): Promise<Response> =>
    API.v2().url("/patients/conditions").get();

  static getNotifications = async (): Promise<PatientNotifications> =>
    API.v1()
      .url(`/getpatientnotifications/?brand=${BRAND}`)
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static getHealthcareTeam = async (): Promise<HealthcareTeamMember[]> =>
    API.v1()
      .url(`/gethealthcareteam/?brand=${BRAND}`)
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static verifyDigitalId = async ({
    username,
    grantCode
  }: {
    username: string;
    grantCode: string;
  }): Promise<Response> =>
    API.v1()
      .url("/digitalid/")
      .body({ username, grant_code: grantCode })
      .post();

  static checkVerification = async (): Promise<null | IDVerification> => {
    const response = await API.v2().url("/id-verified/").get();
    if (response.ok) {
      const j = await response.json();
      return j;
    }
    return null;
  };

  /**
   * Payment
   */

  /**
   * Update the default payment method for a patient
   * @param param0 An object containing the setup intent id which will be used to update the default payment method
   * @returns
   */
  static updatePaymentMethod = async ({
    setupIntentId
  }: {
    setupIntentId: string;
  }): Promise<PaymentMethodResult> =>
    API.v2()
      .url("/payment-method/")
      .body({ setup_intent_id: setupIntentId })
      .patch()
      .then((r) => {
        if (!r.ok) {
          throw r;
        }

        return r.json();
      });

  /**
   * Deactivates a user's account
   * @returns Whether the account was successfully deleted or not
   */
  static deactivateAccount = async (): Promise<{}> =>
    API.v2()
      .url("/deactivate/")
      .body({})
      .post()
      .then((r) => {
        if (!r.ok) {
          throw r;
        }

        return r.json();
      });

  static createOrActivateRepeatQuestionnaire = async (
    subscriptionScriptId: number
  ): Promise<boolean> =>
    API.v2()
      .url(
        `/subscription-script/${subscriptionScriptId}/create-or-activate-repeat-questionnaire/`
      )
      .body({})
      .post()
      .then((response) => response.ok);

  /**
   * Subscriptions
   */

  static updatePatientSubscriptionScript = async (
    id: number,
    data: Partial<PatientSubscriptionScript>
  ): Promise<PatientSubscriptionScript> =>
    API.v1()
      .url(`/patientsubscriptionscripts/${id}`)
      .body(data)
      .json()
      .patch()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  /**
   * Gets the initial wizard steps to use for treatment acceptance of a particularly subscription.
   *
   * @param subscriptionId The ID of the subscription to get the wizard steps for.
   * @returns The initial wizard steps to use for treatment acceptance of the subscription.
   * @throws If a network error occurs while getting the wizard steps.
   */
  static getTreatmentAcceptanceWizard = async (
    subscriptionId: number
  ): Promise<TreatmentAcceptanceWizardStep[]> =>
    API.v2()
      .url(`/subscription-script/${subscriptionId}/treatment-acceptance/`)
      .json()
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      });

  /**
   * Submits a step of the treatment acceptance wizard for a subscription, and returns the current state of the wizard.
   *
   * @param subscriptionId The ID of the subscription to submit the wizard step for.
   * @param data The response data to submit for the wizard step.
   * @returns The current state of the wizard after submitting the step.
   * @throws If a network error occurs while submitting the wizard step.
   */
  static submitTreatmentAcceptanceWizardStep = async (
    subscriptionId: number,
    data: TreatmentAcceptanceWizardData
  ) =>
    API.v2()
      .url(`/subscription-script/${subscriptionId}/treatment-acceptance/`)
      .body(data)
      .json()
      .post()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }

        return response.json();
      });

  /**
   * Update IHI number
   * @param param0 An object containing the setup intent id which will be used to update the default payment method
   * @returns
   */
  static updateIHINumber = async (ihi: string): Promise<boolean> =>
    API.v1()
      .url("/patients/me/ihi")
      .body({
        number: ihi
      })
      .patch()
      .then((r) => {
        if (!r.ok) {
          return false;
        }
        return true;
      });

  static getUserMembershipStatus = async (): Promise<UserMembership[]> =>
    API.v2()
      .url("/user_membership/")
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static createUserMembership = async (): Promise<UserMembership[]> =>
    API.v2()
      .url("/user_membership/")
      .body({})
      .post()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static changeUserMembership = async (
    id: number,
    action: string
  ): Promise<UserMembership> =>
    API.v2()
      .url(`/user_membership/${id}/${action}/`)
      .body({})
      .post()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static getUserMembershipInvoiceUrl = async (
    id: number
  ): Promise<{ hosted_invoice_url: string }> =>
    API.v2()
      .url(`/user_membership/${id}/hosted_invoice_url/`)
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static getAllowedMembers = async (): Promise<AllowedMember[]> =>
    API.v2()
      .url("/allowed_member/")
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });

  static createPatient = async (
    request: CreatePatientRequest
  ): Promise<Response> =>
    API.unauthenticated().v2().url("/create_patient/").body(request).post();
}
