import { Flex } from "@mh/components";

interface EscriptProductSelectorFooterLinkProps {
  brand: string;
}

const getLinkValue = (brand: string) =>
  ({
    youly: {
      whatWeTreatLink: "youly.com.au/treatment-2/",
      renewYourScriptLink: "youly.com.au"
    },
    hubhealth: {
      whatWeTreatLink: "hub.health/treatment-2/",
      renewYourScriptLink: "hub.health"
    },
    stagger: {
      whatWeTreatLink: "stagger.com.au/products2/",
      renewYourScriptLink: "stagger.com.au"
    }
  })[brand];

export const EscriptProductSelectorFooterLink = (
  props: EscriptProductSelectorFooterLinkProps
) => (
  <Flex flexDirection="column">
    <div
      className="d-flex flex-md-row flex-column"
      css={{
        padding: "16px",
        marginBottom: "16px",
        background: "#F8F8F8"
      }}
    >
      <Flex
        css={(theme) => ({
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "135%",
          paddingRight: "24px",
          paddingBottom: "16px",
          [theme.mq.sm]: {
            textAlign: "left",
            paddingBottom: "0px"
          }
        })}
      >
        Need a prescription for a regular medication? Click here to renew your
        script
      </Flex>
      <Flex>
        <a
          href={`https://portal.${getLinkValue(props.brand)
            ?.renewYourScriptLink}/questionnaire/?product=renew_a_script`}
          css={(theme) => ({
            textDecoration: "unset",
            border: "0.5px solid",
            borderColor: theme.color.primary,
            padding: "8px 16px",
            borderRadius: "12px",
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "135%",
            textAlign: "center",
            cursor: "pointer",
            width: "100%",
            [theme.mq.sm]: {
              width: "155px"
            }
          })}
        >
          Renew your script
        </a>
      </Flex>
    </div>
    <div
      className="d-flex flex-md-row flex-column"
      css={{
        padding: "16px",
        background: "#F8F8F8"
      }}
    >
      <Flex
        css={(theme) => ({
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "135%",
          paddingRight: "24px",
          paddingBottom: "16px",
          [theme.mq.sm]: {
            textAlign: "left",
            paddingBottom: "0px"
          }
        })}
      >
        Treating another condition? Find out what we treat
      </Flex>
      <Flex>
        <a
          href={`https://${getLinkValue(props.brand)?.whatWeTreatLink}`}
          css={(theme) => ({
            textDecoration: "unset",
            border: "0.5px solid",
            borderColor: theme.color.primary,
            padding: "8px 16px",
            borderRadius: "12px",
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "135%",
            textAlign: "center",
            cursor: "pointer",
            width: "100%",
            [theme.mq.sm]: {
              width: "155px"
            }
          })}
        >
          What we treat
        </a>
      </Flex>
    </div>
  </Flex>
);
