import { API, type ConcreteAPI, User, BasketPricing } from "../../";

export class VoucherAPI {
  /**
   * Checks that a voucher exists for the given code, and is applicable to the given product. This can be used to see
   * what discount a user would get if they use the given voucher code.
   *
   * If a user is logged in, other benefits like user-specific discounts will be applied. If the request is made while
   * anonymous, only the voucher will be applied to the end result's price.
   *
   * @param voucher The voucher code to check
   * @param productId The ID of the product for which to retrieve a discounted price
   * @returns The discounted price, and the discount amount
   */
  static checkVoucher = async (
    voucher: string,
    productId: number
  ): Promise<BasketPricing> => {
    let api: ConcreteAPI = API.url(
      `/shop/api/check-voucher/?voucher=${voucher}&product=${productId}`
    );

    if (!User.loggedIn()) {
      // If the user is unauthenticated, we want to explicitly send an authenticated request so that offers which
      // required a user are not applied
      api = api.unauthenticated();
    }

    return api.get().then((r) => {
      if (!r.ok) throw r;

      return r.json();
    });
  };
}
