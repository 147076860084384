export const IconDelivery = () => (
  <svg
    width="71"
    height="71"
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="35.8652"
      cy="35.9429"
      r="35"
      css={(theme) => ({
        fill: `${theme.color.primary}`
      })}
    />
    <path
      d="M37.8652 23H23.8652C23.3027 23 22.8652 23.5 22.8652 24V26H30.8652C31.3652 26 31.8652 26.5 31.8652 27C31.8652 27.5625 31.3652 28 30.8652 28H16.8652C16.3027 28 15.8652 27.5625 15.8652 27C15.8652 26.5 16.3027 26 16.8652 26H19.8652V24C19.8652 21.8125 21.6152 20 23.8652 20H37.8652C40.0527 20 41.8652 21.8125 41.8652 24V26H44.4902C45.4277 26 46.3027 26.375 46.9902 27.0625L52.8027 32.875C53.4902 33.5625 53.8652 34.4375 53.8652 35.375V43H54.3652C55.1777 43 55.8652 43.6875 55.8652 44.5C55.8652 45.375 55.1777 46 54.3652 46H51.8652C51.8652 49.3125 49.1777 52 45.8652 52C42.4902 52 39.8652 49.3125 39.8652 46H31.8652C31.8652 49.3125 29.1777 52 25.8652 52C22.4902 52 19.8652 49.3125 19.8652 46V38H22.8652V40.8125C23.7402 40.3125 24.7402 40 25.8652 40C28.0527 40 29.9902 41.25 31.0527 43H37.8652C38.3652 43 38.8652 42.5625 38.8652 42V24C38.8652 23.5 38.3652 23 37.8652 23ZM44.8652 29.1875C44.7402 29.0625 44.6152 29 44.4902 29H41.8652V35H50.7402H50.6777L44.8652 29.1875ZM25.8652 43C24.1777 43 22.8652 44.375 22.8652 46C22.8652 47.6875 24.1777 49 25.8652 49C27.4902 49 28.8652 47.6875 28.8652 46C28.8652 44.375 27.4902 43 25.8652 43ZM45.8652 49C47.4902 49 48.8652 47.6875 48.8652 46C48.8652 44.375 47.4902 43 45.8652 43C44.1777 43 42.8652 44.375 42.8652 46C42.8652 47.6875 44.1777 49 45.8652 49ZM32.8652 30C33.3652 30 33.8652 30.5 33.8652 31C33.8652 31.5625 33.3652 32 32.8652 32H18.8652C18.3027 32 17.8652 31.5625 17.8652 31C17.8652 30.5 18.3027 30 18.8652 30H32.8652ZM30.8652 34C31.3652 34 31.8652 34.5 31.8652 35C31.8652 35.5625 31.3652 36 30.8652 36H16.8652C16.3027 36 15.8652 35.5625 15.8652 35C15.8652 34.5 16.3027 34 16.8652 34H30.8652Z"
      fill="white"
    />
  </svg>
);
