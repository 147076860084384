export type UserType = "patient" | "nurse" | "pharmacy" | "doctor" | "mh_staff";

export type OTPMethod = "sms" | "email";

export const OTPMethodNames: { [k in OTPMethod]: string } = {
  email: "email",
  sms: "SMS"
};

export interface UserDetail {
  id: number;
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  user_type: string;
  phone: string;
  is_active: string;
  date_joined: string;
}

export type UserDetailSimple = Pick<
  UserDetail,
  "id" | "first_name" | "last_name" | "user_type"
> & {
  profile_image: string | null;
};

export interface UserName {
  formatted: string;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
}

export interface UserInfo {
  id: number;
  token: string;
  expiry: string;
  email: string;
  user_type: UserType;
  has_patient_details: boolean;
  name: UserName;
  zendesk_credentials?: any;
}

export interface ExistingUserInfo {
  exists: boolean;
  redirect: string;
}

export interface ClinicalDetail {
  height: string;
  weight: string;
  smoking_status: string;
  alcohol_status: string;
  allergies: string;
  current_medications: string;
  medical_conditions: string;
  patients_goal_weight: string;
}

export interface OTPRequestContent {
  email: string;
  method: OTPMethod;
}

export interface OTPValidateContent {
  email: string;
  token: string;
}
