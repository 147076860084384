import { ComponentPropsWithoutRef } from "react";

export const IconMedicalHistory = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.3335 11.8607C3.3335 9.34668 3.3335 8.08934 4.11483 7.30868C4.8955 6.52734 6.15283 6.52734 8.66683 6.52734H11.3335C13.8475 6.52734 15.1048 6.52734 15.8855 7.30868C16.3215 7.74401 16.5142 8.32734 16.5988 9.19401M16.6668 11.8607C16.6668 14.3747 16.6668 15.632 15.8855 16.4127C15.1048 17.194 13.8475 17.194 11.3335 17.194H8.66683C6.15283 17.194 4.8955 17.194 4.11483 16.4127C3.67883 15.9773 3.48616 15.394 3.4015 14.5273"
      stroke="#6E8DBD"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12.6668 6.52702C12.6668 5.26968 12.6668 4.64168 12.2762 4.25102C11.8855 3.86035 11.2575 3.86035 10.0002 3.86035C8.74283 3.86035 8.11483 3.86035 7.72416 4.25102C7.3335 4.64168 7.3335 5.26968 7.3335 6.52702"
      stroke="#6E8DBD"
      strokeWidth="1.5"
    />
    <path
      d="M12 12.1279H8M10 10.1279V14.1279"
      stroke="#6E8DBD"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
