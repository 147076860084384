export function ClosedConversationBar() {
  return (
    <div
      className="pt-2 pb-0 px-4 border border-2 border-secondary rounded-3 bg-white"
      data-testid="closed-conversation-bar"
    >
      <p className="text-center">
        This conversation has been marked as closed.
      </p>
    </div>
  );
}
