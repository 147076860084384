import { BRAND } from "@mh/core";

export default [
  {
    id: 1,
    start: "2024-03-01",
    end: "2024-09-30",
    pages: ["treatment", "home"],
    type: "rightimg",
    left: {
      type: "left",
      classes: " order-1 order-lg-0 bg-green nib-slide",
      contents: [
        {
          classes: "logo [[BrandName]]-logo",
          type: "img",
          path: "/assets/images/[[from]]/bannerlogo.png"
        },
        {
          classes: "headerText [[HeaderFont]]",
          text: `Join nib and get up to $500 credit with hub.health`,
          excludeBrands: ["stagger", "youly"]
        },
        {
          classes: "headerText [[HeaderFont]]",
          text: `Join nib and score a Youly gift card worth up to $500`,
          excludeBrands: ["hubhealth", "stagger"]
        },
        {
          classes: "headerText [[HeaderFont]]",
          text: `Get up to $500 Stagger credit when you join nib`,
          excludeBrands: ["youly", "hubhealth"]
        },
        {
          type: "txtgroup",
          classes: "mainText",
          txtGroup: [
            {
              classes: "",
              text: `Join a combined Hospital + Extras Cover and you’ll receive `
            },
            {
              classes: "strong-6",
              text: `up to a $500 [[brand]] gift card `
            },
            {
              classes: "",
              text: `to use on medication purchases, consultations, medical certificates and prescriptions. Plus, `
            },
            {
              classes: "strong-6",
              text: `skip the 2 & 6 month wait on Extras*.`
            }
          ]
        },
        {
          classes: "btn btn-[[brand]] col-12 col-md-6",
          type: "link",
          text: "Get a quote",
          path: "https://cloud.au.nib.com.au/hubhealth"
        },
        {
          type: "txtgroup",
          classes: "bottomText",
          txtGroup: [
            {
              text: `*Offer ends 30 September 2024. New Australian resident members only. Ineligible products include Gold Hospital Tier, Basic Kickstarter and Basic Accident Hospital. Offer fulfilled in month 3 of membership. Value of offer based on level of cover selected, `
            },
            {
              classes: "strong-6 blackcolor",
              type: "link",
              text: "see details.",
              path: "https://www.nib.com.au/docs/hubhealth-offer-terms-tables-mar-2024"
            },
            {
              text: " Other "
            },
            {
              classes: "strong-6 blackcolor",
              type: "link",
              text: "T&Cs apply.",
              path: "https://www.nib.com.au/docs/hubhealth-offer-terms-and-conditions-mar-2024"
            }
          ]
        }
      ]
    },
    right: {
      type: "right",
      classes: "  order-0 order-lg-1",
      contents: [
        {
          classes: "d-none d-lg-block w-100 pcView",
          type: "img",
          path: "/assets/images/[[from]]/newNibBannerRight.svg"
        },
        {
          backgroundImage: true,
          classes: "d-none d-lg-block tabletView",
          type: "img",
          path: "/assets/images/[[from]]/newNibBannerRight.svg"
        },
        {
          classes: "d-md-none d-block mobile",
          type: "img",
          path: "/assets/images/[[from]]/newMobileNibBannerRight.svg"
        }
      ]
    }
  },
  {
    id: 2,
    start: "2023-11-01",
    end: "2030-04-30",
    pages: ["treatment", "home"],
    type: "rightimg",
    mobileOnly: true,
    left: {
      type: "left",
      classes: ` order-1 order-lg-0 pharmacy ${BRAND}-pharmacy-bg`,
      contents: [
        {
          classes: "pharmacy-heading",
          text: `Online Pharmacy Open!`
        },
        {
          type: "txtgroup",
          classes: "mainText",
          txtGroup: [
            {
              classes: "pharmacy-description",
              text: `All your non-prescription needs available on-line 24hr/day and delivered to your door!`
            }
          ]
        },
        {
          classes: `btn btn-[[brand]] col-12 col-md-6 btn-${BRAND}`,
          type: "link",
          text: "Shop Now",
          path: "/shop"
        }
      ]
    },
    right: {
      type: "right",
      classes: " order-0 order-lg-1",
      contents: [
        {
          backgroundImage: true,
          classes: "d-block mobileView medicine-img",
          type: "img",
          path: "/assets/images/com/medicine.png"
        }
      ]
    }
  }
];
