import { useRef } from "react";
import { type Price } from "@mh/api";
import { Spinner, Select, Button } from "@mh/components";

interface ProductPriceProps {
  price: Price | null;
  /** Is this product being added to, or removed from the basket? */
  isPending: boolean;
  /** Is this product present on a basket line? */
  isInBasket: boolean;
  /** Used to limit the max quantity */
  maxQuantity: number;
  /** Fired when the selected quantity changes */
  onQuantityChange: (quantity: number) => void;
  /** Fired when the "Add to card" button is clicked */
  onAddOrRemove: (quantity: number) => void;
}

export const ProductPrice = ({
  price,
  isPending,
  isInBasket,
  maxQuantity,
  onQuantityChange,
  onAddOrRemove
}: ProductPriceProps): JSX.Element => {
  const quantity = useRef<number>(1);

  return (
    <div
      css={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.color.backgroundFaint,
        width: "100%",
        padding: "24px 16px",
        gap: "24px"
      })}
    >
      <div
        css={(theme) => ({
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          [theme.mq.md]: {
            alignItems: "flex-start",
            justifyContent: "flex-start"
          }
        })}
      >
        <span css={{ alignSelf: "flex-start", fontWeight: 500 }}>
          Our Price:
        </span>
        {price ? (
          <span
            css={(theme) => ({
              color: theme.color.secondary,
              fontSize: "40px",
              fontWeight: 500
            })}
          >
            {Intl.NumberFormat("en-AU", {
              style: "currency",
              currency: "AUD"
            }).format(parseFloat(price.incl_tax))}
          </span>
        ) : (
          <Spinner size="sm" />
        )}
      </div>
      <div css={{ display: "flex", alignItems: "center", flex: 1, gap: "8px" }}>
        <span css={{ fontWeight: 500 }}>Qty</span>
        <Select
          css={{ height: "100%", width: "fit-content" }}
          onChange={(e) => {
            const value = parseInt(e.target.value, 10);
            if (value) {
              quantity.current = value;
              onQuantityChange(value);
            }
          }}
          data-testid="product-price-select"
        >
          {[...Array(maxQuantity).keys()].map((value) => (
            <option key={value + 1} value={value + 1}>
              {value + 1}
            </option>
          ))}
        </Select>
        <Button
          css={{ color: "#FFFFFF" }}
          onClick={() => onAddOrRemove(quantity.current)}
        >
          {isPending && <Spinner size="sm" variant="backgroundFaint" />}
          {!isPending && <>{isInBasket ? "Remove from cart" : "Add to cart"}</>}
        </Button>
      </div>
    </div>
  );
};
