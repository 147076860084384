import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { SchedulingAPI, PatientAPI } from "@mh/api";
import {
  Feature,
  PatientQuestionnaire,
  PatientQuestionnaireRepeat
} from "@mh/api";
import {
  Button,
  Status,
  sortArrayByFieldsAndDescending,
  IHIModal
} from "@mh/components";
import { Sentry } from "@mh/core";
import { SchedulingModal } from "./SchedulingModal";
import { ActionConfirmationModal } from "./ActionConfirmationModal";
import { ClinicianLayout } from "./ClinicianDropBox";

interface SchedulingCardProps {
  initialQuestionnaire: PatientQuestionnaire;
  currentQuestionnaire: PatientQuestionnaire | PatientQuestionnaireRepeat;
  nibTeleHealth: boolean;
  nibMembershipNumber: number | undefined;
  membershipsStatus: string | undefined;
}

export const SchedulingAction = ({
  initialQuestionnaire,
  currentQuestionnaire,
  nibTeleHealth,
  nibMembershipNumber,
  membershipsStatus
}: SchedulingCardProps) => {
  const { booking_event: bookingDetails, consultation } = currentQuestionnaire;
  const { name: product, slug } = currentQuestionnaire.category;
  const consultAsync = !currentQuestionnaire.consultation?.is_synchronous;
  const { arhi } = initialQuestionnaire;
  const { vip } = initialQuestionnaire;
  const [availableClinicians, setAvailableClinicians] = useState([]);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    useState(false);
  const [showSchedulingModal, setShowSchedulingModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [clinicianId, setClinicianId] = useState(
    bookingDetails?.clinician.id ?? -1
  );

  const [showIHIModal, setShowIHIModal] = useState(false);

  /* after hours test related */
  const [afterHoursTest, setafterHoursTest] = useState(false);
  useEffect(() => {
    const getAfterHoursTest = async () => {
      const afterHoursTest = await Feature.isActive("after-hours-test");
      setafterHoursTest(afterHoursTest);
    };
    getAfterHoursTest();
  }, []);

  const navigate = useNavigate();

  const bookingStatus = consultation?.asap_status || bookingDetails?.status;

  const bookingDate: false | moment.Moment =
    !!bookingDetails &&
    bookingStatus === "taken" &&
    moment.utc(bookingDetails?.start).tz(moment.tz.guess());

  const bookingDateTime =
    !!bookingDetails &&
    bookingStatus === "taken" &&
    moment.utc(bookingDetails?.start).tz(moment.tz.guess());
  const isAfterCutOffMinutes =
    bookingDateTime &&
    moment().isAfter(bookingDateTime.clone().subtract(15, "minutes"));

  const loadAvailableClinicians = async () =>
    // @ts-ignore
    consultation &&
    SchedulingAPI.getAvailableClinicians({
      consultation_id: consultation.id
    }).then((data) =>
      setAvailableClinicians(
        sortArrayByFieldsAndDescending(
          // @ts-ignore
          data,
          ["previous_clinician", "first_availability"],
          [true, false]
        )
      )
    );

  // .utc() mutates the time object provided to the function, we use clone to avoid
  // modifying the time parameter
  const convertToUtcFormat = (time: moment.Moment) =>
    time.clone().utc().toISOString();

  const displayErrorAndReload = async (message: string) => {
    setErrorMessage(message);
    setTimeout(() => navigate(0), 3000); // wait and refresh
  };

  const handleBook = async (
    selectedTime: moment.Moment,
    isRescheduling: boolean
  ) => {
    setIsLoading(true);
    const endpoint =
      // @ts-ignore
      isRescheduling && bookingDetails?.id
        ? SchedulingAPI.rescheduleBooking
        : SchedulingAPI.createScheduledBooking;

    try {
      // prevent previous booked clinician id is passed
      // 1. previous booked clinician is not available
      // 2. doesn't select any clinician, but show all clinicians
      const isClinicianAvailable = availableClinicians?.some(
        // @ts-ignore
        (clinician) => clinician.id === clinicianId
      );

      // @ts-ignore
      const response =
        consultation &&
        (await endpoint({
          consultation_id: consultation.id,
          requested_datetime: convertToUtcFormat(selectedTime), // has to be in UTC
          booking_time_zone: moment.tz.guess(),
          // @ts-ignore
          booking_id: bookingDetails?.id,
          clinician_id:
            isClinicianAvailable && clinicianId && clinicianId > -1
              ? clinicianId
              : null
        }));
      if (response?.ok) {
        setSuccessMessage("Your booking has been confirmed.");
      } else {
        const data = await response?.json();
        displayErrorAndReload(data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelBooking = async () => {
    setIsLoading(true);
    try {
      // @ts-ignore
      const response =
        consultation &&
        (await SchedulingAPI.clearBooking({
          consultation_id: consultation.id
        }));
      if (response?.ok) {
        setSuccessMessage("Your booking has been cancelled.");
      } else {
        const data = await response?.json();
        displayErrorAndReload(data?.message);
      }
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoinTheQueue = async () => {
    setIsLoading(true);
    try {
      // @ts-ignore
      const response =
        consultation &&
        (await SchedulingAPI.makeASAPBooking({
          consultation_id: consultation?.id
        }));
      if (response && response.ok) {
        setSuccessMessage(
          "You are in the queue, our clinician will contact you shortly."
        );
      } else {
        const data = await response?.json();
        displayErrorAndReload(data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestAfterHours = async () => {
    setIsLoading(true);
    if (!consultation?.id) {
      displayErrorAndReload("Please try it again!");
      return;
    }
    try {
      const verification = await PatientAPI.checkVerification();
      if (!verification?.ihi_verified_and_active) {
        setShowIHIModal(true);
        return;
      }

      const response = await SchedulingAPI.makeAfterHoursBooking(
        consultation.id
      );
      if (response.ok) {
        setTimeout(() => navigate("/"), 500);
      } else {
        displayErrorAndReload("Please try it again!");
      }
    } catch (e) {
      displayErrorAndReload("Please try it again!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnHide = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setShowSchedulingModal(false);
  };

  const hideForCancelConfirmation = () => {
    setShowCancelConfirmationModal(false);
    setShowSchedulingModal(true);
  };

  const openCancelBookingConfirmation = () => {
    setShowSchedulingModal(false);
    setShowCancelConfirmationModal(true);
  };

  useEffect(() => {
    if (showSchedulingModal) {
      setIsLoading(true);
      loadAvailableClinicians().finally(() => setIsLoading(false));
    }
  }, [showSchedulingModal]);

  const BookingRequired = () => (
    <Status
      titleVariant="danger"
      title={
        bookingStatus === "call-back"
          ? "CALL MISSED"
          : bookingStatus === "to-reschedule"
          ? "APPOINTMENT MISSED"
          : "READY FOR CONSULT"
      }
      actions={
        <>
          <Button
            onClick={() => setShowSchedulingModal(true)}
            data-testid="no-booking-button"
            css={{
              float: "right",
              width: "100%"
            }}
          >
            {bookingStatus === "call-back" || bookingStatus === "to-reschedule"
              ? "Reschedule appointment"
              : "Choose consult time"}
          </Button>
        </>
      }
    >
      <div>
        {/* @ts-ignore */}
        {bookingDetails?.formal_name && (
          <div
            css={{
              paddingRight: "24px",
              paddingBottom: "8px"
            }}
          >
            <ClinicianLayout
              clinician={{
                // @ts-ignore
                id: bookingDetails?.clinician_id,
                // @ts-ignore
                formal_name: bookingDetails?.formal_name,
                // @ts-ignore
                qualifications:
                  bookingDetails?.qualifications ||
                  bookingDetails?.clinician?.qualifications,
                // @ts-ignore
                profile_image:
                  bookingDetails?.profile_image ||
                  bookingDetails?.clinician?.user?.profile_image
              }}
              isHeader={true}
            />
          </div>
        )}
        {bookingStatus === "call-back"
          ? "We tried calling you and were unable to reach you for your consultation. We'll make another attempt to call you soon (typically within the next 30 minutes). Use the ‘Reschedule appointment’ button if you would prefer to choose a specific time for your consultation."
          : bookingStatus === "to-reschedule"
          ? "We tried calling you and were unable to reach you for your consultation. Please use the ‘Reschedule appointment’ button to choose a new time for this appointment."
          : ["telehealth-consult", "digital-health-check"].includes(slug)
          ? "You can book a time or join the queue for your telehealth appointment using the ‘Choose consult time’ button."
          : "Before we can approve your request, one of our clinicians needs to complete a call with you. You can book a time or join the queue for the next available appointment using the 'Choose consult time' button."}
      </div>
    </Status>
  );

  const ASAPBookingMade = () => (
    <Status
      title="AWAITING CONSULT"
      actions={
        <Button
          css={{
            float: "right",
            width: "100%"
          }}
          onClick={() => setShowSchedulingModal(true)}
          data-testid="asap-booking-button"
        >
          Manage my booking
        </Button>
      }
    >
      <div>
        You are currently in the queue, we will notify you when your assigned
        clinician is about to call you.
      </div>
    </Status>
  );

  const ConsultAsync = () => (
    <Status title="AWAITING REVIEW">
      <div>
        We have received your request and this will be reviewed by a clinician
        shortly. Your treatment plan will typically be issued within 24 hours
        and your clinician will contact you if they have any questions.
      </div>
    </Status>
  );

  const ScheduledBooking = () => (
    <Status
      title="APPOINTMENT BOOKED"
      actions={
        <Button
          css={{
            float: "right",
            width: "100%"
          }}
          disabled={
            bookingStatus === "taken" && !bookingDetails?.can_rebook
              ? isAfterCutOffMinutes
              : false
          }
          onClick={() => setShowSchedulingModal(true)}
          data-testid="manage-booking-button"
        >
          Manage my booking
        </Button>
      }
    >
      <div
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          paddingBottom: "8px",
          [theme.mq.md]: {
            flexDirection: "row"
          }
        })}
      >
        {/* @ts-ignore */}
        {bookingDetails?.clinician.formal_name && (
          <div
            css={(theme) => ({
              paddingRight: "24px",
              paddingBottom: "8px",
              [theme.mq.md]: {
                paddingBottom: "0px"
              }
            })}
          >
            <ClinicianLayout
              clinician={{
                // @ts-ignore
                id: bookingDetails?.clinician.id,
                // @ts-ignore
                formal_name: bookingDetails?.clinician.formal_name,
                // @ts-ignore
                qualifications:
                  bookingDetails?.qualifications ||
                  bookingDetails?.clinician?.qualifications,
                // @ts-ignore
                profile_image:
                  bookingDetails?.profile_image ||
                  bookingDetails?.clinician?.user?.profile_image
              }}
              isHeader={true}
            />
          </div>
        )}
        <div
          css={(theme) => ({
            paddingRight: "24px",
            fontSize: "14px",
            marginTop: "auto",
            marginBottom: "auto",
            paddingBottom: "8px",
            [theme.mq.md]: {
              paddingBottom: "0px"
            }
          })}
        >
          <i
            className="fa fa-calendar"
            css={{ fontSize: "12px" }}
            aria-hidden="true"
          ></i>
          <span data-testid="booking-date" css={{ paddingLeft: "4px" }}>
            {/* @ts-ignore */}
            {bookingDate?.format("dddd D MMMM, YYYY")}
          </span>
        </div>
        <div
          css={{
            paddingRight: "24px",
            fontSize: "14px",
            marginTop: "auto",
            marginBottom: "auto"
          }}
        >
          <i className="fa fa-clock-o" aria-hidden="true"></i>
          <span data-testid="booking-time" css={{ paddingLeft: "4px" }}>
            {/* @ts-ignore */}
            {bookingDate?.format("h:mm A")}
          </span>
        </div>
      </div>
      <div>
        {/* @ts-ignore */}
        Your appointment is scheduled for {moment(bookingDate).format(
          "h:mm A"
        )}{" "}
        {/* @ts-ignore */}
        on {moment(bookingDate).format("D MMM YYYY")}. Your clinician will call
        you around the time of your appointment.
      </div>
    </Status>
  );

  const AfterHoursBooking = () => (
    <Status title="AFTER-HOURS CONSULTATION">
      <div>
        Your request for an after-hours consultation has been referred to our
        partner Phenix Health. You will typically receive a phone call within an
        hour and you’ll receive an email with your booking confirmation shortly.
      </div>
    </Status>
  );

  const BookingAction = () => {
    if (isLoading) return null;
    const asapBooking = consultation?.asap_booking;
    const bookingDate = bookingDetails?.start;
    if (consultation?.status === "after_hours") {
      return <AfterHoursBooking />;
    }
    if (consultAsync) {
      return <ConsultAsync />;
    }
    if (
      asapBooking &&
      // @ts-ignore
      bookingStatus !== "call-back"
    ) {
      return <ASAPBookingMade />;
    } else if (bookingStatus === "taken" && bookingDate) {
      return <ScheduledBooking />;
    }
    return <BookingRequired />;
  };

  return (
    <>
      <BookingAction />
      <SchedulingModal
        show={showSchedulingModal}
        onHide={handleOnHide}
        bookingDetails={bookingDetails}
        onClickBook={handleBook}
        onClickCancelBooking={openCancelBookingConfirmation}
        onClickJoinTheQueue={handleJoinTheQueue}
        onClickRequestAfterHours={handleRequestAfterHours}
        successMessage={successMessage}
        errorMessage={errorMessage}
        existingBookingDetails={bookingDetails}
        product={product}
        slug={slug}
        consultationId={consultation?.id}
        clinicianId={clinicianId}
        setClinicianId={setClinicianId}
        availableClinicians={availableClinicians}
        nibTeleHealth={nibTeleHealth}
        nibMembershipNumber={nibMembershipNumber}
        arhi={arhi}
        vip={vip}
        afterHoursTest={afterHoursTest}
        membershipsStatus={membershipsStatus}
      />
      <ActionConfirmationModal
        action={"cancel your appointment"}
        onHide={hideForCancelConfirmation}
        onConfirm={async () => {
          await handleCancelBooking();
          hideForCancelConfirmation();
        }}
        show={showCancelConfirmationModal}
        confirmActionLabel={"Cancel Appointment"}
        cancelActionLabel={"Keep Appointment"}
      />
      {showIHIModal && (
        <IHIModal
          show={showIHIModal}
          setShow={setShowIHIModal}
          refreshPage={true}
          from={"afterHoursBooking"}
        />
      )}
    </>
  );
};
