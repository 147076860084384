import { ListQuestionTypeParams } from "@mh/questionnaire/src/types";
import { QuestionnaireContentComponentProps } from "..";
import { QCBaseList } from "./QCBaseList";
import { QCListProvider } from "./QCListContext";

export const QCMultiList = (props: QuestionnaireContentComponentProps) => (
  <QCListProvider
    initialOptions={
      (props.question.type_params as ListQuestionTypeParams)!.options!
    }
    initialOptionsSelection={props.initialValue?.options_selection ?? {}}
  >
    <QCBaseList
      {...props}
      checkType="checkbox"
      onSelect={(option, initialFalseState, prevSelectedOptions) => {
        const optionNewState = !prevSelectedOptions[option];
        const exclusive =
          (props.question.type_params as ListQuestionTypeParams)?.exclusive ??
          [];

        if (exclusive.includes(option) && optionNewState)
          return { ...initialFalseState, [option]: true };

        return {
          ...initialFalseState,
          ...prevSelectedOptions,
          ...exclusive.reduce((obj, key) => ({ ...obj, [key]: false }), {}),
          [option]: optionNewState
        };
      }}
    />
  </QCListProvider>
);
