import { useState } from "react";
import { Button, Form } from "react-bootstrap";

import { ListQuestionTypeParams } from "@mh/questionnaire/src/types";
import { Flex, Toast } from "@mh/components";

import { QCBaseList } from "../QCList";
import { QuestionnaireContentComponentProps } from "../..";
import { useQCListContext } from "../QCList/QCListContext";

export const MedicationList = (
  props: QuestionnaireContentComponentProps & {
    complete: (v: string[]) => void;
  }
) => {
  const { setOptions, options, setSelectedOptions, selectedOptions } =
    useQCListContext();
  const [value, setValue] = useState<string>("");

  return (
    <Flex flexDirection="column">
      <QCBaseList
        {...props}
        checkType="checkbox"
        preventUpdatePropagation={true}
        onSelect={(option, initialFalseState, prevSelectedOptions) => {
          const optionNewState = !prevSelectedOptions[option];
          const exclusive =
            (props.question.type_params as ListQuestionTypeParams)?.exclusive ??
            [];

          let selected;
          if (exclusive.includes(option) && optionNewState) {
            selected = { ...initialFalseState, [option]: true };
          } else {
            selected = {
              ...prevSelectedOptions,
              ...exclusive.reduce((obj, key) => ({ ...obj, [key]: false }), {}),
              [option]: optionNewState
            };
          }

          return selected;
        }}
      />
      <Flex style={{ marginTop: 10 }}>
        <Form.Control
          onChange={(event) => {
            const v = event.target.value;
            setValue(v);
          }}
          value={value}
          autoFocus={true}
          placeholder="Please enter other medications"
        />
        <Button
          type="submit"
          style={{ marginLeft: 5, borderRadius: 5 }}
          onClick={() => {
            if (options.includes(value)) {
              Toast.info(`You've already added ${value} to the list above`);
            }
            setOptions([...options, value]);
            setSelectedOptions({ ...selectedOptions, ...{ [value]: true } });
            setValue("");
          }}
          disabled={value === ""}
        >
          +
        </Button>
      </Flex>
      <Button
        onClick={() =>
          props.complete(
            Object.entries(selectedOptions)
              .filter(([_, selected]) => selected)
              .map(([option, _]) => option)
          )
        }
        style={{ marginTop: 10 }}
        disabled={value !== ""}
      >
        Next
      </Button>
    </Flex>
  );
};
