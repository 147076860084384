import { PatientAPI, User } from "@mh/api";
import { useEffect, useState } from "react";

type Membership = "active" | "offer" | "no_show";

export const useMembership = () => {
  const [membershipStatus, setMembershipStatus] =
    useState<Membership>("no_show");
  const [loading, setLoading] = useState(true);

  const fetchUserMembership = async () => {
    const memberships = await PatientAPI.getUserMembershipStatus();
    const activeOrLapsedMemberships = memberships.filter(
      (membership) =>
        membership.status === "active" || membership.status === "lapsed"
    );
    if (activeOrLapsedMemberships.length > 0) {
      return activeOrLapsedMemberships[0].status;
    }
    return undefined;
  };

  const fetchAllowedMember = async () => {
    const allowedMembers = await PatientAPI.getAllowedMembers();
    if (allowedMembers.length > 0) {
      const [allowedMember] = allowedMembers;
      const status =
        !allowedMember.is_revoked && !allowedMember.membership.is_revoked
          ? "active"
          : "revoked";
      return status;
    }
    return undefined;
  };

  useEffect(() => {
    const getStates = async () => {
      setLoading(true);

      const [userMembershipStatus, allowedMemberStatus] = await Promise.all([
        fetchUserMembership(),
        fetchAllowedMember()
      ]);
      if (
        userMembershipStatus === "active" ||
        allowedMemberStatus === "active"
      ) {
        setMembershipStatus("active");
      } else if (
        (userMembershipStatus === "cancelled" ||
          userMembershipStatus === undefined) &&
        (allowedMemberStatus === "revoked" || allowedMemberStatus === undefined)
      ) {
        setMembershipStatus("offer");
      }
      setLoading(false);
    };
    if (User.loggedIn()) {
      getStates();
    } else {
      setMembershipStatus("offer");
      setLoading(false);
    }
  }, []);

  if (!loading) {
    return membershipStatus;
  }
};
