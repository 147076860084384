import {
  type PatientQuestionnaire,
  type PatientQuestionnaireRepeat
} from "@mh/api";

/**
 * Checks if the provided questionnaire is an initial questionnaire and not a repeat
 *
 * @param questionnaire The questionnaire to check
 * @returns True if the questionnaire is an initial questionnaire, false otherwise
 */
export const isInitialQuestionnaire = (
  questionnaire: PatientQuestionnaire | PatientQuestionnaireRepeat
): questionnaire is PatientQuestionnaire =>
  Object.hasOwn(questionnaire, "pharmacy_status");

/**
 * Checks if the questionnaire is approved by the pharmacy
 *
 * NOTE: As the questionnaire-consultation model becomes refined, this function is intended to abstract that logic
 * into a single source of truth
 *
 * @param questionnaire The treatment which the pharmacy may have reviewed and approved
 * @returns True if the questionnaire has been approved by the pharmacy, false otherwise
 */
export const isPharmacyApproved = (questionnaire: PatientQuestionnaire) =>
  questionnaire.pharmacy_status === "approve";
