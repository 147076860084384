import { ComponentPropsWithoutRef } from "react";

export const IconMessagesFill = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 8C13 9.125 12.0938 10 11 10L6.0625 10.0312L3.46875 11.9688C3.28125 12.0938 3 11.9688 3 11.7188V10.0312L2 10C0.875 10 0 9.125 0 8V2C0 0.90625 0.875 0 2 0H11C12.0938 0 13 0.90625 13 2V8ZM18 4C19.0938 4 20 4.90625 20 6V12.0312C20 13.125 19.0938 14 18 14H17V15.7188C17 15.9688 16.6875 16.0938 16.4688 15.9375L13.875 14H9.96875C8.875 14 8 13.125 8 12V11H11C12.625 11 14 9.65625 14 8V4H18Z"
      fill="currentColor"
    />
  </svg>
);
