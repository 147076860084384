import { Conversation } from "@mh/api";
import { ConversationItem } from "./ConversationItem";

export interface ConversationsPanelProps {
  conversations: Conversation[];
  conversationId: number | null;
  setConversationId: (conversation_id: number) => void;
}

export function ConversationsPanel({
  conversations,
  conversationId,
  setConversationId
}: ConversationsPanelProps) {
  function conversationItems() {
    return conversations.map((conversation) => (
      <ConversationItem
        conversation={conversation}
        selected={conversation.id === conversationId}
        key={conversation.id}
        onClick={() => setConversationId(conversation.id)}
      />
    ));
  }
  return (
    <>
      {/** This should display on a screen md or larger */}
      <div className="card col-3 h-100 overflow-scroll px-4 py-5 border-0 d-none d-md-inline">
        {conversationItems()}
      </div>
      {/** This should display on a screen smaller than md */}
      {!conversationId && (
        // if there is no selected conversation, display the same this as above
        <div className="card h-100 overflow-scroll px-4 py-5 border-0 d-inline d-md-none">
          {conversationItems()}
        </div>
      )}
    </>
  );
}
