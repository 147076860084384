import { useState } from "react";
import { type PaginationOptions } from "@mh/api";

const DEFAULT_LIMIT = 25;

interface UsePagination extends Required<PaginationOptions> {
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
}

/**
 * Provides controlled pagination options
 * @returns Pagination values and controls of type {@link UsePagination}
 */
export const usePagination = (): UsePagination => {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);

  return {
    page,
    limit,
    setPage,
    setLimit
  };
};
