import { PropsWithChildren } from "react";
import { Theme } from "../theme";
import { Size } from "../types";

interface DisplayHeadingProps {
  /** Alignment of the heading and underline. */
  alignment?: "start" | "center" | "end";
  bold?: boolean;
  /** Size variant. */
  size?: Size;
  /** Underline color, or "none" for no underline. */
  underline?: keyof Theme["color"] | "none";
  /** Color variant. */
  variant?: keyof Theme["color"];
}

const SIZE_MAP: Record<Size, `${number}px`> = {
  sm: "18px",
  md: "20px",
  lg: "24px"
};

export const DisplayHeading = ({
  alignment = "start",
  bold = false,
  children,
  size = "md",
  underline = "secondary",
  variant = "dark"
}: PropsWithChildren<DisplayHeadingProps>) => (
  <div
    css={(theme) => ({
      display: "flex",
      flexDirection: "column",
      color: theme.color[variant],
      fontFamily: theme.font.heading,
      fontSize: SIZE_MAP[size],
      fontWeight: bold ? "600" : "500",
      lineHeight: "120%",
      marginBottom: "8px",
      alignItems: alignment
    })}
  >
    {children}
    {underline !== "none" && (
      <div
        css={(theme) => ({
          width: "75px",
          borderBottom: `2px solid ${theme.color[underline]}`,
          marginTop: "8px"
        })}
      />
    )}
  </div>
);
