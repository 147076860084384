import { ComponentPropsWithoutRef } from "react";

export const IconHome = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.8125 8.625C19.0312 8.8125 19.0312 9.125 18.8438 9.34375C18.6875 9.5625 18.375 9.5625 18.1562 9.375L17 8.34375V14.5C17 15.9062 15.875 17 14.5 17H5.5C4.09375 17 3 15.9062 3 14.5V8.34375L1.8125 9.375C1.59375 9.5625 1.28125 9.5625 1.125 9.34375C0.9375 9.125 0.9375 8.8125 1.15625 8.625L9.65625 1.15625C9.84375 0.96875 10.125 0.96875 10.3125 1.15625L18.8125 8.625ZM5.5 16H7.5V11C7.5 10.4688 7.9375 10 8.5 10H11.5C12.0312 10 12.5 10.4688 12.5 11V16H14.5C15.3125 16 16 15.3438 16 14.5V7.46875L10 2.1875L4 7.46875V14.5C4 15.3438 4.65625 16 5.5 16ZM8.5 16H11.5V11H8.5V16Z"
      fill="currentColor"
    />
  </svg>
);
