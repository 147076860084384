import { useContext } from "react";
import { Patient, PatientContext } from "@mh/api";
import { Validation } from "@mh/core";
import { PhoneField } from "@mh/components";

import { ProfileCard } from "./ProfileCard";
import { ProfileField, ProfileForm } from "./ProfileForm";
import { EmergencyContactNew } from "./QuestionnaireRepeat";

type EmergencyContactValues = Pick<
  Patient,
  "ec_first_name" | "ec_last_name" | "ec_phone"
>;

export interface EmergencyContactProps {
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
  /* value is true if it is from questionnaire */
  isQuestionnaire?: boolean | undefined;
}

export const EmergencyContact = ({
  isEditingCallback,
  isQuestionnaire
}: EmergencyContactProps) => {
  const { isFetching, data: patient, update } = useContext(PatientContext);

  return (
    <ProfileForm<EmergencyContactValues>
      title="Emergency Contact"
      id="emergency"
      initialValues={{
        ec_first_name: patient?.ec_first_name || "",
        ec_last_name: patient?.ec_last_name || "",
        ec_phone: patient?.ec_phone || ""
      }}
      isFetching={isFetching}
      onSave={async (values) =>
        await update({
          ec_first_name: values.ec_first_name,
          ec_last_name: values.ec_last_name,
          ec_phone: values.ec_phone
        })
      }
      validate={(values) => ({
        ec_first_name: Validation.strLen(
          { gtEq: 2 },
          { name: "First name" }
        ).validate(values.ec_first_name),
        ec_last_name: Validation.strLen(
          { gtEq: 2 },
          { name: "Last name" }
        ).validate(values.ec_last_name),
        ec_phone: Validation.mobile().validate(values.ec_phone)
      })}
      isEditingCallback={isEditingCallback}
      isQuestionnaire={isQuestionnaire}
    >
      {({ values, setValues, error, isEditing, id }) =>
        !isQuestionnaire ? (
          <ProfileCard.Group>
            <ProfileForm.Control
              label="First name"
              data-testid={`${id}-firstname`}
              disabled={isFetching}
              error={error?.ec_first_name}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, ec_first_name: e.currentTarget.value })
              }
              value={values.ec_first_name}
            />
            <ProfileForm.Control
              label="Last name"
              data-testid={`${id}-lastname`}
              disabled={isFetching}
              error={error?.ec_last_name}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, ec_last_name: e.currentTarget.value })
              }
              value={values.ec_last_name}
            />
            <ProfileField label={"Phone"} error={error?.ec_phone}>
              <PhoneField
                readOnly={!isEditing}
                inputTestId={`${id}-phone`}
                onChange={(value) =>
                  setValues({ ...values, ec_phone: value ?? "" })
                }
                value={values.ec_phone}
                className={
                  error?.ec_phone !== undefined ? "is-invalid" : undefined
                }
              />
            </ProfileField>
          </ProfileCard.Group>
        ) : (
          <>
            <EmergencyContactNew
              values={values}
              setValues={setValues}
              error={error}
              isEditing={isEditing}
              id={id}
              isFetching={isFetching}
            />
          </>
        )
      }
    </ProfileForm>
  );
};
