import { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { BRAND } from "@mh/core";
import { BaseQuestionnaire, Clinician } from "@mh/api";
import {
  Button,
  DatePicker,
  DatePickerProps,
  DisplayHeading,
  HorizontalDivider,
  Theme
} from "@mh/components";

import { ClinicianDropBox } from "./ClinicianDropBox";
/**
 * Check current moment is within opening hours:
 * 8:00am - 8:50pm AEST
 *
 * @returns true if within opening and closing time, otherwise false
 */
export const checkWithinOperatingHours = (): boolean => {
  const aestNow = moment.tz("Australia/Brisbane"); // Current time
  const aestOpening = moment(aestNow).set("hour", 8); // 8:00am today
  const aestClosing = moment(aestNow).set({ hour: 20, minute: 50 }); // 8:50pm today
  return aestNow.isSameOrAfter(aestOpening) && aestNow.isBefore(aestClosing);
};

interface TimePickerProps {
  onChangeSelectedTime: (time: moment.Moment) => void;
  selectedTime?: moment.Moment;
  times: moment.Moment[];
  title: string;
}

const TimePicker = ({
  onChangeSelectedTime,
  selectedTime,
  times,
  title
}: TimePickerProps) => (
  <div>
    <div
      css={(theme) => ({
        color: theme.color.dark,
        fontSize: "14px",
        fontWeight: 600,
        textTransform: "uppercase"
      })}
    >
      {title}
    </div>
    <div
      css={{
        display: "flex",
        flexFlow: "row wrap",
        ">*": {
          width: "90px",
          margin: "6px 4px"
        }
      }}
    >
      {times.map((time) => (
        <Button
          data-testid="time-picker-time-button"
          key={time.toString()}
          onClick={() => onChangeSelectedTime(time)}
          size="sm"
          variant={
            selectedTime && selectedTime.isSame(time)
              ? "primary"
              : "primary-outline"
          }
          border="rounded"
        >
          {time.format("h:mm a")}
        </Button>
      ))}
    </div>
  </div>
);

interface ManageBookingProps {
  /**
   * Times available for selection. Assumed to be in ascending order.
   */
  availableTimes: moment.Moment[];
  bookingDetails: BaseQuestionnaire["booking_event"] | null;
  isLoading?: boolean;
  onClickBook: (selectedTime: moment.Moment) => void;
  onClickCancel?: () => void;
  /**
   * Called when the user select's to join the queue.
   * If undefined, will not render the "Join the queue" container.
   */
  onClickJoinTheQueue?: () => void;
  onClickRequestAfterHours?: () => void;
  liveWaitTime: number;
  waitTimeMessage: string;
  product?: string;
  clinicianId?: number;
  setClinicianId: (id: number) => void;

  availableClinicians?: Clinician[];

  // nib general telehealth enable flag, it will be removed when nib general telehealth is launched.
  nibTeleHealth: boolean;
  // if it is nib member
  nibMembershipNumber: number | undefined;
  // for arhi patient
  arhi: boolean;
  // for after hours button test
  afterHoursTest?: boolean;
}

const boxStyle = (theme: Theme) => ({
  padding: "16px",
  background: "#F8F8F8",
  borderRadius: "8px",
  marginTop: "16px",
  marginLeft: "24px",
  marginRight: "24px",
  [theme.mq.md]: {
    marginTop: "24px"
  }
});

const titleStyle = (theme: Theme) => ({
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "150%",
  color: theme.color.dark
});

const txtStyle = {
  width: "100%",
  maxWidth: "400px",
  fontSize: "14px",
  lineHeight: "135%"
};

export const ManageBooking = ({
  availableTimes,
  bookingDetails,
  isLoading = false,
  onClickBook,
  onClickCancel,
  onClickJoinTheQueue,
  onClickRequestAfterHours,
  liveWaitTime,
  clinicianId,
  setClinicianId,
  availableClinicians,
  waitTimeMessage,
  product,
  nibTeleHealth,
  nibMembershipNumber,
  arhi,
  afterHoursTest
}: ManageBookingProps) => {
  const [selectedMonth, setSelectedMonth] =
    useState<DatePickerProps["selectedMonth"]>();
  const [selectedDay, setSelectedDay] =
    useState<DatePickerProps["selectedDay"]>();
  const [selectedTime, setSelectedTime] = useState<moment.Moment>();
  const [liveWaitTimeRepr, setLiveWaitTimeRepr] = useState<string>();

  /** Has booking if they have an existing booking that is not an ASAP booking. */
  const hasBooking =
    // @ts-ignore
    bookingDetails && bookingDetails.status && bookingDetails.start;

  useEffect(() => {
    setSelectedMonth(availableTimes.at(0));
    setSelectedDay(availableTimes.at(0));
  }, [availableTimes]);

  useEffect(() => {
    const liveWaitInMinutes = liveWaitTime;
    if (!liveWaitInMinutes) {
      setLiveWaitTimeRepr("");
    } else {
      if (liveWaitInMinutes > 60) {
        setLiveWaitTimeRepr("more than 1 hour");
      } else {
        setLiveWaitTimeRepr(
          `${liveWaitInMinutes} minute${liveWaitInMinutes !== 1 ? "s" : ""}`
        );
      }
    }
  });
  /**
   * Get the minimum and maximum days in the available times, and calculate
   * which days inbetween have no available time slots (and thus should be disabled).
   */
  const [minDay, maxDay, disabledDays] = useMemo(() => {
    const minDay = availableTimes.at(0);
    const maxDay = availableTimes.at(-1);

    const disabledDays = [
      ...Array(minDay && maxDay ? maxDay.diff(minDay, "days") + 1 : 0).keys()
    ]
      .map((index) => moment(minDay).add(index, "days"))
      .filter((day) => !availableTimes.find((time) => time.isSame(day, "day")));
    return [minDay, maxDay, disabledDays];
  }, [availableTimes]);

  /**
   * Convert and split the morning/afternoon time slots for the currently selected day.
   */
  const [morningTimes, afternoonTimes] = useMemo<
    [moment.Moment[], moment.Moment[]]
  >(() => {
    if (!selectedDay) {
      /** If there is no selected day, return no times. */
      return [[], []];
    }

    const selectableTimes = availableTimes.filter((time) =>
      time.isSame(selectedDay, "day")
    );

    return [
      selectableTimes.filter((time) => time.hour() < 12),
      selectableTimes.filter((time) => time.hour() >= 12)
    ];
  }, [availableTimes, selectedDay]);

  return (
    <>
      {onClickJoinTheQueue && (
        <div css={[boxStyle]}>
          <DisplayHeading bold size="sm" underline="none" variant="primary">
            <span css={[titleStyle]}>Join the queue</span>
          </DisplayHeading>
          <div
            css={(theme) => ({
              display: "none",
              [theme.mq.md]: {
                display: "block",
                borderBottom: "1px solid #D7D7D7",
                marginBottom: "16px"
              }
            })}
          ></div>
          <div
            css={(theme) => ({
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "16px",
              [theme.mq.md]: {
                flexFlow: "row"
              }
            })}
          >
            <span css={[txtStyle]} data-testid="asap-message-text">
              {!checkWithinOperatingHours()
                ? "Our clinicians will be back online at 8am AEDT. Feel free to join the queue now or come back later."
                : waitTimeMessage ||
                  "Join the queue to be seen by a clinician as soon as possible. You will receive a call from 07 3185 5552"}
            </span>
            <div
              css={(theme) => ({
                width: "100%",
                textAlign: "center",
                [theme.mq.md]: {
                  width: "250px"
                }
              })}
            >
              <Button
                css={(theme) => ({
                  marginBottom: "16px",
                  [theme.mq.lg]: {
                    marginBottom: "5px"
                  }
                })}
                disabled={isLoading}
                fullWidth
                onClick={onClickJoinTheQueue}
              >
                Join the queue
              </Button>
              {liveWaitTimeRepr && checkWithinOperatingHours() && (
                <span css={{ fontSize: "14px", fontWeight: 500 }}>
                  Estimated wait time: {liveWaitTimeRepr}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {nibTeleHealth &&
        (nibMembershipNumber || arhi) &&
        onClickRequestAfterHours &&
        (!checkWithinOperatingHours() || afterHoursTest) &&
        product === "Telehealth Consult" && (
          <div
            css={[
              boxStyle,
              {
                button: {
                  background: "#37514D",
                  border: "unset"
                }
              }
            ]}
          >
            <DisplayHeading bold size="sm" underline="none" variant="primary">
              <span css={[titleStyle]}>
                Request an urgent after-hours appointment
              </span>
            </DisplayHeading>
            <div
              css={(theme) => ({
                display: "none",
                [theme.mq.md]: {
                  display: "block",
                  borderBottom: "1px solid #D7D7D7",
                  marginBottom: "16px"
                }
              })}
            ></div>
            <div
              css={(theme) => ({
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "16px",
                [theme.mq.md]: {
                  flexFlow: "row"
                }
              })}
            >
              <span css={[txtStyle]} data-testid="asap-message-text">
                Not suitable for medical emergencies - if you have a medical
                emergency call 000 and ask for an ambulance.
              </span>
              <div
                css={(theme) => ({
                  width: "100%",
                  [theme.mq.md]: {
                    width: "250px"
                  }
                })}
              >
                <Button
                  disabled={isLoading}
                  fullWidth
                  variant="secondary"
                  className="submitbtcolor"
                  onClick={onClickRequestAfterHours}
                >
                  Request after-hours doctor
                </Button>
              </div>
            </div>
          </div>
        )}
      {!product || product !== "Medical Certificates" ? (
        <div
          css={[
            boxStyle,
            {
              background: "#FFF",
              paddingBottom: "0px"
            }
          ]}
        >
          <DisplayHeading bold size="sm" underline="none" variant="dark">
            <div
              css={{
                marginBottom: "8px"
              }}
            >
              {hasBooking
                ? "Reschedule your appointment"
                : "Book an appointment"}
            </div>
          </DisplayHeading>
          <HorizontalDivider size={"sm"} />
          <>
            <div
              className="d-flex flex-md-row flex-column justify-content-between"
              css={{ marginTop: "16px" }}
            >
              <div
                css={(theme) => ({
                  marginTop: "auto",
                  marginBottom: "8px",
                  [theme.mq.md]: {
                    marginBottom: "auto"
                  }
                })}
              >
                Select a clinician (optional)
              </div>
              <div>
                <ClinicianDropBox
                  availableClinicians={availableClinicians}
                  clinicianId={clinicianId}
                  setClinicianId={setClinicianId}
                />
              </div>
            </div>
            <HorizontalDivider />
          </>
          <div
            css={(theme) => ({
              display: "flex",
              flexFlow: "column nowrap",
              alignItems: "center",
              gap: "16px",
              width: "100%",
              [theme.mq.md]: {
                flexFlow: "row nowrap",
                alignItems: "start"
              }
            })}
          >
            <DatePicker
              disabled={availableTimes.length === 0}
              selectedMonth={selectedMonth}
              onChangeSelectedMonth={setSelectedMonth}
              selectedDay={selectedDay}
              onChangeSelectedDay={setSelectedDay}
              minDay={minDay}
              maxDay={maxDay}
              disabledDays={disabledDays}
            />
            <div css={{ width: "100%" }}>
              <div
                css={(theme) => ({
                  borderRadius: "8px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: theme.color.dark,
                  fontSize: "18px",
                  fontWeight: 600,
                  marginBottom: "16px",
                  textAlign: "center",
                  backgroundColor: `${
                    BRAND === "hubhealth"
                      ? `${theme.color.primaryLight}`
                      : `${theme.color.background}`
                  }`,
                  [theme.mq.md]: {
                    paddingBottom: "8px"
                  }
                })}
              >
                {selectedDay &&
                  moment(selectedDay).isValid() &&
                  moment(selectedDay).format("dddd Do MMM, YYYY")}
              </div>
              {morningTimes.length > 0 && (
                <TimePicker
                  onChangeSelectedTime={setSelectedTime}
                  selectedTime={selectedTime}
                  times={morningTimes}
                  title="Morning"
                />
              )}
              <HorizontalDivider size={"md"} />
              {afternoonTimes.length > 0 && (
                <TimePicker
                  onChangeSelectedTime={setSelectedTime}
                  selectedTime={selectedTime}
                  times={afternoonTimes}
                  title="Afternoon"
                />
              )}
              {morningTimes.length === 0 && afternoonTimes.length === 0 && (
                <p>No available time slots for this day.</p>
              )}
              <>
                <HorizontalDivider size={"md"} />
                <div
                  css={(theme) => ({
                    display: "none",
                    [theme.mq.md]: {
                      display: "flex",
                      gap: "16px"
                    }
                  })}
                >
                  <Button
                    data-testid="scheduled-booking-button"
                    disabled={isLoading || !selectedTime}
                    fullWidth
                    onClick={() => onClickBook(selectedTime!)}
                    variant="primary"
                  >
                    Book
                  </Button>
                </div>
              </>
            </div>
          </div>
        </div>
      ) : (
        <div css={[boxStyle]}>
          <span data-testid="med-cert-not-bookable" css={[txtStyle]}>
            As medical certificates are time sensitive, we don’t currently
            support bookings. We prioritise medical certificates over other
            conditions so please join the queue to be seen ASAP.
          </span>
        </div>
      )}
      {hasBooking && (
        <div css={[boxStyle]}>
          <DisplayHeading bold size="sm" underline="none" variant="primary">
            <span css={[titleStyle]}>Cancel your appointment</span>
          </DisplayHeading>
          <div
            css={(theme) => ({
              display: "flex",
              flexFlow: "column",
              justifyContent: "space-between",
              alignItems: "start",
              gap: "16px",
              [theme.mq.md]: {
                flexFlow: "row"
              }
            })}
          >
            <span css={[txtStyle]}>
              By cancelling this appointment you confirm that you no longer
              require this consultation.
            </span>
            <Button
              css={(theme) => ({
                width: "100%",
                [theme.mq.md]: {
                  width: "250px"
                }
              })}
              variant="primary-outline"
              onClick={onClickCancel}
            >
              Cancel appointment
            </Button>
          </div>
        </div>
      )}
      {(!product || product !== "Medical Certificates") && (
        <div
          css={(theme) => ({
            marginTop: "auto",
            backgroundColor: "white",
            position: "sticky",
            bottom: "0",
            paddingBottom: "24px",
            zIndex: "1",
            [theme.mq.md]: {
              display: "none"
            }
          })}
        >
          <Button
            data-testid="scheduled-booking-button"
            disabled={isLoading || !selectedTime}
            fullWidth
            onClick={() => onClickBook(selectedTime!)}
            variant="primary"
          >
            Book
          </Button>
        </div>
      )}
    </>
  );
};
