interface CustomErrorMessageMapType {
  [key: string]: {
    [key: string]: {
      [key: string]: JSX.Element;
    };
  };
}

const CustomErrorMessageMap: CustomErrorMessageMapType = {
  dob: {
    weight_management: {
      maxAge: (
        <div>
          <h2 className="qtitle text-center header-font pb-3">
            Sorry, we are unable to assist you today.
          </h2>
          <p>
            As you explore your health and weight goals, your wellbeing is our
            top priority. It’s important to note that some weight management
            medications have varying effects among different age groups.
            Clinical prescribing guidelines advise exercising caution and, in
            some cases, deem certain weight management medications unsuitable
            for individuals over the age of 75 years old.
          </p>
          <p>
            Unfortunately, we are unable to proceed with your weight management
            questionnaire as you have indicated that you are over the age of 75
            years old. Medication safety is paramount, as certain medications
            can influence your metabolism and muscle preservation.
          </p>
          <p>
            We would highly encourage you to seek guidance from your regular
            General Practitioner (GP). Your GP can provide personalised advice,
            taking into account your medical history, current medications and
            specific needs to tailor a health-first treatment plan which
            incorporates regular physical activity, dietary adjustments and
            lifestyle supports to help you reach your health and wellbeing
            goals. We wish you all the very best.
          </p>
        </div>
      ),
      minAge: (
        <div>
          <h2 className="qtitle text-center header-font pb-3">
            Sorry, we are unable to assist you today.
          </h2>
          <p>
            As you explore your health and weight goals, your wellbeing is our
            top priority. It’s important to note that some weight management
            medications have varying effects among different age groups.
            Clinical prescribing guidelines advise exercising caution and, in
            some cases, deem certain weight management medications unsuitable
            for individuals under the age of 18 years old.
          </p>
          <p>
            Unfortunately, we are unable to proceed with your weight management
            questionnaire as you have indicated that you are under the age of 18
            years old. Medication safety is paramount, as certain medications
            can influence your metabolism and muscle development.
          </p>
          <p>
            We would highly encourage you to seek guidance from your regular
            General Practitioner (GP). Your GP can provide personalised advice,
            taking into account your medical history, current medications and
            specific needs to tailor a health-first treatment plan which
            incorporates regular physical activity, dietary adjustments and
            lifestyle supports to help you reach your health and wellbeing
            goals. We wish you all the very best.
          </p>
        </div>
      )
    }
  }
};

interface GenericCustomEndPageProps {
  type: string;
  code: string;
  messageType: string;
}

interface DobEndPageProps {
  code: string;
  messageType: string;
}

const GenericCustomEndPage = (props: GenericCustomEndPageProps) =>
  CustomErrorMessageMap[props.type][props.code][props.messageType];

export const DobCustomEndPage = (props: DobEndPageProps) => (
  <GenericCustomEndPage
    type={"dob"}
    code={props.code}
    messageType={props.messageType}
  />
);

export const IsCustomEndPage = (type: string, code: string): boolean =>
  !!(CustomErrorMessageMap[type] && CustomErrorMessageMap[type][code]); // to avoid Unnecessary use of boolean literals in conditional expression error by eslint
