import { SafeURLSearchParams } from "@mh/core";
import { API } from "../api";
import { User } from "../user";
import { createRequest, PaginatedResponse, extractPagination } from "../utils";
import type {
  Product,
  LinkedProduct,
  Category,
  Option,
  TinyProductWithImages,
  ProductAvailability,
  CategoryPortalImage,
  ProductRecommendation
} from "../shop/catalogue";
import type { Price } from "../shop/partner";

import { ProductQueryParams, BulkPriceResponse } from "./types";

export interface PaginationOptions {
  page?: number;
  limit?: number;
}

/** Options to pass into the getProductPrice API function */
interface GetProductPriceOptions {
  /**
   * Sets the persisted_pricing query param. When not set, the request is sent unauthenticated.
   * Attempts to retrieve the persisted price for the product, based on the patient's subscription.
   */
  persistedPricing?: boolean;
  consultReferrerCode?: string;
}

export class OscarAPI {
  static getProducts = extractPagination(
    createRequest<
      PaginatedResponse<LinkedProduct>,
      undefined,
      {},
      PaginationOptions & Partial<ProductQueryParams>
    >("/shop/api/products/")
  );

  /**
   * Loads products at the given paginated position, with optional filters
   * @param params Product endpoint URL query parameters
   * @param paginationOptions Pagination options for the product URL endpoint, with non-undefined attributes
   *  of type {@link PaginationOptions}
   * @returns A {@link PaginatedResponse} of type {@link LinkedProduct}
   */
  static getProductsPaginated = async (
    {
      structure,
      orderByTitle,
      contraindications,
      categories,
      search,
      productClass,
      orderByNumPurchases
    }: Omit<ProductQueryParams, "category"> & { categories?: number[] },
    { page, limit }: Required<PaginationOptions>
  ): Promise<PaginatedResponse<LinkedProduct>> => {
    const queryParams = new SafeURLSearchParams({
      page: page.toString(),
      limit: limit.toString(),
      orderByTitle: orderByTitle?.toString() || "false",
      orderByNumPurchases: orderByNumPurchases?.toString() || "false"
    });

    if (contraindications) {
      queryParams.set("contraindications", contraindications.join(","));
    }

    if (categories) {
      // Append category IDs to the URL, if any are provided
      for (const categoryId of categories) {
        queryParams.append("category", categoryId.toString());
      }
    }

    if (structure) {
      // Append product structures to the URL, if any were provided
      for (const productStructure of structure) {
        queryParams.append("structure", productStructure);
      }
    }

    if (search) queryParams.set("search", search);

    if (productClass) queryParams.set("product_class", productClass);

    const url = `/shop/api/products/?${queryParams.toString()}`;

    let api = API.url(url);

    const isLoggedIn = User.loggedIn();

    if (!isLoggedIn) {
      // Perform an unauthenticated request if the user is not logged in
      api = api.unauthenticated();
    }

    return api.get().then((r) => r.json());
  };

  /**
   * Retrieves recommended products for a category
   *
   * @param categoryId The ID of the category
   * @returns A list of products of type {@link TinyProductWithImages} - the recommended products
   */
  static getRecommendedProducts = async (
    categoryId: number
  ): Promise<TinyProductWithImages[]> =>
    API.url(`/shop/api/categories/${categoryId}/recommended-products/`)
      .cache()
      .get()
      .then((r) => r.json());

  /**
   * Retrieves recommended products for to be upsold on a per-product basis
   *
   * @param productId The ID of the category
   * @param clinicianRecommendedOnly If true, only loads clinician recommended upselling products. If false or
   *   undefined, loads non-clinician recommended products
   * @returns A list of products of type {@link TinyProductWithImages} - the recommended products
   */
  static getProductRecommendations = async (
    productId: number,
    clinicianRecommendedOnly?: boolean
  ): Promise<ProductRecommendation[]> =>
    API.url(
      `/shop/api/products/${productId}/recommended-products/?is_clinician_recommended=${
        clinicianRecommendedOnly ? "true" : "false"
      }`
    )
      .cache()
      .get()
      .then((r) => {
        if (!r.ok) throw r;

        return r.json();
      });

  static getProduct = (productId: number): Promise<Product> =>
    API.url(`/shop/api/products/${productId}/`)
      .unauthenticated()
      .get()
      .then((r) => r.json());

  static getCategories = extractPagination(
    createRequest<
      PaginatedResponse<Category>,
      undefined,
      {},
      { forceAll?: boolean; nameLike?: string; otc?: boolean }
    >("/shop/api/categories/")
  );

  static getCategory = (id: number): Promise<Category> =>
    API.url(`/shop/api/categories/${id}/`)
      .get()
      .then((r) => r.json());

  static getCategoryPortalImage = (
    brandName: string
  ): Promise<CategoryPortalImage[]> =>
    API.url(`/api/v2/category-portal-images/?brand=${brandName}`)
      .get()
      .then((r) => r.json());

  /**
   * Retrieves the price for a Product
   * @param productId The ID of the Product whose price will be retrieved
   * @param options Options of type {@link GetProductPriceOptions} to pass into the API call, for instance whether
   * persisted pricing should be used
   * @returns A {@link Promise} of type {@link Price}
   */
  static getProductPrice = async (
    productId: number,
    options?: GetProductPriceOptions
  ): Promise<Price> => {
    const extradata: { [key: string]: any } = {};
    if (options?.persistedPricing)
      extradata.persisted_pricing = options.persistedPricing;

    if (options?.consultReferrerCode)
      extradata.consult_referrer_code = options.consultReferrerCode;

    const queryParams = new SafeURLSearchParams(extradata);

    let apiCall = API.url(
      `/shop/api/products/${productId}/price/?${queryParams.toString()}`
    );

    if (!options?.persistedPricing) {
      // This can be used at the start of the questionnaire
      apiCall = apiCall.unauthenticated();
    }

    return apiCall
      .cache()
      .get()
      .then((r) => r.json());
  };

  static getProductAvailability = async (
    productId: number
  ): Promise<ProductAvailability> =>
    API.url(`/shop/api/products/${productId}/availability/`)
      .unauthenticated()
      .cache()
      .get()
      .then((r) => {
        if (!r.ok) {
          throw new Error(`getProductAvailability error: status=${r.status}`);
        }

        return r.json();
      });

  /**
   * Retrieves the prices for multiple products.
   * This tends to be faster than calling {@link getProductPrice} multiple times.
   * @param productIds The IDs of the Products whose prices will be retrieved
   * @returns A {@link Promise} of type {@link BulkPriceResponse[]}
   */
  static getProductPricesBulk = async (
    productIds: number[]
  ): Promise<BulkPriceResponse[]> =>
    API.url(`/shop/api/products/bulk-price/?ids=${productIds.join(",")}`)
      .unauthenticated()
      .cache()
      .get()
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
        throw new Error(`Error fetching bulk prices. status=${r.status}`);
      })
      .then((data) => data.results);

  /**
   * Consultation
   */

  /**
   * Retrieves a consultation's product from a given category
   *
   * @param categorySlug The slug of a category
   * @returns A {@link Promise} of type {@link Product}
   */
  static getConsultationProduct = async (
    categorySlug: string
  ): Promise<Product> =>
    API.url(
      `/shop/api/consultation-product/?${new SafeURLSearchParams({
        category: categorySlug
      })}`
    )
      .unauthenticated()
      .get()
      .then((r) => r.json());

  /**
   * Retrieves a renewal consultation's product from a given category
   *
   * @param categorySlug The slug of a category
   * @returns A {@link Promise} of type {@link Product}
   */
  static getRenewalConsultationProduct = async (
    categorySlug: string
  ): Promise<Product> =>
    API.url(
      `/shop/api/renewal-consultation-product/?${new SafeURLSearchParams({
        category: categorySlug
      })}`
    )
      .unauthenticated()
      .get()
      .then((r) => r.json());

  /**
   * Retrieves a list of membership products
   *
   * @returns A {@link Promise} of type {@link Product}
   */
  static getMembershipProducts = async (): Promise<Product[]> =>
    API.url("/shop/api/products/membership/")
      .unauthenticated()
      .get()
      .then((r) => r.json());

  /**
   * Options
   */

  static getOptions = async (): Promise<PaginatedResponse<Option>> =>
    API.url("/shop/api/options/")
      .get()
      .then((r) => r.json());
}
