import { useState } from "react";
import { toast } from "react-toastify";
import { PatientAPI, PatientSubscriptionScript } from "@mh/api";
import { Button, IconMessagesFill } from "@mh/components";
import { ContactUsModal } from "@mh/messaging";

import { Status } from "./Status";

interface CancelStatusProps {
  onChange: () => void;
  subscription: PatientSubscriptionScript;
}

export const CancelStatus = (props: CancelStatusProps) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const handleTreatmentActive = async () => {
    try {
      await PatientAPI.updatePatientSubscriptionScript(props.subscription.id, {
        status: "active"
      });
      toast.success("Your treatment plan has been activated.");
      props.onChange();
    } catch (error) {
      if (error instanceof Response && error.status === 400) {
        const errorData = await error.json();
        if (
          typeof errorData === "object" &&
          Array.isArray(errorData.supply_expire) &&
          errorData.supply_expire.length
        ) {
          toast.error(errorData.supply_expire[0]);
          return;
        }
      }
      toast.error("There was an error updating your treatment.");
    }
  };

  return (
    <Status
      actions={
        <Button css={{ width: "100%" }} onClick={handleTreatmentActive}>
          Re-enable treatment
        </Button>
      }
      title="CANCELLED"
      titleVariant="danger"
    >
      <p>
        Your treatment has been cancelled. Use the &apos;Re-enable
        treatment&apos; button to continue with your previous treatment. If you
        have any queries regarding your treatment or would like to request a
        change to your treatment plan, please contact our{" "}
        <span
          css={{
            cursor: "pointer",
            textDecoration: "none",
            fontWeight: "600",
            color: "#000"
          }}
          onClick={() => setShowContactUsModal(true)}
        >
          Patient Success team
        </span>
        <span css={{ paddingLeft: "4px" }}>
          <IconMessagesFill
            css={(theme) => ({
              width: "14px",
              height: "14px",
              color: theme.color.primary
            })}
          />
        </span>
      </p>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </Status>
  );
};
