export const NotificationText = ({ text }: { text: string }) => (
  <div
    css={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FF0018",
      textAlign: "center",
      fontSize: "50%",
      color: "white",
      borderRadius: "3px",
      padding: "0px 4px"
    }}
    data-testid="notification-text"
  >
    {text}
  </div>
);
