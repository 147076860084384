import { Accordion, Card, Form, Spinner } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { RemoteValidation, Sentry } from "@mh/core";
import {
  checkWithinOperatingHours,
  RadioToggle,
  MedicationSearch,
  PhoneInput,
  OfferTreatments,
  hideThankButton,
  getOfferProductText,
  SplashScreen,
  Toast,
  Tooltip,
  QuestionnairePaymentType,
  QueueAppointmentTime
} from "@mh/components";

import END from "../../questionnaires/end";
import jQuery from "jquery";
import moment from "moment";
import queryString from "query-string";
import { ExpandableDiscountCode } from "../promocode/ExpandableDiscountCode";
import LocationInput from "../LocationInput/LocationInput";
import { LocationAutocomplete } from "@mh/components";
import { getOptionConditionData, isNextButtonDisabled } from "./utils";
import { makeReadableAddress } from "../../utils";
import { Feature, User, PatientAPI, OscarAPI } from "@mh/api";
import { nibInternationalSearchParam } from "@mh/core/src/constants";
import { QuestionnaireDatePicker } from "./QuestionnaireDatePicker";
import { MedicationSelectBox } from "./MedicationSelectBox";
import { MedicationRadioBox } from "./MedicationRadioBox";
import { NibInternational } from "./NibInternational";
import { QuestionnairePersonalDetails } from "./QuestionnairePersonalDetails";
import { ClinicalInfo } from "../../components/forms/ClinicalInfo";
import { SubmitButtonBlock } from "./paymentBlock/SubmitButtonBlock";
import { PaymentDetailBlock } from "./paymentBlock/PaymentDetailBlock";
import { MedicationBox } from "./MedicationBox";
import { BloodPressureQuestion } from "./BloodPressureQuestion";
import {
  EscriptProductSelector,
  isValidScriptStatus
} from "./escriptProductSelector/EscriptProductSelector";
import { NewPaymentPage } from "./NewPaymentPage";
import { EmailAndNameQuestion } from "./EmailAndNameQuestion";
import { SingleEmailQuestion } from "./SingleEmailQuestion";
import { QuestionnaireMedicare } from "./QuestionnaireMedicare";
import { QuestionnaireIHI } from "./QuestionnaireIHI";
import { EmployeeHealthCheckSplashPage } from "./EmployeeHealthCheckSplashPage";
import { EmployeeHealthCheckScorePage } from "./EmployeeHealthCheckScorePage";

import CCards from "../creditcard/creditcard";

function checkExtraCond(cond, data) {
  let count = cond.filter(
    (c) => data[c.name] && data[c.name] === c.value
  )?.length;
  cond.map((c) => {
    if (data[c.name] && data[c.name] === c.value) {
      console.log(c.name + " " + c.value);
    }
  });
  return count === cond.length ? true : false;
}

function setGridTemplate(contents_, info, contentclass_ = "") {
  let contentclass = contentclass_ ? contentclass_ : "row bggray";
  let contents__ = [];
  let contents2__ = [];
  let iscontent = true;
  let is2nd = false;
  let pclass = false;
  for (let i = 0; i < contents_.length; i++) {
    const contentlist = contents_[i];

    if (contentlist.hideMembership) continue;

    if (contentlist?.excludeproduct?.includes(info.productenv.code)) continue;

    if (contentlist.type && contentlist.type === "card") {
      is2nd = true;
      continue;
    }
    let depth_1 = [];
    for (let j = 0; j < contentlist.content.length; j++) {
      const content = contentlist.content[j];
      if (
        content.product ||
        content.extracond ||
        content.excludeproduct ||
        content.hide
      ) {
        if (
          content.product &&
          info.productenv &&
          content.product !== info.productenv.name.toLowerCase() &&
          content.product !== info.productenv.code
        ) {
          continue;
        }
        if (
          content.excludeproduct &&
          info.productenv &&
          content.excludeproduct?.includes(info.productenv.code)
        ) {
          continue;
        }
        if (content.extracond && !checkExtraCond(content.extracond, info)) {
          continue;
        }
        if (
          content.hide &&
          info.hasOwnProperty(content.hide.name) &&
          ((content.hide.value === "yes" && info[content.hide.name]) ||
            (content.hide.value === "no" && !info[content.hide.name]))
        ) {
          continue;
        }
      }

      if (content?.pclass_) {
        pclass = content.pclass_;
      }

      let innerContent = content.type ? (
        content.type === "img" ? (
          <img
            src={info.Base.getRawDatafromText(content.path, null, info)}
          ></img>
        ) : content.type === "hr" ? (
          <hr />
        ) : content.type === "text" ? (
          content.tag_ ? (
            content.tag_ === "h1" ? (
              <h1 className={content.class_ ? content.class_ : ""}>
                {info.Base.getDatafromText(
                  content.text,
                  null,
                  info,
                  content.class_ ? content.class_ : ""
                )}
              </h1>
            ) : content.tag_ === "h2" ? (
              <h2 className={content.class_ ? content.class_ : ""}>
                {info.Base.getDatafromText(
                  content.text,
                  null,
                  info,
                  content.class_ ? content.class_ : ""
                )}
              </h2>
            ) : content.tag_ === "h3" ? (
              <h3 className={content.class_ ? content.class_ : ""}>
                {info.Base.getDatafromText(
                  content.text,
                  null,
                  info,
                  content.class_ ? content.class_ : ""
                )}
              </h3>
            ) : content.tag_ === "h4" ? (
              <h4 className={content.class_ ? content.class_ : ""}>
                {info.Base.getDatafromText(
                  content.text,
                  null,
                  info,
                  content.class_ ? content.class_ : ""
                )}
              </h4>
            ) : (
              info.Base.getDatafromText(content.text, null, info)
            )
          ) : (
            info.Base.getDatafromText(content.text, null, info)
          )
        ) : (
          ""
        )
      ) : content.text ? (
        info.Base.getDatafromText(content.text)
      ) : (
        ""
      );

      if (content.outerclass_) {
        depth_1.push(
          <div key={j} className={content.class_ ? content.class_ : ""}>
            <div className={content.outerclass_}>{innerContent}</div>
          </div>
        );
      } else {
        depth_1.push(
          <div key={j} className={content.class_ ? content.class_ : ""}>
            {innerContent}
          </div>
        );
      }
    }

    if (!is2nd) {
      if (contentlist?.outerclass_) {
        contents__.push(
          <div
            key={i}
            className={pclass && pclass !== "" ? pclass : contentlist?.class_}
          >
            <div className={contentlist?.outerclass_}>{depth_1}</div>
          </div>
        );
      } else {
        contents__.push(
          <div
            key={i}
            className={pclass && pclass !== "" ? pclass : contentlist?.class_}
          >
            {depth_1}
          </div>
        );
      }
    } else {
      contents2__.push(
        <div
          key={i}
          className={pclass && pclass !== "" ? pclass : contentlist?.class_}
        >
          {depth_1}
        </div>
      );
    }
  }
  return { contentclass, contents__, iscontent, contents2__ };
}

const dropDownChange = (param, setBrandPill) => (e) => {
  e?.preventDefault();
  setBrandPill(param);
};

class Questionnairetemplate {
  constructor() {
    this.displayform = this.displayform.bind(this);
  }

  /**
   * Renders the current questionnaire question
   * @param {{
   *   isInitDone?: boolean;
   *   brand_pill?: import("./utils").ProductWithPrice;
   *   price?: string;
   *   activeVouchers: import("@mh/api/src/shop/voucher/voucher").VoucherDiscount[];
   *   discount: import("@mh/api/src/oscar/basket/types").BasketPricing | null;
   *   applyVoucher: (voucher: string) => void;
   *   voucherError: string | null;
   * }} info The questionnaire data and other potential patient-related information. The typing for this info is not
   *   extensive and more properties may exist.
   * @returns The rendered question
   */
  displayform(info) {
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [membershipFee, setMembershipFee] = useState("");
    const locationInputRef = useRef(null);

    const fetchMembershipFee = async () => {
      const membershipProducts = await OscarAPI.getMembershipProducts();
      const membershipProduct = membershipProducts.find(
        (product) => product.slug === "membership-fee-monthly"
      );
      if (membershipProduct) {
        const price = await OscarAPI.getProductPrice(membershipProduct.id);
        setMembershipFee(price.incl_tax);
      }
    };

    useEffect(() => {
      if (info.membershipsStatus !== "active") {
        fetchMembershipFee();
      }
    }, [info.membershipsStatus]);

    const [address, setAddress] = useState({
      addressLine1: "",
      addressLine2: "",
      suburb: "",
      state: "",
      postcode: ""
    });

    // This function will be used as the callback for both components
    const handleFieldsFilledChange = (filled) => {
      setIsAddressValid(filled);
    };

    if (!info.isInitDone) return false;
    info.Base.logger(
      info.Base.Config().STATE,
      "templatequestionnaire",
      "displayform",
      { info }
    );
    if (info.data_) return info.data_;
    if (
      !info.questionnaire ||
      !Array.isArray(info.questionnaire) ||
      info.questionnaire.length === 0
    )
      return;

    let isStickybt = false;
    let subindex_ = info.subindex > 1 ? info.subindex - 1 : 0;
    let currentquestion_ =
      info.questionnaire[info.questionnaire.length - 1 - subindex_];
    //check current ga_id and if not update and trigger gtm
    if (
      info.by !== "repeat" &&
      currentquestion_.ga_id &&
      (!info.curGaid || info.curGaid !== currentquestion_.ga_id)
    ) {
      info.setCurGaid(currentquestion_.ga_id);
      info.Base.gtmpro({
        curView: true,
        questionnaire: currentquestion_,
        productenv: info.productenv,
        patientprospectid: info.patientprospectid,
        patientid: info.patientid
      });
    } else if (info.by !== "repeat" && !currentquestion_.ga_id) {
      Sentry.captureMessage(
        `Ga Id doesn't exist in the ${info.productenv?.name} question: ${currentquestion_?.title}`
      );
    }

    if (currentquestion_?.noLeavePopup && info.unload) {
      info.setUnload(false);
    }

    // Test for show payment
    //info.showpayment = "yes";

    const inputCss = (theme) => ({
      borderRadius: "12px !important",
      maxWidth: "342px",
      width: "100% !important",
      padding: "8px !important",
      marginLeft: "auto",
      marginRight: "auto",
      lineHeight: "135% !important",
      minHeight: "35px !important",
      height: "unset !important",
      [theme.mq.md]: {
        maxWidth: "431px !important",
        width: "100% !important"
      },
      [theme.mq.lg]: {
        width: "431px !important",
        maxWidth: "unset  !important"
      }
    });

    const buttonCss = (theme) => ({
      lineHeight: "135% !important",
      borderRadius: "12px !important",
      background: theme.color.primary,
      "&:hover, &:active, &.active": {
        color: "#fff",
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
      },
      "&.outlined": {
        borderColor: `${theme.color.primary} !important`,
        background: "white",
        input: {
          accentColor: "white",
          background: "white",
          color: "black"
        },
        "&:hover, &:active, &.active, &.checked-bg": {
          color: "#fff",
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary}`,
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
          "input[type='checkbox']": {
            accentColor: theme.color.secondary,
            background: "white",
            color: "black",
            [theme.mq.md]: {
              accentColor: "white"
            }
          }
        }
      },
      "&.disabled": {
        opacity: "0.65",
        borderColor: "#6C757D",
        color: "#fff"
      }
    });

    const nextButtonIsDisabled = isNextButtonDisabled({
      btLoading: info.btLoading,
      type: currentquestion_.type,
      subtype: currentquestion_.sub_type,
      subtypeNew: currentquestion_.subtype,
      brandPillId: info.brand_pill?.id,
      brandPillIdNew: info.brand_pill_id,
      brandPillTitle: info.brand_pill?.title,
      isStock: info.isStock,
      scriptOnly: info.script_only || currentquestion_?.hidePrice,
      isAddressValid,
      eScriptName: info.eScriptName,
      medicationList: info.medicationList,
      ph: info.ph,
      isClinicalDetails:
        currentquestion_?.type !== "clinicalDetails" ||
        (currentquestion_?.type === "clinicalDetails" &&
          info.clinicalDetails?.weight &&
          info.clinicalDetails?.height)
    });

    let title = "";
    let subtitle = "";
    let add_sub_title = "";
    let content = <input></input>;
    let footer_bottom = "";
    let image_right_of_title = "";
    let footer_top = isValidScriptStatus({
      type: currentquestion_?.type,
      status: info?.eScriptStatus
    }) && (
      <button
        css={[
          inputCss,
          buttonCss,
          {
            borderRadius: "50px !important"
          }
        ]}
        id="nextgobutton"
        className={`btn btn-primary btn-lg col-12 ${
          nextButtonIsDisabled ? "disabled" : ""
        }`}
        disabled={nextButtonIsDisabled}
        onClick={info.next({ currentquestion_ })}
      >
        {info.btLoading && (
          <span className="loadingouter position-relative">
            <span className="hidetext">isload</span>
            {currentquestion_?.btloadcount && (
              <span className="position-absolute" id="nextcountdown"></span>
            )}
            <Spinner animation="border" className="loading" />
          </span>
        )}
        {info.btLoading
          ? currentquestion_.btloadtxt
            ? currentquestion_.btloadtxt
            : "Loading ..."
          : currentquestion_ && currentquestion_.bttxt
          ? currentquestion_.bttxt
          : "Next"}
      </button>
    );
    let dontHidePayment =
      (info.productenv.hidePayment && info.productenv.hidePayment !== "yes") ||
      !info.productenv.hidePayment;

    let showCreditCards =
      (info.productenv.once === "yes" ||
        (info.productenv.subscription === "yes" &&
          (info.showpayment === "yes" || !User.loggedIn()))) &&
      dontHidePayment;

    //Find no type question
    if (!currentquestion_.type && currentquestion_.title) {
      Sentry.captureMessage(
        `Type doesn't exist in the question: ${currentquestion_.title}`
      );
    }

    if (
      !info.acceptbypass_ &&
      currentquestion_?.type === "email" &&
      (!info.commonvalues ||
        (!info.commonvalues.termofuse &&
          !info.commonvalues.agreement &&
          !info.commonvalues.privacy))
    ) {
      info.acceptBypassSet(true);
    }

    if (
      currentquestion_?.type === "signup" ||
      currentquestion_?.type === "bypass" ||
      (info?.by === "repeat" && currentquestion_?.type === "submitrepeats")
    ) {
      if (currentquestion_?.employeeHealthCheck) {
        const SubmitBtStyle = (theme) => ({
          background: "#DD8E76",
          border: "none",
          borderRadius: "4px",
          color: "white",
          marginRight: "auto",
          marginLeft: "auto",
          padding: "16px 24px",
          gap: "16px",
          width: "344px",
          fontSize: "16px",
          lineHeight: "140%"
        });

        return (
          <EmployeeHealthCheckScorePage
            firstName={info.first_name}
            totalScore={info.healthCheckResult?.overall_result?.score ?? 0}
            totalRating={
              info.healthCheckResult?.overall_result?.bands ??
              "could_be_improved"
            }
            scores={[
              {
                code: "lifestyle",
                score: info.healthCheckResult?.lifestyle_result?.score ?? 0,
                rating:
                  info.healthCheckResult?.lifestyle_result?.bands ??
                  "could_be_improved"
              },
              {
                code: "physical_health",
                score:
                  info.healthCheckResult?.physical_health_result?.score ?? 0,
                rating:
                  info.healthCheckResult?.physical_health_result?.bands ??
                  "could_be_improved"
              },
              {
                code: "nutrition",
                score: info.healthCheckResult?.nutrition_result?.score ?? 0,
                rating:
                  info.healthCheckResult?.nutrition_result?.bands ??
                  "could_be_improved"
              },
              {
                code: "emotional_wellbeing",
                score:
                  info.healthCheckResult?.emotional_wellbeing_result?.score ??
                  0,
                rating:
                  info.healthCheckResult?.emotional_wellbeing_result?.bands ??
                  "could_be_improved"
              }
            ]}
            submit={info.submit}
          />
        );
      }

      let discountPrice = info.discount
        ? {
            finalconsultprice: parseFloat(info.discount?.total_incl_tax),
            to: "nextButton",
            text: `Pay ${Intl.NumberFormat("en-AU", {
              style: "currency",
              currency: "AUD"
            }).format(parseFloat(info.discount.total_incl_tax))}`
          }
        : null;

      jQuery("#secondary-page").addClass(" signup-page");
      if (
        jQuery(window).width() <= 450 &&
        jQuery(".page-wrapper").length >= 1
      ) {
        jQuery("#secondary-page").addClass(" submitqbg");
        if (!User.loggedIn() || showCreditCards) {
          let fullwidth_ = jQuery(window).width();
          let width_ = jQuery(".questionnaire-base").width();
          let leftright = (fullwidth_ - width_) / 2;
        }
      }

      let done = "";
      let endname = "done_new";
      let contents_ = "";
      let contents__ = "";
      let contents2__ = "";
      let contentclass = "";
      let contentclasstop = "";
      let end_content = "";
      let nextButton = [];
      let nextButtonClass = "";
      let iscontent = false;
      let headerContents = "";
      let headerContentsClass = "";

      if (END) {
        end_content = END;
      }

      let price__ = info.price ? info.price : 0;

      if (!info.price || parseFloat(info.price) <= 0) {
        price__ = info.productenv.price;
      }
      if (
        info.deliverycost &&
        info.deliverycost.price &&
        Number(info.deliverycost.price) &&
        (!info?.isChemist2u || info.isChemist2u !== "yes")
      ) {
        price__ = parseFloat(price__) + parseFloat(info.deliverycost.price);
        price__ = parseFloat(price__).toFixed(2);
      }

      if (price__ && info.productenv?.expresscost) {
        price__ = (
          parseFloat(price__) + parseFloat(info.productenv?.expresscost)
        )
          ?.toFixed(2)
          ?.toString();
      }

      info.price__ = price__;

      if (info?.isChemist2u) {
        info.totalprice__ = (
          parseFloat(info.price__) +
          (info.shipCost ? parseFloat(info.shipCost) : 0)
        )
          ?.toFixed(2)
          ?.toString();
      }

      let setalready = false;

      if (
        info.isValidParam &&
        info.fromParam &&
        ((Array.isArray(info.isValidParam) &&
          info.isValidParam.length > 0 &&
          info.isValidParam?.findIndex((param) => param === info.fromParam) >
            -1) ||
          (info.fromParam.length > 0 && info.fromParam === info.isValidParam))
      ) {
        endname = info.fromParam.toLowerCase();
      } else if (currentquestion_?.endname) {
        endname = currentquestion_.endname;
      } else {
        endname = info.script_only
          ? "done_script_only"
          : currentquestion_ && currentquestion_.endname
          ? currentquestion_.endname
          : info.productenv.once === "yes"
          ? User.loggedIn()
            ? "acceptonce_new"
            : "done_paymentonce_new"
          : User.loggedIn()
          ? info?.by === "repeat"
            ? "accept_repeat"
            : "accept_new"
          : "done_payment_new";
      }

      if (info.productenv.code === "fill-a-script")
        endname = info?.brand_pill
          ? "fill_a_script_medication"
          : "fill_a_script_no_medication";

      end_content.map((end) => {
        if (!setalready && endname.includes(end.name)) {
          done = end;
          setalready = true;
        }
      });

      contents_ = done.contents
        ? done.contents
        : done.content
        ? done.content
        : "";
      if (done && done.type === "grid") {
        let result = setGridTemplate(contents_, info, done?.contentsclass);
        contentclass = result.contentclass;
        contentclasstop = done?.contentclasstop;
        contents__ = result.contents__;
        contents2__ = result.contents2__;
        iscontent = result.iscontent;
      }

      if (done && done.titlecontents) {
        let headerresult = setGridTemplate(
          done.titlecontents,
          info,
          done?.headercontentsclass
        );
        headerContentsClass = headerresult.contentclass;
        headerContents = headerresult.contents__;
      }

      if (discountPrice) {
        info.Base.logger(
          info.Base.Config().STATE,
          "templatequestionnaire",
          "displayform check next button discountPrice",
          { discountPrice: discountPrice }
        );
      }

      if (
        (done && done.nextButton) ||
        (discountPrice && discountPrice.to && discountPrice.to === "nextButton")
      ) {
        nextButtonClass =
          done.buttonText && done.buttonText.class_
            ? done.buttonText.class_
            : "";
        nextButton.push("Make payment");
      }

      let bottomtxt = [];
      if (done && done.bottomtxt && done.bottomtxt.contents) {
        done.bottomtxt.contents.map((c, ix) => {
          bottomtxt.push(<p key={ix}>{info.Base.getDatafromText(c.text)}</p>);
        });
      }

      let promo = "";
      if (done && done.promo) {
        promo = done.promo;
      }

      let accountattr = "";
      if (done && done.createaccount) {
        accountattr = done.createaccount;
      }

      const signup = done?.signup;

      title =
        currentquestion_.type === "bypass"
          ? info.Base.getDatafromText(currentquestion_.title, null, info)
          : info.Base.getDatafromText(done.title, null, info, " ");

      const subtitle = done?.subtitle
        ? info.Base.getDatafromText(done.subtitle, null, info)
        : done.content
        ? info.Base.getDatafromText(done.content, null, null, " ")
        : currentquestion_.type === "bypass" && currentquestion_.subtitle
        ? currentquestion_.subtitle
        : "";

      const undertitleContents = done?.undertitle
        ? done.undertitle.findIndex((t) => t.product === info.productenv.code) >
          -1
          ? done.undertitle.find((t) => t.product === info.productenv.code)
          : done.undertitleDefault
        : false;

      const undertitleBlock = undertitleContents && (
        <div
          className={`col-12 extratitle text-center pb-3 ${
            User.loggedIn() && "order-first"
          }`}
        >
          <h2 className={done.undertitleclass_}>
            {User.loggedIn() && undertitleContents?.logintext
              ? undertitleContents.logintext
              : undertitleContents.text}
          </h2>
        </div>
      );

      const isEventPromo =
        (!info.productenv.once &&
          info.productenv.subscription &&
          discountPrice?.to &&
          discountPrice?.finalconsultprice === 0) ??
        false;

      const isThrushOrMAP = ["thrush", "morning_after_pill"].includes(
        info.productenv.code
      );
      const reactCard = (!done || !done.nopayment) &&
        !info.iseventpromo &&
        showCreditCards &&
        !isEventPromo && (
          <>
            <CCards
              isShowNewPaymentPage={info.isShowNewPaymentPage}
              modalSet={info.modalSet}
              hidemodal={true}
              hideclose={true}
              loc={"questionnaire"}
              from={"newquestionnaire"}
              noact={true}
              issignup={true}
              ref={info.childRef}
              noaddbt={true}
              newsubmitqus={true}
              showSeparator={isThrushOrMAP}
            />
          </>
        );

      const reactPromo = !done?.noPromo &&
        !currentquestion_.noPromo &&
        !info.productenv?.noPromo &&
        currentquestion_.type !== "bypass" &&
        info.membershipsStatus !== "active" && (
          <div
            className="col-12 col-lg-11 ps-3 pe-3 ps-lg-0 pe-lg-0"
            css={(theme) => ({
              paddingLeft: info?.isShowNewPaymentPage
                ? "0px !important"
                : "unset",
              paddingRight: info?.isShowNewPaymentPage
                ? "0px !important"
                : "unset",
              paddingTop: info?.isShowNewPaymentPage ? "" : "unset",
              input: {
                marginBottom: "0px !important"
              },
              button: {
                marginTop: "auto !important",
                marginBottom: "auto !important"
              },
              [theme.mq.md]: {
                input: {
                  height: "40px",
                  minWidth: "300px",
                  border: "unset !important",
                  paddingLeft: "12px"
                },
                button: {
                  height: "40px",
                  width: "100px"
                }
              }
            })}
          >
            <ExpandableDiscountCode
              placeHolder={info?.isShowNewPaymentPage ? "Promo Code" : ""}
              onApplied={info.applyVoucher}
              voucherDiscounts={info.activeVouchers}
              isApplyingDiscount={false}
              discountError={info.voucherError}
              marginBetween="0px"
              showBottomMessage={true}
            />
          </div>
        );

      const phoneField = (
        <PhoneInput
          key={"signup_phone_key"}
          value={info.ph}
          onChange={info.setPh}
          style={{ marginBottom: 20 }}
        />
      );

      const nextButtonBlock = SubmitButtonBlock({
        signup,
        nextButtonClass,
        nextButton,
        currentQuestion: currentquestion_,
        done,
        submit: info.submit
      });

      const { from } = queryString.parse(window.location.search);

      const PaymentDetailBlock_ = PaymentDetailBlock({
        signup,
        productenv: {
          ...info.productenv,
          catId: info.productenv?.CATID
        },
        currentQuestion: currentquestion_,
        info,
        done,
        reactCard,
        reactPromo,
        phoneField,
        nextButtonBlock,
        opts: {
          nextButtonClass,
          nextButton,
          promo,
          discountPrice,
          accountAttrClass: accountattr?.label?.class_ ?? undefined,
          showCreditCards
        },
        isRepeat: info?.by && info?.by === "repeat"
      });

      if (info.isShowNewPaymentPage) {
        return (
          <NewPaymentPage
            brand={info.from}
            consultFee={info.consultfee}
            brandPill={info?.brand_pill}
            card={reactCard}
            promo={reactPromo}
            nextButtonBlock={nextButtonBlock}
          />
        );
      }

      const isOperatingHours = checkWithinOperatingHours();

      return (
        <div>
          <div
            className="pb-5 inner-content"
            css={(theme) => ({
              marginTop: "-35px",
              [theme.mq.md]: {
                marginTop: "-16px"
              }
            })}
          >
            <div className="row">
              {currentquestion_.type !== "bypass" && (
                <div className="col-md-1 order-first"></div>
              )}
              <div
                className={`col-12 order-first title-outer ${
                  currentquestion_.type === "bypass" ? "col-lg-12" : "col-lg-10"
                } text-center`}
              >
                {done?.titletag === "h1" ? (
                  <h1 className={done.titleclass_}>{title}</h1>
                ) : done?.titletag === "h2" ? (
                  <h2 className={done.titleclass_}>{title}</h2>
                ) : (
                  <div
                    css={{
                      fontSize: "24px",
                      fontWeight: "400"
                    }}
                  >
                    {title}
                  </div>
                )}
                {(!User.loggedIn() ||
                  (User.loggedIn() &&
                    (!undertitleContents?.positionLogin ||
                      undertitleContents?.positionLogin === "top"))) &&
                  undertitleBlock}
                {headerContents && (
                  <div
                    className={headerContentsClass ? headerContentsClass : ""}
                  >
                    {headerContents}
                  </div>
                )}
              </div>
              {currentquestion_.type !== "bypass" && (
                <div className="col-md-1 order-first"></div>
              )}
            </div>
            <div className="row">
              {done?.subtitle && <div className="col-md-1 order-first"></div>}
              {(currentquestion_.type === "bypass" &&
                currentquestion_.subtitle) ||
              done?.subtitle ? (
                <div className="col-12 order-first text-center pb-3">
                  {done?.subtitletag === "h2" ? (
                    <h2 className={done.subtitleclass_}>{subtitle}</h2>
                  ) : done?.subtitletag === "p" ? (
                    <p className={done.subtitleclass_}>{subtitle}</p>
                  ) : (
                    <div
                      className={`${
                        done.subtitleclass_ || "strong text-uppercase"
                      }`}
                    >
                      {subtitle}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {!isThrushOrMAP &&
              info.fromParam !== nibInternationalSearchParam && (
                <QuestionnairePaymentType
                  type={info.paymentType}
                  onChange={info.setPaymentType}
                  consultFee={info.consultfee}
                  membershipFee={membershipFee}
                  hidePickConsultTime={
                    (info.arhi || info.isNibPatient) &&
                    info.productenv.slug === "telehealth-consult" &&
                    isOperatingHours
                  }
                />
              )}
            {info.fromParam !== nibInternationalSearchParam &&
              (info.arhi || info.isNibPatient) &&
              info.productenv.slug === "telehealth-consult" &&
              isOperatingHours && (
                <div className="row">
                  <QueueAppointmentTime arhi={true} />
                </div>
              )}
            {info.membershipsStatus !== "active" && (
              <>
                <div className="row">
                  {User.loggedIn() &&
                    undertitleContents?.positionLogin === "bottom" &&
                    undertitleBlock}

                  {info?.fromParam && info?.isValidParam && PaymentDetailBlock_}

                  {(!currentquestion_.subtype ||
                    currentquestion_.subtype !== "common") &&
                    !iscontent && (
                      <div
                        dangerouslySetInnerHTML={{ __html: contents_ }}
                      ></div>
                    )}
                  <div className="col-12 main-contents">
                    {((currentquestion_.subtype &&
                      currentquestion_.subtype === "common") ||
                      iscontent) && (
                      <div className={contentclasstop ?? contentclass ?? ""}>
                        {contents__}
                      </div>
                    )}
                  </div>
                </div>
                {(!info?.fromParam || !info?.isValidParam) &&
                  PaymentDetailBlock_}
              </>
            )}
            {contents2__ &&
            ((currentquestion_.subtype &&
              currentquestion_.subtype === "common") ||
              iscontent) ? (
              currentquestion_.type === "bypass" &&
              currentquestion_.subtitle &&
              currentquestion_.subtitle_login ? (
                ""
              ) : (
                <div className={contentclass ? contentclass : ""}>
                  {contents2__}
                </div>
              )
            ) : (
              ""
            )}
            {info.membershipsStatus === "active" &&
              SubmitButtonBlock({
                signup,
                nextButtonClass,
                nextButton: ["Continue"],
                currentQuestion: currentquestion_,
                done,
                submit: info.submit,
                wrapperCss: (theme) => ({
                  [theme.mq.lg]: {
                    textAlign: "center",
                    marginTop: "20px",
                    button: {
                      width: "300px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }
                  }
                })
              })}
          </div>
        </div>
      );
    }
    if (jQuery(".page-wrapper").length >= 1) {
      jQuery("#secondary-page").removeClass(" submitqbg");
      jQuery("#secondary-page").removeClass(" signup-page");
    }

    if (info.triggerPromo) {
      info.setTriggerPromo(false);
    }

    if (currentquestion_.type === "end" || currentquestion_.end) {
      title = currentquestion_.onlymessage
        ? currentquestion_.onlymessage
        : true;
      subtitle = currentquestion_.onlysubmessage
        ? currentquestion_.onlysubmessage
        : currentquestion_.onlymessage
        ? ""
        : currentquestion_.subtitle
        ? currentquestion_.subtitle
        : "";
      add_sub_title = currentquestion_.onlymessage
        ? ""
        : currentquestion_.add_sub_title
        ? currentquestion_.add_sub_title
        : "";
      content = currentquestion_.onlymessage
        ? ""
        : currentquestion_.content
        ? currentquestion_.content
        : currentquestion_.message
        ? currentquestion_.message
        : "";
      footer_top = "";
    } else if (
      currentquestion_.type === "button" ||
      currentquestion_.type === "select"
    ) {
      let curvalue =
        currentquestion_.value &&
        Array.isArray(currentquestion_.value) &&
        currentquestion_.value.length == 1
          ? currentquestion_.value[0]
          : currentquestion_.response
          ? Array.isArray(currentquestion_.response)
            ? currentquestion_.response[0]
            : currentquestion_.response
          : "";
      title =
        currentquestion_?.requestchange &&
        info?.brand_pill?.title &&
        currentquestion_?.title?.includes("[brand]")
          ? currentquestion_?.title?.replace("[brand]", info?.brand_pill?.title)
          : true;
      subtitle = true;
      if (currentquestion_.subtype === "selectbox") {
        content = (
          <MedicationSelectBox
            currentTitle={info?.brand_pill?.title}
            currentQuestion={currentquestion_}
            dropDownChange={(option) =>
              dropDownChange(option, info?.setBrandpill)
            }
            preference={currentquestion_?.preference ?? false}
          />
        );
      } else if (currentquestion_.sub_type === "productSearch") {
        content = (
          <MedicationRadioBox
            currentTitle={info?.brand_pill?.title}
            currentQuestion={currentquestion_}
            onChange={info.setBrandpill}
          />
        );
      } else {
        let select = [];
        currentquestion_.options.map((obj_, index) => {
          if (
            !currentquestion_.iscond ||
            (currentquestion_.iscond &&
              (!obj_.cond ||
                obj_.condobjs ||
                (obj_.cond &&
                  info.Base.checkCondition(
                    currentquestion_,
                    obj_.cond,
                    obj_.cond?.comp === "ga_id"
                      ? getOptionConditionData(
                          currentquestion_.ga_id,
                          currentquestion_.iscond
                        )
                      : null,
                    info
                  ))))
          ) {
            select.push(
              <button
                css={[inputCss, buttonCss]}
                disabled={info.btLoading || obj_.soldOut}
                key={`${
                  currentquestion_?.ga_id ?? currentquestion_.id
                }_${index}`}
                className={`outlined
                ${info.btLoading || obj_.soldOut ? "disabled " : ""}
                ${
                  curvalue === obj_.title ||
                  (obj_.active && curvalue.includes(obj_.active))
                    ? `active ${
                        obj_.style
                          ? obj_.style
                          : "btn btn-outline-secondary col-12"
                      }`
                    : `${
                        obj_.style
                          ? obj_.style
                          : "btn btn-outline-secondary col-12"
                      }`
                } 
                                    ${
                                      currentquestion_.style
                                        ? currentquestion_.style
                                        : ""
                                    }`}
                onClick={info.next({ obj_, currentquestion_ })}
              >
                {info.btLoading && (
                  <span className="pe-2">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                )}
                {info.createButtonText(obj_, "", {
                  showprice: currentquestion_.showprice,
                  useOption: currentquestion_.useoption,
                  hideSoldOut: currentquestion_.hideSoldOut
                })}
              </button>
            );
          }
        });

        content = (
          <div
            className={`${
              currentquestion_.innerStyle
                ? currentquestion_.innerStyle
                : "inner-content"
            }`}
          >
            {currentquestion_?.requestchange && (
              <MedicationBox
                singleRepeatPrice={
                  info?.singleRepeatPrice
                    ? parseFloat(info?.singleRepeatPrice)
                    : 0
                }
                preferredMonth={
                  info?.preferredmonth ? parseInt(info?.preferredmonth) : 0
                }
                price={info?.price ? parseFloat(info?.price) : 0}
                brandPill={info?.brand_pill}
                shippingCost={info?.shippingCost}
                ptPlanInfo={info?.ptPlanInfo}
              />
            )}
            <div css={{ textAlign: "center" }}>
              {select}
              <textarea
                css={[
                  inputCss,
                  {
                    borderRadius: "4px !important",
                    height: "120px !important",
                    paddingTop: "12px !important",
                    paddingBottom: "12px !important",
                    paddingLeft: "16px !important",
                    paddingRight: "16px !important"
                  }
                ]}
                hidden={info.hideOther}
                defaultValue={info.other}
                placeholder={
                  currentquestion_.placeholder
                    ? currentquestion_.placeholder
                    : "Please Specify"
                }
                id="otherinput"
                name="otherinput"
                onChange={(e) => info.setOther(e.target.value)}
              />
            </div>
          </div>
        );
        footer_top = info.hideOther ? "" : footer_top;
      }
    } else if (currentquestion_.type === "input") {
      title = true;
      subtitle = true;
      content = (
        <div className="inner-content" css={{ textAlign: "center" }}>
          <input
            key={`${currentquestion_.id}_${
              currentquestion_.ga_id
                ? currentquestion_.ga_id
                : currentquestion_.aid
                ? currentquestion_.aid
                : ""
            }`}
            id="thisinput"
            onKeyDown={info.next({ currentquestion_ })}
            defaultValue={
              currentquestion_.value &&
              Array.isArray(currentquestion_.value) &&
              currentquestion_.value.length == 1
                ? currentquestion_.value[0]
                : currentquestion_.response
                ? Array.isArray(currentquestion_.response)
                  ? currentquestion_.response[0]
                  : currentquestion_.response
                : currentquestion_.sub_type === "weight"
                ? info.weight
                : currentquestion_.sub_type === "height"
                ? info.height
                : currentquestion_.timeformat
                ? moment().format(currentquestion_.timeformat)
                : ""
            }
            type={
              currentquestion_.input_type
                ? currentquestion_.input_type
                : currentquestion_.sub_type &&
                  currentquestion_.sub_type === "date"
                ? "date"
                : "text"
            }
            placeholder={currentquestion_?.placeholder}
            pattern={currentquestion_?.input_type === "number" ? "[0-9]*" : ""}
            autoFocus={true}
            css={[
              inputCss,
              (theme) => ({
                maxWidth: "342px !important",
                fontSize: "14px",
                fontWeight: "400",
                border: "1px solid #737373",
                ":focus": {
                  outline: "none !important",
                  borderColor: `${theme.color.secondary} !important`
                },
                marginBottom: "16px",
                [theme.mq.md]: {
                  borderWidth: "0.5px",
                  marginBottom: "24px"
                }
              })
            ]}
          />
        </div>
      );
    } else if (currentquestion_.type === "personalDetails") {
      title = true;
      subtitle = true;
      content = (
        <div className="inner-content">
          <QuestionnairePersonalDetails
            setIsEditingForm={info.setIsEditingForm}
          />
        </div>
      );
    } else if (currentquestion_.type === "clinicalDetails") {
      title = true;
      subtitle = true;
      content = (
        <div className="inner-content">
          <ClinicalInfo
            clinicalDetails={info.clinicalDetails}
            onChange={info.setClinicalDetails}
            setIsEditingForm={info.setIsEditingForm}
            slug={info.productenv.slug}
          />
        </div>
      );
    } else if (currentquestion_.type === "newMedicationDetail") {
      title = false;
      subtitle = false;
      content = (
        <EscriptProductSelector
          brand={info.from}
          brandPill={info?.brand_pill}
          scriptToken={info.scriptToken}
          eScriptTokenStatus={info.eScriptStatus ?? undefined}
          medicationList={info.medicationList}
          eScriptName={info.eScriptName}
          eScriptRemaining={info.eScriptRemaining}
          onChange={info.setBrandpill}
        />
      );
    } else if (currentquestion_.type === "mobile") {
      title = true;
      subtitle = true;

      const key = `${currentquestion_.id}_${
        currentquestion_.ga_id
          ? currentquestion_.ga_id
          : currentquestion_.aid
          ? currentquestion_.aid
          : ""
      }`;

      content = (
        <div
          className="inner-content"
          css={(theme) => ({
            maxWidth: "342px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100% !important",
            [theme.mq.md]: {
              maxWidth: "431px !important",
              width: "100% !important"
            },
            [theme.mq.lg]: {
              width: "431px !important",
              maxWidth: "unset  !important"
            }
          })}
        >
          <PhoneInput
            key={key}
            value={info.ph}
            onChange={async (newPh) => {
              const currentQuestion = currentquestion_;
              const initialBtLoadtxt = currentQuestion.btloadtxt;
              currentquestion_.btloadtxt = "Validating ...";
              info.setBtLoading(true);

              if (newPh === null) {
                info.setPh(null);
              } else {
                // We locally think the phone number is valid, confirm it against the remote
                await RemoteValidation.mobile(newPh)
                  .then((valid) => {
                    if (!valid) {
                      Toast.error("Please enter a valid mobile number.");
                    }
                    info.setPh(valid ? newPh : null);
                  })
                  .finally(() => info.setBtLoading(false));
              }
              info.next({ currentquestion_ });
              info.setBtLoading(false);
              currentQuestion.btloadtxt = initialBtLoadtxt;
            }}
            style={{ marginBottom: 20 }}
            autoFocus={true}
          />
        </div>
      );
    } else if (currentquestion_.type === "multichoice") {
      let curvalues =
        currentquestion_.value &&
        Array.isArray(currentquestion_.value) &&
        currentquestion_.value.length > 0
          ? currentquestion_.value
          : currentquestion_.response
          ? currentquestion_.response
          : "";
      title = true;
      subtitle = true;

      const mc = currentquestion_.options.map((obj_, index) => {
        let ischecked = false;
        let textvalue = "";
        if (curvalues && Array.isArray(curvalues) && curvalues.length > 0) {
          for (let i = 0; i < curvalues.length; i++) {
            if (
              curvalues[i] === obj_.title ||
              (curvalues[i].includes(":") &&
                curvalues[i].split(":").length > 0 &&
                curvalues[i].split(":")[0] === obj_.title)
            ) {
              ischecked = true;
              if (
                curvalues[i].includes(":") &&
                curvalues[i].split(":").length > 1
              ) {
                textvalue = curvalues[i].split(":")[1];
              }
              break;
            }
          }
        }
        if (
          currentquestion_.iscond &&
          obj_.cond &&
          info.Base.checkCondition(
            currentquestion_,
            obj_.cond,
            obj_.cond?.comp === "ga_id"
              ? getOptionConditionData(
                  currentquestion_.ga_id,
                  currentquestion_.iscond
                )
              : null,
            info
          )
        ) {
          return false;
        }
        return (
          <div
            css={{ marginBottom: "24px" }}
            key={`index-${currentquestion_.id}-${obj_.id}`}
          >
            <div
              id={`outer-ck-${currentquestion_.id}-${obj_.id}`}
              className={`type-checkbox outlined ${
                ischecked ? "checked-bg" : ""
              }`}
              css={[
                inputCss,
                buttonCss,
                {
                  paddingTop: "0px !important",
                  paddingBottom: "0px !important",
                  paddingLeft: "16px !important",
                  paddingRight: "16px !important",
                  fontSize: "16px"
                }
              ]}
            >
              <input
                key={`ck_${currentquestion_.id}-${index}`}
                id={`${obj_.id}`}
                defaultChecked={ischecked}
                value={obj_.title}
                type="checkbox"
                name="thisinput[]"
                onKeyDown={info.next({ currentquestion_ })}
                onChange={(e) =>
                  info.onChangeMultiChoice(e, obj_, currentquestion_)
                }
                alt={
                  obj_.txtarea && obj_.txtarea === "yes"
                    ? obj_.tid
                      ? obj_.tid
                      : "other"
                    : ""
                }
                css={{
                  height: "16px !important",
                  border: "1px solid var(--Gray-500, #A6A6A6)"
                }}
              ></input>
              <label
                htmlFor={obj_.id}
                css={{
                  paddingTop: "8px !important",
                  paddingBottom: "8px !important"
                }}
              >
                {obj_.title}
              </label>
            </div>
            {obj_.txtarea && obj_.txtarea === "yes" && (
              <div
                className={
                  ischecked ? "multi-other-outer" : "d-none multi-other-outer"
                }
                css={{
                  marginLeft: "0px",
                  marginRight: "0px"
                }}
                id={obj_.tid ? obj_.tid : "multi-other"}
              >
                <div
                  css={(theme) => ({
                    width: "100% !important",
                    fontSize: "12px",
                    [theme.mq.md]: {
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "431px !important"
                    }
                  })}
                >
                  {obj_.placeholder
                    ? obj_.placeholder
                    : "Please provide details"}
                </div>
                <textarea
                  className=""
                  id={obj_.tid ? obj_.tid + "-text" : "multi-other-text"}
                  defaultValue={
                    ischecked && textvalue && textvalue.length > 0
                      ? textvalue.trim()
                      : ""
                  }
                  css={[
                    inputCss,
                    (theme) => ({
                      borderRadius: "4px !important",
                      height: "80px !important",
                      fontSize: "14px",
                      border: "1px solid #737373",
                      paddingTop: "12px !important",
                      paddingBottom: "12px !important",
                      paddingLeft: "16px !important",
                      paddingRight: "16px !important",
                      ":focus": {
                        outline: "none !important",
                        borderColor: `${theme.color.secondary} !important`
                      },
                      [theme.mq.md]: {
                        borderWidth: "0.5px"
                      }
                    })
                  ]}
                ></textarea>
              </div>
            )}
          </div>
        );
      });

      content = <div className="inner-content">{mc}</div>;
    } else if (currentquestion_.type === "upload") {
      title = true;
      subtitle = true;
      content = (
        <div className="inner-content">
          <button className="btn btn-primary type-button-input upload-box">
            <input
              className="input_"
              key={info.questionnaire.length}
              accept="image/*,.pdf"
              id="current_script"
              type="file"
              onChange={info.next({ currentquestion_ })}
            ></input>
            <div style={{ marginBottom: "10px", marginTop: "0px" }}>
              Upload current script
              {info._scriptname && <div>(uploaded)</div>}
            </div>
          </button>
        </div>
      );
      footer_top = "";
    } else if (currentquestion_.type === "switch") {
      var select = currentquestion_.options.map((obj_, index) => (
        <button
          key={`${index}-${info.questionnaire.length}`}
          className="btn btn-outline-secondary col-12"
          onClick={info.next({ obj_ })}
        >
          {obj_.title}
        </button>
      ));

      title = true;
      subtitle = true;
      content = <div className="inner-content">{select};</div>;
      footer_top = "";
    } else if (currentquestion_.type === "textarea") {
      title = true;
      subtitle = true;
      content = (
        <div className="inner-content" css={{ textAlign: "center" }}>
          <textarea
            key={`txa_${currentquestion_.id}_${
              currentquestion_.ga_id
                ? currentquestion_.ga_id
                : currentquestion_.aid
                ? currentquestion_.aid
                : ""
            }`}
            id="thisinput"
            placeholder={currentquestion_?.placeholder}
            defaultValue={
              currentquestion_.value &&
              Array.isArray(currentquestion_.value) &&
              currentquestion_.value.length == 1
                ? currentquestion_.value[0]
                : currentquestion_.response
                ? Array.isArray(currentquestion_.response)
                  ? currentquestion_.response[0] !== "SKIP THIS QUESTION"
                    ? currentquestion_.response[0]
                    : ""
                  : currentquestion_.response !== "SKIP THIS QUESTION"
                  ? currentquestion_.response
                  : ""
                : ""
            }
            autoFocus={true}
            css={[
              inputCss,
              (theme) => ({
                borderRadius: "4px !important",
                height: "120px !important",
                fontWeight: "400",
                marginBottom: "24px !important",
                border: "1px solid #737373",
                paddingTop: "12px !important",
                paddingBottom: "12px !important",
                paddingLeft: "16px !important",
                paddingRight: "16px !important",
                ":focus": {
                  outline: "none !important",
                  borderColor: `${theme.color.secondary} !important`
                },
                [theme.mq.md]: {
                  borderWidth: "1px"
                }
              })
            ]}
          ></textarea>
        </div>
      );
    } else if (currentquestion_.type === "alert") {
      title = true;
      subtitle = true;
      content = "";
    } else if (currentquestion_.type === "email") {
      title = currentquestion_.title
        ? currentquestion_.title
        : "Let’s start with your email";
      subtitle = true;
      content = (
        <div className="inner-content">
          <div
            css={[
              inputCss,
              (theme) => ({
                paddingLeft: "0px !important",
                paddingRight: "0px !important",
                paddingBottom: "0px !important",
                marginBottom: "16px",
                [theme.mq.md]: {
                  paddingLeft: "0px !important",
                  paddingRight: "0px !important",
                  marginBottom: "24px"
                },
                [theme.mq.lg]: {
                  paddingLeft: "0px !important",
                  paddingRight: "0px !important"
                }
              })
            ]}
          >
            <input
              key={
                currentquestion_ && currentquestion_.id
                  ? currentquestion_.id
                  : currentquestion_.type
              }
              name="user_email"
              id="useremail"
              type="email"
              className="questionnaire-email text-lowercase w-100"
              required
              placeholder="email"
              onKeyDown={info.next({ currentquestion_ })}
              defaultValue={info.user_email}
              autoFocus={true}
              css={(theme) => ({
                lineHeight: "135%",
                marginBottom: "0px !important",
                padding: "8px !important",
                fontWeight: "400",
                border: "1px solid #737373",
                ":focus": {
                  outline: "none !important",
                  borderColor: `${theme.color.secondary} !important`
                },
                [theme.mq.md]: {
                  borderWidth: "0.5px"
                }
              })}
            />
          </div>
          <div
            css={[
              inputCss,
              (theme) => ({
                paddingLeft: "0px !important",
                paddingRight: "0px !important",
                paddingTop: "0px !important",
                paddingBottom: "0px !important",
                marginBottom: "16px !important",
                [theme.mq.md]: {
                  paddingLeft: "0px !important",
                  paddingRight: "0px !important",
                  marginBottom: "24px !important"
                },
                [theme.mq.lg]: {
                  paddingLeft: "0px !important",
                  paddingRight: "0px !important"
                }
              })
            ]}
          >
            <input
              name="confirm_user_email"
              id="confirmuseremail"
              type="email"
              className="questionnaire-email text-lowercase w-100"
              required
              placeholder="confirm email"
              onKeyDown={info.next({ currentquestion_ })}
              css={(theme) => ({
                lineHeight: "135%",
                padding: "8px !important",
                fontWeight: "400",
                border: "1px solid #737373",
                ":focus": {
                  outline: "none !important",
                  borderColor: `${theme.color.secondary} !important`
                },
                [theme.mq.md]: {
                  borderWidth: "0.5px"
                }
              })}
            />
          </div>
        </div>
      );
      footer_bottom =
        info.commonvalues &&
        (info.commonvalues.termofuse ||
          info.commonvalues.agreement ||
          info.commonvalues.privacy) ? (
          <div>
            <p
              css={inputCss}
              style={{
                paddingTop: "8px",
                fontSize: "12px",
                lineHeight: "18px",
                textAlign: "center"
              }}
            >
              By clicking Next you agree to our{" "}
              <a
                className="btn-link"
                href={
                  info.commonvalues && info.commonvalues.termofuse
                    ? info.commonvalues.termofuse.value
                    : ""
                }
                target="terms and conditions"
              >
                Terms &amp; Conditions
              </a>
              {", "}
              <a
                className="btn-link"
                href={
                  info.commonvalues && info.commonvalues.privacy
                    ? info.commonvalues.privacy.value
                    : ""
                }
                target="privacy policy"
              >
                Privacy Policy
              </a>
              {", "}
              and confirm that you are over the age of 18.
            </p>
          </div>
        ) : (
          ""
        );
    } else if (currentquestion_.type === "name") {
      title = true;
      subtitle = true;
      content = (
        <div
          className="inner-content row"
          css={(theme) => ({
            maxWidth: "342px",
            width: "100% !important",
            marginLeft: "auto",
            marginRight: "auto",
            lineHeight: "normal",
            minHeight: "35px !important",
            height: "unset !important",
            [theme.mq.md]: {
              maxWidth: "431px !important",
              width: "100% !important"
            },
            [theme.mq.lg]: {
              width: "431px !important",
              maxWidth: "unset  !important"
            }
          })}
        >
          <div
            className="col-12 col-md-6"
            css={(theme) => ({
              padding: "0px !important",
              [theme.mq.md]: {
                paddingRight: "24px !important"
              }
            })}
          >
            <input
              key={"first_name"}
              name="first_name"
              autoComplete="off"
              id="first_name"
              placeholder="First Name"
              className="first_name w-100"
              defaultValue={info.first_name}
              onKeyDown={info.next({ currentquestion_ })}
              autoFocus={true}
              css={(theme) => ({
                lineHeight: "135%",
                padding: "8px !important",
                fontSize: "14px",
                fontWeight: "400",
                border: "1px solid #737373",
                marginRight: "10px !important",
                marginBottom: "24px",
                ":focus": {
                  outline: "none !important",
                  borderColor: `${theme.color.secondary} !important`
                },
                [theme.mq.md]: {
                  borderWidth: "0.5px"
                }
              })}
            />
          </div>
          <div
            className="col-12 col-md-6"
            css={{
              padding: "0px !important"
            }}
          >
            <input
              key={"last_name"}
              name="last_name"
              placeholder="Last Name"
              autoComplete="off"
              id="last_name"
              className="last_name w-100"
              defaultValue={info.last_name}
              onKeyDown={info.next({ currentquestion_ })}
              css={(theme) => ({
                lineHeight: "135%",
                padding: "8px !important",
                fontWeight: "400",
                fontSize: "14px",
                border: "0.5px solid #737373",
                marginBottom: "24px",
                ":focus": {
                  outline: "none !important",
                  borderColor: `${theme.color.secondary} !important`
                },
                [theme.mq.md]: {
                  paddingLeft: " 16px !important",
                  paddingRight: " 16px !important"
                }
              })}
            />
          </div>
        </div>
      );
    } // used for split test egl-1161 medical certificates, single email field which calls validate_email
    // endpoint. Only if the response is that the email is confirmed as invalid do we alert the user to
    // correct their email address
    else if (currentquestion_.type === "singleEmail") {
      title = currentquestion_.title;
      subtitle = true;
      content = (
        <div
          css={(theme) => ({
            marginBottom: "24px",
            marginLeft: "auto",
            marginRight: "auto",
            lineHeight: "normal",
            minHeight: "35px !important",
            height: "unset !important",
            maxWidth: "342px",
            width: "100% !important",
            [theme.mq.md]: {
              width: "431px !important",
              maxWidth: "unset !important"
            }
          })}
        >
          <SingleEmailQuestion email={info.user_email ?? ""} />
        </div>
      );
      footer_bottom =
        info.commonvalues &&
        (info.commonvalues.termofuse ||
          info.commonvalues.agreement ||
          info.commonvalues.privacy) ? (
          <div>
            <p
              css={inputCss}
              style={{
                paddingTop: "8px",
                fontSize: "12px",
                lineHeight: "18px",
                textAlign: "center"
              }}
            >
              By clicking Next you agree to our{" "}
              <a
                className="btn-link"
                href={
                  info.commonvalues && info.commonvalues.termofuse
                    ? info.commonvalues.termofuse.value
                    : ""
                }
                target="terms and conditions"
              >
                Terms &amp; Conditions
              </a>
              {", "}
              <a
                className="btn-link"
                href={
                  info.commonvalues && info.commonvalues.privacy
                    ? info.commonvalues.privacy.value
                    : ""
                }
                target="privacy policy"
              >
                Privacy Policy
              </a>
              {", "}
              and confirm that you are over the age of 18.
            </p>
          </div>
        ) : (
          ""
        );
    } else if (currentquestion_.type === "emailAndName") {
      title = true;
      subtitle = true;
      content = (
        <div
          css={(theme) => ({
            marginBottom: "24px",
            marginLeft: "auto",
            marginRight: "auto",
            lineHeight: "normal",
            minHeight: "35px !important",
            height: "unset !important",
            maxWidth: "342px",
            width: "100% !important",
            [theme.mq.md]: {
              width: "431px !important",
              maxWidth: "unset !important"
            }
          })}
        >
          <EmailAndNameQuestion
            email={info.user_email}
            firstName={info.first_name}
            lastName={info.last_name}
          />
        </div>
      );
      footer_bottom =
        info.commonvalues &&
        (info.commonvalues.termofuse ||
          info.commonvalues.agreement ||
          info.commonvalues.privacy) ? (
          <div>
            <p
              css={[
                inputCss,
                (theme) => ({
                  paddingTop: "16px",
                  fontSize: "12px",
                  lineHeight: "18px",
                  textAlign: "center",
                  [theme.mq.sm]: {
                    paddingLeft: "15px",
                    paddingRight: "15px"
                  }
                })
              ]}
            >
              By clicking “Next” you agree to our{" "}
              <a
                className="btn-link"
                href={
                  info.commonvalues && info.commonvalues.termofuse
                    ? info.commonvalues.termofuse.value
                    : ""
                }
                target="terms and conditions"
              >
                Terms &amp; Conditions
              </a>
              {", "}
              <a
                className="btn-link"
                href={
                  info.commonvalues && info.commonvalues.privacy
                    ? info.commonvalues.privacy.value
                    : ""
                }
                target="privacy policy"
              >
                Privacy Policy
              </a>
              {", "}
              and confirm that you are over the age of 18.
            </p>
          </div>
        ) : (
          ""
        );
    } else if (
      currentquestion_.type === "dob" ||
      currentquestion_.type === "date"
    ) {
      title = true;
      subtitle = false;

      content = (
        <div
          css={{
            marginBottom: "24px"
          }}
        >
          <QuestionnaireDatePicker
            info={info}
            currentQuestion={{
              ...currentquestion_,
              ...{ gaId: currentquestion_.ga_id }
            }}
            autoFocus={true}
          />
        </div>
      );
    } else if (currentquestion_.type === "bloodPressure") {
      title = true;
      subtitle = false;

      content = (
        <div
          css={[
            inputCss,
            (theme) => ({
              paddingLeft: "0px !important",
              paddingRight: "0px !important",
              [theme.mq.md]: {
                paddingLeft: "0px !important",
                paddingRight: "0px !important"
              }
            })
          ]}
        >
          <BloodPressureQuestion
            highBlood={info?.highBlood}
            lowBlood={info?.lowBlood}
          />
        </div>
      );
    } else if (currentquestion_.type === "address") {
      const loader = document.getElementById("cover-spin");
      const startSpinner = () => {
        loader.className = "show";
      };
      const stopSpinner = () => {
        loader.className = loader.className.replace(/show/g, "");
      };
      title = true;
      subtitle = true;
      const swapDisplay = (key) => {
        const element = document.getElementById(key);
        element.style["display"] =
          element.style["display"] === "none" ? null : "none";
      };
      const locationEntry = (
        <div
          css={(theme) => ({
            marginBottom: "24px",
            marginLeft: "auto",
            marginRight: "auto",
            lineHeight: "normal",
            minHeight: "35px !important",
            height: "unset !important",
            maxWidth: "342px",
            width: "100% !important",
            [theme.mq.md]: {
              width: "431px !important",
              maxWidth: "unset !important"
            }
          })}
        >
          <div style={{ marginBottom: 24 }}>
            <LocationAutocomplete
              style={{ width: "100%", color: "black", fontSize: "14px" }}
              onStartLoading={startSpinner}
              autoFocus={true}
              onSelectedLocation={(location) => {
                if (locationInputRef.current) {
                  locationInputRef.current.handleAutofill(location, () => {
                    stopSpinner();
                    handleFieldsFilledChange(true);
                  });
                } else {
                  stopSpinner();
                }
              }}
              onFieldsFilledChange={handleFieldsFilledChange}
            />
          </div>
          <a
            className="manual-link"
            css={{
              fontSize: "14px",
              fontWeight: "500",
              display: "flex",
              alignContent: "flex-start",
              marginBottom: "24px"
            }}
            onClick={() => swapDisplay("location_manual_entry")}
          >
            Enter your address manually
          </a>
          <LocationInput
            ref={locationInputRef}
            id="location_manual_entry"
            initiallyHidden
            editable={true}
            onFieldsFilledChange={handleFieldsFilledChange}
            address={address}
            onAddressChange={(updatedAddress) => setAddress(updatedAddress)}
          />
        </div>
      );

      // not null or undefined
      const nnou = (x) => x !== null && x !== undefined;
      const hasAllRequiredDetails =
        nnou(info.addressLine1) &&
        nnou(info.suburb) &&
        nnou(info.state) &&
        nnou(info.postcode);
      const initial_footer_top = footer_top;

      if (hasAllRequiredDetails) {
        footer_top = (
          <style id="footer_button_style_wrap" style={{ dispaly: "none" }}>
            {initial_footer_top}
          </style>
        );

        content = (
          <div className="inner-content d-flex flex-column">
            <p
              style={{ display: "flex", justifyContent: "center" }}
              className={"qsubtitle"}
            >
              {makeReadableAddress(info)}
            </p>
            <button
              className="btn btn-primary"
              css={[
                inputCss,
                {
                  paddingTop: "9px",
                  paddingBottom: "9px",
                  borderRadius: "50px !important"
                }
              ]}
              id="location_yes_button"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById("location_yes_button").className =
                  "btn btn-primary active";
                document.getElementById("location_no_button").className =
                  "btn btn-primary";
                footer_top = initial_footer_top;
                info.next({
                  currentquestion_,
                  skipvalidation: true
                })(e);
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-primary"
              css={[
                inputCss,
                {
                  paddingTop: "9px",
                  paddingBottom: "9px",
                  borderRadius: "50px !important"
                }
              ]}
              id="location_no_button"
              onClick={(e) => {
                e.preventDefault();
                setAddress({
                  addressLine1: "",
                  addressLine2: "",
                  suburb: "",
                  state: "",
                  postcode: ""
                });
                handleFieldsFilledChange(false);
                // Handle the required style changes
                document.getElementById("update_existing_address_entry").style[
                  "display"
                ] = null;
                document.getElementById("footer_button_style_wrap").style[
                  "display"
                ] = "flex";
                document.getElementById("location_yes_button").className =
                  "btn btn-primary";
                document.getElementById("location_no_button").className =
                  "btn btn-primary active";
                footer_top = initial_footer_top;
              }}
            >
              No
            </button>
            <div
              id={"update_existing_address_entry"}
              style={{ display: "none" }}
            >
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold"
                }}
                className={"qsubtitle"}
              >
                Please update your address
              </p>
              {locationEntry}
            </div>
          </div>
        );
      } else {
        content = locationEntry;
      }
    } else if (currentquestion_.type === "medication") {
      title = true;
      subtitle = true;
      content = (
        <div className="medication-outer mb-3">
          <MedicationSearch
            defaultBrandId={info.brand_pill_id}
            isScriptOnly={info.script_only || currentquestion_?.hidePrice}
            onChange={info.medicationChange}
            categoryId={
              info.medicationCategoryId > 0
                ? info.medicationCategoryId
                : info.category.id
            }
          />
        </div>
      );
    } else if (currentquestion_.type === "medicare") {
      title = true;
      subtitle = info.productenv?.medicare?.subtitle ?? true;
      content = (
        <QuestionnaireMedicare
          medicareSelectedStatus={info.medicareSelectedStatus}
          medicare={info.medicare}
          medicareref={info.medicareref}
          medicareexptemp={info.medicareexptemp}
          medicareexp={info.medicareexp}
          nolater={info.productenv?.medicare?.nolater}
          next={info.next({ currentquestion_ })}
          medicareSelectedStatusSet={info.medicareSelectedStatusSet}
          nomedicareSet={info.nomedicareSet}
          medicareexptempSet={info.medicareexptempSet}
        />
      );
      footer_top = ["yes", "no", "later"].includes(info.medicareSelectedStatus)
        ? footer_top
        : "";
    } else if (currentquestion_.type === "ihiNumber") {
      title = true;
      subtitle = true;
      content = (
        <QuestionnaireIHI
          ihi={info.ihi}
          ihiSelectedStatus={info.ihiSelectedStatus}
          ihiNumber={info.ihiNumber}
          next={info.next({ currentquestion_ })}
          noIhiSet={info.noIhiSet}
          ihiSelectedStatusSet={info.ihiSelectedStatusSet}
        />
      );
      footer_top = ["yes", "no"].includes(info.ihiSelectedStatus)
        ? footer_top
        : "";
    } else if (currentquestion_.type === "token") {
      title = true;
      subtitle = true;
      image_right_of_title = info.Base.getRawDatafromText(
        currentquestion_.img,
        null,
        info
      );
      content = (
        <div className="inner-content" css={{ textAlign: "center" }}>
          <input
            key={
              currentquestion_ && currentquestion_.id
                ? currentquestion_.id
                : currentquestion_.type
            }
            name="token"
            id="token"
            type="email"
            className="questionnaire-email text-lowercase"
            required
            placeholder="21KR32CTBBKDDTY17B1"
            onKeyDown={info.next({ currentquestion_ })}
            defaultValue={info.scriptToken}
            css={[
              inputCss,
              { maxWidth: "342px !important" },
              (theme) => ({
                fontWeight: "400",
                border: "1px solid #737373",
                ":focus": {
                  outline: "none !important",
                  borderColor: `${theme.color.secondary} !important`
                },
                marginBottom: "16px",
                [theme.mq.md]: {
                  borderWidth: "0.5px",
                  marginBottom: "24px"
                }
              })
            ]}
          />
        </div>
      );
    } else if (currentquestion_.type === "splash") {
      if (currentquestion_.employeeHealthCheck) {
        return (
          <EmployeeHealthCheckSplashPage
            checkStage={currentquestion_.checkStage}
            nextButton={
              <button
                css={(theme) => ({
                  border: "none",
                  marginTop: "24px",
                  width: "100%",
                  color: "white",
                  backgroundColor: "#DD8E76",
                  borderRadius: "4px",
                  padding: "16px 24px",
                  [theme.mq.lg]: {
                    width: "177px"
                  }
                })}
                id="nextgobutton"
                className={`btn btn-primary btn-lg col-12`}
                disabled={nextButtonIsDisabled}
                onClick={info.next({ currentquestion_ })}
              >
                {currentquestion_.bttxt}
                <span css={{ paddingLeft: "16px" }}>
                  <img src="/assets/images/com/rightArrow.svg" />
                </span>
              </button>
            }
          />
        );
      } else if (!currentquestion_?.typefrom?.includes("welcome")) {
        let contents = [];
        currentquestion_.contents.map((content, index) => {
          contents.push(
            <div key={index} className={content.class_ ? content.class_ : ""}>
              {content.type ? (
                content.type === "img" ? (
                  <img src={content.path}></img>
                ) : content.type === "text" ? (
                  content.tag_ ? (
                    content.tag_ === "h1" ? (
                      <h1 className={content.class_ ? content.class_ : ""}>
                        {info.Base.getDatafromText(
                          content.text,
                          null,
                          info,
                          content.class_ ? content.class_ : ""
                        )}
                      </h1>
                    ) : content.tag_ === "h2" ? (
                      <h2 className={content.class_ ? content.class_ : ""}>
                        {info.Base.getDatafromText(
                          content.text,
                          null,
                          info,
                          content.class_ ? content.class_ : ""
                        )}
                      </h2>
                    ) : content.tag_ === "h3" ? (
                      <h3 className={content.class_ ? content.class_ : ""}>
                        {info.Base.getDatafromText(
                          content.text,
                          null,
                          info,
                          content.class_ ? content.class_ : ""
                        )}
                      </h3>
                    ) : content.tag_ === "h4" ? (
                      <h4 className={content.class_ ? content.class_ : ""}>
                        {info.Base.getDatafromText(
                          content.text,
                          null,
                          info,
                          content.class_ ? content.class_ : ""
                        )}
                      </h4>
                    ) : (
                      info.Base.getDatafromText(content.text)
                    )
                  ) : (
                    info.Base.getDatafromText(content.text)
                  )
                ) : (
                  ""
                )
              ) : content.text ? (
                info.Base.getDatafromText(content.text)
              ) : (
                ""
              )}
            </div>
          );
        });

        return (
          <div className="row outer">
            <div className="col-lg-6 position-relative inner inner-left">
              <div className="left-center-img position-absolute">
                <img src="/assets/images/com/iphone.png" />
              </div>
              <div className="row hg-800">
                <div className="col-6"></div>
                <div className="col-6 triangle"></div>
              </div>
            </div>
            <div className="col-lg-6 inner inner-right">
              <div className="row position-relative hg-800 hg-md-max">
                <div className="right-left-img rot-img position-absolute">
                  <img src="/assets/images/com/iphone.png" />
                </div>
                <div className="right-right-img rot-img position-absolute">
                  <img src="/assets/images/com/iphone.png" />
                </div>
                <div className="col-6 pt-10 bg-md"></div>
                <div className="col-6 pt-10 splash-logo text-center bg-md">
                  <img
                    src={
                      info.productenv.splashlogo
                        ? info.productenv.splashlogo
                        : info.productenv.logo
                        ? info.productenv.logo
                        : info.Base.Config().LOGO
                    }
                    alt="logo"
                  ></img>
                </div>
                <div className="col-12 col-lg-0 pb-6 pt-6 bg-md"></div>
                <div className="col-12 col-lg-0 pb-6 pt-6 triangle-rev"></div>
                <div className="col-12 col-lg-0 p-md-350"></div>
                <div className="col-12 col-lg-10">
                  {contents}
                  <div className="pt-6 pb-6">
                    <div className="w-mx-285 button-outer">{footer_top}</div>
                  </div>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </div>
        );
      } else if (currentquestion_?.comp) {
        return (
          <SplashScreen
            type="rightimg"
            classes="banner-treatment"
            name={"newsplash"}
            from={info.Base.Config().FROM}
          >
            <div className={`newsplash-footer ${info.Base.Config().FROM}`}>
              {footer_top}
              <p className="pt-6 small-txt white">
                By clicking "Continue" below you confirm you have read our Terms
                & Conditions and Privacy Policy.
              </p>
            </div>
          </SplashScreen>
        );
      } else if (info.productenv?.subname === "NibInternational") {
        return (
          <NibInternational
            code={info.productenv.code}
            firstName={info.first_name}
            lastName={info.last_name}
            dob_year={info.dob_year}
            dob_month={info.dob_month}
            dob_day={info.dob_day}
            membership={info.membership}
            setMembership={info.setMembership}
            email={info.user_email}
            detail={info.productenv?.splashdetail}
            footer={currentquestion_?.footer?.txt}
            continueToQuestionnaire={info.next({ currentquestion_ })}
            onVoucherApplied={info.applyVoucher}
            setFromParam={info.setFromParam}
            setIsValidParam={info.setIsValidParam}
            setResetProductEnv={info.setResetProductEnv}
            setIsNibPatient={info.setIsNibPatient}
            hideBack={info?.setHideSplash}
          />
        );
      } else if (currentquestion_?.typefrom?.includes("welcome")) {
        let leftcontents = [];
        let rightcontents = [];
        leftcontents = setGridTemplate(
          info.commonvalues.questionnaire[
            `welcome_${
              currentquestion_.splashname
                ? currentquestion_.splashname
                : info.Base.Config().FROM
            }`
          ]?.left?.contents,
          info,
          info.commonvalues.questionnaire?.welcome?.contentsclass
        );
        rightcontents = setGridTemplate(
          info.commonvalues.questionnaire[
            `welcome_${
              currentquestion_.splashname
                ? currentquestion_.splashname
                : info.Base.Config().FROM
            }`
          ]?.right?.contents,
          info,
          info.commonvalues.questionnaire?.welcome?.contentsclass
        );

        return (
          <div
            className={`row outer welcome ${currentquestion_?.splashclass_}`}
          >
            <div
              className={`${
                info.isver &&
                currentquestion_?.brands?.includes(info.Base.Config().FROM)
                  ? "d-none"
                  : "col-lg-6 inner inner-left"
              }`}
            >
              <div className="row position-relative">
                {leftcontents.contents__}
              </div>
            </div>
            {info.isver &&
              currentquestion_?.brands?.includes(info.Base.Config().FROM) && (
                <div
                  className={
                    currentquestion_?.widebrands?.includes(
                      info.Base.Config().FROM
                    )
                      ? `col-lg-2`
                      : `col-lg-3`
                  }
                ></div>
              )}
            <div
              className={`${
                info.isver &&
                currentquestion_?.brands?.includes(info.Base.Config().FROM)
                  ? currentquestion_?.widebrands?.includes(
                      info.Base.Config().FROM
                    )
                    ? "col-12 col-lg-8"
                    : "col-12 col-lg-6"
                  : currentquestion_.splashinnerclass_
                  ? currentquestion_.splashinnerclass_
                  : `col-lg-6 inner inner-right`
              }`}
            >
              <div className="row position-relative">
                {info.isver &&
                  currentquestion_?.brands?.includes(info.Base.Config().FROM) &&
                  info.commonvalues.questionnaire[
                    `welcome_${info.Base.Config().FROM}`
                  ]?.left?.new &&
                  leftcontents.contents__}
                {rightcontents.contents__}
                <div
                  className={`col-12 ${
                    currentquestion_?.footer?.outerclass_
                      ? currentquestion_?.footer?.outerclass_
                      : "col-lg-6 pt-6 pb-6"
                  }`}
                >
                  <div
                    className={`${
                      currentquestion_?.footer?.innerclass_
                        ? currentquestion_?.footer?.innerclass_
                        : ""
                    }`}
                  >
                    <div
                      className={`col-12 button-outer ${currentquestion_?.footer?.btouterclass_}`}
                    >
                      {footer_top}
                    </div>
                  </div>
                </div>
                <div
                  className={`col-12 text-center text-md-start small-txt bottom-text ${
                    currentquestion_?.footer?.outertxtclass_
                      ? currentquestion_?.footer?.outertxtclass_
                      : "white"
                  }`}
                >
                  <div
                    className={`${
                      currentquestion_?.footer?.txtclass_
                        ? currentquestion_?.footer?.txtclass_
                        : "white"
                    }`}
                  >
                    {currentquestion_?.footer?.txt ??
                      `By clicking "Continue" below you confirm you have read our Terms & Conditions and Privacy Policy.`}
                  </div>
                </div>
              </div>
            </div>
            {info.isver &&
              currentquestion_?.brands?.includes(info.Base.Config().FROM) && (
                <div
                  className={
                    currentquestion_?.widebrands?.includes(
                      info.Base.Config().FROM
                    )
                      ? `col-lg-2`
                      : `col-lg-3`
                  }
                ></div>
              )}
          </div>
        );
      }
    } else if (currentquestion_.type === "accordion-select") {
      let select = currentquestion_.options.map((obj_) => {
        const {
          optionNo,
          headerTitle,
          headerSubtitle,
          contentTitle,
          contentSubtitle
        } = obj_;
        return (
          <Card className="mb-3" key={optionNo}>
            <Card.Header
              css={(theme) => ({
                fontSize: "16px !important",
                paddingLeft: "10px",
                paddingRight: "10px",
                ".form-check-input": {
                  marginTop: "7.5px",
                  [theme.mq.md]: {
                    marginTop: "10px"
                  }
                }
              })}
            >
              <RadioToggle
                eventKey={optionNo}
                group="delivery-options"
                id={optionNo}
                value={optionNo.toString()}
                curval={info?.deliverycost?.delivery_option?.toString()}
              >
                <div className="d-flex flex-wrap">
                  <div className="d-none" id={`hiddentitle_${optionNo}`}>{`${
                    headerTitle && headerTitle.title
                  } $${headerSubtitle}`}</div>
                  <div
                    css={{
                      paddingLeft: "15px"
                    }}
                  >
                    {headerTitle?.title}
                  </div>
                  <div
                    className="title-secondary"
                    css={{
                      paddingLeft: "15px"
                    }}
                  >
                    {headerSubtitle}
                  </div>
                </div>
              </RadioToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={optionNo}>
              <Card.Body>
                <div className="display-linebreak accordion-title">
                  {contentTitle}
                </div>
                <div className="display-linebreak accordion-subtitle">
                  {contentSubtitle}
                </div>
                {headerTitle.contentBody && (
                  <div className="display-linebreak accordion-body">
                    <hr />
                    {headerTitle.contentBody}
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      });

      title = true;
      subtitle = true;
      content = <Accordion>{select}</Accordion>;
    } else if (currentquestion_.type === "OfferProd") {
      const obj_ = {
        id: 1133,
        title: "No thanks"
      };
      title = true;
      currentquestion_.title = getOfferProductText(
        "title",
        currentquestion_?.defaultTitle ?? currentquestion_.title,
        currentquestion_?.categoryList ?? info.categoryList,
        currentquestion_?.noCustomTitle
      );
      subtitle = currentquestion_?.isSubTitleData
        ? currentquestion_.defaultSubtitle ?? currentquestion_.subtitle
        : getOfferProductText(
            "subtitle",
            currentquestion_?.originSubtitle ?? currentquestion_.subtitle,
            currentquestion_?.categoryList ?? info.categoryList
          );
      content = (
        <div css={{ textAlign: "center" }}>
          {(currentquestion_?.categoryList || info.categoryList) &&
            Array.isArray(
              currentquestion_?.categoryList || info.categoryList
            ) && (
              <OfferTreatments
                from={info.productenv?.name}
                categoryList={
                  currentquestion_?.categoryList ?? info.categoryList
                }
              />
            )}
          {!hideThankButton(
            currentquestion_?.categoryList ?? info.categoryList,
            currentquestion_?.showNoThankYouBt
          ) && (
            <button
              css={[inputCss, buttonCss]}
              key="btn_nothankyou"
              className="btn outlined btn-outline-secondary mt-3 w-100"
              onClick={info.next({ obj_, currentquestion_ })}
            >
              {currentquestion_?.noThankYouTxt ?? "No thanks"}
            </button>
          )}
        </div>
      );

      footer_top = "";
    }

    add_sub_title = currentquestion_.add_sub_title
      ? currentquestion_.add_sub_title
      : false;

    if (isStickybt && jQuery("#secondary-page").outerWidth() < 500) {
      jQuery("#secondary-page").addClass(" outer-sticky");
      jQuery(`.outer-sticky`).on("scroll", info.IsScrollBottom_);
    } else {
      jQuery(`.outer-sticky`).off("scroll", info.IsScrollBottom_);
      jQuery("#secondary-page").removeClass("outer-sticky");
    }

    let title_block = () => {
      return (
        <div>
          {title && ((currentquestion_ && currentquestion_.title) || title) ? (
            <div
              css={{
                display: currentquestion_?.titleTooltip ? "flex" : "block"
              }}
              className={`qtitle text-center header-font ${
                currentquestion_.titleclass ? currentquestion_.titleclass : ""
              }`}
            >
              {typeof title === "string" || title instanceof String
                ? title
                : User.loggedIn() && currentquestion_.title_login
                ? currentquestion_.isdata
                  ? info.Base.getDatafromText(
                      currentquestion_.title_login,
                      null,
                      info.reason_ || info
                    )
                  : currentquestion_.title_login
                : currentquestion_.isdata
                ? info.Base.getDatafromText(
                    currentquestion_.title,
                    null,
                    info.reason_ ? info.reason_ : info
                  )
                : currentquestion_.title}
              {currentquestion_?.titleTooltip && (
                <span>
                  <Tooltip
                    infoIcon={true}
                    iconWidth="24px"
                    maxWidth="40%"
                    text={currentquestion_.titleTooltip}
                  />
                </span>
              )}
            </div>
          ) : (
            ""
          )}
          {currentquestion_ && currentquestion_.title2 ? (
            <h2
              className={`qtitle text-center header-font ${
                currentquestion_.titleclass ? currentquestion_.titleclass : ""
              }`}
            >
              {currentquestion_.title2}
            </h2>
          ) : (
            ""
          )}
          {title &&
          ((currentquestion_ && currentquestion_.title) || title) &&
          subtitle &&
          (currentquestion_.subtitle || subtitle) ? (
            <p
              className={`qsubtitle text-center ${
                currentquestion_.subtitleclass
                  ? currentquestion_.subtitleclass
                  : ""
              }`}
              style={{
                whiteSpace: "pre-wrap",
                fontSize: "16px",
                lineHeight: "135%"
              }}
            >
              {(typeof subtitle === "string" || subtitle instanceof String) &&
              !currentquestion_.isSubTitleData ? (
                subtitle
              ) : currentquestion_.subList ? (
                <ul className={currentquestion_.subUlClass}>
                  {currentquestion_.subList.map((sub) => {
                    return <li>{sub}</li>;
                  })}
                </ul>
              ) : User.loggedIn() && currentquestion_.subtitle_login ? (
                currentquestion_.subtitle_login
              ) : currentquestion_.isdata || currentquestion_.isSubTitleData ? (
                info.Base.getDatafromText(
                  currentquestion_.subtitle,
                  null,
                  info.reason_ ? info.reason_ : info
                )
              ) : (
                info.Base.getRawDatafromText(
                  currentquestion_.subtitle,
                  null,
                  info
                )
              )}
              {currentquestion_?.subtitleTooltip && (
                <span>
                  <Tooltip
                    infoIcon={true}
                    iconWidth="16px"
                    maxWidth="40%"
                    text={currentquestion_.subtitleTooltip}
                  />
                </span>
              )}
            </p>
          ) : (
            ""
          )}
          {currentquestion_.subtitle2 && (
            <p
              className="qsubtitle text-center"
              css={{
                whiteSpace: "pre-wrap",
                fontSize: "16px",
                lineHeight: "135%"
              }}
            >
              {currentquestion_.subtitle2}
            </p>
          )}
          {currentquestion_.subtitle3 && (
            <p
              className="qsubtitle text-center"
              css={{
                whiteSpace: "pre-wrap",
                fontSize: "16px",
                lineHeight: "135%"
              }}
            >
              {currentquestion_.subtitle3}
            </p>
          )}
          {currentquestion_.subTitleList && (
            <ul
              css={{
                whiteSpace: "pre-wrap",
                fontSize: "16px",
                lineHeight: "135%"
              }}
            >
              {currentquestion_.subTitleList.map((sub) => {
                return <li>{sub}</li>;
              })}
            </ul>
          )}
        </div>
      );
    };

    return (
      <div
        className={`inner-questionnaire-page ${
          currentquestion_.innerclass ? currentquestion_.innerclass : ""
        }`}
        id={
          isStickybt
            ? "outer-sticky"
            : currentquestion_?.ga_id ??
              `${info?.by}-${info.productenv?.name}-${currentquestion_?.id}`
        }
        css={(theme) => ({
          fontSize: "20px",
          color: "#000000",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "150%" /* 36px on desktop 30px on mobile */,
          marginTop: "-35px",
          [theme.mq.md]: {
            fontSize: "24px",
            marginTop: "-16px"
          }
        })}
      >
        <div className="question-title header-font">
          {image_right_of_title !== "" ? (
            <div className="row">
              <div className={`col-12 col-md-8 col-lg-8 mb-2`}>
                {title_block()}
              </div>
              <div className={`col-12 col-md-4 col-lg-4 mb-2 title-image`}>
                <img src={image_right_of_title} className="image" />
              </div>
            </div>
          ) : (
            <div>{title_block()}</div>
          )}
          {title &&
            ((currentquestion_ && currentquestion_.title) || title) &&
            currentquestion_.contentlist &&
            currentquestion_.contentlist.map((content_, index) => {
              let contents = "";
              if (content_.type === "header") {
                contents = (
                  <h2 className="mb-3 header-font">{content_.content}</h2>
                );
              } else if (content_.type === "checkbox") {
                contents = (
                  <div style={{ paddingLeft: "15px" }} className="mt-2">
                    <div className="type-checkbox">
                      <input
                        id="accept_terms"
                        defaultChecked={info.accept_}
                        type="checkbox"
                        onChange={(e) =>
                          info.handleCheck(
                            e,
                            { total: 1, no: 0 },
                            info.checkbts,
                            info.checkbtsSet,
                            info.acceptSet
                          )
                        }
                      ></input>
                      <label
                        htmlFor="accept_purchase"
                        style={{ paddingLeft: "15px" }}
                      >
                        {content_.content}
                      </label>
                    </div>
                  </div>
                );
              } else {
                contents = content_.content;
              }
              return (
                <p key={index} className="qsubtitle">
                  {contents}
                </p>
              );
            })}
          {add_sub_title && (
            <p className="addsubtitle qsubtitle">{add_sub_title}</p>
          )}
        </div>
        {isStickybt && (
          <div className="fixed-block" id="stickyblock">
            {footer_top}
          </div>
        )}
        {content}
        <div className="footer row">
          {footer_top && (
            <div className="d-flex justify-content-center">{footer_top}</div>
          )}
          <button
            className="btn btn-primary col-12"
            id="newloading"
            style={{ display: "none" }}
          >
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
          {footer_bottom && (
            <div className="col-12 nopadding_">{footer_bottom}</div>
          )}
        </div>
      </div>
    );
  }
}

export default new Questionnairetemplate();
