export const isInt = (value: string): boolean => {
  const n = parseInt(value);
  return isNaN(n) ? false : n.toFixed() === value;
};

export const isNum = (value: string): boolean => {
  if (value.indexOf(".") === -1) return isInt(value);
  const n = parseFloat(value);
  if (isNaN(n)) return false;
  return n.toFixed(value.length - value.indexOf(".") - 1) === value;
};
