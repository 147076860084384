import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { PatientContext, PatientQuestionnaire } from "@mh/api";
import { useTheme } from "@emotion/react";
import { ActiveTreatmentCard } from "../ActiveTreatmentCard";
import { IconArrowRight } from "../icons";
import {
  isInactiveTreatment,
  retrieveTreatmentCardRequirements
} from "../ActiveTreatmentCard/utils";

import "./LatestTreatment.scss";

interface LatestTreatmentProps {
  questionnaires: PatientQuestionnaire[];
  membershipsStatus?: string;
  loadQuestionnaires: () => void;
}

export const LatestTreatment = ({
  questionnaires,
  loadQuestionnaires,
  membershipsStatus
}: LatestTreatmentProps) => {
  const theme = useTheme();
  const patientContext = useContext(PatientContext);

  const shouldShowTreatment = (
    initialQuestionnaire: PatientQuestionnaire
  ): boolean => {
    const { subscription, questionnaire, latestScriptOrder } =
      retrieveTreatmentCardRequirements(initialQuestionnaire);

    return !isInactiveTreatment(
      questionnaire,
      initialQuestionnaire,
      subscription,
      latestScriptOrder?.date_placed
    );
  };

  const visibleQuestionnaires = questionnaires.filter(shouldShowTreatment);

  return (
    visibleQuestionnaires[0] && (
      <div className="treatment-container">
        <ActiveTreatmentCard
          patient={patientContext.data || undefined}
          initialQuestionnaire={visibleQuestionnaires[0]}
          onChange={loadQuestionnaires}
          membershipsStatus={membershipsStatus}
        />
        {visibleQuestionnaires.length > 1 && (
          <NavLink to="/treatments" className="treatments-link">
            <span>See all my treatments</span>
            <IconArrowRight width={16} fill={theme.color.secondary} />
          </NavLink>
        )}
      </div>
    )
  );
};
