import moment from "moment-timezone";
import { BRAND } from "@mh/core";
import { Button } from "../buttons";

export interface DatePickerProps {
  /** If true, disable all button in the picker. */
  disabled?: boolean;
  /** Optional array of `Day`s that will be disabled. */
  disabledDays?: moment.MomentInput[];
  /** Fired when the selected month is changed. */
  onChangeSelectedMonth: (month: moment.Moment) => void;
  /** Fired when the selected day is changed. */
  onChangeSelectedDay: (day: moment.Moment) => void;
  /** Optional maximum day, all days after this will be disabled. */
  maxDay?: moment.MomentInput;
  /** Optional minimum day, all days before this will be disabled. */
  minDay?: moment.MomentInput;
  /** The month to show. */
  selectedMonth: moment.MomentInput;
  /** Optional currently selected day. */
  selectedDay?: moment.MomentInput;
}

export const DatePicker = ({
  disabled = false,
  selectedMonth,
  onChangeSelectedMonth,
  selectedDay,
  onChangeSelectedDay,
  minDay,
  maxDay,
  disabledDays = []
}: DatePickerProps) => {
  const firstDayOfMonth = moment(selectedMonth).startOf("month");
  const lastDayOfMonth = moment(selectedMonth).endOf("month");

  /** Align selection to the start of the week (Sunday). */
  const firstDayOfSelection = moment(firstDayOfMonth).add(
    0 - firstDayOfMonth.day(),
    "day"
  );

  /** Pad the selection to the end of the last week (Saturday). */
  const lastDayOfSelection = moment(lastDayOfMonth).add(
    6 - lastDayOfMonth.day(),
    "day"
  );

  return (
    <div
      css={[
        (theme) => ({
          color: "white",
          fontSize: "14px",
          fontWeight: "500",
          borderRadius: "8px",
          backgroundColor:
            BRAND === "hubhealth" ? theme.color.light : theme.color.third,
          userSelect: "none",
          maxWidth: "340px"
        })
      ]}
    >
      <div
        css={(theme) => ({
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor:
            BRAND === "hubhealth"
              ? theme.color.background
              : BRAND === "youly"
              ? "#e84c42"
              : "#00000033",
          borderRadius: "8px 8px 0px 0px",
          padding: "8px 16px",
          color: BRAND === "hubhealth" ? theme.color.dark : "#FFF"
        })}
      >
        <Button
          color="#FFFFFF"
          disabled={disabled || !minDay || !firstDayOfMonth.isAfter(minDay)}
          onClick={() =>
            onChangeSelectedMonth(moment(firstDayOfMonth).subtract(1, "month"))
          }
          size="sm"
          variant="secondary-plain"
        >
          {"<"}
        </Button>
        <span>{moment(selectedMonth).format("MMMM YYYY")}</span>
        <Button
          color="#FFFFFF"
          disabled={disabled || !minDay || !lastDayOfMonth.isBefore(maxDay)}
          onClick={() =>
            onChangeSelectedMonth(moment(firstDayOfMonth).add(1, "month"))
          }
          size="sm"
          variant="secondary-plain"
        >
          {">"}
        </Button>
      </div>
      <div
        css={(theme) => ({
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "start",
          alignItems: "center",
          textAlign: "center",
          padding: "8px 16px",
          borderRadius: "0px 0px 8px 8px",
          backgroundColor: BRAND === "youly" ? "#d0a5cd" : "unset",
          border:
            BRAND === "hubhealth"
              ? `1px solid ${theme.color.background}`
              : "unset",
          ">*": {
            width: `${100 / 7}%`,
            margin: "4px 0px"
          }
        })}
      >
        {[...Array(7).keys()].map((index) => {
          const weekDay = moment(firstDayOfSelection).add(index, "day");
          return (
            <span
              key={index}
              css={{
                color: ["hubhealth", "youly"].includes(BRAND) ? "#000" : "unset"
              }}
            >
              {weekDay.format("ddd")}
            </span>
          );
        })}
        {[
          ...Array(
            lastDayOfSelection.diff(firstDayOfSelection, "days") + 1
          ).keys()
        ].map((index) => {
          const day = moment(firstDayOfSelection).add(index, "day");
          return (
            <Button
              active={day.isSame(selectedDay, "day")}
              key={index}
              color={
                ["hubhealth", "youly"].includes(BRAND) ? "#000" : "#FFFFFF"
              }
              disabled={
                disabled ||
                (minDay && day.isBefore(minDay, "day")) ||
                (maxDay && day.isAfter(maxDay, "day")) ||
                disabledDays.some((value) => day.isSame(value, "day"))
              }
              onClick={() => onChangeSelectedDay(day)}
              size="sm"
              variant="secondary-plain"
            >
              <span
                css={(theme) => ({
                  backgroundColor:
                    selectedDay && day.isSame(selectedDay, "day")
                      ? ["hubhealth", "youly"].includes(BRAND)
                        ? theme.color.primary
                        : "#00000044"
                      : undefined,
                  padding: "4px",
                  borderRadius: "2px",
                  fontWeight: "500",
                  color:
                    selectedDay && day.isSame(selectedDay, "day")
                      ? BRAND === "hubhealth"
                        ? "#FFF"
                        : "unset"
                      : "unset"
                })}
              >
                {day.format("D")}
              </span>
            </Button>
          );
        })}
      </div>
    </div>
  );
};
