import { API } from "@mh/api";

type ValidationResult = "valid" | "invalid" | "unknown";

export const EmailValidation = {
  /**
   * This is a check to validate the deliverability of an email address
   */
  email: async (email: string): Promise<ValidationResult> =>
    API.v2()
      .unauthenticated()
      .url(`/validate_email/?${new URLSearchParams({ email: email })}`)
      .get()
      .then((response) => (response.ok ? response.json() : undefined))
      .then((responseContent) => responseContent?.validity ?? "unknown")
};
