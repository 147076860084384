import { type ReactNode, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Interpolation, Theme } from "@emotion/react";
import type { ProductLike } from "@mh/api";
import { Flex, SafeImg } from "@mh/components";

import "./styles.scss";

interface ProductPreviewProps<ProductType extends ProductLike> {
  product: ProductType;
  /**
   * Renders a footer, with a controlled way of showing the "Read More" modal
   * @param showReadMoreModal The setShowReadMoreModal event
   */
  renderFooter?: (showReadMoreModal: (show: boolean) => void) => ReactNode;
  /** If present, displays the returned element over the product image */
  renderOverImage?: (product: ProductType) => JSX.Element;
  /** Event to fire when the image or title of product is clicked */
  onTitleClick?: () => void;
  isFeatured?: boolean;
}

const ProductPreview = <ProductType extends ProductLike>(
  props: ProductPreviewProps<ProductType>
): JSX.Element => {
  const [showReadMoreModal, setShowReadMoreModal] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [image] = props.product.images;

  const excludedCategories = ["otc", "all products"];
  const categoryNames = props.product.categories
    .filter(
      (category) =>
        category.name &&
        !excludedCategories.includes(category.name.toLowerCase())
    )
    .map((category) => category.name)
    .join(", ");

  const featuredCss: Interpolation<Theme> = {
    width: "calc(25% - 28px)",
    flexDirection: "column",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    padding: "10px",
    margin: "4px 20px 4px 4px",
    backgroundColor: "#FFF"
  };

  return (
    <>
      <div
        className="product-preview"
        css={(theme) => ({
          display: "flex",
          gap: "5px",
          width: "100%",
          padding: "16px",
          background: "#FFF !important",
          ...(props.isFeatured && featuredCss),
          [theme.mq.md]: featuredCss
        })}
      >
        <div
          css={(theme) => ({
            position: "relative",
            display: "flex",
            ...(props.onTitleClick && { cursor: "pointer" }),
            "& > img": {
              height: "100%",
              width: "100%",
              objectFit: "contain",
              // Aspect ratio is needed, otherwise large images cause the container to expand too much
              aspectRatio: "1"
            },
            [theme.mq.md]: {
              flex: "0 0 56%",
              maxHeight: "56%"
            }
          })}
          onClick={props.onTitleClick}
        >
          <SafeImg
            imagePath={image?.original}
            imgProps={{
              alt: props.product.title
            }}
          />
          {props.renderOverImage && (
            <div>{props.renderOverImage(props.product)}</div>
          )}
        </div>
        <div css={{ display: "flex", flexDirection: "column" }}>
          <div
            ref={containerRef}
            css={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingTop: 0,
              flex: "1"
            }}
          >
            <Flex flexDirection="column">
              <span
                css={{
                  fontSize: "12px",
                  fontFamily: "Inter",
                  color: "#808285",
                  marginBottom: "2px"
                }}
              >
                {categoryNames}
              </span>
              <span
                className="product-preview-title"
                onClick={props.onTitleClick}
                css={{ ...(props.onTitleClick && { cursor: "pointer" }) }}
              >
                {props.product.title}
              </span>
            </Flex>
            <Button
              variant="link"
              css={(theme) => ({
                width: "fit-content",
                padding: 0,
                color: theme.color.primary,
                textDecoration: "none",
                fontSize: "12px",
                fontWeight: "600"
              })}
              onClick={() => setShowReadMoreModal(true)}
            >
              Learn more
            </Button>
          </div>
          {props.renderFooter && props.renderFooter(setShowReadMoreModal)}
        </div>
      </div>
      <Modal
        show={showReadMoreModal}
        onHide={() => setShowReadMoreModal(false)}
      >
        <Modal.Header className="p-5" closeButton>
          <Modal.Title className="p-1">{props.product.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <span
            style={{ wordBreak: "break-word" }} // To avoid really long words flowing off the edge
            dangerouslySetInnerHTML={{ __html: props.product.description }} // strings come from our own server
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductPreview;
