import { Button, Flex, IconCheckCircle, Modal } from "@mh/components";

interface Props {
  code?: string;
  onClickFn: () => void;
  membershipsStatus: string;
  nibInternational?: boolean;
}

export const QuestionnaireCompletionModal = ({
  code,
  onClickFn,
  membershipsStatus,
  nibInternational
}: Props) => (
  <Modal show={true} onHide={() => null} size="md" variant="primary">
    <Flex flexDirection="column">
      <IconCheckCircle
        height="50px"
        css={(theme) => ({ color: theme.color.success })}
      />
      <div className="text-center mt-3 mb-3">
        <h2 className="mb-1">
          {code === "fill-a-script"
            ? "Submission Successful"
            : membershipsStatus === "active" || nibInternational
            ? "Your online assessment has been submitted"
            : "Payment successful!"}
        </h2>
      </div>
      <div
        className="text-center mb-3"
        css={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
      >
        <hr
          css={{
            border: "0.5pt solid #737373",
            maxWidth: "275px"
          }}
        />
      </div>
      <div
        className="text-center mb-1"
        css={{
          fontSize: "16px",
          maxWidth: "400px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        {code === "fill-a-script"
          ? 'You can now proceed to purchase by clicking "View your Treatment on the following page'
          : nibInternational
          ? "Please continue to your portal to proceed."
          : membershipsStatus === "active"
          ? "Please continue to your portal to review the status of your request"
          : "Thanks for completing your online assessment. There are just a couple steps to complete before we can review your request."}
      </div>
      <Modal.Actions>
        <div
          css={(theme: any) => ({
            width: "222px",
            marginLeft: "auto",
            marginRight: "auto",
            [theme.mq.md]: {
              width: "275px"
            }
          })}
        >
          <Button key={0} fullWidth onClick={onClickFn} variant="primary">
            Go to my portal
          </Button>
        </div>
      </Modal.Actions>
    </Flex>
  </Modal>
);
