import React, { useState } from "react";
import { PatientAPI } from "@mh/api";
import { isInt } from "@mh/core";
import { Button, Toast, Modal } from "@mh/components";

export interface BloodPressure {
  success: boolean;
}

export interface BloodPressureModalProps {
  questionnaireId: number;
  onHide: () => void;
  onUpdate: () => void;
}

export interface ToastCloseButtonProps {
  closeToast: () => void;
}

export const BloodPressureModal: React.FC<BloodPressureModalProps> = (
  props
) => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [systolic, setSystolic] = useState<number | null>(null);
  const [diastolic, setDiastolic] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const sendBloodPressure = async (
    questionnaireId: number,
    systolic: number,
    diastolic: number
  ): Promise<boolean> =>
    PatientAPI.updatePatientQuestionnaire(questionnaireId, {
      blood_pressure_systolic: systolic,
      blood_pressure_diastolic: diastolic
    })
      .then(() => true)
      .catch(() => {
        setErrorMessage(
          "Sorry, we ran into an error while saving your response. Please try again."
        );
        return false;
      });

  const onInputBloodPressure = (
    e: any,
    updateType: "systolic" | "diastolic"
  ) => {
    e.preventDefault();
    setErrorMessage(null);

    const nan = !e.target?.value || !isInt(e.target.value);
    const invalidInput =
      (updateType === "systolic" &&
        // @ts-ignore
        (parseInt(e.target.value) <= diastolic || !isInt(diastolic))) ||
      (updateType === "diastolic" &&
        // @ts-ignore
        (parseInt(e.target.value) >= systolic || !isInt(systolic)));

    if (nan || invalidInput) {
      setErrorMessage(
        nan
          ? "Please review the readings provided - the inputs must be entered as numbers only"
          : "Please review the readings provided - the Systolic (top) number should be higher than the Diastolic (bottom) number"
      );
    }

    const update = updateType === "systolic" ? setSystolic : setDiastolic;
    update(e.target.value);
    setButtonDisabled(nan || invalidInput);
    return true;
  };

  const CloseButton: React.FC<ToastCloseButtonProps> = (toastProps) => {
    return (
      <button
        className="Toastify__close-button Toastify__close-button--light"
        type="button"
        aria-label="close"
        onClick={() => {
          toastProps.closeToast();
          props.onUpdate();
        }}
      >
        <svg aria-hidden="true" viewBox="0 0 14 16">
          <path
            fillRule="evenodd"
            d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
          ></path>
        </svg>
      </button>
    );
  };

  return (
    <Modal show={true} size="md" onHide={() => props.onHide()}>
      <Modal.Title
        horizontalSize={"sm"}
        // @ts-ignore
        buttonStyle={{
          padding: "0px"
        }}
        // @ts-ignore
        css={(theme) => ({
          marginBottom: "8px",
          [theme.mq.sm]: {
            marginBottom: "16px"
          }
        })}
        onClose={() => props.onHide()}
      >
        <span
          css={{
            fontWeight: "600"
          }}
        >
          Blood pressure
        </span>
      </Modal.Title>

      <div
        className="inner-content"
        css={(theme) => ({
          paddingBottom: "16px",
          [theme.mq.sm]: {
            paddingBottom: "24px"
          }
        })}
      >
        <span>
          What was your blood pressure (BP) reading? Please enter the systolic
          (top number) and diastolic (bottom number) readings. Note that the
          systolic number is always the highest.
        </span>
      </div>

      <div
        css={(theme) => ({
          marginLeft: "auto",
          marginRight: "auto",
          [theme.mq.sm]: {
            paddingLeft: "0px",
            paddingRight: "0px"
          }
        })}
      >
        <div>
          <label
            className="small asterisk noAsteriskPadding"
            css={{ paddingBottom: "4px", fontSize: "10px", fontWeight: "600" }}
            htmlFor="systolic"
          >
            Systolic (mmHg)
          </label>
          <input
            css={{
              padding: "8px 16px",
              height: "40px",
              marginBottom: "0px"
            }}
            required
            id="systolic"
            type="systolic"
            placeholder="Top number"
            className="input"
            onChange={(e) => onInputBloodPressure(e, "systolic")}
          />
        </div>
        <div
          css={(theme) => ({
            paddingTop: "16px",
            [theme.mq.sm]: {
              paddingTop: "24px"
            }
          })}
        >
          <label
            className="small asterisk noAsteriskPadding"
            css={{ paddingBottom: "4px", fontSize: "10px", fontWeight: "600" }}
            htmlFor="diastolic"
          >
            Diastolic (mmHg)
          </label>
          <input
            css={{
              padding: "8px 16px",
              height: "40px",
              marginBottom: "0px"
            }}
            required
            id="diastolic"
            type="diastolic"
            placeholder="Bottom number"
            className="input"
            onChange={(e) => onInputBloodPressure(e, "diastolic")}
          />
        </div>
      </div>
      <div
        css={(theme) => ({
          marginTop: "16px",
          textAlign: "center",
          [theme.mq.sm]: {
            marginTop: "24px"
          }
        })}
      >
        <Button
          disabled={buttonDisabled}
          fullWidth={true}
          variant="primary"
          css={{
            maxWidth: "348.83px",
            fontWeight: "500"
          }}
          onClick={async () => {
            setErrorMessage(null);
            const result = await sendBloodPressure(
              props.questionnaireId,
              // @ts-ignore
              systolic,
              diastolic
            );
            if (result) {
              // @ts-ignore
              props.onHide(false);
              props.onUpdate();
              Toast.success("Blood pressure reading submitted.", {
                // @ts-ignore
                closeButton: CloseButton
              });
            }
          }}
        >
          Continue
        </Button>
        {errorMessage && (
          <div
            css={{
              fontSize: "12px",
              color: "red",
              width: "348.83px",
              textAlign: "left",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "5px"
            }}
          >
            {errorMessage}
          </div>
        )}
      </div>
    </Modal>
  );
};
