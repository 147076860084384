import { ComponentPropsWithoutRef } from "react";

export const IconCircleExclamation = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 0.740234C5.84375 0.740234 0.5 6.13086 0.5 12.7402C0.5 19.3965 5.84375 24.7402 12.5 24.7402C19.1094 24.7402 24.5 19.3965 24.5 12.7402C24.5 6.13086 19.1094 0.740234 12.5 0.740234ZM11.375 7.86523C11.375 7.25586 11.8438 6.74023 12.5 6.74023C13.1094 6.74023 13.625 7.25586 13.625 7.86523V13.8652C13.625 14.5215 13.1094 14.9902 12.5 14.9902C11.8438 14.9902 11.375 14.5215 11.375 13.8652V7.86523ZM12.5 19.4902C11.6562 19.4902 11 18.834 11 18.0371C11 17.2402 11.6562 16.584 12.5 16.584C13.2969 16.584 13.9531 17.2402 13.9531 18.0371C13.9531 18.834 13.2969 19.4902 12.5 19.4902Z"
      fill="currentColor"
    />
  </svg>
);
