import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { NavLink, useMatches, useNavigate } from "react-router-dom";
import { API, MessagingAPI, PatientAPI, User, UserContext } from "@mh/api";
import { BasketCart, BasketDrawer } from "@mh/basket";
import {
  Breadcrumbs,
  Button,
  IconBell,
  IconClock,
  IconExit,
  IconHeart,
  IconHeartPulse,
  IconHome,
  IconMessagesOutline,
  IconProfile,
  IconQuestion,
  IconShop,
  LogoBrand,
  Navigation,
  NotificationOverlay
} from "@mh/components";
import { BRAND, BRAND_NAME } from "@mh/core";
import { ContactUsModal } from "@mh/messaging";
import { ReactRouterMatch } from "../../types";
import { getInitials } from "../../utils";

/** How often to check for notifications, in milliseconds. */
const NOTIFICATION_CHECK_INTERVAL = 30000;

const UnauthenticatedAppContainer = ({ children }: PropsWithChildren) => {
  const BrandLogo = () => (
    <div
      css={(theme) => ({
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        ":before": {
          content: '""',
          position: "absolute",
          bottom: 0,
          width: 103,
          borderBottom:
            BRAND !== "stagger" ? `2px solid ${theme.color.primary}` : "none"
        }
      })}
    >
      <LogoBrand
        css={(theme) => ({
          width: "100%",
          maxWidth: "80%",
          marginTop: 64,
          marginBottom: "30px",
          [theme.mq.md]: {
            maxWidth: 400,
            marginTop: 128
          }
        })}
      />
    </div>
  );

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <BrandLogo />
      {children}
    </div>
  );
};

interface AppContainerProps {
  /** Title to show in the page header. */
  title: React.ReactNode;
}

const AuthenticatedAppContainer = ({
  children,
  title
}: PropsWithChildren<AppContainerProps>) => {
  /** If true, will open the basket drawer. */
  const [isBasketDrawerOpen, setIsBasketDrawerOpen] = useState<boolean>(false);
  /** True to enable the notification for messages. */
  const [messagesNotification, setMessagesNotification] =
    useState<boolean>(false);
  /** True to enable the notification for notifications/repeats. */
  const [notificationsNotification, setNotificationsNotification] =
    useState<boolean>(false);
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  /**
   * Periodically check for any notifications.
   */
  useEffect(() => {
    const checkForNotifications = async () =>
      Promise.all([
        MessagingAPI.getUnreadMessagesCount().then((data) =>
          setMessagesNotification(data.unread_messages_count > 0)
        ),
        PatientAPI.getNotifications()
          .then((data) =>
            setNotificationsNotification(
              Array.isArray(data.repeats) && data.repeats.length > 0
            )
          )
          .catch(() => setNotificationsNotification(false))
      ]);

    checkForNotifications();
    const interval = setInterval(
      checkForNotifications,
      NOTIFICATION_CHECK_INTERVAL
    );
    return () => clearInterval(interval);
  }, []);

  /**
   * Log a user out of the app. Will redirect them to the login page.
   */
  const logout = () => {
    API.v1().url("/users/logout/").get();
    User.logout();
  };

  const menu = (
    <Navigation.Menu>
      <Navigation.MenuItem text="Home" icon={<IconHome />} to="/home" />
      <Navigation.MenuItem
        text="Treatments"
        icon={<IconHeart />}
        to="/treatments"
      />
      <Navigation.MenuItem text="Shop" icon={<IconShop />} to="/shop" />
      <Navigation.MenuItem
        text="Notifications"
        data-testid="app-container-notifications-menu-item"
        icon={<IconBell />}
        to="/notifications"
        notification={notificationsNotification}
      />
      <Navigation.MenuItem text="History" icon={<IconClock />} to="/history" />
      <Navigation.MenuItem text="FAQs" icon={<IconQuestion />} to="/faqs" />
      <Navigation.MenuItem
        text="Messages"
        icon={<IconMessagesOutline />}
        to="/messaging"
        notification={messagesNotification}
      />
      <Navigation.MenuItem
        text="What we treat"
        icon={<IconHeartPulse />}
        to="/what-we-treat"
      />
      <Navigation.Spacer />
      <Navigation.MenuItem
        text="Profile"
        icon={<IconProfile />}
        to="/profile"
      />
      <Navigation.MenuItem
        text="Logout"
        icon={<IconExit />}
        onClick={logout}
        to="/"
      />
    </Navigation.Menu>
  );

  const basketCart = <BasketCart onClick={() => setIsBasketDrawerOpen(true)} />;

  const contactUsButton = (
    <Button
      data-testid="contact-us-modal"
      onClick={() => setShowContactUsModal(true)}
      size="sm"
    >
      <IconMessagesOutline width="16px" />
      <span css={{ whiteSpace: "nowrap", marginLeft: "8px" }}>Contact us</span>
    </Button>
  );

  const notificationsIconButton = (
    <NavLink to="/notifications">
      {({ isActive }) => (
        <Button
          active={isActive}
          data-testid="app-container-notifications-icon-button"
          variant="primary-plain"
        >
          <NotificationOverlay notification={notificationsNotification}>
            <IconBell height="16px" />
          </NotificationOverlay>
        </Button>
      )}
    </NavLink>
  );

  return (
    <>
      <Navigation
        actions={
          <>
            <div
              css={(theme) => ({
                display: "flex",
                alignItems: "center",
                [theme.mq.md]: {
                  display: "none"
                }
              })}
            >
              {basketCart}
              <Navigation.Divider />
              {notificationsIconButton}
              {contactUsButton}
            </div>
            <div
              css={(theme) => ({
                display: "none",
                [theme.mq.md]: {
                  display: "flex",
                  alignItems: "center"
                }
              })}
            >
              {contactUsButton}
              {basketCart}
              <Navigation.Divider />
              {notificationsIconButton}
              {(userContext.data?.first_name ||
                userContext.data?.last_name) && (
                <>
                  <Navigation.Divider />
                  <NavLink to="/profile">
                    {({ isActive }) => (
                      <Button active={isActive} variant="primary-plain">
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "8px"
                          }}
                        >
                          <div className="user-avatar avatar-self">
                            {getInitials(
                              `${userContext.data?.first_name} ${userContext.data?.last_name}`
                            )}
                          </div>
                          <div
                            css={{
                              fontSize: "14px",
                              maxWidth: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap"
                            }}
                          >
                            {`${userContext.data?.first_name || ""} ${
                              userContext.data?.last_name || ""
                            }`.trim()}
                          </div>
                        </div>
                      </Button>
                    )}
                  </NavLink>
                </>
              )}
            </div>
          </>
        }
        menu={menu}
        title={title}
      >
        {children}
      </Navigation>
      <BasketDrawer
        isOpen={isBasketDrawerOpen}
        onToggle={() => setIsBasketDrawerOpen(false)}
        onCheckout={() => navigate("/checkout")}
      />
      {/** This should display as a modal where applicable */}
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </>
  );
};

export const AppContainer = ({ children }: PropsWithChildren) => {
  const [title, setTitle] = useState<React.ReactNode>("");
  const matches = useMatches() as ReactRouterMatch[];

  /** Build breadcrumbs and document title based on matched routes. */
  useEffect(() => {
    const matchesWithTitles = matches.filter((match) => match.handle?.title);
    if (matchesWithTitles.length > 0) {
      setTitle(
        <Breadcrumbs
          includeHome={matches.some(
            (match) => match.handle?.breadcrumbOptions?.showHome
          )}
          crumbs={matchesWithTitles.map((match) => ({
            title: match.handle?.title!,
            to: match.pathname
          }))}
        />
      );
      const documentTitle = matchesWithTitles
        .map((match) => match.handle?.title!)
        .reverse()
        .join(" - ");
      document.title = `${documentTitle} - ${BRAND_NAME} Portal`;
    } else {
      setTitle(
        <Breadcrumbs
          crumbs={[
            { title: `Welcome ${User.info()?.name.first_name}`, to: "/" }
          ]}
          includeHome={false}
        />
      );
      document.title = `${BRAND_NAME} Portal`;
    }
  }, [matches]);

  return (
    <>
      {User.loggedIn() && (
        <AuthenticatedAppContainer title={title}>
          {children}
        </AuthenticatedAppContainer>
      )}
      {!User.loggedIn() && (
        <UnauthenticatedAppContainer>{children}</UnauthenticatedAppContainer>
      )}
    </>
  );
};
