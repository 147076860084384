import React from "react";

export interface EditableBaseProps {
  children?: React.ReactNode;
  /* If true, will render `nonEditableComponent`. Otherwise, will render the component's `children`. */
  isEditing?: boolean;
  /* Will render this component when `isEditing` is truthy. */
  nonEditableComponent?: React.ReactNode;
}

export const EditableBase = ({
  children,
  isEditing,
  nonEditableComponent
}: EditableBaseProps) => <>{isEditing ? children : nonEditableComponent}</>;
