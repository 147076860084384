import { LogoBrand } from "@mh/components";

interface EmployeeHealthCheckSplashLogoProps {
  hideBack: boolean;
  /** go back function  */
  back: () => void;
}

export const EmployeeHealthCheckSplashLogo = (
  props: EmployeeHealthCheckSplashLogoProps
) => {
  return (
    <div
      css={(theme) => ({
        background: "white",
        paddingTop: "34px",
        paddingBottom: "34px",
        [theme.mq.lg]: {
          paddingTop: "40px",
          paddingBottom: "40px"
        }
      })}
    >
      {!props.hideBack && (
        <button
          onClick={props.back}
          css={(theme) => ({
            position: "absolute",
            border: "unset",
            background: "unset",
            left: "5%",
            [theme.mq.lg]: {
              left: "20%"
            }
          })}
        >
          <div className="d-flex flex-row">
            <div>
              <img
                css={{
                  height: "12px",
                  marginBottom: "2.5px"
                }}
                src="/assets/images/com/arrow-left.svg"
              />
            </div>
            <div
              className="txt-back"
              css={(theme) => ({
                display: "none",
                [theme.mq.lg]: {
                  display: "inline-block",
                  fontSize: "16px",
                  paddingLeft: "8px"
                }
              })}
            >
              Back
            </div>
          </div>
        </button>
      )}
      <div
        css={{
          maxWidth: "130px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <LogoBrand />
      </div>
    </div>
  );
};
