/**
 * Create new File object from a Data URL.
 * @param {string} dataUrl Data URL to become the content of the new file.
 * @param {string} name Filename of the new file.
 * @param {string} type Mime type of the new file.
 * @param {number} lastModified Last modified timestamp of the new file.
 * @returns {File} A new File object.
 */
export const createFileFromDataUrl = async (
  dataUrl: string,
  name: string,
  type?: string,
  lastModified?: number
): Promise<File> => {
  const response = await fetch(dataUrl);
  const blob = await response.blob();
  const file = new File([blob], name, {
    type: type ?? blob.type,
    lastModified: lastModified ?? Date.now()
  });
  return file;
};

/**
 * Retrieve a Data URL from a file object.
 * @param {File} file The file object.
 * @returns {string} A Data URL representing the file's contents.
 */
export const readDataUrlFromFile = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        /** `readAsDataURL` ensures the result is a base64-encoded string. */
        resolve(reader.result as string);
      },
      false
    );
    reader.addEventListener(
      "error",
      () => reject(new Error(`Could not read file: ${file.name}`)),
      false
    );
    reader.readAsDataURL(file);
  });

/**
 * Resize an image in [dataUrl] to a size no larger than [maxWidth] by [maxHeight].
 * Preserves aspect ratio and won't resize images already smaller than the target size.
 * @param {string} dataUrl Data URL of the image to resize.
 * @param {string} type Mime type of the image.
 * @param {number} maxWidth Max width for the resized image.
 * @param {number} maxHeight Max height for the resized image.
 * @returns {string} Data URL of the resized image.
 */
const resizeImageDataUrl = (
  dataUrl: string,
  type: string,
  maxWidth: number,
  maxHeight: number
): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const img = document.createElement("img");
    img.addEventListener(
      "load",
      () => {
        let { width, height } = img;
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round(height * (maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round(width * (maxHeight / height));
            height = maxHeight;
          }
        }
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Could not get context."));
          return;
        }

        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL(type);
        resolve(dataUrl);
      },
      false
    );
    img.addEventListener(
      "error",
      () => reject(new Error("Could not load image")),
      false
    );
    img.src = dataUrl;
  });

/**
 * Resize an image in [file] to a size no larger than [maxWidth] by [maxHeight].
 * Preserves aspect ratio and won't resize images already smaller than the target size.
 * @param {File} file The file object for an image.
 * @param {number} maxWidth Max width for the resized image.
 * @param {number} maxHeight Max height for the resized image.
 * @returns {File} A new file object containing the resized image.
 */
export const resizeImageFile = async (
  file: File,
  maxWidth: number,
  maxHeight: number
): Promise<File> => {
  const dataUrl = await readDataUrlFromFile(file);
  const resizedDataUrl = await resizeImageDataUrl(
    dataUrl,
    file.type,
    maxWidth,
    maxHeight
  );
  const newImageFile = await createFileFromDataUrl(
    resizedDataUrl,
    file.name,
    file.type,
    file.lastModified
  );

  return newImageFile;
};
