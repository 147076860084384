import { API } from "../../../api";
import type { SubscriptionPriceResponse } from "./types";

/**
 * API class to handle the shop/api/views/subscription.py views
 */
export class SubscriptionPriceAPI {
  /**
   * Loads the current price a patient would pay for the next order of their subscription.
   * @param subscriptionId The ID of the patient's subscription for which to load the price.
   * @returns The price of the patient's next subscription order, of type {@link SubscriptionPriceResponse}.
   */
  static getSubscriptionPrice = async (
    subscriptionId: number
  ): Promise<SubscriptionPriceResponse> =>
    API.url(`/shop/api/subscriptions/${subscriptionId}/`)
      .get()
      .then((r) => {
        if (!r.ok) throw r;
        return r.json();
      });

  /** update order on demand
   *
   * @param basketId basket id
   * @returns line ids as key and value as order on demand
   */
  static getOrderOnDemand = async (
    basketId: number
  ): Promise<{ [lineId: number]: boolean }> =>
    API.v2()
      .url(`/treatment/order-on-demand/${basketId}`)
      .get()
      .then((r) => r.json());

  /** update order on demand
   *
   * @param basketId basket id
   * @param lineId basket line id
   * @param orderOnDemand order on demand value
   * @returns ture if update is success otherwise, return false
   */
  static setOrderOnDemand = async (
    basketId: number,
    lineId: number,
    orderOnDemand: boolean
  ): Promise<Boolean> =>
    API.v2()
      .url(`/treatment/order-on-demand/${basketId}`)
      .body({ order_on_demand: orderOnDemand || false, line_id: lineId })
      .post()
      .then((r) => r.ok ?? false);
}
