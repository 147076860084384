import React, { useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { PatientContext } from "@mh/api";
import { Button, Modal, ModalProps } from "@mh/components";
import { ValidationResult } from "@mh/core";
import {
  MedicareExpMonth,
  MedicareExpYear,
  MedicareIRN,
  MedicareNumber,
  MedicareValues,
  validateMedicareValues
} from "../../../components/forms/Medicare";
import { ProfileFormError } from "../../forms/ProfileForm";

interface MedicareModalProps extends Omit<ModalProps, "title"> {
  /** Fires on successful submission. */
  onSubmitSuccess: () => void;
}

export const MedicareModal = ({
  onSubmitSuccess,
  ...modalProps
}: MedicareModalProps) => {
  const {
    isFetching,
    data: patient,
    update,
    error: apiError
  } = useContext(PatientContext);

  const [expiryMonth, expiryYear] = useMemo(() => {
    if (typeof patient?.medicare_expiry === "string") {
      // The medicare_expiry is of the form "YYYY-MM-DD", parse out only
      // the month and year, discarding the day
      const date = moment(patient.medicare_expiry);
      return [date.format("MM"), date.format("YYYY")];
    }
    return [null, null];
  }, [patient]);

  const initialValues = {
    number: patient?.medicare_number || "",
    irn: patient?.medicare_number_irn || "",
    expiryMonth: expiryMonth || "",
    expiryYear: expiryYear || ""
  };

  const navigate = useNavigate();

  const [values, setValues] = useState<MedicareValues>(initialValues);

  const [errors, setErrors] = useState<ProfileFormError<MedicareValues> | null>(
    null
  );

  return (
    <Modal {...modalProps}>
      <Modal.Title onClose={modalProps.onHide}>Medicare details</Modal.Title>
      <div
        css={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          gap: "8px"
        }}
      >
        {apiError ? (
          <div>
            <h2
              style={{
                color: "red",
                paddingTop: "10px",
                paddingBottom: "20px",
                textAlign: "center"
              }}
            >
              Service not reachable! Please try again.
            </h2>
            <Button
              fullWidth
              onClick={() => navigate(0)}
              variant={"primary-outline"}
            >
              Close
            </Button>
          </div>
        ) : (
          <>
            <MedicareNumber
              id="id-verify"
              isEditing={true}
              isFetching={isFetching}
              value={values.number}
              onChange={(e) =>
                setValues({ ...values, number: e.currentTarget.value })
              }
              error={errors?.number}
              required={true}
            />
            <MedicareIRN
              id="id-verify"
              isEditing={true}
              isFetching={isFetching}
              value={values.irn}
              onChange={(e) =>
                setValues({ ...values, irn: e.currentTarget.value })
              }
              error={errors?.irn}
              required={true}
            />
            <div css={{ display: "flex", gap: "20px" }}>
              <MedicareExpMonth
                id="id-verify"
                isEditing={true}
                isFetching={isFetching}
                value={values.expiryMonth}
                onChange={(e) =>
                  setValues({ ...values, expiryMonth: e.currentTarget.value })
                }
                error={errors?.expiryMonth}
                required={true}
              />
              <MedicareExpYear
                id="id-verify"
                isEditing={true}
                isFetching={isFetching}
                value={values.expiryYear}
                onChange={(e) =>
                  setValues({ ...values, expiryYear: e.currentTarget.value })
                }
                error={errors?.expiryYear}
                required={true}
              />
            </div>
            <div
              css={{
                display: "flex",
                justifyContent: "space-evenly",
                gap: "16px",
                marginTop: "auto"
              }}
            >
              <Button
                fullWidth
                onClick={modalProps.onHide}
                variant={"primary-outline"}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                onClick={async () => {
                  // Validate
                  const error = validateMedicareValues(values);
                  const errorReducer = (
                    running: boolean,
                    current: ValidationResult
                  ) => current !== null || running;

                  if (Object.values(error).reduce(errorReducer, false)) {
                    setErrors(error);
                    return;
                  }

                  // Save
                  await update({
                    medicare_number: values.number,
                    medicare_number_irn: values.irn,
                    medicare_expiry: `${values.expiryYear}-${values.expiryMonth}-01`
                  });

                  onSubmitSuccess();
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
