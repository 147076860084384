import {
  API,
  CheckVerificationCodeRequest,
  CreateCorporateMemberRequest,
  CreateVerificationCodeRequest,
  SignupCorporateMemberRequest
} from "@mh/api";

export class CorporateMembershipAPI {
  static createVerificationCode = async (
    request: CreateVerificationCodeRequest
  ): Promise<Response> =>
    API.unauthenticated()
      .v2()
      .url("/memberships/create_verification_code/")
      .body(request)
      .post();

  static checkVerificationCode = async (
    request: CheckVerificationCodeRequest
  ): Promise<Response> =>
    API.unauthenticated()
      .v2()
      .url("/memberships/check_verification_code/")
      .body(request)
      .post();

  static createCorporateMember = async (
    request: CreateCorporateMemberRequest
  ): Promise<Response> =>
    API.unauthenticated()
      .v2()
      .url("/memberships/create_corporate_member/")
      .body(request)
      .post();

  static signupExistingUser = async (
    request: SignupCorporateMemberRequest
  ): Promise<Response> =>
    API.authenticateIfProvided()
      .v2()
      .url("/memberships/corporate_signup_existing_user/")
      .body(request)
      .post();
}
