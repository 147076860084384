import { Doctor } from "@mh/api";
import {
  Button,
  Container,
  DisplayHeading,
  HorizontalDivider
} from "@mh/components";

interface ClinicianLetterProps {
  directions: string;
  doctor: Doctor;
  onClickContinue: () => void;
  treatmentTitle: string;
  clinicianNote: string;
}

export const ClinicianLetter = ({
  directions,
  doctor,
  onClickContinue,
  treatmentTitle,
  clinicianNote
}: ClinicianLetterProps) => (
  <Container borderColor="secondary">
    <DisplayHeading underline="none">Treatment details</DisplayHeading>
    <HorizontalDivider />
    <div
      css={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "left",
        alignItems: "center",
        gap: "16px",
        marginBottom: "16px"
      }}
    >
      <img
        width={64}
        height={64}
        src={doctor.user.profile_image || "/assets/images/com/doctor.png"}
        css={{
          borderRadius: "50%"
        }}
      />
      <div css={{ fontSize: "14px" }}>
        <div css={{ fontWeight: 700 }}>{doctor.formal_name}</div>
        <div css={{ textTransform: "capitalize" }}>
          {doctor.occupation_name}
        </div>
      </div>
    </div>
    <div css={{ maxWidth: "850px" }}>
      <div css={{ paddingTop: "16px", fontWeight: "500" }}>
        Medication prescribed: {treatmentTitle}
      </div>

      <div css={{ paddingTop: "16px" }}>
        <div css={{ fontWeight: "600", paddingBottom: "4px" }}>
          Direction for use:
        </div>
        <div
          css={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: directions || ""
          }}
        />
      </div>

      <div css={{ paddingTop: "16px" }}>
        <div css={{ fontWeight: "600", paddingBottom: "4px" }}>
          Notes from your clinician:
        </div>
        <p>{clinicianNote}</p>
      </div>

      <div css={{ paddingTop: "16px", paddingBottom: "32px" }}>
        If you have any urgent concerns, please contact a medical professional
        as soon as possible. For any other questions, feel free to reach out via
        your patient portal at any time.
      </div>
    </div>
    <Button
      css={(theme) => ({
        width: "100%",
        [theme.mq.md]: {
          width: "50%"
        }
      })}
      onClick={onClickContinue}
    >
      Continue to medication details
    </Button>
  </Container>
);
