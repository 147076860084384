import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Address, ShippingAddressValues } from "@mh/components";

import { QuestionnaireContentComponentProps } from ".";

import "./QCAddress.scss";

export const QCAddress = (props: QuestionnaireContentComponentProps) => {
  const [address, setAddress] = useState<ShippingAddressValues>(
    props.initialValue ?? {
      line1: "",
      line2: "",
      suburb: "",
      state: "",
      postcode: ""
    }
  );
  useEffect(() => props.onChange(address), [address]);

  return (
    <Col className="qc-address-container">
      <Address autoFocus={true} value={address} onChange={setAddress} />
    </Col>
  );
};
