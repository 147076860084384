import { useState } from "react";
import { ImageNotAvailable } from "./ImageNotAvailable";

const constructImageUrl = (url: string | undefined): string | undefined =>
  url === undefined
    ? url
    : !url.startsWith("http")
    ? `${import.meta.env.VITE_API_URL}${url}/`
    : url;

interface SafeImgProps {
  imagePath: string | undefined;
  imgProps?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  errProps?: React.SVGProps<SVGSVGElement>;
}

export const SafeImg = (props: SafeImgProps) => {
  const [resolvedSourcePath, setResolvedSourcePath] = useState<
    string | undefined
  >(constructImageUrl(props.imagePath));

  if (resolvedSourcePath === undefined) {
    return (
      <ImageNotAvailable
        {...((props.errProps ??
          props.imgProps) as React.SVGProps<SVGSVGElement>)}
      />
    );
  }

  return (
    <img
      src={resolvedSourcePath}
      {...props.imgProps}
      onError={(_e) => {
        // Using non arrow function here to assist with debugging _e
        setResolvedSourcePath(undefined);
      }}
    />
  );
};
