import { ProfileForm } from "../ProfileForm";

export const ADDRESS_STATE_OPTIONS: [string, string][] = [
  ["act", "ACT"],
  ["nsw", "NSW"],
  ["nt", "NT"],
  ["qld", "QLD"],
  ["sa", "SA"],
  ["tas", "TAS"],
  ["vic", "VIC"],
  ["wa", "WA"]
];

export interface ShippingAddressNewProps {
  values: any;
  setValues: any;
  error: any;
  isEditing: any;
  id: any;
  isFetching: any;
  showManualAddress: boolean;
}

export const ShippingAddressNew = ({
  values,
  setValues,
  error,
  isEditing,
  id,
  isFetching,
  showManualAddress
}: ShippingAddressNewProps) => {
  return (
    (!isEditing || showManualAddress) && (
      <>
        <div className="d-flex flex-lg-row flex-column">
          <div
            className="d-flex fullwidth"
            css={(theme) => ({
              [theme.mq.md]: {
                marginRight: "24px"
              }
            })}
          >
            <ProfileForm.Control
              label="Address Line 1"
              data-testid={`${id}-address-line-1`}
              disabled={isFetching}
              isEditing={isEditing}
              error={error?.line1}
              value={values.line1}
              onChange={(e) =>
                setValues({ ...values, line1: e.currentTarget.value })
              }
            />
          </div>
          <div className="d-flex fullwidth">
            <ProfileForm.Control
              label="Address Line 2"
              data-testid={`${id}-address-line-2`}
              disabled={isFetching}
              isEditing={isEditing}
              error={error?.line2}
              value={values.line2}
              onChange={(e) =>
                setValues({ ...values, line2: e.currentTarget.value })
              }
            />
          </div>
        </div>
        <div className="d-flex flex-lg-row flex-column">
          <div
            className="d-flex fullwidth"
            css={(theme) => ({
              [theme.mq.md]: {
                marginRight: "24px"
              }
            })}
          >
            <ProfileForm.Control
              label="Suburb"
              data-testid={`${id}-suburb`}
              disabled={isFetching}
              isEditing={isEditing}
              error={error?.suburb}
              value={values.suburb}
              onChange={(e) =>
                setValues({ ...values, suburb: e.currentTarget.value })
              }
            />
          </div>
          <div
            className="d-flex fullwidth"
            css={(theme) => ({
              [theme.mq.md]: {
                marginRight: "24px"
              }
            })}
          >
            <ProfileForm.Select
              label="State"
              data-testid={`${id}-state`}
              disabled={isFetching}
              isEditing={isEditing}
              error={error?.state}
              value={values?.state}
              onChange={(e) =>
                setValues({ ...values, state: e.currentTarget.value })
              }
            >
              <option disabled value=""></option>
              {ADDRESS_STATE_OPTIONS.map(([value, display], index) => (
                <option key={`${index}_${value}`} value={value}>
                  {display}
                </option>
              ))}
            </ProfileForm.Select>
          </div>
          <div
            className="d-flex fullwidth"
            css={(theme) => ({
              [theme.mq.md]: {
                marginRight: "24px"
              }
            })}
          >
            <ProfileForm.Control
              label="Postcode"
              data-testid={`${id}-postcode`}
              disabled={isFetching}
              isEditing={isEditing}
              error={error?.postcode}
              value={values?.postcode}
              onChange={(e) =>
                setValues({ ...values, postcode: e.currentTarget.value })
              }
            />
          </div>
        </div>
        <div
          className="w-100"
          css={(theme) => ({
            height: "1px",
            backgroundColor: "#D9D9D9",
            marginTop: "8px",
            [theme.mq.md]: {
              marginTop: "16px"
            }
          })}
        />
      </>
    )
  );
};
