import { useContext } from "react";
import { Patient, PatientContext } from "@mh/api";
import { Validation } from "@mh/core";
import { PhoneField } from "@mh/components";

import { ProfileCard } from "./ProfileCard";
import { ProfileField, ProfileForm } from "./";
import { ADDRESS_STATE_OPTIONS } from "@mh/components/src/ShippingAddress/ShippingAddress";

type RegularGPValues = Pick<
  Patient,
  | "gp_first_name"
  | "gp_last_name"
  | "gp_phone"
  | "gp_unit"
  | "gp_streetnumber"
  | "gp_street"
  | "gp_suburb"
  | "gp_state"
  | "gp_postcode"
>;

export const RegularGP = () => {
  const { isFetching, data: patient, update } = useContext(PatientContext);

  return (
    <ProfileForm<RegularGPValues>
      title="Regular GP"
      id="gp"
      initialValues={{
        gp_first_name: patient?.gp_first_name || "",
        gp_last_name: patient?.gp_last_name || "",
        gp_phone: patient?.gp_phone || "",
        gp_unit: patient?.gp_unit || "",
        gp_streetnumber: patient?.gp_streetnumber || "",
        gp_street: patient?.gp_street || "",
        gp_suburb: patient?.gp_suburb || "",
        gp_state: patient?.gp_state || "",
        gp_postcode: patient?.gp_postcode || ""
      }}
      isFetching={isFetching}
      onSave={async (values) => {
        await update({
          gp_first_name: values.gp_first_name,
          gp_last_name: values.gp_last_name,
          gp_phone: values.gp_phone,
          gp_unit: values.gp_unit,
          gp_streetnumber: values.gp_streetnumber,
          gp_street: values.gp_street,
          gp_suburb: values.gp_suburb,
          gp_state: values.gp_state,
          gp_postcode: values.gp_postcode
        });
      }}
      validate={(values) => ({
        gp_first_name: Validation.strLen(
          { gtEq: 2 },
          { name: "First name" }
        ).validate(values.gp_first_name),
        gp_last_name: Validation.strLen(
          { gtEq: 2 },
          { name: "Last name" }
        ).validate(values.gp_last_name),
        gp_phone: Validation.mobile().validate(values.gp_phone),
        gp_unit: null,
        gp_streetnumber: null,
        gp_street: null,
        gp_suburb: null,
        gp_state: null,
        gp_postcode: null
      })}
    >
      {({ values, setValues, error, isEditing, id }) => (
        <>
          <ProfileCard.Group>
            <ProfileForm.Control
              label="First name"
              data-testid={`${id}-firstname`}
              disabled={isFetching}
              error={error?.gp_first_name}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_first_name: e.currentTarget.value })
              }
              value={values.gp_first_name}
            />
            <ProfileForm.Control
              label="Last name"
              data-testid={`${id}-lastname`}
              disabled={isFetching}
              error={error?.gp_last_name}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_last_name: e.currentTarget.value })
              }
              value={values.gp_last_name}
            />
            <ProfileField label={"Phone"} error={error?.gp_phone}>
              <PhoneField
                readOnly={!isEditing}
                inputTestId={`${id}-phone`}
                onChange={(value) =>
                  setValues({ ...values, gp_phone: value ?? "" })
                }
                value={values.gp_phone}
                className={
                  error?.gp_phone !== undefined ? "is-invalid" : undefined
                }
              />
            </ProfileField>
          </ProfileCard.Group>
          <ProfileCard.HorizontalDivider />
          <ProfileCard.Group>
            <ProfileForm.Control
              label="Unit"
              data-testid={`${id}-unit`}
              disabled={isFetching}
              error={error?.gp_unit}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_unit: e.currentTarget.value })
              }
              value={values.gp_unit}
            />
            <ProfileForm.Control
              label="Street number"
              data-testid={`${id}-streetnumber`}
              disabled={isFetching}
              error={error?.gp_streetnumber}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_streetnumber: e.currentTarget.value })
              }
              value={values.gp_streetnumber}
            />
            <ProfileForm.Control
              label="Street"
              data-testid={`${id}-street`}
              disabled={isFetching}
              error={error?.gp_street}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_street: e.currentTarget.value })
              }
              value={values.gp_street}
            />
          </ProfileCard.Group>
          <ProfileCard.Group>
            <ProfileForm.Control
              label="Suburb"
              data-testid={`${id}-suburb`}
              disabled={isFetching}
              error={error?.gp_suburb}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_suburb: e.currentTarget.value })
              }
              value={values.gp_suburb}
            />
            <ProfileForm.Select
              label="State"
              data-testid={`${id}-state`}
              disabled={isFetching}
              error={error?.gp_state}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_state: e.currentTarget.value })
              }
              value={values.gp_state}
            >
              {ADDRESS_STATE_OPTIONS.map(([value, display], index) => (
                <option key={`${index}_${value}`} value={value}>
                  {display}
                </option>
              ))}
            </ProfileForm.Select>
            <ProfileForm.Control
              label="Postcode"
              data-testid={`${id}-postcode`}
              disabled={isFetching}
              error={error?.gp_postcode}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, gp_postcode: e.currentTarget.value })
              }
              value={values.gp_postcode}
            />
          </ProfileCard.Group>
        </>
      )}
    </ProfileForm>
  );
};
