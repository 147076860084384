import moment, { Moment } from "moment";
import { IMask, IMaskInput } from "react-imask";

interface Info {
  dob_year?: string;
  dob_month?: string;
  dob_day?: string;
  onChange?: (e: any) => void;
  next?: (...any: any) => void;
}

interface Question {
  type: string | undefined;
  gaId?: string;
}

interface Props {
  info: Info;
  currentQuestion: Question;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
}

export const QuestionnaireDatePicker = ({
  info,
  currentQuestion,
  disabled,
  className,
  autoFocus
}: Props) => {
  const initialDate =
    currentQuestion.type === "dob" && info.dob_year
      ? `${info.dob_day}/${info.dob_month}/${info.dob_year}`
      : undefined;

  const format = "DD/MM/YYYY";

  return (
    <div
      key={currentQuestion?.gaId}
      className="inner-content"
      css={{ textAlign: "center" }}
    >
      <IMaskInput
        id="date_picker"
        placeholder={format.toUpperCase()}
        eager // Will add the '/' as soon as you type the second var
        autofix={"pad"} // Will add a 0 in front of an invalid number
        type={"tel"} // Use numeric keypad
        // Date setup
        defaultValue={initialDate}
        mask={Date}
        pattern={format}
        format={(d: Date) => moment(d).format(format)} // Required if pattern set
        parse={(s: string) => moment(s, format)} // Required if pattern set
        min={new Date(1900, 0, 1)}
        max={currentQuestion.type === "dob" ? new Date() : new Date(2099, 0, 1)}
        blocks={{
          // @ts-ignore
          YYYY: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999
          },
          // @ts-ignore
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12
          },
          // @ts-ignore
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31
          }
        }}
        // unmask modifies the value in onAccept
        // `value` if `unmask=false`,
        // `unmaskedValue` if `unmask=true`,
        // `typedValue` if `unmask='typed'`
        unmask={"typed"}
        // DO NOT USE onChange TO HANDLE CHANGES!
        // USE onAccept INSTEAD
        onAccept={(value, _) => {
          const v: Moment | null = value as any;
          if (v !== null) {
            const s = v.format("YYYY-MM-DD");
            if (info.onChange) info.onChange(s);
          }
          if (info.next) info.next({ currentQuestion });
        }}
        disabled={disabled}
        className={className}
        autoFocus={autoFocus}
        css={(theme) => ({
          borderRadius: "12px !important",
          maxWidth: "342px",
          width: "100% !important",
          padding: "8px !important",
          marginLeft: "auto",
          marginRight: "auto",
          lineHeight: "135%",
          minHeight: "35px !important",
          height: "unset !important",
          fontSize: "14px",
          [theme.mq.md]: {
            maxWidth: "431px !important",
            width: "100% !important"
          },
          [theme.mq.lg]: {
            width: "431px !important",
            maxWidth: "unset  !important"
          },
          fontWeight: "400",
          border: "0.5px solid #737373",
          ":focus": {
            outline: "none !important",
            borderColor: `${theme.color.secondary} !important`
          }
        })}
      />
    </div>
  );
};
