import { useState } from "react";
import moment from "moment";
import { Button, TitleStatus, Toast } from "@mh/components";
import { ContactUsModal } from "@mh/messaging";
import {
  PatientAPI,
  PatientQuestionnaire,
  PatientQuestionnaireRepeat,
  PatientSubscriptionScript
} from "@mh/api";
import { useNavigate } from "react-router-dom";

interface OutOfStockStatusProps {
  questionnaire: PatientQuestionnaire | PatientQuestionnaireRepeat;
  subscription?: PatientSubscriptionScript;
  initialQuestionnaireId: number;
}

export const OutOfStockStatus = ({
  questionnaire,
  subscription,
  initialQuestionnaireId
}: OutOfStockStatusProps) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const requestTreatmentChangeHandler = async () => {
    if (subscription) {
      setIsLoading(true);
      const success = await PatientAPI.createOrActivateRepeatQuestionnaire(
        subscription.id
      );
      if (success) {
        const url = `/questionnairerepeats/${questionnaire.category.name}/${initialQuestionnaireId}?requestchange=true`;
        navigate(url);
      } else {
        Toast.error("Failed to request treatment change");
      }
    }
  };

  const renderMessageAndButton = (
    isWeightManagement: boolean,
    canRequestTreatmentChange: boolean
  ): React.ReactElement => {
    const buttonText = canRequestTreatmentChange
      ? "Request Treatment Change"
      : "Contact Patient Success";

    const onClickHandler = canRequestTreatmentChange
      ? requestTreatmentChangeHandler
      : () => setShowModal(true);

    return (
      <>
        <p css={{ marginBottom: 0 }}>
          {isWeightManagement
            ? "Although your current medicine is out of stock, we have secured alternative supply pathways to ensure you can continue on your weight management journey."
            : "The medication prescribed for you is out of stock at the moment."}
          {canRequestTreatmentChange
            ? " To continue, select 'Request Treatment Change' button to speak with one of our clinicians about alternative treatments."
            : " Please contact our Patient Success team if you would like to request an available alternative."}
        </p>
        <Button
          variant="primary"
          css={{ width: "fit-content", alignSelf: "flex-end" }}
          onClick={onClickHandler}
          disabled={isLoading}
        >
          {buttonText}
        </Button>
      </>
    );
  };

  const getStatus = (): React.ReactElement => {
    const isPrescribedMoreThan7DaysAgo = moment(
      questionnaire.script?.prescribed_on
    ).isBefore(moment().subtract(7, "days"));

    const canRequestTreatmentChange =
      // @ts-ignore
      questionnaire.category.can_request_treatment_change &&
      isPrescribedMoreThan7DaysAgo;

    return renderMessageAndButton(
      questionnaire.category.slug === "weight_management",
      canRequestTreatmentChange
    );
  };

  return (
    <div
      css={{
        flex: 1,
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        gap: "8px"
      }}
    >
      <TitleStatus variant="danger">MEDICATION OUT OF STOCK</TitleStatus>
      {getStatus()}
      <ContactUsModal show={showModal} setShow={setShowModal} />
    </div>
  );
};
