import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PatientContext } from "@mh/api";
import { Button, Modal, ModalProps } from "@mh/components";
import { Validation } from "@mh/core";
import { ProfileForm } from "../../../components/forms";

interface DVAModalProps extends Omit<ModalProps, "children"> {
  /** Fire on successful submission. */
  onSubmitSuccess: () => void;
}

export const DVAModal = ({ onSubmitSuccess, ...modalProps }: DVAModalProps) => {
  const {
    isFetching,
    data: patient,
    update,
    error: apiError
  } = useContext(PatientContext);

  const navigate = useNavigate();

  const [dvaNumber, setDVANumber] = useState<string>(patient?.dva_number || "");
  const [error, setError] = useState<string | null>(null);

  return (
    <Modal {...modalProps}>
      <Modal.Title onClose={modalProps.onHide}>DVA details</Modal.Title>
      <div
        css={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          gap: "8px"
        }}
      >
        {apiError ? (
          <div>
            <h2
              style={{
                color: "red",
                paddingTop: "10px",
                paddingBottom: "20px",
                textAlign: "center"
              }}
            >
              Service not reachable! Please try again.
            </h2>
            <Button
              fullWidth
              variant={"primary-outline"}
              onClick={() => navigate(0)}
            >
              Close
            </Button>
          </div>
        ) : (
          <>
            <ProfileForm.Control
              label="DVA number"
              data-testid={"dva-number"}
              disabled={isFetching}
              error={error}
              isEditing={true}
              onChange={(e) => setDVANumber(e.currentTarget.value)}
              value={dvaNumber}
            />
            <div
              css={{
                display: "flex",
                justifyContent: "space-evenly",
                gap: "16px",
                marginTop: "auto"
              }}
            >
              <Button
                fullWidth
                onClick={modalProps.onHide}
                variant="primary-outline"
              >
                Cancel
              </Button>
              <Button
                fullWidth
                onClick={async () => {
                  // Validate
                  const validationResult = Validation.dva().validate(dvaNumber);

                  if (validationResult !== null) {
                    setError(validationResult);
                    return;
                  }

                  // Save
                  await update({
                    dva_number: dvaNumber
                  });

                  onSubmitSuccess();
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
