import { ComponentPropsWithoutRef } from "react";

export const IconMembership = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 48}
    height={props.height ?? 48}
    viewBox="0 0 49 48"
    fill="none"
  >
    <g filter="url(#filter0_d_5629_64840)">
      <circle cx="24.4375" cy="22" r="19.5" fill={props.fill} />
      <path
        d="M36.1658 18.2877C35.5259 18.2877 35.0063 18.8073 35.0063 19.4472C35.0063 19.7384 35.1144 20.0052 35.2922 20.2092C34.1362 21.0793 32.6977 21.5937 31.1388 21.5937C28.3245 21.5937 25.9026 19.9145 24.8197 17.5048C25.2713 17.3461 25.5939 16.9172 25.5939 16.4115C25.5939 15.7716 25.0761 15.252 24.4344 15.252C23.7927 15.252 23.2748 15.7716 23.2748 16.4115C23.2748 16.9172 23.5992 17.3461 24.0508 17.5048C22.9679 19.9163 20.546 21.5937 17.7317 21.5937C16.1728 21.5937 14.7343 21.0776 13.5783 20.2092C13.7561 20.0052 13.8642 19.7402 13.8642 19.4472C13.8642 18.8073 13.3446 18.2877 12.7047 18.2877C12.0647 18.2877 11.5469 18.8056 11.5469 19.4472C11.5469 20.0889 12.0665 20.6068 12.7064 20.6068C12.818 20.6068 12.9261 20.5893 13.029 20.5597C13.7422 22.5771 15.4771 26.7933 16.5878 28.7497H32.2861C33.3969 26.7933 35.1318 22.5771 35.845 20.5597C35.9479 20.5893 36.056 20.6068 36.1676 20.6068C36.8075 20.6068 37.3271 20.0872 37.3271 19.4472C37.3271 18.8073 36.8075 18.2877 36.1676 18.2877H36.1658Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5629_64840"
        x="0.4375"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5629_64840"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5629_64840"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
