import { FC, MouseEventHandler, useRef } from "react";
import { VoucherDiscount } from "@mh/api";
import { Button, Flex, Heading, Spinner } from "@mh/components";
import { Validation, ValidationInput } from "@mh/core";
import { Form } from "react-bootstrap";

export interface DiscountCodeProps {
  text?: string;
  placeHolder?: string;
  onApplied: (code: string) => void;
  voucherDiscounts: VoucherDiscount[];
  isApplyingDiscount: boolean;
  discountError: string | null;
  /** Margin between the input and application button */
  marginBetween?: string;
  showDiscountAmount?: boolean;
  showBottomMessage?: boolean;
  isNewCheckout?: boolean;
}

const codeValidation = (v: ValidationInput) =>
  Validation.required().validate(v);

const DiscountCode: FC<DiscountCodeProps> = (props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleApplied: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const value = inputRef.current?.value;
    if (codeValidation(value) === null) {
      props.onApplied(value!);
    }
  };

  const voucherUsed =
    props.voucherDiscounts &&
    Array.isArray(props.voucherDiscounts) &&
    props.voucherDiscounts.length > 0;

  return (
    <Flex
      flexDirection="column"
      css={(theme) => ({
        width: "100%",
        [theme.mq.md]: {
          width: "unset"
        }
      })}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBetween={props.marginBetween ?? "8px"}
      >
        {props.text && <Heading>{props.text}</Heading>}
        <Flex
          alignItems="center"
          marginBetween="4px"
          className={`discountBox ${
            props.isNewCheckout && "discountBox-newcheckout"
          }`}
        >
          <Form.Control
            data-testid="discount-code-input"
            css={{ flex: 1, width: "100%", outline: "none" }}
            ref={inputRef}
            placeholder={props.text || props.placeHolder || "Discount code"}
            defaultValue={
              voucherUsed ? props.voucherDiscounts[0]?.voucher?.code : undefined
            }
          />
          <Button
            disabled={props.isApplyingDiscount}
            onClick={handleApplied}
            size="sm"
            css={{
              marginLeft: "8px",
              backgroundColor:
                props.isNewCheckout && props.showDiscountAmount && voucherUsed
                  ? "#C2C2C3"
                  : "unset"
            }}
            variant="primary-outline"
          >
            {props.isApplyingDiscount ? (
              <Spinner variant="secondary" size="sm" />
            ) : props.isNewCheckout &&
              props.showDiscountAmount &&
              voucherUsed ? (
              <>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      d="M4.3623 9.15628L7.21947 11.6898L13.1437 5.49219"
                      stroke="#4F4F4F"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span>Applied</span>
              </>
            ) : (
              "Apply"
            )}
          </Button>
          {props.showDiscountAmount && voucherUsed && (
            <i>Less ${props.voucherDiscounts[0]?.amount}</i>
          )}
        </Flex>
      </Flex>
      {props.showBottomMessage && voucherUsed && !props.discountError && (
        <Flex>
          <i
            css={{
              paddingTop: "5px",
              paddingBottom: "10px",
              fontSize: "14px"
            }}
          >
            ${props.voucherDiscounts[0]?.amount} discount applied
          </i>
        </Flex>
      )}
      {props.discountError !== null && (
        <Flex>
          <span>{props.discountError}</span>
        </Flex>
      )}
    </Flex>
  );
};

export default DiscountCode;
