import { useContext, useEffect, useState } from "react";
import { PatientContext, UserContext } from "@mh/api";
import { EmergencyContact, NextOfKin, UserInfo } from "../../components/forms";
import { ShippingAddress } from "@mh/components";

import "./QuestionnairePersonalDetails.scss";

interface QuestionnairePersonalDetailsProps {
  setIsEditingForm: (isEditing: boolean) => void;
  userInfoTitle?: string;
}

export const QuestionnairePersonalDetails: React.FC<
  QuestionnairePersonalDetailsProps
> = ({ setIsEditingForm }: QuestionnairePersonalDetailsProps) => {
  const userContext = useContext(UserContext);
  const patientContext = useContext(PatientContext);

  const [isEditingUserInfo, setIsEditingUserInfo] = useState<boolean>(false);
  const [isEditingNextOfKin, setIsEditingNextOfKin] = useState<boolean>(false);
  const [isEditingEmergencyContact, setIsEditingEmergencyContact] =
    useState<boolean>(false);
  const [isEditingAddress, setIsEditingAddress] = useState<boolean>(false);

  /** If the user/patient data has not been fetched yet, do it on first render. */
  useEffect(() => {
    if (!userContext.data) {
      userContext.get();
    }
    if (!patientContext.data) {
      patientContext.get();
    }
  }, []);

  /** When the user starts/stops editing a componenet, call the setIsEditingForm function */
  useEffect(() => {
    const isEditingAnyForm =
      isEditingUserInfo ||
      isEditingNextOfKin ||
      isEditingEmergencyContact ||
      isEditingAddress;
    setIsEditingForm(isEditingAnyForm);
  }, [
    isEditingUserInfo,
    isEditingNextOfKin,
    isEditingEmergencyContact,
    isEditingAddress
  ]);

  return (
    <div className="questionnaire-personal-details">
      <div className="personal-details">
        <UserInfo
          isEditingCallback={setIsEditingUserInfo}
          hideDigitalID={true}
          title={"Personal Details"}
          isQuestionnaire={true}
        />
      </div>
      <NextOfKin
        isEditingCallback={setIsEditingNextOfKin}
        isQuestionnaire={true}
      />
      <EmergencyContact
        isEditingCallback={setIsEditingEmergencyContact}
        isQuestionnaire={true}
      />
      <ShippingAddress
        isEditingCallback={setIsEditingAddress}
        isQuestionnaire={true}
      />
    </div>
  );
};
