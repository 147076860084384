import { Conversation, MessagingAPI } from "@mh/api";
import { MessageItem } from "./MessageItem";
import { SendMessageBar } from "./SendMessageBar";
import { ClosedConversationBar } from "./ClosedConversationBar";
import { useEffect, useRef } from "react";

export interface Props {
  conversation: Conversation;
  isFirstRender: boolean;
  loadSelectedConversation: (conversationId: number) => void;
  setIsFirstRender: (isFirst: boolean) => void;
  setConversationId: (conversationId: number | null) => void;
}

export function ConversationDetail({
  conversation,
  loadSelectedConversation,
  setConversationId,
  isFirstRender,
  setIsFirstRender
}: Props) {
  const lastMessage = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isFirstRender) {
      lastMessage.current?.scrollIntoView({ behavior: "smooth" });
    }
    setIsFirstRender(false);
  }, [conversation.messages]);

  const updateConversation = async () => {
    await MessagingAPI.updateConversation(conversation.id, {
      read: true
    });
  };

  useEffect(() => {
    updateConversation();
  }, []);

  useEffect(() => {
    const timer = setInterval(
      () => loadSelectedConversation(conversation.id),
      10000
    );
    return () => clearInterval(timer);
  }, [conversation]);

  const footerBar =
    conversation.status === "closed" ? (
      <ClosedConversationBar />
    ) : (
      <SendMessageBar
        conversation={conversation}
        loadSelectedConversation={() =>
          loadSelectedConversation(conversation?.id)
        }
      />
    );

  return (
    <div className="col h-100 d-flex flex-row bg-white mx-5">
      <div className="flex-column w-100 d-flex">
        <div className="flex-row d-flex bg-white w-100 justify-content-between">
          <div className="py-6 px-5">
            <h4>{conversation.other_participant_display_name}</h4>
            <b>{conversation.condition}</b>
          </div>
          <div className="mx-2 d-flex d-inline d-md-none align-items-center">
            <h2
              className="text-primary"
              onClick={() => setConversationId(null)}
            >
              {"x"}
            </h2>
          </div>
        </div>
        <div className="h-100 w-100 overflow-scroll">
          <div className="mb-10">
            {conversation.messages.map((message) => (
              <MessageItem
                message={message}
                key={message.id}
                otherSenderDisplayName={
                  conversation.other_participant_display_name
                }
              />
            ))}
          </div>
          <div ref={lastMessage} />
        </div>
        {/** This should display on a mobile or smaller */}
        <div className="d-flex d-md-none fixed-bottom text-center bg-white mt-2">
          <div className="container" style={{ width: "90%" }}>
            {footerBar}
          </div>
        </div>
        {/** This should display on a screen md or larger */}
        <div className="w-100 d-none d-md-inline">{footerBar}</div>
      </div>
    </div>
  );
}
