const PROD_CATEGORY = [
  {
    brand: ["hubhealth", "youly"],
    ver: "v15",
    name: "Contraceptive Pill",
    slug: "contraceptive-pill",
    code: "contraceptive_pill",
    requiredGender: "female",
    showNewPayment: true,
    adduserv2: true,
    CATID: 1,
    ShortDesc: "Contraceptive Pill",
    script: true,
    subscription: "yes",
    newRepeatMedication: true,
    customBrand: ["hubhealth"],
    age: 14,
    isLoadProducts: true
  },
  {
    brand: ["hubhealth", "youly"],
    ver: "v13",
    name: "Contraceptive Pill",
    slug: "contraceptive-pill",
    code: "contraceptive_pill",
    requiredGender: "female",
    variant: "contraceptive_pill_egl70",
    useBaseQuestionnaire: true,
    showNewPayment: true,
    adduserv2: true,
    CATID: 1,
    ShortDesc: "Contraceptive Pill",
    script: true,
    subscription: "yes",
    newRepeatMedication: true,
    customBrand: ["hubhealth"],
    age: 14,
    isLoadProducts: true
  },
  {
    brand: ["hubhealth", "youly"],
    ver: "v13",
    name: "Contraceptive Pill",
    slug: "contraceptive-pill",
    code: "contraceptive_pill",
    requiredGender: "female",
    variant: "contraceptive_pill_egl707",
    adduserv2: true,
    CATID: 1,
    ShortDesc: "Contraceptive Pill",
    script: true,
    subscription: "yes",
    newRepeatMedication: true,
    customBrand: ["hubhealth"],
    age: 14,
    isLoadProducts: true
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v9",
    name: "HSV1",
    showtitle: "Cold Sores",
    CATID: 4,
    slug: "hsv1",
    code: "hsv1",
    ShortDesc: "Cold Sores",
    script: true,
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v11",
    name: "HSV2",
    showtitle: "Herpes Simplex Virus",
    slug: "hsv2",
    code: "hsv2",
    CATID: 6,
    ShortDesc: "Genital Herpes",
    script: true,
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "youly"],
    ver: "v7",
    name: "Thrush",
    slug: "thrush",
    code: "thrush",
    requiredGender: "female",
    CATID: 3,
    ShortDesc: "Thrush",
    once: "yes",
    expresscost: 10.95,
    isLoadProducts: true
  },
  {
    brand: ["hubhealth", "youly"],
    ver: "v9",
    name: "Nuvaring",
    slug: "nuvaring",
    code: "nuvaring",
    CATID: 5,
    ShortDesc: "Nuvaring",
    subscription: "yes",
    adduserv2: true
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v10",
    name: "Sleep",
    slug: "sleep",
    code: "sleep",
    CATID: 11,
    ShortDesc: "Sleep treatment",
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v6",
    name: "Reflux",
    slug: "reflux",
    code: "reflux",
    CATID: 13,
    ShortDesc: "Reflux treatment",
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v6",
    name: "Asthma",
    slug: "asthma",
    code: "asthma",
    CATID: 14,
    ShortDesc: "Asthma treatment",
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "youly"],
    ver: "v9",
    name: "Menopause",
    slug: "menopause",
    code: "menopause",
    CATID: 15,
    ShortDesc: "Menopause treatment",
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "stagger"],
    ver: "v16",
    name: "ED",
    slug: "ed",
    code: "ed",
    requiredGender: "male",
    CATID: 8,
    ShortDesc: "Erectile Dysfunction",
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "stagger"],
    ver: "v18",
    name: "PE",
    slug: "pe",
    code: "pe",
    requiredGender: "male",
    CATID: 9,
    ShortDesc: "Premature Ejaculation",
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v11",
    name: "Acne",
    slug: "acne",
    code: "acne",
    CATID: 12,
    ShortDesc: "Acne",
    subscription: "yes",
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v8",
    name: "Rosacea",
    slug: "rosacea",
    code: "rosacea",
    CATID: 22,
    ShortDesc: "Rosacea",
    subscription: "yes",
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v8",
    name: "Eczema",
    slug: "eczema",
    code: "eczema",
    CATID: 23,
    ShortDesc: "Eczema",
    subscription: "yes",
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v6",
    name: "Fill-a-script",
    slug: "fill-a-script",
    code: "fill_a_script",
    CATID: 21,
    ALLCAT: "yes",
    GA: "fill-a-script",
    showtitle: "Fill-a-script",
    ShortDesc: "Fill-a-script",
    deliverycost: 8.0,
    subscription: "yes",
    hidePayment: "yes",
    once: "yes",
    both: "yes",
    age: 18,
    exclude: ["AddUserquestions"]
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v5",
    name: "Vaping Cessation",
    slug: "vaping-cessation",
    code: "vaping_cessation",
    CATID: 27,
    ShortDesc: "Quit Vaping",
    subscription: "yes",
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v4",
    name: "Hypertension",
    slug: "hypertension",
    code: "hypertension",
    CATID: 28,
    ShortDesc: "Hypertension (High Blood Pressure)",
    subscription: "yes",
    showtitle: "Hypertension (High Blood Pressure)"
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v7",
    name: "Smoking Cessation",
    slug: "smoking-cessation",
    code: "smoking_cessation",
    CATID: 26,
    ShortDesc: "Quit Smoking",
    subscription: "yes",
    showtitle: "Quit Smoking",
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v9",
    prodprice: "yes",
    name: "Medical Certificates",
    slug: "medicalcertificates",
    code: "medicalcertificates",
    CATID: 29,
    ShortDesc: "Medical Certificate",
    subscription: "yes",
    subscriptiononce: "yes",
    exclude: ["AddUserquestions"],
    bypassId: true,
    customBrand: ["hubhealth"],
    age: 14,
    isLoadProducts: true
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v8",
    prodprice: "yes",
    name: "Medical Certificates",
    code: "medicalcertificates",
    slug: "medicalcertificates",
    variant: "medicalcertificates_egl1032",
    CATID: 29,
    userQuestionVer: 3,
    useBaseQuestionnaire: true,
    ShortDesc: "Medical Certificate",
    subscription: "yes",
    subscriptiononce: "yes",
    exclude: ["AddUserquestions"],
    bypassId: true,
    customBrand: ["hubhealth"],
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v8",
    prodprice: "yes",
    name: "Medical Certificates",
    code: "medicalcertificates",
    slug: "medicalcertificates",
    variant: "medicalcertificates_egl1161",
    CATID: 29,
    userQuestionVer: 4,
    useBaseQuestionnaire: true,
    ShortDesc: "Medical Certificate",
    subscription: "yes",
    subscriptiononce: "yes",
    exclude: ["AddUserquestions"],
    bypassId: true,
    customBrand: ["hubhealth"]
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v6",
    name: "Psoriasis",
    slug: "psoriasis",
    code: "psoriasis",
    CATID: 31,
    ShortDesc: "Psoriasis",
    subscription: "yes",
    showtitle: "Psoriasis",
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v4",
    name: "Fine lines & wrinkles",
    slug: "finelineswrinkles",
    code: "finelineswrinkles",
    CATID: 32,
    ShortDesc: "Fine lines & wrinkles",
    subscription: "yes",
    showtitle: "Fine lines & wrinkles",
    age: 14
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v4",
    name: "Uneven skin tone",
    slug: "unevenskintone",
    code: "unevenskintone",
    CATID: 33,
    ShortDesc: "Uneven skin tone",
    subscription: "yes",
    showtitle: "Uneven skin tone",
    age: 14
  },
  {
    brand: ["youly", "hubhealth"],
    ver: "v5",
    name: "Morning After Pill",
    slug: "morning-after-pill",
    code: "morning_after_pill",
    requiredGender: "female",
    gender: "Female",
    CATID: 2,
    ShortDesc: "Morning After Pill",
    once: "yes",
    addresscheck: "true",
    brand_pill: "Morning After Pill",
    price: "30.00",
    duration: "1 pill",
    medication: "Postrelle-1 Tablets 1.5 mg [1]",
    exclude: ["AddUserquestions"],
    age: 14
  },
  {
    brand: ["hubhealth", "stagger"],
    ver: "v7",
    name: "Hair Loss",
    slug: "hair-loss",
    code: "hl",
    CATID: 7,
    GA: "HL",
    ShortDesc: "Hair Loss",
    subscription: "yes"
  },
  {
    brand: ["hubhealth", "stagger", "youly"],
    ver: "v13",
    name: "Renew-A-Script",
    slug: "renew-a-script",
    code: "renew_a_script",
    CATID: 35,
    GA: "RS",
    ShortDesc: "Renew A Script",
    subscription: "yes",
    subscriptiononce: "yes",
    forceMedicare: true,
    scriptonly: true,
    age: 14,
    replacetext: [
      {
        ga_id: "A0009",
        subtitle:
          "This is required so we can issue an eScript for your medication"
      },
      {
        ga_id: "A0010",
        subtitle:
          "This is required so we can issue an eScript for your medication"
      }
    ]
  },
  {
    brand: ["hubhealth", "stagger", "youly"],
    ver: "v11",
    name: "Renew-A-Script",
    slug: "renew-a-script",
    code: "renew_a_script",
    variant: "renew_a_script_egl1154",
    CATID: 35,
    GA: "RS",
    ShortDesc: "Renew A Script",
    subscription: "yes",
    subscriptiononce: "yes",
    forceMedicare: true,
    scriptonly: true,
    age: 14,
    replacetext: [
      {
        ga_id: "A0009",
        subtitle:
          "This is required so we can issue an eScript for your medication"
      },
      {
        ga_id: "A0010",
        subtitle:
          "This is required so we can issue an eScript for your medication"
      }
    ]
  },
  {
    brand: ["hubhealth", "stagger", "youly"],
    ver: "v3",
    name: "UTI",
    slug: "uti",
    code: "uti",
    requiredGender: "female",
    CATID: 42,
    GA: "UT",
    ShortDesc: "UTI",
    subscription: "yes",
    subscriptiononce: "yes",
    forceShowInTreatmentPage: true,
    maxAge: 65,
    addressCheck: true,
    script: true,
    forceMedicare: true,
    medicare: {
      nolater: true,
      subtitle:
        "This enables us to generate an eScript and is required for us to offer same-day delivery via our partner pharmacies",
      end: true,
      message:
        "Unfortunately we require your Medicare details to enable same-day delivery via our partner pharmacies. If you have a Medicare card, please use the back button above to enter these details."
    }
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v15",
    name: "Weight Management",
    slug: "weight_management",
    code: "weight_management",
    CATID: 40,
    ShortDesc: "Weight Management",
    subscription: "yes",
    showtitle: "Weight Management",
    checkColdchain: true,
    maxAge: 75,
    minAge: 18,
    age: 14,
    reduceProgressBarCount: 10
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v6",
    name: "Telehealth Consult",
    slug: "telehealth-consult",
    code: "telehealth_consult",
    CATID: 50,
    ShortDesc: "General Telehealth",
    subscriptiononce: "yes",
    subscription: "yes",
    forceShowInTreatmentPage: true,
    addCosts: ["medicalcertificates"],
    customBrand: ["hubhealth"]
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    name: "Loreal Acne Trial",
    code: "loreal_acne_trial",
    noaddqus: true
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    stage: 1,
    ver: "v1",
    prodprice: "yes",
    name: "Pre-Travel Consult",
    code: "pre_travel_consult",
    slug: "pre-travel-consult",
    CATID: 51,
    useBaseQuestionnaire: true,
    ShortDesc: "Pre-Travel Consult",
    subscription: "yes",
    subscriptiononce: "yes",
    medicationSearch: "renew-a-script",
    scriptonlySearch: true
  },
  {
    brand: ["hubhealth", "youly", "stagger"],
    ver: "v1",
    name: "Digital Health Check",
    code: "digital_health_check",
    slug: "digital-health-check",
    CATID: 53,
    ShortDesc: "Digital Health Check",
    subscription: "yes",
    subscriptiononce: "yes",
    adduserv2: true,
    customProgressBar: true,
    disableProspect: true,
    noShowPayment: true
  }
];

export default PROD_CATEGORY;
