import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

// Load the style constants from the core package
import "@mh/core/src/style_constants.scss";
const mobileWidth = getComputedStyle(document.documentElement).getPropertyValue(
  "--mh-mobile-width"
);

export const MOBILE_SIZE_CUTOFF = parseInt(mobileWidth);

export enum DeviceSize {
  MOBILE = 0,
  IPAD = 1,
  WEB = 2
}

export interface SizeContextType {
  size: DeviceSize;
  isMobile: boolean;
  isWeb: boolean;
  isIPad: boolean;
  height: number;
  width: number;
}

// Create a new context
export const SizeContext = createContext<SizeContextType | undefined>(
  undefined
);

export const useSizeContext = () => {
  const context = useContext(SizeContext);
  if (!context) {
    // TODO use sentry
    // throw new Error("SizeContext must be used within a SizeProvider");
  }
  return context;
};

// Create a provider component
export const SizeProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const getStateFromWindow = () => ({
    size: DeviceSize.WEB,
    isMobile: window.innerWidth <= MOBILE_SIZE_CUTOFF,
    isWeb: window.innerWidth > MOBILE_SIZE_CUTOFF,
    isIPad: false,
    height: window.innerHeight,
    width: window.innerWidth
  });

  const [state, setState] = useState<SizeContextType>(getStateFromWindow());

  useEffect(() => {
    const handleResize = () => {
      setState(getStateFromWindow());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Provide the state and functions to the children components
  return <SizeContext.Provider value={state}>{children}</SizeContext.Provider>;
};
