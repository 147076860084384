import { NavLink } from "react-router-dom";
import { Button, IconArrowRight } from "@mh/components";

import "./DigitalHealthCheck.scss";

export const DigitalHealthCheck = () => (
  <div className="get-started">
    <div className="content">
      <div className="title">
        Start your <span>Digital Health Check</span> today
      </div>
      <ol>
        <li>
          <div className="step">
            <strong>Answer some questions about your health</strong>
            <p>
              Answer quick questions about your lifestyle habits, nutrition, and
              overall well-being.
            </p>
          </div>
        </li>
        <li>
          <div className="step">
            <strong>Gain key insights with a range of health scores</strong>
            <p>
              Gain key health insights with a range of clinically validated
              health scores.
            </p>
          </div>
        </li>
        <li>
          <div className="step">
            <strong>
              Discuss your results in your free doctor consultation (optional)
            </strong>
            <p>
              After you get your health scores, you can choose to have a free
              call with one of our Australian-based doctors to discuss your
              results and get preventative health recommendations.
            </p>
          </div>
        </li>
      </ol>
    </div>
    <NavLink
      to="/questionnaire/?product=digital-health-check"
      state={{ isNavLink: true, from: window.location.pathname }}
    >
      <Button className="start-button" data-testid="health-check-start">
        <span>Get Started</span>
        <IconArrowRight width={16} />
      </Button>
    </NavLink>
  </div>
);
