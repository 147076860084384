import { Flex } from "@mh/components";

interface SingleEmailQuestionProps {
  email: string;
}

export const SingleEmailQuestion = (props: SingleEmailQuestionProps) => {
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="column"
        css={(theme) => ({
          paddingBottom: "4px",
          [theme.mq.sm]: {
            paddingBottom: "0px"
          }
        })}
      >
        <input
          key="userEmail"
          name="user_email"
          id="useremail"
          type="email"
          required
          placeholder="email"
          defaultValue={props.email}
          css={(theme) => ({
            lineHeight: "135% !important",
            borderRadius: "12px",
            padding: "8px",
            fontSize: "16px",
            border:
              "0.5px solid var(--components-divider-divider-background, #737373)",
            [theme.mq.sm]: {
              marginBottom: "12px"
            }
          })}
        />
      </Flex>
    </Flex>
  );
};
