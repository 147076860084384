import * as SentryBase from "@sentry/react";
import { ENV, Mode } from "../config";

const sentryEnv = (m: Mode): string =>
  ({
    [Mode.DEV]: "dev",
    [Mode.STAGING]: "staging",
    [Mode.LIVE]: "production"
  })[m]!;

export const sentryInit = () => {
  if (import.meta.env.VITE_SENTRY_DSN) {
    SentryBase.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [new SentryBase.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      environment: sentryEnv(ENV),
      tracesSampleRate: 0.05,
      ignoreErrors: ["ChunkLoadError"],
      release: import.meta.env.VITE_SENTRY_RELEASE_TAG,
      dist: import.meta.env.VITE_SENTRY_DIST,
      beforeSend(event, hint) {
        const error = hint.originalException;

        // Filter out reference errors for TikTok Pixel, probably due to an adblocker?
        if (error instanceof ReferenceError && error.message.match(/ttq/i)) {
          return null;
        }

        // Filter out "TypeError: Failed to fetch" errors that come from Google Tag Manager,
        // they occur frequently and there's nothing we can do about them
        if (
          error instanceof TypeError &&
          error.message.match(/failed to fetch/i)
        ) {
          const stackIndex = error.stack?.indexOf(
            "www.googletagmanager.com/gtm.js?id="
          );
          if (typeof stackIndex === "number" && stackIndex > 0) {
            return null;
          }
        }

        return event;
      }
    });
  }
};

export const Sentry = SentryBase;
