import { useContext, useEffect, useState } from "react";
import {
  NIBDetailPatientContext,
  OscarAPI,
  PatientAPI,
  PatientContext,
  PatientQuestionnaire
} from "@mh/api";
import {
  BannerList,
  DigitalHealthCheck,
  HeroProductBanner,
  GetYourMeds,
  Toast,
  LatestTreatment
} from "@mh/components";
import { BRAND } from "@mh/core";
import BCs from "../../components/common/BannerContents";
import { TreatmentCard } from "../../components/TreatmentCard/TreatmentCard";
import { useCheckMobileScreen, useMembership } from "../../../packages/hooks";

export const Home = () => {
  const patientContext = useContext(PatientContext);
  const patientNIBDetailContext = useContext(NIBDetailPatientContext);
  const isMobile = useCheckMobileScreen();

  const [isCorporateMember, setIsCorporateMember] = useState<boolean>(false);
  const [isHealthCheckEnabled, setIsHealthCheckEnabled] =
    useState<boolean>(false);
  const [questionnaires, setQuestionnaires] = useState<PatientQuestionnaire[]>(
    []
  );

  const membershipsStatus = useMembership();

  const loadQuestionnaires = async () => {
    setQuestionnaires(
      await PatientAPI.loadPatientQuestionnaires({ brand: BRAND })
    );
  };

  useEffect(() => {
    loadQuestionnaires();
  }, []);

  useEffect(() => {
    const fetchAllowedMembers = async () => {
      try {
        const response = await PatientAPI.getAllowedMembers();
        const isCorporateMember = response.some(
          (member) => !member.is_revoked && !member.membership.is_revoked
        );
        setIsCorporateMember(isCorporateMember);
      } catch (error) {
        Toast.error("Failed to get allowed member.");
      }
    };
    const initializePatientContext = async () => {
      if (!patientContext.data) {
        patientContext.get();
      }
    };
    const initializeNIBPatientDetailContext = async () => {
      if (!patientNIBDetailContext.data) {
        patientNIBDetailContext.get();
      }
    };
    const fetchHealthCheckStatus = async () => {
      const categories = await OscarAPI.getCategories();
      const healthCheckCategory = categories.find(
        (category) => category.slug === "digital-health-check"
      );
      setIsHealthCheckEnabled(healthCheckCategory?.is_enabled || false);
    };
    fetchAllowedMembers();
    initializePatientContext();
    initializeNIBPatientDetailContext();
    fetchHealthCheckStatus();
  }, []);

  const existingTreatmentCategorySlugs = questionnaires.map(
    (questionnaire) => questionnaire.category.slug
  );

  return (
    <div className="home">
      <LatestTreatment
        questionnaires={questionnaires}
        loadQuestionnaires={loadQuestionnaires}
        membershipsStatus={membershipsStatus}
      />
      {isCorporateMember && isHealthCheckEnabled && <DigitalHealthCheck />}
      <HeroProductBanner
        existingTreatmentCategorySlugs={existingTreatmentCategorySlugs}
      />
      <GetYourMeds />
      <div css={{ margin: "32px 0px" }}>
        <BannerList
          banners={BCs?.filter(
            (banner) =>
              banner.pages.includes("home") &&
              (!isMobile ? !banner.mobileOnly : banner) // if desktop: show banners without mobileOnly, if mobile: show all
          )}
        />
      </div>
      <TreatmentCard
        containerCss={{
          padding: "8px",
          background: "#ffffff",
          marginTop: "24px"
        }}
      />
    </div>
  );
};
