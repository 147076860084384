import React from "react";
import { User } from "@mh/api";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useEffectOnce } from "../../utils";
import { Toast } from "@mh/components";

const NotFound = (): JSX.Element => {
  const navigate = useNavigate();

  // If the user is not logged in we send them to the page after logging in
  // The redirect should be done in a useEffect so it happens after first render
  useEffectOnce(() => {
    if (!User.loggedIn()) {
      const params = createSearchParams({
        redirect: window.location.pathname + window.location.search
      }).toString();
      Toast.info("Please log in to access this page.");
      navigate({ pathname: "/", search: params });
    }
  });

  return (
    <div className="notfound page">
      <div className="row mt-5">
        <div className="col-2 col-md-4"></div>
        <div className="col-8 col-md-4">
          <div className="row">
            <div className="col-12 text-center">
              <h1>OOPS!</h1>
              <span>Page Not Found</span>
            </div>
            <div style={{ height: 20 }} />
            <a className="btn-link text-center" href="/">
              Return to home
            </a>
          </div>
        </div>
        <div className="col-2 col-md-4"></div>
      </div>
    </div>
  );
};

export default NotFound;
