import { ENV, Mode, Sentry } from "@mh/core";
import { ErrorDisplay } from "./ErrorDisplay";
import { useRouteError } from "react-router-dom";
import { LocalErrorDisplay } from "./LocalErrorDisplay";

export const RootErrorBoundary = () => {
  const error = Object(useRouteError());
  const message: string | undefined = error?.message;
  const stack: string | undefined = error?.stack;

  if (ENV !== Mode.LIVE) {
    return <LocalErrorDisplay message={message} stack={stack} />;
  }

  if (message) {
    Sentry.captureMessage(`ErrorBoundary error: ${message}`, (scope) => {
      scope.setExtra("stack", stack);
      return scope;
    });
  }

  return <ErrorDisplay />;
};
