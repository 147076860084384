/*
From react-bootstrap, radio button from Form.Check and the concept of the Accordion's onclick event handler have been combined
Usage:
<Accordion>
    <Card.Header>
        <RadioToggle eventKey="0" group="one" id="one">Click me!</RadioToggle>
    </Card.Header>
    <Accordion.Collapse/>  // this has to be a proper component for this accordion unit's body
</Accordion>
*/
import type { PropsWithChildren } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Form from "react-bootstrap/Form";

interface RadioToggleProps {
  eventKey: string;
  group: string;
  id: string;
  value: string;
  curval: string;
  onClick?: () => void;
}

const RadioToggle = (props: PropsWithChildren<RadioToggleProps>) => (
  <Form.Check
    type="radio"
    id={`radio-${props.id}`}
    label={props.children}
    name={`group-${props.group}`}
    onClick={useAccordionButton(props.eventKey, props.onClick)}
    key={props.id}
    value={props.value}
    defaultChecked={props.value === props.curval}
  />
);

export default RadioToggle;
