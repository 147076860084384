import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import jQuery from "jquery";
import { useBeforeunload } from "react-beforeunload";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  API,
  User,
  OscarAPI,
  CheckoutAPI,
  Feature,
  VoucherAPI,
  compareQuestionnairesByCreatedDate
} from "@mh/api";

import { QuestionnaireCompletionModal } from "./QuestionnaireCompletionModal";

import { getCategory, objectIsProduct } from "./utils";

import Userquestions from "../../questionnaires/userquestions";
import AddUserquestions from "../../questionnaires/adduserquestions";
import AddUserquestionsnew from "../../questionnaires/adduserquestionsnew";

import COMMTXT from "../common/commontext";

import Base from "./basequestionnaire";
import QTemplate from "./templatequestionnaire";

import CCards from "../creditcard/creditcard";
import { Sentry } from "@mh/core";
import { Toast, LogoBrand, ProgressBar } from "@mh/components";

import { getCookies } from "./../../utils";
import { useMembership } from "../../../packages/hooks";

export default function Repeats(props) {
  const { id } = useParams();
  const { product } = useParams();

  const [data_, setData] = useState();
  const [showCompletionModal, setShowCompletionModal] = useState(false);

  const [modaldata, modaldataSet] = useState();
  const [modal_, modalSet] = useState(false);

  const [checkbts, checkbtsSet] = useState(false);
  const [accept_, acceptSet] = useState(false);
  const [acceptbypass_, acceptBypassSet] = useState(false);
  const [extrafields, extrafieldsSet] = useState(false);

  const [productcode, setProductcode] = useState();
  const [productname, setProductname] = useState();
  const [productenv, setProductenv] = useState();
  const [productqus, setProductData] = useState();

  const [patientid, setpatientid] = useState();
  const [questionnaireid, setQuestionnaireid] = useState(0);
  const [questionnairedata, qdataSet] = useState([]);
  const [questionnaire, qSet] = useState([]);
  const [nextid, setNextId] = useState(0);
  const [subindex, setSubindex] = useState(0);
  const [subindex2, setSubindex2] = useState(0);

  const [hideOther, setHideOther] = useState(true);
  const [other, setOther] = useState(null);

  const [isStock, setIsStock] = useState(false);
  const [brand_pill_id, setBrandpillId] = useState(false);
  const [brand_origin_pill, setOriginBrandpill] = useState();
  const [brand_pill, setBrandpill] = useState();
  const [brand_pill_title, setBrandpillTitle] = useState();
  const [price, setPrice] = useState();
  const [duration, setDuration] = useState("");

  const [user_email, setUserEmail] = useState();
  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [dob_day, dob_daySet] = useState();
  const [dob_month, dob_monthSet] = useState();
  const [dob_year, dob_yearSet] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [suburb, setSuburb] = useState();
  const [state, setState] = useState();
  const [postcode, setPostcode] = useState();
  const [weight, weightSet] = useState();
  const [height, heightSet] = useState();
  const [gender, genderSet] = useState(null);
  const [smokingStatus, smokingStatusSet] = useState(false);

  const [scriptqustype, setScriptqusType] = useState();
  const [scriptqus, setScriptQus] = useState();
  const [scriptfile, setScriptFile] = useState();
  const [_scriptname, setScriptName] = useState();
  const [_hasscript, setHasScript] = useState(false);

  const [deliveryoption, deliveryoptionSet] = useState();
  const [deliverycost, deliverycostSet] = useState();

  const [imagefiles, setImageFiles] = useState();

  const [isend, isendset] = useState(false);

  const [iseventpromo, setIsEventPromo] = useState(false);

  const [prequs, prequsSet] = useState();
  const [gaonce, setGaonce] = useState(false);

  const [issameday, isSameDay] = useState("no");

  const [scriptToken, setScriptToken] = useState("");

  const [unload, setUnload] = useState(false);
  const [isInitDone, setisInitDone] = useState(false);

  const [isCheckRes, setIsCheckRes] = useState(false);
  const [preventTopScroll, setPreventTopScroll] = useState(false);

  const [ph, setPh] = useState(null);

  const [requestchange, setRequestchange] = useState(false);

  const [callTime, setCallTime] = useState(false);
  const [showAfter, setShowAfter] = useState(null);

  // Last Order Shipping Cost
  const [shippingCost, setShippingCost] = useState();

  const [ptPlanInfo, setPtPlanInfo] = useState();

  const [singleRepeatPrice, setSingleRepeatPrice] = useState();
  const [preferredmonth, setPreferredmonth] = useState();

  // Address check for cold chain delivery
  const [iscoldchain, setIsColdChain] = useState(true);
  // WM cold chain prod disabled because of sold out
  const [injectablesUnavailable, setInjectablesUnavailable] = useState(false);

  const [btLoading, setBtLoading] = useState(false);

  const [showpayment, setShowpayment] = useState(false);
  const [submitLock, setSubmitLock] = useState(false);

  const [repeatQuestionnaireId, setRepeatQuestionnaireId] = useState();

  const [clinicalDetails, setClinicalDetails] = useState(null);

  //BMI value for Weight Management
  const [bmiValue, setBmiValue] = useState(0);

  // blood pressure
  const [highBlood, setHighBlood] = useState(0);
  const [lowBlood, setLowBlood] = useState(0);
  const [consultfee, setConsultFee] = useState(0);

  // The product used for this category's consultation
  const [consultProduct, setConsultProduct] = useState(null);

  // Vouchers
  /** Array of active vouchers */
  const [activeVouchers, setActiveVouchers] = useState([]);
  /** The currently active discount */
  const [discount, setDiscount] = useState(null);
  /** Voucher error message. Nullable string */
  const [voucherError, setVoucherError] = useState(null);

  const [isNewMedication, setIsNewMedication] = useState(false);

  //Feature Flag to check under age
  const [underAgeQuestion, setUnderAgeQuestion] = useState(false);

  // Payment type could be pay or membership, used in the payment page
  const [paymentType, setPaymentType] = useState("pay");

  const [useProgressBarLength, setUserProgressBarLength] = useState(0);
  const [progressBarLength, setProgressBarLength] = useState(0);
  const [currentProgressBarCount, setCurrentProgressBarCount] = useState(1);

  const { voucherpair } = useOutletContext();

  const membershipsStatus = useMembership();
  const location = useLocation();
  const searchparams = queryString.parse(location.search);

  const childRef = useRef();

  // Whether the user is currently editing a form in their questionnaire
  // and the next button should be disabled (until they finish editing).
  const isEditingForm = useRef(false);
  const setIsEditingForm = (value) => {
    isEditingForm.current = value;
  };

  let email_;
  let product_;

  const commonvalues = COMMTXT;
  const navigate = useNavigate();

  /**
   * Checks that a voucher code is valid and returns what discount would be applied to the ensuing consultation or product purchase
   *
   * @param {string} voucher The voucher code to apply
   */
  const applyVoucher = useCallback(
    (voucher) => {
      /**
       * Checks that a voucher exists and applies it to the consultation
       * @param {string} voucher The voucher code to apply
       * @param {import("@mh/api").Product} consultationProduct The consultation product which the given voucher will apply to
       */
      const checkVoucherExists = async (voucher, consultationProduct) => {
        try {
          const discount = await VoucherAPI.checkVoucher(
            voucher,
            consultationProduct.id
          );
          setActiveVouchers(discount.voucher_discounts);
          setDiscount(discount);
          setConsultFee(parseFloat(discount.total_incl_tax));
        } catch (e) {
          setVoucherError(
            "Looks like that's the wrong code. Please double-check and try again."
          );
        }
      };

      setVoucherError(null);
      if (!activeVouchers.includes(voucher) && consultProduct !== null) {
        checkVoucherExists(voucher, consultProduct);
      }
    },
    [activeVouchers, consultProduct]
  );

  /** Apply a voucher from the URL search params, if present. */
  useEffect(() => {
    if (consultProduct && (voucherpair.voucher || voucherpair.voucherfromurl)) {
      applyVoucher(voucherpair.voucher || voucherpair.voucherfromurl);
    }
  }, [voucherpair, consultProduct]);

  useEffect(() => {
    const getClinicalData = async () => {
      try {
        const response = await API.v2()
          .url(`/clinical-details/${repeatQuestionnaireId}`)
          .get();
        if (response.ok) {
          setClinicalDetails(await response.json());
        }
      } catch (e) {
        setClinicalDetails(null);
      }
    };
    if (repeatQuestionnaireId) {
      getClinicalData();
    }
  }, [repeatQuestionnaireId]);

  const callTimeChange = (startTime, endTime) =>
    setCallTime([startTime, endTime]);

  function medicationChange(banrdpillInfo) {
    setPreventTopScroll(true);
    setBrandpillId(banrdpillInfo?.id);
    setBrandpill(banrdpillInfo?.title);
    setDuration(banrdpillInfo?.duration);
    setIsStock(banrdpillInfo?.availability?.is_available_to_buy);
  }

  useBeforeunload((event) => {
    if (unload) {
      event.preventDefault();
    }
  });

  function closeModal() {
    modalSet(!modal_);
    modaldataSet("");
  }

  const next = (param) => async (e) => {
    if (isEditingForm.current) {
      Toast.error("Please save your changes before continuing.");
      return false;
    }

    setPreventTopScroll(true);
    if (
      (e.type === "keydown" && e.key !== "Enter") ||
      (e.type === "change" && param && param.nonext)
    )
      return;

    if (
      param.currentquestion_?.subtype === "selectbox" ||
      param.currentquestion_?.sub_type === "productSearch"
    ) {
      if (!brand_pill?.title) return;
      if (param.currentquestion_?.preference && brand_pill?.title === "Other") {
        Toast.error("Please select an option from the list provided.");
        return false;
      }
      if (
        param.currentquestion_?.sub_type === "productSearch" &&
        !brand_pill?.availability?.is_available_to_buy
      ) {
        Toast.error(
          "Unfortunately, the item(s) that you selected are out-of-stock. Please select an alternative, or contact our Patient Success team for further information."
        );
        return false;
      }
      param.obj_ = param.currentquestion_.options.find(
        (o) => o.title === brand_pill?.title
      );
    }

    if (
      param?.currentquestion_?.type === "input" &&
      document.getElementById("thisinput")?.value?.length > 100
    ) {
      Toast.error(
        `The maximum response length is 100 characters. You entered ${
          document.getElementById("thisinput").value.length
        } characters. Please update your response to continue.`
      );
      return false;
    }

    if (param && param.currentquestion_ && param.currentquestion_.require) {
      if (
        (param.currentquestion_.type === "input" ||
          param.currentquestion_.type === "textarea") &&
        document.getElementById("thisinput") &&
        !document.getElementById("thisinput").value
      ) {
        Toast.error("Please provide a response to continue.");
        return false;
      }
    }
    if (
      !acceptbypass_ &&
      !accept_ &&
      param &&
      param.currentquestion_ &&
      param.currentquestion_.ckboxs
    ) {
      Toast.error("You must check the box provided to continue.");
      return;
    }

    e.preventDefault();

    setUnload(true);

    const param_ = param;

    const nextid_ = nextid;
    const subindex_ = subindex;
    let questionnaire_ = questionnaire;
    let value = null;
    let bypass = false;
    const issubindex = false;

    let addressLine1_ = "";
    let addressLine2_ = "";
    let suburb_ = "";
    let state_ = "";
    let postcode_ = "";

    let script_file = "";
    let script_name = "";

    let highBloodVal = highBlood;
    let lowBloodVal = lowBlood;

    Base.gtmpro({
      questionnaire,
      productenv,
      _scriptname,
      _hasscript,
      dob_day,
      dob_month,
      dob_year,
      addressLine1,
      addressLine2,
      suburb,
      state,
      postcode,
      questionnaireid,
      subindex,
      showpayment,
      first_name,
      last_name,
      ph,
      user_email,
      prequs,
      prequsSet,
      setGaonce,
      gaonce,
      patientprospectid: id,
      by: "repeat",
      patientid
    });

    if (param_?.currentquestion_?.gtmstart) {
      Base.setGtm("qstartrepeat", {
        event: "questionnaireVersion",
        userType: "Returning User - Repeat Product",
        questionnaire: `${productenv.slug}-repeat`,
        questionnaireVersion: productenv.variantRepeat ?? "original",
        initialquestionnaireId: id,
        patientId: patientid
      });
    }

    if (
      (param_.obj_ &&
        !param_.obj_.bmi &&
        param_.obj_.end &&
        (!param_.obj_.products ||
          (productenv &&
            productenv.name &&
            param_.obj_.products &&
            param_.obj_.products.findIndex(
              (p) => p === productenv.name.toLowerCase()
            ) > -1))) ||
      (param_.currentquestion_ && param_.currentquestion_.type === "end") ||
      (param_.obj_ && param_.obj_.bmi && param_.obj_.bmi.min > bmiValue)
    ) {
      bypass = true;
      isendset(true);
      setUnload(false);

      Base.setGtmQus("qdisq", {
        isnewuser_: false,
        _hasscript,
        productenv,
        _scriptname,
        patientprospectid: "",
        patientId: patientid,
        currentquestion_: param_.currentquestion_,
        reason: param_.obj_ ? param_.obj_.title : ""
      });

      Base.setEndPage(param_, productenv, setData, null, null);
    } else if (param_.obj_) {
      if (
        param_.obj_ &&
        (param_.obj_.out_of_stock ||
          (param_.obj_.title &&
            param_.obj_.title.toLowerCase().includes("out of stock")))
      ) {
        Toast.error(
          "Due to an Australia wide shortage, a number of birth control pills are currently out of stock across the country. However our doctors are more than happy with you to find an appropriate solution in the interim."
        );
        return false;
      }
      let { title } = param_.obj_;

      if (
        param_.currentquestion_ &&
        ((param_.currentquestion_.ga_id &&
          param_.currentquestion_.ga_id === "A0006") ||
          (!param_.currentquestion_.ga_id &&
            param_.currentquestion_.id === 105))
      ) {
        genderSet(title);
      }

      if (param_.obj_.price && param_.obj_.duration) {
        setBrandpillTitle(title ? title : "");
        setBrandpill(
          param_.obj_.brand_pill
            ? param_.obj_.brand_pill
            : param_.obj_.display_name
            ? param_.obj_.display_name
            : title
        );
        setPrice(param_.obj_.price);
        setDuration(param_.obj_.duration);
      }

      const object = param_.obj_;

      if (
        param_.currentquestion_.type === "button" &&
        param_.currentquestion_.requestchange &&
        productenv.newRepeatMedication
      ) {
        if (title.includes("Yes")) {
          setIsNewMedication(false);
        } else {
          setIsNewMedication(true);
        }
      }

      if (
        objectIsProduct(object) &&
        (param_.currentquestion_.type === "button" ||
          param_.currentquestion_.type === "select")
      ) {
        // Change the value to a product item if it originated from a product selection
        setBrandpill(object);
        value = [
          object.isdata ? Base.getRawDatafromText(object.title) : object.title
        ];

        // Load and set the price for this product
        const { incl_tax } = await OscarAPI.getProductPrice(object.id, {
          persistedPricing: true
        });
        setPrice(incl_tax);
      } else {
        value = [title];
      }

      if (param_.obj_ && param_.obj_.type && param_.obj_.type === "Other") {
        if (hideOther) {
          setHideOther(false);
          delete param_.obj_.next;
          return false;
        }
      }
    } else {
      const result = Base.validation(param_.currentquestion_, null, {
        productenv,
        skipvalidation: param_?.skipvalidation,
        underAgeQuestion,
        patientid
      });

      if (!result.vaild) {
        Toast.error(result.msg);
        return false;
      }

      let thisinput = document.getElementById("thisinput");

      if (
        param_.currentquestion_.type === "input" ||
        param_.currentquestion_.type === "textarea" ||
        param_.currentquestion_.type === "textarea_submit"
      ) {
        value = [thisinput.value];
      } else if (param_.currentquestion_.type === "upload") {
        const thisscriptdoc =
          document.getElementById("current_script").files[0];
        const { name } = thisscriptdoc;
        const result = await Base.readFileAsync(thisscriptdoc, name);
        setScriptFile(result);
        setScriptName(name);
        script_file = result;
        script_name = name;
      } else if (param_.currentquestion_.type === "multichoice") {
        thisinput = document.getElementsByName("thisinput[]");
        const inputlist = [];

        for (let i = 0; i < thisinput.length; i++) {
          if (thisinput[i].checked) {
            let tmpvalue = thisinput[i].value;
            if (thisinput[i].alt) {
              const id_ =
                thisinput[i].alt === "other"
                  ? "multi-other-text"
                  : `${thisinput[i].alt}-text`;
              const txtvalue = jQuery(`#${id_}`).val();
              const option_ =
                param_.currentquestion_ &&
                param_.currentquestion_.options &&
                Array.isArray(param_.currentquestion_.options)
                  ? param_.currentquestion_.options.find(
                      (op) => tmpvalue && op.title === tmpvalue.trim()
                    )
                  : "";
              if (
                option_ &&
                option_.required &&
                option_.required === "yes" &&
                (!txtvalue || txtvalue.trim() === "")
              ) {
                Toast.error("Please provide a response to continue.");
                return false;
              }
              tmpvalue = `${thisinput[i].value}: ${txtvalue}`;
            }
            inputlist.push(tmpvalue);
          }
        }

        if (inputlist.length < 1) {
          Toast.error("Please select at least one option.");
          return false;
        }

        if (
          param_.currentquestion_.count &&
          param_.currentquestion_.count < inputlist.length
        ) {
          Toast.error(
            `Please select no more than ${
              param_.currentquestion_.count
            } option${param_.currentquestion_.count > 1 ? "s" : ""}.`
          );
          return false;
        }

        value = inputlist;
      } else if (param_.currentquestion_.type === "mobile") {
        if (ph?.length > 0) {
          value = [ph];
        } else {
          value = ["CONTACT VIA PORTAL"];
        }
      } else if (param_.currentquestion_.type === "name") {
        let first = "";
        let last = "";
        for (const key in result.values) {
          const value = result.values[key] ? result.values[key] : "";
          if (key === "first_name") {
            first = value;
            setFirstName(value);
          } else if (key === "last_name") {
            last = value;
            setLastName(value);
          }
        }

        value = `${first}_${last}`;
      } else if (param_.currentquestion_.type === "bloodPressure") {
        let low = "";
        let high = "";
        for (const key in result.values) {
          const value = result.values[key] ? result.values[key] : "";
          if (key === "lowBlood") {
            lowBloodVal = value;
            setLowBlood(value);
          } else if (key === "highBlood") {
            highBloodVal = value;
            setHighBlood(value);
          }
        }

        value = `${lowBloodVal}_${highBloodVal}`;
      } else if (param_.currentquestion_.type === "image") {
        if (
          param_.currentquestion_.tmpimgs &&
          param_.currentquestion_.tmpimgs.imgs &&
          param_.currentquestion_.tmpimgs.imgs.length > 0
        ) {
          setImageFiles(
            !imagefiles || imagefiles.length === 0
              ? [].concat([param_.currentquestion_.tmpimgs])
              : imagefiles && imagefiles.length === 1
              ? [imagefiles].concat([param_.currentquestion_.tmpimgs])
              : imagefiles.concat([param_.currentquestion_.tmpimgs])
          );
        }
      } else if (param_.currentquestion_.type === "address") {
        if (!param.skipvalidation) {
          for (const key in result.values) {
            const value = result.values[key] ? result.values[key] : "";
            if (key === "addressLine1") {
              addressLine1_ = value;
            } else if (key === "addressLine2") {
              addressLine2_ = value;
            } else if (key === "postcode") {
              postcode_ = value;
            } else if (key === "suburb") {
              suburb_ = value;
            } else if (key === "address_state") {
              state_ = value;
            }
          }

          setAddressLine1(addressLine1_);
          setAddressLine2(addressLine2_);
          setSuburb(suburb_);
          setState(state_);
          setPostcode(postcode_);
        }
        value = `${!param.skipvalidation ? addressLine1_ : addressLine1}_${
          !param.skipvalidation ? addressLine2_ : addressLine2
        }_${!param.skipvalidation ? suburb_ : suburb}_${
          !param.skipvalidation ? state_ : state
        }_${!param.skipvalidation ? postcode_ : postcode}`;
      } else if (param_.currentquestion_.type === "personalDetails") {
        const addressResponse = await API.url(`/shop/api/useraddresses/`).get();
        if (addressResponse.ok) {
          const data = await addressResponse.json();
          if (data.count) {
            setAddressLine1(data.results[0].line1);
            setAddressLine2(data.results[0].line2);
            setSuburb(data.results[0].suburb);
            setState(data.results[0].state);
            setPostcode(data.results[0].postcode);
            addressLine1_ = data.results[0].line1;
            addressLine2_ = data.results[0].line2;
            postcode_ = data.results[0].postcode;
            suburb_ = data.results[0].suburb;
            state_ = data.results[0].state;
          }
        }
      } else if (param_.currentquestion_.type === "dob") {
        let dob_day = "";
        let dob_month = "";
        let dob_year = "";
        for (const key in result.values) {
          const value = result.values[key] ? result.values[key] : "";
          if (key === "dob_day") {
            dob_day = value;
            dob_daySet(value);
          } else if (key === "dob_month") {
            dob_month = value;
            dob_monthSet(value);
          } else if (key === "dob_year") {
            dob_year = value;
            dob_yearSet(value);
          } else if (key === "date_picker") {
            if (param_.currentquestion_.type === "dob") {
              const [day, month, year] = value.split("/");
              dob_daySet(day);
              dob_monthSet(month);
              dob_yearSet(year);
            }
          }
        }
        value = `${dob_day}_${dob_month}_${dob_year}`;
      } else if (param_.currentquestion_.type === "token") {
        value = result.values.token;
        setScriptToken(value);
      } else if (param_.currentquestion_.type === "medication") {
        value = [brand_pill_id];
      } else if (param_.currentquestion_.type === "clinicalDetails") {
        value = clinicalDetails;
      }
    }

    setPreventTopScroll(false);
    setCurrentProgressBarCount(currentProgressBarCount + 1);

    const shouldUseNewAddressField = (field) => {
      return (
        (param.currentquestion_.type === "address" && !param.skipvalidation) ||
        (param.currentquestion_.type === "personalDetails" && field)
      );
    };

    if (!bypass) {
      await Base.setQuestionnaire(
        param_.obj_ ? param_.obj_ : param_,
        qSet,
        setQuestionnaireid,
        setSubindex,
        setSubindex2,
        setNextId,
        nextid_,
        value,
        questionnairedata,
        questionnaire_,
        questionnaireid,
        "next",
        {
          parent: param_.obj_ ? param_.currentquestion_ : "",
          addressLine1: shouldUseNewAddressField(addressLine1_)
            ? addressLine1_
            : addressLine1,
          addressLine2: shouldUseNewAddressField(addressLine2_)
            ? addressLine2_
            : addressLine2,
          suburb: shouldUseNewAddressField(suburb_) ? suburb_ : suburb,
          state: shouldUseNewAddressField(state_) ? state_ : state,
          postcode: shouldUseNewAddressField(postcode_) ? postcode_ : postcode,
          showpayment,
          setShowpayment,
          subindex_,
          issubindex,
          deliveryoptionSet,
          qdataSet,
          scriptqus,
          Userquestions,
          AddUserquestions,
          AddUserquestionsnew,
          productqus,
          _hasscript,
          scriptqustype,
          accept_,
          acceptSet,
          checkbtsSet,
          checkbts,
          script_file,
          script_name,
          user_email,
          smokingStatus,
          smokingStatusSet,
          productenv,
          isendset,
          brand_pill,
          addfields: extrafields,
          imagefiles,
          ph,
          isSameDay,
          patientid,
          _scriptname,
          requestchange,
          gender,
          setShowAfter,
          iscoldchain,
          setIsColdChain,
          setBtLoading,
          injectablesUnavailable,
          bmiValue,
          highBlood: highBloodVal,
          lowBlood: lowBloodVal,
          setIsNewMedication,
          underAgeQuestion,
          setUnload,
          clinicalDetails,
          setClinicalDetails,
          progressBarLength: useProgressBarLength ? progressBarLength : false,
          setProgressBarLength: useProgressBarLength
            ? setProgressBarLength
            : false,
          by: "repeats"
        }
      );
    }

    if (!hideOther) {
      // other text can now be accessed here before being dismissed
      param_.currentquestion_.response_.other = other;
      questionnaire_ = Base.setCurrentQuestionResponse(
        questionnaire_,
        questionnaireid + nextid_ - (subindex_ > 0 ? subindex_ - 1 : 0),
        [
          `${
            param_.currentquestion_.replaceother
              ? param_.currentquestion_.replaceother
              : "Other"
          }: ${other}`
        ],
        qSet,
        param_.currentquestion_
      );
      qSet(questionnaire_);
      setHideOther(true);
      setOther(null);
    }

    if (param_.currentquestion_?.before === "beforesubmitrepeats") {
      Base.redirectToStartByMalformed(
        isCheckRes,
        questionnaire,
        questionnairedata,
        productenv,
        user_email,
        setUnload,
        "repeatquestionnaire"
      );
    }
    Toast.dismiss();
  };

  const back = async (e) => {
    e.preventDefault();
    let nextid_ = nextid;
    let questionnaireid_ = questionnaireid;
    const subindex_ = subindex;
    const subindex2_ = subindex2;

    if (questionnaire[questionnaire.length - 1].type === "upload") {
      setScriptFile("");
      setScriptName("");
    }
    if (questionnaire[questionnaire.length - 1]?.sub_type === "productSearch") {
      setBrandpill(brand_origin_pill);
    }

    if (subindex_ > 0 && questionnaire[questionnaire.length - 1].parent_) {
      console.log("skip");
    } else if (nextid > 0 && questionnaire[questionnaire.length - 1].nextid) {
      nextid_ = nextid - 1;
    } else {
      questionnaireid_ -= 1;
    }

    if (questionnaire[questionnaire.length - 1].jumpnext) {
      nextid_ -= questionnaire[questionnaire.length - 1].jump_;
    } else if (questionnaire[questionnaire.length - 1].jump_) {
      questionnaireid_ -= questionnaire[questionnaire.length - 1].jump_;
    }

    const lastProgressBarCount =
      questionnaire[questionnaire.length - 1].addProgressBarCount;
    if (useProgressBarLength) {
      if (lastProgressBarCount)
        setProgressBarLength(progressBarLength - lastProgressBarCount);
      setCurrentProgressBarCount(currentProgressBarCount - 1);
    }

    let q_ = [];

    if (data_) {
      setData("");
    } else {
      Base.logger(
        Base.Config().STATE,
        null,
        null,
        `questionnaireid_: ${questionnaireid_}, nextid_: ${nextid_}`
      );

      if (questionnairedata.length === 2 && questionnaireid_ === 0) {
        q_ = [questionnaire[0]];
        setQuestionnaireid(questionnaireid_);
        setNextId(0);
        setSubindex(0);
      } else if (
        subindex2_ > 0 &&
        questionnaire[questionnaire.length - 1].parent_
      ) {
        let count = 0;
        const pt = questionnaire[questionnaire.length - 1].parent_;
        for (let i = 0; i < questionnaire.length; i++) {
          if (
            questionnaire[i].parent_ &&
            questionnaire[i].parent_.id === pt.id
          ) {
            count++;
          } else {
            q_.push(questionnaire[i]);
          }
        }
        nextid_ = nextid - count;
        setNextId(nextid_);
        setSubindex2(0);
      } else if (
        subindex_ > 0 &&
        questionnaire[questionnaire.length - 1].parent_
      ) {
        let count = 0;
        const pt = questionnaire[questionnaire.length - 1].parent_;
        for (let i = 0; i < questionnaire.length; i++) {
          if (
            questionnaire[i].parent_ &&
            questionnaire[i].parent_.id === pt.id
          ) {
            count++;
          } else {
            q_.push(questionnaire[i]);
          }
        }
        nextid_ = nextid - count;
        setNextId(nextid_);
        setSubindex(0);
      } else {
        let jump_ = 0;
        if (questionnaire[questionnaire.length - 1].jump_) {
          jump_ = questionnaire[questionnaire.length - 1].jump_;
        }
        q_ = questionnaire.slice(0, questionnaire.length - 1 - jump_);
        setQuestionnaireid(questionnaireid_);
        setNextId(nextid_);
        setSubindex(subindex_);
      }
      qSet(q_);
      if (q_[q_.length - 1].response_ && q_[q_.length - 1].response_.accept_) {
        Base.rehandleCheck(
          q_[q_.length - 1].response_.accept_,
          checkbtsSet,
          acceptSet
        );
      }
    }
    isendset(false);
    setHideOther(true);
    setOther(null);
    if (q_ && q_.length >= 1 && q_[q_.length - 1]?.setUnload) setUnload(false);
    Toast.dismiss();
  };

  async function submitrepeats(e = null, param) {
    e?.preventDefault();
    Base.logger(Base.Config().STATE, null, null, { param });

    if (submitLock) {
      Toast.info("Please wait while we process your request.");
      return true;
    }

    if (!submitLock) {
      setSubmitLock(true);
    }

    const loader = document.getElementById("cover-spin");
    Base.setSubmitLoading(loader);

    const body = {
      brand: Base.capitalizeFirstLetter(Base.Config().FROM).trim(),
      product: productname,
      pqid: id
    };

    if (ph) {
      body.phone = ph;
    }

    if (brand_pill_id || brand_pill?.id >= 0) {
      body.shop_product =
        productenv.newRepeatMedication && isNewMedication && brand_pill?.id
          ? brand_pill?.id
          : brand_pill_id
          ? brand_pill_id
          : brand_pill?.id;
    }

    const response_ = [];

    for (let i = 0; i < questionnaire.length; i++) {
      Base.setResponseForm(
        questionnaire[i],
        response_,
        {
          brand_pill,
          submit: true,
          slug: productenv.slug
        },
        "repeatquestionnaire"
      );
    }

    body.response = response_;

    if (gender) {
      body.gender = gender;
    }

    if (activeVouchers && Array.isArray(activeVouchers)) {
      body.vouchers = activeVouchers.map(({ voucher }) => voucher.code);
    }

    const providedCookies = getCookies();
    if (providedCookies) {
      body.tracking = providedCookies;
    }

    // if user has selected membership, add create_membership to the body
    if (paymentType === "membership-fee-monthly") {
      body.create_membership = true;
    }

    Base.logger(Base.Config().STATE, null, null, { body });

    const submit_ = await Base.Fetch(
      `${Base.Config().HOST}/${Base.Config().API}/submit_repeat_questionnaire/`,
      {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body)
      },
      "repeatsquestionnaire",
      "submit",
      false,
      false,
      email_,
      false,
      true
    );

    const submit = Base.getResult(submit_, "submit_repeat_questionnaire");

    if (!submit || submit.iserror) {
      Base.setSubmitLoading(loader, true);
      setSubmitLock(false);

      if (
        submit &&
        ((submit.msg &&
          (Array.isArray(submit.msg) || typeof submit.msg === "string") &&
          submit.msg?.includes("paymentfailed")) ||
          submit?.submit_repeat_questionnaire?.paymentfailed)
      ) {
        Toast.error(
          "Sorry, we were unable to process your payment using the details we have on file. Please enter your card details below and try again."
        );
        Sentry.captureMessage(
          `The payment method we have on file has failed for renewal. Please ensure there are funds in your account and reenter your details below: ${user_email}`
        );

        setTimeout(() => {
          modalSet(true);
          modaldataSet(
            <CCards
              modalSet={modalSet}
              modaldataSet={modaldataSet}
              hidemodal={false}
              loc={"questionnaire"}
              from={"repeatquestionnaire"}
              noact={true}
              issignup={false}
              ref={childRef}
              noaddbt={false}
              newsubmitqus={true}
              setShowPayment={setShowpayment}
              type={"decline"}
              bttxt={"Save"}
              hiddenOverflow={true}
            />
          );
        }, 500);
        return false;
      }

      Toast.error(
        "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 2349]."
      );
      Sentry.captureMessage(
        "Error while processing your request: 2349",
        (scope) => {
          scope.setExtra("result", submit);
          return scope;
        }
      );
      return false;
    }

    if (
      submit.submit_repeat_questionnaire &&
      !submit.submit_repeat_questionnaire.error
    ) {
      setUnload(false);
      if (consultfee > 0) {
        Base.setPurchaseGtm({
          userType: "Returning User - Repeat Product",
          questionnaireId: id,
          purchaseType: "On Demand",
          productprice: consultfee,
          patientid: patientid ? patientid : "",
          firstname: first_name ? first_name : "",
          lastname: last_name ? last_name : "",
          email: User.email(),
          dob: dob_year ? `${dob_year}${dob_month}${dob_day}` : "",
          suburb: suburb ? suburb : "",
          mobile: ph ? ph : "",
          product: productenv.name,
          productcode: productenv.name.toLowerCase(),
          dimension6: "Consult",
          dimension7: "Consult",
          total: consultfee,
          quantity: 1,
          deliveryoption,
          promo:
            activeVouchers && Array.isArray(activeVouchers)
              ? activeVouchers.map(({ voucher }) => voucher.code).join("|")
              : false,
          script_only: false
        });
      }

      Base.setGtmQus("qcomp", {
        by: "repeat",
        patientprospectid: id,
        email: user_email,
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        db: dob_year ? `${dob_year}${dob_month}${dob_day}` : "",
        suburb: suburb ? suburb : "",
        productenv: productenv,
        isnewuser_: false,
        pqid: id,
        showpayment,
        patientId: patientid ? patientid : ""
      });
      setShowCompletionModal(true);
    } else {
      const defaultMsg =
        "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 1230].";
      const msg =
        submit &&
        submit.submit_repeat_questionnaire &&
        submit.submit_repeat_questionnaire.error
          ? submit.submit_repeat_questionnaire.error
          : defaultMsg;

      Toast.error(msg);
      if (msg === defaultMsg) {
        Sentry.captureMessage(
          "Error while processing your request: 1230",
          (scope) => {
            scope.setExtra("result", submit.submit_repeat_questionnaire.error);
            return scope;
          }
        );
      }
      return false;
    }
  }

  useEffect(() => {
    async function setup() {
      if (!User.loggedIn() || !id || !product) {
        if (loader) loader.className = "";
        navigate(-1);
        return false;
      }
      setUnload(false);
      const userinfo = await Base.getPtDetails("repeatsquestionnaire");
      if (
        userinfo.iserror ||
        !userinfo.patientProfiledetails ||
        !userinfo.patientProfiledetails.patient_id
      ) {
        if (loader) loader.className = "";
        if (userinfo.notoken) {
          Base.showRedirectMessage(
            "/",
            "Sorry, we were unable to process your request. Please try again and contact our Patient Success team if the issue persists.",
            {
              logout: User.logout
            }
          );
        }
        navigate(-1);
        return false;
      }

      const quss = await Base.getNewProductDetails("product->useEffect");
      if (
        !quss ||
        !quss.patientquestionnaire ||
        !Array.isArray(quss.patientquestionnaire) ||
        quss.patientquestionnaire.findIndex((pq) => pq.id == id) <= -1
      ) {
        if (loader) loader.className = "";
        Sentry.captureMessage(
          "No Questionnaire Data for Repeat Script",
          (scope) => {
            scope.setExtra("initial_questionnaire_id", id);
            return scope;
          }
        );
        navigate(-1);
        return false;
      }

      if (quss.nopatientplan) {
        Sentry.captureMessage("No Patient Plan for Repeat Script", (scope) => {
          scope.setExtra("initial_questionnaire_id", id);
          return scope;
        });
      }

      const info = quss.patientquestionnaire.find((pq) => pq.id == id);
      if (!info?.repeatQuestionnaireId) {
        Sentry.captureMessage(
          "No Repeat Questionnaire Id for Repeat Script",
          (scope) => {
            scope.setExtra("initial_questionnaire_id", id);
            return scope;
          }
        );
      }

      const subscription = info.subscription_scripts
        .sort((a, b) => (a.start_date < b.start_date ? -1 : 1))
        .at(-1);
      const renewalQuestionnaire =
        subscription?.repeat_questionnaires
          .sort(compareQuestionnairesByCreatedDate)
          ?.at(-1) ?? null;

      const previousActiveQuestionnaire =
        subscription?.repeat_questionnaires
          .filter(
            (question) =>
              question.consultation &&
              question.consultation.status === "approve"
          )
          .at(-1) || info;

      if (
        !renewalQuestionnaire ||
        renewalQuestionnaire.script ||
        renewalQuestionnaire.consultation?.status
      ) {
        if (loader) loader.className = "";
        Toast.error(
          "Sorry, you are unable to complete this questionnaire at this stage. Please contact our Patient Success team if you believe there is an issue."
        );
        setTimeout(() => {
          navigate(-1);
        }, 300);
        return false;
      }

      setRepeatQuestionnaireId(renewalQuestionnaire.id);
      setUserEmail(User?.email());
      setpatientid(userinfo.patientProfiledetails.patient_id);
      genderSet(userinfo.patientProfiledetails?.gender ?? null);

      const id_ = id;
      let thisproduct = product.toUpperCase();

      const result = await Base.checkandSetQuestionnairedata(
        id_,
        {
          setUserEmail,
          setFirstName,
          setLastName,
          dob_daySet,
          dob_monthSet,
          dob_yearSet,
          setAddressLine1,
          setAddressLine2,
          setSuburb,
          setState,
          setPostcode,
          setPh,
          weightSet,
          heightSet,
          setBrandpill,
          setOriginBrandpill,
          setPrice,
          setBmiValue
        },
        "repeatquestionnaire"
      );

      if (result && result.error) {
        if (loader) loader.className = "";
        navigate(-1);
        return false;
      } else if (!result || !result?.info) {
        Sentry.captureMessage(
          "No Checked Questionnaire Data for Repeat Script",
          (scope) => {
            scope.setExtra("initial_questionnaire_id", id);
            return scope;
          }
        );
      }

      try {
        thisproduct = thisproduct.replaceAll(/%20| |-| |\+/g, "_");
      } catch (e) {
        if (e instanceof TypeError) {
          thisproduct = thisproduct.replace(/%20| |-| |\+/g, "_");
        }
      }

      if (thisproduct === "HAIR_LOSS") {
        thisproduct = "HL";
      }

      product_ = thisproduct;

      setProductcode(thisproduct);

      if (thisproduct === "HL") {
        product_ = "Hair Loss";
      } else if (thisproduct === "SLEEP") {
        product_ = "Sleep";
      } else if (thisproduct === "Hair Loss") {
        thisproduct = "HL";
      } else if (thisproduct === "FINE_LINES_&_WRINKLES") {
        thisproduct = "finelineswrinkles";
      } else if (thisproduct === "UNEVEN_SKIN_TONE") {
        thisproduct = "unevenskintone";
      }

      const products = Base.getprodcatinfo();
      const variantProductList = products.filter(
        (prod) => prod.variantRepeat && prod.code === thisproduct.toLowerCase()
      );
      const variantProduct =
        variantProductList.length > 0 &&
        variantProductList[
          Math.floor(Math.random() * variantProductList.length)
        ];
      const isVariant = variantProduct ? Math.random() < 0.5 : false;

      const productenv = isVariant
        ? variantProduct
        : products.find(
            (prod) =>
              !prod.variantRepeat &&
              !prod.variant &&
              prod.code.toLowerCase() === thisproduct.toLowerCase()
          );

      setProductname(productenv.name);
      if (!productenv) {
        if (loader) loader.className = "";
        Toast.error(
          "Sorry, the product at the specified URL does not exist. Please contact our patient success team for assistance."
        );
        navigate(-1);
        return false;
      }

      if (
        userinfo &&
        userinfo.patientProfiledetails &&
        userinfo.patientProfiledetails.dob
      ) {
        const age = Base.getAgebyDOB(userinfo.patientProfiledetails.dob);
        if (age > 0 && productenv.age) {
          if (parseInt(productenv.age) > age) {
            if (loader) loader.className = "";
            Base.showRedirectMessage(
              "/",
              `Sorry, you need to be ${productenv.age} or over to use this service.`
            );
            return false;
          }
        } else if (age > 0 && age < Base.Config().MINAGE) {
          if (loader) loader.className = "";
          Base.showRedirectMessage(
            "/",
            `Sorry, you need to be ${
              Base.Config().MINAGE
            } or over to use this service.`
          );
          return false;
        }
      }

      setPtPlanInfo(info);
      setBrandpillId(info?.repeat_shop_product?.id ?? info?.shop_product?.id);

      const productdata = await import(
        `../../questionnaires/${
          productenv.variantRepeat
            ? productenv.useBaseQuestionnaire
              ? productenv.code
              : productenv.variantRepeat
            : thisproduct.toLowerCase()
        }_repeats.js`
      );

      const [injectablesUnavailable, category, underAgeQuestion] =
        await Promise.all([
          Feature.isActive("injectablesUnavailable-RenewalQuestionnaire"),
          getCategory(productenv.name),
          Feature.isActive("under_18")
        ]);

      setInjectablesUnavailable(injectablesUnavailable);
      setUnderAgeQuestion(underAgeQuestion);

      const allCategories = await OscarAPI.getCategories(undefined, {
        forceAll: true
      });

      if (!allCategories || !Array.isArray(allCategories)) {
        Sentry.captureMessage(
          "No Categories are returned for Repeat Script:",
          (scope) => {
            scope.setExtra("initial_questionnaire_id", id);
            return scope;
          }
        );
      }

      const categoryIndex = allCategories.findIndex(
        (c) =>
          (c.slug.includes("_") && c.slug === productenv.code) ||
          c.slug === productenv.code.replace(/_| /g, "-") ||
          (productenv.code === "hl" && c.slug === "hair-loss")
      );

      if (!allCategories[categoryIndex].slug) {
        Sentry.captureMessage(
          `No Category Code(${productenv.code}) for Repeat Script`,
          (scope) => {
            scope.setExtra("initial_questionnaire_id", id);
            return scope;
          }
        );
      }

      const consultProduct = await OscarAPI.getRenewalConsultationProduct(
        allCategories[categoryIndex]?.slug
      );

      if (!consultProduct?.id) {
        Sentry.captureMessage(
          "No ConsultProduct Id for Repeat Script",
          (scope) => {
            scope.setExtra("initial_questionnaire_id", id);
            return scope;
          }
        );
      }

      const consultFee = await OscarAPI.getProductPrice(consultProduct.id, {
        persistedPricing: true
      }).then((price) => parseFloat(price.incl_tax));

      const productId = info?.repeat_shop_product?.id ?? info?.shop_product?.id;

      setConsultProduct(consultProduct);
      setConsultFee(consultFee);

      const formresult = await Base.createFullForm(
        productdata,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        productenv,
        null,
        null,
        null,
        null,
        setBrandpill,
        setPrice,
        setDuration,
        null,
        {
          deliveryoptionSet,
          info: result.info,
          isSameDay,
          setIsCheckRes,
          setIsColdChain,
          injectablesUnavailable,
          category,
          productId
        },
        "repeats"
      );

      setRequestchange(searchparams?.requestchange);

      if (!formresult || !formresult.isvaild) {
        if (loader) loader.className = "";

        if (formresult && formresult.message) {
          Toast.error(formresult.message);
          Sentry.captureMessage(
            `Error Message(${formresult.message}) for Repeat Script`,
            (scope) => {
              scope.setExtra("initial_questionnaire_id", id);
              return scope;
            }
          );
        } else if (formresult && formresult.redirect) {
          Sentry.captureMessage(
            "Show Redirect Modal for Repeat Script",
            (scope) => {
              scope.setExtra("initial_questionnaire_id", id);
              return scope;
            }
          );
        } else {
          navigate(-1);
        }
        return false;
      }

      const productprice = productId
        ? await OscarAPI.getProductPrice(productId, { persistedPricing: true })
        : undefined;

      if (!productprice || !productprice?.incl_tax) {
        Sentry.captureMessage("No Product Price for Repeat Script", (scope) => {
          scope.setExtra("initial_questionnaire_id", id);
          return scope;
        });
      }

      setSingleRepeatPrice(productprice?.incl_tax);
      setPreferredmonth(info?.preferred_months ?? 1);

      Base.logger(Base.Config().STATE, null, null, { formresult });

      const latestScriptOrder =
        previousActiveQuestionnaire?.script?.orders.at(-1);
      if (latestScriptOrder?.shipping_incl_tax) {
        setShippingCost(latestScriptOrder.shipping_incl_tax);
      } else {
        const shiplist = await CheckoutAPI.getShippingMethods({
          default: true,
          persisted_shipping: false // Repeat questionnaires set the shipping price to the latest price
        });
        const expressShippingCost =
          shiplist.find((s) => s.code === "express")?.price?.incl_tax ?? -1;
        setShippingCost(expressShippingCost);
      }

      if (!productenv) {
        Sentry.captureMessage("No Product Env for Repeat Script", (scope) => {
          scope.setExtra("initial_questionnaire_id", id);
          return scope;
        });
      }
      if (!productdata) {
        Sentry.captureMessage("No Product Data for Repeat Script", (scope) => {
          scope.setExtra("initial_questionnaire_id", id);
          return scope;
        });
      }
      if (!formresult || !formresult?.qform) {
        Sentry.captureMessage("No Form Data for Repeat Script", (scope) => {
          scope.setExtra("initial_questionnaire_id", id);
          return scope;
        });
      }
      setProductenv(productenv);
      setProductData(productdata);
      qdataSet(formresult.qform);
      qSet([formresult.qform[0]]);
      setQuestionnaireid(0);
      setNextId(0);
      setisInitDone(true);

      if (productenv?.reduceProgressBarCount) {
        setUserProgressBarLength(true);
        setProgressBarLength(
          formresult.qform?.length - (productenv?.reduceProgressBarCount ?? 0)
        );
      }

      if (formresult.qform[0].requestchange && searchparams?.requestchange) {
        const curqus = Object.assign({}, formresult.qform[0]);
        curqus.response_ = {
          title: curqus.title,
          response: [curqus.requestchange],
          id: curqus.id,
          ga_id: curqus.ga_id ? curqus.ga_id : "",
          depth: curqus.depth ? curqus.depth : 0,
          skip: curqus.skip,
          next: false
        };
        curqus.value = [curqus.requestchange];
        curqus.jump_ = 1;
        if (productenv?.newRepeatMedication) setIsNewMedication(true);
        qSet([curqus, formresult.qform[1]]);
        setQuestionnaireid(1);
        if (productenv?.reduceProgressBarCount) setCurrentProgressBarCount(2);
      }
    }

    setup();
  }, []);

  const thisperc = isend
    ? 100
    : (
        ((questionnaireid + 1) / (questionnairedata.length + nextid)) *
        100
      ).toFixed(0);

  const form = QTemplate.displayform({
    data_,
    questionnaire,
    productenv,
    _scriptname,
    price,
    dob_day,
    dob_month,
    dob_year,
    addressLine1,
    addressLine2,
    suburb,
    state,
    postcode,
    questionnaireid,
    height,
    weight,
    commonvalues,
    subindex,
    showpayment,
    first_name,
    last_name,
    user_email,
    next,
    modalSet,
    childRef,
    back,
    IsScrollBottom_: Base.IsScrollBottom_,
    imagefiles,
    setImageFiles,
    setIsEventPromo,
    iseventpromo,
    submitrepeats,
    createButtonText: Base.createButtonText,
    iscoupon: Base.Config().ENABLECOUPON,
    handleCheck: Base.handleCheck,
    checkbtsSet,
    accept_,
    checkbts,
    acceptSet,
    Base,
    acceptBypassSet,
    acceptbypass_,
    hideOther,
    setHideOther,
    other,
    setOther,
    onChangeMultiChoice: Base.onChangeMultiChoice,
    productname: productenv ? productenv.name : "",
    issameday,
    brand_pill_title,
    brand_pill,
    scriptToken,
    setScriptToken,
    isInitDone,
    from: Base.Config().FROM,
    shippingCost,
    ptPlanInfo,
    callTimeChange,
    callTime,
    ph,
    setPh,
    brand_pill_id,
    medicationChange,
    singleRepeatPrice,
    preferredmonth,
    btLoading,
    setBtLoading,
    setBrandpill,
    by: "repeat",
    repeatQuestionnaireId,
    clinicalDetails,
    setClinicalDetails,
    setUnload,
    unload,
    highBlood,
    lowBlood,
    submit: submitrepeats,
    consultfee,
    activeVouchers,
    discount,
    applyVoucher,
    voucherError,
    promocode: voucherpair,
    setIsEditingForm,
    paymentType,
    setPaymentType,
    membershipsStatus
  });

  const loader = document.getElementById("cover-spin");
  if (loader) loader.className = form ? "" : "show";

  if (!preventTopScroll) {
    Base.scrollTop_();
  }

  const questionnaireIsEnabled = questionnairedata?.length > 0;
  return (
    <div
      className={`my-questionnaire container ${
        questionnaire &&
        questionnaire.length >= 1 &&
        questionnaire[questionnaire.length - 1] &&
        questionnaire[questionnaire.length - 1].noheader
          ? "noheader-outer"
          : ""
      }`}
    >
      <div className="questionnaire-base outter-box">
        <div
          className={`header row ${
            questionnaire &&
            questionnaire.length >= 1 &&
            questionnaire[questionnaire.length - 1] &&
            questionnaire[questionnaire.length - 1].noheader
              ? "d-none"
              : ""
          }`}
        >
          <div className="col-2 col-lg-3"></div>
          <div
            className="logo col-8 col-lg-6 text-center"
            css={{ maxWidth: "unset" }}
          >
            <div className="questionnaire-logo-size-container">
              <LogoBrand />
            </div>
          </div>
          <div className="col-2 col-lg-3"></div>
          <div className="col-12 mb-lg-5"></div>
          {questionnaireIsEnabled && (
            <ProgressBar
              isEnd={
                questionnaire[questionnaire.length - 1]?.type ===
                "submitrepeats"
              }
              hideback={productenv.noback || questionnaire.length <= 1}
              back={back}
              current={
                useProgressBarLength
                  ? currentProgressBarCount
                  : questionnaireid + 1 + nextid
              }
              total={
                useProgressBarLength
                  ? progressBarLength + nextid
                  : questionnairedata.length + nextid
              }
            />
          )}
        </div>
        <div
          className={`content ${
            questionnaire &&
            questionnaire.length >= 1 &&
            questionnaire[questionnaire.length - 1] &&
            questionnaire[questionnaire.length - 1].noheader
              ? "noheader-content"
              : ""
          }`}
        >
          <div className="row">
            {questionnaire[questionnaire.length - 1] &&
              (questionnaire[questionnaire.length - 1]?.type !== "splash" ||
                questionnaire[questionnaire.length - 1]?.type !==
                  "personalDetails") && (
                <div
                  className={`${
                    questionnaire[questionnaire.length - 1] &&
                    questionnaire[questionnaire.length - 1].type ===
                      "submitrepeats"
                      ? "col-lg-1"
                      : "col-lg-2"
                  }`}
                ></div>
              )}
            <div
              className={`col-12 ${
                questionnaire[questionnaire.length - 1]
                  ? questionnaire[questionnaire.length - 1]?.type ===
                      "splash" ||
                    questionnaire[questionnaire.length - 1]?.type ===
                      "personalDetails"
                    ? "col-lg-12"
                    : questionnaire[questionnaire.length - 1]?.type ===
                      "submitrepeats"
                    ? "col-lg-10"
                    : "col-lg-8"
                  : "col-lg-8"
              }`}
            >
              {form}
            </div>
            {questionnaire[questionnaire.length - 1] &&
              (questionnaire[questionnaire.length - 1]?.type !== "splash" ||
                questionnaire[questionnaire.length - 1]?.type !==
                  "personalDetails") && (
                <div
                  className={`${
                    questionnaire[questionnaire.length - 1] &&
                    questionnaire[questionnaire.length - 1].type ===
                      "submitrepeats"
                      ? "col-lg-1"
                      : "col-lg-2"
                  }`}
                ></div>
              )}
          </div>
        </div>
      </div>
      <div className="repeatQuestionnaire-modal">{modal_ && modaldata}</div>
      {showCompletionModal && (
        <QuestionnaireCompletionModal
          onClickFn={() => navigate("/")}
          membershipsStatus={membershipsStatus}
        />
      )}
    </div>
  );
}
