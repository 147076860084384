import { useEffect, useState } from "react";
import { Row, Form } from "react-bootstrap";
import { Validation } from "@mh/core";

import { useQContext } from "@mh/questionnaire/src/hooks";

import { QuestionnaireContentComponentProps } from ".";

export const QCEmail = (props: QuestionnaireContentComponentProps) => {
  const { setLocalResponseValidationError } = useQContext();
  const [value, setValue] = useState<string>(props.initialValue ?? "");
  const [confirmValue, setConfirmValue] = useState<string>(
    props.initialValue ?? ""
  );

  useEffect(() => {
    const validation = Validation.email()
      .match(confirmValue, "confirmation email", { name: "Email" })
      .validate(value);

    if (confirmValue === "" && value === "") {
      setLocalResponseValidationError(null);
      props.onChange(undefined);
    } else if (validation !== null) {
      setLocalResponseValidationError(validation);
      props.onChange(undefined);
    } else {
      setLocalResponseValidationError(null);
      props.onChange(value);
    }
  }, [value, confirmValue]);

  return (
    <Row>
      <Form.Control
        onChange={(event) => setValue(event.target.value)}
        placeholder="Email"
        value={value}
        autoFocus
      />
      <span style={{ height: 10, width: 10 }} />
      <Form.Control
        onChange={(event) => setConfirmValue(event.target.value)}
        placeholder="Confirm email"
        value={confirmValue}
      />
    </Row>
  );
};
