import { useState, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { Validation } from "@mh/core";

import { useQContext } from "@mh/questionnaire/src/hooks";

import { QuestionnaireContentComponentProps } from ".";

export const QCDVA = (props: QuestionnaireContentComponentProps) => {
  const { setLocalResponseValidationError } = useQContext();
  const { initialDva } = props.initialValue ?? {};

  const [dva, setDVA] = useState<string>(initialDva ?? "");

  useEffect(() => {
    const validation = Validation.dva().validate(dva);

    if (dva === "") {
      setLocalResponseValidationError(null);
      props.onChange(undefined);
    } else if (validation !== null) {
      setLocalResponseValidationError(validation);
      props.onChange(undefined);
    } else {
      setLocalResponseValidationError(null);
      props.onChange(dva);
    }
  }, [dva]);

  return (
    <Row>
      <Form.Control
        onChange={(event) => setDVA(event.target.value)}
        placeholder="DVA Number"
        value={dva}
        autoFocus
      />
    </Row>
  );
};
