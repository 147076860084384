import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  BaseQuestionnaire,
  Consultation,
  DoctorStatus,
  checkEscriptRequired,
  getQuestionnaireDocuments,
  isPatientQuestionnaireRepeat,
  isRenewalExcludedTreatments,
  PatientAPI,
  PatientQuestionnaire,
  PatientSubscriptionScript,
  PharmacyStatus
} from "@mh/api";
import { generateEscript } from "@mh/api";
import { Button, DropdownButton, IconPhone, Toast } from "@mh/components";
import { ContactUsModal } from "@mh/messaging";

import { Status } from "./Status";
import { ManageTreatmentStatus } from "./ManageTreatmentStatus";
import { IHIModal } from "../IHIModal";

type SupportedStatus = Extract<DoctorStatus, "approve" | "decline"> &
  Extract<PharmacyStatus, "approve" | "decline">;

interface SupportedConsultationStatusQuestionnaire extends BaseQuestionnaire {
  consultation: Consultation & {
    status: Extract<DoctorStatus, "approve" | "decline">;
  };
  pharmacy_status: Extract<PharmacyStatus, "approve" | "decline">;
}

export interface ConsultationStatusProps {
  /** The initial questionnaire. */
  initialQuestionnaire: PatientQuestionnaire;
  /** Callback if the component triggers an update of the questionnaire data. */
  onChange?: () => void;
  /** The questionnaire to show the consultation status of (may be initial or repeat questionnaire). */
  questionnaire: BaseQuestionnaire;
  /** The current subscription script. */
  subscription?: PatientSubscriptionScript;
  /* it will be true if pt is nib member but no vaild ihi number */
  nibIhiRequired?: boolean;
  // nib general telehealth enable flag, it will be removed when nib general telehealth is launched.
  nibTeleHealth?: boolean;

  // auto refill value
  automaticallySendRepeats?: boolean;
}

interface WaitForRenewalProps {
  slug: string;
  id: number;
  subscriptionId: number;
  supplyExpire: any;
}

interface RequestTreatmentProps {
  slug: string;
  id: number;
  subscriptionId: number;
}

const ReadyForPurchaseStatus = ({
  initialQuestionnaire,
  questionnaire
}: ConsultationStatusProps) => {
  const isInitial = !isPatientQuestionnaireRepeat(questionnaire);

  return (
    <Status
      actions={
        <NavLink
          to={`/treatments/${initialQuestionnaire.id}/complete-purchase`}
        >
          <Button fullWidth>
            {isInitial ? "View treatment plan" : "Review treatment plan"}
          </Button>
        </NavLink>
      }
      title={isInitial ? "READY FOR PURCHASE" : "TREATMENT PLAN READY"}
      titleVariant="success"
    >
      <>
        {isInitial && (
          <p>
            {questionnaire.consultation?.clinician?.formal_name ||
              "Your clinician"}{" "}
            has reviewed your request and issued you a treatment plan. After you
            review and accept your treatment plan, you can place an order and we
            will deliver your medication directly to your door.
          </p>
        )}
        {!isInitial && (
          <p>
            {questionnaire.consultation?.clinician?.formal_name ||
              "Your clinician"}{" "}
            has issued your new treatment plan, which includes a change to your
            medication. After you review and accept this new treatment plan, you
            can place an order and we will deliver your medication directly to
            your door.
          </p>
        )}
      </>
    </Status>
  );
};

export const ConsultCompleteStatus = ({
  initialQuestionnaire,
  questionnaire,
  nibIhiRequired,
  nibTeleHealth
}: ConsultationStatusProps) => {
  const [showIHIModal, setShowIHIModal] = useState(false);
  const [showAccesseScript, setShowAccesseScript] = useState(true);
  let documents = getQuestionnaireDocuments(initialQuestionnaire);
  if (documents.length === 1 && documents[0].title === "Consultation Invoice") {
    // Don't show any documents if the only document is an invoice
    documents = [];
  }
  const navigate = useNavigate();
  const eScriptRequired = checkEscriptRequired(questionnaire?.script_set);

  return (
    <>
      <Status
        actions={
          <>
            {nibTeleHealth &&
              showAccesseScript &&
              (nibIhiRequired || (!nibIhiRequired && eScriptRequired)) && (
                <Button
                  fullWidth
                  variant="primary-outline"
                  onClick={async (e) => {
                    e.preventDefault();
                    if (nibIhiRequired) {
                      setShowIHIModal(true);
                    } else if (questionnaire?.consultation?.id) {
                      const success = await generateEscript(
                        questionnaire.consultation.id
                      );
                      if (success?.message?.includes("eScripts successfully")) {
                        Toast.success(
                          "You can now access your eScript by selecting the “View documents” button."
                        );
                        setShowAccesseScript(false);
                        setTimeout(() => {
                          navigate(0);
                        }, 5000);
                      } else {
                        Toast.success(
                          success?.message ??
                            "Not all of your scripts could be generated, contact patient support or try again later."
                        );
                      }
                    }
                  }}
                >
                  Access eScript
                </Button>
              )}
            {documents.length > 0 && (
              <DropdownButton
                variant="primary-outline"
                css={{
                  width: "100%"
                }}
                dropdownContent={
                  <>
                    {documents.map((item) => (
                      <DropdownButton.MenuItem key={item.title}>
                        <a
                          css={{
                            textDecoration: "none",
                            display: "block",
                            width: "100%"
                          }}
                          href={`${item.url}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {item.title}
                        </a>
                      </DropdownButton.MenuItem>
                    ))}
                  </>
                }
                dropdownTitle="Access your documents"
              >
                View documents
              </DropdownButton>
            )}
            <NavLink
              // For DHC, direct patients to telehealth consult, as only one DHC per 12 months can be completed
              reloadDocument
              to={`/questionnaire/?product=${
                initialQuestionnaire.category.slug === "digital-health-check"
                  ? "telehealth-consult"
                  : initialQuestionnaire.category.slug
              }${nibTeleHealth ? "&from=NibInternational&variant=worker" : ""}`}
            >
              <Button fullWidth>Request follow-up consult</Button>
            </NavLink>
          </>
        }
        title="CONSULT COMPLETED"
        titleVariant="success"
      >
        <p>
          {documents.length > 0
            ? "Your consultation is complete and your documents are now available. If you require a follow-up consultation, please use the button to get started."
            : "Your consultation is complete. If you require a follow-up consultation, please use the button to get started."}
        </p>
      </Status>
      {showIHIModal && (
        <IHIModal
          show={showIHIModal}
          setShow={setShowIHIModal}
          setShowAccesseScript={setShowAccesseScript}
          consultationId={questionnaire?.consultation?.id}
          refreshPage={true}
        />
      )}
    </>
  );
};

const TreatmentDeclinedStatus = ({
  questionnaire
}: ConsultationStatusProps) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return (
    <>
      <Status
        actions={
          <Button onClick={() => setShowContactUsModal(true)}>
            Contact patient success
          </Button>
        }
        title="CONSULT COMPLETED"
        titleVariant="success"
      >
        <div>
          <p>
            {questionnaire.consultation?.clinician?.formal_name ||
              "Your clinician"}{" "}
            has reviewed the information you provided in your request for
            treatment, but has declined to provide treatment in this instance.
            If you have any queries regarding this decision or would like to
            provide further information, please contact our Patient Success
            team.
          </p>
          {!!questionnaire.doctor_feedback && (
            <p>
              <span css={{ fontWeight: "600" }}>Feedback:</span>{" "}
              {questionnaire.doctor_feedback}
            </p>
          )}
        </div>
      </Status>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </>
  );
};

const DoctorApprove = ({
  initialQuestionnaire,
  questionnaire,
  nibIhiRequired,
  nibTeleHealth
}: ConsultationStatusProps) => {
  return initialQuestionnaire.category?.slug === "telehealth-consult" ||
    initialQuestionnaire.category?.slug === "pre-travel-consult" ||
    initialQuestionnaire.category?.slug === "digital-health-check" ? (
    <ConsultCompleteStatus
      initialQuestionnaire={initialQuestionnaire}
      questionnaire={questionnaire}
      nibIhiRequired={nibIhiRequired}
      nibTeleHealth={nibTeleHealth}
    />
  ) : (
    <ReadyForPurchaseStatus
      initialQuestionnaire={initialQuestionnaire}
      questionnaire={questionnaire}
      nibIhiRequired={nibIhiRequired}
      nibTeleHealth={nibTeleHealth}
    />
  );
};

const requestTreatmentChangeHandler = async ({
  slug,
  id,
  subscriptionId
}: RequestTreatmentProps) => {
  const success =
    await PatientAPI.createOrActivateRepeatQuestionnaire(subscriptionId);
  if (success) {
    const url = `/questionnairerepeats/${slug}/${id}/`;
    return url;
  }
  Toast.error("Failed to request treatment change");
  return null;
};

const WaitForRenewalStatus = ({
  slug,
  id,
  subscriptionId,
  supplyExpire
}: WaitForRenewalProps) => {
  const navigate = useNavigate();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  return (
    <>
      <Status
        actions={
          <Button
            onClick={async (e) => {
              e.preventDefault();
              const url = await requestTreatmentChangeHandler({
                slug,
                id,
                subscriptionId
              });
              if (url) {
                navigate(url);
              }
            }}
          >
            Renew treatment
          </Button>
        }
        title="ACTIVE"
        titleVariant="success"
      >
        <p>
          We will remind you to renew your treatment on{" "}
          {moment(supplyExpire).format("Do MMMM YYYY")}. If you need to renew
          sooner, use the button below to begin your follow-up consultation.
        </p>
      </Status>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </>
  );
};

const WMWaitForRenewalStatus = ({
  slug,
  id,
  subscriptionId,
  supplyExpire
}: WaitForRenewalProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Status
        actions={
          <Button
            onClick={async (e) => {
              e.preventDefault();
              const url = await requestTreatmentChangeHandler({
                slug,
                id,
                subscriptionId
              });
              if (url) {
                navigate(url);
              }
            }}
          >
            Renew treatment
          </Button>
        }
        title="ACTIVE"
        titleVariant="success"
      >
        <>
          We will remind you to renew your treatment on{" "}
          {moment(supplyExpire).format("Do MMMM YYYY")}. If you need to renew
          sooner, use the button below to begin your follow-up consultation. To
          discuss any treatment questions or concerns, schedule a consultation
          with our{" "}
          <a
            href="https://calendly.com/supportnurse/wmconsult"
            css={{
              cursor: "pointer",
              textDecoration: "none",
              fontWeight: "600",
              color: "#000"
            }}
            rel="noreferrer"
            target="_blank"
          >
            Nurse Support team
          </a>
          <span css={{ paddingLeft: "4px" }}>
            <IconPhone
              css={(theme) => ({
                width: "14px",
                height: "14px",
                color: theme.color.primary
              })}
            />
          </span>
        </>
      </Status>
    </>
  );
};

const ConsultationCompletedStatus = () => (
  <Status
    actions={
      <a href="/questionnaire?product=telehealth-consult">
        <Button>Book telehealth appointment</Button>
      </a>
    }
    title="CONSULTATION COMPLETED"
    titleVariant="success"
  >
    <p>
      Your consultation is complete. If you require a follow-up consultation,
      please use the button to book a telehealth appointment.
    </p>
  </Status>
);

const CONSULTATION_STATUS_MAP: Record<
  SupportedStatus,
  (props: ConsultationStatusProps) => JSX.Element
> = {
  decline: TreatmentDeclinedStatus,
  approve: DoctorApprove
};

/**
 * Questionnaire must have the supported status to be rendered correctly.
 * We also exclude repeat questionnaires when the status is "approve" because
 * in certain cases they will not render the "Ready For Purchase" status and skip
 * straight to the "Manage My Treatment" status. Once this logic is implemented here,
 * this check can be removed.
 */
const isSupportedQuestionnaire = (
  questionnaire: BaseQuestionnaire
): questionnaire is SupportedConsultationStatusQuestionnaire =>
  !!questionnaire &&
  ((!!questionnaire.consultation?.status &&
    Object.keys(CONSULTATION_STATUS_MAP).includes(
      questionnaire.consultation.status
    )) ||
    (!!questionnaire.pharmacy_status &&
      Object.keys(CONSULTATION_STATUS_MAP).includes(
        questionnaire.pharmacy_status
      )));

export const ConsultationStatus = (props: ConsultationStatusProps) => {
  if (!isSupportedQuestionnaire(props.questionnaire)) {
    return null;
  }
  const status =
    props.questionnaire.consultation?.status ||
    props.questionnaire.pharmacy_status;

  if (status === "approve" && props.subscription?.patient_accepted) {
    const scriptRepeats = props.subscription.script_repeats ?? 0;
    const scriptRepeatsUsed = props.subscription.script_repeats_used ?? 0;
    const repeatsRemaining = scriptRepeats - scriptRepeatsUsed;
    if (
      repeatsRemaining ||
      isRenewalExcludedTreatments(props.initialQuestionnaire.category.slug)
    ) {
      return (
        <ManageTreatmentStatus
          {...props}
          subscription={props.subscription}
          automaticallySendRepeats={props.automaticallySendRepeats ?? false}
        />
      );
    } else if (
      props.initialQuestionnaire.category.slug !== "telehealth-consult" &&
      props.initialQuestionnaire.category.slug !== "digital-health-check"
    ) {
      const supplyExpire = moment(props.subscription.supply_expire).isValid()
        ? moment(props.subscription.supply_expire)
            .subtract(14, "days")
            .format("YYYY-MM-DD")
        : moment().add(14, "days").format("YYYY-MM-DD");

      return props.initialQuestionnaire.category?.slug !==
        "weight_management" ? (
        <WaitForRenewalStatus
          slug={props.initialQuestionnaire.category.slug}
          id={props.initialQuestionnaire.id}
          subscriptionId={props.subscription.id}
          supplyExpire={supplyExpire?.toString()}
        />
      ) : (
        <WMWaitForRenewalStatus
          slug={props.initialQuestionnaire.category.slug}
          id={props.initialQuestionnaire.id}
          subscriptionId={props.subscription.id}
          supplyExpire={supplyExpire?.toString()}
        />
      );
    }
    return <ConsultationCompletedStatus />;
  }

  return CONSULTATION_STATUS_MAP[status](props);
};

ConsultationStatus.isSupportedQuestionnaire = isSupportedQuestionnaire;
