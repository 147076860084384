import { Interpolation, Theme } from "@emotion/react";

interface PaymentTypeOptionProps {
  id: string;
  value: string;
  title: string;
  subtitle?: string;
  feeDescription: string;
  fee: string;
  optionCss?: Interpolation<Theme>;
  onChange: (value: string) => void;
  icon?: React.ReactNode;
  expanded?: boolean;
  inputType: "radio" | "checkbox";
  children?: React.ReactNode;
}

export const PaymentTypeOption = ({
  id,
  value,
  title,
  subtitle,
  feeDescription,
  fee,
  optionCss,
  onChange,
  icon,
  expanded,
  inputType,
  children
}: PaymentTypeOptionProps) => {
  const replaceHubpassIcon = (title: string) =>
    title.includes("hubPass") ? (
      <span>
        {title.replace("hubPass", "")}
        <img
          src="/assets/images/com/hubpass.svg"
          alt="hubPass"
          css={{ paddingLeft: "1px", marginTop: "-4px" }}
        />
      </span>
    ) : (
      title
    );

  return (
    <div
      onClick={() => onChange(id)}
      css={[
        (theme) => ({
          display: "flex",
          width: "97%",
          gap: "10px",
          alignItems: "start",
          padding: "16px",
          backgroundColor: "#F8F8F8",
          margin: "5px",
          borderRadius: "10px",
          marginBottom: "24px",
          position: "relative",
          cursor: "pointer",
          [theme.mq.md]: {
            width: "100%"
          }
        }),
        optionCss
      ]}
    >
      <div>
        <input
          type={inputType ?? "radio"}
          name={title}
          value={id}
          checked={value === id}
          css={(theme) => ({
            width: "1.2em",
            height: "1.2em",
            marginTop: "3px",
            accentColor: theme.color.primary,
            backgroundColor: theme.color.primary
          })}
          onChange={() => {}}
        />
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          fontSize: "14px",
          lineHeight: "25px"
        }}
      >
        <span
          css={{
            fontSize: "16px",
            fontWeight: "600"
          }}
        >
          {replaceHubpassIcon(title)}
        </span>
        <div css={{ width: "85%" }}>{subtitle}</div>
        {!expanded && (
          <div
            css={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <span css={{ fontWeight: "500" }}>{feeDescription}</span>
            <span css={{ fontWeight: "500" }}>${fee}</span>
          </div>
        )}
        <div>{children}</div>
      </div>
      <div css={{ position: "absolute", right: "16px" }}>{icon}</div>
    </div>
  );
};
