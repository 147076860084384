import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import {
  Feature,
  Patient,
  PatientAPI,
  PatientContext,
  UserContext,
  UserDetail
} from "@mh/api";
import { DigitalID, PhoneField, Toast } from "@mh/components";
import { DIGITAL_ID, Validation } from "@mh/core";

import {
  ProfileCheckbox,
  ProfileField,
  ProfileForm,
  ProfileSelect
} from "./ProfileForm";

import { PersonalDetails } from "./QuestionnaireRepeat";

import { ProfileCard } from "./ProfileCard";
import { QuestionnaireDatePicker } from "../../components/questionnaire/QuestionnaireDatePicker";

const SEX_OPTIONS: [string, string][] = [
  ["", ""],
  ["male", "Male"],
  ["female", "Female"]
];

const ATSI_OPTIONS: [string, string][] = [
  ["", ""],
  ["neither", "Neither Aboriginal or Torres Strait Islander"],
  ["aboriginal", "Aboriginal"],
  ["tsi", "Torres Strait Islander"]
];

interface DigitalIDFormControlProps {
  /** Handler called on successful verification response. */
  onSuccess: () => {};
  /** The user's username, sent to backend for verification. */
  username: string;
  /** Whether the user has been successfully verified or not. */
  verified: boolean;
}

const DigitalIDFormControl = ({
  onSuccess,
  username,
  verified
}: DigitalIDFormControlProps) => {
  const [digitalIdError, setDigitalIdError] = useState<string>();
  const [verifyIsFetching, setVerifyIsFetching] = useState<boolean>(false);

  const verify = async (code?: string) => {
    if (!code) {
      return;
    }

    setVerifyIsFetching(true);
    const result = await PatientAPI.verifyDigitalId({
      username,
      grantCode: code
    }).catch(() => false);
    if (result) {
      onSuccess();
      setDigitalIdError("");
    } else {
      setDigitalIdError("Verification failed, please try again.");
    }
    setVerifyIsFetching(false);
  };

  return (
    <>
      {verified ? (
        <ProfileForm.Control
          label="Digital iD™ Verification Status"
          readOnly
          value="Verified"
        />
      ) : (
        <>
          {verifyIsFetching ? (
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px"
              }}
            >
              <Spinner size="sm" variant="secondary" />
              <span>Verification in progress...</span>
            </div>
          ) : (
            <DigitalID
              clientId={DIGITAL_ID.clientId}
              error={digitalIdError}
              onComplete={verify}
              sdk={DIGITAL_ID.sdk}
            />
          )}
        </>
      )}
    </>
  );
};

type UserInfoValues = Pick<
  UserDetail,
  "email" | "first_name" | "middle_name" | "last_name" | "phone"
> &
  Pick<
    Patient,
    "dob" | "gender" | "genderdetail" | "atsi" | "ethnicity" | "aslr"
  >;

export interface UserInfoProps {
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
  /* Hide Digital ID form, used in user details form in the questionnaires */
  hideDigitalID?: boolean;
  /* Show custom title */
  title?: string;
  /* value is true if it is from questionnaire */
  isQuestionnaire?: boolean | undefined;
}

export const UserInfo = ({
  isEditingCallback,
  hideDigitalID,
  title,
  isQuestionnaire
}: UserInfoProps) => {
  const {
    isFetching: userIsFetching,
    data: user,
    error: userError,
    update: updateUser,
    get: getUser
  } = useContext(UserContext);
  const {
    isFetching: patientIsFetching,
    data: patient,
    update: updatePatient,
    get: getPatient
  } = useContext(PatientContext);

  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [isFetchingVerificationStatus, setIsFetchingVerificationStatus] =
    useState<boolean>(false);
  const [aslrFlag, setAslrFlag] = useState<boolean>(false);

  useEffect(() => {
    const getVerification = async () => {
      setIsFetchingVerificationStatus(true);
      const verification = await PatientAPI.checkVerification();
      if (verification) {
        setIsUserVerified(verification.verified);
      }
      setIsFetchingVerificationStatus(false);
    };
    getVerification();
    const getAslrFlag = async () => {
      setAslrFlag(await Feature.isActive("aslr"));
    };
    getAslrFlag();
  }, []);

  useEffect(() => {
    if (userError) {
      // Display IHI errors regarding name change on user object
      Toast.error(JSON.stringify(userError).replace(/"/g, ""));
    }
  }, [userError]);

  return (
    <ProfileForm<UserInfoValues>
      title={title ?? "User Info"}
      id="userinfo"
      initialValues={{
        email: user?.email || "",
        first_name: user?.first_name || "",
        middle_name: user?.middle_name || "",
        last_name: user?.last_name || "",
        phone: user?.phone || "",
        dob: patient?.dob || "",
        gender: patient?.gender || "",
        genderdetail: patient?.genderdetail || "",
        atsi: patient?.atsi || "",
        ethnicity: patient?.ethnicity || "",
        aslr: patient?.aslr || false
      }}
      isFetching={
        userIsFetching || patientIsFetching || isFetchingVerificationStatus
      }
      onSave={async (values) =>
        await Promise.all([
          updateUser({
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            phone: values.phone
          }),
          updatePatient({
            dob: values.dob,
            gender: values.gender,
            genderdetail: values.genderdetail,
            atsi: values.atsi,
            ethnicity: values.ethnicity,
            aslr: values.aslr
          })
        ])
      }
      validate={(values) => ({
        first_name: Validation.name({ name: "First name" }).validate(
          values.first_name
        ),
        middle_name: null,
        last_name: Validation.name({ name: "Last name" }).validate(
          values.last_name
        ),
        phone: Validation.mobile().validate(values.phone),
        dob: Validation.required()
          .custom((s) =>
            moment().subtract(18, "years") < moment(s)
              ? "You must be 18 or over."
              : null
          )
          .validate(values.dob),
        gender: null,
        genderdetail: null,
        atsi: null,
        ethnicity: null
      })}
      isEditingCallback={isEditingCallback}
      isQuestionnaire={isQuestionnaire}
    >
      {({ values, setValues, error, isEditing, id }) =>
        !isQuestionnaire ? (
          <>
            <ProfileCard.Group>
              <ProfileForm.Control
                label="Email"
                help={isEditing ? "Contact us to update your email." : null}
                readOnly
                value={values.email}
                data-testid={`${id}-email`}
              />
            </ProfileCard.Group>
            <ProfileCard.HorizontalDivider />
            <ProfileCard.Group>
              <ProfileForm.Control
                required
                label="First name"
                data-testid={`${id}-firstname`}
                disabled={
                  userIsFetching ||
                  patientIsFetching ||
                  isFetchingVerificationStatus
                }
                error={error?.first_name}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, first_name: e.currentTarget.value })
                }
                value={values.first_name}
              />
              <ProfileForm.Control
                label="Middle name(s)"
                data-testid={`${id}-middlename`}
                disabled={
                  userIsFetching ||
                  patientIsFetching ||
                  isFetchingVerificationStatus
                }
                error={error?.middle_name}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, middle_name: e.currentTarget.value })
                }
                value={values.middle_name}
              />
              <ProfileForm.Control
                required
                label="Last name"
                data-testid={`${id}-lastname`}
                disabled={
                  userIsFetching ||
                  patientIsFetching ||
                  isFetchingVerificationStatus
                }
                error={error?.last_name}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, last_name: e.currentTarget.value })
                }
                value={values.last_name}
              />
            </ProfileCard.Group>
            {isEditing && isUserVerified && (
              <div
                className="text-muted form-text"
                css={{ marginTop: -10, marginBottom: 16 }}
              >
                Contact us to update your name.
              </div>
            )}
            <ProfileCard.HorizontalDivider />
            <ProfileCard.Group>
              <ProfileField
                label={"Mobile Number"}
                error={error?.phone}
                required
              >
                <PhoneField
                  readOnly={!isEditing}
                  inputTestId={`${id}-phone`}
                  onChange={(value) =>
                    setValues({ ...values, phone: value ?? "" })
                  }
                  value={values.phone}
                  className={
                    error?.phone !== undefined ? "is-invalid" : undefined
                  }
                />
              </ProfileField>
              <ProfileField
                label={"Date of Birth"}
                error={error?.dob}
                required
                help={
                  isEditing && isUserVerified
                    ? "Contact us to update your date of birth."
                    : null
                }
              >
                {isEditing ? (
                  <QuestionnaireDatePicker
                    info={{
                      dob_year: values.dob?.split("-")[0],
                      dob_month: values.dob?.split("-")[1],
                      dob_day: values.dob?.split("-")[2],
                      onChange: (dob) => setValues({ ...values, dob })
                    }}
                    currentQuestion={{ type: "dob" }}
                    disabled={
                      userIsFetching ||
                      patientIsFetching ||
                      isFetchingVerificationStatus ||
                      isUserVerified
                    }
                    className="form-control"
                  />
                ) : (
                  <span>{values.dob.split("-").reverse().join("/")}</span>
                )}
              </ProfileField>
            </ProfileCard.Group>
            <ProfileCard.HorizontalDivider />
            <ProfileCard.Group>
              <ProfileSelect
                label="Sex"
                data-testid={`${id}-sex`}
                disabled={
                  userIsFetching ||
                  patientIsFetching ||
                  isFetchingVerificationStatus ||
                  isUserVerified
                }
                help={
                  isEditing && isUserVerified
                    ? "Contact us to update your sex."
                    : null
                }
                error={error?.gender}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, gender: e.currentTarget.value })
                }
                value={values.gender}
              >
                {SEX_OPTIONS.map(([value, display], index) => (
                  <option key={`${index}_${value}`} value={value}>
                    {display}
                  </option>
                ))}
              </ProfileSelect>
              <ProfileForm.Control
                label="Gender"
                disabled={userIsFetching || patientIsFetching}
                error={error?.genderdetail}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, genderdetail: e.currentTarget.value })
                }
                value={values.genderdetail}
              />
            </ProfileCard.Group>
            <ProfileCard.Group>
              <ProfileSelect
                label="Aboriginal or Torres Strait Islander"
                data-testid={`${id}-aboriginal`}
                disabled={userIsFetching || patientIsFetching}
                error={error?.atsi}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, atsi: e.currentTarget.value })
                }
                value={values.atsi}
              >
                {ATSI_OPTIONS.map(([value, display], index) => (
                  <option key={`${index}_${value}`} value={value}>
                    {display}
                  </option>
                ))}
              </ProfileSelect>
              <ProfileForm.Control
                label="Ethnicity"
                data-testid={`${id}-ethnicity`}
                disabled={userIsFetching || patientIsFetching}
                error={error?.ethnicity}
                isEditing={isEditing}
                onChange={(e) =>
                  setValues({ ...values, ethnicity: e.currentTarget.value })
                }
                value={values.ethnicity}
              />
            </ProfileCard.Group>
            {user?.username && !isEditing && !hideDigitalID && (
              <>
                <ProfileCard.HorizontalDivider />
                <ProfileCard.Group>
                  <DigitalIDFormControl
                    onSuccess={async () =>
                      await Promise.all([[getPatient(), getUser()]])
                    }
                    username={user.username}
                    verified={!!patient?.digital_id_transaction}
                  />
                </ProfileCard.Group>
              </>
            )}
            {aslrFlag && (
              <>
                <ProfileCard.HorizontalDivider />
                <ProfileCard.Group>
                  <ProfileForm.Control
                    label="Ethnicity"
                    data-testid={`${id}-ethnicity`}
                    disabled={userIsFetching || patientIsFetching}
                    error={error?.ethnicity}
                    isEditing={isEditing}
                    onChange={(e) =>
                      setValues({ ...values, ethnicity: e.currentTarget.value })
                    }
                    value={values.ethnicity}
                  />
                  <ProfileCheckbox
                    label="Share your information with the Active Script List Registry (ASLR)"
                    value={values?.aslr}
                    error={error?.aslr}
                    type="checkbox"
                    isEditing={isEditing}
                    data-testid={`${id}-aslr`}
                    onChange={(e) =>
                      // @ts-ignore
                      setValues({ ...values, aslr: e.target.checked })
                    }
                  />
                </ProfileCard.Group>
              </>
            )}
          </>
        ) : (
          <>
            <PersonalDetails
              values={values}
              setValues={setValues}
              error={error}
              isEditing={isEditing}
              id={id}
              userIsFetching={userIsFetching}
              patientIsFetching={patientIsFetching}
              isFetchingVerificationStatus={isFetchingVerificationStatus}
              isUserVerified={isUserVerified}
            />
          </>
        )
      }
    </ProfileForm>
  );
};
