import { BRAND } from "@mh/core";

export const TERMS_OF_USE_URL = {
  hubhealth: "https://hub.health/terms-and-conditions/",
  stagger: "https://stagger.com.au/terms-and-conditions",
  youly: "https://youly.com.au/terms-and-conditions"
}[BRAND];

export const PRIVACY_URL = {
  hubhealth: "https://hub.health/privacy-policy/",
  stagger: "https://stagger.com.au/privacy-policy",
  youly: "https://youly.com.au/privacy-policy"
}[BRAND];
