import { IconTruckFast } from "../icons";
import { BrandBanner } from "./BrandBanner";

export const FreeDeliveryBanner = () => (
  <BrandBanner>
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: "8px",
        fontSize: "20px"
      }}
    >
      <IconTruckFast
        css={(theme) => ({
          margin: "8px 0px",
          width: "40px",
          [theme.mq.md]: {
            width: "24px"
          }
        })}
      />
      <span css={{ flex: 1 }}>
        FREE shipping when you add a recommended product to your order
      </span>
    </div>
  </BrandBanner>
);
