import React, { useEffect, useRef, useState } from "react";
import { API } from "@mh/api";
import { User } from "@mh/api";
import { SafeURLSearchParams, Sentry, Validation } from "@mh/core";
import { nibInternationalSearchParam } from "@mh/core/src/constants";
import { Spinner, Toast } from "@mh/components";
import { QuestionnaireDatePicker } from "./QuestionnaireDatePicker";
import {
  Button,
  Flex,
  IconCheckCircle,
  IconTriangleExclamation,
  Modal
} from "@mh/components";
import Com from "../common/common";

const Common = new Com();

type LayoutPosition = "left" | "right" | "full";

interface Layout {
  bg: LayoutPosition;
  extra?: LayoutPosition;
  logo: LayoutPosition;
  content: LayoutPosition;
  input: LayoutPosition;
}

interface ExtraContent {
  img: string;
  text: string;
}

interface Step {
  img: string;
  text: string;
}

interface Content {
  desc: string;
  header: string;
  steps: Step[];
}

interface SplashDataType {
  extra?: ExtraContent[];
  content: Content;
  layout: Layout;
}

interface SplashObject {
  [key: string]: SplashDataType;
}

interface ContentBoxs {
  [key: string]: JSX.Element[];
}

interface ProductEnv {
  code: string;
}

interface SplashDetail {
  bg: string;
  mobileBg: string;
  logo: string;
}

export enum NibEligibilityStatus {
  ELIGIBLE = "eligible",
  SERVICE_NOT_COVERED = "service_not_covered",
  DETAILS_NOT_FOUND = "details_not_found",
  INVALID_DETAILS = "invalid_details",
  SERVICE_DOWN = "service_down",
  CLAIMS_API_ERROR = "claims_api_error",
  USER_ALREADY_EXISTS = "user_already_exists",
  GENERIC_ERROR = "generic_error"
}

interface NibInternationalProps {
  code: string;
  firstName: string | undefined;
  lastName: string | undefined;
  dob_year: string | undefined;
  dob_month: string | undefined;
  dob_day: string | undefined;
  membership: string | undefined;
  setMembership: (membership: string) => void;
  email: string | undefined;
  detail: SplashDetail;
  footer: React.ReactNode;
  children: React.ReactNode | undefined;
  continueToQuestionnaire: (e: React.MouseEvent<HTMLElement>) => void;
  setAllDetails?: (data: any) => void;
  onVoucherApplied: (code: string) => void;
  setIsNibPatient: (member: boolean) => void;
  setFromParam: (fromtParam: string) => void;
  setIsValidParam: (validParam: string) => void;
  setResetProductEnv: (isReset: boolean) => void;
  hideBack: (hide: boolean) => void | undefined;
}

const isWorkerVariant = (): boolean => {
  const searchParams = new SafeURLSearchParams(window.location.search);
  const variant: string | null = searchParams.get("variant");
  return variant === "worker";
};

const SplashData: SplashObject = {
  contraceptive_pill: {
    layout: {
      bg: "full",
      logo: "full",
      extra: "full",
      content: "left",
      input: "right"
    },
    extra: [
      {
        img: "/assets/images/hubhealth/consultsubmit_nib-black.svg",
        text: "Medical consultation - no out-of-pocket cost for nib OSHC members"
      },
      {
        img: "/assets/images/hubhealth/deliverysubmit_nib-blck.svg",
        text: "Delivery – no additional fee for nib OSHC members"
      },
      {
        img: "/assets/images/hubhealth/nib-logo.svg",
        text: "working in partnership with"
      }
    ],
    content: {
      desc: "You’re one step closer to taking care of your health. But first, we need to learn more about you. Don’t worry – we’ll keep it short (about 5 mins), and your answers are secure and confidential between you and your doctor.",
      header: "4 easy steps to get your contraceptive pill",
      steps: [
        {
          img: "/assets/images/hubhealth/membershipnib.png",
          text: "Enter your nib membership number"
        },
        {
          img: "/assets/images/hubhealth/tellusnib.png",
          text: "Answer a few questions about your health"
        },
        {
          img: "/assets/images/hubhealth/consultnib.png",
          text: "Our medical team review your health profile"
        },
        {
          img: "/assets/images/hubhealth/deliverordernib.png",
          text: "Delivered fast and free in discreet packaging"
        }
      ]
    }
  },
  medicalcertificates: {
    layout: {
      bg: "full",
      logo: "full",
      extra: "full",
      content: "left",
      input: "right"
    },
    extra: [
      {
        img: "/assets/images/hubhealth/medcert-black.svg",
        text: `Fast and convenient medical certificate - ${
          isWorkerVariant()
            ? "exclusive offer for nib members"
            : "no out-of-pocket cost for nib OSHC members"
        }`
      },
      {
        img: "/assets/images/hubhealth/nib-logo.svg",
        text: "working in partnership with"
      }
    ],
    content: {
      desc: "You’re one step closer to taking care of your health. But first, we need to learn more about you. Don’t worry – we’ll keep it short (about 5 mins), and your answers are secure and confidential between you and your doctor.",
      header: "4 easy steps to get your medical certificate",
      steps: [
        {
          img: "/assets/images/hubhealth/newmembershipnib.png",
          text: "Enter your details, including your nib membership number"
        },
        {
          img: "/assets/images/hubhealth/newtellusnib.png",
          text: "Answer a few questions about your health."
        },
        {
          img: "/assets/images/hubhealth/newconsultnib.png",
          text: "Our clinicians review your health profile"
        },
        {
          img: "/assets/images/hubhealth/send.png",
          text: "We’ll then send your medical certificate direct to your email"
        }
      ]
    }
  },
  telehealth_consult: {
    layout: {
      bg: "full",
      logo: "full",
      extra: "full",
      content: "left",
      input: "right"
    },
    extra: [
      {
        img: "/assets/images/hubhealth/medcert-black.svg",
        text: `Get convenient access to telehealth appointments - ${
          isWorkerVariant()
            ? "exclusive offer for nib members"
            : "no out-of-pocket cost for nib OSHC members"
        }`
      },
      {
        img: "/assets/images/hubhealth/nib-logo.svg",
        text: "working in partnership with"
      }
    ],
    content: {
      desc: "To proceed with your telehealth appointment, we will need to ask a few questions on how we can help you today. Don't worry, it will only take a few minutes, and your answers remain secure and confidential between you and your clinician.",
      header: "3 easy steps to consult with a doctor online",
      steps: [
        {
          img: "/assets/images/hubhealth/newmembershipnib.svg",
          text: "Enter your details, including your nib membership number"
        },
        {
          img: "/assets/images/hubhealth/newtellusnib.svg",
          text: "Book your telehealth appointment"
        },
        {
          img: "/assets/images/hubhealth/newconsultnib.svg",
          text: "Consult with an Australian registered GP"
        }
      ]
    }
  }
};

interface CheckMembershipResult {
  status: NibEligibilityStatus;
  voucher: string | null;
  message: string | null;
}

export const checkMembership = async (
  memberShip: string,
  dob: string,
  firstName: string,
  lastName: string,
  productCode: string
): Promise<CheckMembershipResult> => {
  let result: CheckMembershipResult = {
    status: NibEligibilityStatus.GENERIC_ERROR,
    voucher: null,
    message: null
  };

  const categorySlug = {
    medicalcertificates: "medicalcertificates",
    contraceptive_pill: "contraceptive-pill",
    telehealth_consult: "telehealth-consult"
  }[productCode];

  if (!categorySlug) {
    Sentry.captureMessage(
      `NIB checkMembership error invalid productCode: ${productCode}`
    );
    return {
      status: NibEligibilityStatus.GENERIC_ERROR,
      voucher: null,
      message: null
    };
  }

  const params = {
    membership_number: memberShip,
    date_of_birth: dob,
    first_name: firstName,
    last_name: lastName,
    category_slug: categorySlug
  };

  const url = `/check-nib-eligible/?${new URLSearchParams(params)}`;

  try {
    const checkApi = API.v2().authenticateIfProvided().url(url);

    result = await checkApi.get().then(async (resp: Response) => {
      const res = await resp.json();
      // if we can't determine the eligibility status, default to generic error
      let eligibilityStatus = NibEligibilityStatus.GENERIC_ERROR;
      if (resp.ok) {
        Toast.success("Eligibility confirmed");
        eligibilityStatus = NibEligibilityStatus.ELIGIBLE;
      } else {
        const eligibilityStatusFromBackend = res?.eligibility_status;
        if (
          Object.values(NibEligibilityStatus).includes(
            eligibilityStatusFromBackend
          )
        ) {
          eligibilityStatus =
            eligibilityStatusFromBackend as NibEligibilityStatus;
        }
      }
      return {
        status: eligibilityStatus,
        voucher: res?.coupon ?? null,
        message: res?.message ?? null
      };
    });
  } catch (e: any) {
    Sentry.captureMessage(
      `checkMembership Error User Name: ${firstName} ${lastName} | Error Message: ${e.message}`
    );
    return {
      status: NibEligibilityStatus.GENERIC_ERROR,
      voucher: null,
      message: null
    };
  }

  return result;
};

const getBgBlock = (props: NibInternationalProps) => {
  return (
    <div className={`bgBlock ${props?.code}-bg-outer`}>
      <img className="w-100 pcView" src={props.detail.bg} />
      <img className="w-100 mobileView" src={props.detail.mobileBg} />
    </div>
  );
};

const createExtra = (extra: Step[]): JSX.Element[] => {
  const extraContents: JSX.Element[] = [];
  Object.entries(extra).forEach(([key, value], index) => {
    const isLast = index === Object.keys(extra).length - 1;
    extraContents.push(
      <div
        key={`extra-${key}-${index}`}
        className="mb-3 d-flex justify-content-center"
        css={(theme) => ({
          position: "relative",
          display: "flex",
          width: "100%",
          flexDirection: isLast ? "row-reverse" : "row",
          marginBottom: "0px !important",
          paddingBottom: "12px",
          paddingTop: index > 0 ? "12px" : "",
          "&:after": !isLast
            ? {
                position: "absolute",
                content: "''",
                bottom: "0",
                borderBottom: "1px solid white",
                width: "18%"
              }
            : {
                content: "''",
                position: "absolute",
                top: "0",
                left: "-25%",
                bottom: "-14px",
                width: "150%",
                backgroundColor: theme.color.third,
                borderBottomLeftRadius: "100%",
                borderBottomRightRadius: "100%",
                zIndex: "1",
                [theme.mq.md]: {
                  zIndex: "-1"
                }
              },
          [theme.mq.md]: {
            marginTop: isLast ? "auto" : "unset",
            maxWidth: isLast ? "326px" : "360px",
            paddingBottom: "0px",
            paddingRight: isLast ? "0px" : "50px",
            paddingLeft: isLast ? "50px" : "",
            "&:after": !isLast
              ? {
                  right: "0",
                  transform: "translateY(-50%)",
                  top: "50%",
                  borderRight: "1px solid white",
                  width: "1px",
                  height: "70%"
                }
              : ""
          }
        })}
      >
        <div
          css={{
            marginTop: isLast ? "auto" : "",
            marginBottom: isLast ? "auto" : "",
            zIndex: "2"
          }}
        >
          <img
            css={(theme) => ({
              paddingRight: isLast ? "" : "8px",
              [theme.mq.sm]: {
                paddingLeft: !isLast && index > 0 ? "50px" : ""
              }
            })}
            src={value.img}
          />
        </div>
        <div
          css={{
            marginTop: "auto",
            marginBottom: "auto",
            fontWeight: isLast ? "500" : "400",
            paddingRight: isLast ? "8px" : "0px",
            fontSize: "16px",
            color: "black",
            maxWidth: isLast ? "172px" : "260px",
            zIndex: "2",
            lineHeight: "140%",
            letterSpacing: "-0.08px"
          }}
        >
          {value.text}
        </div>
      </div>
    );
  });
  return extraContents;
};

const getExtraBlock = (extraData: ExtraContent[] | undefined) => {
  if (!extraData) return false;
  const extra: JSX.Element[] = createExtra(extraData);

  return (
    <div
      className="d-md-flex justify-content-md-center"
      css={(theme) => ({
        backgroundColor: theme.color.third,
        paddingTop: "55px",
        [theme.mq.md]: {
          paddingTop: "70px",
          paddingBottom: "24px"
        }
      })}
    >
      {" "}
      {extra}
    </div>
  );
};

const getLogoBlock = (props: NibInternationalProps) => {
  return (
    <div className={`logoBlock logoBlock-${props?.code}`}>
      <img
        css={(theme) => ({
          [theme.mq.md]: {
            maxWidth: "514px"
          }
        })}
        src={props.detail.logo}
      />
    </div>
  );
};

const createSteps = (steps: Step[]): JSX.Element[] => {
  const stpesContents: JSX.Element[] = [];
  Object.entries(steps).forEach(([key, value], index) => {
    stpesContents.push(
      <div
        key={`step-${key}-${index}`}
        css={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "16px !important"
        }}
      >
        <div>
          <img
            css={{
              width: "auto !important",
              maxWidth: "85px !important",
              paddingRight: "15px"
            }}
            src={value.img}
          />
        </div>
        <div
          css={{
            marginTop: "auto",
            marginBottom: "auto",
            fontWeight: "700",
            fontFamily: "Inter"
          }}
        >
          {value.text}
        </div>
      </div>
    );
  });
  return stpesContents;
};

const getContentBlock = (contentData: Content) => {
  if (!contentData) return false;
  const Steps: JSX.Element[] = createSteps(contentData?.steps);

  return (
    <div
      css={(theme) => ({
        [theme.mq.lg]: {
          maxWidth: "546px",
          marginLeft: "auto",
          marginRight: "20px"
        }
      })}
    >
      <h2
        css={{
          paddingTop: "50px",
          fontSize: "18px !important"
        }}
      >
        {contentData.desc}
      </h2>
      <h3
        className=" strong"
        css={{
          paddingTop: "24px",
          paddingBottom: "16px",
          fontSize: "20px !important"
        }}
      >
        {contentData.header}
      </h3>
      <div className="row">{Steps}</div>
    </div>
  );
};

interface MembershipConfirmationModalProps {
  code: string;
  setShow: (show: boolean) => void;
  show: boolean;
  nibEligibilityStatus: NibEligibilityStatus | null;
  messageFromBackend: string | null;
  voucher: string | null;
  eligibilityFailCount: number;
  confirmMembership: (e: React.MouseEvent<HTMLElement>) => void;
  confirmDiscount: (e: React.MouseEvent<HTMLElement>) => void;
  onVoucherApplied: (code: string) => void;
  setIsNibPatient: (member: boolean) => void;
  setFromParam: (fromtParam: string) => void;
  setIsValidParam: (validParam: string) => void;
  setResetProductEnv: (isReset: boolean) => void;
  hideBack: (hide: boolean) => void | undefined;
}

const MembershipConfirmationModal = ({
  code,
  setShow,
  show,
  nibEligibilityStatus,
  messageFromBackend,
  voucher,
  eligibilityFailCount,
  confirmMembership,
  confirmDiscount,
  onVoucherApplied,
  setIsNibPatient,
  setFromParam,
  setIsValidParam,
  setResetProductEnv,
  hideBack
}: MembershipConfirmationModalProps): JSX.Element => {
  let message: JSX.Element;
  let variant: "success" | "warning" | "danger" | null;
  const serviceName =
    code === "medicalcertificates"
      ? "medical certificate"
      : code === "telehealth_consult"
      ? "telehealth consultation"
      : "consultation";
  let buttons: JSX.Element[];

  if (nibEligibilityStatus === NibEligibilityStatus.ELIGIBLE) {
    variant = "success";
    message = (
      <span>
        Great news! Your nib membership covers the cost of your {serviceName}.
      </span>
    );
    buttons = [
      <Button
        key={0}
        fullWidth
        onClick={async (e) => {
          e.preventDefault();
          setIsNibPatient(true);
          confirmMembership(e);
        }}
        variant="primary"
        data-testid="continue-button"
      >
        Continue
      </Button>
    ];
  } else if (
    nibEligibilityStatus === NibEligibilityStatus.SERVICE_NOT_COVERED &&
    (code === "medicalcertificates" || code === "telehealth_consult") &&
    voucher &&
    messageFromBackend
  ) {
    variant = "success";
    message = <span>{messageFromBackend}</span>;
    buttons = [
      <Button
        key={0}
        onClick={() => setShow(false)}
        variant="primary-outline"
        css={{ flex: 1 }}
      >
        Close
      </Button>,
      <Button
        key={1}
        css={{ flex: 1 }}
        fullWidth
        onClick={async (e) => {
          e.preventDefault();
          setIsNibPatient(true);
          setFromParam("");
          setIsValidParam("");
          onVoucherApplied(voucher);
          setResetProductEnv(true);
          hideBack && hideBack(true);
          confirmDiscount(e);
        }}
        variant="primary"
        data-testid="continue-button"
      >
        Continue
      </Button>
    ];
  } else if (
    nibEligibilityStatus === NibEligibilityStatus.USER_ALREADY_EXISTS
  ) {
    variant = "warning";
    message = (
      <span>
        It seems the email address you&apos;ve entered is already associated
        with an existing account. Please log in to continue with your visit.
      </span>
    );
    const urlParams: { [key: string]: string } = {
      product: code,
      from: nibInternationalSearchParam
    };
    if (isWorkerVariant()) {
      urlParams.variant = "worker";
    }
    const redirect = encodeURIComponent(
      `/questionnaire/?${new URLSearchParams(urlParams)}`
    );
    buttons = [
      <a key={1} href={`/?redirect=${redirect}`} css={{ width: "100%" }}>
        <Button fullWidth variant="primary">
          Continue
        </Button>
      </a>
    ];
  } else if (eligibilityFailCount > 3) {
    variant = "danger";
    const extraText =
      code === "medicalcertificates" ? "purchasing a medical certificate " : "";
    message = (
      <span>
        Unfortunately, we cannot find your nib membership. If you would like to
        continue {extraText}privately, please click &apos;Next&apos; to proceed
        or contact nib on 13 14 63 to confirm your membership details.
      </span>
    );
    buttons = [
      <Button
        key={0}
        onClick={() => setShow(false)}
        variant="primary-outline"
        css={{ flex: 1 }}
      >
        Close
      </Button>,
      <a key={1} href={`/questionnaire/?product=${code}`} css={{ flex: 1 }}>
        <Button fullWidth variant="primary">
          Next
        </Button>
      </a>
    ];
  } else if (
    nibEligibilityStatus === NibEligibilityStatus.SERVICE_NOT_COVERED
  ) {
    variant = "warning";
    message =
      code === "medicalcertificates" ? (
        <span>
          Your nib policy covers one medical certificate per day. You can
          continue today by paying for your medical certificate privately or
          return tomorrow for a no out-of-pocket cost medical certificate.
        </span>
      ) : (
        <span>
          Your current nib membership status does not cover the cost of a{" "}
          {serviceName}. If you would like to continue privately, please click
          Next to proceed.
        </span>
      );
    buttons = [
      <Button
        key={0}
        onClick={() => setShow(false)}
        variant="primary-outline"
        css={{ flex: 1 }}
      >
        Close
      </Button>,
      <a key={1} href={`/questionnaire/?product=${code}`} css={{ flex: 1 }}>
        <Button fullWidth variant="primary">
          Next
        </Button>
      </a>
    ];
  } else if (
    nibEligibilityStatus === NibEligibilityStatus.DETAILS_NOT_FOUND ||
    nibEligibilityStatus === NibEligibilityStatus.INVALID_DETAILS
  ) {
    variant = "warning";
    message = (
      <span>
        The nib membership details provided could not be found. Please check
        your <b>membership number</b> and <b>date of birth</b> carefully to try
        again.
      </span>
    );
    buttons = [
      <Button
        key={0}
        fullWidth
        onClick={() => setShow(false)}
        variant="primary"
      >
        Close
      </Button>
    ];
  } else {
    variant = "danger";
    message = (
      <span>We are experiencing technical difficulties please try again.</span>
    );
    buttons = [
      <Button
        key={0}
        fullWidth
        onClick={() => setShow(false)}
        variant="primary"
      >
        Close
      </Button>
    ];
  }

  const icon: JSX.Element = {
    success: (
      <IconCheckCircle
        height="50px"
        css={(theme) => ({ color: theme.color.success })}
      />
    ),
    warning: (
      <IconTriangleExclamation
        height="50px"
        css={(theme) => ({ color: theme.color.warning })}
      />
    ),
    danger: (
      <IconTriangleExclamation
        height="50px"
        css={(theme) => ({ color: theme.color.danger })}
      />
    )
  }[variant];

  return (
    <Modal
      onHide={() => setShow(false)}
      show={show}
      size="md"
      variant={variant}
    >
      <Flex flexDirection="column">
        {icon}
        <span className="text-center mt-3">{message}</span>
        <Modal.Actions>{buttons}</Modal.Actions>
      </Flex>
    </Modal>
  );
};

const getInputBlock = (props: NibInternationalProps): JSX.Element => {
  const [firstName, setFirstName] = useState<string | undefined>(
    props.firstName
  );
  const [lastName, setLastName] = useState<string | undefined>(props.lastName);
  const [dob, setDob] = useState<string | undefined>(
    `${props.dob_year}-${props.dob_month}-${props.dob_day}`
  );
  const [email, setEmail] = useState<string>(props.email ?? "");
  // to prevent unnecessary re-renders, we keep track of a local membership state
  // then only set the memebership with props.setMembership at the end
  const [membership, setMembership] = useState<string | undefined>(
    props.membership
  );
  const [loadingMembershipNumber, setLoadingMembershipNumber] =
    useState<boolean>(false);
  const [
    showEligibilityConfirmationModal,
    setShowEligibilityConfirmationModal
  ] = useState<boolean>(false);
  const [nibEligibilityStatus, setNibEligibilityStatus] =
    useState<NibEligibilityStatus | null>(null);
  const [messageFromBackend, setMessageFromBackend] = useState<string | null>(
    null
  );
  const voucher = useRef<string | null>(null);
  const [eligibilityFailCount, setEligibilityFailCount] = useState(0);
  const [btLoading, setBtLoading] = useState<boolean>(false);

  const emailFieldIsDisabled = User.loggedIn() && !!props.email;

  useEffect(() => {
    const loadNibMembershipNumber = async () => {
      if (membership) return;
      if (!User.loggedIn()) return;

      setLoadingMembershipNumber(true);
      try {
        const response = await API.v2().url("/third-party/nib").get();
        // API returns 200 if the user has a nib membership number, or 204 if they do not
        if (response.status === 200) {
          const responseBody = await response.json();
          setMembership(responseBody.nib_membership_number ?? "");
        }
      } catch (e: any) {
        Sentry.captureMessage(
          `Failed to load membership number in NibInternational: ${e.message}`
        );
      }
      setLoadingMembershipNumber(false);
    };

    loadNibMembershipNumber();
  }, []);

  const processNext = async (e: any) => {
    e.preventDefault();
    const requiredFieldsPopulated =
      !!membership && !!dob && !!firstName && !!lastName && !!email;
    if (!requiredFieldsPopulated) {
      Toast.error("Please fill in all required fields.");
      return false;
    }
    const emailIsEditable = !emailFieldIsDisabled;
    if (emailIsEditable && Validation.email().validate(email ?? "")) {
      Toast.error("Please enter a valid email address.");
      return false;
    }

    setBtLoading(true);
    const checkMembershipResult = await checkMembership(
      membership,
      dob,
      firstName,
      lastName,
      props.code
    );
    let eligibilityStatus = checkMembershipResult.status;
    voucher.current = checkMembershipResult.voucher;
    setMessageFromBackend(checkMembershipResult.message);

    if (emailIsEditable) {
      const checkUserResult = await Common.checkUserByEmail(email);
      if (
        checkUserResult &&
        checkUserResult.authenticateUser &&
        checkUserResult.authenticateUser.exists
      ) {
        eligibilityStatus = NibEligibilityStatus.USER_ALREADY_EXISTS;
      } else if (checkUserResult === false) {
        // There was an error checking if the user exists
        eligibilityStatus = NibEligibilityStatus.GENERIC_ERROR;
      }
    }
    setNibEligibilityStatus(eligibilityStatus);
    setShowEligibilityConfirmationModal(true);
    if (
      eligibilityStatus === NibEligibilityStatus.DETAILS_NOT_FOUND ||
      eligibilityStatus === NibEligibilityStatus.INVALID_DETAILS ||
      eligibilityStatus === NibEligibilityStatus.SERVICE_DOWN ||
      eligibilityStatus === NibEligibilityStatus.CLAIMS_API_ERROR ||
      eligibilityStatus === NibEligibilityStatus.GENERIC_ERROR
    ) {
      setEligibilityFailCount((currentValue) => currentValue + 1);
    }
    setBtLoading(false);
  };

  const confirmMembership = async (e: React.MouseEvent<HTMLElement>) => {
    props.setMembership(membership ?? "");
    props.continueToQuestionnaire(e);
    if (props.setAllDetails) {
      props.setAllDetails({
        first_name: firstName,
        last_name: lastName,
        email: email,
        dob: dob,
        nib_membership_number: membership
      });
    }
  };

  const confirmDiscount = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.setMembership(membership ?? "");
    props.continueToQuestionnaire(e);
  };

  const labelStyle = {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
    paddingBottom: "4px"
  };

  const inputStyle = {
    borderRadius: "12px",
    lineHeight: "135% !important",
    marginBottom: "24px",
    padding: "8px",
    width: "100%",
    height: "38px"
  };

  return (
    <div
      className="InputBlock"
      css={(theme) => ({
        background: "#e5e0d8",
        height: "100%",
        paddingTop: "1.5rem !important",
        paddingBottom: "1.5rem !important",
        paddingLeft: "1.5rem !important",
        paddingRight: "1.5rem !important",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px",
        [theme.mq.lg]: {
          maxWidth: "488px",
          marginTop: "50px"
        }
      })}
    >
      <MembershipConfirmationModal
        code={props.code}
        show={showEligibilityConfirmationModal}
        setShow={setShowEligibilityConfirmationModal}
        nibEligibilityStatus={nibEligibilityStatus}
        messageFromBackend={messageFromBackend}
        voucher={voucher.current}
        eligibilityFailCount={eligibilityFailCount}
        confirmMembership={confirmMembership}
        confirmDiscount={confirmDiscount}
        onVoucherApplied={props.onVoucherApplied}
        setIsNibPatient={props.setIsNibPatient}
        setFromParam={props.setFromParam}
        setIsValidParam={props.setIsValidParam}
        setResetProductEnv={props.setResetProductEnv}
        hideBack={props.hideBack}
      />
      <h2
        className="w-100"
        css={(theme) => ({
          fontSize: "20px !important",
          fontWeight: "600",
          lineHeight: "150%",
          marginBottom: "16px",
          [theme.mq.md]: {}
        })}
      >
        Please provide your details so we can verify your nib membership
      </h2>

      <div
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          [theme.mq.md]: {
            flexDirection: "row"
          }
        })}
      >
        <div
          css={(theme) => ({
            [theme.mq.md]: {
              width: "100%",
              marginRight: "0.75rem !important"
            }
          })}
        >
          <label className="asterisk" css={labelStyle}>
            First name
          </label>
          <input
            className="fullwidth secondfont small-txt"
            placeholder="First name"
            required={true}
            id="first_name"
            type="text"
            defaultValue={props?.firstName}
            css={inputStyle}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div
          css={(theme) => ({
            [theme.mq.md]: {
              width: "100%",
              marginLight: "0.75rem !important"
            }
          })}
        >
          <label className="asterisk" css={labelStyle}>
            Last name
          </label>
          <input
            className="fullwidth secondfont small-txt"
            placeholder="Last name"
            required={true}
            id="last_name"
            type="text"
            defaultValue={props?.lastName}
            css={inputStyle}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          [theme.mq.md]: {
            flexDirection: "row"
          }
        })}
      >
        <div
          css={(theme) => ({
            [theme.mq.md]: {
              width: "100%"
            }
          })}
        >
          <label className="asterisk" css={labelStyle}>
            Email
          </label>
          <input
            className="fullwidth secondfont small-txt"
            placeholder="email"
            required={true}
            id="useremail"
            type="text"
            value={email}
            disabled={emailFieldIsDisabled}
            css={inputStyle}
            onChange={(e) =>
              setEmail(e.target.value?.toLowerCase().trim() ?? "")
            }
          />
        </div>
      </div>
      <div
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          [theme.mq.md]: {
            flexDirection: "row"
          }
        })}
      >
        <div
          className="dobBlock"
          css={(theme) => ({
            [theme.mq.md]: {
              width: "100%",
              marginRight: "0.75rem !important"
            }
          })}
        >
          <label className="asterisk" css={labelStyle}>
            Date of birth
          </label>
          <div className="row">
            <QuestionnaireDatePicker
              info={{
                dob_year: props?.dob_year,
                dob_month: props?.dob_month,
                dob_day: props?.dob_day,
                onChange: setDob
              }}
              currentQuestion={{ type: "dob" }}
            />
          </div>
        </div>
        <div
          css={(theme) => ({
            [theme.mq.md]: {
              width: "100%",
              marginLight: "0.75rem !important"
            }
          })}
        >
          <label className="asterisk" css={labelStyle}>
            nib membership number
          </label>
          <div css={{ position: "relative" }}>
            <div css={{ position: "absolute", bottom: "40px", right: "20px" }}>
              {loadingMembershipNumber && (
                <Spinner size="sm" variant="primary" />
              )}
            </div>
            <input
              className="fullwidth secondfont small-txt"
              placeholder="nib membership number"
              required={true}
              id="membership_number"
              type="text"
              defaultValue={membership}
              css={inputStyle}
              onChange={(e) => setMembership(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column newsplash-footer">
        <div>
          <button
            id="nextgobutton"
            onClick={processNext}
            className="btn btn-primary"
            css={(theme) => ({
              width: "100%",
              padding: "12px 20px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              marginBottom: "16px",
              [theme.mq.md]: {
                marginLeft: "24px",
                width: "208px !important",
                marginBottom: "0px"
              }
            })}
            disabled={btLoading}
          >
            {btLoading ? (
              <span css={{ display: "flex", justifyContent: "center" }}>
                <Spinner size="sm" variant="primary" />
                <span css={{ marginLeft: 15 }}>Loading ...</span>
              </span>
            ) : (
              "Submit"
            )}
          </button>
        </div>
        <div
          className="order-last order-md-first"
          css={(theme) => ({
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "normal",
            [theme.mq.md]: {
              maxWidth: "208px",
              fontSize: "12px"
            }
          })}
        >
          {props.footer}
        </div>
      </div>
    </div>
  );
};

export const NibInternational: React.FC<NibInternationalProps> = (
  props
): JSX.Element => {
  const createLayout = (): JSX.Element[] => {
    if (!SplashData[props.code]) return [<div key="no-contents"></div>];

    const contentsContainer: ContentBoxs = {
      full: [],
      left: [],
      right: []
    };

    Object.entries(SplashData[props.code].layout).forEach(
      ([key, value], index) => {
        let contentBlock = null;

        if (key === "bg") {
          contentBlock = getBgBlock(props);
        } else if (key === "extra") {
          contentBlock = getExtraBlock(SplashData[props.code]?.extra);
        } else if (key === "logo") {
          contentBlock = getLogoBlock(props);
        } else if (key === "content") {
          contentBlock = SplashData[props.code].content
            ? getContentBlock(SplashData[props.code].content)
            : "";
        } else if (key === "input") {
          contentBlock = getInputBlock(props);
        }

        contentsContainer[value].push(
          <div key={`${value}-${index}`} className={value}>
            {contentBlock}
          </div>
        );
      }
    );

    return [
      ...contentsContainer.full,
      <div key="innerContents" className="row innerContents">
        <div className="col-12 col-lg-6">{contentsContainer.left}</div>
        <div className="col-12 col-lg-6">{contentsContainer.right}</div>
      </div>
    ];
  };

  return (
    <div
      className="nib-splash"
      css={(theme) => ({
        backgroundColor: "white",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "60px",
        [theme.mq.md]: {
          paddingLeft: "0px",
          paddingRight: "0px"
        }
      })}
    >
      {createLayout()}
    </div>
  );
};

export const getNibInterContents = (brand: string, productenv: ProductEnv) => {
  const productCode = productenv.code;
  const splashBg =
    productCode === "medicalcertificates"
      ? "/assets/images/hubhealth/nib-med-cert-background.png"
      : productCode === "telehealth_consult"
      ? "/assets/images/hubhealth/nib-telehealth-consult-background.svg"
      : "/assets/images/hubhealth/nib-contraceptive-background.png";

  const splashMobileBg =
    productCode === "medicalcertificates"
      ? "/assets/images/hubhealth/nib-med-cert-background.png"
      : productCode === "telehealth_consult"
      ? "/assets/images/hubhealth/nib-telehealth-consult-background-mobile.svg"
      : "/assets/images/hubhealth/nib-contraceptive-background.png";

  const HOST = brand === "hubhealth" ? "hub.health" : `${brand}.com.au`;
  const termsLink = `https://${HOST}/terms-and-conditions/`;
  const privacyLink = `https://${HOST}/privacy-policy/`;

  return {
    script: false,
    addendtxt: (
      <span>
        To locate a nib provider, simply visit{" "}
        <a
          href="https://www.nib.com.au/find-a-provider"
          rel="noreferrer"
          target="_blank"
        >
          www.nib.com.au/find-a-provider
        </a>
      </span>
    ),
    outerclass: "nibinternational-outer",
    narrow: true,
    issub: "yes",
    subname: "NibInternational",
    brand: ["hubhealth"],
    exclude: productCode === "medicalcertificates" ? "" : ["Userquestions"],
    logo: `/assets/images/${brand}/niblogo.png`,
    splash: "yes",
    nibInternational: true,
    splashdetail: {
      title: "Nib Membership Welcome page",
      bgfull: true,
      bg: splashBg,
      mobileBg: splashMobileBg,
      typecheck: true,
      typeset: "nibInternational",
      logo: `/assets/images/${brand}/newniblogo.png`,
      splashname: "nibinternational",
      splashclass_: "nibinternational-membership inner-content",
      splashinnerclass_: "col-lg-5",
      bttxt: "Submit",
      btloadtxt: "Verifying...",
      btloadcount: 15,
      footer: {
        outerclass_: "nibinternational-footer",
        innerclass_: "inner pl-sm-1 pr-sm-1 pl-6-cs pr-6-cs",
        btouterclass_: "",
        outertxtclass_: "nibinternational-footertext",
        txtclass_:
          "inner pt-4 pb-6 pl-sm-1 pr-sm-1 pl-6-cs pr-6-cs bblr-08 bbrr-08",
        txt: (
          <span>
            By clicking &quot;Submit&quot; you confirm you have read our{" "}
            <a href={termsLink} rel="noreferrer" target="_blank">
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a href={privacyLink} rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
            .
          </span>
        )
      }
    }
  };
};
