import { Button, Col, Row } from "react-bootstrap";

import { QuestionnaireContentComponentProps } from ".";

import "./QCBoolean.scss";

export const QCBoolean = (props: QuestionnaireContentComponentProps) => {
  const onClick = (value: boolean) => {
    props.onChange(value);
  };

  const yesSelected = props.initialValue === true;
  const noSelected = props.initialValue === false;

  return (
    <Col className="qc-boolean-container">
      <Row>
        <Button
          className={`qc-boolean${yesSelected ? " qc-boolean-selected" : ""}`}
          onClick={() => onClick(true)}
        >
          Yes
        </Button>
      </Row>
      <Row>
        <Button
          className={`qc-boolean${noSelected ? " qc-boolean-selected" : ""}`}
          onClick={() => onClick(false)}
        >
          No
        </Button>
      </Row>
    </Col>
  );
};
