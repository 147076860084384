import * as React from "react";
import { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";

type ResolvedOption = { key: string; name: string };

export interface CompleteLocation {
  address_line1: string;
  address_line2: string;
  address_line3: string;
  postcode: string;
  suburb: string;
  state: string;
  street_number: string;
  street_name: string;
  street_type: string;
  unit_number: string;
}

interface LocationAutocompleteProps {
  onSelectedLocation: (location: CompleteLocation) => void;
  onStartLoading?: () => void;
  style?: React.CSSProperties;
  onFieldsFilledChange?: (filled: boolean) => void;
  autoFocus?: boolean;
}
interface LocationAutocompleteState {
  options: Option[];
  loading: boolean;
}

const AutoCorrectStartedAtLength: number = 4;

const API_URL: string = `${
  import.meta.env.VITE_API_URL
}/api/v1/validate_location`;

export class LocationAutocomplete extends Component<
  LocationAutocompleteProps,
  LocationAutocompleteState
> {
  constructor(props: LocationAutocompleteProps) {
    super(props);
    this.state = {
      options: [],
      loading: false
    };
  }

  updateState = (update: Partial<LocationAutocompleteState>): void =>
    this.setState((prevState) => ({ ...prevState, ...update }));

  render(): JSX.Element {
    return (
      <Form.Group>
        <Form.Label
          css={{
            display: "flex",
            alignContent: "flex-start",
            marginBottom: "4px",
            fontSize: "14px",
            fontWeight: "500"
          }}
        >
          Address
        </Form.Label>
        <InputGroup>
          <AsyncTypeahead
            className="typeahead-input-location"
            id="location_autocomplete"
            placeholder="Begin typing your address here..."
            emptyLabel="No address found, please enter manually"
            promptText="Searching"
            searchText="Searching"
            minLength={AutoCorrectStartedAtLength}
            onSearch={this.autoCompleteLocation}
            isLoading={this.state.loading}
            options={this.state.options}
            filterBy={() => true}
            labelKey={"name"}
            // @ts-ignore
            onChange={this.onSelectionChange}
            autoFocus={this.props.autoFocus}
            caseSensitive={false}
            style={this.props.style}
            css={{
              zIndex: "10000",
              input: {
                padding: "8px",
                fontSize: "14px"
              }
            }}
          />
        </InputGroup>
      </Form.Group>
    );
  }

  renderToken = (option: Option): JSX.Element => (
    <p>{(option as ResolvedOption).name}</p>
  );

  onSelectionChange = (selected: ResolvedOption[]): void => {
    if (selected.length === 0) {
      if (this.props.onFieldsFilledChange)
        this.props.onFieldsFilledChange(false);
    } else {
      if (this.props.onStartLoading) this.props.onStartLoading();
      this.selectAddress(selected[0].key);
    }
  };

  autoCompleteLocation = async (input: string): Promise<void> => {
    this.updateState({ loading: true });

    const response = await fetch(`${API_URL}?q=${encodeURIComponent(input)}`);
    const responseJson = await response.json();
    const options: Option[] = [];
    Object.entries(responseJson).map((e) =>
      options.push({ key: `${e[0]}`, name: `${e[1]}` })
    );

    this.updateState({
      options: options,
      loading: false
    });
  };

  selectAddress = (id: string): void => {
    this.updateState({ loading: true });

    fetch(`${API_URL}/${encodeURI(id)}`)
      .then((res) => res.json())
      .then((optionsResult) => {
        this.props.onSelectedLocation(optionsResult);
        const fieldsFilled =
          optionsResult.address_line1 &&
          optionsResult.suburb &&
          optionsResult.state &&
          optionsResult.postcode;
        if (this.props.onFieldsFilledChange)
          this.props.onFieldsFilledChange(fieldsFilled);
      })
      .catch((err) => {
        console.error(`error: ${err}`);
        if (this.props.onFieldsFilledChange)
          this.props.onFieldsFilledChange(false);
      })
      .finally(() => {
        this.updateState({ loading: false });
      });
  };
}
