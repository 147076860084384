import { useState } from "react";
import moment from "moment-timezone";
import {
  CheckoutAPI,
  type Chemist2UStatus,
  Order,
  ShippingEvent,
  Script
} from "@mh/api";
import { Button } from "@mh/components";
import { ContactUsModal } from "@mh/messaging";

import { Status } from "./Status";
import { isColdChainProduct } from "../utils";

export interface ShippingStatusProps {
  product: Script["product"];
  date?: string;
  status:
    | ShippingEvent["event_type"]["code"]
    | Chemist2UStatus["status"]
    | "hds";
  trackingUrl: string | null;
}

/**
 * Get the most recent shipping status for an order.
 * If the order shipped with Chemist2U, this will fetch the current status from the backend.
 * Otherwise, if the order has shipping events (e.g. shippit), the most recent event is used.
 * If there is no recent shipping status, returns null.
 */
const fetchShippingStatusProps = async (
  script: Script,
  order: Order
): Promise<ShippingStatusProps | null> => {
  const latestShippingEvent = order.shipping_events
    .sort((a, b) => (a.date_created < b.date_created ? -1 : 1))
    .at(0);
  if (order.shipping_code === "chemist2u") {
    try {
      const chemist2UStatus = await CheckoutAPI.getChemist2UStatus(order);
      return {
        product: script.product,
        date: moment(
          chemist2UStatus.delivery_expected,
          "dddd - MMMM Do YYYY - h:mm:ss a"
        ).toISOString(),
        status: chemist2UStatus.status,
        trackingUrl: order.tracking_url
      };
    } catch {
      /** Skip the status if the chemist2u request fails. */
      return null;
    }
  } else if (latestShippingEvent) {
    if (order.tracking_url?.startsWith("https://hds.gg")) {
      /**
       * NOTE: This is a workaround until we have the HDS webhook to create shipping events.
       * Until then, an HDS order won't get new shipping events so we'll show a custom status
       * in this case.
       */
      return {
        product: script.product,
        date: latestShippingEvent.date_created,
        status: "hds",
        trackingUrl: order.tracking_url
      };
    }
    return {
      product: script.product,
      date: latestShippingEvent.date_created,
      status: latestShippingEvent.event_type.code,
      trackingUrl: order.tracking_url
    };
  }
  return null;
};

const ShippingStatusBeingPrepared = ({ product }: ShippingStatusProps) => (
  <Status
    title={
      isColdChainProduct(product.title)
        ? "AWAITING COURIER PICKUP"
        : "ORDER BEING PREPARED"
    }
  >
    <p>
      {isColdChainProduct(product.title)
        ? "The medication you ordered is best kept refrigerated. We dispense and dispatch these orders Monday-Wednesday, to ensure that they remain temperature-regulated during transit."
        : "Your order is being prepared by the pharmacy."}
    </p>
  </Status>
);

const ShippingStatusReadyForDelivery = () => (
  <Status title="READY FOR DELIVERY">
    <p>
      Your order has been packed by the pharmacy and is ready for delivery. We
      will notify you when your order is in transit.
    </p>
  </Status>
);

const ShippingStatusInTransit = () => (
  <Status title="IN TRANSIT">
    <p>
      Your order has been shipped and is currently in transit with our delivery
      partner.
    </p>
  </Status>
);

const ShippingStatusDelivered = ({ date }: ShippingStatusProps) => (
  <Status title="DELIVERED">
    {date && (
      <p>Your order was delivered on {moment(date).format("Do MMMM YYYY")}.</p>
    )}
    {!date && <p>Your order was delivered.</p>}
  </Status>
);

const ShippingStatusDeliveryUnsuccessful = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return (
    <Status title="DELIVERY UNSUCCESSFUL" titleVariant="danger">
      <p>
        Our delivery partner was unable to deliver your order. Use the{" "}
        {"'Track Delivery'"} button to see the status of your delivery or{" "}
        <Button
          css={{ fontWeight: 600 }}
          onClick={() => setShowContactUsModal(true)}
          variant="primary-text"
        >
          contact our Patient Success team
        </Button>
        .
      </p>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </Status>
  );
};

const ShippingStatusDeliveryCancelled = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return (
    <Status title="DELIVERY CANCELLED" titleVariant="danger">
      <p>
        Your delivery has been cancelled. For more information, please{" "}
        <Button
          css={{ fontWeight: 600 }}
          onClick={() => setShowContactUsModal(true)}
          variant="primary-text"
        >
          contact our Patient Success team
        </Button>
        .
      </p>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </Status>
  );
};

const ShippingStatusDispatched = () => (
  <Status title="DISPATCHED">
    <p>
      Your order is being shipped refrigerated and is with our delivery partner.
      Use the {"'Track Delivery'"} button for updates.
    </p>
  </Status>
);

const SHIPPING_STATUS_MAP: Record<
  ShippingStatusProps["status"],
  (props: ShippingStatusProps) => JSX.Element
> = {
  /** Shippit */
  awaiting: ShippingStatusDeliveryUnsuccessful,
  "bad-address": ShippingStatusDeliveryUnsuccessful,
  booked: ShippingStatusReadyForDelivery,
  cancelled: ShippingStatusDeliveryCancelled,
  complete: ShippingStatusDelivered,
  completed: ShippingStatusDelivered,
  created: ShippingStatusBeingPrepared,
  dispatch: ShippingStatusReadyForDelivery,
  error: ShippingStatusDeliveryUnsuccessful,
  "in-transit": ShippingStatusInTransit,
  shippit_confirmed: ShippingStatusReadyForDelivery,
  transit: ShippingStatusInTransit,

  /** Chemist2U */
  Delivered: ShippingStatusDelivered,
  Paid: ShippingStatusBeingPrepared,
  "Pharmacy Approved": ShippingStatusReadyForDelivery,
  Ready: ShippingStatusReadyForDelivery,
  "Shipped Out": ShippingStatusInTransit,

  /** HDS */
  hds: ShippingStatusDispatched
};

export const ShippingStatus = (props: ShippingStatusProps) => (
  <div>
    {SHIPPING_STATUS_MAP[props.status](props)}
    {props.trackingUrl && (
      <a
        css={(theme) => ({
          width: "100%",
          alignSelf: "end",
          [theme.mq.md]: {
            width: "auto"
          }
        })}
        href={props.trackingUrl}
        rel="noreferrer"
        target="_blank"
      >
        <Button css={{ width: "100%" }}>Track Delivery</Button>
      </a>
    )}
  </div>
);

ShippingStatus.fetchShippingStatusProps = fetchShippingStatusProps;
