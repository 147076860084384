import { css, Global } from "@emotion/react";
import {
  ToastContainer as BaseToastContainer,
  CloseButtonProps
} from "react-toastify";
import { IconClose } from "../icons";
import { Button } from "../buttons";

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <Button
    onClick={closeToast}
    variant="primary-plain"
    css={{ alignSelf: "baseline", padding: "8px 4px" }}
  >
    <IconClose width={21} height={21} />
  </Button>
);

export const ToastContainer = () => (
  <>
    <Global
      styles={(theme) => css`
        .Toastify__toast {
          border-top-width: 2px;
          border-top-style: solid;
          border-radius: 8px;
          box-shadow: 0px 3.54399px 7.08799px 0px rgba(0, 0, 0, 0.25);
          color: #000000;
        }
        .Toastify__toast-container {
          width: auto;
          z-index: 100001;
          max-width: 480px;
          min-width: 342px;
        }
        .Toastify__toast--info {
          border-color: ${theme.color.primary};
        }
        .Toastify__toast--success {
          border-color: #198754;
        }
        .Toastify__toast--warning {
          border-color: #fd7e14;
        }
        .Toastify__toast--error {
          border-color: #ff0018;
        }
        .Toastify__toast-icon {
          width: 24px;
          height: 40px;
        }
        @media only screen and (max-width: 480px) {
          .Toastify__toast-container {
            width: 100%;
            max-width: 342px;
            min-width: auto;
            left: 50%;
            transform: translate(-50%, 0);
          }
          .Toastify__toast-container--top-center {
            top: 0.5em;
          }
        }
      `}
    />
    <BaseToastContainer closeButton={CloseButton} />
  </>
);
