import { useEffect, useState } from "react";

import { Flex } from "@mh/components";
import { ListQuestionTypeParams } from "@mh/questionnaire/src/types";
import { useQContext } from "@mh/questionnaire/src/hooks";

import { QuestionnaireContentComponentProps } from "../..";
import { QCListProvider } from "../QCList/QCListContext";
import { MedicationList } from "./MedicationList";
import {
  MedicationInformation,
  MedicationInformationResult
} from "./MedicationInformation";

import "./styles.scss";

/**
 * Medication selection has two stages.
 *  - Stage 1: Medication selection: in this stage we build a list of medications to ask more specific questions of
 *  - Stage 2: For each medication we ask several specific questions of
 */

export const QCMedicationSelection = (
  props: QuestionnaireContentComponentProps
) => {
  const { setQuestionTitleOverride, setBackButtonOverride } = useQContext();
  const defaultOptions = (props.question.type_params as ListQuestionTypeParams)!
    .options!;

  const [medications, setMedications] = useState<string[]>(
    Object.keys(props.initialValue ?? [])
  );
  const [medicationIndex, setMedicationIndex] = useState<number>(0);
  const [medicationData, setMedicationData] = useState<
    Record<string, MedicationInformationResult>
  >(props.initialValue ?? {});

  const [medicationSelectionStage, setMedicationSelectionStage] =
    useState<boolean>(true);

  useEffect(
    () =>
      setQuestionTitleOverride(
        medicationSelectionStage
          ? null
          : `${medications[medicationIndex]} [${medicationIndex + 1}/${
              medications.length
            }]`
      ),
    [medications, medicationIndex, medicationSelectionStage]
  );

  useEffect(() => {
    if (medicationSelectionStage) {
      setBackButtonOverride(null);
    } else {
      // Set state takes either an object or a callback
      // Because we are setting a callback we need to add the additional () =>
      // because it assumes the function is the callback which it isn't
      setBackButtonOverride(() => () => {
        if (medicationIndex > 0) {
          setMedicationIndex(medicationIndex - 1);
        } else if (!medicationSelectionStage) {
          setMedicationSelectionStage(true);
        }
      });
    }
  }, [medicationSelectionStage, medicationIndex]);

  return (
    <div className="medication-selection">
      {medicationSelectionStage ? (
        <QCListProvider
          initialOptions={[
            ...defaultOptions,
            ...medications.filter((m) => !defaultOptions.includes(m))
          ]}
          initialOptionsSelection={Object.fromEntries(
            medications.map((v) => [v, true])
          )}
        >
          <MedicationList
            {...props}
            complete={(v) => {
              if (v.length === 0) {
                props.onChange({}, true);
              } else {
                setMedications(v);
                setMedicationIndex(0);
                setMedicationSelectionStage(false);
              }
            }}
          />
        </QCListProvider>
      ) : (
        <Flex>
          <MedicationInformation
            key={`med-info-${medications[medicationIndex]}`}
            initial={medicationData[medications[medicationIndex]]}
            next={(info) => {
              const latestMedicationData = {
                ...Object.fromEntries(
                  Object.entries(medicationData).filter(([k, _]) =>
                    medications.includes(k)
                  )
                ),
                ...{ [medications[medicationIndex]]: info }
              };

              setMedicationData(latestMedicationData);
              if (medicationIndex + 1 === medications.length) {
                props.onChange(latestMedicationData, true);
              } else {
                setMedicationIndex(medicationIndex + 1);
              }
            }}
          />
        </Flex>
      )}
    </div>
  );
};
