import { SafeURLSearchParams } from "@mh/core";

import {
  createRequest,
  extractPagination,
  Id,
  PaginatedResponse
} from "../../../utils";
import { API } from "../../../api";
import type { UserAddress } from "../../address";
import type {
  Country,
  SubmitCheckoutRequest,
  Order,
  ShippingMethod,
  GetShippingMethodsParams,
  RouterMethods,
  Chemist2UStatus
} from "./types";

export class CheckoutAPI {
  /** Retrieves all addresses for the current user */
  static getAddresses = async () => {
    const response = await API.url("/shop/api/useraddresses/").get();
    return (await response.json()) as PaginatedResponse<UserAddress>;
  };

  /** Creates a new address for the user */
  static createAddress = async (payload: Partial<UserAddress>) => {
    const response = await API.body(payload)
      .url("/shop/api/useraddresses/")
      .post();
    return (await response.json()) as UserAddress;
  };

  /** Updates a given user address
   *
   * @param urlParams Object of type {@link Id} containing the ID of the UserAddress to update
   * @param payload Object containing properties to update
   */
  static updateAddress = async (
    urlParams: Id,
    payload: Partial<UserAddress> & Id
  ) => {
    const response = await API.body(payload)
      .url(`/shop/api/useraddresses/${urlParams.id}/`)
      .patch();
    return (await response.json()) as UserAddress;
  };

  /** Loads all shipping countries */
  static getCountries = extractPagination(
    createRequest<PaginatedResponse<Country>>("/shop/api/countries/")
  );

  /** Loads all shipping methods for the current basket
   *
   * @param options Options of type {@link GetShippingMethodsParams}
   * @returns An array of {@link ShippingMethod}
   */
  static getShippingMethods = async (
    options?: GetShippingMethodsParams
  ): Promise<ShippingMethod[]> => {
    // Convert the options object into a query parameters object which SafeURLSearchParams can use
    const stringParams: Record<keyof GetShippingMethodsParams, string> = {
      default: options?.default?.toString() ?? "false",
      persisted_shipping: options?.persisted_shipping?.toString() ?? "false"
    };

    // Get query parameters ready
    const queryParams = new SafeURLSearchParams(stringParams);

    return API.url(
      `/shop/api/basket/shipping-methods/?${queryParams.toString()}`
    )
      .get()
      .then((r) => r.json());
  };

  /** Loads all router methods for all order lines
   *
   * @returns An object of {@link RouterMethods}
   */
  static getRouterMethods = async (): Promise<RouterMethods> =>
    API.url("/shop/api/basket/router-methods/")
      .get()
      .then((r) => r.json());

  /** Loads all orders for the current user */
  static getOrders = async (): Promise<PaginatedResponse<Order>> =>
    API.url("/shop/api/orders/")
      .get()
      .then((r) => r.json());

  /** Creates an order from an open basket
   *
   * @param urlParams Object of type {@link Id}
   * @param payload Object containing preferred repeats for the selected product, and the questionnaire to purchase for
   */
  static submitCheckoutOrder = async (
    payload: SubmitCheckoutRequest
  ): Promise<Order> =>
    API.url("/shop/api/checkout/")
      .body(payload)
      .post()
      .then((r) => {
        if (!r.ok) {
          throw r;
        }
        return r.json();
      });

  static getChemist2UStatus = async (order: Order): Promise<Chemist2UStatus> =>
    API.url(`/shop/api/chemist2u-status/${order.id}/`)
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });
}
