import { HOST, BRAND } from "@mh/core";
import { APIMethod } from "./types";
import { safeGetToken } from "./user/user";

/** Transforms a JSON object into a form encoded string
 *
 * @param json A JSON object to transform
 * @returns A form encoded string with the same key-value pairs as the provided JSON object
 */
function transformJsonToFormEncoded(json: { [key: string]: any }) {
  return Object.keys(json)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
    .join("&");
}

export class RestClient {
  private endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async request<Response, Request = {}>(
    url: string,
    method: APIMethod,
    body?: Request
  ) {
    const token = safeGetToken();
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }

    // Add the brand header
    headers.append("Brand", BRAND);

    const response = await fetch(`${this.endpoint}${url}`, {
      method,
      body: body && transformJsonToFormEncoded(body),
      headers
    });

    if (response.status === 201) {
      // No content, but return an empty object to preserve the response type
      return {} as Response;
    }

    if (response.status === 401 && response.statusText === "Unauthorized") {
      localStorage.removeItem("BASE_USER_INFO");
      window.location.href = "/";
    }

    try {
      const result = await response.json();
      return result as Response;
    } catch (e) {
      throw new Error(`Response was not valid JSON: ${url}`);
    }
  }
}

export const client = new RestClient(HOST);
