import { NavigateFunction } from "react-router-dom";
import moment from "moment-timezone";
import { Sentry } from "@mh/core";
import {
  ExistingUserInfo,
  OTPRequestContent,
  OTPValidateContent,
  UserInfo
} from "./interface";
import { API } from "@mh/api";

export const LOCAL_STORE_USER_KEY = "BASE_USER_INFO";

export const loadUserFromStore = () => {
  const storedInfo: string | null = localStorage.getItem(LOCAL_STORE_USER_KEY);
  const userJSON: any | undefined =
    storedInfo === null ? undefined : JSON.parse(storedInfo);
  if (storedInfo === null || userJSON === undefined) return null;
  return userJSON as UserInfo;
};

export function safeGetToken(): string | null {
  const storedInfo = localStorage.getItem(LOCAL_STORE_USER_KEY);
  if (!storedInfo) return null;
  return JSON.parse(storedInfo).token ?? null;
}

const setUserToStore = (u: UserInfo) =>
  localStorage.setItem(LOCAL_STORE_USER_KEY, JSON.stringify(u));

const safeFormatTokenExpiry = (u: UserInfo | null): null | moment.Moment => {
  if (u === null) return null;
  const { expiry } = u;
  if (expiry.includes("+")) {
    // New format with timezone
    return moment(expiry, "YYYY-MM-DDTHH:mm:ss.SSSZ");
  }
  // Old format
  return moment(expiry).add(moment.tz("Australia/Victoria").utcOffset());
};

export const User = (() => {
  "use strict";

  let user: UserInfo | null = null;
  let setUser: React.Dispatch<React.SetStateAction<UserInfo | null>> | null =
    null;
  let nav: NavigateFunction | null = null;

  const setup = (
    u: UserInfo | null,
    setU: React.Dispatch<React.SetStateAction<UserInfo | null>>,
    navigate: NavigateFunction
  ) => {
    user = u;
    setUser = setU;
    nav = navigate;
  };

  const get = (): UserInfo | null => {
    if (user !== null) return user;
    const storedUser: UserInfo | null = loadUserFromStore();
    if (storedUser !== null) {
      setUserToStore(storedUser);
    }
    return storedUser;
  };

  const updateSentryUser = (user?: UserInfo) => {
    Sentry.setUser(user ? { id: user.id.toString() } : null);
    Sentry.setContext(
      "user_info",
      user
        ? {
            id: user.id.toString(),
            email: user.email,
            user_type: user.user_type,
            has_patient_details: user.has_patient_details.toString()
          }
        : null
    );
  };

  const login = (user: UserInfo) => {
    if (setUser) setUser(user);
    setUserToStore(user);
    updateSentryUser(user);
  };

  const logout = () => {
    localStorage.clear();
    updateSentryUser();
    if (setUser) setUser(null);
    if (nav) nav("/");
  };

  return {
    setup: setup,
    login: login,
    logout: logout,
    info: () => get(),
    // Params
    id: () => get()?.id,
    email: () => get()?.email,
    userType: () => get()?.user_type,
    hasPatientDetails: () => get()?.has_patient_details,
    expiry: () => safeFormatTokenExpiry(get()),
    name: () => get()?.name,
    // Helpers
    loggedIn: () => {
      return get() !== null;
      // const expiry = safeFormatTokenExpiry(get());
      // return (expiry !== null);
      // return expiry.isAfter(moment().utcOffset(expiry.utcOffset()));
    }
  };
})();

export class UserAPI {
  static getOTP = async (request: OTPRequestContent): Promise<Response> =>
    API.unauthenticated().v2().url("/auth/get-token/").body(request).post();

  static validateOTP = async (request: OTPValidateContent): Promise<Response> =>
    API.unauthenticated()
      .v2()
      .url("/auth/validate-token/")
      .body(request)
      .json()
      .post();

  static getExistingUserInfo = async (
    email: string
  ): Promise<ExistingUserInfo> =>
    API.v1()
      .unauthenticated()
      .url(`/authenticateuser?email=${encodeURIComponent(email)}`)
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });
}
