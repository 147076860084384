import { Form } from "react-bootstrap";

import { Flex } from "@mh/components";

export const MedicationInformationDosage = (props: {
  dosage: string | null;
  setDosage: (v: string) => void;
}) => (
  <Flex className="field" flexDirection="column" width="100%">
    <div style={{ paddingBottom: 10 }}>
      <div>
        <div className="asterisk">What dosage did you take?</div>
        <div className="subtitle">Example: 2 30ml pills daily</div>
      </div>
      <div>
        <Form.Control
          placeholder="Dosage"
          value={props.dosage ?? ""}
          onChange={(e) => props.setDosage(e.target.value)}
        />
      </div>
    </div>
  </Flex>
);
