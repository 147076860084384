import { PropsWithChildren } from "react";

export const BrandBanner = ({ children }: PropsWithChildren) => (
  <div
    css={(theme) => ({
      display: "flex",
      flexDirection: "row-reverse",
      backgroundColor: "#270045",
      borderRadius: "8px",
      fontFamily: theme.font.heading,
      fontSize: "20px",
      overflow: "hidden"
    })}
  >
    <div
      css={{
        position: "relative",
        width: "30%",
        maxWidth: "250px"
      }}
    >
      <div
        css={(theme) => ({
          position: "absolute",
          right: 0,
          width: "100%",
          height: "100%",
          backgroundColor: theme.color.primary,
          clipPath: "circle(150px at 150px -50px)"
        })}
      />
      <div
        css={(theme) => ({
          position: "absolute",
          right: 0,
          width: "80%",
          height: "100%",
          backgroundColor: theme.color.secondary,
          clipPath: "circle(200px at 200px 20px)"
        })}
      />
    </div>
    <div
      css={{
        flex: 1,
        color: "white",
        margin: "24px 0px 24px 36px"
      }}
    >
      {children}
    </div>
  </div>
);
