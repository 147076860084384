import { ComponentPropsWithoutRef } from "react";

export const IconClinician = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 30 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.7938 10.0534C27.7938 11.5526 26.7443 12.802 25.3949 13.1518V18.0495C25.3949 22.3973 21.7967 25.9956 17.3988 25.9956C12.951 25.9956 9.40273 22.4473 9.40273 18.0495V17.9495C5.35471 17.5497 2.20625 14.1514 2.20625 10.0534V2.90685C2.20625 2.55702 2.35618 2.30714 2.60606 2.10724L5.80449 0.557996C6.2043 0.358094 6.70405 0.50802 6.90396 0.907825C7.10386 1.30763 6.95393 1.80739 6.55413 2.00729L3.80547 3.35663V10.0534C3.80547 13.6016 6.65408 16.4502 10.2023 16.4502C13.7006 16.4502 16.5992 13.6016 16.5992 10.0534V3.35663L13.8006 2.00729C13.4008 1.80739 13.2509 1.30763 13.4508 1.00778C13.6507 0.607972 14.1504 0.458045 14.5502 0.657947L17.7487 2.20719C17.9985 2.35712 18.1984 2.60699 18.1984 2.90685V10.0534C18.1984 14.2013 15 17.5997 11.002 17.9995V18.0495C11.002 21.5477 13.8506 24.4463 17.3988 24.4463C20.8971 24.4463 23.7957 21.5977 23.7957 18.0495V13.1518C22.3964 12.802 21.3969 11.5526 21.3969 10.0534C21.3969 8.35419 22.7962 6.9049 24.5953 6.9049C26.3445 6.9049 27.7938 8.30421 27.7938 10.0534ZM24.5953 11.6526C25.4449 11.6526 26.1945 10.9529 26.1945 10.1033C26.1945 9.30372 25.4449 8.55409 24.5953 8.55409C23.6958 8.55409 22.9961 9.25375 22.9961 10.1033C22.9961 10.9529 23.6958 11.6526 24.5953 11.6526Z"
      fill="#e84c42"
    />
  </svg>
);
