import { Conversation, Message } from "@mh/api";
import "./ConversationItem.scss";

interface Props {
  conversation: Conversation;
  selected: Boolean;
  onClick: (conversation: Conversation) => void;
}

export function ConversationItem(props: Props) {
  const latestMessage: Message = props.conversation.latest_message;
  const date = new Date(latestMessage.sent).toLocaleString("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    hour12: true,
    minute: "numeric"
  });

  return (
    <div
      className={`card mb-3 p-4 text-truncate ${
        props.selected
          ? "border-0 border-start border-secondary border-3 selected-conversation-item"
          : "border-light bg-light"
      }`}
      onClick={() => props.onClick(props.conversation)}
      data-testid="conversation-item"
    >
      <div className="d-flex flex-row justify-content-between w-100 rounded-3">
        <div className="d-flex flex-column">
          <h6 className="text-primary">
            {props.conversation.other_participant_display_name}
          </h6>
          <b className="small">{props.conversation.condition}</b>
          <p className="text-muted small">{date}</p>
        </div>
      </div>
      <div className="d-flex flex-row w-100">{latestMessage.content}</div>
    </div>
  );
}
