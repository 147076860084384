import { Form, Dropdown } from "react-bootstrap";
import { FC, useEffect, useRef, useState } from "react";
import { Condition, MessagingAPI, PatientAPI } from "@mh/api";
import { Modal, Toast, Button } from "@mh/components";

const ERROR_DISPLAY_TIMEOUT = 10000;

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

interface SubjectOption {
  id: number;
  txt: string;
}

export const ContactUsModal: FC<Props> = (props) => {
  /** Display and select the Condition to query */
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [selectedTreatment, setSelectedTreatment] = useState<
    Condition | null | undefined
  >(null);
  const messageContent = useRef<HTMLTextAreaElement | null>(null);
  /** Request subject and description [Patient Success - Zendesk]  */
  const [selectedSubject, setSelectedSubject] = useState<
    SubjectOption | null | undefined
  >(null);

  /** Is a Conversation or a Request being initiated? */
  const [isSendPSLoading, setIsSendPSLoading] = useState<boolean>(false);
  /** Error message if there has been an error while creating Convo/Req */
  const [errorMessagePS, setErrorMessagePS] = useState<string | null>(null);

  const subjectOptions: SubjectOption[] = [
    { id: 1, txt: "I want to try a different treatment" },
    { id: 2, txt: "I am experiencing a side effect" },
    { id: 3, txt: "I have a problem with my account" },
    { id: 4, txt: "I have a question about my delivery" },
    { id: 5, txt: "I have a problem with my payment" },
    { id: 6, txt: "I have a problem not listed here" }
  ];

  const selectLabelStyle = {
    fontSize: "14px",
    fontWeight: "600",
    marginBottom: "8px"
  };

  const errorMessageSpan = () => {
    return (
      <span
        className="text-danger"
        css={[
          selectLabelStyle,
          {
            paddingTop: "0px !important",
            fontSize: "12px",
            color: "red",
            marginBottom: "8px"
          }
        ]}
      >
        {errorMessagePS}
      </span>
    );
  };

  const loadConditions = async () => {
    const response = await PatientAPI.getConditions();
    if (response.ok) {
      const data = await response.json();
      data.push({ questionnaire_id: 1000, condition: "Other" });
      setConditions(data);
    }
  };

  const handleCreateZendeskRequest = async (event: any) => {
    event.preventDefault();
    if (!selectedSubject?.txt || !messageContent.current?.value) {
      setErrorMessagePS("Treatment, subject and body must not be empty");
      return;
    }
    setIsSendPSLoading(true);

    const response = await MessagingAPI.createZendeskRequest(
      selectedSubject.txt,
      messageContent.current?.value
    );

    setIsSendPSLoading(false);
    if (response.ok) {
      props.setShow(false);
      Toast.success(
        "We have received your message, one of our team members will come back to you shortly."
      );
    } else {
      response.text().then((text) => {
        setErrorMessagePS(JSON.parse(text)?.detail);
      });
    }
  };

  useEffect(() => {
    loadConditions();
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () => setErrorMessagePS(null),
      ERROR_DISPLAY_TIMEOUT
    );
    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [errorMessagePS]);

  const handleSelectChange = (
    type: "treatment" | "subject",
    id: number
  ): void => {
    if (id < 0) return;

    if (type === "treatment") {
      setSelectedTreatment(conditions.find((c) => c.questionnaire_id === id));
    } else {
      setSelectedSubject(subjectOptions.find((c) => c.id === id));
    }
  };

  const getMenuItem = (
    index: number,
    type: "treatment" | "subject",
    id: number,
    text: string
  ) => {
    return (
      <Dropdown.Item
        key={`${type}-${index}`}
        onClick={() => {
          handleSelectChange(type, id);
        }}
      >
        {text}
      </Dropdown.Item>
    );
  };

  const createDropdown = (
    type: "treatment" | "subject",
    items: any[],
    idName: string,
    textName: string,
    selected: string
  ) => {
    return (
      <Dropdown
        css={(theme) => ({
          button: {
            padding: "8px 16px",
            textAlign: "left",
            width: "100%",
            position: "relative",
            background: "#FFF",
            color: "#000",
            "&:after": {
              position: "absolute",
              right: "16px",
              top: "50%"
            },
            "&.show, &:hover, &:active": {
              backgroundColor: "#FFF !important",
              borderColor: theme.color.primary,
              color: "inherit !important",
              boxShadow: "none"
            }
          }
        })}
      >
        <Dropdown.Toggle>
          <div>{selected}</div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          /* @ts-ignore */
          placement="top"
          /* @ts-ignore */
          dropupauto="false"
          data-dropup-auto="false"
          css={(theme) => ({
            top: "0px !important",
            width: "100%",
            paddingBottom: "0px",
            maxHeight: "400px",
            overflowY: "auto",
            [theme.mq.md]: {
              maxHeight: "406px"
            }
          })}
        >
          {items.map((item, index) =>
            getMenuItem(index, type, item[idName], item[textName])
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Modal onHide={() => props.setShow(false)} show={props.show}>
      <Modal.Title onClose={() => props.setShow(false)}>Contact us</Modal.Title>
      <>
        {errorMessagePS !== null && errorMessageSpan()}
        <div>
          <div css={selectLabelStyle}>
            Which treatment or service are you contacting us about?
          </div>
          <div>
            {createDropdown(
              "treatment",
              conditions,
              "questionnaire_id",
              "condition",
              selectedTreatment?.condition ?? "Select treatment or service"
            )}
          </div>
        </div>
        <div>
          <div css={[selectLabelStyle, { marginTop: "16px" }]}>
            What is the reason for your enquiry?
          </div>
          <div>
            {createDropdown(
              "subject",
              subjectOptions,
              "id",
              "txt",
              selectedSubject?.txt ?? "Select reason"
            )}
          </div>
        </div>
        <div>
          <div
            css={[
              selectLabelStyle,
              {
                marginTop: "16px"
              }
            ]}
          >
            How can we help you?
          </div>
          <div>
            <Form.Control
              as="textarea"
              rows={4}
              name={"content"}
              ref={messageContent}
              aria-label="content"
              css={(theme) => ({
                padding: "2px 8px",
                borderRadius: "4px",
                border: `1px solid ${theme.color.primary}`,
                marginBottom: "16px"
              })}
            />
          </div>
        </div>
        <div className="d-flex flex-lg-row flex-column justify-content-end">
          <Button
            css={(theme) => ({
              minWidth: "150px",
              marginBottom: "16px",
              [theme.mq.lg]: {
                marginBottom: "0",
                marginRight: "16px"
              }
            })}
            disabled={isSendPSLoading}
            variant="primary-outline"
            type="button"
            onClick={() => props.setShow(false)}
            className="mr-3"
          >
            <span className="text-muted">Cancel</span>
          </Button>
          <Button
            css={{
              minWidth: "150px"
            }}
            variant="primary"
            type="submit"
            onClick={(e) => handleCreateZendeskRequest(e)}
            disabled={isSendPSLoading}
            data-testid="messaging-submit-conversation"
          >
            Send
          </Button>
        </div>
      </>
    </Modal>
  );
};
