import { API, VoucherDiscount } from "@mh/api";

export interface QuestionnairePaymentIntentResponse {
  client_secret: string;
  price: {
    total_excl_tax: string;
    total_excl_tax_excl_discounts: string;
    total_incl_tax: string;
    total_incl_tax_excl_discounts: string;
    total_tax: string;
    total_discount: number;
    currency: string;
    voucher_discounts: VoucherDiscount[];
    offer_discounts: any[];
    is_tax_known: boolean;
  };
  basket_id: number;
}

export const QuestionnaireAPI = {
  start: (
    slug: string,
    urlParams: object,
    cookies: { [key: string]: string }
  ) =>
    API.authenticateIfProvided()
      .v2()
      .url(`/questionnaire/${slug}/`)
      .json()
      .body({
        url_parameters: urlParams,
        cookies
      })
      .post(),

  submit: (slug: string, response: any) =>
    API.authenticateIfProvided()
      .v2()
      .url(`/questionnaire/${slug}/`)
      .body(response)
      .json()
      .patch(),

  back: (slug: string, response: any) =>
    API.authenticateIfProvided()
      .v2()
      .url(`/questionnaire/${slug}/`)
      .body(response)
      .json()
      .delete(),

  getPaymentIntent: (
    productId: number,
    vouchers: string[]
  ): Promise<Response> =>
    API.authenticateIfProvided()
      .v2()
      .url("/questionnaire/payment-intent/")
      .body({ product_id: productId, vouchers: vouchers })
      .json()
      .post()
};
