import React from "react";
import { Form, FormControlProps } from "react-bootstrap";

import { EditableBase, EditableBaseProps } from "./EditableBase";

export type EditableFormControlProps = FormControlProps & EditableBaseProps;

const DefaultNonEditableComponent = (
  props: Pick<EditableFormControlProps, "type" | "value" | "as">
) => {
  const { type, value, as } = props;
  let displayValue = value;
  if (value) {
    if (type === "date" && typeof value === "string") {
      // Reverse a raw date string value of the form 'YYYY-MM-DD' to display as 'DD / MM / YYY'
      displayValue = value?.split("-").reverse().join(" / ");
    }
    if (
      as &&
      as === "textarea" &&
      typeof value === "string" &&
      value.includes(";")
    ) {
      displayValue = value?.split(";").join("\n");
    }
  }
  return (
    <span
      css={{
        whiteSpace: as === "textarea" ? "pre-line" : "normal"
      }}
    >
      {displayValue || "\u200b"}
    </span>
  );
};

export const EditableFormControl = React.forwardRef(
  (props: EditableFormControlProps, ref) => {
    const { isEditing, nonEditableComponent, ...formControlProps } = props;
    return (
      <EditableBase
        isEditing={isEditing}
        nonEditableComponent={
          nonEditableComponent || <DefaultNonEditableComponent {...props} />
        }
      >
        <Form.Control ref={ref} {...formControlProps} />
      </EditableBase>
    );
  }
);
