import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckVerificationCodeRequest,
  CorporateMember,
  CorporateMembershipAPI,
  CreateVerificationCodeRequest,
  User,
  UserAPI
} from "@mh/api";
import {
  Button,
  LogoBrand,
  Toast,
  ShippingAddressValues,
  PhoneInput,
  Address
} from "@mh/components";
import {
  BRAND_HELP_EMAIL,
  BRAND_NAME,
  HOST,
  SafeURLSearchParams,
  Validation,
  redirectTo
} from "@mh/core";
import { QuestionnaireDatePicker } from "../../components/questionnaire/QuestionnaireDatePicker";

import "./styles.scss";
import { getBrandFromRedirectUrl } from "../../utils";

type SignUpStep =
  | "enter_allowed_member_email"
  | "enter_allowed_member_verification_code"
  | "enter_preferred_email"
  | "preferred_email_confirmation"
  | "enter_login_verification_code"
  | "patient_details_form";

const initialCorporateMember: CorporateMember = {
  firstName: "",
  lastName: "",
  dob: "",
  sex: "",
  addressLine1: "",
  addressLine2: "",
  suburb: "",
  state: "",
  postcode: "",
  phone: ""
};

const termsLink = `https://${HOST}/terms-and-conditions/`;
const privacyLink = `https://${HOST}/privacy-policy/`;
const emailSentMessage =
  "An email has been sent to you containing a single-use code.";
const contactUsLink = `mailto:${BRAND_HELP_EMAIL}?subject=Trouble signing up to Corporate Membership`;

const renderButton = (
  text: string,
  disabled: boolean = false,
  isLoading: boolean = false,
  onClick: () => void = () => {}
) => (
  <Button className="button" disabled={isLoading || disabled} onClick={onClick}>
    {isLoading ? (
      <span
        className="loadingouter position-relative"
        data-testid="spinner-testid"
      >
        <Spinner animation="border" className="loading" size="sm" />
      </span>
    ) : (
      text
    )}
  </Button>
);

const isEmailValid = (email: string): boolean => {
  Toast.dismiss();
  if (Validation.email().validate(email)) {
    Toast.error("Please enter a valid email address.");
    return false;
  }
  return true;
};

export const CorporateSignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [allowedMemberEmail, setAllowedMemberEmail] = useState<string>("");
  const [membershipSlug, setMembershipSlug] = useState<string>("");
  const [preferredEmail, setPreferredEmail] = useState<string>("");
  const [member, setMember] = useState<CorporateMember>(initialCorporateMember);
  const [loginCode, setLoginCode] = useState<string>("");
  const [allowedMemberVerificationCode, setAllowedMemberVerificationCode] =
    useState<string>("");
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [userBrandName, setUserBrandName] = useState<string>("");
  const [signUpStep, setSignUpStep] = useState<SignUpStep>(
    "enter_allowed_member_email"
  );

  const isPatientDetailsFormValid = () =>
    member.firstName &&
    member.lastName &&
    member.dob &&
    member.sex &&
    member.addressLine1 &&
    member.suburb &&
    member.state &&
    member.postcode &&
    member.phone;

  const requestAllowedMemberVerificationCode = async (email: string) => {
    setLoading(true);
    const request: CreateVerificationCodeRequest = {
      email,
      membership_slug: membershipSlug!
    };
    const response =
      await CorporateMembershipAPI.createVerificationCode(request);
    setLoading(false);
    return response;
  };

  const createAllowedMemberVerificationCode = async () => {
    if (isEmailValid(allowedMemberEmail) && !loading) {
      const response =
        await requestAllowedMemberVerificationCode(allowedMemberEmail);
      if (response.ok) {
        Toast.success(emailSentMessage);
        setSignUpStep("enter_allowed_member_verification_code");
      } else {
        Toast.error("Error creating verification code.");
      }
    }
  };

  const requestLoginVerificationCode = async (email: string) => {
    setLoading(true);
    const response = await UserAPI.getOTP({
      email,
      method: "email"
    });
    setLoading(false);
    return response;
  };

  const signupExistingUser = async (): Promise<boolean> => {
    const response = await CorporateMembershipAPI.signupExistingUser({
      allowed_member_email: allowedMemberEmail,
      preferred_email: preferredEmail,
      membership_slug: membershipSlug!,
      token: allowedMemberVerificationCode
    });
    if (!response.ok) {
      Toast.error("Error signing up corporate member.");
    }
    return response.ok;
  };

  const signupWithExistingAccountOtherBrand = async () => {
    // for users who have an existing account with a different brand
    // this is the last step in the process.
    // Sign them up and redirect them to their brand login page.
    const signUpSuccess = await signupExistingUser();
    if (!signUpSuccess) {
      return;
    }

    redirectTo(
      `${redirectUrl}/?prompt=corporate_member_signed_up&email=${encodeURIComponent(
        preferredEmail
      )}`
    );
  };

  const continueWithExistingAccount = async () => {
    if (redirectUrl) {
      await signupWithExistingAccountOtherBrand();
      return;
    }
    const response = await requestLoginVerificationCode(preferredEmail);
    if (response.ok) {
      Toast.success(emailSentMessage);
      setSignUpStep("enter_login_verification_code");
    } else {
      Toast.error("Something went wrong, please try again [Error 4122]");
    }
  };

  const validateMembershipSlugParam = (membershipSlug: string | null) => {
    if (membershipSlug) {
      setMembershipSlug(membershipSlug);
    } else {
      Toast.error("Membership parameter not present. Please check url.");
    }
  };

  useEffect(() => {
    const searchParams = new SafeURLSearchParams(location.search);
    const membershipSlug = searchParams.get("membership");
    validateMembershipSlugParam(membershipSlug);
  }, []);

  const handleAllowedMemberEmailSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    await createAllowedMemberVerificationCode();
  };

  const requestVerificationCodeCheck = async () => {
    setLoading(true);
    const request: CheckVerificationCodeRequest = {
      email: allowedMemberEmail,
      membership_slug: membershipSlug!,
      token: allowedMemberVerificationCode
    };
    const response =
      await CorporateMembershipAPI.checkVerificationCode(request);
    setLoading(false);
    return response;
  };

  const verifyAllowedMemberCode = async () => {
    Toast.dismiss();
    const response = await requestVerificationCodeCheck();
    if (response.ok) {
      setSignUpStep("enter_preferred_email");
    } else {
      Toast.error("Please enter a valid code.");
    }
  };

  const requestLoginCodeVerify = async () => {
    setLoading(true);
    const response = await UserAPI.validateOTP({
      email: preferredEmail,
      token: loginCode
    });
    setLoading(false);
    return response;
  };

  const verifyLoginCode = async () => {
    // If we get here, the user has an existing account in the db, and it is for this brand
    // We need to verify the code, sign them up, then send them to the home page
    Toast.dismiss();
    const loginResponse = await requestLoginCodeVerify();
    if (!loginResponse.ok) {
      Toast.error("Please enter a valid code.");
      return;
    }

    const signUpSuccess = await signupExistingUser();
    if (!signUpSuccess) {
      Toast.error("Something went wrong, please try again [Error 4121]");
      return;
    }

    const userInfo = await loginResponse.json();
    User.login(userInfo);
    navigate("/");
  };

  const handleUserExists = (redirectUrl: string | null) => {
    if (redirectUrl) {
      setRedirectUrl(redirectUrl);
      const brand = getBrandFromRedirectUrl(redirectUrl);
      if (brand) {
        setUserBrandName(brand.displayname);
      } else {
        Toast.error("Brand not found.");
      }
    } else {
      setUserBrandName(BRAND_NAME);
    }
    setSignUpStep("preferred_email_confirmation");
  };

  const handlePreferredEmailSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    try {
      if (isEmailValid(preferredEmail)) {
        setLoading(true);
        const user = await UserAPI.getExistingUserInfo(preferredEmail);
        if (!user.exists) {
          setSignUpStep("patient_details_form");
        } else {
          handleUserExists(user.redirect);
        }
      }
    } catch (e) {
      Toast.error("Error in getting user info.");
    } finally {
      setLoading(false);
    }
  };

  const convertMemberToPayload = (member: CorporateMember) => ({
    allowed_member_email: allowedMemberEmail,
    preferred_email: preferredEmail,
    membership_slug: membershipSlug!,
    token: allowedMemberVerificationCode,
    dob: member.dob,
    first_name: member.firstName,
    last_name: member.lastName,
    sex_assigned_at_birth: member.sex,
    address_line1: member.addressLine1,
    address_line2: member.addressLine2,
    suburb: member.suburb,
    state: member.state,
    postcode: member.postcode,
    phone: member.phone
  });

  const handlePatientDetailsSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    Toast.dismiss();
    setLoading(true);
    const payload = convertMemberToPayload(member);
    const response =
      await CorporateMembershipAPI.createCorporateMember(payload);
    if (response.ok) {
      const jsonResponse = await response.json();
      User.login(jsonResponse.user_info);
      navigate("/");
    } else {
      Toast.error("Something went wrong, please try again [Error 4123]");
    }
    setLoading(false);
  };

  const handleFormFieldChange = (field: string, value: string) =>
    setMember({ ...member, [field]: value });

  const handleAddressChange = (address: ShippingAddressValues) => {
    setMember({
      ...member,
      addressLine1: address.line1,
      addressLine2: address.line2,
      suburb: address.suburb,
      state: address.state,
      postcode: address.postcode
    });
  };

  const goBackToPreferredEmail = () => {
    setPreferredEmail("");
    setSignUpStep("enter_preferred_email");
    setRedirectUrl("");
    setUserBrandName("");
  };

  const enterAllowedMemberEmailForm = (
    <>
      <div className="prompt-text">
        Please enter the email address where you received your hubPass
        invitation
      </div>
      <form onSubmit={handleAllowedMemberEmailSubmit}>
        <div className="form">
          <label
            className="asterisk email-label"
            css={{ marginTop: "24px" }}
            htmlFor="email"
          >
            Email
          </label>
          <input
            required
            id="email"
            type="email"
            placeholder="Email"
            className="input"
            value={allowedMemberEmail}
            onChange={(e) => setAllowedMemberEmail(e.target.value)}
          />
          {renderButton("Send verification code", !allowedMemberEmail, loading)}
        </div>
      </form>
    </>
  );

  const renderVerifyCodeForm = (
    onVerify: () => void,
    value: string,
    setValue: (value: string) => void,
    requestCode: () => void
  ) => {
    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onVerify();
    };

    return (
      <>
        <div className="prompt-text">Enter your verification code</div>
        <form onSubmit={onFormSubmit}>
          <div className="form">
            <input
              required
              id="code"
              data-testid="input-code"
              type="text"
              className="input input-code"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {renderButton("Verify", value.length < 6, loading)}
          </div>
        </form>
        <div className="contact" css={{ margin: "7px 40px" }}>
          Haven&apos;t received a code?{" "}
          <a className="link" onClick={requestCode}>
            Resend the code{" "}
          </a>
          or{" "}
          <a className="link" href={contactUsLink}>
            Contact us
          </a>
        </div>
      </>
    );
  };

  const enterPreferredEmailForm = (
    <>
      <div className="prompt-text" css={{ margin: "0px 18px" }}>
        Please enter your preferred email address to sign-up
      </div>
      <form onSubmit={handlePreferredEmailSubmit}>
        <div className="form">
          <div className="contact" css={{ marginTop: "24px" }}>
            We recommend using a personal email for account login and receiving
            healthcare communications, rather than your corporate email.
          </div>
          <label
            className="asterisk email-label"
            css={{ marginTop: "16px" }}
            htmlFor="email"
          >
            Email
          </label>
          <input
            required
            id="email"
            type="email"
            placeholder="Email"
            className="input"
            css={{ marginBottom: "0" }}
            value={preferredEmail}
            onChange={(e) => setPreferredEmail(e.target.value)}
          />
          {renderButton("Next", !preferredEmail, loading)}
        </div>
      </form>
    </>
  );

  const PreferredEmailConfirmation = (
    <>
      <div className="prompt-text" css={{ margin: "0px 18px" }}>
        Looks like you have an existing account with us at {userBrandName}!
      </div>
      <div className="prompt-text" css={{ margin: "26px 18px 0px 18px" }}>
        Do you want to link your hubPass with <b>{preferredEmail}</b>?
      </div>
      {renderButton(
        "Yes, sign up with my existing account",
        false,
        loading,
        continueWithExistingAccount
      )}
      {renderButton(
        "No, I'll use a different email",
        false,
        false,
        goBackToPreferredEmail
      )}
    </>
  );

  const patientDetailsForm = (
    <>
      <div className="prompt-text">
        To assist in setting up your account, we will need a few details from
        you
      </div>
      <form onSubmit={handlePatientDetailsSubmit}>
        <div className="form">
          <div className="field">
            <div className="title-column asterisk pb-3">Name</div>
            <div className="value-column">
              <label htmlFor="first-name">First Name</label>
              <input
                required
                id="first-name"
                className="input"
                placeholder="First Name"
                value={member.firstName}
                onChange={(e) =>
                  handleFormFieldChange("firstName", e.target.value)
                }
              />
              <label htmlFor="last-name" css={{ marginTop: "16px" }}>
                Last Name
              </label>
              <input
                required
                id="last-name"
                className="input"
                placeholder="Last Name"
                value={member.lastName}
                onChange={(e) =>
                  handleFormFieldChange("lastName", e.target.value)
                }
              />
            </div>
          </div>
          <div className="field">
            <div className="title-column">
              <div className="asterisk">Date of birth</div>
              <div className="subtitle">
                We need to make sure you are 18 years or older
              </div>
            </div>
            <div className="value-column full-width">
              <QuestionnaireDatePicker
                info={{
                  dob_year: member.dob.split("-")[0],
                  dob_month: member.dob.split("-")[1],
                  dob_day: member.dob.split("-")[2],
                  onChange: (value) => handleFormFieldChange("dob", value)
                }}
                currentQuestion={{ type: "dob" }}
              />
            </div>
          </div>
          <div className="field">
            <div className="title-column">
              <div className="asterisk">Sex assigned at birth</div>
              <div className="subtitle">For a more personalised experience</div>
            </div>
            <div className="value-column sex">
              <input
                type="radio"
                id="male"
                className="radio"
                value="male"
                checked={member.sex === "male"}
                onChange={(e) => handleFormFieldChange("sex", e.target.value)}
              />
              <label htmlFor="male">Male</label>
              <input
                type="radio"
                id="female"
                className="radio"
                value="female"
                css={{ marginLeft: "30px" }}
                checked={member.sex === "female"}
                onChange={(e) => handleFormFieldChange("sex", e.target.value)}
              />
              <label htmlFor="female">Female</label>
            </div>
          </div>
          <div className="field">
            <div className="title-column">
              <div className="asterisk">Address</div>
              <div className="subtitle">
                Setup for delivery and verification
              </div>
            </div>
            <div className="value-column address">
              <Address
                onChange={handleAddressChange}
                value={{
                  line1: member.addressLine1,
                  line2: member.addressLine2,
                  suburb: member.suburb,
                  state: member.state,
                  postcode: member.postcode
                }}
              />
            </div>
          </div>
          <div className="field">
            <div className="title-column">
              <div className="asterisk">Mobile Number</div>
              <div className="subtitle">
                We can use your phone number to send your verification code in
                case you can’t access your email
              </div>
            </div>
            <div className="value-column">
              <label htmlFor="phone_input">Mobile Number</label>
              <PhoneInput
                className="phone-input"
                onChange={(phone) =>
                  handleFormFieldChange("phone", phone || "")
                }
              />
            </div>
          </div>
          <div className="terms">
            By clicking “Create account” you agree to our{" "}
            <a href={termsLink} rel="noreferrer" target="_blank">
              Terms & Conditions
            </a>
            ,{" "}
            <a href={privacyLink} rel="noreferrer" target="_blank">
              Privacy Policy
            </a>{" "}
            and confirm you are over the age of 18.
          </div>
          {renderButton(
            "Create account",
            !isPatientDetailsFormValid(),
            loading
          )}
        </div>
      </form>
    </>
  );

  const verifyAllowedMemberCodeForm = renderVerifyCodeForm(
    verifyAllowedMemberCode,
    allowedMemberVerificationCode,
    setAllowedMemberVerificationCode,
    createAllowedMemberVerificationCode
  );

  const verifyLoginCodeForm = renderVerifyCodeForm(
    verifyLoginCode,
    loginCode,
    setLoginCode,
    () => requestLoginVerificationCode(preferredEmail)
  );

  const renderForm = () => {
    const forms: Record<SignUpStep, React.ReactNode> = {
      enter_allowed_member_email: enterAllowedMemberEmailForm,
      enter_allowed_member_verification_code: verifyAllowedMemberCodeForm,
      enter_preferred_email: enterPreferredEmailForm,
      preferred_email_confirmation: PreferredEmailConfirmation,
      enter_login_verification_code: verifyLoginCodeForm,
      patient_details_form: patientDetailsForm
    };
    return forms[signUpStep];
  };

  return (
    <div className="corporate">
      <div className={signUpStep}>
        <div className="circle-wrapper">
          <div className="container">
            <div className="heading">
              <div className="welcome">Welcome to</div>
              <div className="logo">
                <LogoBrand width="100%" />
              </div>
            </div>
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
};
