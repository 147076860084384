import { useEffect, useState } from "react";
import moment from "moment";
import { Button, Spinner, Toast } from "@mh/components";
import { PatientAPI, UserMembership } from "@mh/api";
import { ProfileCard } from "./ProfileCard";

interface MembershipStatus {
  color: "success" | "danger" | "warning";
  title: string;
  date?: string;
}

type MembershipAction =
  | "reactivate"
  | "cancel_at_period_end"
  | "get_membership_invoice_url";

export const Membership = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [membership, setMembership] = useState<UserMembership | null>(null);

  useEffect(() => {
    const fetchUserMembership = async () => {
      const memberships = await PatientAPI.getUserMembershipStatus();
      const activeOrLapsedMemberships = memberships.filter(
        (membership) =>
          membership.membership.slug === "membership" &&
          (membership.status === "active" || membership.status === "lapsed")
      );
      if (activeOrLapsedMemberships.length > 0) {
        setMembership(activeOrLapsedMemberships[0]);
      }
    };
    fetchUserMembership();
  }, []);

  if (!membership) {
    return;
  }

  const getMembershipStatus = (
    membership: UserMembership
  ): MembershipStatus => {
    if (membership.status === "active" && membership.cancel_at) {
      return {
        color: "danger",
        title: "Cancelled",
        date: moment(membership.cancel_at).format("DD/MM/YYYY")
      };
    } else if (membership.status === "active") {
      return {
        color: "success",
        title: "Active",
        date: moment(membership.current_period_end).format("DD/MM/YYYY")
      };
    }
    return {
      color: "warning",
      title: "Lapsed"
    };
  };

  const membershipChangeHandler = async (action: MembershipAction) => {
    setIsLoading(true);
    if (action === "get_membership_invoice_url") {
      try {
        const response = await PatientAPI.getUserMembershipInvoiceUrl(
          membership.id
        );
        if (response.hosted_invoice_url !== "") {
          window.open(response.hosted_invoice_url, "_blank");
        }
      } catch (e: any) {
        Toast.error("Failed to activate membership.");
      }
    } else {
      try {
        const response = await PatientAPI.changeUserMembership(
          membership.id,
          action
        );
        setMembership(response);
      } catch (e: any) {
        Toast.error("Failed to change membership status.");
      }
    }
    setIsLoading(false);
  };

  const renderMembershipBadge = (
    color: "success" | "danger" | "warning",
    title: string
  ) => (
    <div
      css={(theme) => ({
        backgroundColor: theme.color[color],
        borderRadius: "25px",
        padding: "2px 10px",
        fontWeight: "700",
        fontSize: "15px",
        width: "100%",
        color: "#ffffff",
        marginTop: "-7px"
      })}
    >
      {title}
    </div>
  );

  const renderButton = (text: string, action: MembershipAction) => (
    <div>
      <Button
        fullWidth
        onClick={() => membershipChangeHandler(action)}
        disabled={isLoading}
        css={{ minWidth: "200px", display: "flex", justifyContent: "center" }}
      >
        {isLoading ? <Spinner size="md" /> : text}
      </Button>
    </div>
  );

  const status = getMembershipStatus(membership);

  return (
    <ProfileCard>
      <ProfileCard.Header
        title="hubPass"
        style={(theme) => ({
          justifyContent: "space-between",
          [theme.mq.md]: {
            justifyContent: "start",
            gap: "16px"
          }
        })}
      >
        {renderMembershipBadge(status.color, status.title)}
      </ProfileCard.Header>
      <div
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          [theme.mq.md]: {
            flexDirection: "row",
            justifyContent: "space-between"
          }
        })}
      >
        {status.title === "Active" ? (
          <>
            <div
              css={(theme) => ({
                [theme.mq.md]: {
                  display: "flex",
                  gap: "30px"
                }
              })}
            >
              <label
                className="text-nowrap form-label"
                css={{ fontSize: "14px", fontWeight: "600" }}
              >
                Next payment date
              </label>
              <div>{status.date}</div>
            </div>
            {renderButton("Cancel my hubPass", "cancel_at_period_end")}
          </>
        ) : status.title === "Cancelled" ? (
          <>
            <span>
              Your membership will expire on {status.date}. Please note you will
              have access to hubPass benefits up until the expiry date.
            </span>
            {renderButton("Reactivate my hubPass", "reactivate")}
          </>
        ) : status.title === "Lapsed" ? (
          <div css={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div>
              We encountered an issue while processing your payment for your
              recent order. This could be due to insufficient funds or a problem
              with your payment method. To reactivate your membership please
              verify your payment details and try again.
            </div>
            <div
              css={(theme) => ({
                [theme.mq.md]: { alignSelf: "end" }
              })}
            >
              {renderButton(
                "Reactivate my hubPass",
                "get_membership_invoice_url"
              )}
            </div>
          </div>
        ) : null}
      </div>
    </ProfileCard>
  );
};
