import { useCallback, useContext, useEffect, useState } from "react";
import { PatientAPI, PatientContext, PatientQuestionnaire } from "@mh/api";
import {
  ActiveTreatmentCard,
  BannerList,
  DisplayHeading,
  Spinner
} from "@mh/components";
import { BRAND } from "@mh/core";
import {
  getRecommendedProductsWithPrices,
  OTCProducts
} from "@mh/otc-products";

import { TreatmentCard } from "../../components/TreatmentCard/TreatmentCard";
import BCs from "../../components/common/BannerContents";
import { useCheckMobileScreen, useMembership } from "../../../packages/hooks";

export const Treatments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [questionnaires, setQuestionnaires] = useState<PatientQuestionnaire[]>(
    []
  );

  const isMobile = useCheckMobileScreen();
  const patientContext = useContext(PatientContext);
  const membershipsStatus = useMembership();

  const loadQuestionnaires = async () => {
    setIsLoading(true);
    setQuestionnaires(
      await PatientAPI.loadPatientQuestionnaires({ brand: BRAND })
    );
    setIsLoading(false);
  };

  /**
   * Loads the OTC products for a patient's active categories
   */
  const loadOTCProducts = useCallback(
    async () =>
      getRecommendedProductsWithPrices(
        questionnaires.map((questionnaire) => questionnaire.category.id)
      ),
    [questionnaires]
  );

  useEffect(() => {
    loadQuestionnaires();
  }, []);

  useEffect(() => {
    patientContext.get();
  }, []);

  return (
    <>
      <div>
        <DisplayHeading>Active treatments</DisplayHeading>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginTop: "24px"
          }}
        >
          {isLoading && (
            <div
              css={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "24px 0px"
              }}
            >
              <Spinner />
            </div>
          )}
          {!isLoading &&
            questionnaires.map((questionnaire) => (
              <ActiveTreatmentCard
                key={questionnaire.id}
                patient={patientContext.data || undefined}
                initialQuestionnaire={questionnaire}
                onChange={loadQuestionnaires}
                membershipsStatus={membershipsStatus}
              />
            ))}
          {!isLoading && questionnaires.length === 0 && (
            <div className="col-12">
              <div className="outter-box layout-2">
                <div className="header row">
                  <div className="name left col-12 text-center">
                    <h2 className="frequent-font-color">
                      No Treatments & Medications
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {questionnaires.length > 0 && (
        <div
          css={{
            margin: "32px 0px",
            "@media (max-width: 1570px)": {
              width: "78vw"
            },
            "@media (max-width: 1450px)": {
              width: "76vw"
            },
            "@media (max-width: 1300px)": {
              width: "74vw"
            },
            "@media (max-width: 1200px)": {
              width: "72vw"
            },
            "@media (max-width: 1100px)": {
              width: "68vw"
            },
            "@media (max-width: 990px)": {
              width: "65vw"
            },
            "@media (max-width: 880px)": {
              width: "62vw"
            },
            "@media (max-width: 767px)": {
              width: "93vw"
            }
          }}
        >
          {/* @ts-ignore */}
          <OTCProducts loadProducts={loadOTCProducts} scrollable />
        </div>
      )}
      <div css={{ margin: "32px 0px" }}>
        <BannerList
          banners={BCs?.filter(
            (banner) =>
              banner.pages.includes("treatment") &&
              (!isMobile ? !banner.mobileOnly : banner) // if desktop: show banners without mobileOnly, if mobile: show all
          )}
        />
      </div>
      <TreatmentCard />
    </>
  );
};
