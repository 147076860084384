import { Interpolation, Theme } from "@emotion/react";
import { PropsWithChildren } from "react";
import { BRAND } from "@mh/core";

interface HeaderProps {
  /** If true, will stick the header to the top of the page on scroll. */
  sticky?: boolean;
}

interface ContentProps {
  contentCss?: Interpolation<Theme>;
}

export const Header = ({
  children,
  sticky
}: PropsWithChildren<HeaderProps>) => (
  <div
    css={[
      (theme) => ({
        margin: "0px",
        padding: "24px 24px 20px",
        color: "black",
        backgroundColor: "#F8F8F8",
        boxShadow: "0px 3px 5px #ADADAD",
        borderRadius: "0px",
        width: "100%",
        [theme.mq.md]: {
          padding: "42px 32px 20px",
          backgroundColor: "white",
          boxShadow: "0px 20px 10px -20px rgba(0, 0, 0, 0.25)"
        }
      }),
      sticky && {
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 1
      }
    ]}
  >
    {children}
  </div>
);

export const SubHeader = ({ children }: PropsWithChildren) => (
  <div css={{ position: "relative" }}>
    <div
      css={(theme) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        color: "white",
        backgroundColor: `${
          BRAND === "hubhealth"
            ? `${theme.color.primaryLight}`
            : `${theme.color.primary}`
        }`,
        margin: "0px 24px",
        padding: "16px 16px 10px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "0px 0px 8px 8px",
        position: "relative"
      })}
    >
      {children}
    </div>
  </div>
);

const Content = ({ children, contentCss }: PropsWithChildren<ContentProps>) => (
  <div
    css={[
      (theme) => ({
        margin: "24px auto",
        width: "100%",
        maxWidth: "1320px",
        padding: "0px 16px",
        [theme.mq.md]: {
          padding: "0px 32px"
        }
      }),
      contentCss
    ]}
  >
    {children}
  </div>
);

interface PageContainerProps {
  isNewCheckout?: boolean;
}

export const PageContainer = ({
  children,
  isNewCheckout
}: PropsWithChildren<PageContainerProps>) => (
  <div
    css={(theme) => ({
      backgroundColor: isNewCheckout ? "#FFF" : theme.color.backgroundLight,
      margin: "0px auto",
      width: "100%",
      maxWidth: "1542px"
    })}
  >
    {children}
  </div>
);

PageContainer.Header = Header;
PageContainer.SubHeader = SubHeader;
PageContainer.Content = Content;
