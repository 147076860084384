import { type HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  isSelected: boolean;
}

export const SelectableBox = ({ isSelected, children, ...rest }: Props) => (
  <div
    css={(theme) => ({
      display: "flex",
      padding: "16px",
      gap: "8px",
      border: `2px solid ${isSelected ? theme.color.primary : "transparent"}`, // Highlight the selected item
      borderRadius: "8px",
      backgroundColor: isSelected
        ? theme.color.background
        : theme.color.backgroundFaint,
      cursor: "pointer"
    })}
    {...rest}
  >
    {children}
  </div>
);
