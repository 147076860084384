import React from "react";
import { IconQuestionCircle, IconInformationCircle } from "../icons";

interface TooltipProps {
  text: string;
  maxWidth?: string;
  infoIcon?: boolean;
  iconWidth?: string;
  iconHeight?: string;
  iconColor?: string;
}

const Tooltip = (props: TooltipProps): JSX.Element => {
  const [visible, setVisible] = React.useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <span
      onMouseEnter={show}
      onMouseLeave={hide}
      onTouchStart={show}
      onTouchEnd={hide}
      style={{ marginLeft: "5px", fontSize: "18px" }}
    >
      {props.infoIcon ? (
        <IconInformationCircle
          width={props.iconWidth ? props.iconWidth : ""}
          css={(theme) => ({ color: props.iconColor ?? theme.color.primary })}
        />
      ) : (
        <IconQuestionCircle css={(theme) => ({ color: theme.color.primary })} />
      )}
      {visible && (
        <div
          css={(theme) => ({
            position: "absolute",
            backgroundColor: theme.color.secondary,
            border: "1px solid #ddd",
            padding: "10px",
            zIndex: 11,
            borderRadius: "4px",
            fontSize: "12px",
            color: "white",
            left: "15px",
            right: "15px",
            [theme.mq.sm]: {
              fontSize: "14px",
              color: "black",
              backgroundColor: "white",
              left: "unset",
              right: "unset",
              maxWidth: props?.maxWidth ?? "unset"
            }
          })}
        >
          {props.text}
        </div>
      )}
    </span>
  );
};

export default Tooltip;
