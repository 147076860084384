import { useContext } from "react";
import { Patient, PatientContext } from "@mh/api";
import { Validation } from "@mh/core";

import { ProfileForm } from "./ProfileForm";

type DvaValues = Pick<Patient, "dva_number">;

export interface DvaProps {
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
}

export const DVA = ({ isEditingCallback }: DvaProps) => {
  const { isFetching, data: patient, update } = useContext(PatientContext);

  return (
    <ProfileForm<DvaValues>
      title="DVA"
      id="dva"
      initialValues={{
        dva_number: patient?.dva_number || ""
      }}
      isFetching={isFetching}
      onSave={async (values) => {
        await update({
          dva_number: values.dva_number
        });
      }}
      validate={(values) => ({
        dva_number: Validation.dva().validate(values.dva_number)
      })}
      isEditingCallback={isEditingCallback}
    >
      {({ values, setValues, error, isEditing, id }) => (
        <>
          <ProfileForm.Control
            label="DVA number"
            data-testid={`${id}-dvanumber`}
            disabled={isFetching}
            error={error?.dva_number}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({ ...values, dva_number: e.currentTarget.value })
            }
            value={values.dva_number}
          />
        </>
      )}
    </ProfileForm>
  );
};
