let AddUserquestionsnewv2 = [
  {
    id: 105,
    title: "What was your sex assigned at birth?",
    type: "button",
    ga_id: "A0006",
    questionType: "common",
    hideQuestion: "isGenderProvided",
    pageTotal: 3,
    currentPage: 1,
    options: [
      {
        id: 100051,
        title: "Male",
        products: ["contraceptive pill"],
        end: true,
        message:
          "Unfortunately this treatment is not suitable. We recommend that you see your GP physically to discuss options."
      },
      {
        id: 100052,
        title: "Female",
        products: ["ed", "pe"],
        end: true,
        message:
          "Unfortunately this treatment is not suitable. We recommend that you see your GP physically to discuss options."
      }
    ]
  },
  {
    id: 106,
    title: "What is your height in cms?",
    pageTotal: 6,
    currentPage: 1,
    type: "input",
    ga_id: "A0007",
    questionType: "common",
    inputtype: "float",
    input_type: "number",
    placeholder: "cm",
    range: { min: 10, max: 300 }
  },
  {
    id: 107,
    ga_id: "A0008",
    title: "What is your weight in kilograms?",
    pageTotal: 6,
    currentPage: 2,
    type: "input",
    questionType: "common",
    inputtype: "float",
    input_type: "number",
    placeholder: "kg",
    range: { min: 10, max: 300 }
  }
];

export default AddUserquestionsnewv2;
