import { useState } from "react";
import { type StripeElements } from "@stripe/stripe-js";
import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import { IconMasterCard } from "@mh/checkout";

import { EditableProfileCard } from "../../../components/forms";

interface Props {
  last4: string | null;
  isDisabled: boolean;
  onUpdate: (elements: StripeElements) => Promise<void>;
}

export const UpdatePaymentMethod = ({ last4, isDisabled, onUpdate }: Props) => {
  const elements = useElements();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <EditableProfileCard
      id="card"
      title="Payment Details"
      disabled={isDisabled}
      isEditing={isEditing}
      onEdit={() => {
        setIsEditing(true);
      }}
      onSave={async () => {
        if (!elements) {
          // Can't update payment method with no element to type into!!!
          return;
        }

        const { error } = await elements.submit();

        if (error) {
          console.warn("Invalid payment data");
          return;
        }

        // The parent <CheckoutStep> cannot call useElements, so pass Elements to it via a callback
        await onUpdate(elements);
        setIsEditing(false);
      }}
      onCancel={() => {
        setIsEditing(false);
      }}
    >
      {({ isEditing }) => (
        <>
          {!isEditing && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                gap: "8px"
              }}
            >
              <IconMasterCard height={32} width={50} />
              <span>{last4 ? `Card ending in ${last4}` : "-"}</span>
            </div>
          )}
          {isEditing && (
            <PaymentElement
              options={{
                wallets: { googlePay: "auto", applePay: "auto" }
              }}
            />
          )}
        </>
      )}
    </EditableProfileCard>
  );
};
