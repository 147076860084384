import { HorizontalDivider } from "@mh/components";

export interface ConfirmationFormProps {
  questionsChecked: boolean;
  readChecked: boolean;
  onChangeQuestions: (checked: boolean) => void;
  onChangeRead: (checked: boolean) => void;
}

export const ConfirmationForm = ({
  questionsChecked,
  readChecked,
  onChangeQuestions,
  onChangeRead
}: ConfirmationFormProps) => (
  <div
    css={{
      display: "flex",
      flexFlow: "column",
      gap: "8px"
    }}
  >
    <div
      css={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "start",
        gap: "8px",
        cursor: "pointer"
      }}
      onClick={() => onChangeRead(!readChecked)}
    >
      <input
        type="checkbox"
        checked={readChecked}
        readOnly
        css={(theme) => ({
          accentColor: theme.color.primary,
          margin: "5px 0px"
        })}
      />
      <span css={{ flex: 1 }}>
        I have read and understood all of the above information
      </span>
    </div>
    <div
      css={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "start",
        gap: "8px",
        cursor: "pointer"
      }}
      onClick={() => onChangeQuestions(!questionsChecked)}
    >
      <input
        type="checkbox"
        checked={questionsChecked}
        readOnly
        css={(theme) => ({
          accentColor: theme.color.primary,
          margin: "5px 0px"
        })}
      />
      <span css={{ flex: 1 }}>I do not have any other questions</span>
    </div>
    <span css={{ fontSize: "14px" }}>
      If you have any questions or concerns about this Treatment Plan, please
      contact your Medical Team.
    </span>
    <HorizontalDivider />
    <span css={{ fontSize: "14px" }}>
      *Always refer to your medication label for your personalised directions.
      If you have any questions or concerns, please contact your doctor or
      pharmacist via the portal.
    </span>
  </div>
);
