import { ComponentPropsWithoutRef } from "react";

export const IconCaretOutlineRight = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0156 6.50537C10.7344 6.22412 10.2188 6.22412 9.9375 6.50537C9.65625 6.78662 9.65625 7.30225 9.9375 7.5835L14.6719 12.271L9.9375 17.0054C9.65625 17.2866 9.65625 17.8022 9.9375 18.0835C10.2188 18.3647 10.7344 18.3647 11.0156 18.0835L16.2656 12.8335C16.4062 12.6929 16.5 12.5054 16.5 12.271C16.5 12.0835 16.4062 11.896 16.2656 11.7554L11.0156 6.50537ZM12 0.270996C5.34375 0.270996 0 5.66162 0 12.271C0 18.9272 5.34375 24.271 12 24.271C18.6094 24.271 24 18.9272 24 12.271C24 5.66162 18.6094 0.270996 12 0.270996ZM12 22.771C6.1875 22.771 1.5 18.0835 1.5 12.271C1.5 6.50537 6.1875 1.771 12 1.771C17.7656 1.771 22.5 6.50537 22.5 12.271C22.5 18.0835 17.7656 22.771 12 22.771Z"
      fill="currentColor"
    />
  </svg>
);
