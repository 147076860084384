import { keyframes } from "@emotion/react";
import { Theme } from "../theme";
import { Size } from "../types";

interface SpinnerProps {
  /** Size of the spinner. */
  size?: Size;
  /** Variant (color) of the spinner. */
  variant?: keyof Theme["color"];
}

const SIZE_IN_PIXELS: Record<Size, `${number}px`> = {
  sm: "16px",
  md: "24px",
  lg: "32px"
};

const spinner = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = ({ size = "md", variant = "primary" }: SpinnerProps) => (
  <div
    css={(theme) => ({
      width: SIZE_IN_PIXELS[size],
      height: SIZE_IN_PIXELS[size],
      borderRadius: "50%",
      borderTop: `2px solid ${theme.color[variant]}`,
      borderRight: "2px solid transparent",
      animation: `${spinner} .6s linear infinite`
    })}
  />
);
