import { ReactNode } from "react";
import { User } from "@mh/api";
import { QueueAppointmentTime } from "@mh/components";
import { nibInternationalSearchParam } from "@mh/core/src/constants";
import { PaymentHeaderBlock } from "./HeaderBlock";
import { PhoneBlock } from "./PhoneBlock";
import { EmailBlock } from "./EmailBlock";
import { FirstNameBlock, LastNameBlock } from "./NameBlock";

/** optional parameters */
interface PaymentDetailBlockOptionalProps {
  accountAttrClass: string | undefined; // input label class
}

interface PaymentDetailBlockProps {
  /** payment section base attribute list */
  signup: {
    centerClass?: string | undefined; // payment section outer class
    first?: string | undefined; // order-first for payment section outer class
    signupBlockClass?: string | undefined; // payment section inner class
    mobileClass?: string; // mobile input field class
    signupHeaderClass?: string | undefined; // payment section header title class
    signupHeaderDefault?: string; // default header title text
    /** header title text by category */
    signupHeader?: [
      {
        product: string;
        title: string;
        subTitle?: string;
      }
    ];
    /** header sub title text by category */
    loginHeader?: [
      {
        product: string;
        title: string;
        subTitle?: string;
      }
    ];
  };
  /** Product & Category information */
  productenv: {
    noPromo?: boolean; // hide promo
    catId?: number; // category id
    price?: number; // product price
    code?: string; // category code
    slug?: string; // category slug
    noLogin?: boolean; // check this category is for no login
  };
  /** current question information */
  currentQuestion: {
    type: string; // question type
    btLogged?: string; // payment section top class
    btCenter?: string; // payment section outer class (same as signup?.centerClass)
    noPromo?: boolean; // hide promo
  };
  info: {
    fromParam?: string; // extra parameter (nib international only)
    isValidParam?: string; // extra parameter check (nib international only)
    iseventpromo?: boolean; // check it is 100% consult discount
    childRef: any; // children dom element
    ph: string | undefined; // show mobile field
    from?: string; // brand
  };
  /** credit card section */
  reactCard: ReactNode;
  /** promo code section */
  reactPromo: ReactNode;
  /** phone section */
  phoneField: ReactNode;
  /** next button section */
  nextButtonBlock: any;
  /** optional parameters */
  opts: PaymentDetailBlockOptionalProps;
  /** if it is from repeatquestionnaire */
  isRepeat?: boolean;
}

export const PaymentDetailBlock = (props: PaymentDetailBlockProps) => {
  if (
    (props.currentQuestion.type === "bypass" ||
      (props.info?.fromParam && props.info?.isValidParam)) &&
    User.loggedIn() &&
    !props?.signup?.first
  ) {
    return (
      <div>
        <div className="row">
          <div className="col-md-1"></div>
          {props.nextButtonBlock}
          <div className="col-md-1"></div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`col-12 bottom-contents ${
        props.signup?.first ? "order-first" : ""
      }`}
    >
      <div
        className={`${
          !props.info?.fromParam || !props.info?.isValidParam ? "row" : ""
        } ${
          props.currentQuestion.type === "bypass"
            ? ""
            : props.currentQuestion.btLogged
            ? props.currentQuestion.btLogged
            : "bggray"
        } newbtblock bblr-08 bbrr-08`}
      >
        {(!props.info?.fromParam || !props.info?.isValidParam) && (
          <div className="col-lg-2"></div>
        )}
        <div
          className={`${
            props.info?.fromParam && props.info?.isValidParam ? "" : "col-12"
          } ${
            props.currentQuestion.type === "bypass"
              ? User.loggedIn()
                ? "col-lg-8"
                : ""
              : props.info?.fromParam && props.info?.isValidParam
              ? ""
              : props.signup?.centerClass
              ? props.signup?.centerClass
              : props.currentQuestion?.btCenter
              ? props.currentQuestion?.btCenter
              : "col-lg-10"
          } ${props.info?.fromParam && props.info?.isValidParam ? "" : "pb-3"}`}
        >
          {!props?.isRepeat && props.reactCard}
          {props.reactPromo}
          <div>
            {(!User.loggedIn() ||
              (User.loggedIn() && props.signup?.loginHeader)) && (
              <div
                className={`row signup-block ${props.signup?.signupBlockClass}`}
              >
                {(props.signup?.signupHeader ||
                  (User.loggedIn() && props.signup?.loginHeader)) && (
                  <PaymentHeaderBlock
                    signupHeaderClass={props.signup?.signupHeaderClass}
                    signupHeaderDefault={props.signup?.signupHeaderDefault}
                    signupHeader={
                      User.loggedIn() && props?.signup?.loginHeader
                        ? props?.signup?.loginHeader
                        : props.signup?.signupHeader
                    }
                    code={props.productenv.code}
                  />
                )}
                {!props.info?.ph && (
                  <PhoneBlock
                    mobileClass={props.signup?.mobileClass}
                    type={props.currentQuestion?.type}
                    accountAttrClass={props.opts?.accountAttrClass}
                    phoneField={props.phoneField}
                  />
                )}
              </div>
            )}
            {props.productenv?.noLogin && (
              <div className="row">
                <EmailBlock
                  type={props.currentQuestion?.type}
                  accountAttrClass={props.opts?.accountAttrClass}
                />
                <FirstNameBlock
                  type={props.currentQuestion?.type}
                  accountAttrClass={props.opts?.accountAttrClass}
                />
                <LastNameBlock
                  type={props.currentQuestion?.type}
                  accountAttrClass={props.opts?.accountAttrClass}
                />
              </div>
            )}
            {props.info?.fromParam === nibInternationalSearchParam &&
              props.productenv.slug === "telehealth-consult" && (
                <QueueAppointmentTime />
              )}
            <div
              className={`${
                props.info?.fromParam && props.info?.isValidParam
                  ? "bggray ps-3 pe-3 pt-0 fromParam"
                  : ""
              }`}
            >
              {props.nextButtonBlock}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
