import { Button, Flex } from "@mh/components";

interface QuestionnaireIHIProps {
  ihiSelectedStatus: string | undefined;
  ihi: number | undefined;
  next?: (e: any) => void;
  noIhiSet: (noIhi: boolean) => void;
  ihiSelectedStatusSet: (ihiSelectedStatus: string) => void;
}

export const QuestionnaireIHI = (props: QuestionnaireIHIProps) => (
  <Flex
    flexDirection="column"
    css={(theme) => ({
      maxWidth: "342px",
      width: "100% !important",
      padding: "8px !important",
      marginLeft: "auto",
      marginRight: "auto",
      lineHeight: "normal",
      minHeight: "35px !important",
      height: "unset !important",
      [theme.mq.md]: {
        maxWidth: "431px !important",
        width: "100% !important"
      },
      [theme.mq.lg]: {
        width: "431px !important",
        maxWidth: "unset  !important"
      }
    })}
  >
    <Flex
      flexDirection="column"
      css={(theme) => ({
        marginBottom: props.ihiSelectedStatus === "yes" ? "0px" : "24px",
        [theme.mq.sm]: {
          marginBottom: props.ihiSelectedStatus === "yes" ? "8px" : "32px"
        }
      })}
    >
      <Button
        fullWidth
        variant="primary"
        id="yes"
        onClick={(e) => {
          e.preventDefault();
          props.ihiSelectedStatusSet("yes");
          props.noIhiSet(false);
        }}
        css={(theme) => ({
          borderColor: theme.color.primary,
          background:
            props.ihiSelectedStatus === "yes" ? theme.color.primary : "white",
          color: props.ihiSelectedStatus === "yes" ? "white" : "black",
          "&:hover, &:active, &.active": {
            color: "#fff !important",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
          }
        })}
      >
        Yes
      </Button>
      {props.ihiSelectedStatus === "yes" && (
        <Flex
          flexDirection="column"
          css={(theme) => ({
            paddingTop: "24px",
            [theme.mq.sm]: {
              paddingTop: "32px"
            }
          })}
        >
          <div
            className="asterisk"
            css={{
              lineHeight: 1,
              fontSize: "14px",
              fontWeight: "500",
              paddingBottom: "4px"
            }}
          >
            Enter IHI Number
          </div>
          <input
            className="noArrowInNumberInput"
            key={"ihiSet"}
            name="ihiNumber"
            placeholder="IHI Number"
            id="ihiNumber"
            defaultValue={props.ihi}
            type="number"
            pattern="\d*"
            onKeyDown={props.next}
            css={(theme) => ({
              height: "37px",
              borderRadius: "12px",
              lineHeight: "135% !important",
              padding: "8px",
              fontSize: "14px",
              border:
                "0.5px solid var(--components-divider-divider-background, #737373)",
              marginBottom: "16px",
              [theme.mq.sm]: {
                marginBottom: "24px"
              }
            })}
          />
        </Flex>
      )}
    </Flex>
    <Flex
      flexDirection="column"
      css={(theme) => ({
        marginBottom: "24px",
        [theme.mq.sm]: {
          marginBottom: "32px"
        }
      })}
    >
      <Button
        fullWidth
        variant="primary"
        id="noIhi"
        onClick={(e) => {
          e.preventDefault();
          props.ihiSelectedStatusSet("no");
          props.noIhiSet(true);
        }}
        css={(theme) => ({
          borderColor: theme.color.primary,
          background:
            props.ihiSelectedStatus === "no" ? theme.color.primary : "white",
          color: props.ihiSelectedStatus === "no" ? "white" : "black",
          "&:hover, &:active, &.active": {
            color: "#fff !important",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
          }
        })}
      >
        No
      </Button>
    </Flex>
  </Flex>
);
