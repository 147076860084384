import { FC } from "react";
import { asyncIsSuccess } from "@mh/api";
import { Button, IconCart, NotificationOverlay } from "@mh/components";
import { useBasket } from "../../hooks";

interface Props {
  onClick: () => void;
}

const BasketCart: FC<Props> = (props) => {
  const basket = useBasket();

  // If the cart has 1 apple and 3 bananas, the totalQuantity will be 4
  // If the basket's lines have not resolved yet, totalQuantity will be 0
  const totalQuantity = asyncIsSuccess(basket.lines)
    ? basket.lines.data.reduce(
        (total, basketLine) => total + basketLine.quantity,
        0
      )
    : 0;

  return (
    <Button data-testid="basket-cart-icon" variant="primary-plain" {...props}>
      <NotificationOverlay
        notification={totalQuantity > 0 ? totalQuantity.toString() : false}
      >
        <IconCart height="16px" />
      </NotificationOverlay>
    </Button>
  );
};

export default BasketCart;
