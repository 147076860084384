import { BRAND_HELP_EMAIL } from "@mh/core";

export const ContactUs = () => (
  <div className="mt-2 small notes">
    Need assistance?{" "}
    <a
      className="link"
      href={`mailto:${BRAND_HELP_EMAIL}?subject=Trouble logging in`}
    >
      Contact us
    </a>
  </div>
);
