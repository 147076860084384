import { type ComponentPropsWithoutRef } from "react";

export const IconShop = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 6.76294C0 6.57544 0.03125 6.38794 0.125 6.20044L2.5625 1.76294C2.8125 1.26294 3.3125 0.950439 3.875 0.950439H16.0938C16.6562 0.950439 17.1562 1.26294 17.4062 1.76294L19.8438 6.20044C19.9375 6.38794 20 6.57544 20 6.76294C20 7.41919 19.4375 7.95044 18.7812 7.95044H18V16.4504C18 16.7317 17.75 16.9504 17.5 16.9504C17.2188 16.9504 17 16.7317 17 16.4504V7.95044H3V11.9504H11V9.45044C11 9.20044 11.2188 8.95044 11.5 8.95044C11.75 8.95044 12 9.20044 12 9.45044V15.4504C12 16.2942 11.3125 16.9504 10.5 16.9504H3.5C2.65625 16.9504 2 16.2942 2 15.4504V7.95044H1.1875C0.53125 7.95044 0 7.41919 0 6.76294ZM18.7812 6.95044C18.9062 6.95044 19 6.88794 19 6.76294C19 6.73169 18.9688 6.70044 18.9688 6.66919L16.5312 2.23169C16.4375 2.07544 16.2812 1.95044 16.0938 1.95044H3.875C3.6875 1.95044 3.53125 2.07544 3.4375 2.23169L1 6.66919C1 6.70044 1 6.73169 1 6.76294C1 6.88794 1.0625 6.95044 1.1875 6.95044H18.7812ZM3.5 15.9504H10.5C10.75 15.9504 11 15.7317 11 15.4504V12.9504H3V15.4504C3 15.7317 3.21875 15.9504 3.5 15.9504Z"
      fill="currentColor"
    />
  </svg>
);
