import { useEffect, useState } from "react";
import { API } from "@mh/api";

import { ProfileForm } from "./ProfileForm";
import { ProfileCard } from "./ProfileCard";
import { Toast } from "@mh/components";
import { Validation } from "@mh/core";

export interface IndividualHealthcareIdentifierValues {
  number: string;
  record_status: string;
  number_status: string;
}

export interface IndividualHealthcareIdentifierProps {
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
}

export const IndividualHealthcareIdentifier = ({
  isEditingCallback
}: IndividualHealthcareIdentifierProps) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [ihi, setIhi] = useState<{
    number?: string;
    number_status?: string;
    record_status?: string;
  }>();

  const update = async (data: IndividualHealthcareIdentifierValues) => {
    setIsFetching(true);
    const response = await API.v1()
      .url("/patients/me/ihi")
      .json()
      .body(data)
      .patch();
    if (response.ok) {
      setIhi(await response.json());
      Toast.success("IHI details have been saved successfully.");
    } else {
      let errorMessage =
        "Unable to save IHI, please try again or contact Patient Success";
      if (response.status !== 500) errorMessage = await response.text();
      Toast.error(errorMessage.replace(/"/g, ""));
    }
    setIsFetching(false);
  };

  useEffect(() => {
    const get = async () => {
      setIsFetching(true);
      const response = await API.v1().url("/patients/me/ihi").get();
      if (response.ok) {
        setIhi(await response.json());
      }
      setIsFetching(false);
    };
    get();
  }, []);

  return (
    <ProfileForm<IndividualHealthcareIdentifierValues>
      title="Individual Healthcare Identifier (IHI)"
      id="ihi"
      initialValues={{
        number: ihi?.number || "",
        record_status: ihi?.record_status || "",
        number_status: ihi?.number_status || ""
      }}
      isFetching={isFetching}
      onSave={async (values) => {
        await update({
          number: values.number,
          number_status: values.number_status,
          record_status: values.record_status
        });
      }}
      validate={(values) => ({
        number: Validation.ihi().validate(values.number)
      })}
      isEditingCallback={isEditingCallback}
    >
      {({ values, setValues, error, isEditing }) => (
        <>
          <ProfileCard.Group>
            <ProfileForm.Control
              label="IHI Number"
              value={values.number || ""}
              isEditing={isEditing}
              error={error?.number}
              onChange={(e) => setValues({ ...values, number: e.target.value })}
            />
          </ProfileCard.Group>
          <ProfileCard.Group>
            <ProfileForm.Control
              label="Number Status"
              readOnly
              value={values?.number_status || ""}
            />
          </ProfileCard.Group>
          <ProfileCard.Group>
            <ProfileForm.Control
              label="Record Status"
              readOnly
              value={values?.record_status || ""}
            />
          </ProfileCard.Group>
        </>
      )}
    </ProfileForm>
  );
};
