import { type PropsWithChildren, type ReactNode } from "react";
import { Heading } from "@mh/components";

interface WizardStepProps {
  heading: string;
  footerTop?: ReactNode;
  footer: ReactNode;
}

export const TitleStyle = {
  fontSize: "16px",
  fontWeight: "600",
  color: "#262626"
};

export const SubTitleStyle = {
  fontSize: "14px",
  fontWeight: "400",
  color: "#454545"
};
/**
 * Utility component which renders content with a heading and a dynamic width depending on the screen size.
 *
 * This is effectively just a <div> with a class but can be changed to reflect all checkout flow components later.
 */
export const WizardStep = ({
  heading,
  footerTop,
  footer,
  children
}: PropsWithChildren<WizardStepProps>) => (
  <div
    css={(theme) => ({
      display: "flex",
      flexFlow: "column",
      gap: "24px",
      width: "100%",
      padding: "16px",
      paddingBottom: "80px",
      [theme.mq.md]: {
        width: "64%",
        paddingBottom: "unset"
      }
    })}
  >
    <Heading
      css={{
        color: "#000000",
        fontSize: "24px",
        fontWeight: "500"
      }}
    >
      {heading}
    </Heading>
    {children}
    {footerTop && (
      <div
        css={(theme) => ({
          display: "flex",
          flexFlow: "column",
          position: "fixed",
          bottom: "78px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "inherit",
          paddingBottom: "24px",
          background: "white",
          [theme.mq.md]: {
            display: "none"
          }
        })}
      >
        {footerTop}
      </div>
    )}
    <div
      css={(theme) => ({
        display: "flex",
        flexFlow: "column",
        gap: "8px",
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        paddingTop: "16px",
        position: "fixed",
        bottom: "0",
        left: "50%",
        transform: "translateX(-50%)",
        width: "inherit",
        paddingBottom: "24px",
        background: "white",
        [theme.mq.md]: {
          paddingBottom: "unset",
          width: "unset",
          position: "relative",
          flexFlow: "row",
          borderTop: "none",
          marginTop: "64px",
          justifyContent: "flex-end",
          gap: "16px",
          "& > hr": {
            display: "none"
          }
        },
        "& > button": {
          borderRadius: "4px",
          padding: "16px 24px",
          marginLeft: "24px",
          marginRight: "24px",
          [theme.mq.md]: {
            height: "54px",
            width: "120px",
            marginLeft: "unset",
            marginRight: "unset"
          }
        }
      })}
    >
      {footer}
    </div>
  </div>
);
