import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { BRAND } from "@mh/core";
import { IconCircleChevronLeft, IconHome } from "../icons";

interface BreadcrumbsProps {
  /** The array of crumbs to create links for. */
  crumbs: {
    /** Title of the crumb. */
    title: string;
    /** Path to navigate to when the crumb is clicked. */
    to: string;
  }[];
  /** If true, the first crumb will be a `Home` icon that links to "/". */
  includeHome: boolean;
}

export const Breadcrumbs = ({ crumbs, includeHome }: BreadcrumbsProps) => (
  <div
    css={{
      display: "flex",
      flexFlow: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    }}
  >
    <div
      css={(theme) => ({
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "start",
        alignItems: "center",
        gap: "4px",
        color: BRAND === "hubhealth" ? theme.color.dark : "white",
        fontSize: "16px",
        [theme.mq.md]: {
          color: "black",
          fontSize: "20px"
        }
      })}
    >
      {includeHome && (
        <NavLink
          css={(theme) => ({
            color: BRAND === "hubhealth" ? theme.color.dark : "white",
            [theme.mq.md]: {
              color: theme.color.primary
            }
          })}
          to="/"
        >
          <IconHome
            css={(theme) => ({
              display: "block",
              width: "16px",
              height: "16px",
              [theme.mq.md]: {
                width: "20px",
                height: "20px"
              }
            })}
          />
        </NavLink>
      )}
      {crumbs.map((crumb, index) => (
        <Fragment key={`crumb-${index}`}>
          {(includeHome || index > 0) && <span>/</span>}
          <NavLink
            css={{
              color: "inherit",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline"
              }
            }}
            to={crumb.to}
          >
            <span
              css={(theme) => ({
                color:
                  BRAND === "hubhealth" ? theme.color.dark : theme.color.light
              })}
            >
              {crumb.title}
            </span>
          </NavLink>
        </Fragment>
      ))}
    </div>
    {(!!crumbs.at(-2) || includeHome) && (
      <NavLink
        css={(theme) => ({
          display: "flex",
          alignItems: "center",
          gap: "4px",
          color: BRAND === "hubhealth" ? theme.color.dark : "white",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline"
          },
          [theme.mq.md]: {
            display: "none"
          },
          position: "absolute",
          right: "20px"
        })}
        to={crumbs.at(-2)?.to || "/"}
      >
        <IconCircleChevronLeft css={{ width: "16px", height: "16px" }} />
        <span>Back</span>
      </NavLink>
    )}
  </div>
);
