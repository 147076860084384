import {
  Flex,
  IconAboutyou,
  IconEmotionalWellbeing,
  IconLifestyle,
  IconMedicalHistory,
  IconNutrition,
  IconPhysicalHealth
} from "@mh/components";

interface EmployeeHealthCheckSplashPageProps {
  checkStage: number;
  /** next button elements */
  nextButton: [];
}

interface ContentsListProps {
  stage: number;
  title: string;
  title2?: string;
  subtitle?: string;
  subtitle2?: string;
  description?: string;
  description2?: string;
  questions?: number;
  duration?: string;
  nextStage?: string;
}

const CheckList = [
  {
    title: "Lifestyle",
    icon: "IconLifestyle",
    color: "#F19895"
  },
  {
    title: "Physical Health",
    icon: "IconPhysicalHealth",
    color: "#BF835E"
  },
  {
    title: "Nutrition",
    icon: "IconNutrition",
    color: "#90AEB2"
  },
  {
    title: "Medical History",
    icon: "IconMedicalHistory",
    color: "#6E8DBD"
  },
  {
    title: "Emotional Wellbeing",
    icon: "IconEmotionalWellbeing",
    color: "#9983A7"
  }
];

const ContentsList: ContentsListProps[] = [
  {
    stage: 0,
    title: "Get a quick overview of your health with our free",
    title2: "digital health check",
    questions: 36,
    duration: "10-15",
    description:
      "Our digital health check questionnaire covers a broad range of health factors to provide a comprehensive overview of your wellbeing. By answering questions about your lifestyle, physical health, nutrition, medical history, and emotional wellbeing, we can offer tailored advice and next steps based on your responses.",
    description2: "First up, we'll ask a few questions about your lifestyle."
  },
  {
    stage: 1,
    title: "Well done!",
    subtitle: "You've completed the Lifestyle section.",
    nextStage: "Physical Health",
    subtitle2:
      "In this section we will ask about your exercise habits and any limitations you may have as a result of your physical health.",
    questions: 7,
    duration: "1-2"
  },
  {
    stage: 2,
    title: "Great!",
    subtitle: "You've now completed the Physical Health section.",
    nextStage: "Nutrition",
    subtitle2:
      "In this section we will ask about your eating habits to build an understanding of your dietary choices.",
    questions: 9,
    duration: "2-3"
  },
  {
    stage: 3,
    title: "You're almost there!",
    subtitle: "You’ve now completed the Nutrition section.",
    nextStage: "Medical History",
    subtitle2:
      "In this section we will collect a few details on your medical background to help our medical team better understand your current health situation and any risk factors.",
    questions: 7,
    duration: "1-2"
  },
  {
    stage: 4,
    title: "Well done!",
    subtitle: "You've now completed the Medical History section.",
    nextStage: "Emotional Wellbeing",
    subtitle2: "After you complete this section you can review your results.",
    questions: 11,
    duration: "2-3"
  }
];

const LeftContents = (checkStage: number) => {
  const contents = ContentsList.find((content) => content.stage === checkStage);

  return (
    <div>
      <div
        css={(theme) => ({
          color: "#3D3D3D",
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "135%",
          textAlign: "center",
          [theme.mq.lg]: {
            textAlign: "left",
            fontSize: "40px"
          }
        })}
      >
        {contents?.title}
      </div>
      {contents?.title2 && (
        <div
          css={(theme) => ({
            color: "#90AEB2",
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "135%",
            textAlign: "center",
            [theme.mq.lg]: {
              textAlign: "left",
              fontSize: "40px"
            }
          })}
        >
          {contents?.title2}
        </div>
      )}
      {contents?.subtitle && (
        <div
          css={(theme) => ({
            paddingTop: "16px",
            fontSize: "14px",
            fontWeight: "500",
            textAlign: "center",
            [theme.mq.lg]: {
              textAlign: "left",
              fontSize: "20px"
            }
          })}
        >
          {contents?.subtitle}
        </div>
      )}
      {contents?.nextStage && (
        <div
          css={(theme) => ({
            paddingTop: "24px",
            paddingBottom: "24px",
            fontSize: "16px",
            textAlign: "center",
            [theme.mq.lg]: {
              textAlign: "left",
              fontSize: "20px"
            }
          })}
        >
          Next up is{" "}
          <span
            css={{
              fontWeight: "600"
            }}
          >
            {contents?.nextStage}
          </span>
        </div>
      )}
      {contents?.subtitle2 && (
        <div
          css={(theme) => ({
            color: "#3D3D3D",
            fontSize: "14px",
            fontWeight: "500",
            textAlign: "center",
            [theme.mq.lg]: {
              textAlign: "left",
              fontSize: "20px"
            }
          })}
        >
          {contents?.subtitle2}
        </div>
      )}
      <div
        css={(theme) => ({
          textAlign: "center",
          color: "#888",
          fontSize: "14px",
          lineHeight: "150%",
          marginTop: "12px",
          marginBottom: checkStage === 0 ? "12px" : "40px",
          [theme.mq.lg]: {
            textAlign: "left",
            fontSize: "16px",
            marginTop: "14px",
            marginBottom: "14px"
          }
        })}
      >
        <span css={{ paddingRight: "20px" }}>
          {contents?.questions} questions
        </span>{" "}
        |{" "}
        <span css={{ paddingLeft: "20px" }}>
          <img
            css={{
              marginBottom: "2.5px"
            }}
            src="/assets/images/com/duration.svg"
          />
        </span>
        <span css={{ paddingLeft: "7px" }}>{contents?.duration} min</span>
      </div>
      {contents?.description && (
        <div>
          <p
            css={(theme) => ({
              color: "#454545",
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "center",
              [theme.mq.lg]: {
                textAlign: "left"
              }
            })}
          >
            {contents?.description}
          </p>
        </div>
      )}
      {contents?.description2 && (
        <div>
          <p
            css={(theme) => ({
              color: "#454545",
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "center",
              [theme.mq.lg]: {
                textAlign: "left"
              }
            })}
          >
            {contents?.description2}
          </p>
        </div>
      )}
    </div>
  );
};

export const getHealthIcons = (icon: string) => {
  return icon === "IconAboutyou" ? (
    <IconAboutyou width={20} height={20} />
  ) : icon === "IconLifestyle" ? (
    <IconLifestyle width={20} height={20} />
  ) : icon === "IconNutrition" ? (
    <IconNutrition width={20} height={20} />
  ) : icon === "IconPhysicalHealth" ? (
    <IconPhysicalHealth width={20} height={20} />
  ) : icon === "IconMedicalHistory" ? (
    <IconMedicalHistory width={20} height={20} />
  ) : icon === "IconEmotionalWellbeing" ? (
    <IconEmotionalWellbeing width={20} height={20} />
  ) : null;
};

const getSectionBlocks = (section: any, index: number, checkStage: number) => {
  return (
    <div
      key={`SectionBlock-${index}`}
      css={(theme) => ({
        color: "#3D3D3D",
        borderRadius: "8px",
        borderLeft: `solid 3px ${section.color}`,
        fontSize: "24px",
        fontWeight: "bold",
        padding: "12px",
        marginBottom: "12px",
        background: "white",
        lineHeight: "140%",
        opacity: checkStage === index || checkStage === 0 ? "1" : "0.5",
        [theme.mq.lg]: {
          width: "342px"
        }
      })}
    >
      <Flex flexDirection="row">
        <div
          css={{
            lineHeight: "102%"
          }}
        >
          {getHealthIcons(section.icon)}
        </div>
        <div
          css={(theme) => ({
            fontWeight: "400",
            marginLeft: "8px",
            fontSize: "14px",
            [theme.mq.lg]: {
              fontSize: "16px"
            }
          })}
        >
          {section.title}
        </div>
        {checkStage > index && (
          <div
            css={{
              marginLeft: "auto"
            }}
          >
            <img
              css={{
                marginBottom: "2.5px"
              }}
              src="/assets/images/com/check.svg"
            />
          </div>
        )}
      </Flex>
    </div>
  );
};

const RightContents = (checkStage: number) => {
  return (
    <Flex flexDirection="column">
      {CheckList.map((section, index) =>
        getSectionBlocks(section, index, checkStage)
      )}
    </Flex>
  );
};

export const EmployeeHealthCheckSplashPage = (
  props: EmployeeHealthCheckSplashPageProps
) => {
  return (
    <div
      className="d-flex flex-column flex-lg-row"
      css={(theme) => ({
        paddingRight: "36px",
        paddingLeft: "36px",
        [theme.mq.lg]: {
          maxWidth: "1068px",
          marginLeft: "auto",
          marginRight: "auto"
        }
      })}
    >
      <Flex
        flexDirection="column"
        css={(theme) => ({
          marginRight: "8px",
          [theme.mq.lg]: {
            maxWidth: "532.811px",
            marginRight: "28px"
          }
        })}
      >
        {LeftContents(props.checkStage)}
        <div
          css={(theme) => ({
            display: "none",
            [theme.mq.lg]: {
              display: "block"
            }
          })}
        >
          {props.nextButton}
        </div>
      </Flex>
      <Flex flexDirection="column">{RightContents(props.checkStage)}</Flex>
      <div
        css={(theme) => ({
          [theme.mq.lg]: {
            display: "none"
          }
        })}
      >
        {props.nextButton}
      </div>
    </div>
  );
};
