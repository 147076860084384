import { useTheme } from "@emotion/react";
import { IconErrorMonitor } from "./IconErrorMonitor";
import { IconRightTriangle } from "../icons";

import "./ErrorDisplay.scss";
import { useNavigate } from "react-router-dom";

interface ErrorDisplayProps {
  title?: string;
  subtitle?: string;
  redirectPath?: string;
}

/**
 * When users get an un-caught error, this component will be displayed
 */
export const ErrorDisplay = (props: ErrorDisplayProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <div className="error-display">
      <IconErrorMonitor color={theme.color.primary} />
      <div className="title">{props.title ?? "OOPS"}</div>
      <div className="sub-title">
        {props.subtitle ?? "Something went wrong"}
      </div>
      <div
        className="back-to-home"
        onClick={() => navigate(props.redirectPath ?? "/")}
      >
        <IconRightTriangle
          color={theme.color.primary}
          style={{ marginRight: 8, height: 20 }}
        />
        <div style={{ color: theme.color.primary }}>Back to home</div>
      </div>
    </div>
  );
};
