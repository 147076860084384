import {
  type TinyProductWithImages,
  type ProductRecommendation,
  useAsync,
  OscarAPI
} from "@mh/api";
import { Await, Button, Spinner } from "@mh/components";
import { useBasket, getProductStatus } from "@mh/basket";

interface ClinicianRecommendationProps
  extends Pick<ProductRecommendation, "subtitle" | "details"> {
  product: TinyProductWithImages;
}

const currencyFormatter = Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD"
});

export const ClinicianRecommendation = ({
  product,
  subtitle,
  details
}: ClinicianRecommendationProps) => {
  const basket = useBasket();
  // @ts-ignore
  const { line, isInBasket, isPending } = getProductStatus(basket, {
    ...product,
    price: ""
  });
  const price = useAsync(() =>
    OscarAPI.getProductPrice(product.id, { persistedPricing: false })
  );
  const [image] = product.images;

  /**
   * Adds the product to the basket if it is not already present, otherwise it removes the product
   */
  const handleClick = () => {
    if (line) {
      // This product exists in the basket, so remove it
      basket.removeItem(line.id);
    } else {
      // This product doesn't exist in the basket, so add it
      basket.addItem(product, 1);
    }
  };

  return (
    <div
      css={(theme) => ({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: 282,
        flex: "0 0 282px",
        padding: 24,
        borderRadius: 8,
        backgroundColor: "#F8F8F8",
        [theme.mq.md]: {
          flexDirection: "row",
          flex: "0 0 440px",
          width: "100%",
          maxWidth: "100%",
          height: 340
        }
      })}
    >
      {image && (
        <img
          src={image.original}
          alt={product.title}
          css={(theme) => ({
            height: 200,
            maxWidth: "100%",
            objectFit: "contain",
            alignSelf: "center",
            [theme.mq.md]: { height: "100%", flex: "0 0 32%", maxWidth: "32%" }
          })}
        />
      )}
      <div
        css={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: "8px"
        }}
      >
        <span
          css={(theme) => ({
            color: theme.color.primary,
            font: theme.font.heading,
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "18px",
            [theme.mq.md]: {
              fontSize: 16,
              lineHeight: "24px"
            }
          })}
        >
          {product.title}
        </span>
        <span
          css={(theme) => ({
            textOverflow: "ellipsis",
            overflow: "auto",
            fontSize: 14,
            lineHeight: "16.8px",
            height: "36px",
            [theme.mq.md]: { lineHeight: "18px" }
          })}
        >
          {subtitle}
        </span>
        <hr css={{ width: 75, border: "1px solid #737373", margin: 0 }} />
        <span
          css={(theme) => ({
            flex: "1 0 180px",
            textOverflow: "ellipsis",
            overflow: "auto",
            fontSize: 14,
            lineHeight: "16.8px",
            [theme.mq.md]: { lineHeight: "18px" }
          })}
          dangerouslySetInnerHTML={{ __html: details || "-" }} // HTML strings come from our own database
        />
        <hr css={{ width: 75, border: "1px solid #737373", margin: 0 }} />
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <b
            css={(theme) => ({
              fontSize: "28px",
              fontWeight: 500,
              color: theme.color.secondary
            })}
          >
            <Await asyncs={[price.async]} renderPending={<Spinner size="sm" />}>
              {([price]) =>
                currencyFormatter.format(parseFloat(price.incl_tax))
              }
            </Await>
          </b>
          <Button
            disabled={isPending}
            variant="primary-outline"
            css={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: isInBasket && !isPending ? 1 : "0 0 108px",
              minWidth: isInBasket && !isPending ? "unset" : "max-content",
              fontSize: "14px",
              [theme.mq.md]: {
                whiteSpace: "nowrap",
                maxWidth: isInBasket && !isPending ? "min-content" : "unset"
              }
            })}
            onClick={handleClick}
          >
            {isPending && <Spinner size="sm" />}
            {!isPending && (isInBasket ? "Remove from basket" : "Add to cart")}
          </Button>
        </div>
      </div>
    </div>
  );
};
