import React from "react";

import { Sentry, sendDataLayer } from "@mh/core";

export interface Category {
  id: number | undefined;
  name: string | undefined;
  displayName?: string | undefined;
  noCustomTitle?: boolean | undefined;
  btTxt?: string | undefined;
}

interface OfferTreatmentsProps {
  categoryList: Category[];
  buttonClass?: string | undefined;
  from?: string | undefined;
}

interface QuestionnaireParams {
  name: string;
  param: string;
  displayName: string;
  btTxt?: string | undefined;
}

const QUESTIONNAIRES: { name: string; param: string }[] = [
  {
    name: "HSV1",
    param: "hsv1"
  },
  {
    name: "HSV2",
    param: "hsv2"
  },
  {
    name: "Nuvaring",
    param: "nuvaring"
  },
  {
    name: "Hair Loss",
    param: "hl"
  },
  {
    name: "ED",
    param: "ed"
  },
  {
    name: "PE",
    param: "pe"
  },
  {
    name: "Sleep",
    param: "sleep"
  },
  {
    name: "Contraceptive Pill",
    param: "contraceptive pill"
  },
  {
    name: "Acne",
    param: "acne"
  },
  {
    name: "Reflux",
    param: "reflux"
  },
  {
    name: "Menopause",
    param: "menopause"
  },
  {
    name: "Rosacea",
    param: "rosacea"
  },
  {
    name: "Eczema",
    param: "eczema"
  },
  {
    name: "Vaping Cessation",
    param: "vaping cessation"
  },
  {
    name: "Hypertension",
    param: "hypertension"
  },
  {
    name: "Smoking Cessation",
    param: "smoking cessation"
  },
  {
    name: "Psoriasis",
    param: "psoriasis"
  },
  {
    name: "Fine lines & wrinkles",
    param: "finelineswrinkles"
  },
  {
    name: "Uneven skin tone",
    param: "unevenskintone"
  },
  {
    name: "Weight Management",
    param: "weight_management"
  },
  {
    name: "Medicalcertificates",
    param: "medicalcertificates"
  }
];

interface TextList {
  [catname: string]: {
    [position: string]: string | null;
  };
}

const TXTLIST: TextList = {
  "Weight Management": {
    title:
      "The medication you have selected is only available through our Weight Management program, which includes medication delivered to your door.",
    subtitle: "Note that we are unable to offer eScripts for this medication.",
    button: "Start Weight Management Program Consult"
  }
};

const setTreatmentInfo = (categoryList: Category[]): QuestionnaireParams[] => {
  const treatmentInfo: QuestionnaireParams[] = [];

  QUESTIONNAIRES.forEach((q) =>
    categoryList.forEach((c) => {
      if (c.name?.toLowerCase() === q.name.toLowerCase()) {
        treatmentInfo.push({
          ...q,
          displayName: c.displayName ?? q.name,
          btTxt: c?.btTxt ?? undefined
        });
      }
    })
  );
  return treatmentInfo;
};

export const getOfferProductText = (
  position: string,
  text: string,
  category: Category[]
) => {
  const catName = category?.find(
    (cat) => cat.name && !cat?.noCustomTitle && Object.hasOwn(TXTLIST, cat.name)
  )?.name;
  return catName && TXTLIST[catName][position]
    ? TXTLIST[catName][position] === "void"
      ? ""
      : TXTLIST[catName][position]
    : text;
};

export const OfferTreatments: React.FC<OfferTreatmentsProps> = (props) => {
  const params: QuestionnaireParams[] = setTreatmentInfo(props.categoryList);

  return (
    <div className="d-flex flex-column">
      {params.map((param, index) => {
        return (
          <button
            css={(theme) => ({
              maxWidth: "342px",
              width: "100% !important",
              padding: "8px !important",
              marginLeft: "auto",
              marginRight: "auto",
              lineHeight: "135% !important",
              minHeight: "35px !important",
              height: "unset !important",
              borderColor: `${theme.color.primary} !important`,
              borderRadius: "12px !important",
              [theme.mq.md]: {
                maxWidth: "431px !important",
                width: "100% !important"
              },
              [theme.mq.lg]: {
                width: "431px !important",
                maxWidth: "unset  !important"
              },
              "&:hover, &:active, &.active": {
                color: "#fff !important",
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary} !important`,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
              }
            })}
            className={`btn btn-outline-primary mt-3 nounderline ${
              props?.buttonClass ?? ""
            }`}
            key={`link_${index}`}
            onClick={async (e) => {
              e.preventDefault();
              try {
                sendDataLayer("questionnaireRedirect", {
                  from: props?.from,
                  to: param.name
                });
              } catch (e) {
                Sentry.captureException(e);
              } finally {
                location.href = `/questionnaire/?product=${param.param}`;
              }
            }}
          >
            {param?.btTxt ??
              getOfferProductText(
                "button",
                `Start ${param.name} consult`,
                props.categoryList
              )}
          </button>
        );
      })}
    </div>
  );
};

export const getOfferTreatmentList = (
  categoryList: Category[]
): QuestionnaireParams[] => {
  const params: QuestionnaireParams[] = setTreatmentInfo(categoryList);
  return params;
};

export const hideThankButton = (
  category: Category[],
  showNoThankYouBt?: boolean
): boolean => {
  return (
    !showNoThankYouBt &&
    category?.findIndex((cat) => cat.name === "Weight Management") > -1
  );
};
