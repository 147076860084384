import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createSearchParams,
  Navigate,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  useLocation,
  useMatches,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams
} from "react-router-dom";
import { Global } from "@emotion/react";
import {
  User,
  loadUserFromStore,
  UserContextProvider,
  PatientContextProvider,
  NIBDetailPatientContextProvider,
  UserInfo,
  LOCAL_STORE_USER_KEY,
  safeGetToken,
  API,
  ConversationsContextProvider,
  useFeatures
} from "@mh/api";
import { BasketContextProvider } from "@mh/basket";
import { Checkout } from "@mh/checkout";
import { Sentry } from "@mh/core";
import { QuestionnairePage } from "@mh/questionnaire";
import { RootErrorBoundary, Login, Toast } from "@mh/components";

import { AppContainer } from "./components/container/AppContainer";
import NewNotifications from "./components/notifications/newnotifications";
import NotFound from "./components/etc/NotFound";
import Questionnaire from "./components/questionnaire/questionnaire";
import QuestionnaireRepeats from "./components/questionnaire/repeatsquestionnaire";
import {
  FAQ,
  Messaging,
  Profile,
  History,
  Home,
  Shop,
  WhatWeTreat,
  TreatmentCompletePurchase,
  Treatments,
  PageWithLogoHeader,
  CorporateSignUp,
  TreatmentAcceptanceWizard,
  HubPassSignUp,
  PurchaseHubPass
} from "./pages";
import { ReactRouterMatch, ReactRouterRouteObject } from "./types";
import { sendTrackingCookie } from "./utils";

/**
 * Return true if the token is valid, otherwise false.
 * Make a request to the backend (source of truth for token validity).
 */
const checkTokenIsValid = async (token: string): Promise<boolean> =>
  API.v2()
    // route will respond with status 200 if token is valid, else 401.
    .url("/check-token/")
    .headers({ Authorization: `Bearer ${token}` })
    .get()
    .then((response) => response.status === 200)
    .catch((error) => {
      Sentry.captureException(error);
      return false;
    });

/**
 * If there is a token in local storage, and it is invalid, remove it.
 */
const removeInvalidToken = async (): Promise<void> => {
  const token = safeGetToken();
  if (!token) return;
  const valid = await checkTokenIsValid(token);
  if (!valid) localStorage.removeItem(LOCAL_STORE_USER_KEY);
};

const Root = () => {
  // Global Contexts
  const [user, setUser] = useState<UserInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [zendeskChatBotFeature, setZendeskChatBotFeature] =
    useState<boolean>(false);

  /** Voucher query param. */
  const [searchParams] = useSearchParams();
  const [vouchervalue, setVoucher] = useState<string>();
  const [voucherfromurl, setVoucherfromUrl] = useState<string>();

  const location = useLocation();
  const matches = useMatches() as ReactRouterMatch[];
  const navigate = useNavigate();
  const { isFeatureActive } = useFeatures();

  useEffect(() => {
    async function setup() {
      if (window.zE) {
        window.zE("messenger", "hide");
      }
      const zendeskChatBot = await isFeatureActive("zendesk_chat_bot", false);
      setZendeskChatBotFeature(zendeskChatBot);
    }
    setup();
  }, []);

  useEffect(() => {
    const checkTokenAndLoadUser = async () => {
      setIsLoading(true);
      // If the token in local storage is invalid, remove it.
      // i.e. if a browser's token has expired, instead of assuming it is the same
      // user and asking them to login, we treat them as an unauthenticated user.
      // This is so that when multiple people use the same device,
      // the second person can seamlessly complete an initial questionnaire.
      await removeInvalidToken();
      setUser(loadUserFromStore());
      User.setup(user, setUser, navigate);
      setIsLoading(false);
    };
    checkTokenAndLoadUser();
  }, []);

  useEffect(() => {
    if (User.loggedIn()) {
      sendTrackingCookie();
    }
    // Does Zendesk Config exist in index.html
    if (window.zE) {
      if (user === null) {
        // Not logged in
        window.zE("messenger", "logoutUser");
      } else if (user.zendesk_credentials) {
        // Login to zendesk widget if logged in
        window.zE("messenger", "loginUser", (callback: Function) => {
          callback(user.zendesk_credentials);
        });
      }
    }
  }, [user]);

  const showHideZendeskBot = (
    zendeskChatBotFeature: boolean,
    showZendesk: boolean
  ) => {
    if (zendeskChatBotFeature && window.zE) {
      if (!showZendesk || user === null) {
        window.zE("messenger", "hide");
      } else {
        window.zE("messenger", "show");
        window.zE("messenger:set", "conversationFields", [
          { id: "24399735805081", value: window.location.href }
        ]);
      }
    }
  };

  const loadFeature = async (flagName: string) => {
    const featureResult = await isFeatureActive(flagName, false);
    if (!featureResult) {
      navigate({ pathname: "/404" });
    }
  };

  /**
   * Check if the user must be authenticated to access any of the matched routes,
   * and redirect them to the login page if so.
   * Check if the route a user is accessing has any feature flags required to be active,
   * and if not active, redirect them to the 404 not found page
   */
  useEffect(() => {
    /** Do nothing until the user has been initialised. */
    if (isLoading) return;

    const resolveRequirements = async () => {
      const showZendesk = matches.find((match) => match.handle?.showZendesk);
      showHideZendeskBot(zendeskChatBotFeature, !!showZendesk);

      const requiresAuthentication = matches.find(
        (match) => match.handle?.authenticationRequired
      );

      if (requiresAuthentication && !User.loggedIn()) {
        const params = createSearchParams({
          redirect: location.pathname + location.search
        }).toString();
        Toast.info("Please log in to access this page.");
        navigate({ pathname: "/", search: params });
      }

      const requiresActiveFeatureFlag = matches.find(
        (match) => match.handle?.featureFlagsRequired
      );
      const featureFlagArray =
        requiresActiveFeatureFlag?.handle?.featureFlagsRequired;
      if (featureFlagArray) {
        await Promise.all(featureFlagArray.map(loadFeature));
      }
      setShow(true);
    };
    resolveRequirements();
  }, [isLoading, matches, user]);

  if (isLoading) {
    return null;
  }

  // Prevents being redirected multiple times and causing duplicate toast messages
  if (!show) return null;

  if (!User.loggedIn()) {
    return (
      <Outlet
        context={{
          voucherpair: {
            vouchervalue,
            setVoucher,
            voucher: searchParams.get("voucher")?.toUpperCase(),
            setVoucherfromUrl,
            voucherfromurl
          }
        }}
      />
    );
  }

  return (
    <UserContextProvider id="me">
      <PatientContextProvider id="me">
        <NIBDetailPatientContextProvider>
          <BasketContextProvider>
            <ConversationsContextProvider>
              <ScrollRestoration />
              <Outlet
                context={{
                  voucherpair: {
                    vouchervalue,
                    setVoucher,
                    voucher: searchParams.get("voucher")?.toUpperCase(),
                    setVoucherfromUrl,
                    voucherfromurl
                  }
                }}
              />
            </ConversationsContextProvider>
          </BasketContextProvider>
        </NIBDetailPatientContextProvider>
      </PatientContextProvider>
    </UserContextProvider>
  );
};

const OutletContainer = () => <Outlet context={useOutletContext()} />;

const Default = () => {
  return User.loggedIn() ? <Navigate replace to="/home" /> : <Login />;
};

const Navigation = () => {
  const outletContext = useOutletContext();
  return (
    <AppContainer>
      <Outlet context={outletContext} />
    </AppContainer>
  );
};

/** Redirect the `/myplan/:questionnaireId` route to `/treatments/:questionnaireId/complete-purchase` */
const RedirectMyPlan = () => {
  const { questionnaireId } = useParams();
  return (
    <Navigate replace to={`/treatments/${questionnaireId}/complete-purchase`} />
  );
};

const GrayBackground = () => {
  const outletContext = useOutletContext();
  return (
    <>
      <Global
        styles={{
          body: {
            backgroundColor: "#FAFAFA"
          }
        }}
      />
      <Outlet context={outletContext} />
    </>
  );
};

const LogoutComponent = () => {
  User.logout();
  return <Navigate to="/" replace />;
};

const ROUTES: ReactRouterRouteObject[] = [
  {
    errorElement: <RootErrorBoundary />,
    element: <Root />,
    children: [
      {
        element: <Navigation />,
        children: [
          {
            path: "/",
            element: <Default />
          },
          {
            path: "/home",
            element: <Home />,
            handle: {
              authenticationRequired: true
            }
          },
          {
            path: "/treatments",
            element: <OutletContainer />,
            handle: {
              authenticationRequired: true
            },
            children: [
              {
                path: "",
                element: <Treatments />,
                handle: {
                  title: "Treatments"
                }
              },
              {
                path: ":questionnaireId/complete-purchase/:step?",
                element: <TreatmentCompletePurchase />,
                handle: {
                  title: "Your Treatment",
                  breadcrumbOptions: {
                    showHome: true
                  }
                }
              }
            ]
          },
          {
            path: "/notifications",
            element: <NewNotifications />,
            handle: {
              authenticationRequired: true,
              title: "Notifications"
            }
          },
          {
            path: "/what-we-treat",
            element: <WhatWeTreat />,
            handle: {
              authenticationRequired: true,
              title: "What We Treat"
            }
          },
          {
            path: "/history",
            element: <History />,
            handle: {
              authenticationRequired: true,
              title: "History"
            }
          },
          {
            path: "/faqs",
            element: <FAQ />,
            handle: {
              authenticationRequired: true,
              title: "Frequently Asked Questions",
              showZendesk: true
            }
          },
          {
            path: "/profile",
            element: <Profile />,
            handle: {
              authenticationRequired: true,
              title: "Profile"
            }
          },
          {
            path: "/messaging",
            element: <Messaging />,
            handle: {
              authenticationRequired: true,
              title: "Messages"
            }
          },
          {
            path: "/messaging/conversation/:conversationId",
            element: <Messaging />,
            handle: {
              authenticationRequired: true,
              title: "Messages"
            }
          },
          {
            path: "/myplan/:questionnaireId",
            element: <RedirectMyPlan />
          },
          {
            path: "/shop/:productId?",
            element: <Shop />,
            handle: {
              authenticationRequired: true,
              title: "Shop"
            }
          },
          {
            path: "/logout",
            element: <LogoutComponent />
          }
        ]
      },
      {
        path: "/checkout",
        element: (
          <PageWithLogoHeader>
            <Checkout />
          </PageWithLogoHeader>
        ),
        handle: {
          authenticationRequired: true
        }
      },
      {
        path: "/checkout/subscription/:subscriptionId",
        element: (
          <PageWithLogoHeader isNewCheckout={true}>
            <TreatmentAcceptanceWizard />
          </PageWithLogoHeader>
        ),
        handle: {
          title: "Treatment Acceptance",
          authenticationRequired: true
        }
      },
      {
        path: "/purchase-hubpass",
        element: <PurchaseHubPass />,
        handle: {
          authenticationRequired: true
        }
      },
      {
        element: <GrayBackground />,
        children: [
          {
            path: "/questionnairerepeats/:product/:id",
            element: <QuestionnaireRepeats />,
            handle: {
              authenticationRequired: true
            }
          },
          {
            path: "/questionnaire/",
            element: <Questionnaire />
          },
          {
            path: "/questionnaire/:category",
            element: <QuestionnairePage />
          },
          {
            path: "*",
            element: <NotFound />
          }
        ]
      },
      {
        children: [
          {
            path: "/corporate",
            element: <CorporateSignUp />
          }
        ]
      },
      {
        path: "/hubpass-signup",
        element: <HubPassSignUp />
      }
    ]
  }
];

const Router = () => <RouterProvider router={createBrowserRouter(ROUTES)} />;

export default Router;
