import { ComponentPropsWithoutRef } from "react";

export const IconTriangleExclamation = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 41 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.6787 26.4147C21.6943 26.4147 22.5537 27.274 22.4756 28.2897C22.4756 29.3834 21.6943 30.1647 20.6787 30.1647C19.6631 30.1647 18.8037 29.3834 18.8037 28.2897C18.8037 27.274 19.585 26.4147 20.6787 26.4147ZM20.6787 23.2897C19.9756 23.2897 19.4287 22.7428 19.4287 22.0397V10.7897C19.4287 10.1647 20.0537 9.53967 20.6787 9.53967C21.2256 9.53967 21.8506 10.1647 21.8506 10.7897V22.0397C21.8506 22.7428 21.3037 23.2897 20.6787 23.2897ZM40.0537 29.3834C40.835 30.7115 40.835 32.274 40.0537 33.6022C39.2725 35.0084 37.8662 35.7897 36.3037 35.7897H5.05371C3.41309 35.7897 2.00684 35.0084 1.22559 33.6022C0.444336 32.274 0.444336 30.7115 1.22559 29.3834L16.8506 2.97717C17.6318 1.64905 19.0381 0.789673 20.6787 0.789673C22.2412 0.867798 23.6475 1.64905 24.4287 2.97717L40.0537 29.3834ZM37.8662 32.3522C38.2568 31.8053 38.1787 31.1803 37.8662 30.6334L22.2412 4.22717C21.9287 3.6803 21.3037 3.3678 20.6787 3.28967C20.6006 3.28967 20.6787 3.28967 20.6787 3.28967C19.9756 3.28967 19.3506 3.6803 19.0381 4.22717L3.41309 30.6334C3.10059 31.1803 3.02246 31.8053 3.41309 32.3522C3.72559 32.9772 4.35059 33.2897 5.05371 33.2897H36.2256C36.9287 33.2897 37.5537 32.9772 37.8662 32.3522Z"
      fill="currentColor"
    />
  </svg>
);
