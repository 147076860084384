import { SafeURLSearchParams } from "@mh/core";
import { API } from "../api";
import { User } from "../user";
import type { OexaScriptLookup } from "./types";

/**
 * Looks up the given script in the Medication Knowledge (MK) database and returns the matching script, if one matches.
 *
 * @param token The eScript token
 * @param email If provided, it is assumed this method has been called from an authenticated context, like an initial
 * questionnaire. In such cases, this email will be used as the user identifier in the OEXA-MK lookup.

 * @returns The matching script, if one exists
 */
const lookupScript = async (
  token: string,
  email?: string
): Promise<OexaScriptLookup> => {
  let url = `/api/v2/script-token-lookup/${token}/`;
  let api = API;

  if (!User.loggedIn() && !email) {
    throw new Error(
      "lookupScript requires an email when called in an unauthenticated context."
    );
  }

  if (email) {
    url += `?${new SafeURLSearchParams({ email }).toString()}`;
    api = api.unauthenticated();
  }

  const response = await api.url(url).get();
  if (!response.ok) throw response;

  return response.json();
};

export const OexaAPI = {
  lookupScript
};
