import React, { useContext, useMemo } from "react";
import moment from "moment";
import { PatientContext } from "@mh/api";
import { Validation, ValidationResult } from "@mh/core";

import { ProfileCard } from "./ProfileCard";
import { ProfileForm, ProfileFormValues } from "./ProfileForm";

import { Tooltip } from "@mh/components";

export interface MedicareValues extends ProfileFormValues {
  number: string;
  expiryMonth: string;
  expiryYear: string;
  irn: string;
}

export const validateMedicareValues = (values: MedicareValues) => {
  // Only expiries starting from next month are valid
  const expiryValidation = () =>
    moment(`${values.expiryYear}-${values.expiryMonth}`) <
    moment().startOf("month").add(1, "month")
      ? "Expiry must be in the future."
      : null;
  return {
    number: Validation.medicare().validate(values.number),
    irn: Validation.int()
      .custom((v) =>
        parseInt(v!, 10) > 0 && parseInt(v!, 10) < 10
          ? null
          : "IRN must be a number between 1 and 9, inclusive."
      )
      .validate(values.irn),
    expiryMonth: Validation.required({ name: "Expiry month" })
      .custom(expiryValidation)
      .validate(values.expiryMonth),
    expiryYear: Validation.required({ name: "Expiry year" })
      .custom(expiryValidation)
      .validate(values.expiryYear)
  };
};

const MEDICARE_EXPIRY_MONTHS = [
  ["01", "January"],
  ["02", "February"],
  ["03", "March"],
  ["04", "April"],
  ["05", "May"],
  ["06", "June"],
  ["07", "July"],
  ["08", "August"],
  ["09", "September"],
  ["10", "October"],
  ["11", "November"],
  ["12", "December"]
];

// 7 consecutive years, starting from the current year
// Medicare cards are valid for 5 years, so this has some buffer
// see: https://www.servicesaustralia.gov.au/your-medicare-card?context=60092
const MEDICARE_EXPIRY_YEARS = Array.from(new Array(7)).map((_, index) =>
  moment().add(index, "year").year().toString()
);

export type MedicareFieldProps = {
  id: string;
  isFetching: boolean;
  error?: ValidationResult;
  isEditing: boolean;
  onChange: (event: any) => void;
  value: string;
  required?: boolean;
};
export const MedicareNumber = (props: MedicareFieldProps) => (
  <ProfileForm.Control
    label="Medicare number"
    data-testid={`${props.id}-number`}
    disabled={props.isFetching}
    error={props.error}
    isEditing={props.isEditing}
    onChange={props.onChange}
    value={props.value}
    required={props.required}
  />
);

export const MedicareIRN = (props: MedicareFieldProps) => (
  <ProfileForm.Control
    label={
      <>
        IRN
        <Tooltip text="The number next to your name on your Medicare card" />
      </>
    }
    data-testid={`${props.id}-irn`}
    disabled={props.isFetching}
    error={props.error}
    isEditing={props.isEditing}
    onChange={props.onChange}
    value={props.value}
    required={props.required}
  />
);

export const MedicareExpMonth = (props: MedicareFieldProps) => (
  <ProfileForm.Select
    label="Expiry month"
    data-testid={`${props.id}-expiry-month`}
    disabled={props.isFetching}
    error={props.error}
    isEditing={props.isEditing}
    onChange={props.onChange}
    value={props.value}
    required={props.required}
  >
    <option disabled value=""></option>
    {MEDICARE_EXPIRY_MONTHS.map(([month, monthName], index) => (
      <option key={`${index}_${month}`} value={month}>
        {monthName}
      </option>
    ))}
  </ProfileForm.Select>
);

export const MedicareExpYear = (props: MedicareFieldProps) => (
  <ProfileForm.Select
    label="Expiry year"
    data-testid={`${props.id}-expiry-year`}
    disabled={props.isFetching}
    error={props.error}
    isEditing={props.isEditing}
    onChange={props.onChange}
    value={props.value}
    required={props.required}
  >
    <option disabled value=""></option>
    {MEDICARE_EXPIRY_YEARS.map((year, index) => (
      <option key={`${index}_${year}`} value={year}>
        {year}
      </option>
    ))}
  </ProfileForm.Select>
);

export interface MedicareProps {
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
}

export const Medicare = ({ isEditingCallback }: MedicareProps) => {
  const { isFetching, data: patient, update } = useContext(PatientContext);

  const [expiryMonth, expiryYear] = useMemo(() => {
    if (typeof patient?.medicare_expiry === "string") {
      // The medicare_expiry is of the form "YYYY-MM-DD", parse out only
      // the month and year, discarding the day
      const date = moment(patient.medicare_expiry);
      return [date.format("MM"), date.format("YYYY")];
    }
    return [null, null];
  }, [patient]);

  return (
    <ProfileForm<MedicareValues>
      title="Medicare"
      id="medicare"
      initialValues={{
        number: patient?.medicare_number || "",
        irn: patient?.medicare_number_irn || "",
        expiryMonth: expiryMonth || "",
        expiryYear: expiryYear || ""
      }}
      isFetching={isFetching}
      onSave={async (values) => {
        await update({
          medicare_number: values.number,
          medicare_number_irn: values.irn,
          medicare_expiry: `${values.expiryYear}-${values.expiryMonth}-01`
        });
      }}
      validate={validateMedicareValues}
      isEditingCallback={isEditingCallback}
    >
      {({ values, setValues, error, isEditing, id }) => (
        <>
          <ProfileCard.Group>
            <MedicareNumber
              id={id}
              isFetching={isFetching}
              error={error?.number}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, number: e.currentTarget.value })
              }
              value={values.number}
            />
            <MedicareIRN
              id={id}
              isFetching={isFetching}
              error={error?.irn}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, irn: e.currentTarget.value })
              }
              value={values.irn}
            />
          </ProfileCard.Group>
          <ProfileCard.Group>
            <MedicareExpMonth
              id={id}
              isFetching={isFetching}
              error={error?.expiryMonth}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, expiryMonth: e.currentTarget.value })
              }
              value={values.expiryMonth}
            />
            <MedicareExpYear
              id={id}
              isFetching={isFetching}
              error={error?.expiryYear}
              isEditing={isEditing}
              onChange={(e) =>
                setValues({ ...values, expiryYear: e.currentTarget.value })
              }
              value={values.expiryYear}
            />
          </ProfileCard.Group>
        </>
      )}
    </ProfileForm>
  );
};
