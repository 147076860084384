import { useState } from "react";
import { IconCaretLeft, IconCaretRight, Toast } from "@mh/components";

import { useQContext } from "@mh/questionnaire/src/hooks";
import { HOST } from "@mh/core";
import { User } from "@mh/api";

export const QuestionnaireDebugPanel = () => {
  const [show, setShow] = useState<boolean>(false);
  const { uuid, nodeUUID, responseData } = useQContext();

  const BoolIcon = (props: { bool: boolean }) => (
    <img
      src={`${HOST}/static/admin/img/icon-${props.bool ? "yes" : "no"}.svg`}
    />
  );

  const OpenClose = () =>
    show ? (
      <>
        <IconCaretRight color="white" />
        <IconCaretRight color="white" />
      </>
    ) : (
      <>
        <IconCaretLeft color="white" />
        <IconCaretLeft color="white" />
      </>
    );

  const ShowResponseData = () => (
    <button
      onClick={() => {
        Toast.dismiss();
        Toast.info(JSON.stringify(responseData));
      }}
    >
      Show response data
    </button>
  );

  return (
    <div
      css={{
        position: "absolute",
        right: 0
      }}
    >
      <div
        style={{
          width: 15,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
          padding: 2,
          background: "blue"
        }}
        onClick={() => setShow(!show)}
      >
        <OpenClose />
      </div>
      <div
        style={{
          border: "1px solid black",
          display: show ? "flex" : "none",
          padding: 10,
          flexDirection: "column",
          backgroundColor: "rgba(255,255,255,0.8)"
        }}
      >
        <h2>Debug panel</h2>
        <div>
          <div>UUID: {uuid}</div>
          <div>Node UUID: {nodeUUID}</div>
          <div>
            Is authenticated <BoolIcon bool={User.loggedIn()} />
          </div>
          <ShowResponseData />
        </div>
      </div>
    </div>
  );
};
