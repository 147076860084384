import { Script } from "@mh/api";

interface MedicationDetailsProps {
  script: Script;
}

export const MedicationDetails = ({ script }: MedicationDetailsProps) => {
  const pharmacyNotes = script.product.pharmacy_notes.map(
    (note) =>
      !note.title?.includes("How to use") && (
        <div key={note.id}>
          <div css={{ fontWeight: "600", marginBottom: "4px" }}>
            {note.title}
          </div>
          <span css={{ whiteSpace: "pre-line" }}>{note.content}</span>
        </div>
      )
  );

  return (
    <>
      {script.product.pharmacy_notes.length > 0 && (
        <div
          css={{
            display: "flex",
            flexFlow: "column",
            gap: "16px",
            fontSize: "14px"
          }}
        >
          {pharmacyNotes}
        </div>
      )}
    </>
  );
};
