import { ComponentPropsWithoutRef } from "react";

export const IconPhysicalHealth = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2701 11.2052C10.5741 10.5005 11.1119 9.92227 11.7927 9.56799C12.4735 9.21371 13.2557 9.10511 14.0073 9.2605C14.7589 9.4159 15.4338 9.82578 15.9183 10.421C16.4028 11.0162 16.6672 11.7603 16.6668 12.5278C16.6668 15.1062 13.999 17.1965 10.6643 17.1965C7.94509 17.1965 5.22659 16.6496 3.74729 15.5544C3.46316 15.3437 3.32644 14.9995 3.33378 14.646C3.40647 11.0092 3.74595 3.85742 8.6634 3.85742C9.19406 3.85742 9.70298 4.06823 10.0782 4.44346C10.4534 4.81869 10.6643 5.32762 10.6643 5.85828C10.6643 6.21205 10.5237 6.55134 10.2736 6.80149C10.0234 7.05165 9.68412 7.19219 9.33035 7.19219C8.59337 7.19219 8.23655 6.89606 7.99644 6.52523"
      stroke="#BF835E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 11.8618C11.5976 11.5598 11.1319 11.3527 10.638 11.2562C10.1441 11.1597 9.63475 11.1763 9.14814 11.3046C8.66153 11.4329 8.21028 11.6697 7.8282 11.9972C7.44611 12.3247 7.14311 12.7345 6.94189 13.1957"
      stroke="#BF835E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.63997 7.0752C7.34275 7.84352 8.33051 11.1936 7.33008 12.5275"
      stroke="#BF835E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
