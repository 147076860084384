import { FC, useEffect, useState } from "react";
import { BasketLine, OscarAPI, RouterMethod } from "@mh/api";
import {
  Button,
  Flex,
  IconClose,
  OrderOnDemandSwitch,
  QuantitySelectorProps,
  SafeImg,
  Spinner
} from "@mh/components";
import { BRAND } from "@mh/core";

import { isTreatment } from "../../utils";
import { useBasket } from "../../hooks";
import Quantity from "./Quantity";
import TreatmentSelection from "./TreatmentSelection";
import { shouldShowTreatmentQuantity } from "./utils";
import "./styles.scss";

interface Props extends Pick<QuantitySelectorProps, "onChange"> {
  /** The product line */
  line: BasketLine;
  /** Router method */
  routerMethod: RouterMethod | null;
  /** Optionally true, to show if a product is out of stock */
  outOfStock?: boolean;
  /** Optional callback to fire if the order is removed */
  onRemove?: () => void;
}

interface CheckoutProps extends Props {
  /**
   * Optional mapping from basket line id to order on demand value
   * It is optional because BasketDrawer(cart) does not have auto refill toggle button
   */
  lineIdToOrderOnDemand: { [lineId: number]: boolean } | undefined;

  /**
   * Optional callback to set mapping from basket line id to order on demand value
   * It is optional because BasketDrawer(cart) does not have auto refill toggle button
   */
  setLineIdToOrderOnDemand: React.Dispatch<
    React.SetStateAction<
      | {
          [lineId: number]: boolean;
        }
      | undefined
    >
  >;
}

const OrderSummary: FC<Props | CheckoutProps> = (props) => {
  const productIsTreatment = isTreatment(props.line.product);
  const [image, setImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Load the category image if the product is a treatment, or its default image if it is not
    if (props.line.product.categories.length > 0 && productIsTreatment) {
      // Load the portal image off the product's first category directly

      // Sorting categories to see the actual treatment category first

      const [tinyCategory] = props.line.product.categories.sort((a, b) => {
        if (a.name === "Fill-a-script" || a.name === "Renew-a-script") return 1;
        if (b.name === "Fill-a-script" || b.name === "Renew-a-script")
          return -1;
        return 0;
      });

      OscarAPI.getCategoryPortalImage(BRAND).then((portalImages) => {
        const result = portalImages.find(
          ({ category }) => category === tinyCategory.id
        );
        setImage(result?.image);
      });
    } else {
      // Use the first image from the product, serialised with images
      const [image] = props.line.product.images;
      setImage(image?.original ?? undefined);
    }
  }, [props.line.product]);

  const basket = useBasket();
  const isRemoving = basket.removingLines.has(props.line.id);

  const { setLineIdToOrderOnDemand, lineIdToOrderOnDemand } =
    props as CheckoutProps;

  return (
    <Flex flexDirection="column" className="order-summary">
      <Flex marginBetween="16px">
        <SafeImg
          // Force re-load if the image changes
          key={`order_summary_image_${image}`}
          imagePath={image}
          imgProps={{
            alt: props.line.product.title,
            className: "order-summary-img"
          }}
        />
        <Flex
          flex="1"
          flexDirection="column"
          marginBetween="1rem"
          justifyContent="space-between"
        >
          <Flex justifyContent="space-between" width="100%" alignItems="start">
            <Flex flexDirection="column">
              <span
                css={{
                  fontWeight: "600",
                  marginTop: "auto",
                  marginBottom: "auto"
                }}
              >
                {props.line.product.title}
              </span>
              {props.routerMethod && (
                <span
                  css={{
                    fontWeight: "400",
                    fontSize: "12px"
                  }}
                >
                  {props.routerMethod.shipping_method_name}
                  {" - "}
                  {props.routerMethod.shipping_method_description}
                </span>
              )}
              {props.outOfStock && (
                <span className="fw-bold">(out of stock)</span>
              )}
            </Flex>
            {props.onRemove && (
              <Button
                active
                data-testid="order-summary-remove"
                disabled={isRemoving}
                onClick={props.onRemove}
                size="sm"
                variant="danger-plain"
              >
                {isRemoving ? (
                  <Spinner />
                ) : (
                  <IconClose width={17} height={17} />
                )}
              </Button>
            )}
          </Flex>
          {!productIsTreatment && (
            <Quantity
              value={props.line.quantity}
              onChange={props.onChange}
              range={[1, props.line.product.max_repeats]}
              totalPrice={props.line.price_incl_tax_excl_discounts}
            />
          )}
        </Flex>
      </Flex>
      {productIsTreatment && shouldShowTreatmentQuantity(props.line) && (
        <div>
          {setLineIdToOrderOnDemand && lineIdToOrderOnDemand && (
            <OrderOnDemandSwitch
              line={props.line}
              setLineIdToOrderOnDemand={setLineIdToOrderOnDemand}
              lineIdToOrderOnDemand={lineIdToOrderOnDemand}
            />
          )}
          <TreatmentSelection
            product={props.line.product}
            value={props.line.quantity}
            numSuppliesRemaining={props.line.num_supplies_remaining}
            onChange={props.onChange}
            orderOnDemand={
              lineIdToOrderOnDemand && lineIdToOrderOnDemand[props.line.id]
            }
          />
        </div>
      )}
    </Flex>
  );
};

export default OrderSummary;
