import { ComponentPropsWithoutRef } from "react";

export const IconBell = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.83594 0.625C8.83594 0.3125 9.14844 0 9.46094 0C9.8125 0 10.125 0.3125 10.125 0.625V1.28906C13.25 1.60156 15.75 4.25781 15.75 7.5V8.67188C15.75 10.3516 16.4141 11.9922 17.625 13.2031L17.7422 13.3203C18.0547 13.6328 18.25 14.0625 18.25 14.5312C18.25 15.5078 17.4688 16.25 16.4922 16.25H2.46875C1.49219 16.25 0.75 15.5078 0.75 14.5312C0.75 14.0625 0.90625 13.6328 1.21875 13.3203L1.33594 13.2031C2.54688 11.9922 3.25 10.3516 3.25 8.67188V7.5C3.25 4.25781 5.71094 1.60156 8.875 1.28906L8.83594 0.625ZM9.46094 2.5C6.72656 2.5 4.46094 4.76562 4.46094 7.5V8.67188C4.46094 10.7031 3.67969 12.6562 2.23438 14.1016L2.11719 14.1797C2.03906 14.2969 1.96094 14.4141 1.96094 14.5312C1.96094 14.8047 2.19531 15 2.46875 15H16.4922C16.7656 15 17 14.8047 17 14.5312C17 14.4141 16.9219 14.2969 16.8438 14.1797L16.7266 14.1016C15.2812 12.6562 14.5 10.7031 14.5 8.67188V7.5C14.5 4.76562 12.2344 2.5 9.46094 2.5ZM9.46094 18.75C10.0078 18.75 10.4766 18.4375 10.6719 17.9297C10.7891 17.6172 11.1406 17.4219 11.4531 17.5391C11.7656 17.6562 11.9609 18.0078 11.8438 18.3594C11.4922 19.3359 10.5547 20 9.46094 20C8.40625 20 7.46875 19.3359 7.11719 18.3594C7 18.0078 7.19531 17.6562 7.50781 17.5391C7.82031 17.4219 8.17188 17.6172 8.28906 17.9297C8.48438 18.4375 8.95312 18.75 9.46094 18.75Z"
      fill="currentColor"
    />
  </svg>
);
