import { Button, Form } from "react-bootstrap";

import { Flex } from "@mh/components";

export const MedicationInformationSideEffects = (props: {
  hasSideEffects: boolean | null;
  setHasSideEffects: (v: boolean) => void;
  sideEffectDetails: string | null;
  setSideEffectDetails: (v: string) => void;
}) => (
  <Flex className="field" flexDirection="column" width="100%">
    <div>
      <div className="asterisk">
        Did you have any side effects from this medication?
      </div>
    </div>
    <div>
      <Flex justifyContent="center" style={{ marginTop: 10 }}>
        <Button
          className={`qc-boolean${
            props.hasSideEffects === true ? " qc-boolean-selected" : ""
          }`}
          onClick={() => props.setHasSideEffects(true)}
        >
          Yes
        </Button>
      </Flex>
      <Flex justifyContent="center" style={{ marginTop: 10 }}>
        <Button
          className={`qc-boolean${
            props.hasSideEffects === false ? " qc-boolean-selected" : ""
          }`}
          onClick={() => props.setHasSideEffects(false)}
        >
          No
        </Button>
      </Flex>
    </div>
    {props.hasSideEffects && (
      <Form.Control
        as="textarea"
        placeholder="Please describe the side effects"
        style={{ marginTop: 10 }}
        value={props.sideEffectDetails ?? ""}
        onChange={(e) => props.setSideEffectDetails(e.target.value)}
      />
    )}
  </Flex>
);
