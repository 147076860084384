import { BRAND } from "@mh/core";
import { Flex } from "@mh/components";

interface Props {
  /** is it last question page  */
  isEnd: boolean;
  /** hide back button  */
  hideback: boolean;
  /** For new last page  */
  isShowNewPaymentPage?: boolean;
  /** current question index  */
  current: number;
  /** The total length of questionnaire  */
  total: number;
  /** go back function  */
  back: () => void;
}

export const ProgressBar = ({
  isEnd,
  hideback,
  back,
  isShowNewPaymentPage,
  current,
  total
}: Props): JSX.Element => {
  const position = isEnd ? 100 : (current / total) * 100;

  return (
    <>
      <div
        className={`col-2 col-lg-1 v-center
        ${isEnd && isShowNewPaymentPage && "NewPaymentBack"}
      `}
      >
        {!hideback && (
          <div className={"back"}>
            <button
              className="btn btn-link text-decoration-none newback"
              css={{
                paddingLeft: "0"
              }}
              onClick={back}
            >
              <span
                css={(theme) => ({
                  borderRadius: "20px",
                  background:
                    BRAND === "hubhealth"
                      ? theme.color.third
                      : theme.color.secondary,
                  width: "22px",
                  height: "22px",
                  paddingBottom: "2px"
                })}
              >
                <img
                  css={{
                    height: "12px",
                    marginBottom: "4.39px"
                  }}
                  src="/assets/images/com/arrow-left.svg"
                />
              </span>
              <span
                className="txt-back"
                css={{
                  fontSize: "12px"
                }}
              >
                Back
              </span>{" "}
            </button>
          </div>
        )}
      </div>
      <div className="col-lg-0 col-7"></div>

      <div
        className="col-12 col-lg-10 v-center"
        css={{
          marginTop: "4px",
          marginBottom: "4px"
        }}
      >
        {!isEnd && (
          <div
            css={(theme) => ({
              background:
                BRAND === "hubhealth"
                  ? theme.color.third
                  : theme.color.secondary,
              height: "6px",
              borderRadius: "100px",
              [theme.mq.sm]: {
                marginTop: "11px",
                marginBottom: "11px"
              }
            })}
          >
            <div
              css={(theme) => ({
                width: `${position}%`,
                background: theme.color.primary,
                height: "6px",
                borderRadius: "100px"
              })}
            ></div>
          </div>
        )}
        {isEnd && isShowNewPaymentPage && (
          <div
            className="col-lg-0 col-12"
            css={{
              textAlign: "center"
            }}
          >
            <div className="w-100">
              <hr
                css={{
                  color: "#737373",
                  width: "200px",
                  opacity: "1",
                  marginLeft: "auto",
                  marginRight: "auto",
                  paddingBottom: "25px"
                }}
              />
            </div>
            <h3 css={{ fontWeight: "400" }}>
              Thanks for completing your online assessment
            </h3>
          </div>
        )}
        {isEnd && isShowNewPaymentPage && (
          <Flex
            flexDirection="row"
            justifyContent="center"
            style={{
              paddingTop: "6px",
              paddingBottom: "6px"
            }}
          >
            <div
              css={(theme) => ({
                display: "block",
                paddingTop: "6px",
                paddingBottom: "6px",
                textAlign: "center",
                [theme.mq.md]: {
                  textAlign: "unset",
                  display: "flex"
                }
              })}
            >
              <div
                className="d-block d-md-none"
                css={{
                  fontSize: "11px",
                  fontWeight: "400",
                  maxWidth: "90px"
                }}
              >
                Online assessment
              </div>
              <i
                className="fa fa-check-circle OnlineassessmentIcon"
                css={{
                  position: "relative",
                  zIndex: "99",
                  background: "white"
                }}
                aria-hidden="true"
              ></i>
              <span
                className="d-none d-md-block"
                css={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px"
                }}
              >
                Online assessment
              </span>
            </div>
            <Flex
              justifyContent="center"
              css={(theme) => ({
                paddingLeft: "5px",
                paddingRight: "5px",
                position: "relative",
                width: "47.87px",
                [theme.mq.md]: {
                  width: "72.57px"
                }
              })}
            >
              <hr
                css={(theme) => ({
                  color: theme.color.secondary,
                  width: "300%",
                  height: "1px",
                  opacity: "1",
                  marginTop: "auto",
                  marginBottom: "auto",
                  position: "absolute",
                  bottom: "24%",
                  [theme.mq.md]: {
                    position: "unset",
                    width: "72.57px",
                    bottom: "unset"
                  }
                })}
              />
            </Flex>
            <div
              css={(theme) => ({
                display: "block",
                paddingTop: "6px",
                paddingBottom: "6px",
                textAlign: "center",
                [theme.mq.md]: {
                  textAlign: "unset",
                  display: "flex"
                }
              })}
            >
              <div
                className="d-block d-md-none"
                css={(theme) => ({
                  color: theme.color.primary,
                  fontSize: "11px",
                  fontWeight: "400",
                  width: "90px"
                })}
              >
                Pay consultation fee
              </div>
              <i
                className="fa fa-circle-o PayconsultationfeeIcon"
                css={{
                  position: "relative",
                  zIndex: "99",
                  background: "white"
                }}
                aria-hidden="true"
              ></i>
              <span
                className="d-none d-md-block"
                css={(theme) => ({
                  color: theme.color.primary,
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px"
                })}
              >
                Pay consultation fee
              </span>
            </div>
            <Flex
              justifyContent="center"
              css={(theme) => ({
                paddingLeft: "5px",
                paddingRight: "5px",
                position: "relative",
                width: "47.87px",
                [theme.mq.md]: {
                  width: "72.57px"
                }
              })}
            ></Flex>
            <div
              css={(theme) => ({
                display: "block",
                paddingTop: "6px",
                paddingBottom: "6px",
                textAlign: "center",
                [theme.mq.md]: {
                  textAlign: "unset",
                  display: "flex"
                }
              })}
            >
              <div
                className="d-block d-md-none"
                css={{
                  fontSize: "11px",
                  fontWeight: "400",
                  width: "105px"
                }}
              >
                Order contraceptive pill online
              </div>
              <img
                css={{
                  width: "15px"
                }}
                src="/assets/images/com/progresscheck.svg"
              ></img>
              <span
                className="d-none d-md-block"
                css={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px"
                }}
              >
                Order contraceptive pill online
              </span>
            </div>
          </Flex>
        )}
      </div>
    </>
  );
};
