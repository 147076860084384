export default [
  {
    id: 10010,
    title: "Where should we send your personalised treatment plan?",
    subtitle: "Let’s start with your name and email",
    type: "emailAndName",
    questionType: "common",
    ga_id: "A00010",
    skipForNib: true
  },
  {
    id: 10002,
    title: "What is your Date of Birth?",
    type: "dob",
    questionType: "common",
    ga_id: "A0004",
    skipForNib: true
  },
  {
    id: 10010,
    ga_id: "A10010",
    age: true,
    title:
      "Do you have a parent or guardian who consents to you accessing this service and will attend your consultation with you? ",
    subtitle:
      "We can only provide services to patients under the age of 18 with the consent of a parent or guardian who is present for your consultation.",
    options: [
      {
        id: 1,
        title: "Yes",
        next: [
          {
            id: 1,
            ga_id: "A10010_1",
            title:
              "Please enter the name of the parent or guardian who will attend your consultation with you and their relationship to you (e.g. 'Jane Doe (Mother)')",
            type: "textarea",
            require: "yes"
          }
        ]
      },
      {
        id: 2,
        title: "No",
        end: true,
        message: "Sorry, we are unable to assist you today."
      }
    ],
    type: "button"
  },
  {
    id: 10003,
    title: "What is your address?",
    type: "address",
    questionType: "common",
    ga_id: "A0005"
  }
];
