import { Interpolation } from "@emotion/react";
import { Container, DisplayHeading, Spinner, Theme } from "@mh/components";
import { useContext, useEffect, useState } from "react";
import { Category, NIBDetailPatientContext, OscarAPI } from "@mh/api";
import { NavLink } from "react-router-dom";
import { IconMinus, IconPlus } from "@mh/components";
import { BRAND, Brand, setNIBSearchParams } from "@mh/core";
import { nibCategories } from "@mh/core/src/constants";

interface ProductListProps {
  categories: Category[];
  nibMembershipNumber?: number;
}

interface TreatmentCardProps {
  containerCss?: Interpolation<Theme>;
}

type Section =
  | "General Health"
  | "Skincare"
  | "Women's Health"
  | "Men's Health"
  | "Pregnancy Packs";

const SECTIONS: Section[] = [
  "General Health",
  "Skincare",
  "Women's Health",
  "Men's Health",
  "Pregnancy Packs"
];

// Categories without a section are default to "General Health"
const CATEGORY_MAP: Record<Category["slug"], Section> = {
  thrush: "Women's Health",
  "contraceptive-pill": "Women's Health",
  acne: "Skincare",
  menopause: "Women's Health",
  "morning-after-pill": "Women's Health",
  uti: "Women's Health",
  nuvaring: "Women's Health",
  rosacea: "Skincare",
  eczema: "Skincare",
  "hair-loss": "Men's Health",
  ed: "Men's Health",
  pe: "Men's Health",
  unevenskintone: "Skincare",
  finelineswrinkles: "Skincare",
  psoriasis: "Skincare"
};

const BRAND_DESCRIPTION_MAP: Record<Brand, string> = {
  hubhealth:
    "From everyday health issues to weight loss, skincare and general well-being, we empower Australians to take control of their health with fast, convenient and discreet access to treatments and prescriptions.",
  youly:
    "From sexual health to weight loss, skincare, and everyday well-being, we empower women to take control of their health with fast, convenient and discreet access to treatments and prescriptions.",
  stagger:
    "From weight loss to sexual health and male pattern hair loss, we empower men to take control of their health with fast, convenient, and discreet access to treatments and prescriptions."
};

const ProductList = ({ categories }: ProductListProps) => {
  const { data: nibData } = useContext(NIBDetailPatientContext);

  return (
    <>
      <div
        css={{
          display: "flex",
          alignItems: "flex-start",
          alignContent: "flex-start",
          flexFlow: "row wrap",
          gap: "8px",
          marginTop: "12px"
        }}
      >
        {categories.map((category) => {
          const params = new URLSearchParams({ product: category.slug });

          // Hide category to enter initial questionnaire, but allow to enter repeat questionnaire for existing pt
          if (
            category.slug === "asthma" ||
            category.slug === "pre-travel-consult" ||
            category.slug === "fertility"
          ) {
            return <div key={category.slug}></div>;
          }

          if (category.slug === "renew-a-script") {
            params.set("version", "new");
          }
          if (
            nibData?.nib_membership_number &&
            nibCategories.includes(category.slug)
          ) {
            setNIBSearchParams(params, category.slug);
          }
          return (
            <NavLink
              to={`/questionnaire/?${params.toString()}`}
              state={{ isNavLink: true, from: window.location.pathname }}
              key={category.slug}
              css={{
                border: "2px solid #BADAB1",
                borderRadius: "50px",
                padding: "8px 16px",
                cursor: "pointer",
                textDecoration: "none",
                color: "#000",
                ":hover": {
                  backgroundColor: "#BADAB1"
                }
              }}
            >
              {category.display_name || category.name}
            </NavLink>
          );
        })}
      </div>
    </>
  );
};

const CategorySection = ({
  section,
  categories
}: {
  section: Section;
  categories: Category[];
}) => {
  const [open, setOpen] = useState<boolean>(section === "General Health");
  const sectionCategories = categories.filter((category) =>
    section === "General Health"
      ? !CATEGORY_MAP[category.slug]
      : CATEGORY_MAP[category.slug] === section
  );
  if (sectionCategories.length === 0) return null;
  return (
    <div key={section} css={{ flex: 1, width: "100%" }}>
      <div
        css={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "top",
          cursor: "pointer",
          [theme.mq.md]: {
            cursor: "auto"
          }
        })}
        onClick={() => setOpen(!open)}
      >
        <DisplayHeading size="sm">{section}</DisplayHeading>
        <div>
          {open ? (
            <IconMinus
              css={(theme) => ({
                width: "24px",
                height: "24px",
                color: theme.color.primary,
                [theme.mq.md]: {
                  display: "none"
                }
              })}
            />
          ) : (
            <IconPlus
              css={(theme) => ({
                width: "24px",
                height: "24px",
                color: theme.color.primary,
                [theme.mq.md]: {
                  display: "none"
                }
              })}
            />
          )}
        </div>
      </div>
      <div
        css={(theme) => ({
          display: open ? "flex" : "none",
          [theme.mq.md]: {
            display: "flex"
          }
        })}
      >
        <ProductList categories={sectionCategories} />
      </div>
    </div>
  );
};

export const TreatmentCard = ({ containerCss }: TreatmentCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const patientNIBDetailContext = useContext(NIBDetailPatientContext);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        let categories = await OscarAPI.getCategories();
        categories = categories.filter(
          (category) => !category.hide_in_patient_portal && category.is_enabled
        );
        setCategories(categories);
        if (!patientNIBDetailContext.data) {
          patientNIBDetailContext.get();
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Container
          containerCss={[
            containerCss,
            (theme) => ({
              backgroundColor: theme.color.backgroundLight
            })
          ]}
        >
          <DisplayHeading alignment="start" size="md">
            What we treat
          </DisplayHeading>
          <div
            css={{
              marginBottom: "24px",
              fontSize: "16px"
            }}
          >
            {BRAND_DESCRIPTION_MAP[BRAND]}
          </div>
          <div
            css={(theme) => ({
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "16px",
              [theme.mq.md]: {
                flexFlow: "row nowrap",
                gap: "32px"
              }
            })}
          >
            {SECTIONS.map((section) => (
              <CategorySection
                key={section}
                section={section}
                categories={categories}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  );
};
