import { Flex } from "@mh/components";

interface BloodPressureProps {
  highBlood?: number;
  lowBlood?: number;
}

export const BloodPressureQuestion = (props: BloodPressureProps) => (
  <Flex
    flexDirection="column"
    css={{
      fontSize: "16px"
    }}
  >
    <Flex
      flexDirection="column"
      css={{
        marginBottom: "16px"
      }}
    >
      <label
        htmlFor="highBlood"
        css={{
          fontWeight: "500"
        }}
      >
        Systolic (top number)
      </label>
      <input
        key="highBlood"
        name="highBlood"
        autoComplete="off"
        id="highBlood"
        placeholder="0"
        defaultValue={props?.highBlood}
        autoFocus={true}
        css={{
          padding: "8px !important"
        }}
      />
    </Flex>
    <Flex
      flexDirection="column"
      css={(theme) => ({
        marginBottom: "16px",
        [theme.mq.md]: {
          marginBottom: "24px"
        }
      })}
    >
      <label
        css={{
          fontWeight: "500"
        }}
        htmlFor="lowBlood"
      >
        Diastolic (bottom number)
      </label>
      <input
        key="lowBlood"
        name="lowBlood"
        autoComplete="off"
        id="lowBlood"
        placeholder="0"
        defaultValue={props?.lowBlood}
        css={{
          padding: "8px !important"
        }}
      />
    </Flex>
  </Flex>
);
