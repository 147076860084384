import { ShippingAddressValues } from "@mh/components";

import {
  QuestionResponseCheck,
  QuestionResponseData
} from "@mh/questionnaire/src/hooks";
import {
  ListQuestionTypeParams,
  Question,
  QuestionContentType
} from "@mh/questionnaire/src/types";

import { QCListSelectedResponse } from "./content/QCList";

export enum ResponseActionType {
  REQUIRED = "required"
}

const validateRequiredList = (
  question: Question,
  responseData: QuestionResponseData
): boolean => {
  const otherValue = responseData[question.id]?.other_value;
  if (otherValue) return true;

  // QuestionResponseData doesn't have a more specific type for this
  // because it could be any of the questions response data types before this point
  const listValue: undefined | QCListSelectedResponse =
    responseData[question.id]?.options_selection;
  if (listValue === undefined) return false;

  return Object.entries(listValue).reduce((running, [option, selected]) => {
    if (running) return running;

    const allowOther =
      (question.type_params as ListQuestionTypeParams) ?? false;

    if (option !== "Other" || !allowOther) {
      return selected;
    }
    return false;
  }, false);
};

const validateRequiredAddress = (
  question: Question,
  responseData: QuestionResponseData
): boolean => {
  if (!responseData[question.id]) return false;
  const address: ShippingAddressValues = responseData[question.id];
  return (
    address.line1 !== "" &&
    address.postcode !== "" &&
    address.state !== "" &&
    address.suburb !== ""
  );
};

const validateRequiredBoolean = (
  question: Question,
  responseData: QuestionResponseData
): boolean =>
  responseData[question.id] === true || responseData[question.id] === false;

const validateRequiredType: {
  [key in QuestionContentType]?: (
    question: Question,
    responseData: QuestionResponseData
  ) => boolean;
} = {
  [QuestionContentType.SINGLE_SELECTION_LIST]: validateRequiredList,
  [QuestionContentType.MULTI_SELECTION_LIST]: validateRequiredList,
  [QuestionContentType.ADDRESS]: validateRequiredAddress,
  [QuestionContentType.BOOLEAN]: validateRequiredBoolean,
  [QuestionContentType.INFO]: (_1, _2) => true
};

export const ResponseActions: Record<
  ResponseActionType,
  QuestionResponseCheck
> = {
  [ResponseActionType.REQUIRED]: (
    question: Question,
    responseData: QuestionResponseData
  ): boolean => {
    if (question.required) {
      if (question.type in validateRequiredType) {
        return validateRequiredType[question.type]!(question, responseData);
      }
      return !!responseData[question.id];
    }
    return true;
  }
};
