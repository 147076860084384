import type { APIMethod, Exception } from "./types";
import { client } from "./client";

export interface Id {
  id: number;
}

/** Response types which paginated Django responses return */
export interface PaginatedResponse<Type> {
  count: number;
  next: string | null;
  previous: string | null;
  results: Type[];
}

function objectHasId(obj: any): obj is Id {
  return (
    // eslint-disable-next-line prefer-object-has-own
    typeof obj === "object" && Object.prototype.hasOwnProperty.call(obj, "id")
  );
}

function objectExists<Object extends {}>(object?: Object): object is Object {
  return !!object && Object.keys(object).length > 0;
}

/**
 * Takes in URL parameters and returns a URI string i.e. /patient/1/
 * where Params = { id: number; }
 */
type ParamsUrl<Params extends {}> = (params: Params) => string;

export type QueryParam = string | number;

export type GetRequest<
  Response,
  Params extends {},
  QueryParams extends { [param: string]: QueryParam } = {}
> = (
  params?: Params,
  queryParams?: QueryParams,
  body?: never
) => Promise<Response>;

export type PostRequest<
  Response,
  Request extends {},
  Params extends {} = {}
> = (params?: Params, queryParams?: {}, body?: Request) => Promise<Response>;

export type PatchRequest<
  Response,
  Request extends Id,
  Params extends {},
  QueryParams extends {} | undefined = {}
> = (
  params?: Params,
  queryParams?: QueryParams,
  body?: Request
) => Promise<Response>;

export type DeleteRequest<Response, Params extends Id> = (
  params?: Params
) => Promise<Response>;

/**
 * Takes in response, request, URL parameter and query parameter types and returns which HTTP method this API request
 * uses.
 *
 * The resulting type is decided by:
 *
 * if the request body type is an object:
 *   if the request contains an ID:
 *     "PATCH" - updating an existing item
 *   else:
 *     "POST" - new item to be made
 *
 * "GET" - GET request
 */
export type RequestFn<
  Response,
  Request = undefined,
  Params extends {} = {},
  QueryParams extends { [param: string]: QueryParam } = {}
> = Request extends {}
  ? Request extends Id
    ? PatchRequest<Response, Request, Params, QueryParams>
    : PostRequest<Response, Request, Params>
  : GetRequest<Response, Params, QueryParams>;

/**
 * Constructs query params from a given object, for instance a given object of type
 * { name: string; value: number; thing: string[] }
 * where { name: "hello", value: 5, thing: ["hello", "ben"] }
 * returns "?name=hello&value=5&thing=hello&thing=ben"
 *
 * @param queryParams An object whose keys and values can be arranged into query parameters
 * @returns A query parameters string
 */
function constructQueryParams<
  QueryParams extends { [param: string]: QueryParam }
>(queryParams: QueryParams) {
  function createParam(param: QueryParam) {
    const paramValue = queryParams[param];

    if (Array.isArray(paramValue)) {
      // If an array was passed in for this key, make a query parameters for every item in the array
      return paramValue
        .map((value) => `${param}=${encodeURIComponent(value)}`)
        .join("&");
    }

    return `${param}=${encodeURIComponent(paramValue)}`;
  }

  return `?${Object.keys(queryParams).map(createParam).join("&")}`;
}

/** Constructs a URL for an API request, turning a URL params object into the final URL if needed */
function constructUrl<Params extends {}>(
  url: string | ParamsUrl<Params>,
  params?: Params
) {
  if (typeof url === "function") {
    if (!params) {
      throw new Error(
        "createRequest: url was provided as a function with no params."
      );
    }
    // Call the URL function with the provided parameters
    return url(params);
  }

  // If it's a string, just use that
  return url;
}

export function createRequest<
  ResponseShape,
  RequestShape extends {} | undefined = undefined,
  Params extends {} = {},
  QueryParams extends {} = {}
>(
  url: string | ParamsUrl<Params>
): RequestFn<ResponseShape, RequestShape, Params, QueryParams> {
  const fn = async (
    params?: Params,
    queryParams?: QueryParams,
    body?: RequestShape
  ) => {
    let method: APIMethod = "GET";
    let finalUrl: string;

    if (body) {
      method = objectHasId(body) ? "PATCH" : "POST";
    }

    finalUrl = constructUrl(url, params);

    if (objectExists(queryParams)) {
      finalUrl += constructQueryParams(queryParams);
    }

    return client.request<ResponseShape, RequestShape>(finalUrl, method, body);
  };

  return fn as RequestFn<ResponseShape, RequestShape, Params, QueryParams>;
}

interface PageQueryParams {
  page: number;
}

/** Calls a function which returns an Ember paginated response repeatedly, until the end of pagination has been
 * reached. The returned data is combined into an array and returned.
 */
export function extractPagination<
  Response,
  Params extends {} = {},
  QueryParams extends {} = {}
>(fn: GetRequest<PaginatedResponse<Response>, Params, QueryParams>) {
  return async (
    params?: Params,
    queryParams?: QueryParams,
    /** Body is never used but is kept in place so that useAsync doesn't mess up the arguments */
    _body?: {} | never
  ) => {
    // Perform an initial fetch for the first (of potentially many) paginated data pages
    let page = 1;
    let response = await fn(
      params,
      { ...queryParams, page } as QueryParams & PageQueryParams,
      undefined
    );

    const result = [...response.results];
    while (response.next) {
      // If the response indicates there is more data than was retrieved in this pagination, then search for the next
      // page
      // eslint-disable-next-line no-await-in-loop
      response = await fn(
        params,
        { ...queryParams, page: ++page } as QueryParams & PageQueryParams,
        undefined
      );
      result.push(...response.results);
    }

    return result;
  };
}

// let a = createRequest<{}>("/get/");
// a() does a GET request

// let b = createRequest<{}, { name: string }>("/post/");
// b({ "name": "Ben" }) does a POST request

// let c = createRequest<{}, Id & { name: string }>("/patch/");
// c({ id: 1, name: "Alec" }) does a PATCH request

// Take in function
// If `body` is defined, return a POST request function
// Otherwise return GET

/**
 * Sees whether an API response object has a key called "detail". Django APIExceptions have this field when rendered
 * to JSON
 *
 * @param response The API response object
 * @returns True if the response has a key called "detail", false otherwise
 */
export const responseIsException = (
  response: object
): response is Exception => {
  // eslint-disable-next-line prefer-object-has-own
  return Object.prototype.hasOwnProperty.call(response, "detail");
};
