import { Doctor, Script } from "@mh/api";
import {
  Button,
  Container,
  DisplayHeading,
  HorizontalDivider,
  MedicationDetails
} from "@mh/components";
import { ConfirmationForm, ConfirmationFormProps } from "./ConfirmationForm";

interface PharmacistNoteProps extends ConfirmationFormProps {
  doctor: Doctor | null;
  onClickContinue: () => void;
  script: Script;
}

export const PharmacistNote = ({
  doctor,
  onClickContinue,
  script,
  ...confirmationFormProps
}: PharmacistNoteProps) => (
  <Container borderColor="secondary">
    <div css={{ display: "flex", flexFlow: "column", gap: "8px" }}>
      {doctor && (
        <span css={{ fontSize: "16px" }}>
          {doctor.formal_name} has prescribed:
        </span>
      )}
      <span css={{ fontSize: "24px" }}>{script.product.title}</span>
    </div>
    <HorizontalDivider />
    <DisplayHeading underline="none">Medication Details</DisplayHeading>
    <HorizontalDivider />
    <MedicationDetails script={script} />
    <HorizontalDivider />
    <p css={{ fontWeight: 500 }}>Please confirm before proceeding</p>
    <ConfirmationForm {...confirmationFormProps} />
    <Button
      disabled={
        !confirmationFormProps.readChecked ||
        !confirmationFormProps.questionsChecked
      }
      css={(theme) => ({
        marginTop: "16px",
        width: "100%",
        [theme.mq.md]: {
          width: "50%"
        }
      })}
      onClick={onClickContinue}
    >
      Continue
    </Button>
  </Container>
);
