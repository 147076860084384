import { API } from "@mh/api";
import { createFileFromDataUrl } from "@mh/core";

export interface UploadedImage {
  id: number;
  name: string;
  patient: number;
  image: string;
  questionnaire: number;
  category_image_requirement: number;
}

export type UploadedImageWithFile = { file: File } & UploadedImage;

export interface WaitDate {
  expected_wait_time?: {
    generic_members: number;
    hub_pass_members: number;
    nib_general_telehealth: number;
    nib_international_arhi_members: number;
    vip_members: number;
  };
  earliest_available_booking_slot?: string;
}

/**
 * Example response
 * [
      {
        "id": 78,
        "name": "front",
        "patient": 16618,
        "image": "/media/patients/16618/treatments/10/file_name.png",
        "questionnaire": 19000,
        "category_image_requirement": 30
      },
 * ]
 *
 *
*/

export const getQuestionnaireImages = (
  patientId: number | string,
  questionnaireId: number
): Promise<UploadedImageWithFile[]> =>
  API.url(
    `/api/v1/patients/${patientId}/images?${new URLSearchParams({
      questionnaire: questionnaireId.toString()
    })}`
  )
    .get()
    .then((response) => (response.ok ? response.json() : []))
    .then((json) =>
      Promise.all(
        json.map(async (uploadedImage: UploadedImage) => ({
          ...uploadedImage,
          file: await createFileFromDataUrl(
            uploadedImage.image,
            uploadedImage.name
          )
        }))
      )
    );

/**
 * Update the default payment method for a patient
 * @param param0 An object containing the setup intent id which will be used to update the default payment method
 * @returns
 */
export const uploadImage = async (
  patientId: number,
  questionnaireId: number,
  name: string,
  requirementId: number | undefined,
  file: File
): Promise<UploadedImage> => {
  const formData = new FormData();
  formData.append("questionnaire", questionnaireId?.toString());
  formData.append("name", name);
  formData.append("image", file);
  if (requirementId)
    formData.append("category_image_requirement", requirementId?.toString());

  const response = await API.v1()
    .noContentType()
    .body(formData)
    .url(`/patients/${patientId}/images`)
    .post();

  if (!response.ok) {
    throw response;
  }

  return await response.json();
};

export const generateEscript = async (consultationId: number) =>
  API.v2()
    .url(`/consultation/${consultationId}/generate-escripts/`)
    .body({})
    .post()
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    });

export const getWaitTime = async (): Promise<WaitDate> =>
  API.v2()
    .url("/schedules/queue-time-and-earliest-available-appointment/")
    .unauthenticated()
    .get()
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    });

export const getEmployeeHealthCheckScore = async (data: []) =>
  API.v2()
    .url("/health-check/run")
    .body({ response: data })
    .json()
    .post()
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    });
