import { useState } from "react";

import type { BaseQuestionnaire, Script } from "@mh/api";
import { Button, TreatmentDetails, MedicationDetails } from "@mh/components";

interface ConsultSummaryProps {
  script: Script;
  questionnaire: BaseQuestionnaire;
}

export const ConsultSummary = ({
  script,
  questionnaire
}: ConsultSummaryProps) => {
  const [showTreatmentDetails, setShowTreatmentDetails] = useState(true);

  return (
    <div>
      <div css={{ display: "flex", paddingBottom: "24px" }}>
        <div css={{ backgroundColor: "#F5F5F5", borderRadius: "1000px" }}>
          <Button
            css={{
              padding: "4px 12px",
              fontSize: "14px"
            }}
            border="pill"
            variant={showTreatmentDetails ? "primary" : "primary-plain"}
            onClick={(e) => {
              e.preventDefault();
              setShowTreatmentDetails(true);
            }}
          >
            Treatment details
          </Button>
          <Button
            css={{
              padding: "4px 12px",
              fontSize: "14px"
            }}
            border="pill"
            variant={showTreatmentDetails ? "primary-plain" : "primary"}
            onClick={(e) => {
              e.preventDefault();
              setShowTreatmentDetails(false);
            }}
          >
            Medication details
          </Button>
        </div>
      </div>
      {showTreatmentDetails ? (
        <TreatmentDetails script={script} questionnaire={questionnaire} />
      ) : (
        <MedicationDetails script={script} />
      )}
    </div>
  );
};
