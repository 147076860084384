import { ComponentPropsWithoutRef } from "react";

export const IconCheckCircle = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.1599 26.7689C18.6912 27.2377 17.8318 27.2377 17.363 26.7689L12.363 21.7689C11.8943 21.3002 11.8943 20.4408 12.363 19.972C12.8318 19.5033 13.6912 19.5033 14.1599 19.972L18.3005 24.1127L27.363 14.972C27.8318 14.5033 28.6912 14.5033 29.1599 14.972C29.6287 15.4408 29.6287 16.3002 29.1599 16.7689L19.1599 26.7689ZM40.8005 20.8314C40.8005 31.9252 31.8162 40.8314 20.8005 40.8314C9.70679 40.8314 0.800537 31.9252 0.800537 20.8314C0.800537 9.8158 9.70679 0.831421 20.8005 0.831421C31.8162 0.831421 40.8005 9.8158 40.8005 20.8314ZM20.8005 3.33142C11.113 3.33142 3.30054 11.222 3.30054 20.8314C3.30054 30.5189 11.113 38.3314 20.8005 38.3314C30.4099 38.3314 38.3005 30.5189 38.3005 20.8314C38.3005 11.222 30.4099 3.33142 20.8005 3.33142Z"
      fill="currentColor"
    />
  </svg>
);
