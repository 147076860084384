import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@mh/components";
import { ContactUsModal } from "@mh/messaging";

import { Status } from "./Status";

interface RenewStatusProps {
  status: "Follow-up required" | "New Script Required";
  categoryName: string;
  id: number;
}

const FollowupRequired = (props: RenewStatusProps) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return (
    <Status
      actions={
        <>
          <NavLink
            to={`/questionnairerepeats/${props.categoryName}/${props.id}?requestchange=true`}
          >
            <Button css={{ width: "100%" }}>Request change</Button>
          </NavLink>
          <NavLink
            to={`/questionnairerepeats/${props.categoryName}/${props.id}`}
          >
            <Button data-testid="renew-script" css={{ width: "100%" }}>
              Renew treatment
            </Button>
          </NavLink>
        </>
      }
      title="FOLLOW-UP REQUIRED"
      titleVariant="danger"
    >
      <p>
        Our medical team need to complete a quick check-in before we can renew
        your treatment. Use the buttons below to renew your existing treatment
        or request a change.
      </p>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
    </Status>
  );
};

const NewScriptRequired = (props: RenewStatusProps) => {
  return (
    <Status
      actions={
        <NavLink to={`/questionnairerepeats/${props.categoryName}/${props.id}`}>
          <Button data-testid="renew-script" css={{ width: "100%" }}>
            Renew treatment
          </Button>
        </NavLink>
      }
      title="NEW SCRIPT REQUIRED"
      titleVariant="danger"
    >
      <p>
        Our pharmacy requires a new script to process your recent order. Please
        use the button ‘Renew’ to complete a quick check-in.
      </p>
    </Status>
  );
};

const RENEW_STATUS_MAP: Record<
  RenewStatusProps["status"],
  (props: RenewStatusProps) => JSX.Element
> = {
  "Follow-up required": FollowupRequired,
  "New Script Required": NewScriptRequired
};

export const RenewStatus = (props: RenewStatusProps) => {
  return <>{RENEW_STATUS_MAP[props.status](props)}</>;
};
