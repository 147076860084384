import { type FC, useState } from "react";
import { QuantityWithPrice } from "./QuantityWithPrice";

export interface QuantityAndBasketProps {
  isAdded: boolean;
  onAdd: (quantity: number) => void;
  onRemove: () => void;
  onInfoPressed?: () => void;
  maxQuantity: number;
  isLoading: boolean;
  itemPrice?: number;
}

export const QuantityAndBasket: FC<QuantityAndBasketProps> = (props) => {
  const [quantity, setQuantity] = useState<number>(1);

  const handleButtonClick = (): void => {
    if (!props.isLoading) {
      if (props.isAdded) {
        props.onRemove();
      } else {
        props.onAdd(quantity);
      }
    }
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "12px"
      }}
    >
      <QuantityWithPrice
        quantity={quantity}
        onQuantityChange={setQuantity}
        maxQuantity={props.maxQuantity}
        totalPrice={
          props.itemPrice ? (props.itemPrice * quantity).toFixed(2) : undefined
        }
        onInfoPressed={props.onInfoPressed}
        handleCartButtonClick={handleButtonClick}
        isLoading={props.isLoading}
        isAdded={props.isAdded}
      />
    </div>
  );
};
