import { LoadedProduct } from "../utils";

import { type OexaScriptStatus } from "@mh/api";
import { Flex } from "@mh/components";

import { EscriptProductSelectorRadioButton } from "./EscriptProductSelectorRadioButton";
import { EscriptProductSelectorTitle } from "./EscriptProductSelectorTitle";
import { EscriptProductSelectorQrCode } from "./EscriptProductSelectorQrCode";
import { EscriptProductSelectorContents } from "./EscriptProductSelectorContents";
import { EscriptProductSelectorFooterLink } from "./EscriptProductSelectorFooterLink";

interface IsValidScriptStatusProps {
  type: string;
  status?: OexaScriptStatus;
}

interface EscriptProductSelectorProps {
  brand: string;
  brandPill: { title: string } | undefined;
  scriptToken: string;
  qrToken: string | undefined;
  eScriptTokenStatus: string | undefined;
  medicationList?: LoadedProduct[];
  eScriptName?: string;
  eScriptRemaining?: number;
  onChange: (selectedOption: LoadedProduct) => void;
}

export const isValidScriptStatus = (props: IsValidScriptStatusProps) =>
  !props.type ||
  props.type !== "newMedicationDetail" ||
  (props.type === "newMedicationDetail" && props.status === "Found");

export const EscriptProductSelector = (props: EscriptProductSelectorProps) => {
  const loader = document.getElementById("cover-spin");
  if (!props.eScriptTokenStatus) {
    if (loader) loader.className = "show";
    return <></>;
  }
  if (loader) loader.className = loader.className.replace(/show/g, "");

  const items =
    props.medicationList &&
    EscriptProductSelectorRadioButton({
      medicationList: props.medicationList,
      title: props.brandPill?.title,
      onChange: props.onChange
    });

  return (
    <Flex
      flexDirection="column"
      css={{
        marginTop: "8px"
      }}
    >
      <EscriptProductSelectorTitle
        eScriptTokenStatus={props.eScriptTokenStatus}
      />
      <div
        className="d-flex flex-md-row flex-column"
        css={(theme) => ({
          width: "100%",
          [theme.mq.md]: {
            width: props.eScriptTokenStatus === "Found" ? "100%" : "431px",
            marginLeft: "auto",
            marginRight: "auto"
          }
        })}
      >
        {props.eScriptTokenStatus === "Found" && (
          <EscriptProductSelectorQrCode scriptToken={props.scriptToken} />
        )}
        <EscriptProductSelectorContents
          scriptToken={props.scriptToken}
          eScriptTokenStatus={props.eScriptTokenStatus ?? undefined}
          name={props.brandPill?.title ?? props.eScriptName ?? "N/A"}
          eScriptRemaining={props.eScriptRemaining}
        />
      </div>
      <Flex>
        {props.eScriptTokenStatus === "Found" && props.medicationList ? (
          <Flex flexDirection="column" css={{ width: "100%" }}>
            <Flex
              flexDirection="column"
              css={(theme) => ({
                width: "200px",
                marginBottom: "24px",
                borderTop: "1px solid #737373",
                marginLeft: "auto",
                marginRight: "auto",
                [theme.mq.sm]: {
                  marginBottom: "32px"
                }
              })}
            />
            <Flex
              css={(theme) => ({
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "24px",
                fontSize: "20px",
                fontWeight: "400",
                color: theme.color.primary
              })}
            >
              Which brand do you prefer?
            </Flex>
            <Flex flexDirection="column">{items}</Flex>
          </Flex>
        ) : props.eScriptTokenStatus === "Found" ? (
          <></>
        ) : (
          <EscriptProductSelectorFooterLink brand={props.brand} />
        )}
      </Flex>
    </Flex>
  );
};
