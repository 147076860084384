import { HOST } from "@mh/core";
import {
  nibInternationalSearchParam,
  workerSearchParam
} from "@mh/core/src/constants";

export const prependBackendHostIfRequired = (url: string): string => {
  if (url.startsWith("http")) {
    return url;
  }
  return `${HOST}${url}`;
};

export const setNIBSearchParams = (params: URLSearchParams, slug: string) => {
  params.set("from", nibInternationalSearchParam);
  if (slug === "medicalcertificates" || slug === "telehealth-consult") {
    params.set("variant", workerSearchParam);
  }
  return params;
};

export const redirectTo = (url: string): void => {
  window.location.href = url;
};
