import { isInt, isNum } from "../utils/NumberUtils";
import { isStr } from "../utils/StringUtils";
import { CommonRegEx } from "./CommonRegEx";
import { ErrorMessages } from "./ErrorMessages";
import { PhoneNumberUtil } from "google-libphonenumber";

export type ValidationInput = string | null | undefined;
export interface ValidationOptions {
  name?: string; // For use when an error returns a custom string using the name
  message?: string; // Replace the whole returned error string with the provided message
}
export type ValidationResult = string | null;
export type ValidationFn = (s: ValidationInput) => ValidationResult;
type ErrorFn = (s?: string) => string;

interface ValidationState {
  validators: ValidationFn[];
}

interface RangeOptions {
  lt?: number;
  ltEq?: number;

  gt?: number;
  gtEq?: number;

  eq?: number;
}

/**
 * The Validation class is designed to allow for easy chaining of common validation requirements
 * with the goal of been able to return the most relevant error at each point in the process
 */
class ConcreteValidation {
  private state: ValidationState;
  constructor(state?: ValidationState) {
    this.state = state ?? { validators: [] };
  }

  #chain = (fn: ValidationFn): ConcreteValidation =>
    new ConcreteValidation({ validators: [...this.state.validators, fn] });

  #err = (err: string | ErrorFn, o?: ValidationOptions): string => {
    if (o?.message) return o.message;
    if (isStr(err)) return err as string;
    return (err as ErrorFn)(o?.name);
  };

  validate = (
    i: ValidationInput,
    options: {
      noTrim?: boolean;
      optional?: boolean;
    } = {}
  ): ValidationResult => {
    if (options.optional && (i === "" || i === null || i === undefined)) {
      return null;
    }
    const parsedI = options.noTrim ? i : i?.trim();
    return this.state.validators.reduce<ValidationResult>(
      (p, fn) => (p === null ? fn(parsedI) : p),
      null
    );
  };

  validateAll = (i: ValidationInput): ValidationResult[] =>
    this.state.validators.map<ValidationResult>((fn) => fn(i));

  /**
   * Validation functions
   */
  required = (o?: ValidationOptions) =>
    this.string(o).#chain((i: ValidationInput) =>
      i === "" ? this.#err(ErrorMessages.form.required, o) : null
    );

  string = (o?: ValidationOptions) =>
    this.#chain((i: ValidationInput) =>
      i === null || i === undefined
        ? this.#err(ErrorMessages.form.required, o)
        : null
    );

  match = (other: string, otherName?: string, o?: ValidationOptions) =>
    this.#chain((i: ValidationInput) =>
      i === other
        ? null
        : this.#err(
            (name?: string) =>
              ErrorMessages.form.match(otherName ?? "other", name),
            o
          )
    );

  notMatch = (other: string, otherName?: string, o?: ValidationOptions) =>
    this.#chain((i: ValidationInput) =>
      i !== other
        ? null
        : this.#err(
            (name?: string) =>
              ErrorMessages.form.match(otherName ?? "other", name),
            o
          )
    );

  int = (o?: ValidationOptions) =>
    this.#chain((i: ValidationInput) =>
      isInt(i ?? "") ? null : this.#err(ErrorMessages.form.int, o)
    );

  num = (o?: ValidationOptions) =>
    this.#chain((i: ValidationInput) =>
      isNum(i ?? "") ? null : this.#err(ErrorMessages.form.num, o)
    );

  regex = (pattern: string | RegExp, o?: ValidationOptions) =>
    this.#chain((i: ValidationInput) =>
      RegExp(pattern).test(i ?? "")
        ? null
        : this.#err(ErrorMessages.form.pattern, o)
    );

  email = (o?: ValidationOptions) =>
    this.regex(CommonRegEx.email, {
      name: "email",
      ...o
    });

  custom = (fn: ValidationFn) => this.#chain(fn);

  medicare = (o?: ValidationOptions) =>
    this.required({ ...o, name: "Medicare number" })
      .strLen({ eq: 10 }, { ...o, name: "Medicare number" })
      .num({ ...o, message: ErrorMessages.form.medicare })
      .#chain((i) => {
        // Following specification provided by the document available here
        // https://developer.digitalhealth.gov.au/specifications/national-infrastructure/ep-1826-2014/nehta-1732-2014
        // As at 16/01/23
        const errStr = this.#err(ErrorMessages.form.medicare, o);
        const cardIdentifier = [...i!].map((c) => parseInt(c));
        const checkSumDigit = parseInt(i![8]);
        if (cardIdentifier[0] < 2 || cardIdentifier[0] > 6) return errStr;
        const checkSumWeights = [1, 3, 7, 9, 1, 3, 7, 9];
        // Multiply each item at the same index by the weight and summate the result
        const checkSum = checkSumWeights.reduce(
          (p, c, idx) => p + c * cardIdentifier[idx],
          0
        );
        if (checkSum % 10 !== checkSumDigit) return errStr;
        return null;
      });

  dva = (o?: ValidationOptions) =>
    this.required({ ...o, name: "DVA number" })
      // see: https://github.com/medipass/validate-dva-number/blob/master/index.js
      .regex(
        /^[N,V,Q,S,W,T]([A-Z ][0-9]{1,6}|[A-Z]{2}[0-9]{1,5}|[A-Z]{3}[0-9]{1,4})[A-Z]?$/,
        { message: "Provided DVA number is invalid." }
      );

  mobile = (o?: ValidationOptions) =>
    this.custom((input: ValidationInput) => {
      const err = o?.message ?? `Invalid ${o?.name ?? "phone number"} provided`;
      const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();

      try {
        const phone = phoneUtil.parseAndKeepRawInput(input ?? "");
        return phoneUtil.isValidNumber(phone) ? null : err;
      } catch (e) {
        return err;
      }
    });

  charConditionCount = (
    condition: (s: string) => boolean,
    opts: RangeOptions,
    o?: ValidationOptions
  ) =>
    this.string().#chain((i) => {
      const count: number = [...(i as string)].reduce(
        (count, char) => count + (condition(char) ? 1 : 0),
        0
      );
      const checks: [boolean, string][] = [];
      if (opts.lt)
        checks.push([
          count < opts.lt!,
          this.#err((name) => ErrorMessages.form.maxLen(opts.lt!, name), o)
        ]);
      if (opts.ltEq)
        checks.push([
          count <= opts.ltEq!,
          this.#err((name) => ErrorMessages.form.maxLen(opts.ltEq!, name), o)
        ]);
      if (opts.gt)
        checks.push([
          count > opts.gt!,
          this.#err((name) => ErrorMessages.form.minLen(opts.gt!, name), o)
        ]);
      if (opts.gtEq)
        checks.push([
          count >= opts.gtEq!,
          this.#err((name) => ErrorMessages.form.minLen(opts.gtEq!, name), o)
        ]);
      if (opts.eq)
        checks.push([
          count === opts.eq!,
          this.#err((name) => ErrorMessages.form.eqLen(opts.eq!, name), o)
        ]);

      return checks.reduce<string | null>(
        (p, [result, err]) => (p === null ? (result ? null : err) : p),
        null
      );
    });

  strLen = (opts: RangeOptions, o?: ValidationOptions) =>
    this.charConditionCount((_) => true, opts, o);

  charRangeMatch = (
    pattern: RegExp,
    opts: RangeOptions,
    o?: ValidationOptions
  ) => this.charConditionCount((s) => pattern.test(s), opts, o);

  intOccurrenceRange = (opts: RangeOptions, o?: ValidationOptions) =>
    this.charRangeMatch(/\d/, opts, o);

  upperCaseOccurrenceRange = (opts: RangeOptions, o?: ValidationOptions) =>
    this.charRangeMatch(/[A-Z]/, opts, o);

  lowerCaseOccurrenceRange = (opts: RangeOptions, o?: ValidationOptions) =>
    this.charRangeMatch(/[a-z]/, opts, o);

  specialCharOccurrenceRange = (opts: RangeOptions, o?: ValidationOptions) =>
    this.charRangeMatch(/[^a-zA-Z0-9]/, opts, o);

  name = (o?: ValidationOptions) => this.required(o).regex(CommonRegEx.name, o);

  ihi = (o?: ValidationOptions) =>
    this.required({ ...o, name: "IHI number" }).regex(/(?<!\d)\d{16}(?!\d)/, {
      message: "Provided IHI number is invalid."
    });
}

export const Validation = new ConcreteValidation();
