import { ComponentPropsWithoutRef, FC } from "react";
import { Brand, BRAND } from "@mh/core";
import { nibInternationalSearchParam } from "@mh/core/src/constants";
import { LogoHubHealth } from "./LogoHubHealth";
import { LogoHubHealthNib } from "./LogoHubHealthNib";
import { LogoStagger } from "./LogoStagger";
import { LogoYouly } from "./LogoYouly";
import { LogoHubHealthMember } from "./LogoHubHealthMember";
import { LogoYoulyMember } from "./LogoYoulyMember";
import { LogoStaggerMember } from "./LogoStaggerMember";

interface Props extends ComponentPropsWithoutRef<"svg"> {
  customBrand?: string;
  isMember?: boolean;
}

const BRAND_LOGO_MAP: Record<Brand, FC<ComponentPropsWithoutRef<"svg">>> = {
  hubhealth: LogoHubHealth,
  stagger: LogoStagger,
  youly: LogoYouly
};

const BRAND_LOGO_MEMBER_MAP: Record<
  Brand,
  FC<ComponentPropsWithoutRef<"svg">>
> = {
  hubhealth: LogoHubHealthMember,
  stagger: LogoStaggerMember,
  youly: LogoYoulyMember
};

/**
 * Convenience component that returns the correct Logo for the
 * BRAND environment variable.
 * If BRAND is not set or not a valid brand, will return the
 * hub-health logo.
 */

const getLogo = (props: Props) => {
  if (props?.customBrand === nibInternationalSearchParam) {
    return LogoHubHealthNib;
  }

  if (props?.isMember) {
    return BRAND_LOGO_MEMBER_MAP[BRAND] || LogoHubHealthMember;
  }

  return BRAND_LOGO_MAP[BRAND] || LogoHubHealth;
};

export const LogoBrand = (props: Props) => {
  const Logo = getLogo(props);
  // we skip passing cutomBrand to the svg element to avoid a warning
  return (
    <Logo
      width={props.width}
      height={props.height}
      className={props.className}
    />
  );
};
