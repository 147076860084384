import React from "react";
import { Form, FormSelectProps } from "react-bootstrap";

import { EditableBase, EditableBaseProps } from "./EditableBase";

export type EditableFormSelectProps = FormSelectProps & EditableBaseProps;

/**
 * Will attempt to pick out the content of the currently selected `<option />` to use as the non-editable component.
 */
const DefaultNonEditableSelectComponent = (
  props: Pick<EditableFormSelectProps, "children" | "value">
) => {
  const { children, value } = props;
  let displayValue = null;
  React.Children.toArray(children).forEach((child) => {
    if (React.isValidElement(child)) {
      if (child.type === "option" && child.props.value === value) {
        displayValue = child.props.children;
      }
    }
  });
  return displayValue || <span>{"\u200b"}</span>;
};

export const EditableFormSelect = React.forwardRef<
  HTMLSelectElement,
  EditableFormSelectProps
>((props, ref) => {
  const { isEditing, nonEditableComponent, ...formSelectProps } = props;
  return (
    <EditableBase
      isEditing={isEditing}
      nonEditableComponent={
        nonEditableComponent || <DefaultNonEditableSelectComponent {...props} />
      }
    >
      <Form.Select as="select" ref={ref} {...formSelectProps} />
    </EditableBase>
  );
});
