import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BRAND } from "@mh/core";
import { Button } from "../buttons";
import { IconBars, IconClose } from "../icons";
import { Overlay } from "../Overlay";
import { NotificationDot } from "../notifications";
import { PageContainer } from "../containers";
import { LogoBrand } from "../logos";
import { useMembership } from "../../../hooks";

interface MenuItemProps {
  /** Icon to render to the left of the text. */
  icon: ReactNode;
  /** If set, will render a NotificationIndicator next to the text. */
  notification?: boolean;
  /** Optional onClick event handler. Note: does not replace navigation event. */
  onClick?: () => void;
  /** Text to display in the menu item. */
  text: string;
  /** The route to navigate to when clicked. Also determines the active state when the route matches it. */
  to: string;
}

const MenuItem = ({ text, icon, notification, onClick, to }: MenuItemProps) => (
  <NavLink
    css={{
      textDecoration: "none"
    }}
    onClick={onClick}
    to={to}
  >
    {({ isActive }) => (
      <Button fullWidth variant={isActive ? "primary" : "primary-plain"}>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            ">svg": {
              width: "16px"
            }
          }}
        >
          {icon}
          {text}
          {notification && <NotificationDot />}
        </div>
      </Button>
    )}
  </NavLink>
);

const Menu = ({ children }: PropsWithChildren) => (
  <div
    css={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "4px"
    }}
  >
    {children}
  </div>
);

const Spacer = () => <div css={{ marginBottom: "16px" }} />;

const Divider = () => (
  <div
    css={{
      width: "1px",
      height: "70%",
      backgroundColor: "#737373",
      padding: "8px 0px"
    }}
  />
);

interface NavigationProps {
  /** Actions section rendered to the right of the page header. */
  actions: ReactNode;
  /**
   * Navigation menu.
   * On mobile, shows as a fullscreen modal when clicking the menu icon in the page header.
   * On desktop, always shows to the left of the page.
   */
  menu?: ReactNode;
  /** Title section rendered to the left of the page header. */
  title: ReactNode;
}

export const Navigation = ({
  actions,
  children,
  menu,
  title
}: PropsWithChildren<NavigationProps>) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const location = useLocation();

  /** Close the menu when the location changes. */
  useEffect(() => setShowMenu(false), [location]);

  const membershipStatus = useMembership();

  return (
    <>
      <div
        data-testid="navigation-container"
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          [theme.mq.md]: {
            flexDirection: "row"
          }
        })}
      >
        <div
          css={(theme) => ({
            display: "flex",
            flexDirection: "column",
            position: "sticky",
            top: 0,
            left: 0,
            backgroundColor: "#FAFAFA",
            boxShadow: "0px 3px 5px #ADADAD",
            zIndex: 1,
            [theme.mq.md]: {
              width: "260px",
              height: "100vh",
              boxShadow: "none"
            }
          })}
        >
          {menu && (
            <div
              data-testid="navigation-menu"
              css={(theme) => ({
                display: "none",
                [theme.mq.md]: {
                  display: "block",
                  overflow: "auto",
                  height: "100%",
                  padding: "48px 16px 0px"
                }
              })}
            >
              <LogoBrand
                isMember={membershipStatus === "active"}
                css={{ width: "180px", marginBottom: "32px" }}
              />
              {menu}
            </div>
          )}
        </div>
        <div
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white"
          }}
        >
          <PageContainer>
            <PageContainer.Header sticky>
              <div
                css={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <div
                  css={(theme) => ({
                    display: "none",
                    [theme.mq.md]: {
                      display: "inline"
                    }
                  })}
                >
                  {title}
                </div>
                <div
                  css={(theme) => ({
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                    [theme.mq.md]: {
                      display: "none"
                    }
                  })}
                >
                  <Button
                    data-testid="navigation-menu-button"
                    onClick={() => setShowMenu(true)}
                    variant="primary-plain"
                  >
                    <IconBars css={{ height: "16px" }} />
                  </Button>
                  <LogoBrand
                    isMember={membershipStatus === "active"}
                    css={{
                      height: "100%",
                      maxHeight: "40px",
                      width: "100%",
                      maxWidth: "120px"
                    }}
                  />
                </div>
                {actions}
              </div>
            </PageContainer.Header>
            <div
              css={(theme) => ({
                [theme.mq.md]: {
                  display: "none"
                }
              })}
            >
              <PageContainer.SubHeader>
                <span
                  css={(theme) => ({
                    color:
                      BRAND === "hubhealth"
                        ? theme.color.dark
                        : theme.color.light
                  })}
                >
                  {title}
                </span>
              </PageContainer.SubHeader>
            </div>
            <PageContainer.Content
              contentCss={
                location.pathname.startsWith("/shop") &&
                ((theme) => ({
                  [theme.mq.md]: {
                    margin: "0px auto"
                  }
                }))
              }
            >
              {children}
            </PageContainer.Content>
          </PageContainer>
        </div>
      </div>
      <Overlay
        onClick={() => setShowMenu(false)}
        onKeyDown={(e) => e.code === "Escape" && setShowMenu(false)}
        show={showMenu}
      >
        <div
          css={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "32px",
            flex: 1,
            backgroundColor: "#FAFAFA",
            overflow: "hidden",
            paddingTop: "48px",
            ">*": {
              paddingLeft: "24px",
              paddingRight: "24px"
            }
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: "40px"
            }}
          >
            <LogoBrand
              isMember={membershipStatus === "active"}
              css={{
                height: "100%",
                maxHeight: "40px",
                width: "100%",
                maxWidth: "120px"
              }}
            />
            <Button onClick={() => setShowMenu(false)} variant="primary-plain">
              <IconClose css={{ display: "block", width: "21px" }} />
            </Button>
          </div>
          <div
            data-testid="navigation-menu"
            css={{ flex: 1, overflow: "auto" }}
          >
            {menu}
          </div>
        </div>
      </Overlay>
    </>
  );
};

Navigation.Menu = Menu;
Navigation.MenuItem = MenuItem;
Navigation.Divider = Divider;
Navigation.Spacer = Spacer;
