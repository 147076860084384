import { ComponentPropsWithoutRef, forwardRef } from "react";
import { CSSObject, Interpolation } from "@emotion/react";
import { Color, Theme } from "../theme";
import { Size } from "../types";
import { classNames } from "../utils";

type VariantColor = keyof Theme["color"];
type VariantType = "" | "outline" | "plain" | "text";
type Variant = `${VariantColor}${"" | "-outline" | "-plain" | "-text"}`;
type VariantStyle = CSSObject & {
  /** Class for active state. */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  "&.active:not(:disabled), &:active:not(:disabled)": CSSObject;
  /** Class for disabled state. */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  "&:disabled": CSSObject;
  /** Class for hover state. */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  "&:hover:not(:disabled):not(:active):not(.active)": CSSObject;
};

const DISABLED_GRAY = "#9FA5AA";

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  /** If true, shows the button in the active state. */
  active?: boolean;
  /** Rounded border or pill-style border. */
  border?: "pill" | "rounded";
  /** Default color of the children. */
  color?: Color;
  /** Custom styling. */
  css?: Interpolation<Theme>;
  /** If true, make the button full width (i.e. width: 100%). */
  fullWidth?: boolean;
  /** An optional size variant of the button. */
  size?: Size;
  /** The variant of the button style to use. */
  variant?: Variant;
}

const mainStyle = (color: Color | undefined): VariantStyle => ({
  fontWeight: "500",
  backgroundColor: color,
  border: `0.5px solid ${color}`,
  color: "white",
  "&.active:not(:disabled), &:active:not(:disabled)": {
    backgroundImage: "linear-gradient(rgb(0 0 0/10%) 0 0)"
  },
  "&:disabled": {
    backgroundColor: DISABLED_GRAY,
    border: `0.5px solid ${DISABLED_GRAY}`
  },
  "&:hover:not(:disabled):not(:active):not(.active)": {
    backgroundImage: "linear-gradient(rgb(0 0 0/5%) 0 0)"
  }
});

const outlineStyle = (color: Color | undefined): VariantStyle => ({
  backgroundColor: "#FFF",
  border: `0.5px solid ${color}`,
  color: "black",
  "&.active:not(:disabled), &:active:not(:disabled)": {
    backgroundImage: "linear-gradient(rgb(0 0 0/10%) 0 0)"
  },
  "&:disabled": {
    border: `0.5px solid ${DISABLED_GRAY}`,
    color: DISABLED_GRAY
  },
  "&:hover:not(:disabled):not(:active):not(.active)": {
    backgroundImage: "linear-gradient(rgb(0 0 0/5%) 0 0)"
  }
});

const plainStyle = (
  color: CSSObject["color"],
  props: ButtonProps
): VariantStyle => ({
  backgroundColor: "transparent",
  border: "0.5px solid transparent",
  color: props.color || "black",
  "&.active:not(:disabled), &:active:not(:disabled)": {
    color
  },
  "&:disabled": {
    color: DISABLED_GRAY
  },
  "&:hover:not(:disabled):not(:active):not(.active)": {
    color
  }
});

const textStyle = (color: CSSObject["color"]): VariantStyle => ({
  background: "none",
  border: "none",
  margin: 0,
  padding: 0,
  color,
  "&.active:not(:disabled), &:active:not(:disabled)": {
    color
  },
  "&:disabled": {
    color: DISABLED_GRAY
  },
  "&:hover:not(:disabled):not(:active):not(.active)": {
    color,
    textDecoration: "underline"
  }
});

const BORDER_MAP: Record<NonNullable<ButtonProps["border"]>, CSSObject> = {
  pill: {
    borderRadius: "10000px" // Very large border radius result in a pill shaped border
  },
  rounded: {
    borderRadius: "4px"
  }
};

const SIZE_MAP: Record<Size, CSSObject> = {
  sm: {
    fontSize: "14px",
    padding: "4px 8px"
  },
  md: {
    fontSize: "18px",
    padding: "8px 16px"
  },
  lg: {
    fontSize: "20px",
    padding: "12px 20px"
  }
};

const getVariantStyle = (theme: Theme, props: ButtonProps): CSSObject => {
  const [variantColor, variantType] = props.variant?.split("-", 2) as [
    VariantColor,
    VariantType
  ];
  const color = theme.color[variantColor];
  switch (variantType) {
    case "outline":
      return outlineStyle(color);
    case "plain":
      return plainStyle(color, props);
    case "text":
      return textStyle(color);
    default:
      return mainStyle(color);
  }
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      active = false,
      border = "pill",
      children,
      className,
      color,
      css,
      fullWidth = false,
      size = "md",
      variant = "primary",
      ...buttonProps
    },
    ref
  ) => (
    <button
      className={classNames(active && "active", className)}
      css={[
        (theme) => ({
          whiteSpace: "nowrap",
          textAlign: "center",
          ":not(:disabled)": {
            cursor: "pointer"
          },
          width: fullWidth ? "100%" : undefined,
          ...BORDER_MAP[border],
          ...SIZE_MAP[size],
          ...getVariantStyle(theme, { color, variant })
        }),
        css
      ]}
      ref={ref}
      {...buttonProps}
    >
      {children}
    </button>
  )
);
