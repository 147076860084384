import { ComponentPropsWithoutRef } from "react";

export const IconMessagesOutline = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.9336 4.2627H14.9336V5.2627H18.9336C19.4648 5.2627 19.9336 5.73145 19.9336 6.2627V12.2627C19.9336 12.8252 19.4648 13.2627 18.9336 13.2627H16.9336V14.8252L14.1836 13.2627H10.9336C10.3711 13.2627 9.93359 12.8252 9.93359 12.2627V11.2627H8.93359V12.2627C8.93359 13.3877 9.80859 14.2627 10.9023 14.2627H13.9336L17.3086 16.2002C17.4023 16.2627 17.4648 16.2627 17.5586 16.2627C17.7461 16.2627 17.9336 16.1377 17.9336 15.8877V14.2627H18.9336C20.0273 14.2627 20.9336 13.3877 20.9336 12.2939V6.2627C20.9336 5.16895 20.0273 4.2627 18.9336 4.2627ZM13.9336 8.2627V2.2627C13.9336 1.16895 13.0273 0.262695 11.9336 0.262695H2.93359C1.80859 0.262695 0.933594 1.16895 0.933594 2.2627V8.2627C0.933594 9.3877 1.80859 10.2627 2.93359 10.2627L3.93359 10.2939V11.8877C3.93359 12.1377 4.08984 12.2627 4.30859 12.2627C4.37109 12.2627 4.43359 12.2627 4.52734 12.2002L7.93359 10.2627H11.9336C13.0273 10.2627 13.9336 9.3877 13.9336 8.2627ZM7.65234 9.2627L4.93359 10.8252V9.2627H2.93359C2.37109 9.2627 1.93359 8.8252 1.93359 8.2627V2.2627C1.93359 1.73145 2.37109 1.2627 2.93359 1.2627H11.9336C12.4648 1.2627 12.9336 1.73145 12.9336 2.2627V8.2627C12.9336 8.8252 12.4648 9.2627 11.9336 9.2627H7.65234Z"
      fill="currentColor"
    />
  </svg>
);
