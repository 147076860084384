import { ComponentPropsWithoutRef } from "react";

export const IconRightTriangle = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.03125 9.03149L3.03125 15.0315C2.60938 15.4534 1.95312 15.594 1.39062 15.3596C0.828125 15.1252 0.453125 14.5627 0.453125 13.9534V1.95337C0.453125 1.34399 0.828125 0.828369 1.39062 0.593994C1.95312 0.359619 2.60938 0.500244 3.03125 0.922119L9.03125 6.92212C9.64062 7.48462 9.64062 8.46899 9.03125 9.03149Z"
      fill="currentColor"
    />
  </svg>
);
