import { PropsWithChildren } from "react";
import { Interpolation } from "@emotion/react";
import { Theme } from "../theme";

interface ContainerProps {
  /** Top border color based on theme color. */
  borderColor?: keyof Theme["color"];
  /** white background */
  isWhiteBg?: boolean;
  containerCss?: Interpolation<Theme>;
}

export const Container = ({
  borderColor,
  isWhiteBg,
  containerCss,
  children
}: PropsWithChildren<ContainerProps>) => (
  <div
    css={(theme) => [
      {
        padding: "24px",
        background: isWhiteBg ? "#FFF" : "#FAFAFA",
        borderRadius: "16px",
        borderTop: borderColor
          ? `2px solid ${theme.color[borderColor]}`
          : undefined
      },
      containerCss
    ]}
  >
    {children}
  </div>
);
