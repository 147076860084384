/**
 * @param {
 * {
 * _class?: string;
 * _classsub?: string;
 * left?: string;
 * midume?: boolean;
 * lefttext?: { content: string; class_: string; }[];
 * children: import("react").ReactNode }
 * } props
 */
const CommRow = (props) => {
  let left = props.noright
    ? `left col-12 text-center fitcontent`
    : props.small
    ? `left col-4 col-xl-2 text-center`
    : props.smallv1
    ? `left col-4 col-lg-2 col-xl-1  text-center`
    : props.midume
    ? `left ${
        props.left === "icon" || props.onerow ? "col-5" : "col-12"
      } col-md-5 col-lg-4 col-xl-3 text-center`
    : props.large
    ? `left ${
        props.left === "icon" || props.onerow ? "col-6" : "col-12"
      } col-md-6 col-lg-5 col-xl-4 text-center`
    : props.xlarge
    ? `left ${
        props.left === "icon" || props.onerow ? "col-6" : "col-12"
      } col-md-6 text-center`
    : props.custom
    ? `left ${props.custom[0].left}`
    : `left ${
        props.left === "icon" || props.onerow ? "col-4" : "col-12"
      } col-md-4 col-lg-3 col-xl-2 text-center`;
  let right = props.left
    ? props.small
      ? `right col-8 col-xl-10`
      : props.smallv1
      ? `right col-8 col-lg-10 col-xl-11`
      : props.midume
      ? `right ${
          props.left === "icon" || props.onerow ? "col-7" : "col-12"
        } col-md-7 col-lg-8 col-xl-9`
      : props.large
      ? `right ${
          props.left === "icon" || props.onerow ? "col-6" : "col-12"
        } col-md-6 col-lg-7 col-xl-8`
      : props.xlarge
      ? `right ${
          props.left === "icon" || props.onerow ? "col-6" : "col-12"
        } col-md-6`
      : props.custom
      ? `right ${props.custom[0].right}`
      : `right ${
          props.left === "icon" || props.onerow ? "col-8" : "col-12"
        } col-md-8 col-lg-9 col-xl-10`
    : "";

  let leftcontent = props.lefttxt
    ? props.lefttxt.map((content, index) => {
        let content_ = content
          ? content.content
            ? content.content
            : content
          : "";
        let content_extra =
          content && content.content_extra ? content.content_extra : "";
        if (content.size_) {
          if (content.size_ === "h1") {
            content_ = <h1>{content_}</h1>;
          } else if (content.size_ === "h2") {
            content_ = <h2>{content_}</h2>;
          } else if (content.size_ === "h3") {
            content_ = <h3>{content_}</h3>;
          }
        }
        return (
          <div
            className={
              content.class_
                ? `${content.class_} column-${index}`
                : `column-${index}`
            }
            key={index}
          >
            {content_}
            <br></br>
            {content_extra ? content_extra : ""}
          </div>
        );
      })
    : "";

  return (
    <div className={props._class}>
      <div className="row">
        {props.left ? (
          <div className={`${left} ${props._classsub ? props._classsub : ""}`}>
            {props.left === "img" ? (
              <img
                src={props.img}
                className={props._imgclass ? props._imgclass : "fullwidth"}
              />
            ) : props.left === "icon" ? (
              <>
                {typeof props.icon === "string" ? (
                  <div className={`${props.iconwrapper} ${props?.iconclass}`}>
                    <i className={props.icon}></i>
                  </div>
                ) : (
                  props.icon
                )}
              </>
            ) : props.left === "txt" ? (
              <div>{leftcontent}</div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {!props.noright && (
          <div
            className={
              props.left
                ? `${props._rightclass ? props._rightclass : ""} ${right} ${
                    props._classsub ? props._classsub : ""
                  }`
                : `col-12 ${props._classsub ? props._classsub : ""}`
            }
          >
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommRow;
