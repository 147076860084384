import { ComponentPropsWithoutRef } from "react";

export const IconError = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.0766 15.875C13.686 15.875 14.2016 16.3906 14.1547 17C14.1547 17.6562 13.686 18.125 13.0766 18.125C12.4672 18.125 11.9516 17.6562 11.9516 17C11.9516 16.3906 12.4203 15.875 13.0766 15.875ZM13.0766 14C12.6547 14 12.3266 13.6719 12.3266 13.25V6.5C12.3266 6.125 12.7016 5.75 13.0766 5.75C13.4047 5.75 13.7797 6.125 13.7797 6.5V13.25C13.7797 13.6719 13.4516 14 13.0766 14ZM24.7016 17.6562C25.1703 18.4531 25.1703 19.3906 24.7016 20.1875C24.2328 21.0312 23.3891 21.5 22.4516 21.5H3.7016C2.71722 21.5 1.87347 21.0312 1.40472 20.1875C0.935974 19.3906 0.935974 18.4531 1.40472 17.6562L10.7797 1.8125C11.2485 1.01562 12.0922 0.5 13.0766 0.5C14.0141 0.546875 14.8578 1.01562 15.3266 1.8125L24.7016 17.6562ZM23.3891 19.4375C23.6235 19.1094 23.5766 18.7344 23.3891 18.4062L14.0141 2.5625C13.8266 2.23438 13.4516 2.04688 13.0766 2C13.0297 2 13.0766 2 13.0766 2C12.6547 2 12.2797 2.23438 12.0922 2.5625L2.71722 18.4062C2.52972 18.7344 2.48285 19.1094 2.71722 19.4375C2.90472 19.8125 3.27972 20 3.7016 20H22.4047C22.8266 20 23.2016 19.8125 23.3891 19.4375Z"
      fill="#FF0018"
    />
  </svg>
);
