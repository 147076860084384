import { HOST, BRAND } from "@mh/core";
import moment from "moment";
import { APIMethod } from "./types";
import { User } from "./user/user";

export type APIBody = { [key: string]: any };

// For some reason eslint didn't like the typing of BodyInit, so this is the resolved types
type BodyInit =
  | ReadableStream<any>
  | string
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | URLSearchParams;

type RequestInit = Parameters<typeof fetch>[1];

export type CacheOptions = {
  /** The duration in seconds after which to collect a new result */
  cacheDuration?: number;
  /** Normally we don't cache failures, if this is true then we do */
  cacheFailures?: boolean;
};

export type AuthRequirement =
  | "none" // No authentication required - will explicitly not be sent
  | "required" // Authentication required - will throw an exception if not provided
  | "optional"; // Authentication optional - will send if provided

interface APIState {
  url?: string;
  body?: APIBody;
  headers?: Headers;
  authentication: AuthRequirement;
  v1?: boolean;
  v2?: boolean;
  externalAPI?: boolean;
  /** Will this request's body use JSON over form-urlencoded? */
  json?: boolean;
  /** Prevents any modification to the provided body */
  noContentType?: boolean;
  /** Additional fetch options to apply to the request */
  fetchOptions?: RequestInit;
  /** Caching options - only caches ok responses */
  cacheOptions?: CacheOptions;
}

interface APIRequestCacheResult {
  value: Response;
  at: moment.Moment;
}
const apiRequestCache: Map<string, APIRequestCacheResult> = new Map();

export const APIErrorMessages = {
  noURL: "No url provided when making api request",
  unparsableBody: "Provided body could not be encoded correctly",
  noBody: "A body must be provided for a POST request for url",
  noAuth: "API Call attempted without required authorization for url",
  multipleVersions: "Can only use only v1 or v2 in one call",
  externalVersion: "A version can't be specified with the external api",
  contentTypeWithJson:
    "The Content-Type header has been explicitly set, while json is also being used."
};

export const APIDefaultHeaders = {
  "Content-Type": "application/x-www-form-urlencoded"
};

export class ConcreteAPI {
  private state: APIState;
  constructor(state?: APIState) {
    this.state = state ?? { authentication: "required" };
  }

  /**
   * Immutable constructors
   */

  url = (u: string): ConcreteAPI => new ConcreteAPI({ ...this.state, url: u });

  v1 = (): ConcreteAPI => {
    if (this.state.v2) throw new Error(APIErrorMessages.multipleVersions);
    if (this.state.externalAPI)
      throw new Error(APIErrorMessages.externalVersion);
    return new ConcreteAPI({ ...this.state, v1: true });
  };

  v2 = (): ConcreteAPI => {
    if (this.state.v1) throw new Error(APIErrorMessages.multipleVersions);
    if (this.state.externalAPI)
      throw new Error(APIErrorMessages.externalVersion);
    return new ConcreteAPI({ ...this.state, v2: true });
  };

  externalAPI = (): ConcreteAPI => {
    if (this.state.v1 || this.state.v2)
      throw new Error(APIErrorMessages.externalVersion);

    return new ConcreteAPI({ ...this.state, externalAPI: true });
  };

  body = (b: APIBody, force: boolean = false): ConcreteAPI => {
    if (!force) {
      try {
        const result = JSON.stringify(b);
        if (result === undefined) throw new Error();
      } catch (err) {
        let errStr = APIErrorMessages.unparsableBody;
        if (this.state.url) errStr += ` for url ${this.state.url}`;
        throw new Error(errStr);
      }
    }
    return new ConcreteAPI({ ...this.state, body: b });
  };

  headers = (h: ConstructorParameters<typeof Headers>[0]) =>
    new ConcreteAPI({
      ...this.state,
      headers: new Headers(h)
    });

  /**
   * Opt out of using authentication for this request
   */
  unauthenticated = () =>
    new ConcreteAPI({ ...this.state, authentication: "none" });

  /**
   * If the user is logged in, the request will be authenticated.
   * If not, it will not throw an exception.
   */
  authenticateIfProvided = () =>
    new ConcreteAPI({ ...this.state, authentication: "optional" });

  /**
   * Opt in to using a JSON body with this request, this also set the Content-Type to undefined to allow for binary uploads
   */
  json = () => new ConcreteAPI({ ...this.state, json: true });

  noContentType = () => new ConcreteAPI({ ...this.state, noContentType: true });

  fetchOptions = (fetchOptions: RequestInit) =>
    new ConcreteAPI({ ...this.state, fetchOptions });

  cache = (cacheOptions?: CacheOptions) =>
    new ConcreteAPI({ ...this.state, cacheOptions: cacheOptions ?? {} });

  clearCache = () => apiRequestCache.clear();

  /**
   *  Promise Methods
   */
  get = async (): Promise<Response> => this.#makeRequest("GET");
  post = async (force: boolean = false): Promise<Response> =>
    this.#bodyRequiredRequest("POST", force);

  patch = async (force: boolean = false): Promise<Response> =>
    this.#bodyRequiredRequest("PATCH", force);

  put = async (): Promise<Response> => this.#makeRequest("PUT");

  delete = async (): Promise<Response> => this.#makeRequest("DELETE");

  options = async (): Promise<Response> => this.#makeRequest("OPTIONS");

  getFetchUrl = (): string => {
    if (this.state.url === undefined) {
      throw new Error(APIErrorMessages.noURL);
    }

    // Don't prepend the host to URLs already containing the host
    const host =
      this.state.externalAPI || this.state.url.startsWith("http") ? "" : HOST;
    const version = `${this.state.v1 ? "/api/v1" : ""}${
      this.state.v2 ? "/api/v2" : ""
    }`;

    return `${host}${version}${this.state.url}`;
  };

  getFetchContent = (method: APIMethod): RequestInit => {
    const headers = this.#getHeaders();
    return {
      method: method,
      headers: headers,
      body: this.#getBody(headers),
      ...(this.state.fetchOptions || {})
    };
  };

  /**
   * Helpers
   */
  #makeRequest = (method: APIMethod): Promise<Response> =>
    this.#cachedMakeRequest(method, this.state.cacheOptions);

  /**
   * Returns a fetch response, if the result is cached it handles the cacheOptions appropriately and returns the
   * associated correct response
   * @param method the API method to use in the fetch
   * @param cacheOptions the caching options to apply to the request
   * @returns the fetch response, in some cases cloned so that all the streams are in their initial state
   */
  #cachedMakeRequest = async (
    method: APIMethod,
    cacheOptions?: CacheOptions
  ): Promise<Response> => {
    const url = this.getFetchUrl();
    const cacheKey = `${method}+${url}`;

    if (cacheOptions) {
      if (apiRequestCache.has(cacheKey)) {
        // Get the cached item
        const cachedResult = apiRequestCache.get(cacheKey)!;

        // Check if the cached item is within the cache duration if applicable
        const returnACachedFailure = cacheOptions.cacheFailures ?? false;

        if (cachedResult.value.ok || returnACachedFailure) {
          // If we previously stored a failed result in the cache, but don't want to allow that currently
          // we delete it and move on
          if (cacheOptions.cacheDuration) {
            const now = moment();
            const deltaSeconds = now.diff(cachedResult.at, "seconds");

            if (deltaSeconds < cacheOptions.cacheDuration) {
              // Cached result is within the duration, returned the cloned item to ensure data streams are valid
              return Promise.resolve(cachedResult.value.clone());
            }
            // We need to remove the cached option
            apiRequestCache.delete(cacheKey);
          } else {
            // No specified cache duration, always return the cached item, returned the cloned item to ensure data streams are valid
            return Promise.resolve(cachedResult.value.clone());
          }
        }
      }
    }

    const result = await fetch(url, this.getFetchContent(method));

    if (cacheOptions && (result.ok || cacheOptions.cacheFailures)) {
      apiRequestCache.set(cacheKey, { at: moment(), value: result });
    }

    return result.clone();
  };

  /**
   * Adds additional authorisation and content type headers where appropriate. Will throw an exception
   * if the user attempts to make an unauthenticated fetch without specifically allowing it.
   * @returns The headers used in all requests
   */
  #getHeaders = (): Headers => {
    // Apply the default headers
    const headers = new Headers(
      this.state.noContentType ? {} : APIDefaultHeaders
    );

    if (this.state.json) {
      if (
        this.state.headers?.has("Content-Type") &&
        this.state.headers?.get("Content-Type") !== "application/json"
      ) {
        // Opting into using JSON overrides the Content-Type header with application/json, but this request has used something else
        throw new Error(APIErrorMessages.contentTypeWithJson);
      }
      // Ensure the JSON content-type header if JSON is being used
      headers.set("Content-Type", "application/json");
    }

    // Apply the provided headers over the top
    this.state.headers?.forEach((value, key) => headers.set(key, value));

    // Apply security headers if it is an internal api
    if (
      !headers.has("Authorization") &&
      !this.state.externalAPI &&
      this.state.authentication !== "none"
    ) {
      if (User.loggedIn()) {
        headers.set("Authorization", `Bearer ${User.info()!.token}`);
      } else if (this.state.authentication === "required") {
        throw new Error(`${APIErrorMessages.noAuth}: ${this.state.url}`);
      }
    }

    // If unauthenticated set then strip any auth headers always
    if (this.state.authentication === "none") {
      // If unauthenticated is explicitly stated we want to remove the auth header if it is provided
      headers.delete("Authorization");
    }

    // Add the brand header
    headers.set("Brand", BRAND);

    return headers;
  };

  #getBody = (headers: Headers): BodyInit | undefined => {
    if (this.state.body) {
      switch (headers.get("Content-Type")) {
        case "application/json":
          return JSON.stringify(this.state.body);
        case "application/x-www-form-urlencoded":
          return this.#transformJsonToFormEncoded(this.state.body);
        default:
          return this.state.body as BodyInit;
      }
    }
  };

  /** Transforms a JSON object into a form encoded string
   *
   * @param json A JSON object to transform
   * @returns A form encoded string with the same key-value pairs as the provided JSON object
   */
  #transformJsonToFormEncoded = (json: APIBody) =>
    new URLSearchParams(
      json as ConstructorParameters<typeof URLSearchParams>[0]
    ).toString();

  #bodyRequiredRequest = (method: APIMethod, force: boolean) => {
    if (this.state.body === undefined && !force) {
      throw new Error(`${APIErrorMessages.noBody}: ${this.state.url}`);
    }
    return this.#makeRequest(method);
  };
}

// This is implemented to avoid users form actually been able to pass through the state
// on the initial construction
export const API = new ConcreteAPI();
