import { Button, Form } from "react-bootstrap";
import { Flex } from "@mh/components";
import { LoadedProduct } from "../utils";

interface EscriptProductSelectorRadioButtonProps {
  medicationList: LoadedProduct[];
  title: string | undefined;
  onChange: (selectedOption: LoadedProduct) => void;
}

export const EscriptProductSelectorRadioButton = (
  props: EscriptProductSelectorRadioButtonProps
) =>
  props.medicationList
    .filter((t) => t)
    .sort((a, b) =>
      a.title.trim().toLowerCase() < b.title.trim().toLowerCase() ? -1 : 1
    )
    .map((option, index) => {
      const selected = props.title === option.title;
      return (
        <Button
          key={`radio-${index}`}
          data-testid={`radio-button-${index}`}
          variant="outline-secondary"
          active={selected}
          onClick={() => props.onChange(option)}
          disabled={!option.availability?.is_available_to_buy}
          css={{
            width: "100%",
            paddingTop: "12px",
            paddingBottom: "12px",
            paddingLeft: "16px",
            marginBottom: "24px"
          }}
        >
          <Flex flexDirection="row">
            <Flex>
              <Form.Check
                className="radioOuter my-auto"
                type="radio"
                checked={selected}
                readOnly
                css={[
                  {
                    position: "relative",
                    marginBottom: "auto!important",
                    marginTop: "auto!important"
                  },
                  selected && {
                    "&:after": {
                      content: '""',
                      backgroundColor: "#fff",
                      borderRadius: "7.5px",
                      display: "inline-block",
                      height: "8px",
                      left: "50%",
                      position: "absolute",
                      top: "50%",
                      transform: "translate(-55%,-50%)",
                      width: "8px"
                    }
                  }
                ]}
              ></Form.Check>
            </Flex>
            <Flex
              css={{
                paddingLeft: "8px",
                paddingRight: "8px"
              }}
            >
              {option.title}
            </Flex>
            <Flex
              css={{
                marginLeft: "auto"
              }}
            >
              {option.price ? (
                !option.availability?.is_available_to_buy ? (
                  <span
                    css={(theme) => ({
                      fontSize: "12px",
                      color: theme.color.danger
                    })}
                  >
                    Out of Stock
                  </span>
                ) : (
                  <span>${parseFloat(option.price).toFixed(2)}</span>
                )
              ) : (
                <></>
              )}
            </Flex>
          </Flex>
        </Button>
      );
    });
