import { ComponentPropsWithoutRef } from "react";

export const IconPhone = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.13281 1.66602C9.29688 1.2207 9.78906 1.00977 10.2344 1.12695L12.2969 1.68945C12.6953 1.80664 13 2.1582 13 2.58008C13 8.39258 8.28906 13.0801 2.5 13.0801C2.07812 13.0801 1.70312 12.7988 1.58594 12.4004L1.02344 10.3379C0.90625 9.89258 1.14062 9.40039 1.5625 9.23633L3.8125 8.29883C4.1875 8.13477 4.63281 8.22852 4.89062 8.55664L5.85156 9.70508C7.49219 8.93164 8.82812 7.5957 9.625 5.95508L8.45312 4.99414C8.14844 4.73633 8.03125 4.29102 8.19531 3.91602L9.13281 1.66602Z"
      fill="currentColor"
    />
  </svg>
);
