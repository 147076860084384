export interface PendingAsync {
  state: "pending";
}

export interface FailedAsync<Error = any> {
  state: "failed";
  error?: Error;
}

export interface SuccessAsync<Data> {
  state: "success";
  data: Data;
}

export type Async<Data, Error = any> =
  | PendingAsync
  | FailedAsync<Error>
  | SuccessAsync<Data>;

export function createAsync<Data, Error = any>(
  data?: Data
): Async<Data, Error> {
  if (data !== undefined) {
    // Create an Async with fulfilled data
    return {
      state: "success",
      data
    };
  }

  return {
    state: "pending"
  };
}

export function asyncIsSuccess<T>(async: Async<T>): async is SuccessAsync<T> {
  return async.state === "success";
}
