import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { useQContext } from "@mh/questionnaire/src/hooks";
import { QuestionContentType } from "@mh/questionnaire/src/types";

import {
  ComponentContentTypes,
  QuestionnaireContentComponentProps
} from "./content";
import { QuestionnaireEULA } from "../QuestionnaireEULA";
import { QuestionnaireNext } from "./QuestionnaireNext";

export const QuestionComponent = () => {
  const {
    question,
    responseData,
    localResponseValidationError,
    updateResponseData,
    questionTitleOverride
  } = useQContext();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let Component: React.FC<QuestionnaireContentComponentProps> =
    ComponentContentTypes[question.type];

  useEffect(() => {
    Component = ComponentContentTypes[question.type];
  }, [question]);

  return (
    <Col>
      <Row className="row-title">{questionTitleOverride ?? question.title}</Row>
      <Row className="row-sub-title">{question.subtitle}</Row>
      <Form
        noValidate
        // Prevent form submission from occurring as we handle it elsewhere
        onSubmit={(e) => e.preventDefault()}
      >
        <Row>
          <Col className="q-question">
            {Component && (
              <Component
                key={question.id}
                question={question}
                initialValue={responseData[question.id]}
                onChange={(value: any, update?: boolean) =>
                  updateResponseData(question.id, value, update ?? false)
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="q-question">
            <Row>
              <Form.Control
                type="hidden"
                isInvalid={localResponseValidationError !== null}
              />
              <Form.Control.Feedback type="invalid">
                {localResponseValidationError}
              </Form.Control.Feedback>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col />
          <Col>
            <QuestionnaireNext />
          </Col>
          <Col />
        </Row>
        <Row className="qc-eula">
          {question.type === QuestionContentType.EMAIL && <QuestionnaireEULA />}
        </Row>
      </Form>
    </Col>
  );
};
