import { ComponentPropsWithoutRef } from "react";

export const IconPlus = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 21 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.25 12.9355C20.25 13.5918 19.7344 14.0605 19.125 14.0605H11.625V21.5605C11.625 22.2168 11.1094 22.7324 10.5 22.7324C9.84375 22.7324 9.375 22.2168 9.375 21.5605V14.0605H1.875C1.21875 14.0605 0.75 13.5918 0.75 12.9824C0.75 12.3262 1.21875 11.8105 1.875 11.8105H9.375V4.31055C9.375 3.70117 9.84375 3.23242 10.5 3.23242C11.1094 3.23242 11.625 3.70117 11.625 4.31055V11.8105H19.125C19.7344 11.8105 20.25 12.3262 20.25 12.9355Z"
      fill="currentColor"
    />
  </svg>
);
