const ActiveCampaignStupidPostfix = "?utm_source=ActiveCampaign";

/**
 * Handle Active Campaign mangled url query params
 *
 * This class is stupid and shouldn't have to exist. However it is designed to handle when we are trying to get
 * url params from the page, where the params have been sent by ActiveCampaign. For some reason they don't consider
 * if our URL include url params or not. So they just do "url"+"marketing stuff" and don't check. So this
 * class exists as a result of that stupidity
 */
export class SafeURLSearchParams extends URLSearchParams {
  constructor(init: ConstructorParameters<typeof URLSearchParams>[0]) {
    let safeInit = init;
    if (
      typeof init === "string" &&
      init.includes(ActiveCampaignStupidPostfix) &&
      // Ensures that if the search starts with the postfix we don't mangle it
      init.indexOf(ActiveCampaignStupidPostfix) !== 0
    ) {
      safeInit = init.replace(
        ActiveCampaignStupidPostfix,
        ActiveCampaignStupidPostfix.replace("?", "&")
      );
    }

    super(safeInit);
  }
}
