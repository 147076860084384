import { useEffect, useState, useRef } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import {
  API,
  OscarAPI,
  LinkedProduct,
  type ProductAvailability as BaseProductAvailability
} from "@mh/api";
import "./MedicationSearch.scss";

interface ProductAvailability extends BaseProductAvailability {
  /* Extra field hydrated by parseAvailability() */
  viewableMessage: "Not available" | "In stock" | "Out of stock" | "Available";
  isPurchasable: boolean;
}

/**
 * If `Type` is an array, return the type of the array items. Otherwise, return `Type`.
 * @example `Singular<string[]>` is `string`
 * @example `Singular<string>` is `string`
 */
type Singular<Type> = Type extends (infer Item)[] ? Item : Type;

/**
 * Extending the availability and children fields of LinkedProduct to have the hydrated
 * results of parseAvailability
 *  */
type LinkedProductWithAvailability = Omit<
  LinkedProduct,
  "availability" | "children"
> & {
  availability: ProductAvailability;
  children: (Singular<LinkedProduct["children"]> & {
    availability: ProductAvailability;
  })[];
};

// @ts-ignore
const inputStyle = (theme) => ({
  borderRadius: "12px !important",
  maxWidth: "342px",
  width: "100% !important",
  marginLeft: "auto",
  marginRight: "auto",
  lineHeight: "135% !important",
  minHeight: "35px !important",
  height: "unset !important",
  fontWeight: "400",
  [theme.mq.md]: {
    maxWidth: "431px !important",
    width: "100% !important"
  },
  [theme.mq.lg]: {
    width: "431px !important",
    maxWidth: "unset  !important"
  },
  ":focus, &:hover, &:active, &.active": {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary}`,
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
    borderColor: `${theme.color.primary}`,
    color: "white",
    ".text-primary": {
      color: "white !important"
    }
  },
  input: {
    "&:checked": {
      backgroundColor: theme.color.secondary,
      borderColor: theme.color.secondary
    }
  }
});

const parseAvailability = (
  availability: BaseProductAvailability,
  isScriptOnly: boolean
): ProductAvailability => {
  let viewableMessage: ProductAvailability["viewableMessage"] = "Not available";
  if (availability.is_available_to_buy) {
    viewableMessage = "In stock";
  } else if (availability.message === "Unavailable") {
    viewableMessage = "Out of stock";
  }

  if (isScriptOnly && viewableMessage !== "Not available") {
    viewableMessage = "Available";
  }

  return {
    ...availability,
    viewableMessage,
    isPurchasable:
      viewableMessage === "In stock" || viewableMessage === "Available"
  };
};

interface Product {
  id: number;
  upc: string;
  title: string;
  brand_name?: string;
  availability: ProductAvailability;
  children: Product[];
  disabled: boolean;
}

const sortProducts = <
  ProductType extends Pick<
    LinkedProductWithAvailability,
    "title" | "availability"
  >
>(
  a: ProductType,
  b: ProductType
): number => {
  if (a.availability.isPurchasable !== b.availability.isPurchasable) {
    // Primary sort by availability
    return a.availability.isPurchasable ? -1 : 1;
  }

  // Secondary sort by title
  return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
};

interface AvailabilityProps {
  message: ProductAvailability["viewableMessage"];
}

const Availability = ({ message }: AvailabilityProps) => {
  const availabilityClass = {
    "In stock": "text-success",
    Available: "text-success",
    "Out of stock": "text-danger",
    "Not available": "text-muted"
  }[message];

  return (
    <span className={`figure-caption ${availabilityClass}`}>{message}</span>
  );
};

interface BrandItemProps {
  /* The brand product to render. */
  brand: Product;
  /**
   * Whether the brand is for a script only (true) or delivery (false).
   * If script only, the price and availability are not shown.
   */
  isScriptOnly: boolean;
  /* Callback fired when the item is clicked. */
  onClick: () => void;
  /* Whether the item is currently selected or not. */
  selected: boolean;
}

const BrandItem = ({
  brand,
  isScriptOnly,
  onClick,
  selected
}: BrandItemProps) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [price, setPrice] = useState<string>("");

  useEffect(() => {
    const getPrice = async () => {
      setIsFetching(true);
      const response = await API.unauthenticated()
        .url(`/shop/api/products/${brand.id}/price/`)
        .get();
      if (response.ok) {
        const data = await response.json();
        setPrice(data.incl_tax);
      }
      setIsFetching(false);
    };
    if (!isScriptOnly && brand.availability.isPurchasable) {
      getPrice();
    }
  }, []);

  return (
    <Button
      css={inputStyle}
      variant="outline-secondary"
      active={selected}
      onClick={onClick}
      disabled={isFetching || !brand.availability.isPurchasable}
    >
      <div className="d-flex flex-row m-2">
        <Form.Check
          className="my-auto"
          type="radio"
          checked={selected}
          disabled={isFetching || !brand.availability.isPurchasable}
          readOnly
          css={(theme) => ({
            "&:after": {
              top: "59% !important",
              transform: "translate(-51%, -50%) !important",
              [theme.mq.md]: {
                left: "50% !important"
              }
            }
          })}
        ></Form.Check>
        <div className="d-flex flex-column align-items-start ms-4">
          <span className="text-start">{brand.title}</span>
          {isFetching && <Spinner size="sm" />}
          {!isScriptOnly && (
            <>
              {price ? (
                <strong className="text-primary">{`$${price}`}</strong>
              ) : (
                <Availability message={brand.availability.viewableMessage} />
              )}
            </>
          )}
        </div>
      </div>
    </Button>
  );
};

interface MedicationSearchMenuItemProps {
  /* The medication to choose. */
  medication: Product;
  /* The search string that produced the set of menu results. */
  searchText: string;
  /* Whether the medication is currently selected or not. */
  selected: boolean;
}

const MedicationSearchMenuItem = ({
  medication,
  searchText,
  selected
}: MedicationSearchMenuItemProps) => {
  const searchTokens = searchText
    .split(" ")
    .filter((tok) => !!tok)
    .map((tok) => tok.toLowerCase());
  const brandMatches = medication.children.filter((v) =>
    searchTokens.some((tok) => v.title.toLowerCase().includes(tok))
  );

  return (
    <div
      className={`medication-search-menu-item d-flex flex-column border-bottom p-1 ${
        selected ? "bg-light" : ""
      }`}
    >
      <span
        className={`fs-6 text-wrap ${
          !medication.availability.isPurchasable ? "text-muted" : ""
        }`}
      >
        {medication.title}
      </span>
      {brandMatches.map((brand) => (
        <strong
          key={brand.id}
          className={`figure-caption ${
            medication.availability.isPurchasable ? "text-dark" : "text-muted"
          }`}
        >
          - {brand.brand_name || brand.title}
        </strong>
      ))}
      <Availability message={medication.availability.viewableMessage} />
    </div>
  );
};

interface MedicationSearchProps {
  /* An optional product/brand ID to be initially selected. */
  defaultBrandId?: number;
  /**
   * Whether the medication selected is for a script only or not.
   * Changes how the availability status is displayed.
   */
  isScriptOnly: boolean;
  /**
   * Trigerred when the user selects a brand.
   * Resets to `null` if the user initiates a new search after making a selection.
   */
  onChange: (brand: Product | null) => void;

  /**
   * categoryId used for filtering products based on category
   */
  categoryId: number;
}

export const MedicationSearch = ({
  defaultBrandId,
  isScriptOnly,
  onChange,
  categoryId
}: MedicationSearchProps) => {
  const medicationSearchInput = useRef();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [medications, setMedications] = useState<
    LinkedProductWithAvailability[]
  >([]);
  const [selectedMedication, setSelectedMedication] = useState<Product | null>(
    null
  );
  const [selectedBrand, setSelectedBrand] = useState<Product | null>(null);

  useEffect(() => {
    const setDefaultBrand = async () => {
      setIsFetching(true);
      const [brandResponse, availabilityResponse] = await Promise.all([
        API.unauthenticated()
          .url(`/shop/api/products/${defaultBrandId}/`)
          .get(),
        API.unauthenticated()
          .url(`/shop/api/products/${defaultBrandId}/availability/`)
          .get()
      ]);
      if (brandResponse.ok) {
        let brandData = await brandResponse.json();
        if (availabilityResponse.ok) {
          const availability = await availabilityResponse.json();
          brandData = {
            ...brandData,
            availability: parseAvailability(availability, isScriptOnly)
          };
        }
        setSelectedBrand(brandData);
      }
      setIsFetching(false);
    };

    if (defaultBrandId) {
      setDefaultBrand();
    }
  }, [defaultBrandId]);

  useEffect(() => {
    onChange(selectedBrand);
  }, [selectedBrand]);

  const search = async (query: string) => {
    // If there is currently a request in flight, abort it
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for the current request
    const controller = new AbortController();
    setAbortController(controller);

    setIsFetching(true);
    try {
      const { results: products } = await OscarAPI.getProductsPaginated(
        {
          categories: [categoryId],
          structure: ["parent"],
          search: query.trim()
        },
        { page: 1, limit: 20 }
      );
      if (products.length > 0) {
        const results: LinkedProductWithAvailability[] = products
          .map((medication: LinkedProduct) => ({
            ...medication,
            availability: parseAvailability(
              medication.availability,
              isScriptOnly
            ),
            children: medication.children.map((child) => ({
              ...child,
              availability: parseAvailability(child.availability, isScriptOnly)
            }))
          }))
          .map((medication: LinkedProductWithAvailability) => ({
            ...medication,
            disabled: !medication.availability.isPurchasable
          }));
        results.sort(sortProducts);
        results.forEach((p: LinkedProductWithAvailability) =>
          p.children.sort(sortProducts)
        );
        setMedications(results);
      }
      setIsFetching(false);

      // Clear the abort controller when the request is finished
      setAbortController(null);
    } catch (error) {
      // Silently swallow AbortError exceptions, due to a request being cancelled
      if (!(error instanceof DOMException) || error.name !== "AbortError") {
        throw error;
      }
    }
  };

  return (
    <div className="d-flex flex-column">
      <AsyncTypeahead
        css={inputStyle}
        id="medication-search"
        className="typeahead-input"
        defaultSelected={selectedMedication ? [selectedMedication] : []}
        delay={500}
        filterBy={() => true}
        labelKey="title"
        isLoading={isFetching}
        minLength={3}
        onSearch={search}
        // @ts-ignore
        ref={medicationSearchInput}
        onChange={(selected) => {
          if (selected.length) {
            setSelectedMedication(selected[0] as Product);
            // @ts-ignore
            medicationSearchInput?.current?.blur();
          } else {
            setSelectedMedication(null);
          }
          setSelectedBrand(null);
        }}
        options={medications}
        placeholder="Type to search..."
        renderMenuItemChildren={(option, { text }) => {
          const medication = option as Product;
          return (
            <MedicationSearchMenuItem
              medication={medication}
              searchText={text}
              selected={medication.id === selectedMedication?.id}
            />
          );
        }}
      />
      {(selectedMedication || selectedBrand) && (
        <div className="w-100 d-flex flex-column gap-3 mt-5">
          <h2 className="text-center">Which brand would you prefer?</h2>
          {selectedMedication &&
            selectedMedication.children.map((child: Product) => (
              <BrandItem
                key={child.id}
                brand={child}
                isScriptOnly={isScriptOnly}
                selected={child.id === selectedBrand?.id}
                onClick={() => setSelectedBrand(child)}
              />
            ))}
          {!selectedMedication && selectedBrand && (
            <BrandItem
              key={selectedBrand.id}
              brand={selectedBrand}
              isScriptOnly={isScriptOnly}
              selected={selectedBrand.id === selectedBrand?.id}
              onClick={() => setSelectedBrand(selectedBrand)}
            />
          )}
        </div>
      )}
    </div>
  );
};
