import { FC } from "react";

export const SendMessageIcon: FC = () => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0625 9.64062L3.0625 0.640625C2.875 0.546875 2.6875 0.5 2.5 0.5C2.07812 0.5 1.65625 0.640625 1.375 0.96875C0.953125 1.4375 0.859375 2.14062 1.14062 2.70312L5.5 11L1.14062 19.3438C0.859375 19.9062 0.953125 20.6094 1.375 21.0312C1.65625 21.3594 2.07812 21.5469 2.5 21.5469C2.6875 21.5469 2.875 21.5 3.0625 21.4062L24.0625 12.4062C24.625 12.1719 25 11.6094 25 11C24.9531 10.4375 24.625 9.875 24.0625 9.64062ZM2.45312 2.04688L21.7188 10.25H6.76562L2.45312 2.04688ZM2.45312 20.0469L6.76562 11.75H21.6719L2.45312 20.0469Z"
        fill="#008C95"
      />
    </svg>
  );
};
