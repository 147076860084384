import { type ProductRecommendation } from "@mh/api";
import { ScrollableContainer, DisplayHeading } from "@mh/components";

import { ClinicianRecommendation } from "./ClinicianRecommendation";

interface Props {
  recommendations: ProductRecommendation[];
}

/**
 * Provides a list of products with basket interactions.
 *
 * On a mobile view, it renders the <ScrollableContainer /> component.
 * On a desktop view, it renders a list of <ClinicianRecommendation /> components.
 */
export const BrowsableProductsContainer = ({ recommendations }: Props) => (
  <>
    <div
      css={(theme) => ({
        display: "flex",
        [theme.mq.md]: { display: "none" }
      })}
    >
      <ScrollableContainer<ProductRecommendation>
        title="Our clinicians recommend"
        items={recommendations}
        renderItem={({ recommendation, subtitle, details }) => (
          <ClinicianRecommendation
            key={recommendation.id}
            product={recommendation}
            subtitle={subtitle}
            details={details}
          />
        )}
      />
    </div>
    <div
      css={(theme) => ({
        display: "none",
        [theme.mq.md]: {
          display: "flex",
          flexDirection: "column",
          gap: "16px"
        }
      })}
    >
      <DisplayHeading>Our clinicians recommend</DisplayHeading>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%"
        }}
      >
        {recommendations.map(({ recommendation, subtitle, details }) => (
          <ClinicianRecommendation
            key={recommendation.id}
            product={recommendation}
            subtitle={subtitle}
            details={details}
          />
        ))}
      </div>
    </div>
  </>
);
