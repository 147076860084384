import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import {
  BaseQuestionnaire,
  isRenewalExcludedTreatments,
  OscarAPI,
  PatientAPI,
  PatientQuestionnaire,
  PatientSubscriptionScript
} from "@mh/api";
import { useBasket } from "@mh/basket";
import { ContactUsModal } from "@mh/messaging";
import { IconMessagesFill, IconPhone } from "@mh/components";

import { Status } from "./Status";
import { Button, SwitchButton } from "../../buttons";
import { Modal, ModalProps } from "../../Modal";
import { HorizontalDivider } from "../../dividers";

/**
 * The date shown to the user is 14 days before the actual supply expiry.
 * This is because the overnight script generates orders 14 days before the supply expiry.
 */
const SUPPLY_EXPIRE_OFFSET_DAYS = 14;

interface ManageTreatmentModalProps extends ModalProps {
  onChange?: () => void;
  subscription: PatientSubscriptionScript;
  originAutomaticallySendRepeats: boolean;
  slug: string;
  initialQuestionnaireId: number;
}

interface RequestMedicationChangeArguments {
  slug: string;
  initialQuestionnaireId: number;
  subscriptionId: number;
}

const ManageTreatmentModal = ({
  onChange = () => {},
  subscription,
  originAutomaticallySendRepeats,
  slug,
  initialQuestionnaireId,
  ...modalProps
}: ManageTreatmentModalProps) => {
  const navigate = useNavigate();

  const supplyExpire = subscription.supply_expire
    ? moment(subscription.supply_expire)
    : null;
  const scriptExpire = subscription.script_expire
    ? moment(subscription.script_expire)
    : null;

  const [nextRefillDate, setNextRefillDate] = useState<moment.Moment | null>(
    supplyExpire?.subtract(SUPPLY_EXPIRE_OFFSET_DAYS, "days") ?? null
  );
  const [automaticallySendRepeats, setAutomaticallySendRepeats] = useState(
    originAutomaticallySendRepeats
  );
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const titleStyle = (theme: any) => ({
    color: theme.color.primary,
    fontSize: "16px",
    fontWeight: "600"
  });

  const txtStyle = {
    fontSize: "14px",
    lineHeight: "18.9px",
    paddingBottom: "8px"
  };

  const handleSaveAndExit = async (type: string) => {
    const data =
      type === "save"
        ? {
            order_on_demand: !automaticallySendRepeats,
            supply_expire:
              nextRefillDate
                ?.add(SUPPLY_EXPIRE_OFFSET_DAYS, "days")
                .format("YYYY-MM-DD") ?? undefined
          }
        : {
            status: "cancelled" as PatientSubscriptionScript["status"],
            cancellation_reason: "Patient requested to cancel treatment plan."
          };
    try {
      await PatientAPI.updatePatientSubscriptionScript(subscription.id, data);
      toast.success(
        type === "save"
          ? "Your treatment plan has been updated."
          : "Your treatment plan has been cancelled."
      );
      onChange();
      modalProps.onHide();
    } catch (error) {
      if (error instanceof Response && error.status === 400) {
        const errorData = await error.json();
        if (
          typeof errorData === "object" &&
          Array.isArray(errorData.supply_expire) &&
          errorData.supply_expire.length
        ) {
          toast.error(errorData.supply_expire[0]);
          return;
        }
      }
      toast.error("There was an error updating your treatment.");
    }
  };

  const requestMedicationChangeHandler = async ({
    slug,
    initialQuestionnaireId,
    subscriptionId
  }: RequestMedicationChangeArguments) => {
    const success =
      await PatientAPI.createOrActivateRepeatQuestionnaire(subscriptionId);
    if (success) {
      const url = `/questionnairerepeats/${slug}/${initialQuestionnaireId}/`;
      return url;
    }
    return null;
  };

  return (
    <>
      <Modal {...modalProps}>
        <Modal.Title onClose={modalProps.onHide}>
          <span css={{ color: "#000", fontWeight: "500" }}>
            Manage Your Order
          </span>
        </Modal.Title>
        <div>
          <div>
            <div
              className="d-flex flex-lg-row flex-column justify-content-between"
              css={{ display: "flex", gap: "4px" }}
            >
              <div className="d-flex flex-column">
                <div css={[titleStyle]}>Reshedule your next order</div>
                <div css={[txtStyle]}>Estimate dispatch date</div>
              </div>
              <div className="d-flex flex-column">
                <input
                  type="date"
                  min={moment().add(2, "days").format("YYYY-MM-DD")}
                  max={
                    scriptExpire
                      ?.subtract(SUPPLY_EXPIRE_OFFSET_DAYS + 2, "days")
                      .format("YYYY-MM-DD") ?? ""
                  }
                  onChange={(e) =>
                    setNextRefillDate(moment(e.currentTarget.value))
                  }
                  value={nextRefillDate?.format("YYYY-MM-DD") ?? ""}
                  css={(theme) => ({
                    height: "38px",
                    width: "100%",
                    borderRadius: "4px",
                    padding: "7px 13px",
                    border: "0.5px solid #D9D9D9",
                    [theme.mq.lg]: {
                      width: "206px"
                    }
                  })}
                />
              </div>
            </div>
          </div>
          <HorizontalDivider />
          <div css={{ display: "flex", alignItems: "center" }}>
            <div css={{ flex: 1 }}>Automatically send repeats</div>
            <SwitchButton
              on={automaticallySendRepeats}
              onClick={() =>
                setAutomaticallySendRepeats(!automaticallySendRepeats)
              }
              size="sm"
            >
              {automaticallySendRepeats ? "Enabled" : "Disabled"}
            </SwitchButton>
          </div>
          <HorizontalDivider />
          {!isRenewalExcludedTreatments(slug) && (
            <>
              <div>
                <div css={[titleStyle]}>Request medication change</div>
                <div css={[txtStyle]}>
                  To request an alternative medication for this treatment,
                  please use the button below to initiate a follow-up
                  consultation.
                </div>
                <div
                  css={(theme) => ({
                    textAlign: "center",
                    [theme.mq.lg]: {
                      textAlign: "right"
                    }
                  })}
                >
                  <Button
                    css={(theme) => ({
                      width: "100%",
                      [theme.mq.lg]: {
                        width: "unset"
                      }
                    })}
                    onClick={async (e) => {
                      e.preventDefault();
                      const url = await requestMedicationChangeHandler({
                        slug,
                        initialQuestionnaireId,
                        subscriptionId: subscription.id
                      });
                      if (url) {
                        navigate(url);
                      }
                    }}
                  >
                    Request medication change
                  </Button>
                </div>
              </div>
              <HorizontalDivider />
            </>
          )}
          <div>
            <div css={[titleStyle]}>Cancel treatment plan</div>
            <div css={[txtStyle]}>
              Please note cancelling will prevent any current or future orders
              under this treatment.
            </div>
            <div
              css={{
                fontSize: "14px",
                fontWeight: "600",
                textDecoration: "underline",
                cursor: "pointer"
              }}
              onClick={() => setShowCancelPlanModal(true)}
            >
              Cancel treatment
            </div>
          </div>
        </div>
        <HorizontalDivider />
        <Modal.Actions>
          <Button onClick={modalProps.onHide} variant="primary-outline">
            Cancel
          </Button>
          <Button onClick={() => handleSaveAndExit("save")}>Save & Exit</Button>
        </Modal.Actions>
      </Modal>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          setShow={setShowContactUsModal}
        />
      )}
      {showCancelPlanModal && (
        <Modal {...modalProps}>
          <Modal.Title onClose={() => setShowCancelPlanModal(false)}>
            <span css={{ color: "#000", fontWeight: "500" }}>
              Cancel treatment plan
            </span>
          </Modal.Title>
          <div>
            Continue with cancellation to disable all orders under this
            treatment. If you have any queries regarding your treatment plan,
            please contact our{" "}
            <span
              css={{
                cursor: "pointer",
                textDecoration: "none",
                fontWeight: "600",
                color: "#000"
              }}
              onClick={() => setShowContactUsModal(true)}
            >
              Patient Success team
            </span>
            <span css={{ paddingLeft: "4px" }}>
              <IconMessagesFill
                css={(theme) => ({
                  width: "14px",
                  height: "14px",
                  color: theme.color.primary
                })}
              />
            </span>
            <HorizontalDivider />
          </div>
          <div css={{ paddingBottom: "24px", fontWeight: "600" }}>
            Are you sure you want to continue
          </div>
          <div className="d-flex flex-column flex-md-row">
            <Button
              onClick={() => handleSaveAndExit("cancel")}
              variant="primary-outline"
              fullWidth
            >
              Yes
            </Button>
            <div
              css={(theme) => ({
                height: "16px",
                [theme.mq.lg]: { width: "16px" }
              })}
            ></div>
            <Button onClick={() => setShowCancelPlanModal(false)} fullWidth>
              No
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export const ManageTreatmentStatus = ({
  initialQuestionnaire,
  onChange = () => {},
  questionnaire,
  subscription,
  automaticallySendRepeats
}: {
  initialQuestionnaire: PatientQuestionnaire;
  onChange?: () => void;
  questionnaire: BaseQuestionnaire;
  subscription: PatientSubscriptionScript;
  automaticallySendRepeats: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showManageTreatmentModal, setShowManageTreatmentModal] =
    useState(false);
  const basket = useBasket();
  const navigate = useNavigate();

  const handleCheckout = async () => {
    if (!questionnaire.script?.product) {
      return;
    }
    setIsLoading(true);
    const scriptId = questionnaire.script.id;
    const questionnaireId = initialQuestionnaire.id;
    const options = await OscarAPI.getOptions();
    const scriptIdOption = options.results.find(
      (option) => option.code === "script_id"
    );
    const questionnaireIdOption = options.results.find(
      (option) => option.code === "questionnaire_id"
    );

    // script ID and questionnaire ID options are required for adding products to an Oscar basket
    if (
      scriptIdOption &&
      questionnaireIdOption &&
      scriptId &&
      questionnaireId
    ) {
      // Clear out the basket from any potential previous checkout
      await basket.reset();
      // Quantity is the initial order + repeat amount
      await basket.addItem(questionnaire.script.product, 1, [
        { option: scriptIdOption.url, value: scriptId },
        { option: questionnaireIdOption.url, value: questionnaireId }
      ]);
      basket.basket.refresh();
      navigate("/checkout");
    }
    setIsLoading(false);
  };

  const isWM = initialQuestionnaire.category?.slug === "weight_management";

  return (
    <Status
      actions={
        <>
          <Button disabled={isLoading} onClick={handleCheckout}>
            Order repeat
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => setShowManageTreatmentModal(true)}
            variant="secondary-outline"
          >
            Manage treatment
          </Button>
        </>
      }
      title="ACTIVE"
    >
      {!isWM && (
        <>
          {questionnaire.consultation?.clinician?.formal_name ||
            "Your clinician"}{" "}
          has issued your treatment plan. If you would like to request an
          alternative medication for this treatment, please use the {"'"}Manage
          treatment{"'"} button below.
        </>
      )}
      {isWM && (
        <p
          css={{
            letterSpacing: "0.3px"
          }}
        >
          {questionnaire.consultation?.clinician?.formal_name ||
            "Your clinician"}{" "}
          has issued your treatment plan. To discuss any treatment questions or
          concerns, schedule a consultation with our{" "}
          <a
            href="https://calendly.com/supportnurse/wmconsult"
            css={{
              cursor: "pointer",
              textDecoration: "none",
              fontWeight: "600",
              color: "#000"
            }}
            rel="noreferrer"
            target="_blank"
          >
            Nurse Support team
          </a>
          <span css={{ paddingLeft: "4px" }}>
            <IconPhone
              css={(theme) => ({
                width: "14px",
                height: "14px",
                color: theme.color.primary
              })}
            />
          </span>
        </p>
      )}
      {showManageTreatmentModal && (
        <ManageTreatmentModal
          onChange={onChange}
          subscription={subscription}
          show={showManageTreatmentModal}
          originAutomaticallySendRepeats={automaticallySendRepeats}
          onHide={() => setShowManageTreatmentModal(false)}
          initialQuestionnaireId={initialQuestionnaire.id}
          slug={initialQuestionnaire.category.slug}
        />
      )}
    </Status>
  );
};
