import { ComponentPropsWithoutRef } from "react";

export const IconHeartPulse = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 7.625L11.7188 9.03125L15.2188 9L9.6875 14.7188C9.3125 15.125 8.65625 15.125 8.25 14.7188L2.75 9L5.8125 9.03125L6.75 6.78125L8.53125 10.7188C8.71875 11.0938 9.25 11.125 9.4375 10.75L11 7.625ZM15.4688 1.96875C17.25 3.53125 17.4688 6.1875 16.0938 8.03125H12.3125L11.4375 6.28125C11.25 5.9375 10.7188 5.9375 10.5312 6.28125L9 9.34375L7.1875 5.3125C7 4.90625 6.4375 4.90625 6.28125 5.3125L5.15625 8H1.875C0.5 6.1875 0.71875 3.53125 2.5 1.96875C4.21875 0.5 6.8125 0.78125 8.375 2.375L9 3.03125L9.59375 2.375C11.1562 0.78125 13.75 0.5 15.4688 1.96875Z"
      fill="currentColor"
    />
  </svg>
);
