import { createRequest, extractPagination, PaginatedResponse } from "../utils";
import { Feature as FeatureType } from "./types";

export class FeatureAPI {
  /**
   * Fetch all feature flags.
   */
  static getFeatures = extractPagination(
    createRequest<PaginatedResponse<FeatureType>>("/api/v2/feature/")
  );
}
