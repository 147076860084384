import { ComponentPropsWithoutRef } from "react";

export const IconNutrition = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3866 6.53749C11.9012 6.3736 12.4357 6.28017 12.9754 6.25977C15.3631 6.25977 16.2108 8.50128 16.2108 10.4204C16.2108 14.3261 14.0941 16.6607 12.0502 16.6612C11.6038 16.637 11.1654 16.5331 10.7557 16.3544C10.5066 16.2425 10.2414 16.1705 9.96989 16.1412C9.6989 16.1707 9.43425 16.2427 9.18562 16.3544C8.77574 16.5332 8.33715 16.6371 7.89063 16.6612H7.88959C5.84466 16.6607 3.729 14.3261 3.729 10.4204C3.729 7.85379 4.93037 6.25977 6.86609 6.25977C7.42643 6.2836 7.98163 6.37706 8.51888 6.53801C8.79646 6.61288 9.2569 6.76049 9.77235 6.76049C10.2878 6.76049 11.0755 6.6287 11.3866 6.53749Z"
      stroke="#90AEB2"
      strokeWidth="1.5"
    />
    <path
      d="M7.86 8.31055C6.50231 8.31055 5.87805 9.85739 6.01577 10.8134"
      stroke="#90AEB2"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.86133 2.75879C8.69581 3.14492 9.87278 5.02791 9.87278 6.25969"
      stroke="#90AEB2"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
