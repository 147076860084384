import { SplashContent } from "./types";

export const SPLASH_CONTENTS: SplashContent[] = [
  {
    name: "newsplash",
    fullbg: "bg_newsplash.png",
    brandlogo: "newsplashbrandlogo.png",
    right: undefined,
    left: {
      classes: " new-splash-bg z-2",
      contents: [
        {
          type: "txtgroup",
          tag: undefined,
          classes: "row pb-5",
          innerClasses: undefined,
          text: undefined,
          img: undefined,
          path: undefined,
          txtGroup: [
            {
              type: "img",
              tag: undefined,
              classes: "col-3 col-lg-2 v-center",
              innerClasses: "logo",
              text: undefined,
              img: undefined,
              path: "/assets/images/[[from]]/newsplashlogo.png",
              txtGroup: undefined
            },
            {
              type: undefined,
              tag: "h2",
              classes: "col-9 col-lg-10 v-center text-left logo-txt",
              innerClasses: "white",
              text: "QuickScript",
              img: undefined,
              path: undefined,
              txtGroup: undefined
            }
          ]
        },
        {
          type: undefined,
          tag: undefined,
          classes: "pt-5 white",
          innerClasses: "small-txt",
          text: "QuickScript offers simple and convenient prescriptions for medications that you take regularly.You can choose between receiving an eScript that you can take to any pharmacy or getting your medication delivered to your door for a small fee.",
          img: undefined,
          path: undefined,
          txtGroup: undefined
        },
        {
          type: undefined,
          tag: undefined,
          classes: "pt-5 pb-5 white",
          innerClasses: "small-txt",
          text: "The consultation fee is only $35, with no additional charge for your eScript. If you prefer medication delivery, we offer competitive prices and express delivery for just $10.95!",
          img: undefined,
          path: undefined,
          txtGroup: undefined
        },
        {
          type: undefined,
          tag: undefined,
          classes: "pb-5 sub-header",
          innerClasses: undefined,
          text: "Get your script sorted in 3 easy steps",
          img: undefined,
          path: undefined,
          txtGroup: undefined
        },
        {
          type: "txtgroup",
          tag: undefined,
          classes: "row pb-5",
          innerClasses: undefined,
          text: undefined,
          img: undefined,
          path: undefined,
          txtGroup: [
            {
              type: "img",
              tag: undefined,
              classes: "col-3 col-lg-2 v-center",
              innerClasses: "w-100 maxw-40 minw-30",
              text: undefined,
              img: undefined,
              path: "/assets/images/[[from]]/questionnaire.png",
              txtGroup: undefined
            },
            {
              type: undefined,
              tag: undefined,
              classes: "col-9 col-lg-10 v-center text-left pl-0",
              innerClasses: "small-txt white",
              text: "Fill out a short questionnaire",
              img: undefined,
              path: undefined,
              txtGroup: undefined
            }
          ]
        },
        {
          type: "txtgroup",
          tag: undefined,
          classes: "row pb-5",
          innerClasses: undefined,
          text: undefined,
          img: undefined,
          path: undefined,
          txtGroup: [
            {
              type: "img",
              tag: undefined,
              classes: "col-3 col-lg-2 v-center",
              innerClasses: "w-100 maxw-40 minw-30",
              text: undefined,
              img: undefined,
              path: "/assets/images/[[from]]/renew.png",
              txtGroup: undefined
            },
            {
              type: undefined,
              tag: undefined,
              classes: "col-9 col-lg-10 v-center text-left pl-0",
              innerClasses: "small-txt white",
              text: "Our Australian-based medical team will review your responses online and give you a brief call to verify your details",
              img: undefined,
              path: undefined,
              txtGroup: undefined
            }
          ]
        },
        {
          type: "txtgroup",
          tag: undefined,
          classes: "row pb-5",
          innerClasses: undefined,
          text: undefined,
          img: undefined,
          path: undefined,
          txtGroup: [
            {
              type: "img",
              tag: undefined,
              classes: "col-3 col-lg-2 v-center",
              innerClasses: "w-100 maxw-40 minw-30",
              text: undefined,
              img: undefined,
              path: "/assets/images/[[from]]/issuescript.png",
              txtGroup: undefined
            },
            {
              type: undefined,
              tag: undefined,
              classes: "col-9 col-lg-10 v-center text-left pl-0",
              innerClasses: "small-txt white",
              text: "We’ll issue your eScript instantly via email or deliver your medication direct to your door - it’s your choice!",
              img: undefined,
              path: undefined,
              txtGroup: undefined
            }
          ]
        }
      ]
    }
  }
];
