import { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { type StripeElements } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { BRAND } from "@mh/core";
import {
  type CheckoutStep as ICheckoutStep,
  type UserMembership,
  type BasketMembershipPricing,
  type Basket,
  type BasketLine,
  type Async,
  type Price,
  type StripeSetupIntent,
  SubscriptionPriceAPI,
  BasketAPI,
  CheckoutAPI,
  asyncIsSuccess,
  createAsync,
  OscarAPI,
  StripeAPI,
  PatientAPI
} from "@mh/api";
import { Await, Button, ShippingAddress, Spinner, Toast } from "@mh/components";
import { BasketSummary, useBasket } from "@mh/basket";
import { type Card } from "@mh/checkout";

import type { TreatmentAcceptanceStepProps } from "../types";

import { WizardStep } from "./WizardStep";
import { UpdatePaymentMethod } from "./UpdatePaymentMethod";

interface CheckoutStepProps extends TreatmentAcceptanceStepProps {
  step: ICheckoutStep;
}

export const CheckoutStep = ({ step }: CheckoutStepProps) => {
  const {
    setup_intent: initialSetupIntent,
    user_memberships,
    bundle,
    shipping_method
  } = step.data;

  const stripe = useStripe();
  const navigate = useNavigate();
  const { basket, lines } = useBasket();

  /** The user's potentially active hubPass membership */
  const [hubPassMembership, isMember] = useMemo<
    [UserMembership | null, boolean]
  >(() => {
    const membership = user_memberships.find(
      ({ membership }) => membership.slug === "membership"
    );

    if (!membership) {
      return [null, false];
    }

    return [membership, membership.status === "active"];
  }, [user_memberships]);

  /** Pricing and discounts of a membership */
  const [membershipPricing, setMembershipPricing] = useState<
    Async<BasketMembershipPricing>
  >(createAsync());

  /** Price of the membership product */
  const [membershipPrice, setMembershipPrice] = useState<Async<Price>>(
    createAsync()
  );

  /** Mapping of basket line IDs to whether their subscription is order on demand */
  const [orderOnDemand, setOrderOnDemand] = useState<Record<number, boolean>>(
    step.data.order_on_demand
  );

  /** Is the membership signup option selected? Defaults to true if the user is a hubPass member */
  const [isMembershipSignupSelected, setIsMembershipSignupSelected] =
    useState<boolean>(isMember);

  /** The setup intent ID used to checkout with, to use the correct payment method */
  const [setupIntent, setSetupIntent] =
    useState<StripeSetupIntent>(initialSetupIntent);

  /** True if a new setup intent is being loaded */
  const [isUpdatingSetupIntent, setIsUpdatingSetupIntent] =
    useState<boolean>(false);

  /** True when the checkout is being submitted */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [isApplyingDiscount, setIsApplyingDiscount] = useState<boolean>(false);
  const [discountError, setDiscountError] = useState<string | null>(null);

  const [showMemberPromoText, setShowMemberPromoText] = useState<boolean>(true);

  // Reload the basket upon page load
  const hasInitiallyRefreshedBasket = useRef<boolean>(false);

  useEffect(() => {
    if (hasInitiallyRefreshedBasket.current) {
      return;
    }

    basket.refresh();

    hasInitiallyRefreshedBasket.current = true;
  }, [basket.refresh]);

  /** Has the membership pricing already been loaded? */
  const hasLoadedMembership = useRef<boolean>(false);

  useEffect(() => {
    const hasRefreshedBasket = hasInitiallyRefreshedBasket.current;
    const hasLoadedAlready = hasLoadedMembership.current;

    // Load membership when the initial basket refresh is complete
    const shouldLoadMembership = hasRefreshedBasket && !hasLoadedAlready;

    if (!asyncIsSuccess(basket.async) || !shouldLoadMembership) {
      // Don't load membership pricing twice
      return;
    }

    // Don't load memberships twice
    hasLoadedMembership.current = true;

    // Retrieve the basket ID to load membership pricing
    const { id } = basket.async.data;

    // Load membership pricing for the active basket
    BasketAPI.getMembershipPricing(id)
      .then((pricing) => setMembershipPricing(createAsync(pricing)))
      .catch((error) => setMembershipPricing({ state: "failed", error }));
  }, [basket]);

  useEffect(() => {
    /** Loads pricing of the hubPass membership product and saves it */
    const loadMembershipPrice = async () => {
      // Load membership product pricing
      const membershipProduct = await OscarAPI.getMembershipProducts().then(
        (products) =>
          products.find((product) => product.slug === "membership-fee-monthly")
      );

      if (membershipProduct) {
        // Get the price of the membership product for display later
        const membershipProductPrice = await OscarAPI.getProductPrice(
          membershipProduct.id
        );
        setMembershipPrice(createAsync(membershipProductPrice));
      }
    };

    loadMembershipPrice();
  }, []);

  /** Loads a new setup intent which should use the updated payment method */
  const handlePaymentMethodUpdated = async (elements: StripeElements) => {
    if (!stripe) {
      console.warn("Can't update card as Stripe has not loaded");
      return;
    }

    const { client_secret: clientSecret } = setupIntent;

    // const { error: setupError } = await stripe.confirmCardSetup(clientSecret);
    const { setupIntent: confirmedSetupIntent, error: setupError } =
      await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: "if_required"
      });

    if (setupError) {
      // SetupIntent submission failed
      throw new Error(setupError.message);
    }

    const { id: confirmedSetupIntentId } = confirmedSetupIntent;

    // Disable payment button
    setIsUpdatingSetupIntent(true);

    // Update the patient's default payment method on Stripe
    await PatientAPI.updatePaymentMethod({
      setupIntentId: confirmedSetupIntentId
    });

    // Get a new setup intent to use for this checkout, which should use the new payment method
    const newSetupIntent = await StripeAPI.getSetupIntent();
    setSetupIntent(newSetupIntent);

    // Enable payment button
    setIsUpdatingSetupIntent(false);
  };

  /**
   * Places the order, refreshes the basket and redirects the patient to the home page.
   *
   * @param setupIntent The resolved setup intent object from the Payment component
   */
  const handleCheckout = async () => {
    const { async: basketAsync } = basket;
    const hasBaskedLoaded = asyncIsSuccess(basketAsync);

    if (!hasBaskedLoaded || !stripe) {
      console.warn("Cannot checkout if the basket or Stripe have not loaded");
      return;
    }

    setIsSubmitting(true);

    const setupIntentResult = await stripe.retrieveSetupIntent(
      setupIntent.client_secret
    );

    /** Did the setup intent retrieval result in a failure? */
    const hasError = !!setupIntentResult.error;

    if (hasError) {
      // Don't continue with confirming the failed setup intent
      setIsSubmitting(false);
      Toast.error("Sorry, there was an error purchasing. Please try again.");
      throw new Error(setupIntentResult.error.message);
    }

    const { setupIntent: resolvedSetupIntent } = setupIntentResult;
    const { client_secret: clientSecret } = resolvedSetupIntent;

    if (!clientSecret) {
      setIsSubmitting(false);
      Toast.error("Sorry, there was an error purchasing. Please try again.");
      throw new Error(
        "Error retrieving client secret from setup intent. Cannot checkout."
      );
    }

    // Get the payment method to pay with
    const paymentMethod = resolvedSetupIntent.payment_method;

    // We must checkout with a valid payment method
    const isPaymentMethodValid = paymentMethod !== null;

    if (!isPaymentMethodValid) {
      return;
    }

    // In the off chance the payment method is expanded, extract its ID
    const paymentMethodId =
      typeof paymentMethod === "string" ? paymentMethod : paymentMethod.id;

    // Confirm the setup intent - by this point either loaded the page with a valid payment method in Stripe, or
    // they've updated their card
    const confirmed = await stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: paymentMethodId
      },
      { handleActions: false }
    );

    // Did saving the card succeed?
    const wasSuccessful = confirmed.error === undefined;

    if (!wasSuccessful) {
      setIsSubmitting(false);
      throw new Error(confirmed.error.message);
    }

    const { id } = basketAsync.data;

    // Update order on demand statuses
    const orderOnDemandUpdates = Object.entries(orderOnDemand)
      // Assert lineId is a string
      .map(
        ([lineId, isOrderOnDemand]) =>
          [parseInt(lineId), isOrderOnDemand] as [number, boolean]
      )
      // Update each line's order on demand status, provided it has a matching subscription
      .map(([lineId, isOrderOnDemand]) =>
        SubscriptionPriceAPI.setOrderOnDemand(id, lineId, isOrderOnDemand)
      );

    // Wait for every line to be updated
    await Promise.all(orderOnDemandUpdates);

    if (isMembershipSignupSelected) {
      try {
        await PatientAPI.createUserMembership();
      } catch (e) {
        // Failed to create membership
        setIsSubmitting(false);
        Toast.error(
          "Sorry, there was an error creating your hubPass membership. Please try again."
        );
        throw e;
      }
    }

    try {
      // Submit the checkout
      await CheckoutAPI.submitCheckoutOrder({
        basket: basketAsync.data.url,
        setup_intent_id: resolvedSetupIntent.id
      });
    } catch (e) {
      Toast.error("Sorry, there was an error purchasing. Please try again.");
      throw e;
    }

    // If the checkout succeeds, then the user should be moved to the treatments page
    navigate("/");
  };

  useEffect(() => {
    if (discountError !== null) {
      setTimeout(() => setDiscountError(null), 5000);
    }
  }, [discountError]);

  const handleDiscountCodeApplied = async (code: string): Promise<void> => {
    setIsApplyingDiscount(true);
    const voucherResponse = await BasketAPI.addVoucher({ vouchercode: code });

    if (voucherResponse.isFailure) {
      setDiscountError(voucherResponse.failure);
    }

    basket.refresh();
    setIsApplyingDiscount(false);
  };

  return (
    <Await<[Basket, BasketLine[], BasketMembershipPricing, Price]>
      asyncs={[basket.async, lines, membershipPricing, membershipPrice]}
      renderPending={
        <div css={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Spinner size="lg" />
        </div>
      }
    >
      {([currentBasket, lines, membershipPricing, membershipPrice]) => {
        let basket = currentBasket;

        /** The selected shipping method's price */
        let shippingPrice = parseFloat(shipping_method.price.incl_tax);

        /** Discount applied to shipping */
        let shippingDiscount = shipping_method.discount;

        /**
         * True if the patient is displaying potential pricing, i.e. they are not a member but have selected the
         * membership signup checkbox
         */
        const shouldShowMembershipPrice =
          !isMember && isMembershipSignupSelected;

        if (shouldShowMembershipPrice) {
          // Show pricing of the basket with membership as a preview.
          // If the user is already a member, their current basket will have membership pricing anyway so we don't need
          // to override
          const { basket: basketWithMembership } =
            membershipPricing.with_membership;
          basket = basketWithMembership;

          // Show what shipping price a patient would pay if they signed up to hubPass
          shippingPrice = parseFloat(
            membershipPricing.with_membership.shipping_price.incl_tax
          );

          // Show what discount a patient would receive if they signed up to hubPass
          shippingDiscount =
            membershipPricing.with_membership.shipping_discount;
        }

        /** The current basket total */
        const basketPrice = parseFloat(basket.total_incl_tax);

        /** The total price of the basket, including shipping */
        const totalPrice = basketPrice + shippingPrice;

        /** How much hubPass saving would apply to this basket */
        const membershipSaving =
          parseFloat(
            membershipPricing.without_membership.basket.total_incl_tax
          ) -
          parseFloat(membershipPricing.with_membership.basket.total_incl_tax);

        /** Human-readable price to pay */
        const priceDisplay = Intl.NumberFormat("en-AU", {
          style: "currency",
          currency: "AUD"
        }).format(totalPrice);

        const { client_secret, exp_month, exp_year, last_4 } = setupIntent;

        /** The current card to use for payment */
        const card =
          last_4 && exp_month && exp_year
            ? ({
                last4: last_4,
                expiryMonth: exp_month,
                expiryYear: exp_year
              } as Card)
            : null;

        /** Does this user have a valid card saved against their Stripe profile? */
        const hasCard = card !== null;

        /** The last four digits of the current card, if a card exists */
        const last4 = hasCard ? card.last4 : null;

        /** Disable payment if the user has no registered card to pay with, or something is being loaded */
        const isPaymentDisabled =
          !hasCard || isSubmitting || isUpdatingSetupIntent;

        return (
          <WizardStep
            heading="Review & Pay"
            footerTop={
              isMember &&
              showMemberPromoText && (
                <div
                  css={(theme) => ({
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "16px 24px",
                    backgroundColor:
                      BRAND === "youly" ? "#F1CBE0" : theme.color.third,
                    [theme.mq.md]: {
                      display: "none"
                    }
                  })}
                >
                  <img
                    src="/assets/images/com/hubpass.svg"
                    alt="hubPass"
                    css={{ paddingLeft: "1px", marginTop: "-4px" }}
                  />{" "}
                  Saving{" "}
                  {Intl.NumberFormat("en-AU", {
                    style: "currency",
                    currency: basket.currency ?? undefined
                  }).format(membershipSaving)}{" "}
                  with hubPass
                  <div
                    css={{
                      position: "absolute",
                      top: "7px",
                      right: "10px"
                    }}
                    onClick={() => setShowMemberPromoText(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        d="M0.883789 1L8.88379 9"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.88379 1L0.883789 9"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              )
            }
            footer={
              <Button
                data-testid="review-and-pay"
                variant="primary"
                disabled={isPaymentDisabled}
                css={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  justifyContent: isSubmitting ? "center" : "space-between",
                  [theme.mq.md]: {
                    width: "50% !important"
                  }
                })}
                onClick={handleCheckout}
              >
                {isSubmitting && <Spinner />}
                {!isSubmitting && (
                  <>
                    <span>Pay now</span>
                    <span
                      css={{
                        borderLeft: "1px solid #FFFFFF",
                        paddingLeft: "24px"
                      }}
                    >
                      {priceDisplay}
                    </span>
                  </>
                )}
              </Button>
            }
          >
            <div
              css={(theme) => ({
                marginBottom: "50px",
                [theme.mq.md]: {
                  marginBottom: "unset"
                }
              })}
            >
              <div
                css={(theme) => ({
                  marginBottom: "16px",
                  [theme.mq.md]: {
                    marginBottom: "16px"
                  }
                })}
              >
                <BasketSummary
                  basket={basket}
                  bundle={bundle}
                  lines={lines}
                  shipping={{
                    methodName: shipping_method.name,
                    price: shippingPrice,
                    discount: shippingDiscount
                  }}
                  membership={{
                    isMember,
                    userMembership: hubPassMembership,
                    saving: membershipSaving,
                    price: membershipPrice
                  }}
                  isMembershipSelected={isMembershipSignupSelected}
                  orderOnDemand={orderOnDemand}
                  onIsMembershipSelectedChange={setIsMembershipSignupSelected}
                  onOrderOnDemandChange={setOrderOnDemand}
                  onDiscountCodeApplied={handleDiscountCodeApplied}
                  isApplyingDiscount={isApplyingDiscount}
                  voucherDiscounts={basket.voucher_discounts}
                  discountError={discountError}
                />
              </div>
              <div
                css={(theme) => ({
                  marginBottom: "16px",
                  ".addressText": {
                    fontWeight: "500",
                    fontSize: "14px",
                    [theme.mq.md]: {
                      fontSize: "16px"
                    }
                  },
                  ".edit-button": {
                    background: "unset",
                    border: "unset",
                    color: theme.color.primary,
                    fontWeight: "600",
                    fontSize: "14px",
                    paddingTop: "0px",
                    [theme.mq.md]: {
                      fontSize: "16px"
                    }
                  }
                })}
              >
                <ShippingAddress
                  compactView
                  isEditingCallback={() => {}}
                  autoSave={true}
                />
              </div>
              {isUpdatingSetupIntent && (
                <div
                  css={(theme) => ({
                    ".edit-button": {
                      background: "unset",
                      border: "unset",
                      color: theme.color.primary,
                      fontWeight: "600",
                      fontSize: "14px",
                      paddingTop: "0px",
                      [theme.mq.md]: {
                        fontSize: "16px"
                      }
                    }
                  })}
                >
                  <UpdatePaymentMethod
                    last4={last4}
                    isDisabled
                    onUpdate={handlePaymentMethodUpdated}
                  />
                </div>
              )}
              {!isUpdatingSetupIntent && (
                <div
                  css={(theme) => ({
                    ".edit-button": {
                      background: "unset",
                      border: "unset",
                      color: theme.color.primary,
                      fontWeight: "600",
                      fontSize: "14px",
                      [theme.mq.md]: {
                        fontSize: "16px"
                      }
                    }
                  })}
                >
                  <Elements
                    stripe={stripe}
                    options={{ clientSecret: client_secret }}
                  >
                    <UpdatePaymentMethod
                      last4={last4}
                      isDisabled={isPaymentDisabled}
                      onUpdate={handlePaymentMethodUpdated}
                    />
                  </Elements>
                </div>
              )}
              {isMember && (
                <div
                  css={(theme) => ({
                    display: "none",
                    alignItems: "center",
                    gap: "8px",
                    padding: "16px 24px",
                    marginTop: "16px",
                    borderRadius: "8px",
                    backgroundColor:
                      BRAND === "youly" ? "#F1CBE0" : theme.color.third,
                    [theme.mq.md]: {
                      display: "flex"
                    }
                  })}
                >
                  <img
                    src="/assets/images/com/hubpass.svg"
                    alt="hubPass"
                    css={{ paddingLeft: "1px", marginTop: "-4px" }}
                  />{" "}
                  Saving{" "}
                  {Intl.NumberFormat("en-AU", {
                    style: "currency",
                    currency: basket.currency ?? undefined
                  }).format(membershipSaving)}{" "}
                  with hubPass membership on this order!
                </div>
              )}
            </div>
          </WizardStep>
        );
      }}
    </Await>
  );
};
