import { useState } from "react";
import { NavLink } from "react-router-dom";
import { IconCaretDown, IconCaretRight } from "@mh/components";

interface FAQQuestionProps {
  title: string;
  content: React.ReactElement;
}

const FAQQuestion = ({ title, content }: FAQQuestionProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      css={{
        backgroundColor: "#F8F8F8",
        margin: "16px 0px"
      }}
    >
      <div
        css={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          fontWeight: "500",
          padding: "16px 24px",
          cursor: "pointer"
        }}
        onClick={() => setOpen(!open)}
      >
        <span>{title}</span>
        {open ? (
          <IconCaretDown
            css={(theme) => ({
              width: "16px",
              height: "16px",
              color: theme.color.primary
            })}
          />
        ) : (
          <IconCaretRight
            css={(theme) => ({
              width: "16px",
              height: "16px",
              color: theme.color.primary
            })}
          />
        )}
      </div>
      {open && (
        <div
          css={{
            color: "#6C757D",
            padding: "0px 24px 16px"
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

const HistoryLink = "/history";
const ProfileLink = "/profile";

export const FAQ = () => (
  <div>
    <FAQQuestion
      title="How do I verify my identity?"
      content={
        <>
          You will be prompted to verify your identity on the{" "}
          <NavLink to="/">&lsquo;Treatments&rsquo;</NavLink> page of your
          patient portal upon paying for your consultation. Alternatively, you
          can go to the
          <NavLink to={ProfileLink}>&lsquo;Profile&rsquo;</NavLink> page of your
          portal and follow the &lsquo;Digital ID&rsquo; verification process or
          add your Medicare details.
        </>
      }
    />
    <FAQQuestion
      title="How do I add my Medicare details?"
      content={
        <>
          You can add your Medicare details on the{" "}
          <NavLink to={ProfileLink}>&lsquo;Profile&rsquo;</NavLink> page of your
          patient portal.
        </>
      }
    />
    <FAQQuestion
      title="How do I upload images for my consultation?"
      content={
        <>
          You will be prompted to upload images on the{" "}
          <NavLink to="/">&lsquo;Treatments&rsquo;</NavLink> page of your
          patient portal.
        </>
      }
    />
    <FAQQuestion
      title="How do I book a time for my consultation?"
      content={
        <>
          After you pay for your consultation, you will be prompted to complete
          a few steps, including making a booking for your consultation. You can
          choose to join the queue or book a time that suits you for a phone
          call.
        </>
      }
    />
    <FAQQuestion
      title="What do I do if I missed the phone call for my consultation?"
      content={
        <>
          Your clinician will attempt to call you back within 30 minutes or
          notify you to reschedule your appointment.
        </>
      }
    />
    <FAQQuestion
      title="How do I renew my medication?"
      content={
        <>
          You will be prompted to complete a follow-up consultation when you
          have used all of your repeats and are scheduled to run out of
          medication. If you need to renew sooner, please contact our Patient
          Success team using the &lsquo;Contact Us&rsquo; button.
        </>
      }
    />
    <FAQQuestion
      title="Where can I see the delivery status of my order?"
      content={
        <>
          A tracking link will be available on the{" "}
          <NavLink to="/">&lsquo;Treatments&rsquo;</NavLink> page of your
          patient portal. Alternatively, you will find the tracking link for all
          your past orders on the{" "}
          <NavLink to={HistoryLink}>&lsquo;Order History&rsquo;</NavLink> page.
        </>
      }
    />
    <FAQQuestion
      title="How can I talk to a clinician about my treatment?"
      content={
        <>
          You can talk to a clinician by booking in a follow-up consult. To
          request a follow-up consult, contact our Patient Support team using
          the &lsquo;Contact Us&rsquo; button.
        </>
      }
    />
    <FAQQuestion
      title="How do I request to change the medication under my treatment plan?"
      content={
        <>
          You can contact our Patient Support team using the &lsquo;Contact
          Us&rsquo; button, and they will issue you a follow-up consultation
          where you can request a change to your treatment plan.
        </>
      }
    />
    <FAQQuestion
      title="How do I enable or disable automatically sending my next repeat?"
      content={
        <>
          You can enable or disable automatically sending repeats on the{" "}
          <NavLink to="/">&lsquo;Treatments&rsquo;</NavLink> page of your
          patient portal.
        </>
      }
    />
    <FAQQuestion
      title="Where can I find my medical certificate?"
      content={
        <>
          Once your clinician has issued your medical certificate, you can
          download it from the{" "}
          <NavLink to={HistoryLink}>&lsquo;Order History&rsquo;</NavLink> page.
        </>
      }
    />
    <FAQQuestion
      title="How do I download my invoice?"
      content={
        <>
          You can download your invoice from the{" "}
          <NavLink to={HistoryLink}>&lsquo;Order History&rsquo;</NavLink> page.
        </>
      }
    />
    <FAQQuestion
      title="How do I change my payment details?"
      content={
        <>
          You can change the payment details on the{" "}
          <NavLink to={ProfileLink}>&lsquo;Profile&rsquo;</NavLink> page of your
          patient portal.
        </>
      }
    />
    <FAQQuestion
      title="How do I change my shipping address?"
      content={
        <>
          You can change your shipping address on the{" "}
          <NavLink to={ProfileLink}>&lsquo;Profile&rsquo;</NavLink> page of your
          patient portal. Please note, if your order has already been processed
          we are unable to change the shipping address, in the event this occurs
          please contact our Patient Success team using the &lsquo;Contact
          Us&rsquo; button.
        </>
      }
    />
    <FAQQuestion
      title="How do I change my mobile number?"
      content={
        <>
          You can change your mobile number on the{" "}
          <NavLink to={ProfileLink}>&lsquo;Profile&rsquo;</NavLink> page of your
          patient portal.
        </>
      }
    />
    <FAQQuestion
      title="How do I cancel my order?"
      content={
        <>
          To cancel your order, please contact our Patient Success team using
          the &lsquo;Contact Us&rsquo; button. Please note that we cannot cancel
          any orders that are ready for delivery or have already been
          dispatched.
        </>
      }
    />
    <FAQQuestion
      title="How do I cancel my consultation appointment?"
      content={
        <>
          You can cancel your consultation by selecting &lsquo;Manage my
          booking&rsquo; button on the{" "}
          <NavLink to="/">&lsquo;Treatments&rsquo;</NavLink> page of your
          patient portal.{" "}
        </>
      }
    />
  </div>
);
