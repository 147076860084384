import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from "react";
import {
  BasketAPI,
  Basket,
  BasketLine,
  useAsync,
  asyncIsSuccess,
  createAsync,
  UseAsync,
  OptionPayload,
  OscarAPI,
  responseIsException,
  Async,
  type ShippingMethod,
  CheckoutAPI,
  type ProductLike
} from "@mh/api";
import { isTreatment } from "./utils";

export interface BasketState {
  /** Current basket. {@link Async} of type {@link Basket}. */
  readonly basket: UseAsync<Basket>;
  /** The current lines attached to this basket. {@link Async} of {@link BasketLine} */
  readonly lines: Async<BasketLine[]>;
  /** Shipping methods for this basket. Dependent on the current basket {@link lines} */
  readonly shippingMethods: Async<ShippingMethod[]>;
  /** Adds an item to the basket */
  readonly addItem: (
    item: Pick<ProductLike, "id">,
    quantity: number,
    options?: OptionPayload[]
  ) => Promise<void>;
  /** Sets the selected quantity of an item at the given idnex in the basket */
  readonly setQuantity: (index: number, quantity: number) => Promise<void>;
  /** Removes an item from the basket */
  readonly removeItem: (lineId: number) => Promise<void>;
  /** Deletes all lines in the current basket */
  readonly reset: () => Promise<void>;
  /** Reload basket lines */
  readonly reloadLines: () => Promise<void>;
  /** Keeps track of which products in the basket are currently involved in an async calls */
  readonly addingProducts: Set<number>;
  readonly removingLines: Set<number>;
}

export function createBasket(): BasketState {
  return {
    basket: {
      async: createAsync(),
      refresh: () => {
        /** Filled in by provider */
      },
      setData: () => {
        /** Filled in by provider */
      }
    },
    lines: createAsync(),
    shippingMethods: createAsync(),
    addingProducts: new Set(),
    removingLines: new Set(),
    /** Following all filled in by provider */
    addItem: async () =>
      Promise.reject(
        new Error("BasketContext.addItem has not been implemented")
      ),
    setQuantity: () =>
      Promise.reject(
        new Error("BasketContext.setQuantity has not been implemented")
      ),
    removeItem: () =>
      Promise.reject(
        new Error("BasketContext.removeItem has not been implemented")
      ),
    reset: () =>
      Promise.reject(new Error("BasketContext.reset has not been implemented")),
    reloadLines: () =>
      Promise.reject(
        new Error("BasketContext.reloadLines has not been implemented")
      )
  };
}

export const BasketContext = createContext(createBasket());

const pendingReducer =
  (adding: boolean) =>
  (
    state: Set<number>,
    action: ["add", number] | ["update", number[]]
  ): Set<number> => {
    if (action[0] === "add") {
      return new Set([...state, action[1]]);
    }

    // If we are adding, we know the async has completed when the list includes the item, and therefore
    // should be filtered out. Otherwise for removal it is the inverse
    return new Set(
      [...state].filter((p) =>
        adding ? !action[1].includes(p) : action[1].includes(p)
      )
    );
  };

export const BasketContextProvider: FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const basket = useAsync(BasketAPI.getBasket);
  const [lines, setLines] = useState<Async<BasketLine[]>>(createAsync());
  // Shipping methods are loaded by the Checkout API but can potentially change whenever basket lines change
  const shippingMethods = useAsync(CheckoutAPI.getShippingMethods, {
    persisted_shipping: true
  });
  /** Is this the first time the shipping methods have been fetched? */
  const isFirstShippingMethodFetch = useRef<boolean>(true);
  // Keep track of the last shipping methods to avoid unnecessary reloads. Null for the first render
  const previousBasketLines = useRef<BasketLine[]>([]);

  const [pendingProductAdds, updatePendingProducts] = useReducer(
    pendingReducer(true),
    new Set<number>()
  );
  const [pendingLineRemovals, updatePendingLines] = useReducer(
    pendingReducer(false),
    new Set<number>()
  );

  const { async: basketAsync, refresh: refreshBasket } = basket;

  const loadLines = useCallback<() => Promise<void>>(async () => {
    if (asyncIsSuccess(basketAsync)) {
      const lines = await BasketAPI.getLines({ id: basketAsync.data.id });
      setLines(createAsync(lines));
      updatePendingProducts(["update", lines.map((l) => l.product.id)]);
      updatePendingLines(["update", lines.map((l) => l.id)]);
    }
  }, [basketAsync]);

  useEffect(() => {
    // Load basket lines initially
    loadLines();
  }, [loadLines]);

  const { refresh: refreshShippingMethods } = shippingMethods;
  useEffect(() => {
    // Refresh shipping methods when the basket lines change
    if (!asyncIsSuccess(lines)) return;

    // The IDs of the current basket lines
    const currentLines = lines.data.map((line) => line.id);
    // The IDs of the previous basket lines. If they're empty, then nothing had loaded on the last render and we
    // substitude in the current basket lines to avoid unnecessary shipping method reloading
    const previousLines = isFirstShippingMethodFetch.current
      ? currentLines
      : previousBasketLines.current.map((line) => line.id);

    // Check if any of the new basket lines are different to the old ones
    const haveBasketLinesChanged =
      currentLines.some((lineId) => !previousLines.includes(lineId)) ||
      previousLines.some((lineId) => !currentLines.includes(lineId));

    if (!isFirstShippingMethodFetch.current && haveBasketLinesChanged) {
      // Basket lines have updated. Refresh the shipping methods
      refreshShippingMethods();
    }

    if (isFirstShippingMethodFetch.current || haveBasketLinesChanged) {
      // Assign the cached old basket lines to the new ones, so they are equal and we don't refresh shipping methods
      // again
      previousBasketLines.current = lines.data;
    }
  }, [lines, refreshShippingMethods]);

  useEffect(() => {
    if (asyncIsSuccess(shippingMethods.async)) {
      // Shipping methods have loaded
      isFirstShippingMethodFetch.current = false;
    }
  }, [shippingMethods.async]);

  /** Utility function for updating an existing line
   * @param index The index of the line to update
   * @param updatedLine Updates the line with the existing line's details, overriden by new values
   */
  const updateLine = useCallback<
    (index: number, updatedLine: Partial<BasketLine>) => void
  >((index, updatedLine) => {
    if (asyncIsSuccess(lines)) {
      setLines(
        createAsync([
          ...lines.data.slice(0, index),
          { ...lines.data[index], ...updatedLine },
          ...lines.data.slice(index + 1)
        ])
      );
    }
  }, []);

  const addItem = useCallback<BasketState["addItem"]>(
    async (item, quantity, options) => {
      updatePendingProducts(["add", item.id]);
      const productWithUrl = await OscarAPI.getProduct(item.id);
      const updatedBasket = await BasketAPI.addProduct({
        url: productWithUrl.url,
        quantity,
        options
      });
      basket.setData(updatedBasket);
      await loadLines();
    },
    [basket.setData, loadLines]
  );

  const setQuantity = useCallback<BasketState["setQuantity"]>(
    async (index, quantity) => {
      if (!asyncIsSuccess(basketAsync) || !asyncIsSuccess(lines)) {
        // Don't do anything if the basket hasn't finished loading, because the lines won't have either
        return;
      }

      // Update the line and set its new details in state
      const { id: lineId } = lines.data[index];
      const updatedLine = await BasketAPI.updateLine(
        basketAsync.data.id,
        lineId,
        {
          id: lineId,
          quantity
        }
      );

      if (responseIsException(updatedLine)) {
        throw new Error("Could not set quantity of basket line");
      }

      updateLine(index, updatedLine);
      refreshBasket();
    },
    [basketAsync, lines, updateLine, refreshBasket]
  );

  const removeItem = useCallback<BasketState["removeItem"]>(
    async (lineId) => {
      if (!asyncIsSuccess(basketAsync)) {
        // Don't do anything if the basket hasn't finished loading, because the lines won't have either
        return;
      }

      updatePendingLines(["add", lineId]);

      await BasketAPI.deleteLine(basketAsync.data.id, lineId);
      refreshBasket();
    },
    [basketAsync, lines, refreshBasket]
  );

  const reset = useCallback<BasketState["reset"]>(async () => {
    if (!asyncIsSuccess(basketAsync) || !asyncIsSuccess(lines)) {
      // Don't attempt to delete the basket or lines haven't loaded yet
      return;
    }

    /**
     * Remove all non-OTC lines from the basket, since moving onto a checkout from the treatment acceptance page should
     * only have the prescribed medicine from that treatment acceptance appear in the checkout.
     *
     * Any OTC products in the basket however, should remain in the basket since they can be added independently of
     * the treatment acceptance.
     */
    await Promise.all(
      lines.data
        .filter((line) => isTreatment(line.product))
        .map((line) => BasketAPI.deleteLine(basketAsync.data.id, line.id))
    );
  }, [basketAsync, lines]);

  return (
    <BasketContext.Provider
      value={{
        basket,
        lines,
        shippingMethods: shippingMethods.async,
        addingProducts: pendingProductAdds,
        removingLines: pendingLineRemovals,
        addItem,
        setQuantity,
        removeItem,
        reset,
        reloadLines: loadLines
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
