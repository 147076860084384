import "@mh/components/src/styles.scss";
import "../../index.scss";

import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { debounce } from "@mh/core";
import {
  type Async,
  type Category,
  asyncIsSuccess,
  createAsync,
  type PaginationOptions,
  type ProductLike,
  type PaginatedResponse
} from "@mh/api";
import { getProductStatus, useBasket } from "@mh/basket";
import {
  ScrollableContainer,
  Await,
  Flex,
  Spinner,
  Search,
  PaginationController,
  DropdownButton,
  IconGrid,
  IconCaretDown
} from "@mh/components";

import { usePagination } from "../../hooks/usePagination";
import { ProductPreview } from "./../ProductPreview";
import { QuantityAndBasket } from "./../QuantityAndBasket";
import { useCategories } from "./hooks";

/** A function which takes in pagination options and a list of category IDs, and returns paginated products
 * with pricing */
export type LoadProductsFnPaginated<ProductType extends ProductLike> = (
  options: Required<PaginationOptions> & {
    categories?: number[];
    search?: string;
  }
) => Promise<PaginatedResponse<ProductType>>;

/** A function which takes in an option list of category IDs, and returns products with pricing */
export type LoadProductsFn<ProductType extends ProductLike> = (options: {
  categories?: number[];
  search?: string;
}) => Promise<ProductType[]>;

/** Pagination-related props */
interface PaginatedOTCProductsProps<ProductType extends ProductLike> {
  /** This component will be paginated */
  paginated: true;
  /** Function to load products with pagination */
  loadProducts: LoadProductsFnPaginated<ProductType>;
}

/** Unpagination-related props */
interface UnpaginatedOTCProductsProps<ProductType extends ProductLike> {
  /** This component will not be paginated */
  paginated?: false;
  /** Function to load products without pagination */
  loadProducts: LoadProductsFn<ProductType>;
}

/**
 * Provides the categories, search and products elements that can be rendered by this component. These elements
 * can be chosen to render where suitable.
 * @param elements The categories, search and products element that can be rendered by this component
 * @returns
 */
export type OTCProductsChildren = (elements: {
  categories?: JSX.Element;
  search?: JSX.Element;
  products: JSX.Element;
  pagination?: JSX.Element;
}) => JSX.Element;

/** Props shared across paginated and unpaginated functionality */
interface OTCProductsProps<ProductType extends ProductLike> {
  title?: string;
  scrollable?: boolean;
  /** Categories to have selectable in this component */
  categories?: Category[];
  /** If true, hides the "Products matching x" text from the search result */
  hideSearchResults?: boolean;
  /** If true, the product image and title are clickable */
  clickableTitle?: boolean;
  /**
   * An optional index which will set the category filter to be the category provided by {@link categories} at that
   * index
   */
  initialCategoryIndex?: number;
  /**
   * Event to fire when the info button is pressed on a product.
   * If this event is not provided, then the info button will not be rendered.
   *
   * @param product The product whose information button has just been pressed
   * @param showReadMore Sets the respective ProductPreview's "Read More" modal status in a controlled manner
   */
  onInfoPressed?: (
    product: ProductType,
    showReadMore: (show: boolean) => void
  ) => void;
  /**
   * Callback when the search changes
   *
   * @param search The search value
   */
  onSearch?: (search: string) => void;
  /**
   * Callback when the category changes
   *
   * @param category The category that has been changed to. Null if "All Products" was selected
   */
  onCategoryChange?: (category: Category | null) => void;
  /** If present, renders an item over the product image with the given product as a parameter */
  renderOverImage?: (product: ProductType) => JSX.Element;
  /** If the children prop is undefined, the products are rendered by default */
  children?: OTCProductsChildren;
}

type Props<ProductType extends ProductLike = ProductLike> = (
  | PaginatedOTCProductsProps<ProductType>
  | UnpaginatedOTCProductsProps<ProductType>
) &
  OTCProductsProps<ProductType>;

/**
 * A key for the products cache of format `<category_id>-<page>-<limit>-<search>`.
 * If no active category is selected, then the category ID will be -1.
 * If the component is not paginated, then the page will be 1.
 * The third template is the pagination page limit, which will default to 1. if the component is not paginated, this
 * will always be 1.
 * The fourth template is any search string.
 */
type CacheKey =
  `${Category["id"]}-${PaginationOptions["page"]}-${PaginationOptions["limit"]}-${string}`;

/**
 * A component which displays a list of (usually) OTC products.
 * This component can be used in a paginated or unpaginated manner, with the only difference being rendering and how
 * arguments are passed into the provided loadProducts function.
 *
 * If paginated is true, then the loadProducts function will be called with pagination options, and the component will
 * render with pagination options. Otherwise, pagination options will not appear.
 *
 * If scrollable is true, the products will be rendered within a {@link ScrollableContainer} component. Otherwise, they
 * will be rendered sequentially inside a {@link Flex} component.
 *
 * This component optionally takes in a product type so its props can be typed. This defaults to {@link ProductLike},
 * but can optionally be specified into a different product type if you need additional fields from that specific type.
 */
export const OTCProducts = <ProductType extends ProductLike = ProductLike>({
  loadProducts,
  title,
  scrollable,
  categories,
  hideSearchResults,
  clickableTitle,
  initialCategoryIndex,
  renderOverImage,
  onInfoPressed,
  onSearch,
  onCategoryChange,
  children
}: Props<ProductType>): JSX.Element => {
  const basket = useBasket();
  const { page, limit, setPage, setLimit } = usePagination();
  const { activeCategoryId, setActiveCategoryId, hasDoneInitialCategoryCheck } =
    useCategories(initialCategoryIndex, categories);
  /** Caching between a selected category's ID and what page the user is looking at */
  const productsCache = useRef<
    Map<CacheKey, PaginatedResponse<ProductType> | ProductType[]>
  >(new Map());
  const [products, setProducts] = useState<
    Async<PaginatedResponse<ProductType> | ProductType[]>
  >(createAsync());
  const [search, setSearch] = useState<string>("");

  /**
   * Loads OTC products and caches them. If the products have already been loaded, then they will be retrieved from the
   * cache.
   *
   * The products retrieved are set into state.
   *
   * @param page The current pagination page
   * @param limit The current pagination limit
   * @param activeCategoryId The currently selected category's ID. If no category is selected, then this will be -1
   * @param search The search string to filter products by. If the page, limit, active category or product loading function
   * change, the previous search value will be used. Otherwise if the function is called in a controlled method with a
   * search string, that search term will be used instead
   */
  const loadOTCProducts = useCallback<
    (
      page: number,
      limit: number,
      activeCategoryId: number,
      search: string
    ) => Promise<void>
  >(
    async (page, limit, activeCategoryId, search) => {
      /**
       * The options parameter of {@link LoadProductsFnPaginated} is a superset of the options parameter of
       * {@link LoadProductsFn}, so page and limit can be passed through as can be ignored if this component is not
       * used in a paginated manner
       */
      const options: Parameters<LoadProductsFnPaginated<ProductType>>[0] = {
        page,
        limit
      };

      if (activeCategoryId > 0) {
        // Load a specific category if one has been selected
        options.categories = [activeCategoryId];
      }

      if (search) {
        options.search = search;
      }

      const cacheKey: CacheKey = `${activeCategoryId}-${page}-${limit}-${search}`;

      if (productsCache.current.has(cacheKey)) {
        // If the products for this category-pagination combination have already been loaded, then use them
        setProducts(createAsync(productsCache.current.get(cacheKey)));
        return;
      }

      try {
        // Otherwise, load and cache the products
        setProducts(createAsync());
        const products = await loadProducts(options);
        productsCache.current.set(cacheKey, products);
        setProducts(createAsync(products));
      } catch (e) {
        // Loading failed :( poor alce alce
        setProducts({ state: "failed", error: "Failed to load products" });
      }
    },
    [loadProducts]
  );

  /** A debounced call of the loadOTCProducts function */
  const loadProductsDebounced = useMemo<
    (...args: Parameters<typeof loadOTCProducts>) => void
  >(() => debounce(loadOTCProducts, 500), [loadOTCProducts]);

  useEffect(() => {
    if (!hasDoneInitialCategoryCheck.current) {
      // Don't load categories if there is potentially an initial category to filter to
      return;
    }

    // If the user changes the page, limit, active category or the product loading function changes, then reload products
    loadProductsDebounced(page, limit, activeCategoryId, search);
  }, [page, limit, activeCategoryId, search, loadProductsDebounced]);

  const renderProduct = (product: ProductType): JSX.Element => {
    if (!asyncIsSuccess(basket.lines)) {
      // The basket's lines must have loaded
      return <Fragment key={product.id} />;
    }

    const { isPending, isInBasket, line } = getProductStatus(basket, product);

    /** Fires when an item is added to the cart. */
    const handleAddToCart = async (quantity: number): Promise<void> =>
      basket.addItem(product, quantity);

    /** Fires when an item should be removed from the cart. */
    const handleRemoveFromCart = async (): Promise<void> =>
      line ? basket.removeItem(line.id) : Promise.resolve();

    const handleShowDetails = (): void =>
      onInfoPressed ? onInfoPressed(product, () => {}) : undefined;

    const renderProductPreviewFooter = (
      showReadMore: (show: boolean) => void
    ): JSX.Element => {
      const handleInfoPressed = (): void => {
        if (onInfoPressed) {
          onInfoPressed(product, showReadMore);
        }
      };

      return (
        <QuantityAndBasket
          isAdded={isInBasket}
          onAdd={handleAddToCart}
          onRemove={handleRemoveFromCart}
          maxQuantity={product.max_repeats}
          isLoading={isPending}
          itemPrice={product.price ? parseFloat(product.price) : undefined}
          onInfoPressed={onInfoPressed ? handleInfoPressed : undefined}
        />
      );
    };

    return (
      <ProductPreview<ProductType>
        isFeatured={scrollable}
        key={product.id}
        product={product}
        renderFooter={renderProductPreviewFooter}
        renderOverImage={renderOverImage}
        {...(clickableTitle && { onTitleClick: handleShowDetails })}
      />
    );
  };

  /**
   * Renders the categories element
   * @param categories Categories to render
   * @returns The categories element
   */
  const renderCategories = (categories: Category[]): JSX.Element => {
    const handleCategoryChange = (id: number): void => {
      setActiveCategoryId(id);
      if (onCategoryChange) {
        onCategoryChange(
          categories?.find((category) => category.id === id) || null
        );
      }
    };

    return (
      <DropdownButton
        css={(theme) => ({
          width: "165px",
          backgroundColor: "transparent",
          color: "#000",
          fontWeight: "500",
          fontSize: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "7px",
          border: "none",
          borderRadius: "0px",
          borderBottom: `2px solid ${theme.color.secondary}`,
          [theme.mq.md]: {
            width: "195px",
            border: "none",
            color: "#fff",
            backgroundColor: theme.color.secondary,
            padding: "19px 0 9px 0",
            borderRadius: "8px",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0"
          }
        })}
        modalBodyCss={{ minHeight: "100%" }}
        dropdownContent={
          <div
            css={(theme) => ({
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              width: "100%",
              minWidth: "300px",
              marginTop: "-15px",
              borderRadius: "8px",
              "a.active": {
                fontWeight: "600"
              },
              "a:last-child": {
                borderBottom: "0"
              },
              [theme.mq.md]: {
                marginTop: "0px",
                padding: "0px 17px",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                a: {
                  ":first-child": {
                    paddingTop: "25px"
                  },
                  ":last-child": {
                    paddingBottom: "25px"
                  }
                }
              }
            })}
          >
            {[{ id: -1, name: "All Products" }, ...categories].map((item) => (
              <a
                key={item.id}
                onClick={() => handleCategoryChange(item.id)}
                className={item.id === activeCategoryId ? "active" : ""}
                css={{
                  padding: "10px 8px",
                  borderBottom: "1px solid #eee",
                  textDecoration: "none",
                  color: "black",
                  cursor: "pointer"
                }}
              >
                {item.name}
              </a>
            ))}
          </div>
        }
        dropdownTitle="Browse"
      >
        <IconGrid
          id="grid"
          css={(theme) => ({
            width: "20px",
            height: "19px",
            color: theme.color.secondary,
            [theme.mq.md]: {
              color: "#fff"
            }
          })}
        />{" "}
        Categories
        <IconCaretDown
          css={{
            width: "16px",
            height: "16px"
          }}
        />
      </DropdownButton>
    );
  };

  /**
   * Renders the search element
   * @returns The search element
   */
  const renderSearch = (): JSX.Element => (
    <Flex
      alignItems="center"
      width="100%"
      className="otc-products-search-wrapper"
    >
      {search &&
        !hideSearchResults &&
        asyncIsSuccess(products) &&
        !Array.isArray(products.data) && (
          // If products.data is not an array, then it is paginated
          <Flex flex="1" flexDirection="column">
            <h3>Products matching &quot;{search}&quot;</h3>
            <span>
              Found {products.data.count} results, showing{" "}
              {products.data.results.length}
            </span>
          </Flex>
        )}
      <Search
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            const searchValue = e.currentTarget.value.trim();

            if (search !== searchValue) {
              // The search state shouldn't be reset if you're searching for the same thing, since it'll be cached
              setProducts(createAsync());
            }

            setSearch(searchValue);
            if (onSearch) {
              onSearch(searchValue);
            }
          }
        }}
      />
    </Flex>
  );

  /**
   * Renders the products element
   * @returns The products that have loaded
   */
  const renderProducts = (): JSX.Element => (
    <Await<[PaginatedResponse<ProductType> | ProductType[]]>
      asyncs={[products]}
      renderPending={
        // A placeholder block for OTC products while they load
        <div className="scrollable-container scrollable-container-placeholder">
          <Spinner />
        </div>
      }
    >
      {([productsResult]) => {
        // Extract products from pagination if it's paginated, otherwise just use the list products
        const products: ProductType[] = Array.isArray(productsResult)
          ? productsResult
          : productsResult.results;

        return (
          <>
            {scrollable && (
              <ScrollableContainer<ProductType>
                title={title ?? "Recommended Products"}
                items={products}
                scrollable
                renderItem={renderProduct}
              />
            )}
            {!scrollable && (
              <Flex flexWrap="wrap" className="otc-products-non-scrollable">
                {products.map(renderProduct)}
              </Flex>
            )}
          </>
        );
      }}
    </Await>
  );

  const renderPagination = (): JSX.Element => {
    if (!asyncIsSuccess(products) || Array.isArray(products.data)) {
      // This should ideally never be hit. If the response is not paginated, then parent component should not render
      // the pagination controls
      return (
        <PaginationController
          page={page}
          previous={null}
          next={null}
          count={0}
          limit={limit}
          onPageChange={setPage}
          onLimitChange={setLimit}
        />
      );
    }

    const { previous, next, count } = products.data;

    // Strip the next page regex out of the previous and next hyperlinked fields: "page=1" -> 1
    const pageMatch = /page=(\d+)/;
    const previousPage = previous?.match(pageMatch)?.at(1);
    const nextPage = next?.match(pageMatch)?.at(1);

    return (
      <PaginationController
        page={page}
        previous={previousPage ? parseInt(previousPage) : null}
        next={nextPage ? parseInt(nextPage) : null}
        count={count}
        limit={limit}
        onPageChange={setPage}
        onLimitChange={setLimit}
      />
    );
  };

  return children
    ? children({
        // Temporarily ignore the all-otc-products category while the change to use it is in progress
        // This step will be undone in https://midnight-health.atlassian.net/browse/ENG-1457
        categories:
          categories &&
          renderCategories(
            categories.filter(
              (category) => category.slug !== "all-otc-products"
            )
          ),
        search: renderSearch(),
        products: renderProducts(),
        pagination: renderPagination()
      })
    : renderProducts();
};
