import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  HorizontalDivider,
  ImageSelector,
  Modal,
  ModalProps,
  Spinner,
  Toast
} from "@mh/components";
import { CategoryImageRequirement } from "@mh/api";

const RESIZE_IMAGE_MAX_WIDTH = 2048;
const RESIZE_IMAGE_MAX_HEIGHT = 2048;

interface UploadImagesModalProps extends Pick<ModalProps, "onHide" | "show"> {
  /** The image requirement specifications */
  imageRequirements?: CategoryImageRequirement[];
  /** The specific instructions for a singular image request from the clinician */
  imageRequestInstructions?: string | null | undefined;
  /** Fired on submission of chosen images. */
  onSubmit: (
    images: Record<number, [File[], CategoryImageRequirement]>
  ) => Promise<Set<number>>;
  isRepeat?: boolean;
}

export const UploadImagesModal = ({
  imageRequirements,
  imageRequestInstructions,
  onHide,
  onSubmit,
  show,
  isRepeat
}: UploadImagesModalProps) => {
  const navigate = useNavigate();

  const requirements: CategoryImageRequirement[] = imageRequirements
    ? imageRequirements
    : [
        {
          id: 0,
          category: -1,
          name: "Clinician requested image",
          required: true,
          public: true,
          description: imageRequestInstructions ?? null,
          requirement_type: isRepeat ? "repeat_only" : "initial_only"
        }
      ];

  const requirementIDMap: Map<number, CategoryImageRequirement> = new Map();
  requirements.forEach((requirement) =>
    requirementIDMap.set(requirement.id, requirement)
  );

  const [images, setImages] = useState<
    Record<number, [File[], CategoryImageRequirement]>
  >({});
  const [uploadedImages, setUploadedImages] = useState<
    Record<number, [File[], CategoryImageRequirement]>
  >({});
  const [imageErrorMessages, setImageErrorMessages] = useState<
    Record<number, string>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnSet = (
    id: number,
    imageArray: File[],
    requirement: CategoryImageRequirement
  ) => {
    if (imageArray.length > 4) {
      setImageErrorMessages({
        [id]: "Maximum of 4 images can be uploaded."
      });
      return;
    }
    setImages({
      ...images,
      [id]: [imageArray, requirement]
    });
    setImageErrorMessages((currentValue) => {
      const { [id]: _, ...rest } = currentValue;
      return rest;
    });
  };

  const handleOnRemove = (id: number) => {
    setImages((currentValue) => {
      const { [id]: _, ...rest } = currentValue;
      return rest;
    });
    setImageErrorMessages((currentValue) => {
      const { [id]: _, ...rest } = currentValue;
      return rest;
    });
  };

  const handleOnError = (id: number, files?: File[]) => {
    setImages((currentValue) => {
      const { [id]: _, ...rest } = currentValue;
      return rest;
    });
    const fieldNames = files && files.map((f) => f.name);
    const errorMessage = fieldNames
      ? `The image you selected is invalid or unsupported: ${fieldNames}. Please select another.`
      : "The image you selected is invalid or unsupported. Images must be in JPEG or PNG format.";
    setImageErrorMessages((currentValue) => ({
      ...currentValue,
      [id]: errorMessage
    }));
  };

  const excludeUploadedImages = (
    images: Record<number, [File[], CategoryImageRequirement]>
  ) => {
    const uploadedFiles = Object.entries(uploadedImages)?.map(
      ([_, [file, _2]]) => file
    );
    const remainingImages = uploadedFiles
      ? Object.fromEntries(
          Object.entries(images).filter(([_, [file, _2]]) => {
            const uploadedFileNames = uploadedFiles.flat().map((f) => f.name);
            return file.filter((f) => !uploadedFileNames.includes(f.name));
          })
        )
      : images;
    return remainingImages;
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    try {
      const errors = await onSubmit(excludeUploadedImages(images));

      if (errors.size === 0) {
        Toast.success("Image uploaded successfully");
        setTimeout(() => {
          navigate(0);
        }, 1200);
        return;
      }

      requirements.forEach((requirement) => {
        if (errors.has(requirement.id)) {
          handleOnError(requirement.id);
        } else {
          setUploadedImages((currentValue) => ({
            ...currentValue,
            [requirement.id]: [images[requirement.id]?.[0], requirement]
          }));
        }
      });
    } catch (e) {
      Toast.error("Images upload has failed.");
      setTimeout(() => {
        navigate(0);
      }, 1200);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal disabled={isLoading} onHide={onHide} show={show}>
      <Modal.Title
        onClose={onHide}
        subtitle="Allowed file types: JPEG & PNG. Maximum of 4 images can be uploaded."
      >
        Upload images
      </Modal.Title>
      <div
        css={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%"
        }}
      >
        {requirements.map((requirement) => (
          <Fragment key={requirement.id}>
            <ImageSelector
              requirement={requirement}
              disabled={isLoading}
              error={imageErrorMessages[requirement.id]}
              image={images[requirement.id]?.[0]}
              onError={handleOnError}
              onRemove={handleOnRemove}
              onSet={handleOnSet}
              resizeDimensions={{
                width: RESIZE_IMAGE_MAX_WIDTH,
                height: RESIZE_IMAGE_MAX_HEIGHT
              }}
            />
            <HorizontalDivider />
          </Fragment>
        ))}
        <div
          css={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "18px"
          }}
        >
          By submitting these images you confirm that they are current images of
          yourself and consent to their review by our medical team.
        </div>
      </div>
      <Modal.Actions sticky>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            gap: "16px",
            marginTop: "auto"
          }}
        >
          <Button
            disabled={isLoading}
            onClick={onHide}
            variant="primary-outline"
          >
            Cancel
          </Button>
          <Button
            disabled={
              isLoading ||
              !requirements.every((requirement) => !!images[requirement.id])
            }
            onClick={handleOnSubmit}
          >
            {isLoading ? <Spinner /> : "Send Images"}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
