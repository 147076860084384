import { Button, Form } from "react-bootstrap";
import { LoadedProduct } from "../questionnaire/utils";

interface Question {
  type: string;
  options: LoadedProduct[];
}

interface Props {
  currentTitle: string | undefined;
  currentQuestion: Question;
  onChange: (selectedOption: LoadedProduct) => void;
}

export const MedicationRadioBox = ({
  currentTitle,
  currentQuestion,
  onChange
}: Props) => {
  const items = currentQuestion.options
    .filter((t) => t) // Fix error to exclude null value
    .sort((a, b) =>
      a.title.trim().toLowerCase() < b.title.trim().toLowerCase() ? -1 : 1
    )
    .map((option, index) => {
      const selected = currentTitle === option.title;
      return (
        <Button
          key={`radio-${index}`}
          variant="outline-primary"
          active={selected}
          onClick={() => onChange(option)}
          disabled={!option.availability?.is_available_to_buy}
          css={(theme) => ({
            maxWidth: "342px",
            width: "100% !important",
            padding: "8px !important",
            marginLeft: "auto",
            marginRight: "auto",
            lineHeight: "normal",
            minHeight: "35px !important",
            height: "unset !important",
            [theme.mq.md]: {
              maxWidth: "431px !important",
              width: "100% !important"
            },
            [theme.mq.lg]: {
              width: "431px !important",
              maxWidth: "unset  !important"
            },
            "&:hover, &:active, &.active": {
              color: "white",
              ".text-primary": {
                color: "white !important"
              }
            }
          })}
        >
          <div className="d-flex flex-row">
            <Form.Check
              className="radioOuter my-auto"
              type="radio"
              checked={selected}
              readOnly
              css={[
                {
                  position: "relative",
                  marginBottom: "auto!important",
                  marginTop: "auto!important"
                },
                selected &&
                  ((theme) => ({
                    input: {
                      "&:checked": {
                        backgroundColor: theme.color.secondary,
                        borderColor: theme.color.secondary
                      }
                    },
                    "&:after": {
                      content: '""',
                      backgroundColor: "#fff",
                      borderRadius: "7.5px",
                      display: "inline-block",
                      height: "8px",
                      left: "50%",
                      position: "absolute",
                      width: "8px",
                      top: "59% !important",
                      transform: "translate(-51%, -50%) !important",
                      [theme.mq.md]: {
                        left: "50% !important"
                      }
                    }
                  }))
              ]}
            ></Form.Check>
            <div className="d-flex flex-column align-items-start ms-4 w-100">
              <div
                className="p1"
                css={{
                  textAlign: "left"
                }}
              >
                {option.title}
              </div>
              {option.price && (
                <div className="d-flex justify-content-between w-100">
                  <div
                    className="p-1 text-start text-primary"
                    css={{
                      fontWeight: "700",
                      paddingLeft: "0px !important"
                    }}
                  >
                    ${parseFloat(option.price).toFixed(2)}
                  </div>
                  {!option.availability?.is_available_to_buy && (
                    <div
                      className="ml-auto mt-auto p-1 red"
                      css={{
                        fontSize: "12px"
                      }}
                    >
                      Out of Stock
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Button>
      );
    });

  return (
    <div className="w-100 d-flex flex-column gap-3 mt-5 mb-5">{items}</div>
  );
};
