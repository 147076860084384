import { useState } from "react";
import { Form } from "react-bootstrap";

import { QuestionnaireContentComponentProps } from ".";

export const QCLongText = (props: QuestionnaireContentComponentProps) => {
  const [value, setValue] = useState<string>(props.initialValue ?? "");

  return (
    <Form.Control
      as="textarea"
      autoFocus={true}
      onKeyDown={(e) => {
        // Prevent going to the next question on enter press
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          setValue(`${value}\n`);
        }
      }}
      onChange={(event) => {
        const v = event.target.value;
        props.onChange(v);
        setValue(v);
      }}
      value={value}
      placeholder={props.question.placeholder}
    />
  );
};
