import { ComponentPropsWithoutRef } from "react";
import { CSSObject, Interpolation } from "@emotion/react";
import { Theme } from "@mh/components";
import { Size } from "../types";

interface HorizontalDividerProps extends ComponentPropsWithoutRef<"div"> {
  css?: Interpolation<Theme>;
  size?: Size;
}

const SIZE_MAP: Record<Size, CSSObject> = {
  sm: { margin: "8px 0px" },
  md: { margin: "16px 0px" },
  lg: { margin: "24px 0px" }
};

export const HorizontalDivider = ({
  css,
  size = "md",
  ...props
}: HorizontalDividerProps) => (
  <div
    css={[
      {
        borderTop: "0.5px solid #D7D7D7"
      },
      SIZE_MAP[size],
      css
    ]}
    {...props}
  />
);
