import { PropsWithChildren, useCallback, FC } from "react";
import { Button } from "../buttons";

import { Flex } from "../Flex";
import { IconClose } from "../icons";
import { Overlay } from "../Overlay";
import { classNames } from "../utils";

import "./styles.scss";

export interface InfoDrawerProps {
  /** Displays the info drawer is true */
  isOpen: boolean;
  /** Optional title to render at the top of the info drawer, to the left of the close button */
  title?: string;
  /** Toggle that fires when the info drawer is closed */
  onToggle: (isOpen: boolean) => void;
}

const InfoDrawer: FC<PropsWithChildren<InfoDrawerProps>> = (props) => {
  const toggle = useCallback(() => {
    props.onToggle(!props.isOpen);
  }, [props.isOpen, props.onToggle]);

  return (
    <Overlay
      onClick={toggle}
      onKeyDown={(e) => props.isOpen && e.code === "Escape" && toggle()}
      show={props.isOpen}
    >
      <Flex
        flexDirection="column"
        className={classNames(
          "info-drawer",
          props.isOpen && "info-drawer-open"
        )}
        marginBetween="16px"
      >
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          {props.title && <h3>{props.title}</h3>}
          <Button
            data-testid="info-drawer-close"
            onClick={toggle}
            variant="primary-plain"
          >
            <IconClose css={{ display: "block", width: "21px" }} />
          </Button>
        </Flex>
        <hr />
        <Flex
          flex="1"
          flexDirection="column"
          width="100%"
          className="mb-auto overflow-scroll"
        >
          {props.children}
        </Flex>
      </Flex>
    </Overlay>
  );
};

export default InfoDrawer;
