import { useState, type FC, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useStripe } from "@stripe/react-stripe-js";
import { StripeElements } from "@stripe/stripe-js";
import {
  DiscountCode,
  type DiscountCodeProps,
  Flex,
  Spinner,
  IconCash,
  IconDelivery,
  IconMedication,
  IconCheckCircle
} from "@mh/components";
import { Sentry } from "@mh/core";

import {
  QuestionnaireAPI,
  QuestionnairePaymentIntentResponse
} from "@mh/questionnaire/src/api";
import { useQContext } from "@mh/questionnaire/src/hooks";
import { PaymentQuestionTypeParams } from "@mh/questionnaire/src/types";

import { QuestionnaireContentComponentProps } from ".";
import "./QCQuestionnairePayment.scss";

interface IconTileProps {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  children?: JSX.Element;
}

export const ExpandableDiscountCode: FC<DiscountCodeProps> = ({
  ...discountCodeProps
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Flex flexDirection="column" style={{ marginTop: 24 }}>
      <Flex alignItems="center" onClick={() => setIsOpen(!isOpen)}>
        <h3 style={{ userSelect: "none" }}>Promo Code</h3>
        <span
          data-testid="expandable-discount-code-arrow"
          className={`qc-promo-expand-icon ${isOpen ? "open" : "closed"}`}
        >
          {"❯"}
        </span>
      </Flex>
      {isOpen && <DiscountCode {...discountCodeProps} />}
    </Flex>
  );
};

const IconTile = (props: IconTileProps) => (
  <Row className="qc-payment-tile">
    <Col className="qc-payment-tile-icon col-lg-2">{props.icon}</Col>
    <Col className="qc-payment-tile-content col-lg-10">
      <Row className="qc-payment-tile-title">{props.title}</Row>
      <Row className="qc-payment-tile-subtitle">{props.subtitle}</Row>
      {props.children}
    </Col>
  </Row>
);

/**
 * For some reason, I have to mount the payment component through the stripe elements.
 *
 * Ideally we should be able to wrap this element with an <Elements> component, but it doesn't seem to work,
 * it's also difficult to do because we need to change the price based on the voucher. Which would then
 * require us to update the <Elements> component, with the new client secret.
 */
const CardDetails = (props: QuestionnaireContentComponentProps) => {
  const [vouchers, setVouchers] = useState<string[]>([]);
  const [intentResponse, setIntentResponse] =
    useState<QuestionnairePaymentIntentResponse | null>(null);
  const [isUpdatingIntent, setIsUpdatingIntent] = useState(false);

  const [isPaying, setIsPaying] = useState<boolean>(false);
  const [isPaymentEntryComplete, setIsPaymentEntryComplete] = useState(false);

  const stripe = useStripe();
  const [elements, setElements] = useState<StripeElements | null>(null);

  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [discountError, setDiscountError] = useState<string | null>(null);

  useEffect(() => {
    setIsUpdatingIntent(true);
    const loadPaymentIntent = async () => {
      try {
        const response = await QuestionnaireAPI.getPaymentIntent(
          (props.question.type_params as PaymentQuestionTypeParams).product_id,
          vouchers
        );
        if (response.status === 404) {
          // Voucher does not exist
          setDiscountError("Provided voucher does not exist");
        } else if (response.status === 406) {
          // Something else went wrong
          const json = await response.json();
          setDiscountError(json.message);
        } else {
          const intent: QuestionnairePaymentIntentResponse =
            await response.json();
          setIntentResponse(intent);
          setDiscountError(null);
        }
      } catch (error) {
        Sentry.captureMessage(`Payment error: ${error}`);
        setLoadingError(true);
      } finally {
        setIsUpdatingIntent(false);
      }
    };
    loadPaymentIntent();
  }, [vouchers]);

  const handlePayment = async () => {
    if (!stripe || !intentResponse || !elements) {
      return;
    }

    setIsPaying(true);

    const { error } = await elements.submit();
    if (error) {
      console.error(error);
      setIsPaying(false);
      return;
    }

    const result = await stripe
      .confirmPayment({
        clientSecret: intentResponse.client_secret,
        elements,
        redirect: "if_required"
      })
      .finally(() => setIsPaying(false));

    if (result.error) {
      console.log(result.error.message);
    } else {
      props.onChange({ ...result, basket_id: intentResponse.basket_id }, true);
    }
  };

  useEffect(() => {
    /**
     * Note: This mounts the element twice, so it will flicker. This is only because
     * of the React.StrictMode, it doesn't happen in production.
     */

    if (!intentResponse?.client_secret || !stripe) return;
    setElements(
      stripe.elements({
        clientSecret: intentResponse?.client_secret
      })
    );
  }, [intentResponse]);

  useEffect(() => {
    if (!elements) return;
    /**
     * Already mounted, don't need to re-mount
     *
     * It has been confirmed against stripe that this element will use the latest intent
     * even if it isn't recreated against the elements instance of the newest secret intent
     */
    if (elements.getElement("payment")) return;

    const paymentElement = elements.create("payment");

    if (!paymentElement) {
      Sentry.captureMessage("Unexpected error creating payment element");
      return;
    }

    paymentElement.mount("#payment-element");

    paymentElement?.on("change", (event) =>
      setIsPaymentEntryComplete(event.complete)
    );

    return () => paymentElement?.unmount();
  }, [elements]);

  const CheckoutLoading = () => (
    <Flex flexDirection="column" alignItems="center">
      <Spinner />
      Loading your secure checkout...
    </Flex>
  );

  const CheckoutLoadingFailed = () => (
    <Flex flexDirection="column" alignItems="center">
      <div
        style={{
          width: "100%",
          backgroundColor: "#e74c3c",
          color: "white",
          borderRadius: 5,
          padding: 10,
          textAlign: "left",
          marginBottom: 10
        }}
      >
        We encountered an issue connecting to our secure checkout. Please wait a
        moment and try again.
      </div>
      <Button
        style={{ width: 200 }}
        onClick={() => {
          setLoadingError(false);
          setVouchers([...vouchers]);
        }}
      >
        Retry
      </Button>
    </Flex>
  );

  return (
    <IconTile
      title="Pay for your consultation"
      subtitle="You'll receive a full refund on your consultation fee if we can't help you."
      icon={<IconCash />}
    >
      <Col>
        <span className="qc-payment-black-spacer" />
        <Row>
          <Col>
            <h2>Your Payment Details</h2>
          </Col>
        </Row>
        {intentResponse === null ? (
          loadingError === false ? (
            <CheckoutLoading />
          ) : (
            <CheckoutLoadingFailed />
          )
        ) : (
          <>
            <div id="payment-element" className="qc-payment-element" />
            <ExpandableDiscountCode
              placeHolder={"Promo Code"}
              onApplied={(voucher) => setVouchers([voucher])}
              voucherDiscounts={intentResponse.price.voucher_discounts}
              isApplyingDiscount={isUpdatingIntent}
              discountError={discountError}
              showBottomMessage={true}
            />
            <span style={{ height: 10, display: "block" }} />
            <button
              className="btn btn-secondary"
              onClick={handlePayment}
              disabled={isUpdatingIntent || isPaying || !isPaymentEntryComplete}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              {isPaying || isUpdatingIntent ? (
                <Spinner />
              ) : (
                `Pay $${intentResponse?.price.total_incl_tax} for consult`
              )}
            </button>
          </>
        )}
      </Col>
    </IconTile>
  );
};

const ReviewTreatmentPlan = () => (
  <IconTile
    title="Review your treatment plan"
    subtitle="Our medical team will speak to you about your treatment and create a personalised treatment plan for your review. You can book a time for your consultation at the next step."
    icon={<IconMedication />}
  />
);

const MedicationToYourDoor = () => (
  <IconTile
    title="Get your medication delivered to your door"
    subtitle="Simply head back to your portal and pay everyday pharmacy prices for your medication! We will deliver your medication direct to your door via Express Post."
    icon={<IconDelivery />}
  />
);

export const QCQuestionnairePayment = (
  props: QuestionnaireContentComponentProps
) => {
  const { position, maxPosition } = useQContext();
  const lastQuestion = position === maxPosition;
  const text = lastQuestion ? "Submit" : "Next";

  return props.initialValue ? (
    <Flex css={{ flexDirection: "column", alignItems: "center" }}>
      <IconCheckCircle
        color="green"
        height={100}
        width={100}
        style={{ paddingBottom: 20 }}
      />
      Payment complete, please click {text} to continue
    </Flex>
  ) : (
    <Col>
      <div className="qc-payment-subtitle">What&apos;s next</div>
      <span style={{ height: 36, display: "block" }} />
      <Row>
        <Col>
          <CardDetails {...props} />
          <ReviewTreatmentPlan />
          <MedicationToYourDoor />
        </Col>
      </Row>
    </Col>
  );
};
