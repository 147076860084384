import { OexaScriptStatus } from "@mh/api";
import { Flex } from "@mh/components";

interface EscriptProductSelectorTitleProps {
  eScriptTokenStatus: string | undefined;
}

const getTitle = (status: OexaScriptStatus | undefined) =>
  ({
    Found: "Your e-prescription has been successfully matched and verified.",
    Dispensed: "Sorry this prescription has already been dispensed.",
    Expired: "Sorry this prescription has expired.",
    Error: "Sorry this token you entered is invalid.",
    Cancelled: "Sorry the original prescriber has cancelled this prescription.",
    Ceased: "Sorry the original prescriber has ceased this medicine.",
    Disabled: "Sorry this prescription you entered is invalid.",
    NotFound: "Sorry this prescription you entered is invalid.",
    NotAuthorized: "Unfortunately the token you entered is invalid.",
    InvalidRequest:
      "Sorry! It appears the electronic prescription management system is offline."
    // @ts-ignore
  })[status];

export const EscriptProductSelectorTitle = (
  props: EscriptProductSelectorTitleProps
) => (
  <Flex
    css={(theme) => ({
      width: "100%",
      fontSize: "20px",
      fontWeight: "500",
      marginBottom: "24px",
      textAlign: "center",
      [theme.mq.md]: {
        width: "431px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "32px"
      }
    })}
  >
    {!props.eScriptTokenStatus
      ? "Sorry! We unfortunately do not support chart based prescriptions."
      : // @ts-ignore
        getTitle(props.eScriptTokenStatus)}
  </Flex>
);
