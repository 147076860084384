import { Button, Form } from "react-bootstrap";

import { Flex } from "@mh/components";

export const MedicationInformationIsEffective = (props: {
  isEffective: boolean | null;
  setIsEffective: (v: boolean) => void;
  effectiveDetails: string | null;
  setEffectiveDetails: (v: string) => void;
}) => (
  <Flex
    className="field"
    flexDirection="column"
    width="100%"
    style={{ borderWidth: 0 }}
  >
    <div>
      <div className="asterisk">Did you get the results you hoped for?</div>
    </div>
    <div>
      <Flex justifyContent="center" style={{ marginTop: 10 }}>
        <Button
          className={`qc-boolean${
            props.isEffective === true ? " qc-boolean-selected" : ""
          }`}
          onClick={() => props.setIsEffective(true)}
        >
          Yes
        </Button>
      </Flex>
      <Flex justifyContent="center" style={{ marginTop: 10 }}>
        <Button
          className={`qc-boolean${
            props.isEffective === false ? " qc-boolean-selected" : ""
          }`}
          onClick={() => props.setIsEffective(false)}
        >
          No
        </Button>
      </Flex>
    </div>
    {props.isEffective && (
      <Form.Control
        as="textarea"
        placeholder="Please describe the results from your treatment"
        style={{ marginTop: 10 }}
        value={props.effectiveDetails ?? ""}
        onChange={(e) => props.setEffectiveDetails(e.target.value)}
      />
    )}
  </Flex>
);
