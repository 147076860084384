import type { FC } from "react";
import { Flex } from "@mh/components";

import IconMasterCard from "./icons/IconMasterCard";
import Form from "react-bootstrap/Form";

export interface Card {
  /** The last four digits of the user's card */
  last4: string;
  /** The expiry month of the user's card */
  expiryMonth: number;
  /** The expiry year of the user's card */
  expiryYear: number;
}

interface CardBlockProps {
  card: Card;
}

const CardBlock: FC<CardBlockProps> = (props) => {
  const yearStr = props.card.expiryYear.toFixed(0).substring(2, 4);
  const mnthStr = props.card.expiryMonth.toFixed(0).padStart(2, "0");

  return (
    <Flex className="payment-card" marginBetween="1rem">
      <IconMasterCard height={56} width={80} />
      <Flex flexDirection="column" className="payment-card-details">
        <div className="card-number">**** **** **** {props.card.last4}</div>
        <div className="card-expiry">
          EXP: {mnthStr}/{yearStr}
        </div>
      </Flex>
    </Flex>
  );
};

interface CardSelectorProps extends CardBlockProps {
  /** Whether this card input has been selected or not */
  selected: boolean;
  /** Fires when the card is selected */
  onSelect: () => void;
}

const CardSelector: FC<CardSelectorProps> = (props) => {
  return (
    <Flex flexDirection="column" width="100%" marginBetween="1rem">
      <Flex
        className="card-option"
        marginBetween="0.5rem"
        onClick={props.onSelect}
      >
        <Form.Check type="radio" checked={props.selected} readOnly />
        <span>Existing Card</span>
      </Flex>
      <CardBlock card={props.card} />
    </Flex>
  );
};

export default CardSelector;
