import { API } from "@mh/api";

type SafeRejectionResponse = Response | { ok: false };

/**
 * An easy way to access the feature API with default caching of 1h on the response
 */
export const Feature = {
  isActive: async (featureName: string): Promise<boolean> => {
    // Either a response or the failed ok on server rejection
    const response: SafeRejectionResponse = await API.v1()
      .url(
        `/checkfeatureactive/?${new URLSearchParams({ feature: featureName })}`
      )
      .cache({ cacheDuration: 60 * 60 })
      .unauthenticated()
      .get()
      .catch(() => ({ ok: false }));
    if (!response.ok) return false;
    return response
      .json()
      .then((res) => res && res.success && res.active)
      .catch(() => false);
  }
};
