import { useState } from "react";
import { QuestionnaireContentComponentProps } from "..";
import { Form } from "react-bootstrap";

export const QCListOtherInput = (
  props: QuestionnaireContentComponentProps & { show: boolean }
) => {
  const [value, setValue] = useState<string>(props.initialValue ?? "");

  return (
    <Form.Control
      className="qc-list-other"
      disabled={!props.show}
      placeholder={props.show ? "Please specify" : "Select other to specify"}
      onChange={(event) => {
        const v = event.target.value;
        props.onChange(v);
        setValue(v);
      }}
      value={props.show ? value : ""}
      autoFocus={true}
    />
  );
};
