import { useState, forwardRef } from "react";

/**
 * @type React.FC<{
 *  modaltype?: string; ref?: any
 * }>
 */
const Commmodal = forwardRef((props, ref) => {
  const [data_, setData] = useState();
  const [filename, setFilename] = useState();
  const { modalSet, enableclose } = props;

  const handleInputChange = (e) => {
    if (e.target.type === "file") {
      let newfiles = [];
      if (filename && Array.isArray(filename)) {
        for (let i = 0; i < filename.length; i++) {
          if (filename[i].name !== e.target.name) {
            newfiles.push(filename[i]);
          }
        }
      }
      newfiles.push({ file: e.target.files[0].name, name: e.target.name });
      setFilename(newfiles);
    }
    setData({
      ...data_,
      [e.target.name]:
        e.target.type === "file" ? e.target.files[0] : e.target.value
    });
  };

  function closeModal() {
    modalSet(false);
  }

  return (
    <div
      className={`modal-overlay translucent simple ${
        props.class_ ? props.class_ : ""
      }`}
    >
      <div
        className={`new-modal-dialog ${props.mobilewide ? "mobilewide" : ""} ${
          props.nomargin ? "m-0" : ""
        } ${props.innerclass_ ? props.innerclass_ : ""}`}
      >
        <div
          className={
            props.wide ? `new-modal-content wide ` : `new-modal-content`
          }
        >
          <div
            className={
              props.header || props.iconcenter
                ? props.nohaderbtborder
                  ? `modal-header nohaderbtborder`
                  : `modal-header`
                : "d-none"
            }
          >
            <div className="row w-100">
              <div
                className={
                  props.iconcenter
                    ? "col-12 iconcenter"
                    : props.icon
                    ? "icon col-2 col-md-2 col-lg-1 v-center pt-02 pt-md-05"
                    : "d-none"
                }
              >
                {props.image ? (
                  <img src={props.image} />
                ) : (
                  <i className={props.icon}></i>
                )}
              </div>
              {(!props.iconcenter || props.header) && (
                <div
                  className={
                    props.icon && !props.iconcenter
                      ? "col-10 col-md-10 col-lg-11 v-center"
                      : "col-12"
                  }
                >
                  <h4
                    className={
                      props.smallheader
                        ? `modal-title smallheader`
                        : `modal-title`
                    }
                    id="myModalLabel"
                  >
                    {props.header ? props.header : ""}
                  </h4>
                </div>
              )}
            </div>
            {props.close && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.close(props.for ? props.for : "")}
              >
                <span aria-hidden="true">×</span>
              </button>
            )}
            {!props.close && modalSet && enableclose && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => closeModal()}
              >
                <span aria-hidden="true">×</span>
              </button>
            )}
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
});

export default Commmodal;
