import { ClinicalDetail } from "@mh/api";

import { ProfileForm } from "./ProfileForm";
import { ProfileCard } from "./ProfileCard";
import { Validation } from "@mh/core";

export const HEIGHT_FIELD_LABEL = "Height (cm)";
export const WEIGHT_FIELD_LABEL = "Weight (kg)";
export const GOAL_WEIGHT_FIELD_LABEL = "Goal Weight (kg)";
export const SMOKING_STATUS_FIELD_LABEL = "Smoking Status";
export const ALCOHOL_STATUS_FIELD_LABEL = "Alcohol Status";
export const ALLERGIES_FIELD_LABEL = "Allergies";
export const CURRENT_MEDICATIONS_FIELD_LABEL = "Current Medications";
export const MEDICAL_CONDITIONS_FIELD_LABEL = "Other medical conditions";

interface ClinicalInfoProps {
  clinicalDetails: ClinicalDetail;
  onChange: (values: ClinicalDetail) => void;
  setIsEditingForm: (isEditing: boolean) => void;
  slug: string;
}

export const ClinicalInfo: React.FC<ClinicalInfoProps> = ({
  clinicalDetails,
  onChange,
  setIsEditingForm,
  slug
}: ClinicalInfoProps) => {
  const validateString = (value: string, minLength?: number) =>
    Validation.string()
      .strLen({ lt: minLength ?? 256, gtEq: 0 })
      .validate(value);
  return (
    <ProfileForm<ClinicalDetail>
      title="Clinical Details"
      id="clinicalDetails"
      initialValues={{
        height:
          clinicalDetails && clinicalDetails.height
            ? clinicalDetails.height.toString()
            : "",
        weight:
          clinicalDetails && clinicalDetails.weight
            ? clinicalDetails.weight.toString()
            : "",
        smoking_status: clinicalDetails?.smoking_status || "",
        alcohol_status: clinicalDetails?.alcohol_status || "",
        allergies: clinicalDetails?.allergies || "",
        current_medications: clinicalDetails?.current_medications || "",
        medical_conditions: clinicalDetails?.medical_conditions || "",
        patients_goal_weight:
          clinicalDetails && clinicalDetails.patients_goal_weight
            ? clinicalDetails.patients_goal_weight.toString()
            : ""
      }}
      validate={(values) => ({
        height: validateString(values.height, 6),
        weight: validateString(values.weight, 6),
        smoking_status: validateString(values.smoking_status),
        alcohol_status: validateString(values.alcohol_status),
        allergies: validateString(values.allergies),
        current_medications: validateString(values.current_medications),
        medical_conditions: validateString(values.medical_conditions),
        patients_goal_weight: validateString(values.patients_goal_weight, 6)
      })}
      isFetching={false}
      isEditingCallback={setIsEditingForm}
      onSave={async (values) => onChange(values)}
      isQuestionnaire={true}
    >
      {({ values, setValues, error, isEditing, id }) => (
        <div
          css={(theme) => ({
            span: {
              lineHeight: "normal",
              wordWrap: "break-word"
            },
            [theme.mq.md]: {
              label: {
                width: "122px",
                marginRight: "48px",
                textWrap: "wrap !important",
                lineHeight: "normal",
                marginTop: "auto",
                marginBottom: "auto"
              },
              span: {
                paddingLeft: "48px",
                maxWidth: "300px",
                lineHeight: "normal",
                wordWrap: "break-word"
              },
              input: {
                maxWidth: "275px"
              }
            }
          })}
        >
          <ProfileForm.Control
            label={HEIGHT_FIELD_LABEL}
            data-testid={`${id}-height`}
            type="number"
            disabled={false}
            error={error?.height}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({
                ...values,
                height: e.currentTarget.value
              })
            }
            value={values.height}
            flexDirection={"flex-column flex-md-row"}
          />
          <ProfileCard.HorizontalDivider bgColor={"#D9D9D9"} />
          <ProfileForm.Control
            label={WEIGHT_FIELD_LABEL}
            data-testid={`${id}-weight`}
            type="number"
            disabled={false}
            error={error?.weight}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({
                ...values,
                weight: e.currentTarget.value
              })
            }
            value={values.weight}
            flexDirection={"flex-column flex-md-row"}
          />
          {slug === "weight_management" && (
            <>
              <ProfileCard.HorizontalDivider bgColor={"#D9D9D9"} />
              <ProfileForm.Control
                label={GOAL_WEIGHT_FIELD_LABEL}
                data-testid={`${id}-goal-weight`}
                type="number"
                disabled={false}
                error={error?.patients_goal_weight}
                isEditing={isEditing}
                onChange={(e) => {
                  setValues({
                    ...values,
                    patients_goal_weight: e.currentTarget.value
                  });
                }}
                value={values.patients_goal_weight}
                flexDirection={"flex-column flex-md-row"}
              />
            </>
          )}
          <ProfileCard.HorizontalDivider bgColor={"#D9D9D9"} />
          <ProfileForm.Control
            label={SMOKING_STATUS_FIELD_LABEL}
            data-testid={`${id}-smoking-status`}
            disabled={false}
            error={error?.smoking_status}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({
                ...values,
                smoking_status: e.currentTarget.value
              })
            }
            value={values.smoking_status}
            flexDirection={"flex-column flex-md-row"}
          />
          <ProfileCard.HorizontalDivider bgColor={"#D9D9D9"} />
          <ProfileForm.Control
            label={ALCOHOL_STATUS_FIELD_LABEL}
            data-testid={`${id}-alcohol-status`}
            disabled={false}
            error={error?.alcohol_status}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({
                ...values,
                alcohol_status: e.currentTarget.value
              })
            }
            value={values.alcohol_status}
            flexDirection={"flex-column flex-md-row"}
          />
          <ProfileCard.HorizontalDivider bgColor={"#D9D9D9"} />
          <ProfileForm.Control
            label={ALLERGIES_FIELD_LABEL}
            data-testid={`${id}-allergies`}
            disabled={false}
            error={error?.allergies}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({
                ...values,
                allergies: e.currentTarget.value
              })
            }
            value={values.allergies}
            flexDirection={"flex-column flex-md-row"}
          />
          <ProfileCard.HorizontalDivider bgColor={"#D9D9D9"} />
          <ProfileForm.Control
            label={CURRENT_MEDICATIONS_FIELD_LABEL}
            data-testid={`${id}-current-medications`}
            disabled={false}
            error={error?.current_medications}
            isEditing={isEditing}
            as={"textarea"}
            rows={3}
            onChange={(e) =>
              setValues({
                ...values,
                current_medications: e.currentTarget.value
              })
            }
            value={values.current_medications?.replace(/;/g, "\n")}
            flexDirection={"flex-column flex-md-row"}
          />
          <ProfileCard.HorizontalDivider bgColor={"#D9D9D9"} />
          <ProfileForm.Control
            label={MEDICAL_CONDITIONS_FIELD_LABEL}
            data-testid={`${id}-medical-conditions`}
            disabled={false}
            error={error?.medical_conditions}
            isEditing={isEditing}
            as={"textarea"}
            rows={3}
            onChange={(e) =>
              setValues({
                ...values,
                medical_conditions: e.currentTarget.value
              })
            }
            value={values.medical_conditions?.replace(/;/g, "\n")}
            flexDirection={"flex-column flex-md-row"}
          />
        </div>
      )}
    </ProfileForm>
  );
};
