import type { Price } from "@mh/api";
import { Form } from "react-bootstrap";
import { BRAND } from "@mh/core";

interface MembershipPromoProps {
  /** The total savings membership would give */
  saving: number;
  /** The price of the membership product */
  price: Price;
  /** Is the membership signup button checked? */
  isMembershipChecked: boolean;
  /** Callback when the membership signup button is checked */
  onMembershipChecked: (checked: boolean) => void;
}

/**
 * Shows a promotional message for membership.
 */
export const MembershipPromo = ({
  saving,
  price,
  isMembershipChecked,
  onMembershipChecked
}: MembershipPromoProps) => {
  const { incl_tax, currency } = price;

  return (
    <div
      css={(theme) => ({
        display: "flex",
        flexFlow: "column",
        gap: "8px",
        width: "100%",
        padding: "16px",
        borderRadius: "4px",
        backgroundColor: BRAND === "youly" ? "#F1CBE0" : theme.color.third
      })}
    >
      <div
        className={isMembershipChecked ? "active" : ""}
        css={{
          display: "flex",
          alignItems: "center",
          gap: "8px"
        }}
      >
        <Form.Check
          type="radio"
          checked={isMembershipChecked}
          onChange={(e) => {
            onMembershipChecked(e.target.checked);
          }}
        />
        <div
          css={{
            fontWeight: "600"
          }}
        >
          Save{" "}
          {Intl.NumberFormat("en-AU", {
            style: "currency",
            currency: currency ?? undefined
          }).format(saving)}{" "}
          with{" "}
          <img
            src="/assets/images/com/hubpass.svg"
            alt="hubPass"
            css={{ paddingLeft: "1px", marginTop: "-4px" }}
          />
        </div>
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          gap: "8px",
          paddingLeft: "40px"
        }}
      >
        <b>FREE</b> express delivery
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          gap: "8px",
          paddingLeft: "40px"
        }}
      >
        <b>FREE</b> unlimited consults
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          gap: "8px",
          paddingLeft: "40px"
        }}
      >
        <span>Exclusive prices on orders</span>
      </div>
      <div
        css={{
          display: "flex",
          fontSize: "14px",
          fontWeight: "500",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        <b>Monthly fee (cancel anytime)</b>
        <b>
          {Intl.NumberFormat("en-AU", {
            style: "currency",
            currency
          }).format(parseFloat(incl_tax))}
        </b>
      </div>
    </div>
  );
};
