import React from "react";

import "./SplashScreen.scss";
import { SPLASH_CONTENTS } from "./SplashContents";
import { SplashContent, ContentOuter, Contents } from "./types";

export interface ReplaceTxt {
  from: string;
  to: string;
}

export interface SplashProps {
  name: string;
  from: string;
  classes: string | undefined;
  children: React.ReactNode | undefined;
  replace: ReplaceTxt[] | undefined;
}

const createInnerContent = (
  innerContents: JSX.Element[],
  index: number,
  content: Contents,
  isTxtGroup: boolean,
  replaceStrs: ReplaceTxt[]
) => {
  replaceStrs?.forEach((replace) => {
    if (content?.text?.includes("[[")) {
      content.text = content.text?.replace(replace.from, replace.to);
    }
    if (content?.path?.includes("[[")) {
      content.path = content.path?.replace(replace.from, replace.to);
    }
  });

  if (content.type === "txtgroup") {
    const txtGroup: JSX.Element[] = [];
    content.txtGroup?.forEach((content, index) => {
      createInnerContent(txtGroup, index, content, true, replaceStrs);
    });
    innerContents.push(
      React.createElement(
        content?.tag ?? "div",
        { key: `txtgroup-${index}`, className: content.classes },
        txtGroup
      )
    );
  } else if (content.type === "img") {
    if (isTxtGroup) {
      innerContents.push(
        <div key={`img-${index}`} className={`${content.classes}`}>
          <img src={content.path} className={`${content.innerClasses}`} />
        </div>
      );
    } else {
      innerContents.push(
        <img
          key={`img-${index}`}
          className={content.classes}
          src={content.path}
        />
      );
    }
  } else if (content.type === "link") {
    if (isTxtGroup) {
      innerContents.push(
        <div key={`link-${index}`} className={`${content.classes}`}>
          <a
            target="_blank"
            rel="noreferrer"
            href={content.path}
            className={`${content.innerClasses}`}
          >
            {content.text}
          </a>
        </div>
      );
    } else {
      innerContents.push(
        <a
          target="_blank"
          rel="noreferrer"
          key={`link-${index}`}
          className={content.classes}
          href={content.path}
        >
          {content.text}
        </a>
      );
    }
  } else {
    if (isTxtGroup) {
      innerContents.push(
        <div key={`div-${index}`} className={`${content.classes}`}>
          {React.createElement(
            content?.tag ?? "div",
            { className: content.innerClasses },
            content.text
          )}
        </div>
      );
    } else {
      innerContents.push(
        React.createElement(
          isTxtGroup ? "div" : content?.tag ?? "div",
          { key: `div-${index}`, className: content.classes },
          content.text
        )
      );
    }
  }
};

const getContent = (
  content: ContentOuter,
  extraClass: string,
  children: React.ReactNode | undefined,
  replaceStrs: ReplaceTxt[]
) => {
  const innerContents: JSX.Element[] = [];
  content.contents.forEach((content, ix) => {
    createInnerContent(innerContents, ix, content, false, replaceStrs);
  });

  return (
    <div className={`inner ${extraClass} ${content.classes}`}>
      {innerContents}
      {children}
    </div>
  );
};

export const SplashScreen: React.FC<SplashProps> = (props) => {
  const splashContents: SplashContent | undefined = SPLASH_CONTENTS.find(
    (s) => s.name === props.name
  );

  if (!splashContents) {
    return <div className="d-none"></div>;
  }

  const baseReplace = [
    {
      from: "[[from]]",
      to: props.from
    },
    {
      from: "[[brand]]",
      to: props.from === "hubhealth" ? "hub.health" : props.from
    }
  ];

  const REPLACESTR: ReplaceTxt[] = props?.replace
    ? [...baseReplace, ...props.replace]
    : baseReplace;

  const left = splashContents.left
    ? getContent(
        splashContents.left,
        !splashContents.right ? "full-left" : "only-left",
        props.children,
        REPLACESTR
      )
    : undefined;
  const right = splashContents.right
    ? getContent(
        splashContents.right,
        !splashContents.left ? "full-right" : "only-right",
        props.children,
        REPLACESTR
      )
    : undefined;

  return (
    <div
      className={`row splash-box ${props.from} ${splashContents.name}-outer ${props.classes}`}
    >
      {splashContents?.brandlogo && (
        <div className={`brandlogo ${props.from}`}>
          <img
            src={`/assets/images/${props.from}/${splashContents.brandlogo}`}
          />
        </div>
      )}
      {splashContents?.fullbg && (
        <div className="splashfullbg">
          <img src={`/assets/images/${props.from}/${splashContents.fullbg}`} />
        </div>
      )}
      <div className="splash-content">
        {left}
        {right}
      </div>
    </div>
  );
};
