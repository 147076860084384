import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useOutletContext, useNavigate } from "react-router-dom";
import jQuery from "jquery";
import queryString from "query-string";
import { useBeforeunload } from "react-beforeunload";
import "intl-tel-input/build/js/utils";
import "../../styles/_box.scss";
import { User, OscarAPI, Feature, PatientAPI, VoucherAPI } from "@mh/api";
import {
  EmailValidation,
  SafeURLSearchParams,
  Sentry,
  Validation,
  bmi
} from "@mh/core";
import { nibInternationalSearchParam } from "@mh/core/src/constants";
import {
  getOfferTreatmentList,
  Toast,
  LogoBrand,
  Login,
  Modal,
  ProgressBar,
  RedirectModal
} from "@mh/components";

import Userquestions from "../../questionnaires/userquestions";
import NewUserquestions from "../../questionnaires/userquestionsnew";
import UserquestionsV3 from "../../questionnaires/userquestionsV3";
import UserquestionsV4 from "../../questionnaires/userquestionsV4";
import AfterUserquestions from "../../questionnaires/afteruserquestions";
import AddUserquestions from "../../questionnaires/adduserquestions";
import AddUserquestionsnew from "../../questionnaires/adduserquestionsnew";
import AddUserquestionsnewv2 from "../../questionnaires/adduserquestionsnewv2";

import COMMTXT from "../common/commontext";
import Commmodal from "../common/commmodal";

import Base from "./basequestionnaire";
import QTemplate from "./templatequestionnaire";
import QuestionnaireDisabledMessage from "./QuestionnaireDisabledMessage";
import { QuestionnaireCompletionModal } from "./QuestionnaireCompletionModal";
import { EmployeeHealthCheckSplashLogo } from "./EmployeeHealthCheckSplashLogo";
import moment from "moment";
import { objectIsProduct, getCategory, setFieldAndValue } from "./utils";
import { getCookies } from "./../../utils";
import { useMembership } from "../../../packages/hooks";

let newPlainCode = null;

export default function Questionnaire(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { HALFDESIGN } = props;

  const [fullrefresh, setFullRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data_, setData] = useState();
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [hideback, setHideback] = useState(false);
  const [noaddbt, setNoaddbt] = useState(true);
  const [checkbts, checkbtsSet] = useState(false);
  const [accept_, acceptSet] = useState(false);
  const [acceptbypass_, acceptBypassSet] = useState(false);
  const [extrafields, extrafieldsSet] = useState(false);
  const [tokindex, tokindexSet] = useState(false);
  const [isnewuser_, setIsNewUser] = useState(false);
  const [patientid, setpatientid] = useState();

  const [firsttime, setFirst] = useState(false);

  const [productga, setProductga] = useState();
  const [productcode, setProductcode] = useState();
  const [productenv, setProductenv] = useState();
  const [resetProductEnv, setResetProductEnv] = useState(false);
  const [originProductenv, setOriginProductenv] = useState();
  const [productqus, setProductData] = useState();

  const [isprospect, setIsprospect] = useState(false);
  const [patientprospectid, setpatientprospectid] = useState();
  const [plainCode, setPlainCode] = useState();
  const [questionnaireid, setQuestionnaireid] = useState(false);
  const [questionnairedata, qdataSet] = useState([]);
  const [questionnaireprospect, qProspectSet] = useState([]);
  const [questionnaire, qSet] = useState([]);
  const [nextid, setNextId] = useState(0);
  const [subindex, setSubindex] = useState(0);
  const [subindex2, setSubindex2] = useState(0);

  const [hideOther, setHideOther] = useState(true);
  const [other, setOther] = useState(null);

  const [showpayment, setShowpayment] = useState(false);

  const [isStock, setIsStock] = useState(false);
  const [brand_pill_id, setBrandpillId] = useState(false);
  const [brand_pill, setBrandpill] = useState();
  const [brand_pill_title, setBrandpillTitle] = useState();
  const [price, setPrice] = useState();
  const [duration, setDuration] = useState();
  const [brandlist, setBrandList] = useState();
  const [brandlisttitle, setBrandListTitle] = useState();
  const [brandlistsubtitle, setBrandListSubtitle] = useState();
  const [brand_origin_pill, setOriginBrandpill] = useState();

  const [total, setTotal] = useState();
  const [duenow, setDueNow] = useState();

  const [user_email, setUserEmail] = useState();
  const [first_name, setFirstName] = useState();
  const [last_name, setLastName] = useState();
  const [dob_day, dob_daySet] = useState();
  const [dob_month, dob_monthSet] = useState();
  const [dob_year, dob_yearSet] = useState();

  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [suburb, setSuburb] = useState();
  const [state, setState] = useState();
  const [postcode, setPostcode] = useState();

  const [weight, weightSet] = useState();
  const [height, heightSet] = useState();
  const [gender, genderSet] = useState(null);
  const [isGenderProvided, setIsGenderProvided] = useState(false);
  const [smokingStatus, smokingStatusSet] = useState(null);

  const [medicare, medicareSet] = useState();
  const [medicareref, medicarerefSet] = useState();
  const [medicareexp, medicareexpSet] = useState();
  const [medicareexptemp, medicareexptempSet] = useState();
  const [originmedicare, originmedicareSet] = useState();
  const [originmedicareref, originmedicarerefSet] = useState();
  const [originmedicareexp, originmedicareexpSet] = useState();
  const [nomedicare, nomedicareSet] = useState(false);
  const [medicareSelectedStatus, medicareSelectedStatusSet] = useState(null); // 'yes', 'no' or null (neither)

  const [scriptqustype, setScriptqusType] = useState();
  const [scriptqus, setScriptQus] = useState();
  const [scriptfile, setScriptFile] = useState();
  const [_scriptname, setScriptName] = useState();
  const [_hasscript, setHasScript] = useState(false);

  // For renew a script questionnaire
  const [script_only, setScriptOnly] = useState(false);
  const [consultfee, setConsultFee] = useState(0);
  // The product used for this category's consultation
  const [consultProduct, setConsultProduct] = useState(null);
  const [deliveryop, setDeliveryOp] = useState(false);
  const [categoryList, setCategoryList] = useState(false);

  const [deliveryoption, deliveryoptionSet] = useState();
  const [deliverycost, deliverycostSet] = useState();

  const [imagefiles, setImageFiles] = useState();

  const [isend, isendset] = useState(false);

  const [iseventpromo, setIsEventPromo] = useState(false);

  const [modaldata, modaldataSet] = useState();
  const [modal_, modalSet] = useState(false);

  const [gaonce, setGaonce] = useState(false);
  const [prequs, prequsSet] = useState();

  const [issameday, isSameDay] = useState();

  const [getdiscount, setGetDiscount] = useState(false);

  const [checkedprod, setCheckedProd] = useState();

  const [scriptToken, setScriptToken] = useState("");

  const [unload, setUnload] = useState(false);

  const [isInitDone, setisInitDone] = useState(false);

  const [addComIndex, setAddComIndex] = useState(false);

  // Only for the medical certificate
  const [reason_, setReason] = useState(false);
  const [isCert, setIsCert] = useState(false);

  const [isCheckRes, setIsCheckRes] = useState(false);
  const [preventTopScroll, setPreventTopScroll] = useState(false);

  /*
        This is Welcome Page A B test flag,
        It is temporary variable to find out which welcome page is more user friendly

        How it works:
        - Check new in querystring, and if new is exist, set value in setVer
        - new welcome page will show if isver has value, otherwise, show old welcome page
    */
  const [isver, setVer] = useState(false);

  const [ph, setPh] = useState(null);

  // From query string related
  const [fromParam, setFromParam] = useState(false);
  const [newLogo, setNewLogo] = useState(false);
  const [newLogoClass, setNewLogoClass] = useState(false);
  const [isValidParam, setIsValidParam] = useState(false);

  // Membership (ex: nib)
  const [membership, setMembership] = useState("");

  const [callTime, setCallTime] = useState(false);
  const [showAfter, setShowAfter] = useState(null);

  const [typeSet, setTypeSet] = useState(false);

  const [btLoading, setBtLoading] = useState(false);
  const [category, setCategory] = useState(false);

  const [submitLock, setSubmitLock] = useState(false);

  // For Chemist2u
  const [isChemist2u, setIsChemist2u] = useState("no");
  const [shipCost, setShipCost] = useState();

  const [triggerPromo, setTriggerPromo] = useState(false);

  // Address check for cold chain delivery
  const [iscoldchain, setIsColdChain] = useState(false);
  // WM cold chain prod disabled because of sold out
  const [injectablesUnavailable, setInjectablesUnavailable] = useState(false);

  // Rosemary Id
  const [roseRXID, setRoseRXID] = useState(false);

  const [consultReferrer, setConsultReferrer] = useState(false);

  const [curGaid, setCurGaid] = useState(false);

  // Vouchers
  /** Array of active vouchers */
  const [activeVouchers, setActiveVouchers] = useState([]);
  /** The currently active discount */
  const [discount, setDiscount] = useState(null);
  /** Voucher error message. Nullable string */
  const [voucherError, setVoucherError] = useState(null);

  //BMI value for Weight Management
  const [bmiValue, setBmiValue] = useState(0);

  // blood pressure
  const [highBlood, setHighBlood] = useState(null);
  const [lowBlood, setLowBlood] = useState(null);

  //Show new payment page
  const [isShowNewPaymentPage, setIsShowNewPaymentPage] = useState(false);

  //eScript token status for fill-a-script
  const [eScriptStatus, setEScriptStatus] = useState(false);
  //eScript medication list for fill-a-script
  const [medicationList, setMedicationList] = useState(false);
  //eScript medication name for fill-a-script
  const [eScriptName, setEScriptName] = useState(false);
  //eScript repeat remaining for fill-a-script
  const [eScriptRemaining, setEScriptRemaining] = useState(false);

  const [userInfo, setUserInfo] = useState(null);

  //Feature Flag to check under age
  const [underAgeQuestion, setUnderAgeQuestion] = useState(false);

  // Payment type could be pay or membership, used in the payment page
  const [paymentType, setPaymentType] = useState("pay");

  // Only for Nibmember question type
  const [ihi, ihiSet] = useState(false);
  const [noIhi, noIhiSet] = useState(false);
  const [ihiSelectedStatus, ihiSelectedStatusSet] = useState(null);
  const [showIhiQustion, showIhiQuestionSet] = useState(false);

  // for arhi patient
  const [arhi, setArhi] = useState(false);
  const [isNibPatient, setIsNibPatient] = useState(false);

  // for custom category for medication search
  const [medicationCategoryId, setMedicationCategoryId] = useState(false);

  const [skipUserQuestionForNib, setSkipUserQuestionForNib] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const [healthCheckResult, setHealthCheckResult] = useState(null);

  const [hideSplash, setHideSplash] = useState(false);

  const childRef = useRef();
  const childPromoRef = useRef();
  const membershipsStatus = useMembership();

  const { voucherpair } = useOutletContext();

  const ChemistShipCost = 18.95;

  let email_;

  const commonvalues = COMMTXT;

  /**
   * Checks that a voucher code is valid and returns what discount would be applied to the ensuing consultation or product purchase
   *
   * @param {string} voucher The voucher code to apply
   */
  const applyVoucher = useCallback(
    (voucher) => {
      /**
       * Checks that a voucher exists and applies it to the consultation
       * @param {string} voucher The voucher code to apply
       * @param {import("@mh/api").Product} consultationProduct The consultation product which the given voucher will apply to
       */
      const checkVoucherExists = async (voucher, consultationProduct) => {
        try {
          const discount = await VoucherAPI.checkVoucher(
            voucher,
            consultationProduct.id
          );
          setActiveVouchers(discount.voucher_discounts);
          setDiscount(discount);
          setConsultFee(parseFloat(discount.total_incl_tax));
        } catch (e) {
          setVoucherError(
            "Looks like that's the wrong code. Please double-check and try again."
          );
        }
      };

      setVoucherError(null);
      if (!activeVouchers.includes(voucher) && consultProduct !== null) {
        checkVoucherExists(voucher, consultProduct);
      }
    },
    [activeVouchers, consultProduct]
  );

  /** Apply a voucher from the URL search params, if present. */
  useEffect(() => {
    if (consultProduct && (voucherpair.voucher || voucherpair.voucherfromurl)) {
      applyVoucher(voucherpair.voucher || voucherpair.voucherfromurl);
    }
  }, [voucherpair, consultProduct]);

  const callTimeChange = (startTime, endTime) =>
    setCallTime([startTime, endTime]);

  function resetBrandInfo() {
    setScriptOnly(false);
  }

  function medicationChange(banrdpillInfo) {
    setPreventTopScroll(true);
    if (banrdpillInfo?.categories) {
      const catList = Base.getprodcatinfo();
      const Categories = banrdpillInfo?.categories
        .map((cat) => {
          const catinfo = catList.find(
            (c) => c.name.toLowerCase() === cat.name?.toLowerCase()
          );
          if (catinfo) {
            cat.displayName = catinfo?.showtitle ?? catinfo?.name;
            return cat;
          }
        })
        .filter((t) => t);
      setCategoryList(getOfferTreatmentList(Categories));
    }

    setBrandpillId(banrdpillInfo?.id);
    setBrandpill(banrdpillInfo?.title);
    setDuration(banrdpillInfo?.duration);
    setIsStock(banrdpillInfo?.availability?.is_available_to_buy ? "yes" : "no");
  }

  useBeforeunload((event) => {
    if (unload) {
      event.preventDefault();
      event.stopPropagation();
    }
  });

  function setProduct(e = null, info, checkedprod = null) {
    if (info.duration) {
      setDuration(info.duration);
      if (
        questionnaire.length - 2 > 0 &&
        questionnaire[questionnaire.length - 2].response_ &&
        questionnaire[questionnaire.length - 2].response_.response
      ) {
        delete questionnaire[questionnaire.length - 2].response_.response;
        questionnaire[questionnaire.length - 2].response_.response = [
          info.duration
        ];
      }
    }
    setPrice(info.price);
    setTotal(info.price);
    setDueNow(info.price);
    setCheckedProd(info.title);

    if (e) {
      setBrandpill(info);
    } else {
      if (brandlist) {
        brandlist.map((brand) => {
          if (brand.title === checkedprod) {
            setBrandpill(brand);
          }
        });
      }
    }
  }

  const _stripResponse = (response, defaultReturn) => {
    return response.length > 0 && response[0] ? response[0] : defaultReturn;
  };

  /**
   * Return whether this is a quick script consultation with a referral param.
   * We have special behaviour for this case.
   */
  const isReferredQuickScript = (category, consultReferrer) => {
    // Note: quick script was previously called "renew a script"
    return consultReferrer && category.slug === "renew-a-script";
  };

  const shouldCreatePatientProspect = (
    category,
    consultReferrer,
    isNib = false
  ) => {
    // There are issues with prospect reentry for referred quick scripts,
    // so we simply don't create prospects in that case.
    return !isNib && !isReferredQuickScript(category, consultReferrer);
  };

  async function submit(e = null, param) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    Base.logger(Base.Config().STATE, null, null, { param });

    if (submitLock) {
      Toast.info("Please wait while we process your request.");
      return true;
    }

    setUnload(false);

    if (!submitLock) {
      setSubmitLock(true);
    }

    const loader = document.getElementById("cover-spin");
    Base.setSubmitLoading(
      loader,
      false,
      param?.idSuffix ? `submit${param.idSuffix}` : null,
      param?.idSuffix ? `loading${param.idSuffix}` : null
    );

    Base.redirectToStartByMalformed(
      isCheckRes,
      questionnaire,
      questionnairedata,
      productenv,
      user_email,
      setUnload,
      "submit questionnaire"
    );

    const errormsg = await Base.checkBeforeSubmit(param, {
      email: user_email,
      noloading: true
    });

    if (errormsg && errormsg.length > 2) {
      Toast.error(errormsg);
      Base.setSubmitLoading(
        loader,
        true,
        param?.idSuffix ? `submit${param.idSuffix}` : null,
        param?.idSuffix ? `loading${param.idSuffix}` : null
      );
      setSubmitLock(false);
      return false;
    }

    let body = {};
    let price_ = price;

    if (import.meta.env.VITE_GID) {
      let isvoucher = false;
      if (voucherpair.voucher && voucherpair.voucherfromurl) {
        isvoucher = voucherpair.voucher
          ? `/?voucher=${voucherpair.voucher}`
          : voucherpair.voucherfromurl
          ? `/?voucher=${voucherpair.voucherfromurl}`
          : false;
      }

      Base.logger(
        Base.Config().STATE,
        "questionnaire",
        "submit",
        `code: ${isvoucher}`
      );

      window.ga("mhtracker.send", "pageview", {
        page: `questionnaire-complete/${productenv.name}${
          isvoucher ? isvoucher : ""
        }`,
        title: `${productenv.name} complete`
      });
    }

    body = {
      loggedin: true,
      email: user_email,
      first_name: first_name,
      last_name: last_name,
      dob_day: dob_day,
      dob_month: dob_month,
      dob_year: dob_year,
      address_line1: addressLine1,
      address_line2: addressLine2,
      suburb: suburb,
      state: state,
      postcode: postcode,
      weight: weight,
      height: height,
      vouchers: activeVouchers.map(({ voucher }) => voucher.code),
      phone: ph
    };

    if (
      (brand_pill_id && brand_pill_id !== "SKIP THIS QUESTION") ||
      brand_pill?.id >= 0
    ) {
      body.shop_product = brand_pill_id ? brand_pill_id : brand_pill?.id;
    }

    if (!param.isLoggedIn) {
      const checkbox = document.getElementById("accept_purchase");

      if (checkbox && !checkbox.checked) {
        Toast.error("Please accept our terms and conditions to continue.");
        Base.setSubmitLoading(
          loader,
          true,
          param?.idSuffix ? `submit${param.idSuffix}` : null,
          param?.idSuffix ? `loading${param.idSuffix}` : null
        );
        setSubmitLock(false);
        return false;
      }

      const isvalid = Base.checkSubmitValidation({
        underAgeQuestion,
        first: first_name,
        last: last_name,
        mobile: ph,
        email: user_email,
        typeset: Array.isArray(typeSet)
          ? typeSet?.find((t) => t.signup && Array.isArray(t.signup))?.signup
          : ""
      });

      if (!isvalid) {
        Base.setSubmitLoading(
          loader,
          true,
          param?.idSuffix ? `submit${param.idSuffix}` : null,
          param?.idSuffix ? `loading${param.idSuffix}` : null
        );
        setSubmitLock(false);
        return false;
      }

      const uid = Base.getUid(document.cookie.split(";"));
      body.loggedin = false;
      body.uid = uid;
      body.username = user_email;
    }

    if (membership) {
      body.nib_membership_number = membership;
    }

    body.product = productenv.name;
    body.brand = Base.capitalizeFirstLetter(Base.Config().FROM).trim();

    body.patientprospectid = patientprospectid;
    body.has_current_script =
      questionnaire.findIndex((qus) => qus.ga_id && qus.ga_id === "B0003") > -1;

    const response_ = [];
    for (let i = 0; i < questionnaire.length; i++) {
      Base.setResponseForm(
        questionnaire[i],
        response_,
        {
          brand_pill,
          submit: true,
          slug: productenv.slug
        },
        "questionnaire"
      );
    }

    if (productenv.name === "Fill-a-script") {
      const fill_a_script_body = {
        can_handle_repeats: true,
        preferred_brand: "Generic"
      };

      if (scriptToken.length > 0)
        fill_a_script_body.fill_a_script_token = scriptToken;

      response_.forEach((question) => {
        if (question.title === "Please enter your ePrescription token") {
          fill_a_script_body.fill_a_script_token = question.response;
        } else if (
          question.title === "What is your preferred brand for the medication?"
        ) {
          fill_a_script_body.preferred_brand = _stripResponse(
            question.response,
            "Generic"
          );
        }
      });

      if (!("fill_a_script_token" in fill_a_script_body)) {
        Toast.error(
          "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 1745]."
        );
        Base.setSubmitLoading(
          loader,
          true,
          param?.idSuffix ? `submit${param.idSuffix}` : null,
          param?.idSuffix ? `loading${param.idSuffix}` : null
        );
        setSubmitLock(false);
        return false;
      }
      body = { ...body, ...fill_a_script_body };
    }

    body.formdata = response_;
    body.iszippay = false;

    if (childPromoRef.current && childPromoRef.current.vaildCode()) {
      const promocode = childPromoRef.current.vaildCode();
      body.promocode = promocode.code;
      body.promocodetype = promocode.type;
      body.promodiscount =
        promocode.type && promocode.type === "event"
          ? 0
          : promocode.discountprice;
    }

    if (smokingStatus) body.smoking_status = smokingStatus;

    if (script_only) body.script_only = true;

    if (roseRXID) body.rose_rx_id = roseRXID;
    if (arhi) body.arhi = true;
    if (consultReferrer) body.consult_referrer_code = consultReferrer;

    if (param.type === "bypass") body.has_script_only = true;

    if (gender) body.gender = gender;

    /*
    If question informs that pt will input blood pressure, 
    set initial_contraceptive_pill in body so later will show input blood pressure button in treatment page
    */
    if (
      response_.reduce(
        (requiresBP, response) =>
          requiresBP || (response.requireBloodpressure ?? false),
        false
      )
    ) {
      body.initial_contraceptive_pill = true;
    }

    const providedCookies = getCookies();
    if (providedCookies) {
      body.tracking = providedCookies;
    }

    if (
      (!showpayment ||
        showpayment === "yes" ||
        (productenv.once && productenv.once === "yes")) &&
      ((productenv.once && productenv.once === "yes") ||
        (productenv.subscription && productenv.subscription === "yes"))
    ) {
      if (childRef.current && childRef.current.getPaymentType()) {
        const stripetk = await childRef.current.getToken();
        if (
          !stripetk ||
          !stripetk.tokeninfo ||
          !stripetk.tokeninfo.token ||
          stripetk.tokeninfo.token.error
        ) {
          Toast.error(
            "Sorry, we were unable to process your payment. Please review the card details provided and ensure you have sufficient funds, then try again."
          );
          Base.setSubmitLoading(
            loader,
            true,
            param?.idSuffix ? `submit${param.idSuffix}` : null,
            param?.idSuffix ? `loading${param.idSuffix}` : null
          );
          setSubmitLock(false);
          return false;
        }
        body.tokenid =
          stripetk && stripetk.tokeninfo && stripetk.tokeninfo.token.id
            ? stripetk.tokeninfo.token.id
            : "";
        body.cardfname =
          stripetk && stripetk.firstname ? stripetk.firstname : "";
        body.cardlname = stripetk && stripetk.lastname ? stripetk.lastname : "";
      }
    }
    if (!price_ || parseFloat(price_) <= 0) {
      price_ = productenv.price ? productenv.price : 0;
    }
    if (deliverycost && deliverycost.price) {
      body.delivery_option = deliverycost.delivery_option;
      if (deliverycost.price !== "0.0" && !Number(deliverycost.price)) {
        Toast.error(
          "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 0571]."
        );
        Base.setSubmitLoading(
          loader,
          true,
          param?.idSuffix ? `submit${param.idSuffix}` : null,
          param?.idSuffix ? `loading${param.idSuffix}` : null
        );
        setSubmitLock(false);
        Sentry.captureMessage(`Delivery Cost is not set!: ${user_email}`);
        return false;
      }
      const price__ = parseFloat(price_) + parseFloat(deliverycost.price);
      body.price = parseFloat(price__).toFixed(2);
    } else {
      body.price = price_;
    }

    if (
      !nomedicare &&
      (originmedicare !== medicare ||
        originmedicareref !== medicareref ||
        originmedicareexp !== medicareexp)
    ) {
      body.medicare = medicare;
      body.medicareref = medicareref;
      body.medicareexp = medicareexp;
    } else if (medicareSelectedStatus === "later") {
      body.medicare_notify_later = true;
    } else if (nomedicare) {
      body.nomedicare = true;
    }
    // if user has selected membership, add create_membership to the body
    if (paymentType === "membership-fee-monthly") {
      body.create_membership = true;
    }

    if (healthCheckResult?.id) {
      body.health_check_result_id = healthCheckResult.id;
    }

    if (productenv?.slug === "digital-health-check") {
      if (param?.skipConsult) {
        body.skip_consult = true;
      } else {
        body.skip_consult = false;
      }
    }

    Base.logger(Base.Config().STATE, null, null, { body });

    const objname = "submitquestionnaire";
    const submit_ = await Base.Fetch(
      `${Base.Config().HOST}/${Base.Config().API}/${objname}/`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify(body)
      },
      "questionnaire",
      "submit",
      false,
      false,
      email_,
      false,
      true,
      true,
      true
    );

    const submit = Base.getResult(submit_, objname);

    if (
      submit &&
      ((submit.iserror &&
        submit.msg &&
        (Array.isArray(submit.msg) || typeof submit.msg === "string") &&
        submit.msg?.includes("paymentfailed")) ||
        (submit[objname] && submit[objname].paymentfailed))
    ) {
      setShowpayment("yes");
      setRefresh(!refresh);
      const errorMessage =
        "Sorry, we were unable to process your payment using the details we have on file. Please enter your card details below and try again.";
      Toast.error(errorMessage);
      Sentry.captureMessage(`${errorMessage}: ${user_email}`);
      Base.setSubmitLoading(
        loader,
        true,
        param?.idSuffix ? `submit${param.idSuffix}` : null,
        param?.idSuffix ? `loading${param.idSuffix}` : null
      );
      setSubmitLock(false);
      return false;
    }

    if (!submit || submit.iserror) {
      setRefresh(!refresh);
      let message = "";
      try {
        message =
          submit &&
          submit.msg &&
          (submit.msg.includes("message") || submit.msg.includes("error"))
            ? JSON.parse(submit.msg)
            : "";
      } catch (e) {
        message = "";
      }

      const toastMsg = message
        ? message[objname] && message[objname].message
          ? message[objname].message
          : message.message
          ? message.message
          : message.error
          ? message.error
          : "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 3490]."
        : "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 3491].";
      Toast.error(toastMsg);
      Sentry.captureMessage(`${toastMsg}: ${user_email}`);
      Base.setSubmitLoading(
        loader,
        true,
        param?.idSuffix ? `submit${param.idSuffix}` : null,
        param?.idSuffix ? `loading${param.idSuffix}` : null
      );
      setSubmitLock(false);
      return false;
    }

    if (
      !submit[objname].error ||
      submit[objname].error === "" ||
      submit[objname].error.trim() === ""
    ) {
      await goFurther(param.isLoggedIn, body.username, param, loader, submit, {
        promocode: body.promocode ? body.promocode : false,
        promocodetype: body.promocodetype ? body.promocodetype : false,
        promodiscount: body.promodiscount ? body.promodiscount : false,
        price: body.price
      });
      setUserInfo(submit.submitquestionnaire.user_info);
    } else {
      setRefresh(!refresh);
      Toast.error(submit[objname].error);
      Sentry.captureMessage(`submitquestionnaire error: ${user_email}`);
      Base.setSubmitLoading(
        loader,
        true,
        param?.idSuffix ? `submit${param.idSuffix}` : null,
        param?.idSuffix ? `loading${param.idSuffix}` : null
      );
      return false;
    }
  }

  /**
   * NOTE: This function returns a function which takes an event as a param
   * @param {*} param
   * @returns function
   */
  const next = (param) => async (e) => {
    setPreventTopScroll(true);
    if (
      (e.type === "keydown" && e.key !== "Enter") ||
      (e.type === "change" && param && param.nonext)
    ) {
      return;
    }
    e?.preventDefault();
    e?.stopPropagation();
    if (
      param.currentquestion_?.subtype === "selectbox" ||
      param.currentquestion_?.sub_type === "productSearch"
    ) {
      if (!brand_pill?.title) return;
      if (param.currentquestion_?.preference && brand_pill?.title === "Other") {
        Toast.error("Please select an option from the list provided.");
        return false;
      }
      if (
        param.currentquestion_?.sub_type === "productSearch" &&
        !brand_pill?.availability?.is_available_to_buy
      ) {
        Toast.error(
          "Unfortunately, the item(s) that you selected are out-of-stock. Please select an alternative, or contact our Patient Success team for further information."
        );
        return false;
      }
      param.obj_ = param.currentquestion_.options.find(
        (o) => o.title === brand_pill?.title
      );
    }

    if (
      param?.currentquestion_?.type === "input" &&
      document.getElementById("thisinput")?.value?.length > 100
    ) {
      Toast.error(
        `The maximum response length is 100 characters. You entered ${
          document.getElementById("thisinput").value.length
        } characters. Please update your response to continue.`
      );
      return false;
    }

    if (param && param.currentquestion_ && param.currentquestion_.require) {
      if (
        (param.currentquestion_.type === "input" ||
          param.currentquestion_.type === "textarea") &&
        document.getElementById("thisinput") &&
        !document.getElementById("thisinput").value
      ) {
        Toast.error("Please provide a response to continue.");
        return false;
      }
    }

    if (
      !acceptbypass_ &&
      !accept_ &&
      param &&
      param.currentquestion_ &&
      param.currentquestion_.ckboxs
    ) {
      Toast.error("You must check the box provided to continue.");
      return;
    }

    if (User.loggedIn() && !firsttime) {
      const result_ = await Base.checkandsetupBasicInfo(
        setIsprospect,
        isprospect,
        user_email,
        setUserEmail,
        setFirstName,
        setLastName,
        dob_daySet,
        dob_monthSet,
        dob_yearSet,
        setAddressLine1,
        setAddressLine2,
        setSuburb,
        setState,
        setPostcode,
        setPh,
        smokingStatusSet,
        medicareSet,
        medicarerefSet,
        medicareexpSet,
        ihiSet,
        originmedicareSet,
        originmedicarerefSet,
        originmedicareexpSet,
        genderSet,
        setIsGenderProvided,
        productenv
      );
      if (result_) {
        setFirst(true);
      } else {
        return false;
      }
    }

    setUnload(true);

    Base.gtmpro({
      questionnaire,
      productenv,
      _scriptname,
      _hasscript,
      dob_day,
      dob_month,
      dob_year,
      addressLine1,
      addressLine2,
      suburb,
      state,
      postcode,
      questionnaireid,
      subindex,
      showpayment,
      first_name,
      last_name,
      ph,
      user_email,
      prequs,
      prequsSet,
      setGaonce,
      gaonce,
      patientprospectid,
      isnewuser_,
      patientid
    });

    const param_ = param;

    const nextid_ = nextid;
    const subindex_ = subindex;
    let questionnaire_ = questionnaire;
    let value = null;
    let bypass = false;
    const issubindex = false;

    let addressLine1_ = "";
    let addressLine2_ = "";
    let suburb_ = "";
    let state_ = "";
    let postcode_ = "";

    let script_file = "";
    let script_name = "";

    let medicare = "";
    let medicareref = "";
    let medicareexp = "";

    let email_ = "";

    let highBloodVal = null;
    let lowBloodVal = null;

    let token = null;

    let dob_day_local = null;
    let dob_month_local = null;
    let dob_year_local = null;

    let brandPill = null;

    if (import.meta.env.VITE_GID) {
      let gaid = "";
      if ((param_.obj_ && param_.obj_.ga_id) || param_.currentquestion_.ga_id) {
        gaid =
          param_ && param_.obj_ && param_.obj_.ga_id
            ? param_.obj_.ga_id
            : param_.currentquestion_.ga_id === "C0001" &&
              param_ &&
              param_.obj_ &&
              param_.obj_.id &&
              param_.currentquestion_.ga_id
            ? `${param_.currentquestion_.ga_id}_${param_.obj_.id}`
            : param_.currentquestion_.ga_id;
      } else {
        gaid = param.obj_ ? `${param_.obj_.id}` : param.currentquestion_.id;
      }
      if (gaid) {
        window.ga("mhtracker.send", "pageview", {
          page: `${productenv.name}/${gaid}`,
          title: `${productenv.name} questionnaire index ${gaid}`
        });
      }
    }

    if (
      param_.currentquestion_ &&
      param_.currentquestion_.dobefore &&
      param_.currentquestion_.dobefore === "checkcard"
    ) {
      if (!User.loggedIn() || !(await Base.hasPaymentInfo())) {
        setShowpayment("yes");
      } else {
        setShowpayment("no");
      }
      // if contraceptive pill, it shows new payment page
      if (
        productenv.showNewPayment &&
        productenv?.subname !== "NibInternational" &&
        questionnaire.findIndex((qus) => qus.showNewPayment) > -1
      ) {
        setIsShowNewPaymentPage(true);
      }
    }

    if (
      (param_.obj_ &&
        !param_.obj_.bmi &&
        param_.obj_.end &&
        (!param_.obj_.products ||
          (productenv &&
            productenv.name &&
            param_.obj_.products &&
            param_.obj_.products.findIndex(
              (p) => p === productenv.name.toLowerCase()
            ) > -1))) ||
      (param_.currentquestion_ && param_.currentquestion_.type === "end") ||
      (param_.obj_ && param_.obj_.bmi && param_.obj_.bmi.min > bmiValue) ||
      (nomedicare && productenv.medicare?.end)
    ) {
      bypass = true;
      setUnload(false);
      isendset(true);

      Base.setGtmQus("qdisq", {
        isnewuser_,
        _hasscript,
        productenv,
        _scriptname,
        patientprospectid,
        patientId: patientid,
        currentquestion_: param_.currentquestion_,
        reason: param_.obj_ ? param_.obj_.title : ""
      });
      Base.setEndPage(param_, productenv, setData, { nomedicare, reason_ });
    } else if (param_.obj_) {
      if (
        param_.obj_ &&
        (param_.obj_.out_of_stock ||
          (param_.obj_.title &&
            param_.obj_.title.toLowerCase().includes("out of stock")))
      ) {
        Toast.error(
          "Due to an Australia wide shortage, a number of birth control pills are currently out of stock across the country. However our doctors are more than happy with you to find an appropriate solution in the interim."
        );
        return false;
      }
      let title = param_.obj_.isdata
        ? Base.getRawDatafromText(param_.obj_.title)
        : param_.obj_.title;

      if (
        param_.currentquestion_ &&
        ((param_.currentquestion_.ga_id &&
          param_.currentquestion_.ga_id === "A0006") ||
          (!param_.currentquestion_.ga_id &&
            param_.currentquestion_.id === 105))
      ) {
        genderSet(title);
      }

      if (param_.obj_.price && param_.obj_.duration) {
        Base.setBrandData({
          setBrandpillTitle,
          setBrandpill,
          setPrice,
          setDuration,
          obj_: param_.obj_
        });
        brandPill = param_.obj_;
      }

      if (param_.obj_ && param_.obj_.type && param_.obj_.type === "Other") {
        if (hideOther) {
          setHideOther(false);
          delete param_.obj_.next;
          return false;
        }
      }

      const object = param_.obj_;

      if (object && object.condobjs) {
        const isReCreate = await Base.reCreateQuestionnaire(
          object,
          questionnaire,
          questionnaireid,
          nextid,
          subindex,
          setQuestionnaireid,
          setNextId,
          setSubindex,
          qSet,
          {
            nostock: true,
            resetBrandInfo
          }
        );
        if (isReCreate) {
          return false;
        }
      }

      if (
        objectIsProduct(object) &&
        (param_.currentquestion_.type === "button" ||
          param_.currentquestion_.type === "select")
      ) {
        // Change the value to a product item if it originated from a product selection
        setBrandpill(object);
        brandPill = object;
        value = [
          object.isdata ? Base.getRawDatafromText(object.title) : object.title
        ];
        setBtLoading(true);
        // Load and set the price for this product
        const { incl_tax } = await OscarAPI.getProductPrice(object.id);
        setPrice(incl_tax);
        setBtLoading(false);
      } else {
        value = [title];
      }

      if (object && object.consultfee) {
        setConsultFee(object.consultfee);
      }

      if (object && object.script_only) {
        setScriptOnly(object.script_only === "yes");
      }
    } else {
      const result = Base.validation(param_.currentquestion_, null, {
        productenv,
        nomedicare,
        skipvalidation: param_?.skipvalidation,
        typecheck: productenv?.typecheck ?? false,
        typeset: productenv?.typeset ?? false,
        setData,
        setHideback,
        underAgeQuestion,
        noIhi,
        patientprospectid,
        patientid
      });

      if (result === "bypass") return false;
      if (!result.vaild) {
        Toast.error(result.msg);
        return false;
      }

      let thisinput = document.getElementById("thisinput");

      if (
        param_.currentquestion_.type === "input" ||
        param_.currentquestion_.type === "textarea"
      ) {
        value = [thisinput?.value];
        if (param_.currentquestion_?.ga_id === "A0007")
          heightSet(parseFloat(thisinput.value));
        if (param_.currentquestion_?.ga_id === "A0008") {
          weightSet(parseFloat(thisinput.value));
          setBmiValue(bmi(parseFloat(thisinput.value), height));
        }
      } else if (param_.currentquestion_.type === "upload") {
        const thisscriptdoc =
          document.getElementById("current_script").files[0];
        const { name } = thisscriptdoc;
        const result = await Base.readFileAsync(thisscriptdoc, name);
        setScriptFile(result);
        setScriptName(name);
        script_file = result;
        script_name = name;
      } else if (param_.currentquestion_.type === "multichoice") {
        thisinput = document.getElementsByName("thisinput[]");
        const inputlist = [];

        for (let i = 0; i < thisinput.length; i++) {
          if (thisinput[i].checked) {
            let tmpvalue = thisinput[i].value;
            if (thisinput[i].alt) {
              const id_ =
                thisinput[i].alt === "other"
                  ? "multi-other-text"
                  : `${thisinput[i].alt}-text`;
              const txtvalue = jQuery(`#${id_}`).val();
              const option_ =
                param_.currentquestion_ &&
                param_.currentquestion_.options &&
                Array.isArray(param_.currentquestion_.options)
                  ? param_.currentquestion_.options.find(
                      (op) => tmpvalue && op.title === tmpvalue.trim()
                    )
                  : "";
              if (
                option_ &&
                option_.required &&
                option_.required === "yes" &&
                (!txtvalue || txtvalue.trim() === "")
              ) {
                Toast.error("Please provide a response to continue.");
                return false;
              }
              tmpvalue = `${thisinput[i].value}: ${txtvalue}`;
            }
            inputlist.push(tmpvalue);
          }
        }

        if (inputlist.length < 1) {
          Toast.error("Please select at least one option.");
          return false;
        }

        if (
          param_.currentquestion_.count &&
          param_.currentquestion_.count < inputlist.length
        ) {
          Toast.error(
            `Please select no more than ${
              param_.currentquestion_.count
            } option${param_.currentquestion_.count > 1 ? "s" : ""}.`
          );
          return false;
        }
        value = inputlist;
      } else if (param_.currentquestion_.type === "mobile") {
        if (ph?.length > 0) {
          value = [ph];
        } else {
          value = ["CONTACT VIA PORTAL"];
        }
      } else if (
        param_.currentquestion_.type === "email" ||
        param_.currentquestion_.typecheck ||
        param_.currentquestion_.type === "name" ||
        param_.currentquestion_.type === "emailAndName" ||
        param_.currentquestion_.type === "singleEmail"
      ) {
        if (param_.currentquestion_.type !== "name") {
          const isNotEmailType = !!(
            param_.currentquestion_.typecheck &&
            result?.names.findIndex(
              (name) =>
                name.name === "useremail" &&
                (!name.notrequired ||
                  (name.notrequired &&
                    !name.notrequired.includes(productenv.code)))
            ) <= -1
          );

          const email = result.values?.useremail
            ? result.values?.useremail?.trim()
            : false;
          const result1 = !isNotEmailType
            ? await Base.checkUserByEmail(email, "questionnaire")
            : "";

          if (!result1 && !isNotEmailType) {
            // This occurs when the call to the BE to check user existance fails
            Toast.error(
              "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 2403]."
            );
            Sentry.captureMessage("Error while processing your request: 2403");
            return false;
          }

          if (
            result1 &&
            result1.authenticateUser &&
            !isNotEmailType &&
            !productenv?.nibInternational
          ) {
            setUnload(false);
            if (result1.authenticateUser.redirect) {
              modaldataSet(
                <RedirectModal
                  url={result1.authenticateUser.redirect}
                  onClose={closeModal}
                />
              );
              modalSet(true);
              return { redirect_: "no" };
            }

            newPlainCode = await Base.postpatientdata(
              plainCode,
              "questionnaire",
              {
                product_: productenv.name,
                slug: productenv.slug,
                questionnaire_,
                questionnaireid,
                user_email: email,
                patientprospectid: patientprospectid ? patientprospectid : "",
                setpatientprospectid,
                setPlainCode,
                first_name: first_name ? first_name : "",
                isCodeReturn: true
              }
            );

            setUserEmail(email.toLowerCase());
            setShowLoginModal(true);
            return;
          }

          if (param_.currentquestion_.type === "singleEmail") {
            const email_validity = await EmailValidation.email(email);
            if (email_validity === "invalid") {
              Toast.error(
                "You have entered an invalid email address. Please try again."
              );
              return false;
            }
          }

          if (
            param_.currentquestion_.typecheck &&
            result.values &&
            result.values.first_name &&
            result.values.last_name
          ) {
            const [dobDay, dobMonth, dobYear] =
              result.values.date_picker.split("/");
            // Check name as the name question?
            const isvalid = Base.checkSubmitValidation({
              underAgeQuestion,
              first: result.values.first_name,
              last: result.values.last_name,
              email: email,
              dob: true,
              dob_day: dobDay,
              dob_month: dobMonth,
              dob_year: dobYear,
              nomobile: true,
              typeset: result.names
            });

            if (result.names) {
              setTypeSet(result.names);
            }

            if (isvalid) {
              const isCreated = true;
              if (isCreated) {
                setFirstName(result.values.first_name.trim());
                setLastName(result.values.last_name.trim());
                setAddressLine1(result.values.addressLine1);
                setAddressLine2(result.values.addressLine2);
                setSuburb(result.values.suburb);
                setState(result.values?.state ?? result.values?.address_state);
                setPostcode(result.values.postcode);
                dob_daySet(dobDay);
                dob_monthSet(dobMonth);
                dob_yearSet(dobYear);
              }
            } else {
              return false;
            }
            const dobData = result.values.date_picker
              ? `${dobDay}_${dobMonth}_${dobYear}`
              : `${result.values.dob_day}_${result.values.dob_month}_${result.values.dob_year}`;

            value = `email: ${isNotEmailType ? "" : email.toLowerCase()};`;
            value += `name:${result.values.first_name}_${result.values.last_name};`;
            value += `dob:${dobData};`;
            value += `address:${result.values.addressLine1}_${result.values.addressLine2}_${result.values.suburb}_${result.values.state}_${result.values.postcode}`;

            dob_day_local = dobDay;
            dob_month_local = dobMonth;
            dob_year_local = dobYear;
            if (
              result?.names.findIndex(
                (name) => name.name === "membership_number"
              ) > -1
            ) {
              value += `;membership_number: ${result.values.membership_number};`;
              value += `from: ${fromParam}`;
            }
          } else {
            value = email.toLowerCase();
          }

          if (!isNotEmailType) {
            setUserEmail(email.toLowerCase());
            email_ = email.toLowerCase();
          }
        }

        if (
          param_.currentquestion_.type === "name" ||
          param_.currentquestion_.type === "emailAndName"
        ) {
          const first = result.values.first_name?.trim() ?? "";
          const last = result.values.last_name?.trim() ?? "";

          setFirstName(first);
          setLastName(last);

          if (param_.currentquestion_.type === "emailAndName") {
            value = JSON.stringify({
              email: email_,
              first_name: first,
              last_name: last
            });
          } else {
            value = `${first}_${last}`;
          }
        }
      } else if (param_.currentquestion_.type === "bloodPressure") {
        for (const key in result.values) {
          const value = result.values[key] ? result.values[key] : "";
          if (key === "lowBlood") {
            lowBloodVal = value;
            setLowBlood(value);
          } else if (key === "highBlood") {
            highBloodVal = value;
            setHighBlood(value);
          }
        }

        value = `${lowBloodVal}_${highBloodVal}`;
      } else if (param_.currentquestion_.type === "image") {
        if (
          param_.currentquestion_.tmpimgs &&
          param_.currentquestion_.tmpimgs.imgs &&
          param_.currentquestion_.tmpimgs.imgs.length > 0
        ) {
          setImageFiles(
            !imagefiles || imagefiles.length === 0
              ? [].concat([param_.currentquestion_.tmpimgs])
              : imagefiles && imagefiles.length === 1
              ? [imagefiles].concat([param_.currentquestion_.tmpimgs])
              : imagefiles.concat([param_.currentquestion_.tmpimgs])
          );
        }
      } else if (param_.currentquestion_.type === "address") {
        if (!param.skipvalidation) {
          for (const key in result.values) {
            const value = result.values[key] ? result.values[key] : "";
            if (key === "addressLine1") {
              addressLine1_ = value;
            } else if (key === "addressLine2") {
              addressLine2_ = value;
            } else if (key === "postcode") {
              postcode_ = value;
            } else if (key === "suburb") {
              suburb_ = value;
            } else if (key === "address_state") {
              state_ = value;
            }
          }
          if (productenv?.addressCheck) {
            setBtLoading(true);
            const { is_serviceable: isServiceable } =
              await PatientAPI.isAddressServiceable({
                line1: addressLine1_,
                line2: addressLine2_,
                suburb: suburb_,
                state: state_,
                postcode: postcode_
              });
            setBtLoading(false);
            if (!isServiceable) {
              setData(
                <div className="inner-questionnaire-page" id="end-page">
                  <h2 className="pb-3">
                    Sorry, we are unable to service your location
                  </h2>
                  <h3>
                    Unfortunately, our delivery partner is unable to provide
                    express shipping to your address. To ensure patients can
                    start treatment as soon as possible, we only service
                    locations where we can deliver treatment within 24 hours. To
                    enable you to start your treatment as soon as possible, we
                    recommend seeing a doctor in person.
                  </h3>
                </div>
              );
              return false;
            }
          }
          setAddressLine1(addressLine1_);
          setAddressLine2(addressLine2_);
          setSuburb(suburb_);
          setState(state_);
          setPostcode(postcode_);
        }
        value = `${!param.skipvalidation ? addressLine1_ : addressLine1}_${
          !param.skipvalidation ? addressLine2_ : addressLine2
        }_${!param.skipvalidation ? suburb_ : suburb}_${
          !param.skipvalidation ? state_ : state
        }_${!param.skipvalidation ? postcode_ : postcode}`;
      } else if (
        param_.currentquestion_.type === "dob" ||
        param_.currentquestion_.type === "date"
      ) {
        for (const key in result.values) {
          const value = result.values[key] ? result.values[key] : "";
          if (key === "dob_day") {
            dob_day_local = value;
            param_.currentquestion_.type === "dob" ? dob_daySet(value) : "";
          } else if (key === "dob_month") {
            dob_month_local = value;
            param_.currentquestion_.type === "dob" ? dob_monthSet(value) : "";
          } else if (key === "dob_year") {
            dob_year_local = value;
            param_.currentquestion_.type === "dob" ? dob_yearSet(value) : "";
          } else if (key === "date_picker") {
            if (param_.currentquestion_.type === "dob") {
              const [day, month, year] = value.split("/");
              dob_daySet(day);
              dob_monthSet(month);
              dob_yearSet(year);
              dob_day_local = day;
              dob_month_local = month;
              dob_year_local = year;
            }
          }
        }
        value = `${dob_day_local}_${dob_month_local}_${dob_year_local}`;
      } else if (param_.currentquestion_.type === "token") {
        value = result.values.token;
        token = value;
        setScriptToken(value);
      } else if (param_.currentquestion_.type === "medicare") {
        const hasNotProvidedMedicareDetails =
          medicareSelectedStatus === "later" || nomedicare;
        if (
          isReferredQuickScript(category, consultReferrer) &&
          hasNotProvidedMedicareDetails
        ) {
          // referred quick scripts are always eScript only, so the patient has to provide their medicare details
          Toast.error(
            "Unfortunately we cannot provide you with an eScript without your Medicare card details. " +
              "If you have a Medicare card, you can add these details now."
          );
          return false;
        }

        if (medicareSelectedStatus === "later") {
          value = "later";
        } else if (nomedicare) {
          value = "nomedicare";
        } else {
          let month = null;
          let year = null;
          for (const key in result.values) {
            const value = result.values[key] ? result.values[key] : "";
            if (key === "medicare") {
              const isNotValid = Validation.medicare().validate(value);
              if (isNotValid) {
                Toast.error(isNotValid);
                return false;
              }
              medicare = value;
            } else if (key === "medicareref") {
              const isNotValid = Validation.int()
                .custom((v) =>
                  parseInt(v) > 0 && parseInt(v) < 10
                    ? ""
                    : "IRN must be a number between 1 and 9, inclusive."
                )
                .validate(value);

              if (isNotValid) {
                Toast.error(isNotValid);
                return false;
              }
              medicareref = value;
            } else if (key === "medicare-expiry-month") {
              month = value;
            } else if (key === "medicare-expiry-year") {
              year = value;
            }
          }

          if (!month || !year) {
            Toast.error("Please enter your Medicare card expiry date.");
            return false;
          }
          const inputdate = `${year}-${month}-01`;
          if (moment(inputdate) < moment().startOf("month").add(1, "month")) {
            Toast.error("Medicare card expiry can't be in the past.");
            return false;
          }
          medicareexp = inputdate;

          medicareSet(medicare);
          medicarerefSet(medicareref);
          medicareexpSet(medicareexp);
          medicareexptempSet(medicareexp);
          value = `${medicare}_${medicareref}_${medicareexp}`;
        }
      } else if (param_.currentquestion_.type === "ihiNumber") {
        if (noIhi) {
          value = "noIhi";
        } else {
          for (const key in result.values) {
            const ihiValue = result.values[key] ? result.values[key] : "";
            if (key === "ihiNumber") {
              const isNotValid = Validation.ihi().validate(ihiValue);
              if (isNotValid) {
                Toast.error(isNotValid);
                return false;
              } else {
                ihiSet(ihiValue);
                value = ihiValue;
              }
            }
          }
        }
      } else if (param_.currentquestion_.type === "accordion-select") {
        const accordionOptions = document.getElementsByName(
          "group-delivery-options"
        );
        const inputList = [];
        let title = "";
        for (let i = 0; i < accordionOptions.length; i++) {
          if (accordionOptions[i].checked) {
            const tmpvalue = accordionOptions[i]?.value;
            if (
              param_.currentquestion_ &&
              param_.currentquestion_.sub_type &&
              param_.currentquestion_.sub_type === "chemist2u"
            ) {
              title = jQuery(`#hiddentitle_${tmpvalue}`)?.text();
              const deliverycost_ = deliveryoption.find(
                (d) => d.delivery_option?.toString() === tmpvalue?.toString()
              );
              const delivery_option = deliverycost_?.delivery_option;
              if (delivery_option !== undefined && delivery_option !== null) {
                deliverycostSet(deliverycost_);
                setShipCost(deliverycost_?.price);
              } else {
                Toast.error(
                  "Sorry, we ran into an error while processing your request. Please try again and contact our Patient Success team for assistance if the problem persists [Error 4980]."
                );
                Sentry.captureMessage(
                  "Error while processing your request: 4980",
                  {
                    extra: {
                      delivery: deliverycost_
                    }
                  }
                );
                return false;
              }
            }
            inputList.push(title);
            break;
          }
        }
        value = inputList;
      } else if (param_.currentquestion_.type === "medication") {
        value = [brand_pill_id];
      }
    }

    const fieldValue = setFieldAndValue({
      currentQuestion: param_.currentquestion_,
      value
    });

    if (fieldValue) {
      setReason({ ...reason_, ...fieldValue });
    }

    setPreventTopScroll(false);

    if (resetProductEnv) {
      setResetProductEnv(false);
      setProductenv(originProductenv);
    }

    if (!bypass) {
      await Base.setQuestionnaire(
        param_.obj_ ? param_.obj_ : param_,
        qSet,
        setQuestionnaireid,
        setSubindex,
        setSubindex2,
        setNextId,
        nextid_,
        value,
        questionnairedata,
        questionnaire_,
        questionnaireid,
        "next",
        {
          parent: param_.obj_ ? param_.currentquestion_ : "",
          addressLine1:
            param.currentquestion_.type === "address" && !param.skipvalidation
              ? addressLine1_
              : addressLine1,
          addressLine2:
            param.currentquestion_.type === "address" && !param.skipvalidation
              ? addressLine2_
              : addressLine2,
          suburb:
            param.currentquestion_.type === "address" && !param.skipvalidation
              ? suburb_
              : suburb,
          state:
            param.currentquestion_.type === "address" && !param.skipvalidation
              ? state_
              : state,
          postcode:
            param.currentquestion_.type === "address" && !param.skipvalidation
              ? postcode_
              : postcode,
          showpayment,
          setShowpayment,
          subindex_,
          issubindex,
          deliveryoptionSet,
          qdataSet,
          scriptqus,
          Userquestions,
          NewUserquestions,
          AddUserquestions,
          AddUserquestionsnew,
          AddUserquestionsnewv2,
          AfterUserquestions,
          productqus,
          _hasscript,
          scriptqustype,
          accept_,
          acceptSet,
          checkbtsSet,
          checkbts,
          patientprospectid,
          script_file,
          script_name,
          user_email,
          smokingStatus,
          smokingStatusSet,
          productenv,
          isendset,
          brand_pill: brandPill || brand_pill,
          price,
          addfields: extrafields,
          imagefiles,
          ph,
          isSameDay,
          patientid,
          _scriptname,
          setBrandpillTitle,
          setBrandpill,
          setPrice,
          setDuration,
          setBrandList,
          setBrandListTitle,
          setBrandListSubtitle,
          setProduct,
          addComIndex,
          reason_,
          categoryList,
          ChemistShipCost,
          gender:
            param_?.currentquestion_.ga_id === "A0006" ? value[0] : gender,
          script_only,
          consultfee,
          setShowAfter,
          iscoldchain,
          setIsColdChain,
          setBtLoading,
          injectablesUnavailable,
          setOriginBrandpill,
          brand_origin_pill,
          bmiValue,
          highBlood: highBloodVal,
          lowBlood: lowBloodVal,
          isGenderProvided,
          scriptToken,
          setScriptToken,
          setEScriptStatus,
          setMedicationList,
          setEScriptName,
          setEScriptRemaining,
          token,
          setUnload,
          dob_year: dob_year_local ?? dob_year,
          dob_month: dob_month_local ?? dob_month,
          dob_day: dob_day_local ?? dob_day,
          underAgeQuestion,
          showIhiQustion,
          fromParam,
          first_name,
          last_name,
          skipUserQuestionForNib,
          setHideback,
          isNibPatient,
          setHealthCheckResult
        }
      );
    }

    if (!hideOther) {
      // other text can now be accessed here before being dismissed
      param_.currentquestion_.response_.other = other;
      questionnaire_ = Base.setCurrentQuestionResponse(
        questionnaire_,
        questionnaireid + nextid_ - (subindex_ > 0 ? subindex_ - 1 : 0),
        [
          `${
            param_.currentquestion_.replaceother
              ? param_.currentquestion_.replaceother
              : "Other"
          }: ${other}`
        ],
        qSet,
        param_.currentquestion_
      );
      qSet(questionnaire_);
      setHideOther(true);
      setOther(null);
    }

    if (
      shouldCreatePatientProspect(
        category,
        consultReferrer,
        fromParam === nibInternationalSearchParam
      ) &&
      (user_email || email_) &&
      !productenv.disableProspect
    ) {
      await Base.postpatientdata(plainCode, "questionnaire", {
        product_: productenv.name,
        slug: productenv.slug,
        questionnaire_,
        questionnaireid,
        user_email: email_ ? email_ : user_email,
        patientprospectid: patientprospectid ? patientprospectid : "",
        setpatientprospectid,
        setPlainCode,
        first_name: first_name ? first_name : ""
      });
    }

    if (param_.currentquestion_ && param_.currentquestion_.submit) {
      submit(null, {
        type: param_.currentquestion_.type,
        isLoggedIn: User.loggedIn(),
        currentquestion_: param_.currentquestion_
      });
    }

    if (
      questionnaire_ &&
      Array.isArray(questionnaire_) &&
      questionnaire_.length > 0 &&
      questionnaire_[questionnaire_.length - 1].type === "signup"
    ) {
      Base.redirectToStartByMalformed(
        isCheckRes,
        questionnaire,
        questionnairedata,
        productenv,
        user_email,
        setUnload,
        "questionnaire"
      );
    }
    Toast.dismiss();
  };

  const back = async (e = null) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (User.loggedIn()) {
      await Base.checkandsetupBasicInfo(
        setIsprospect,
        isprospect,
        user_email,
        setUserEmail,
        setFirstName,
        setLastName,
        dob_daySet,
        dob_monthSet,
        dob_yearSet,
        setAddressLine1,
        setAddressLine2,
        setSuburb,
        setState,
        setPostcode,
        setPh,
        smokingStatusSet,
        medicareSet,
        medicarerefSet,
        medicareexpSet,
        ihiSet,
        originmedicareSet,
        originmedicarerefSet,
        originmedicareexpSet,
        genderSet,
        setIsGenderProvided,
        productenv
      );
    }

    if (
      questionnaire[questionnaire.length - 1]?.resetBrand &&
      brand_origin_pill
    )
      setBrandpill(brand_origin_pill);

    let nextid_ = nextid;
    let questionnaireid_ = questionnaireid;
    const subindex_ = subindex;
    const subindex2_ = subindex2;

    if (questionnaire[questionnaire.length - 1].type === "upload") {
      setScriptFile("");
      setScriptName("");
    }

    if (subindex_ > 0 && questionnaire[questionnaire.length - 1].parent_) {
      console.log("back skip");
    } else if (nextid > 0 && questionnaire[questionnaire.length - 1].nextid) {
      nextid_ = nextid - 1;
    } else {
      questionnaireid_ -= 1;
    }

    if (questionnaire[questionnaire.length - 1].jumpnext) {
      nextid_ -= questionnaire[questionnaire.length - 1].jump_;
    } else if (questionnaire[questionnaire.length - 1].jump_) {
      questionnaireid_ -= questionnaire[questionnaire.length - 1].jump_;
    } else if (
      questionnaire[questionnaire.length - 1].hidepatient ||
      questionnaire[questionnaire.length - 1].reserve
    ) {
      questionnaire[questionnaire.length - 1].jump_ = 1;
      questionnaireid_ -= 1;
    }

    let q_ = [];

    if (data_) {
      setData("");
    } else {
      Base.logger(
        Base.Config().STATE,
        null,
        null,
        `questionnaireid_: ${questionnaireid_}, nextid_: ${nextid_}`
      );
      if (questionnairedata.length === 2 && questionnaireid_ === 0) {
        q_ = [questionnaire[0]];
        setQuestionnaireid(questionnaireid_);
        setNextId(0);
        setSubindex(0);
      } else if (
        subindex2_ > 0 &&
        questionnaire[questionnaire.length - 1].parent_
      ) {
        let count = 0;
        const pt = questionnaire[questionnaire.length - 1].parent_;
        for (let i = 0; i < questionnaire.length; i++) {
          if (
            questionnaire[i].parent_ &&
            questionnaire[i].parent_.id === pt.id
          ) {
            count++;
          } else {
            q_.push(questionnaire[i]);
          }
        }
        nextid_ = nextid - count;
        setNextId(nextid_);
        setSubindex2(0);
      } else if (
        subindex_ > 0 &&
        questionnaire[questionnaire.length - 1].parent_
      ) {
        let count = 0;
        const pt = questionnaire[questionnaire.length - 1].parent_;
        for (let i = 0; i < questionnaire.length; i++) {
          if (
            questionnaire[i].parent_ &&
            questionnaire[i].parent_.id === pt.id
          ) {
            count++;
          } else {
            q_.push(questionnaire[i]);
          }
        }
        nextid_ = nextid - count;
        setNextId(nextid_);
        setSubindex(0);
      } else {
        let jump_ = 0;
        if (questionnaire[questionnaire.length - 1].jump_) {
          jump_ = questionnaire[questionnaire.length - 1].jump_;
        }
        q_ = questionnaire.slice(0, questionnaire.length - 1 - jump_);
        setQuestionnaireid(questionnaireid_);
        setNextId(nextid_);
        setSubindex(subindex_);
      }

      qSet(q_);
      if (q_[q_.length - 1].response_ && q_[q_.length - 1].response_.accept_) {
        Base.rehandleCheck(
          q_[q_.length - 1].response_.accept_,
          checkbtsSet,
          acceptSet
        );
      }
    }
    isendset(false);
    setHideOther(true);
    setOther(null);
    if (q_ && q_.length >= 1 && q_[q_.length - 1]?.setUnload) setUnload(false);
    Toast.dismiss();
  };

  async function goFurther(
    isLoggedIn,
    _email,
    opt = null,
    loader = null,
    submit_ = null,
    opts = null,
    qid = null,
    from = null
  ) {
    if (import.meta.env.VITE_GID) {
      const promo = opts && opts.promocode ? opts.promocode : false;
      const promocodetype_ =
        opts && opts.promocodetype ? opts.promocodetype : false;
      const promodiscount_ =
        opts && opts.promodiscount ? parseFloat(opts.promodiscount) : 0;
      let isConsult = false;
      let consultNewFee = 0;

      if (productenv.once) {
        let price_ = opts.price ? opts.price : price;
        let deliveryoption = false;
        if (!price_ || parseFloat(price_) <= 0) {
          price_ = productenv.price ? productenv.price : 0;
        }
        if (
          promocodetype_ &&
          promocodetype_ !== "event" &&
          promodiscount_ > 0
        ) {
          price_ = parseFloat(price_) - promodiscount_;
        }
        if (deliverycost && deliverycost.price) {
          price_ = parseFloat(price_) + parseFloat(deliverycost.price);
          deliveryoption = "Paid Delivery";
        }

        if (price_ > 0) {
          Base.setPurchaseGtm({
            userType: isnewuser_
              ? "New User"
              : "Returning User - No Subscription",
            questionnaireId:
              submit_?.submitquestionnaire && submit_?.submitquestionnaire?.pqid
                ? submit_.submitquestionnaire.pqid
                : false,
            purchaseType: "On Demand",
            productprice: price_,
            patientid: patientid ? patientid : "",
            firstname: first_name ? first_name : "",
            lastname: last_name ? last_name : "",
            email: User.email(),
            dob: dob_year ? `${dob_year}${dob_month}${dob_day}` : "",
            suburb: suburb ? suburb : "",
            mobile: ph ? ph : "",
            product: productenv.name,
            productcode: productenv.name.toLowerCase(),
            dimension6: brand_pill ? brand_pill : productenv.name,
            total: price_,
            quantity: 1,
            deliveryoption,
            promo: promo
          });
        }
      } else if (!promo || promocodetype_ !== "event") {
        isConsult = true;
        consultNewFee = consultfee;
        if (promocodetype_) {
          consultNewFee -= promodiscount_;
        }
        if (consultNewFee > 0) {
          Base.setPurchaseGtm({
            userType: isnewuser_ ? "New User" : "Returning User - New Product",
            questionnaireId:
              submit_?.submitquestionnaire && submit_?.submitquestionnaire?.pqid
                ? submit_.submitquestionnaire.pqid
                : false,
            purchaseType: "On Demand",
            productprice: consultNewFee,
            patientid: patientid ? patientid : "",
            firstname: first_name ? first_name : "",
            lastname: last_name ? last_name : "",
            email: User.email(),
            dob: dob_year ? `${dob_year}${dob_month}${dob_day}` : "",
            suburb: suburb ? suburb : "",
            mobile: ph ? ph : "",
            product: productenv.name,
            productcode: productenv.name.toLowerCase(),
            dimension6: "Consult",
            dimension7: "Consult",
            total: consultNewFee,
            quantity: 1,
            deliveryoption,
            promo: promo,
            script_only
          });
        }
      }

      Base.setGtmQus("qcomp", {
        isLoggedIn: isLoggedIn,
        _hasscript,
        _scriptname,
        patientprospectid: patientprospectid ? patientprospectid : false,
        email: User.email(),
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        db: dob_year ? `${dob_year}${dob_month}${dob_day}` : "",
        suburb: suburb ? suburb : "",
        productenv: productenv,
        isnewuser_,
        pqid:
          submit_.submitquestionnaire && submit_.submitquestionnaire.pqid
            ? submit_.submitquestionnaire.pqid
            : false,
        showpayment,
        patientId: patientid ? patientid : "",
        isConsult,
        consultNewFee,
        script_only
      });
    }
    setHideback(true);

    loader.className = loader.className.replace(/show/g, "");
    if (productenv.code === "digital_health_check") {
      navigate("/home");
    } else {
      setShowCompletionModal(true);
    }
  }

  const onGoToPortalClick = () => {
    if (!User.loggedIn()) {
      User.login(userInfo);
    }
    navigate("/");
  };

  function closeModal() {
    modalSet(!modal_);
    modaldataSet("");
  }

  function setAlready(setData_, type = null, opts) {
    const text = (
      <h3>
        You have already purchased this product. Please{" "}
        <a
          href={`/${
            voucherpair.voucher ? `?voucher=${voucherpair.voucher}` : ""
          }`}
        >
          login
        </a>{" "}
        and order a refill.
      </h3>
    );
    if (type) {
      <h3>
        This treatment has already been purchased, please{" "}
        <a
          href={`/${
            voucherpair.voucher ? `?voucher=${voucherpair.voucher}` : ""
          }`}
        >
          login
        </a>{" "}
        and order a refill.
      </h3>;
    }
    let already_ = (
      <div>
        {text}
        <br></br>
      </div>
    );
    if (commonvalues.questionnaire && commonvalues.questionnaire.already) {
      already_ = [];
      commonvalues.questionnaire.already.map((content_, index) => {
        let content__ = content_;
        if (content_.link) {
          content__ = [];
          const tmp = content_.text.split("[link]").map((c, index) => {
            return index === 0 ? (
              <span key={`d1${index}`}>
                <a
                  className="button text-decoration-none white-font-color"
                  href={content_.link}
                >
                  {content_.bttxt}
                </a>
              </span>
            ) : (
              <span key={`d1${index}`}>{c}</span>
            );
          });
          already_.push(<br key={index}></br>);
          already_.push(tmp);
        } else {
          already_.push(<div key={index}>{content_.text}</div>);
        }
      });
    }
    setData_(already_);
  }

  useEffect(() => {
    async function setup() {
      const location = new SafeURLSearchParams(window.location.search);

      let info__ = "";
      let isProspect = false;

      let thisproduct = location.get("product")?.toUpperCase();

      try {
        thisproduct = thisproduct.replaceAll(/%20| |-/g, "_");
      } catch (e) {
        if (e instanceof TypeError) {
          thisproduct = thisproduct.replace(/%20| |-/g, "_");
        }
      }

      if (thisproduct === "HAIR_LOSS") {
        thisproduct = "HL";
      }
      setProductcode(thisproduct);

      setUnload(false);
      const tokindex_ = Base.getExtraFields(extrafieldsSet, tokindexSet);

      const products = Base.getprodcatinfo();

      const productenv = products.find(
        (prod) =>
          (!prod.variant &&
            !prod.variantRepeat &&
            prod.code.toLowerCase() === thisproduct.toLowerCase()) ||
          prod?.variant === thisproduct.toLowerCase()
      );

      if (productenv?.code === "asthma") {
        Base.showRedirectMessage(
          "/",
          `Sorry, this product is currently unavailable. ${
            productenv?.code !== "asthma"
              ? "If this problem persists, please contact our patient success team."
              : ""
          }`
        );
        return false;
      }

      if (!productenv) {
        Base.showRedirectMessage(
          "/",
          "Sorry, the product at this URL does not exist. Please contact our patient success team for assistance."
        );
        return false;
      }

      if (productenv.login && !User.loggedIn()) {
        Base.showRedirectMessage(
          "/",
          "Please log in to access this page.",
          null,
          3000,
          "info"
        );
        return false;
      }

      let originProductEnv = Object.assign({}, productenv);

      // Check category from server
      const categories = await OscarAPI.getCategories();

      const categoryIndex = categories.findIndex(
        (c) =>
          (c.slug.includes("_") && c.slug === productenv.code) ||
          c.slug === productenv.code.replace(/_| /g, "-") ||
          (productenv.code === "hl" && c.slug === "hair-loss") ||
          productenv.test ||
          (productenv.variant &&
            c.slug === productenv.code.replace(/_| /g, "-"))
      );

      if (categoryIndex <= -1) {
        Base.showRedirectMessage(
          "/",
          "Sorry, this product is currently unavailable. If this problem persists, please contact our patient success team.",
          null,
          10000
        );
        return false;
      }

      // Retrieve this category for use later
      const category = categories[categoryIndex];

      setCategory(category);

      const arhi = location.get("arhi")?.toLowerCase();
      setArhi(arhi);

      const roseRXIDParam = location.get("rose_rx_id");
      setRoseRXID(roseRXIDParam);

      const consultReferrerParam = location.get("consult_referrer_code");
      setConsultReferrer(consultReferrerParam);

      // Referred Quick Script patients are always eScript only.
      if (isReferredQuickScript(category, consultReferrerParam))
        setScriptOnly(true);

      const tokenParam = location.get("token");
      if (tokenParam !== null) {
        setScriptToken(tokenParam);
      }

      window.ga("create", import.meta.env.VITE_GID_USER, {
        cookieDomain: "auto",
        name: "mhtracker"
      });

      await Base.setPromocode(
        { voucherpair },
        "questionnaire",
        productenv.code,
        setIsEventPromo
      );
      setProductga(productenv.GA ? productenv.GA : productenv.code);

      const { version } = queryString.parse(window.location.search);
      setVer(version ?? productenv?.newversion);

      if (productenv.code === "medicalcertificates") {
        setIsCert(true);
      }

      const { from } = queryString.parse(window.location.search);
      if (
        from &&
        (!productenv.fromStage || productenv.fromStage >= Base.Config().STATE)
      ) {
        setIsValidParam(from);
        await Base.setSubEnvInfo(from, productenv, {
          setFromParam,
          setNewLogo,
          setNewLogoClass,
          showIhiQuestionSet
        });
      }

      if (productenv.scriptonly) {
        setDeliveryOp("no");
      }

      if (productenv.medicationSearch)
        setMedicationCategoryId(
          categories.find((cat) => cat.slug === productenv.medicationSearch).id
        );
      if (productenv.scriptonlySearch) setScriptOnly(true);

      if (User.loggedIn()) {
        setUserEmail(User.email());
        if (newPlainCode) setPlainCode(newPlainCode);

        const productcheck = await Base.checkproduct(
          User.email(),
          productenv.name,
          "questionnaire > useEffect"
        );
        if (
          productcheck &&
          productcheck.getquestionnaire &&
          !productenv.both &&
          !productenv.subscriptiononce
        ) {
          setAlready(setData);
        } else {
          if (productenv.notallow || productenv.scriptonly) {
            const questionnairelist = await Base.getPatientquestionnaires(
              productenv.name,
              "questionnaire > useEffect"
            );
            if (
              questionnairelist &&
              productenv.notallow?.findIndex(
                (not) =>
                  questionnairelist.findIndex(
                    (q) => not === q.product.toLowerCase()
                  ) > -1
              ) > -1
            ) {
              setAlready(setData, "notallow");
            }
          }
          const userinfo = await Base.getPtDetails();
          if (
            userinfo.iserror ||
            !userinfo.patientProfiledetails ||
            !userinfo.patientProfiledetails.patient_id
          ) {
            if (userinfo.notoken) {
              Base.showRedirectMessage(
                "/",
                "Your session has expired. Please log in to continue.",
                { logout: User.logout }
              );
            }
            return false;
          }
          info__ = userinfo.patientProfiledetails;
          if (info__?.dob) {
            const age = Base.getAgebyDOB(info__.dob);
            if (age > 0 && productenv.age) {
              if (parseInt(productenv.age) > age) {
                Base.showRedirectMessage(
                  "/",
                  `Sorry, you need to be ${productenv.age} or over to use this service.`
                );
                return false;
              } else if (parseInt(productenv?.maxAge) < age) {
                Base.showRedirectMessage(
                  "/",
                  `Sorry, this service is not suitable for people over the age of ${productenv.maxAge} as there may be other factors impacting your condition that need to be assessed. We recommend seeing a doctor in person to complete any necessary tests and prescribe treatment.`
                );
                return false;
              }
            } else if (age > 0 && age < Base.Config().MINAGE) {
              Base.showRedirectMessage(
                "/",
                `Sorry, you need to be ${
                  Base.Config().MINAGE
                } or over to use this service.`
              );
              return false;
            }
          }

          if (
            info__?.gender &&
            productenv.requiredGender &&
            productenv?.requiredGender?.toLowerCase() !==
              info__.gender.toLowerCase()
          ) {
            const Messages = AddUserquestionsnew.find(
              (qus) => qus.ga_id === "A0006"
            ).options.find(
              (answer) =>
                answer.title.toLowerCase() === info__?.gender?.toLowerCase()
            ).message;
            const message = Messages[productenv?.code]
              ? Messages[productenv?.code]
              : Messages["default"]
              ? Messages["default"]
              : Messages;
            Base.showRedirectMessage("/", message);
            return false;
          }

          const result___ = await Base.setBasicQuestionnaireInfo(
            info__,
            setFirstName,
            setLastName,
            dob_daySet,
            dob_monthSet,
            dob_yearSet,
            setAddressLine1,
            setAddressLine2,
            setSuburb,
            setState,
            setPostcode,
            setPh,
            smokingStatusSet,
            medicareSet,
            medicarerefSet,
            medicareexpSet,
            ihiSet,
            originmedicareSet,
            originmedicarerefSet,
            originmedicareexpSet,
            genderSet,
            setIsGenderProvided,
            productenv
          );
          if (result___ && result___.error) {
            Base.showRedirectMessage(
              "/",
              "Sorry, we were unable to process your request. Please try again and contact our Patient Success team if the issue persists."
            );
          }
          setpatientid(userinfo.patientProfiledetails.patient_id);
        }
      } else {
        setIsNewUser(true);
      }

      const productdata = await import(
        `../../questionnaires/${
          productenv.variant
            ? productenv.useBaseQuestionnaire
              ? productenv.code
              : productenv.variant
            : thisproduct.toLowerCase()
        }.js`
      );

      const extradata = window.location.search.substring(1).split("&")[1];
      let prospect = "";
      let formresult = "";

      const consultProduct = await OscarAPI.getConsultationProduct(
        category.slug
      );

      setConsultProduct(consultProduct);

      const consultFee = await OscarAPI.getProductPrice(consultProduct.id, {
        consultReferrerCode: consultReferrerParam
      }).then((price) => parseFloat(price.incl_tax));
      setConsultFee(consultFee);

      if (productenv.addCosts) {
        let constList = {};
        await Promise.all(
          productenv.addCosts.map(async (categorySlug) => {
            const categoryObj =
              await OscarAPI.getConsultationProduct(categorySlug);
            constList = {
              ...constList,
              ...{
                [categorySlug]:
                  "$" +
                  (await OscarAPI.getProductPrice(categoryObj.id).then(
                    (price) => parseFloat(price.incl_tax)
                  ))
              }
            };
          })
        );
        setReason(constList);
      }

      const [injectablesUnavailable, underAgeQuestion, skipUserQuestionForNib] =
        await Promise.all([
          Feature.isActive("injectablesUnavailable-InitialQuestionnaire"),
          Feature.isActive("under_18"),
          Feature.isActive("skip-userquestion-for-nib")
        ]);

      setInjectablesUnavailable(injectablesUnavailable);
      setUnderAgeQuestion(underAgeQuestion);
      setSkipUserQuestionForNib(skipUserQuestionForNib);

      prospect = await Base.getProspectForm(
        extradata,
        setUserEmail,
        setpatientprospectid,
        setPlainCode,
        setFirstName,
        setLastName,
        dob_daySet,
        dob_monthSet,
        dob_yearSet,
        setAddressLine1,
        setAddressLine2,
        setSuburb,
        setState,
        setPostcode,
        genderSet,
        modaldataSet,
        modalSet,
        setIsprospect,
        closeModal,
        setUnload,
        productenv,
        medicareSet,
        medicarerefSet,
        medicareexpSet,
        medicareexptempSet,
        nomedicareSet,
        setIsValidParam,
        setFromParam,
        setNewLogo,
        setNewLogoClass,
        setMembership,
        setBrandpillId,
        setConsultFee,
        setScriptOnly,
        setBrandpill,
        medicareSelectedStatusSet,
        consultFee,
        setOriginBrandpill,
        heightSet,
        weightSet,
        setBmiValue,
        setHighBlood,
        setLowBlood,
        ihiSet,
        noIhiSet,
        showIhiQuestionSet,
        newPlainCode,
        setCategoryList,
        "questionnaire"
      );

      formresult = await Base.createFullForm(
        productdata,
        Userquestions,
        NewUserquestions,
        UserquestionsV3,
        UserquestionsV4,
        AddUserquestions,
        AddUserquestionsnew,
        AddUserquestionsnewv2,
        AfterUserquestions,
        extradata,
        productenv,
        setHasScript,
        setScriptQus,
        setScriptqusType,
        qProspectSet,
        setBrandpill,
        setPrice,
        setDuration,
        prospect?.isnewversion
          ? { isnewversion: true }
          : prospect?.qform_ && !prospect?.message
          ? prospect.qform_
          : null,
        {
          deliveryoptionSet,
          info: info__,
          tokindex: tokindex_ > 0 ? tokindex_ : -1,
          isSameDay,
          modaldataSet,
          modalSet,
          closeModal,
          setUnload,
          setAddComIndex,
          setIsCheckRes,
          setIsChemist2u,
          ChemistShipCost,
          setIsColdChain,
          injectablesUnavailable,
          category
        }
      );

      if (info__?.ihi) showIhiQuestionSet(false);

      if (!formresult || !formresult.isvaild) {
        if (formresult && formresult.message) {
          Toast.error(formresult.message);
        }
        return false;
      }

      newPlainCode = null;

      isProspect = !!(
        formresult.prospectform_ &&
        Array.isArray(formresult.prospectform_) &&
        formresult.prospectform_.length > 0
      );

      if (formresult.isnewversion || (prospect && prospect.message)) {
        modaldataSet(
          <Commmodal
            modaltype={"simple"}
            header={""}
            wide={true}
            mobilewide={true}
          >
            <div className="consultation-list">
              <div className="col-12 chatbox-inner-container mt-3">
                <p>Welcome back!</p>
                <p>
                  We've made some technical updates since you were last here,
                  which means you may find you need to start your questionnaire
                  from the beginning again.
                </p>
                <p>Sorry for any inconvenience caused!</p>
              </div>
              <div className="col-12 chatbox-inner-container mt-3">
                <button className={"button  fullwidth"} onClick={closeModal}>
                  Ok, I understand
                </button>
              </div>
            </div>
          </Commmodal>
        );
        modalSet(true);
      }
      Base.logger(Base.Config().STATE, null, null, { formresult });

      if (isReferredQuickScript(category, consultReferrerParam)) {
        // Remove irrelevant questions for referred Quick Script consultations
        for (const gaId of ["RS0100", "RS0150", "RS0480"]) {
          const index = formresult.qform.findIndex((qus) => qus.ga_id === gaId);
          if (index > -1) {
            formresult.qform.splice(index, 1);
          } else {
            Sentry.captureMessage(
              `Failed remove question for Quick Script referred consult: ${gaId}`
            );
          }
        }
      }

      let newindex = 0;
      let curqfrom = [formresult.qform[newindex]];

      /*
                This is releated to the controlled experiment(a/b/c) for the welcome page by version parameter
   
                - test details are as followings:
                a: no version value and show old welcome page and
                b: version value is new and show new welcome page
                c: version value is no and show no welcome page
   
                - Following logic is for c test, so it will remove the welcome page
            */
      if (
        (version === "no" ||
          (productenv.showsplash && version !== productenv.showsplash)) &&
        formresult.qform[newindex]?.type === "splash" &&
        formresult.qform[newindex]?.typefrom === "welcome"
      ) {
        formresult.qform.splice(newindex, 1);
        curqfrom = [formresult.qform[newindex]];
      }

      while (
        formresult?.prospectform_?.length > 1 &&
        formresult?.prospectform_[
          formresult?.prospectform_?.length - 1
        ]?.response.includes("SKIP THIS QUESTION")
      ) {
        newindex--;
        formresult?.prospectform_.pop();
      }

      setOriginProductenv(originProductEnv);
      setProductenv(productenv);
      setProductData(productdata);
      qdataSet(formresult.qform);
      qSet(
        isProspect && !formresult.isnewversion
          ? formresult.prospectform_
          : curqfrom
      );
      setQuestionnaireid(
        isProspect
          ? formresult.isnewversion
            ? newindex
            : formresult.prospectindex
          : newindex
      );

      setNextId(
        isProspect
          ? formresult.isnewversion
            ? 0
            : formresult.prospectform_.length - 1 - formresult.prospectindex
          : 0
      );
      setGaonce(false);
      if (productenv.gender) {
        genderSet(productenv.gender);
      }

      setisInitDone(true);

      jQuery(window).resize(() => {
        if (jQuery(".signup_outer").length >= 1) {
          if (
            jQuery(window).width() <= 450 &&
            jQuery(".page-wrapper").length >= 1
          ) {
            jQuery("#secondary-page").addClass(" submitqbg");
          } else {
            jQuery("#secondary-page").removeClass(" submitqbg");
          }
        }
      });

      if (!category) {
        setCategory(await getCategory(productenv.name));
      }

      history.pushState(null, null, location.href);
    }

    setup();
  }, [fullrefresh]);

  const thisperc = isend
    ? 100
    : (
        ((questionnaireid + 1 + nextid) / (questionnairedata.length + nextid)) *
        100
      ).toFixed(0);
  const form = QTemplate.displayform({
    data_,
    questionnaire,
    productenv,
    _scriptname,
    price,
    deliverycost,
    brand_pill,
    dob_day,
    dob_month,
    dob_year,
    addressLine1,
    addressLine2,
    suburb,
    state,
    postcode,
    questionnaireid,
    height,
    weight,
    commonvalues,
    subindex,
    showpayment,
    setShowpayment,
    first_name,
    last_name,
    user_email,
    noaddbt,
    next,
    modalSet,
    childRef,
    childPromoRef,
    back,
    imagefiles,
    setImageFiles,
    submit,
    createButtonText: Base.createButtonText,
    iscoupon: Base.Config().ENABLECOUPON,
    setIsEventPromo,
    iseventpromo,
    handleCheck: Base.handleCheck,
    checkbtsSet,
    accept_,
    checkbts,
    acceptSet,
    Base,
    acceptBypassSet,
    acceptbypass_,
    promocode: voucherpair,
    setPromocode: Base.setPromocode,
    onChangeMultiChoice: Base.onChangeMultiChoice,
    hideOther,
    setHideOther,
    other,
    setOther,
    productname: productenv ? productenv.name : "",
    issameday,
    brand_pill_title,
    duration,
    getdiscount,
    setGetDiscount,
    scriptToken,
    setScriptToken,
    medicareexptemp,
    medicareexptempSet,
    nomedicare,
    nomedicareSet,
    medicareSelectedStatus,
    medicareSelectedStatusSet,
    medicare,
    medicareref,
    medicareexp,
    HALFDESIGN,
    brandlist,
    setProduct,
    total,
    duenow,
    checkedprod,
    isInitDone,
    brandlisttitle,
    brandlistsubtitle,
    questionnairedata,
    reason_,
    isCert,
    from: Base.Config().FROM,
    brandnm_: Base.Config().FROM
      ? Base.Config().FROM === "hubhealth"
        ? "hub.health"
        : Base.capitalizeFirstLetter(Base.Config().FROM)
      : "",
    isver,
    plainCode,
    saveCompLater: Base.saveCompLater,
    isValidParam,
    fromParam,
    setIsValidParam,
    setFromParam,
    setMembership,
    membership,
    btLoading,
    setBtLoading,
    script_only,
    setBrandpillId,
    deliveryop,
    brand_pill_id,
    medicationChange,
    consultfee,
    callTimeChange,
    callTime,
    ph,
    setPh,
    categoryList,
    setUnload,
    unload,
    isChemist2u,
    shipCost,
    isStock,
    setTriggerPromo,
    triggerPromo,
    setBrandpill,
    curGaid,
    setCurGaid,
    patientprospectid,
    patientid,
    brand_origin_pill,
    showCountdown: Base.showCountdown,
    activeVouchers,
    discount,
    applyVoucher,
    voucherError,
    category,
    highBlood,
    lowBlood,
    isShowNewPaymentPage,
    eScriptStatus,
    medicationList,
    eScriptName,
    eScriptRemaining,
    paymentType,
    setPaymentType,
    membershipsStatus,
    ihi,
    ihiSet,
    noIhiSet,
    ihiSelectedStatus,
    ihiSelectedStatusSet,
    arhi,
    medicationCategoryId,
    setIsNibPatient,
    isNibPatient,
    healthCheckResult,
    setHideSplash,
    setResetProductEnv
  });

  // if form object is not created yet, show a loading
  const loader = document.getElementById("cover-spin");
  if (loader) loader.className = form ? "" : "show";

  if (!preventTopScroll) {
    Base.scrollTop_();
  }

  if (User.loggedIn() && !fullrefresh) {
    setFullRefresh(true);
  }

  if (!isInitDone) {
    return <div></div>;
  }

  // If the category has not loaded yet, assume the questionnaire is enabled
  // so that the questionnaire disabled message does not flash on and off as the page loads
  const questionnaireIsEnabled = category?.is_enabled ?? true;

  window.onpopstate = (_) => {
    if (questionnaire?.length === 1) {
      if (location.state?.isNavLink) {
        navigate(location.state.from, { replace: true });
      } else {
        history.go(-1);
      }
    } else {
      history.go(1);
    }
  };

  return (
    <div
      id="secondary-page"
      className={`
        ${
          questionnaire[questionnaire.length - 1].type === "splash"
            ? questionnaire[questionnaire.length - 1].comp &&
              questionnaire[questionnaire.length - 1].splashname
              ? questionnaire[questionnaire.length - 1].splashname
              : questionnaire[questionnaire.length - 1]?.typefrom?.includes(
                  "welcome"
                )
              ? `welcome-page ${isver ? "newwelcome" : ""} ${
                  questionnaire[questionnaire.length - 1]?.bgfull &&
                  "welcome-page-bg-full"
                }`
              : ""
            : ""
        }
          ${
            questionnaire[questionnaire.length - 1].employeeHealthCheck &&
            "employeehealthcheck"
          }
      `}
    >
      {questionnaire[questionnaire.length - 1].employeeHealthCheck && (
        <EmployeeHealthCheckSplashLogo
          back={back}
          hideBack={questionnaire.length <= 1}
        />
      )}
      <div
        className={`my-questionnaire container${
          questionnaire[questionnaire.length - 1] &&
          questionnaire[questionnaire.length - 1]?.wide
            ? "-fluid"
            : ""
        } ${productenv?.outerclass}
              ${
                questionnaire[questionnaire.length - 1]
                  ? questionnaire[questionnaire.length - 1].typefrom
                    ? `${
                        questionnaire[questionnaire.length - 1].typefrom
                      }-page ${
                        questionnaire[questionnaire.length - 1].typefrom
                      }_outer`
                    : questionnaire[questionnaire.length - 1].type
                    ? `${questionnaire[questionnaire.length - 1].type}-page ${
                        questionnaire[questionnaire.length - 1].type
                      }_outer`
                    : ""
                  : ""
              }
              ${
                questionnaire &&
                questionnaire.length >= 1 &&
                questionnaire[questionnaire.length - 1] &&
                questionnaire[questionnaire.length - 1].noheader
                  ? "noheader-outer"
                  : ""
              } ${
                questionnaire[questionnaire.length - 1] &&
                questionnaire[questionnaire.length - 1]?.type === "signup" &&
                isShowNewPaymentPage &&
                "noHorizontalScroll-md"
              }
              ${
                questionnaire[questionnaire.length - 1] &&
                questionnaire[questionnaire.length - 1]?.type === "signup" &&
                isShowNewPaymentPage &&
                "showNewPaymentPage"
              }
              ${
                questionnaire[questionnaire.length - 1].employeeHealthCheck &&
                "employeehealthcheck-outer"
              }
          `}
      >
        {productenv?.subname !== "NibInternational" &&
          !questionnaire[questionnaire.length - 1].employeeHealthCheck &&
          questionnaire[questionnaire.length - 1] &&
          questionnaire[questionnaire.length - 1]?.type === "splash" &&
          questionnaire[questionnaire.length - 1]?.typefrom?.includes(
            "welcome"
          ) &&
          (!isver ||
            !questionnaire[questionnaire.length - 1]?.brands?.includes(
              Base.Config().FROM
            )) && (
            <div
              className="bg_welcome"
              css={{
                backgroundImage: `url(/assets/images/${Base.Config().FROM}/${
                  questionnaire[questionnaire.length - 1]?.bgfull
                    ? "newwelcome1.png"
                    : "bgwelcome.png"
                })`
              }}
            ></div>
          )}
        <div
          className={`questionnaire-base outter-box 
                  brand-${Base.Config().FROM}
                  ${
                    questionnaire[questionnaire.length - 1] &&
                    questionnaire[questionnaire.length - 1]?.type ===
                      "splash" &&
                    questionnaire[questionnaire.length - 1]?.typefrom?.includes(
                      "welcome"
                    ) &&
                    isver &&
                    questionnaire[questionnaire.length - 1]?.brands?.includes(
                      Base.Config().FROM
                    )
                      ? "welcome-new"
                      : ""
                  }
              `}
        >
          {questionnaire &&
            questionnaire.length > 0 &&
            questionnaire[questionnaire.length - 1].type !== "splash" &&
            !questionnaire[questionnaire.length - 1].employeeHealthCheck && (
              <div
                className={`header row
                  ${
                    questionnaire &&
                    questionnaire.length >= 1 &&
                    questionnaire[questionnaire.length - 1] &&
                    questionnaire[questionnaire.length - 1].noheader
                      ? "d-none"
                      : ""
                  }`}
              >
                <div
                  className="logo col-12 text-center"
                  css={{
                    maxWidth: "unset",
                    marginBottom:
                      fromParam === nibInternationalSearchParam ? "0px" : "20px"
                  }}
                >
                  <div className="questionnaire-logo-size-container">
                    <LogoBrand
                      customBrand={fromParam}
                      css={{
                        width:
                          fromParam === nibInternationalSearchParam
                            ? "320px !important"
                            : "unset"
                      }}
                    />
                  </div>
                </div>
                {questionnaire[questionnaire.length - 1] &&
                  questionnaire[questionnaire.length - 1]?.type === "signup" &&
                  isShowNewPaymentPage && (
                    <div
                      className="col-lg-12 d-lg-block d-none"
                      css={{
                        textAlign: "center"
                      }}
                    >
                      <div className="w-100">
                        <hr
                          css={{
                            color: "#737373",
                            width: "200px",
                            opacity: "1",
                            marginLeft: "auto",
                            marginRight: "auto",
                            paddingBottom: "25px"
                          }}
                        />
                      </div>
                      <h3 css={{ fontWeight: "400" }}>
                        Thanks for completing your Online assessment
                      </h3>
                    </div>
                  )}
                {questionnaireIsEnabled && (
                  <ProgressBar
                    isEnd={
                      questionnaire[questionnaire.length - 1]?.type === "signup"
                    }
                    hideback={
                      hideback ||
                      questionnaire.length <= 1 ||
                      productenv.noback ||
                      (hideSplash && questionnaire.length <= 2)
                    }
                    back={back}
                    isShowNewPaymentPage={isShowNewPaymentPage}
                    current={
                      productenv.customProgressBar
                        ? questionnaire[questionnaire.length - 1]?.currentPage
                        : questionnaireid + 1 + nextid
                    }
                    total={
                      productenv.customProgressBar
                        ? questionnaire[questionnaire.length - 1]?.pageTotal
                        : questionnairedata.length + nextid
                    }
                  />
                )}
              </div>
            )}
          <div
            className={`content  main-part ${
              questionnaire &&
              questionnaire.length >= 1 &&
              questionnaire[questionnaire.length - 1] &&
              questionnaire[questionnaire.length - 1].noheader
                ? "noheader-content"
                : ""
            }`}
          >
            <div
              className={`${
                questionnaire &&
                questionnaire.length > 0 &&
                questionnaire[questionnaire.length - 1].type === "splash"
                  ? "main-part-inner"
                  : "row"
              } ${
                !questionnaire[questionnaire.length - 1] ||
                questionnaire[questionnaire.length - 1].type !== "signup"
                  ? ""
                  : "pr-sm-0-cs"
              }`}
            >
              {questionnaire[questionnaire.length - 1] &&
                questionnaire[questionnaire.length - 1]?.type !== "splash" &&
                (questionnaire[questionnaire.length - 1]?.type !== "signup" ||
                  (questionnaire[questionnaire.length - 1]?.type === "signup" &&
                    !isShowNewPaymentPage)) && (
                  <div
                    className={`${
                      questionnaire[questionnaire.length - 1] &&
                      questionnaire[questionnaire.length - 1].type ===
                        "signup" &&
                      !productenv.narrow
                        ? "col-lg-1"
                        : questionnaire[questionnaire.length - 1].type ===
                            "signup" && productenv.narrow
                        ? "col-lg-2"
                        : "col-lg-2"
                    }`}
                  ></div>
                )}
              <div
                className={`col-12 ${
                  questionnaire[questionnaire.length - 1]
                    ? questionnaire[questionnaire.length - 1]?.type ===
                        "splash" ||
                      (questionnaire[questionnaire.length - 1]?.type ===
                        "signup" &&
                        isShowNewPaymentPage)
                      ? `col-lg-12 ${
                          productenv?.subname === "NibInternational" &&
                          "new-bg-color"
                        }`
                      : questionnaire[questionnaire.length - 1]?.type ===
                          "signup" && !productenv.narrow
                      ? "col-lg-10"
                      : questionnaire[questionnaire.length - 1].type ===
                          "signup" && productenv.narrow
                      ? "col-lg-8"
                      : "col-lg-8"
                    : "col-lg-8"
                }`}
              >
                <form>
                  {!questionnaireIsEnabled && (
                    <QuestionnaireDisabledMessage category={category} />
                  )}
                  {questionnaireIsEnabled && form}
                </form>
              </div>
              {questionnaire[questionnaire.length - 1] &&
                questionnaire[questionnaire.length - 1]?.type !== "splash" &&
                (questionnaire[questionnaire.length - 1]?.type !== "signup" ||
                  (questionnaire[questionnaire.length - 1]?.type === "signup" &&
                    !isShowNewPaymentPage)) && (
                  <div
                    className={`${
                      questionnaire[questionnaire.length - 1] &&
                      questionnaire[questionnaire.length - 1].type ===
                        "signup" &&
                      !productenv.narrow
                        ? "col-lg-1"
                        : questionnaire[questionnaire.length - 1].type ===
                            "signup" && productenv.narrow
                        ? "col-lg-2"
                        : "col-lg-2"
                    }`}
                  ></div>
                )}
            </div>
          </div>
        </div>

        {modal_ && modaldata}
        <Modal
          show={showLoginModal}
          variant="success"
          onHide={() => setShowLoginModal(false)}
        >
          <Modal.Title
            customCss={{ marginBottom: "24px" }}
            onClose={() => setShowLoginModal(false)}
            noDivider={true}
          />
          {showLoginModal && (
            <Login userEmail={user_email} isQuestionnaireModal={true} />
          )}
        </Modal>
      </div>
      {showCompletionModal && (
        <QuestionnaireCompletionModal
          code={productenv.code}
          onClickFn={onGoToPortalClick}
          membershipsStatus={membershipsStatus}
          nibInternational={fromParam === nibInternationalSearchParam}
        />
      )}
    </div>
  );
}
