import React, { useState } from "react";

import { API, ConcreteAPI } from "../api";
import { Patient } from "../patient";
import {
  createApiResourceContext,
  createApiResourceContextProvider
} from "./ApiResourceContext";

export const PatientContext = createApiResourceContext<Patient>({
  isFetching: false,
  data: null,
  response: null,
  error: null,
  get: async () => {},
  update: async () => {}
});

const ContextProvider =
  createApiResourceContextProvider<Patient>(PatientContext);

export const PatientContextProvider = ({
  children,
  id
}: {
  children: React.ReactNode;
  id: string | number;
}) => {
  const [api] = useState<ConcreteAPI>(API.v1().url(`/patients/${id}`));
  return <ContextProvider api={api}>{children}</ContextProvider>;
};
