import { useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { type StripeError } from "@stripe/stripe-js";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import {
  StripeAPI,
  type StripeSetupIntent,
  useAsync,
  asyncIsSuccess,
  PatientAPI
} from "@mh/api";
import { Await } from "@mh/components";

import { ProfileField, ProfileForm, ProfileFormValues } from "../";
import { PaymentWrapper, type PaymentWrapperRef } from "./PaymentWrapper";

interface PaymentDetailsValues extends ProfileFormValues {
  isComplete: boolean;
}

/** Represents the display of a payment method card */
interface Card {
  last4?: string | null;
  expiryMonth?: number | null;
  expiryYear?: number | null;
}

const formatLast4 = (last4?: string | null) =>
  last4 ? `**** **** **** ${last4}` : "";

const formatExpiry = (
  expiryMonth?: number | null,
  expiryYear?: number | null
) => {
  if (expiryMonth && expiryYear) {
    return `${
      expiryMonth <= 9 ? `0${expiryMonth}` : expiryMonth
    } / ${expiryYear}`;
  }
  return "";
};

export const PaymentDetails = (): JSX.Element => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<StripeError | null>(null);
  const stripeSetupIntent = useAsync<StripeSetupIntent>(
    StripeAPI.getSetupIntent
  );
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [card, setCard] = useState<Card | null>(null);
  const paymentRef = useRef<PaymentWrapperRef | null>(null);

  const stripe = useStripe();

  const update = async () => {
    if (
      stripe === null ||
      !asyncIsSuccess(stripeSetupIntent.async) ||
      !isComplete ||
      paymentRef.current === null
    ) {
      return;
    }

    setIsFetching(true);

    const resolvedSetupIntent = await paymentRef.current.confirm();

    if (resolvedSetupIntent?.error) {
      setStripeError(resolvedSetupIntent.error);
      setIsFetching(false);
    }

    if (resolvedSetupIntent?.setupIntent) {
      const paymentMethod = await PatientAPI.updatePaymentMethod({
        setupIntentId: resolvedSetupIntent.setupIntent.id
      });
      // Update the card details for display
      setCard({
        last4: paymentMethod.last4,
        expiryMonth: paymentMethod.exp_month,
        expiryYear: paymentMethod.exp_year
      });
    }

    setIsFetching(false);
  };

  useEffect(() => {
    if (asyncIsSuccess(stripeSetupIntent.async) && card === null) {
      // If the initial setup intent has loaded and the card has not been set, then populate the card details for
      // display
      const {
        last_4: last4,
        exp_month: expiryMonth,
        exp_year: expiryYear
      } = stripeSetupIntent.async.data;

      setCard({ last4, expiryMonth, expiryYear });
    }
  }, [stripeSetupIntent.async, card]);

  return (
    <Await<[StripeSetupIntent]> asyncs={[stripeSetupIntent.async]}>
      {([{ client_secret: clientSecret, ...cardDetails }]) => {
        // Show specifically-set card details if they have been set, otherwise fall back to the setup intent's card
        const last4 = card?.last4 ?? cardDetails.last_4;
        const expiryMonth = card?.expiryMonth ?? cardDetails.exp_month;
        const expiryYear = card?.expiryYear ?? cardDetails.exp_year;

        return (
          <ProfileForm<PaymentDetailsValues>
            title="Payment details"
            id="payment"
            initialValues={{
              isComplete: false
            }}
            isFetching={isFetching}
            onSave={async () => await update()}
          >
            {({ isEditing }) => (
              <>
                {stripeError && (
                  <Alert variant="danger">{stripeError.message}</Alert>
                )}
                {!isEditing && (
                  <>
                    <ProfileField label="Card number">
                      <span>{formatLast4(last4)}</span>
                    </ProfileField>
                    <ProfileField label="Expiry">
                      <span>{formatExpiry(expiryMonth, expiryYear)}</span>
                    </ProfileField>
                  </>
                )}
                <Elements stripe={stripe} options={{ clientSecret }}>
                  {isEditing && (
                    <PaymentWrapper
                      ref={paymentRef}
                      stripe={stripe}
                      onCompleteChange={setIsComplete}
                    />
                  )}
                </Elements>
              </>
            )}
          </ProfileForm>
        );
      }}
    </Await>
  );
};
