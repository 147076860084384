let AddUserquestions = [
  {
    id: 105,
    title: "What was your sex assigned at birth?",
    type: "button",
    ga_id: "A0006",
    questionType: "common",
    hideQuestion: "isGenderProvided",
    options: [
      {
        id: 100051,
        title: "Male",
        products: ["contraceptive pill"],
        end: true,
        message:
          "Unfortunately this treatment is not suitable. We recommend that you see your GP physically to discuss options."
      },
      {
        id: 100052,
        title: "Female"
      }
    ]
  },
  {
    id: 106,
    title: "What is your height in cms?",
    type: "input",
    ga_id: "A0007",
    questionType: "common",
    inputtype: "float",
    input_type: "number",
    range: { min: 10, max: 300 },
    placeholder: "cm"
  },
  {
    id: 107,
    title: "What is your weight in kilograms?",
    type: "input",
    ga_id: "A0008",
    questionType: "common",
    inputtype: "float",
    input_type: "number",
    range: { min: 10, max: 300 },
    placeholder: "kg"
  }
];

export default AddUserquestions;
