import { User } from "@mh/api";
import { Spinner } from "react-bootstrap";

export interface SubmitProps {
  /* If the user is logged in (if not they are a new user) */
  isLoggedIn: boolean;
  /* question type */
  type: string;
  /* show product list or not (vidality only for now) */
  showProdList?: string;
}

interface SubmitButtonBlockProps {
  /** payment section base attribute list */
  signup?: {
    noLeft?: string | undefined; // payment section has left side or not
  };
  /** class for next button */
  nextButtonClass: string | undefined;
  /** next button elements */
  nextButton?: [];
  /** current question information */
  currentQuestion: {
    type: string; // question type
    showProdList?: string; // show product list or not (vidality only for now)
    buttonText?: string; // next button text from question
  };
  /* payment page contents/classes from end.jsx */
  done?: {
    useButtonText?: string; // button text for new user
    /* button text between login and new user */
    buttonText?: {
      loginText?: string; // button text for logged in user
      text?: string; // button text for new user
    };
  };
  /* when click submit button in payment page */
  submit: (e: any, params: SubmitProps) => void;
  /* optional css for the wrapper div */
  wrapperCss?: any;
}

export const SubmitButtonBlock = ({
  signup,
  nextButtonClass,
  nextButton,
  currentQuestion,
  done,
  submit,
  wrapperCss
}: SubmitButtonBlockProps) => (
  <div
    className={`${
      User.loggedIn()
        ? "col-12 col-md-10 mt-3"
        : signup?.noLeft || currentQuestion.type === "bypass"
        ? "col-12"
        : "col-12 col-lg-11 mt-3"
    } pt-sm-10-cs v-center`}
  >
    <div className="footer left submit-btn pb-3" css={wrapperCss}>
      <button
        className={`btn btn-primary btn-lg col-12 bt-pl-0-cs bt-pr-0-cs ${
          nextButtonClass ?? "submitbtcolor submitcolor"
        }`}
        id="submit"
        onClick={(e: any) =>
          submit(e, {
            isLoggedIn: User.loggedIn() ?? false,
            type: currentQuestion.type,
            showProdList: currentQuestion?.showProdList
          })
        }
      >
        <div>
          {currentQuestion?.buttonText
            ? currentQuestion?.buttonText
            : done?.useButtonText
            ? User.loggedIn() && done.buttonText?.loginText
              ? done.buttonText.loginText
              : done.buttonText?.text
            : nextButton && Array.isArray(nextButton) && nextButton.length > 0
            ? nextButton
            : "Next"}
        </div>
      </button>
      <button
        className="btn btn-primary col-12 p-3"
        id="loading"
        style={{ display: "none" }}
        disabled={true}
      >
        <Spinner animation="border" className="loading" size="sm" />
      </button>
    </div>
  </div>
);
