import { ThemeProvider, ThemeProviderProps } from "@emotion/react";
import { Brand } from "@mh/core";
import { Breakpoint, Color, MediaQuery, Theme } from "./types";

import "./theme.scss";

/** Breakpoints for standard screen sizes, taken from Bootstrap defaults. */
export const BREAKPOINTS: Record<Breakpoint, number> = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

/** Media queries for each breakpoint. */
export const MEDIA_QUERIES: Record<Breakpoint, MediaQuery> = {
  sm: `@media (min-width: ${BREAKPOINTS.sm}px)`,
  md: `@media (min-width: ${BREAKPOINTS.md}px)`,
  lg: `@media (min-width: ${BREAKPOINTS.lg}px)`,
  xl: `@media (min-width: ${BREAKPOINTS.xl}px)`,
  xxl: `@media (min-width: ${BREAKPOINTS.xxl}px)`
};

type DefaultColor = "dark" | "light";

/** Colour types which reflect user actions or states */
type ActionColor = "success" | "warning" | "danger";

/** Colours used for backgrounds */
type BackgroundColor = "backgroundFaint";

/** All colour types combined */
type ColorKey = DefaultColor | ActionColor | BackgroundColor;

const BASE_COLORS: Record<ColorKey, Color> = {
  dark: "#000000",
  light: "#FFFFFF",
  success: "#198754",
  warning: "#FD7E14",
  danger: "#FF0018",
  backgroundFaint: "#F8F9FA"
};

const HUBHEALTH_THEME: Theme = {
  mq: MEDIA_QUERIES,
  color: {
    primary: "#DD8E76",
    primaryLight: "#EFE2D6",
    secondary: "#B6594C",
    third: "#EEE6DD",
    fourth: "#90AEB2",
    background: "#EEE6DD",
    backgroundLight: "#F8F5F1",
    ...BASE_COLORS
  },
  font: {
    heading: "Inter"
  }
};

const STAGGER_THEME: Theme = {
  mq: MEDIA_QUERIES,
  color: {
    primary: "#091540",
    primaryLight: "#091540",
    secondary: "#48D6D3",
    third: "#091540",
    fourth: "#091540",
    background: "#FBFBFB",
    backgroundLight: "#FFFFFF",
    ...BASE_COLORS
  },
  font: {
    heading: "Inter"
  }
};

const YOULY_THEME: Theme = {
  mq: MEDIA_QUERIES,
  color: {
    primary: "#e84c42",
    primaryLight: "#e84c42",
    secondary: "#d0a5cd",
    third: "#e84c42",
    fourth: "#e84c42",
    background: "#EEEDF4",
    backgroundLight: "#FFFFFF",
    ...BASE_COLORS
  },
  font: {
    heading: "Inter"
  }
};

export const THEMES: Record<Brand, Theme> = {
  hubhealth: HUBHEALTH_THEME,
  stagger: STAGGER_THEME,
  youly: YOULY_THEME
};

interface BrandThemeProviderProps extends Omit<ThemeProviderProps, "theme"> {
  /** The brand which determines the theme to apply. */
  brand: Brand;
}

export const BrandThemeProvider = ({
  brand,
  ...props
}: BrandThemeProviderProps) => (
  <ThemeProvider theme={THEMES[brand]} {...props} />
);
