import { useState } from "react";
import { BaseQuestionnaire } from "@mh/api";

import { Status } from "./Status";
import { BloodPressureModal } from "../../BloodPressure";
import { Button } from "../../buttons";

interface BloodPressureStatusProps {
  /** Callback called when the blood pressure has been entered successfully. */
  onUpdate: () => void;
  questionnaire: BaseQuestionnaire;
}

export const BloodPressureStatus = ({
  onUpdate,
  questionnaire
}: BloodPressureStatusProps) => {
  const [showBloodPressureModal, setShowBloodPressureModal] = useState(false);
  return (
    <>
      <Status
        actions={
          <Button onClick={() => setShowBloodPressureModal(true)}>
            Input reading
          </Button>
        }
        title="BLOOD PRESSURE REQUIRED"
        titleVariant="danger"
      >
        <p>
          Please provide a blood pressure reading to continue with your
          consultation. You can have your blood pressure checked at most
          pharmacies or use a home blood pressure cuff.
        </p>
      </Status>
      {showBloodPressureModal && (
        <BloodPressureModal
          onUpdate={onUpdate}
          questionnaireId={questionnaire.id}
          onHide={() => setShowBloodPressureModal(false)}
        />
      )}
    </>
  );
};
