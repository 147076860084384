import { ComponentPropsWithoutRef } from "react";

export const IconCircleCancel = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.75"
      y="1.06934"
      width="32.5"
      height="32.5"
      rx="16.25"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <path
      d="M21.8438 22.1943C21.6562 22.3818 21.3125 22.3818 21.125 22.1943L17 18.0381L12.8438 22.1943C12.6562 22.3818 12.3125 22.3818 12.125 22.1943C11.9375 22.0068 11.9375 21.6631 12.125 21.4756L16.2812 17.3193L12.125 13.1943C11.9375 13.0068 11.9375 12.6631 12.125 12.4756C12.3125 12.2881 12.6562 12.2881 12.8438 12.4756L17 16.6318L21.125 12.4756C21.3125 12.2881 21.6562 12.2881 21.8438 12.4756C22.0312 12.6631 22.0312 13.0068 21.8438 13.1943L17.6875 17.3193L21.8438 21.4756C22.0312 21.6631 22.0312 22.0068 21.8438 22.1943Z"
      fill="currentColor"
    />
  </svg>
);
