import { Message } from "@mh/api";
import { Flex } from "@mh/components";
import { Card } from "react-bootstrap";
import { getInitials } from "../../../src/utils";

interface Props {
  message: Message;
  otherSenderDisplayName: string;
}

export function MessageItem({ message, otherSenderDisplayName }: Props) {
  const date = new Date(message.sent).toLocaleString("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    hour12: true,
    minute: "numeric"
  });
  return (
    <Flex className="row w-100 pb-3 m-0">
      <Flex className="align-items-end">
        {!message.sender_is_me && (
          <div className="me-1 user-avatar avatar-other">
            {getInitials(otherSenderDisplayName)}
          </div>
        )}
        <Card
          className={`py-3 px-4 text-break ${
            message.sender_is_me
              ? "speech-self ms-auto bg-primary text-white"
              : "speech-other me-auto bg-light"
          }`}
          data-testid="message-item-card"
        >
          {/** This should display on a mobile or smaller */}
          <p className="d-none d-md-inline">{message.content}</p>
          {/** This should display on a screen md or larger */}
          <p className="d-flex d-md-none small">{message.content}</p>
          <span className="small fw-light">{date}</span>
        </Card>
        {message.sender_is_me && (
          <div className="ms-1 user-avatar avatar-self">
            {getInitials(message.sender_display_name)}
          </div>
        )}
      </Flex>
    </Flex>
  );
}
