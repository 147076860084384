import { Button, ButtonProps } from "./Button";
import { Theme } from "../theme";

interface SwitchButtonProps extends Omit<ButtonProps, "border" | "variant"> {
  /** Whether the button is "on" or not. */
  on: boolean;
  /** Color variant when the switch is "off". */
  offVariant?: keyof Theme["color"];
  /** Color variant when the switch is "on". */
  onVariant?: keyof Theme["color"];
}

export const SwitchButton = ({
  on,
  children,
  offVariant = "danger",
  onVariant = "success",
  ...buttonProps
}: SwitchButtonProps) => (
  <Button
    border="pill"
    variant={
      children
        ? on
          ? onVariant
          : offVariant
        : on
        ? `${onVariant}-plain`
        : `${offVariant}-plain`
    }
    {...buttonProps}
  >
    <div
      css={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        gap: "8px",
        fontWeight: 600
      }}
    >
      <div
        css={(theme) => ({
          width: "32px",
          height: "16px",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: on ? "end" : "start",
          alignItems: "center",
          backgroundColor: children
            ? "white"
            : on
            ? theme.color[onVariant]
            : theme.color[offVariant],
          borderRadius: "10000px",
          margin: "6px 0px"
        })}
      >
        <div
          css={(theme) => ({
            width: "12px",
            height: "12px",
            margin: "2px",
            borderRadius: "50%",
            backgroundColor: children
              ? on
                ? theme.color[onVariant]
                : "#9FA5AA"
              : "white"
          })}
        />
      </div>
      {children}
    </div>
  </Button>
);
