import { Sentry } from "@mh/core";
import {
  OscarAPI,
  type BulkPriceResponse,
  type TinyProductWithImages,
  type ProductLike
} from "@mh/api";

export interface TinyProductWithImagesAndPrice extends TinyProductWithImages {
  price?: string;
}

/** A type used to determine whether an object is "product-like" enough */

interface ProductPrice {
  price?: string;
}

type ProductLikeWithoutPrice = Omit<ProductLike, "price">;

/**
 * Takes in a list of product-like objects and loads prices for them and removes duplicates
 *
 * @param products A list of product-like objects
 * @returns A list of product-like objects with prices attached to them, with duplicates removed
 */
export const getProductsWithPrices = async <
  ProductType extends ProductLikeWithoutPrice
>(
  products: ProductType[]
): Promise<(ProductType & ProductPrice)[]> => {
  let productsWithPrice: (ProductType & ProductPrice)[] = products.map(
    (product) => ({
      ...product,
      price: undefined
    })
  );

  // Filter out duplicate products from the list
  productsWithPrice = productsWithPrice.filter(
    (product, index, allProducts) =>
      allProducts.findIndex((p) => p.id === product.id) === index
  );

  const productIds = productsWithPrice.map((product) => product.id);
  let productPrices: BulkPriceResponse[] = [];

  if (productIds.length > 0) {
    // Only load bulk prices if there are any products in the first place
    try {
      productPrices = await OscarAPI.getProductPricesBulk(productIds);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const productIdToPrice = Object.fromEntries(
    productPrices.map((productPrice) => [
      productPrice.id,
      productPrice.price.incl_tax
    ])
  );
  productsWithPrice.forEach((product) => {
    // if we can't find the price, leave it as undefined and the UI will not show it
    product.price = productIdToPrice[product.id];
  });

  return productsWithPrice;
};

/** Load all recommended products for a given category and fetch their prices.
 *
 * @param categoryIds The ids of categories we are fetching recommended products for.
 * @returns An array of recommended products with prices.
 */
export const getRecommendedProductsWithPrices = async (
  categoryIds: number[]
): Promise<TinyProductWithImagesAndPrice[]> => {
  const products: TinyProductWithImages[] = await Promise.all(
    categoryIds.map((id) => OscarAPI.getRecommendedProducts(id))
  ).then((products) => products.flat());

  // @ts-ignore
  return getProductsWithPrices<TinyProductWithImages>(products);
};
