import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import {
  BaseQuestionnaire,
  Clinician,
  getWaitTime,
  SchedulingAPI,
  WaitDate
} from "@mh/api";
import {
  Button,
  IconCheckCircle,
  IconCircleExclamation,
  Modal,
  ModalProps
} from "@mh/components";
import { ManageBooking } from "./ManageBooking";

interface SchedulingModalProps extends Omit<ModalProps, "size"> {
  onClickBook: (
    selectedTime: moment.Moment,
    isRescheduling: boolean
  ) => Promise<void>;
  bookingDetails: BaseQuestionnaire["booking_event"] | null;
  onClickCancelBooking: () => void;
  onClickJoinTheQueue: () => Promise<void>;
  onClickRequestAfterHours?: () => void;
  successMessage?: string;
  errorMessage?: string;
  existingBookingDetails: BaseQuestionnaire["booking_event"] | null;
  product?: string;
  slug: string;
  consultationId: number | undefined;
  clinicianId: number;
  setClinicianId: (id: number) => void;
  availableClinicians?: Clinician[];
  // nib general telehealth enable flag, it will be removed when nib general telehealth is launched.
  nibTeleHealth: boolean;
  // if it is nib member
  nibMembershipNumber: number | undefined;
  // for arhi patient
  arhi: boolean;
  // for vip patient
  vip: boolean;
  // for after hours button test
  afterHoursTest?: boolean;

  membershipsStatus: string | undefined;
}

export const SchedulingModal = ({
  bookingDetails,
  onClickBook,
  onClickCancelBooking,
  onClickJoinTheQueue,
  onClickRequestAfterHours,
  successMessage,
  errorMessage,
  existingBookingDetails,
  consultationId,
  clinicianId,
  setClinicianId,
  availableClinicians = [],
  nibTeleHealth,
  nibMembershipNumber,
  arhi,
  vip,
  afterHoursTest,
  membershipsStatus,
  ...modalProps
}: SchedulingModalProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [liveWaitTime, setLiveWaitTime] = useState(5); // default wait time is 5 minutes
  const [waitTimeMessage, setWaitTimeMessage] = useState<string>("");
  const [availableTimesLocalTz, setAvailableTimesLocalTz] = useState<
    moment.Moment[]
  >([]);
  /** Only manage the appointment if they have an existing booking that is not an ASAP booking. */
  useEffect(() => {
    const fetchAvailableAppointments = async () => {
      setIsLoading(true);
      try {
        const isClinicianAvailable = availableClinicians.some(
          // @ts-ignore
          (clinician) => clinician.id === clinicianId
        );

        const availableAppointments =
          consultationId &&
          (await SchedulingAPI.getAvailableAppointments({
            consultation_id: consultationId,
            clinician_id: isClinicianAvailable ? clinicianId : undefined
          }));

        /**
         * Convert the API dates/times to a flat array of datetimes converted to the browser's local timezone.
         * Assumes the API dates/times are in UTC.
         */
        const availableTimes =
          availableAppointments &&
          availableAppointments.available_slots
            .map((slot) => moment.utc(slot).tz(moment.tz.guess()))
            .sort((a, b) => (a.isBefore(b) ? -1 : 1));
        if (availableTimes) setAvailableTimesLocalTz(availableTimes);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const loadLiveWaitTime = async (categorySlug: string) => {
      setIsLoading(true);
      const liveWaitTimeData =
        await SchedulingAPI.getLiveWaitTime(categorySlug);
      setWaitTimeMessage(liveWaitTimeData.wait_time_message);
      if (
        nibTeleHealth &&
        modalProps.product === "Telehealth Consult" &&
        (nibMembershipNumber || arhi)
      ) {
        const { expected_wait_time }: WaitDate = await getWaitTime();
        setLiveWaitTime(
          expected_wait_time
            ? expected_wait_time.generic_members
            : liveWaitTimeData.average_wait_time_minutes?.generic_members || 5
        );
      } else {
        const {
          generic_members: genericMembersWaitTime,
          hub_pass_members: hubPassMembersWaitTime,
          nib_general_telehealth: nibGeneralTelehealthWaitTime,
          nib_international_arhi_members: nibInternationalArhiMembersWaitTime,
          vip_members: vipMembersWaitTime
        } = liveWaitTimeData.average_wait_time_minutes || {};
        let waitTime = genericMembersWaitTime || 5;
        if (
          nibTeleHealth &&
          modalProps.product === "Telehealth Consult" &&
          (nibMembershipNumber || arhi)
        ) {
          waitTime = Math.min(
            nibGeneralTelehealthWaitTime || waitTime,
            nibInternationalArhiMembersWaitTime || waitTime
          );
        } else if (vip) {
          waitTime = vipMembersWaitTime!;
        } else if (membershipsStatus === "active") {
          waitTime = hubPassMembersWaitTime!;
        } else if (nibMembershipNumber || arhi) {
          waitTime = nibInternationalArhiMembersWaitTime!;
        }

        setLiveWaitTime(waitTime);
      }

      setIsLoading(false);
    };

    if (modalProps.show) {
      fetchAvailableAppointments();
      loadLiveWaitTime(modalProps.slug);
    }
  }, [modalProps.show, clinicianId]);

  const handleClickBook = async (selectedTime: moment.Moment) => {
    setIsLoading(true);
    await onClickBook(
      selectedTime,
      existingBookingDetails?.status === "taken" ||
        // @ts-ignore
        existingBookingDetails?.status === "call-back" ||
        // @ts-ignore
        existingBookingDetails?.status === "to-reschedule"
    );
    setIsLoading(false);
  };

  const handleClickJoinTheQueue = async () => {
    setIsLoading(true);
    await onClickJoinTheQueue();
    setIsLoading(false);
  };

  const handleClickRequestAfterHours = async () => {
    setIsLoading(true);
    onClickRequestAfterHours && (await onClickRequestAfterHours());
    setIsLoading(false);
  };

  const handleClickCancelBooking = async () => {
    setIsLoading(true);
    await onClickCancelBooking();
    setIsLoading(false);
  };

  const handleClickModalClose = () => {
    // in case of success, refreshes the page, updating outdated status messages
    successMessage ? navigate("/") : modalProps.onHide();
  };

  useEffect(() => {
    // call close button after 2 seconds when get the success message
    if (successMessage) {
      setTimeout(() => {
        handleClickModalClose();
      }, 1500);
    }
  }, [successMessage]);

  return (
    <Modal
      size={successMessage ? "sm" : "lg"}
      variant={successMessage ? "success" : undefined}
      mobileVCenter={!!(successMessage || errorMessage)}
      {...modalProps}
    >
      {!successMessage && !errorMessage && (
        <>
          <Modal.Title
            onClose={modalProps.onHide}
            horizontalSize={"sm"}
            buttonCss={{ padding: "0px" }}
          >
            <span css={(theme) => ({ color: theme.color.dark })}>
              Schedule an appointment
            </span>
          </Modal.Title>
          <ManageBooking
            availableTimes={availableTimesLocalTz}
            bookingDetails={bookingDetails}
            isLoading={isLoading}
            onClickBook={handleClickBook}
            onClickCancel={handleClickCancelBooking}
            onClickJoinTheQueue={handleClickJoinTheQueue}
            onClickRequestAfterHours={
              onClickRequestAfterHours
                ? handleClickRequestAfterHours
                : undefined
            }
            liveWaitTime={liveWaitTime}
            waitTimeMessage={waitTimeMessage}
            product={modalProps.product}
            clinicianId={clinicianId}
            setClinicianId={setClinicianId}
            availableClinicians={availableClinicians}
            nibTeleHealth={nibTeleHealth}
            nibMembershipNumber={nibMembershipNumber}
            arhi={arhi}
            afterHoursTest={afterHoursTest}
          />
        </>
      )}
      {successMessage && (
        <>
          <div
            css={(theme) => ({
              display: "flex",
              gap: "16px",
              paddingBottom: "24px",
              [theme.mq.md]: {
                paddingBottom: "unset"
              }
            })}
          >
            <IconCheckCircle
              css={(theme) => ({
                marginTop: "auto",
                marginBottom: "auto",
                width: "32px",
                height: "32px",
                color: theme.color.success
              })}
            />
            <span>{successMessage}</span>
          </div>
        </>
      )}
      {errorMessage && (
        <>
          <div
            css={(theme) => ({
              display: "flex",
              gap: "16px",
              paddingBottom: "24px",
              [theme.mq.md]: {
                paddingBottom: "unset"
              }
            })}
          >
            <IconCircleExclamation
              css={(theme) => ({
                marginTop: "auto",
                marginBottom: "auto",
                width: "32px",
                height: "32px",
                color: theme.color.danger
              })}
            />
            <span>{errorMessage}</span>
          </div>
        </>
      )}
      <div
        css={(theme) => ({
          display: "none",
          [theme.mq.md]: {
            display: "block"
          }
        })}
      >
        <Modal.Actions sticky>
          <Button onClick={handleClickModalClose}>Close</Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
};
