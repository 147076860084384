import { ComponentPropsWithoutRef } from "react";

export const IconHeart = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.96875 2L8.34375 1.65625C9.375 0.625 10.8438 0.15625 12.25 0.40625C14.4062 0.75 16 2.625 16 4.8125V4.96875C16 6.28125 15.4375 7.53125 14.5 8.40625L8.84375 13.6875C8.625 13.9062 8.3125 14 8 14C7.65625 14 7.34375 13.9062 7.125 13.6875L1.46875 8.40625C0.53125 7.53125 0 6.28125 0 4.96875V4.8125C0 2.625 1.5625 0.75 3.71875 0.40625C5.125 0.15625 6.59375 0.625 7.625 1.65625L7.96875 2ZM7.96875 3.4375L6.90625 2.34375C6.125 1.5625 5 1.1875 3.875 1.375C2.21875 1.65625 0.96875 3.09375 0.96875 4.8125V4.96875C0.96875 6 1.40625 6.96875 2.15625 7.65625L7.8125 12.9375C7.84375 13 7.90625 13 7.96875 13C8.0625 13 8.125 13 8.15625 12.9375L13.8125 7.65625C14.5625 6.96875 15 6 15 4.96875V4.8125C15 3.09375 13.75 1.65625 12.0938 1.375C10.9688 1.1875 9.84375 1.5625 9.0625 2.34375L7.96875 3.4375Z"
      fill="currentColor"
    />
  </svg>
);
