import { ComponentPropsWithoutRef } from "react";

export const IconFile = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.57617 0.541992V4.54199H12.5762L8.57617 0.541992ZM7.57617 4.54199V0.541992H2.07617C1.23242 0.541992 0.576172 1.22949 0.576172 2.04199V15.042C0.576172 15.8857 1.23242 16.542 2.07617 16.542H11.0762C11.8887 16.542 12.5762 15.8857 12.5762 15.042V5.54199H8.57617C8.01367 5.54199 7.57617 5.10449 7.57617 4.54199ZM9.07617 13.542H4.07617C3.79492 13.542 3.57617 13.3232 3.57617 13.042C3.57617 12.792 3.79492 12.542 4.07617 12.542H9.07617C9.32617 12.542 9.57617 12.792 9.57617 13.042C9.57617 13.3232 9.32617 13.542 9.07617 13.542ZM9.07617 11.542H4.07617C3.79492 11.542 3.57617 11.3232 3.57617 11.042C3.57617 10.792 3.79492 10.542 4.07617 10.542H9.07617C9.32617 10.542 9.57617 10.792 9.57617 11.042C9.57617 11.3232 9.32617 11.542 9.07617 11.542ZM9.57617 9.04199C9.57617 9.32324 9.32617 9.54199 9.07617 9.54199H4.07617C3.79492 9.54199 3.57617 9.32324 3.57617 9.04199C3.57617 8.79199 3.79492 8.54199 4.07617 8.54199H9.07617C9.32617 8.54199 9.57617 8.79199 9.57617 9.04199Z"
      fill="currentColor"
    />
  </svg>
);
