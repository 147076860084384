import { useEffect, useState } from "react";
import {
  PatientAPI,
  type TreatmentAcceptanceWizardStep,
  type TreatmentAcceptanceWizardData
} from "@mh/api";
import { Toast } from "@mh/components";

import {
  UseGenericStep,
  ProductStep,
  PackStep,
  ShippingMethodStep,
  CheckoutStep
} from "./steps";
import { useNavigate, useParams } from "react-router-dom";

const autoSelectSingleOption = (
  stepData: any[],
  key: string,
  handleContinue: (data: Partial<TreatmentAcceptanceWizardData>) => void,
  value: any = null
): boolean => {
  // if only one option is present, we want to auto select and move onto the next step
  if (stepData.length === 1) {
    handleContinue({ [key]: value });
    return true;
  }
  return false;
};

export const TreatmentAcceptanceWizard = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const navigate = useNavigate();

  /** Wizard steps the patient follows upon performing a treatment acceptance checkout */
  const [steps, setSteps] = useState<TreatmentAcceptanceWizardStep[] | null>(
    null
  );
  /** Response data the patient enters during the checkout flow */
  const [data, setData] = useState<TreatmentAcceptanceWizardData>({});
  /** The current index of steps which indicates the currently active (visible) step */
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    if (!subscriptionId) {
      console.error(
        "Subscription ID is required for a treatment acceptance checkout flow."
      );
      navigate("/");
      return;
    }

    const parsedSubscriptionId = parseInt(subscriptionId);

    if (isNaN(parsedSubscriptionId)) {
      console.warn("Subscription ID is not a valid number.");
      navigate("/");
      return;
    }

    // Load the initial steps
    PatientAPI.getTreatmentAcceptanceWizard(parsedSubscriptionId)
      .then(setSteps)
      .catch(() => {
        // Subscription not found, navigate back to the home page
        navigate("/");
      });
  }, [subscriptionId]);

  useEffect(() => {
    /**
     * If the next step or steps are satisfied as a result of the response of the just-submitted step, then skip past
     * them.
     */

    if (!steps) {
      // Steps haven't loaded
      return;
    }

    // Jump forward to the next unsatisfied step
    const nextStepIndex = steps.findIndex((step) => !step.is_satisfied);

    if (nextStepIndex > -1) {
      setActiveStep(nextStepIndex);
    }
  }, [steps]);

  const handleContinue = async (
    stepData: Partial<TreatmentAcceptanceWizardData>
  ) => {
    if (!subscriptionId) {
      return;
    }

    const parsedSubscriptionId = parseInt(subscriptionId);

    const combinedData = { ...data, ...stepData };

    try {
      const steps = await PatientAPI.submitTreatmentAcceptanceWizardStep(
        parsedSubscriptionId,
        combinedData
      );

      setSteps(steps);
      setData(combinedData);
    } catch (e) {
      Toast.error("Sorry, there was an error continuing. Please try again.");
    }
  };

  const renderStep = (step: TreatmentAcceptanceWizardStep) => {
    switch (step.type) {
      case "use_generic":
        return <UseGenericStep step={step} onContinue={handleContinue} />;
      case "brand":
        return <ProductStep step={step} onContinue={handleContinue} />;
      case "pack":
        if (autoSelectSingleOption(step.data.packs, "pack", handleContinue)) {
          return null;
        }
        return <PackStep step={step} onContinue={handleContinue} />;
      case "shipping_method":
        if (
          autoSelectSingleOption(
            step.data.shipping_methods,
            "shipping_method",
            handleContinue,
            step.data.shipping_methods[0].code
          )
        ) {
          return null;
        }
        return <ShippingMethodStep step={step} onContinue={handleContinue} />;
      case "checkout":
        return <CheckoutStep step={step} onContinue={handleContinue} />;
      default:
        return null;
    }
  };

  if (!steps) {
    return null;
  }

  const step = steps.at(activeStep);

  if (!step) {
    return null;
  }

  return (
    <div
      css={{
        display: "flex",
        flexFlow: "column",
        width: "100%",
        alignItems: "center"
      }}
    >
      {renderStep(step)}
    </div>
  );
};
