import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconCircleChevronLeft,
  LogoBrand,
  PageContainer
} from "@mh/components";

interface PageWithLogoHeaderProps {
  isNewCheckout?: boolean;
}
export const PageWithLogoHeader = ({
  children,
  isNewCheckout
}: PropsWithChildren<PageWithLogoHeaderProps>) => {
  const navigate = useNavigate();

  return (
    <PageContainer isNewCheckout={isNewCheckout}>
      <PageContainer.Header sticky>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <LogoBrand css={{ height: "24px" }} />
          <Button
            css={{ display: "flex", alignItems: "center", gap: "4px" }}
            onClick={() => navigate(-1)}
            variant="primary-plain"
          >
            <IconCircleChevronLeft css={{ width: "16px" }} />
            <span>Back</span>
          </Button>
        </div>
      </PageContainer.Header>
      <PageContainer.Content
        contentCss={{
          padding: isNewCheckout ? "0px" : "0px 16px"
        }}
      >
        {children}
      </PageContainer.Content>
    </PageContainer>
  );
};
