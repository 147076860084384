import { SplashTypeParams } from "@mh/questionnaire/src/types";
import { QuestionnaireContentComponentProps } from "..";

import "./QCSplash.scss";
import { SplashComponents } from "./pages";

export const QCSplash = (props: QuestionnaireContentComponentProps) => {
  const SplashComponent: React.FC<QuestionnaireContentComponentProps> =
    SplashComponents[(props.question!.type_params as SplashTypeParams).screen];
  return (
    <div className="qc-splash-page">
      <SplashComponent {...props} />
    </div>
  );
};
