import moment, { Moment } from "moment";
import { IMask, IMaskInput } from "react-imask";

import { QuestionnaireContentComponentProps } from ".";

const DATE_FORMAT = "DD/MM/YYYY";

export const QCDate = (
  props: Partial<QuestionnaireContentComponentProps> & { autoFocus?: boolean }
) => (
  // More or less duplicates <repo>/src/components/questionnaire/QuestionnaireDatePicker.tsx
  <div className="inner-content" css={{ textAlign: "center" }}>
    <IMaskInput
      id="date_picker"
      placeholder={DATE_FORMAT.toUpperCase()}
      eager // Will add the '/' as soon as you type the second var
      autofix={"pad"} // Will add a 0 in front of an invalid number
      type={"tel"} // Use numeric keypad
      // Date setup
      // We send the date in utc format, but it needs to be in local format in the component
      defaultValue={
        props.initialValue
          ? moment(props.initialValue).format(DATE_FORMAT)
          : undefined
      }
      mask={Date}
      pattern={DATE_FORMAT}
      format={(d: Date) => moment(d).format(DATE_FORMAT)} // Required if pattern set
      parse={(s: string) => moment(s, DATE_FORMAT)} // Required if pattern set
      min={new Date(1900, 0, 1)}
      max={new Date()}
      blocks={{
        // @ts-ignore
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 9999
        },
        // @ts-ignore
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        // @ts-ignore
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        }
      }}
      // unmask modifies the value in onAccept
      // `value` if `unmask=false`,
      // `unmaskedValue` if `unmask=true`,
      // `typedValue` if `unmask='typed'`
      unmask={"typed"}
      // DO NOT USE onChange TO HANDLE CHANGES!
      // USE onAccept INSTEAD
      onAccept={(value, _) => {
        const v: Moment | null = value as any;
        if (v !== null) {
          const s = v.format("YYYY-MM-DD");
          props.onChange!(s);
        }
      }}
      autoFocus={props.autoFocus ?? true}
      css={(theme) => ({
        maxWidth: "342px",
        width: "100% !important",
        padding: "8px !important",
        marginLeft: "auto",
        marginRight: "auto",
        lineHeight: "normal",
        minHeight: "35px !important",
        height: "unset !important",
        [theme.mq.md]: {
          maxWidth: "431px !important",
          width: "100% !important"
        },
        [theme.mq.lg]: {
          width: "431px !important",
          maxWidth: "unset  !important"
        },
        fontWeight: "400",
        border: "0.5px solid #737373",
        ":focus": {
          outline: "none !important",
          borderColor: `${theme.color.secondary} !important`
        }
      })}
    />
  </div>
);
