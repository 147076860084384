interface HeaderBlockProps {
  signupHeaderClass?: string; // payment section header title class
  signupHeaderDefault?: string; // default header title text
  /** header title text by category */
  signupHeader?: [
    {
      product: string;
      title: string;
      subTitle?: string;
      titleClass?: string;
      subTitleClass?: string;
    }
  ];
  code?: string; // category code
  classes?: string;
  titleClass?: string;
  subTitleClass?: string;
}

const getTitle = (props: HeaderBlockProps) => {
  const content = props.signupHeader
    ? props.signupHeader.find((t) => t.product === props.code)
    : "";
  return content && content.title ? content.title : props?.signupHeaderDefault;
};

const getSubTitle = (props: HeaderBlockProps) => {
  const content = props.signupHeader
    ? props.signupHeader.find((t) => t.product === props.code)
    : "";
  return content && content.subTitle ? content.subTitle : "";
};

export const PaymentHeaderBlock = (props: HeaderBlockProps) => {
  const title = getTitle(props);
  const subTitle = getSubTitle(props);
  return (
    <div
      className={`${
        props?.classes ?? "col-12 order-first"
      } ${props?.signupHeaderClass}`}
    >
      <h3 className={`title ${props?.titleClass}`}>{title}</h3>
      {subTitle && (
        <p className={`subtitle ${props?.subTitleClass}`}>{subTitle}</p>
      )}
    </div>
  );
};
