import { useContext, useEffect } from "react";
import { PatientContext, UserContext } from "@mh/api";
import { ShippingAddress } from "@mh/components";

import {
  UserInfo,
  NextOfKin,
  EmergencyContact,
  RegularGP,
  Medicare,
  DVA,
  IndividualHealthcareIdentifier,
  PaymentDetails,
  Membership
} from "../../components/forms";

export const Profile = () => {
  const userContext = useContext(UserContext);
  const patientContext = useContext(PatientContext);

  /** If the user/patient data has not been fetched yet, do it on first render. */
  useEffect(() => {
    if (!userContext.data) {
      userContext.get();
    }
    if (!patientContext.data) {
      patientContext.get();
    }
  }, []);

  return (
    <div className="container d-flex flex-column gap-5">
      <UserInfo />
      <NextOfKin />
      <EmergencyContact />
      <ShippingAddress />
      <RegularGP />
      <Medicare />
      <DVA />
      <IndividualHealthcareIdentifier />
      <Membership />
      <PaymentDetails />
    </div>
  );
};
