import { ComponentPropsWithoutRef } from "react";

export const IconBars = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.683594 16.7627C0.683594 16.5141 0.784162 16.2756 0.963174 16.0998C1.14219 15.924 1.38498 15.8252 1.63814 15.8252H20.729C20.9822 15.8252 21.225 15.924 21.404 16.0998C21.583 16.2756 21.6836 16.5141 21.6836 16.7627C21.6836 17.0113 21.583 17.2498 21.404 17.4256C21.225 17.6014 20.9822 17.7002 20.729 17.7002H1.63814C1.38498 17.7002 1.14219 17.6014 0.963174 17.4256C0.784162 17.2498 0.683594 17.0113 0.683594 16.7627ZM0.683594 9.2627C0.683594 9.01406 0.784162 8.7756 0.963174 8.59978C1.14219 8.42397 1.38498 8.3252 1.63814 8.3252H20.729C20.9822 8.3252 21.225 8.42397 21.404 8.59978C21.583 8.7756 21.6836 9.01406 21.6836 9.2627C21.6836 9.51134 21.583 9.74979 21.404 9.92561C21.225 10.1014 20.9822 10.2002 20.729 10.2002H1.63814C1.38498 10.2002 1.14219 10.1014 0.963174 9.92561C0.784162 9.74979 0.683594 9.51134 0.683594 9.2627ZM0.683594 1.7627C0.683594 1.51405 0.784162 1.2756 0.963174 1.09978C1.14219 0.923968 1.38498 0.825195 1.63814 0.825195H20.729C20.9822 0.825195 21.225 0.923968 21.404 1.09978C21.583 1.2756 21.6836 1.51405 21.6836 1.7627C21.6836 2.01134 21.583 2.24979 21.404 2.42561C21.225 2.60142 20.9822 2.7002 20.729 2.7002H1.63814C1.38498 2.7002 1.14219 2.60142 0.963174 2.42561C0.784162 2.24979 0.683594 2.01134 0.683594 1.7627Z"
      fill="currentColor"
    />
  </svg>
);
