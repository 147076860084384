import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { OTPMethod } from "@mh/api";
import { HorizontalDivider, LogoBrand } from "@mh/components";
import { Button } from "../buttons";

interface OTPFormProps {
  loading: boolean;
  method: OTPMethod;
  onOtpRequest: (method: OTPMethod) => void;
  onSubmit: (code: string) => void;
  userReentry?: boolean;
  isQuestionnaireModal?: boolean;
}

const DividerStyle = (theme: any) => ({
  color: theme.color.primary,
  width: "103px",
  height: "1px",
  marginTop: "24px",
  marginBottom: "24px"
});

export const OTPForm = ({
  loading,
  method,
  onOtpRequest,
  onSubmit,
  userReentry,
  isQuestionnaireModal
}: OTPFormProps) => {
  const [code, setCode] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(code);
  };

  const resendHandler = (switchMethod: boolean) => {
    if (!loading) {
      if (switchMethod) {
        onOtpRequest(method === "sms" ? "email" : "sms");
      } else {
        onOtpRequest(method);
      }
    }
  };

  const submitButton = (
    <Button
      variant="primary"
      css={{ marginTop: 8 }}
      disabled={loading || code.length < 6}
      data-testid="verify-button"
    >
      {loading ? (
        <span
          className="loadingouter position-relative"
          data-testid="spinner-testid"
        >
          <Spinner animation="border" className="loading" size="sm" />
        </span>
      ) : (
        "Verify and login"
      )}
    </Button>
  );

  const help = (
    <div className="notes small">
      <a className="link" onClick={() => resendHandler(false)}>
        {method === "sms"
          ? "Resend the code to my mobile "
          : "Re-email me the code "}
      </a>
      or{" "}
      <a className="link" onClick={() => resendHandler(true)}>
        {method === "sms" ? "email code" : "send code to my mobile"}
      </a>
    </div>
  );

  const form = (
    <form
      onSubmit={handleSubmit}
      className={userReentry ? "form reentryModal" : "form"}
    >
      <input
        type="text"
        className="input"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        data-testid="code-input"
        maxLength={6}
      />
      {help}
      {submitButton}
    </form>
  );

  return (
    <>
      {!isQuestionnaireModal ? (
        <div className={userReentry ? "title reentryModal" : "title"}>
          Enter your verification code
        </div>
      ) : (
        <>
          <LogoBrand width="140px" />
          <HorizontalDivider css={DividerStyle} />
          <div>Welcome back!</div>
          <div>Please log in to continue.</div>
          <HorizontalDivider css={DividerStyle} />
          <div
            css={{
              width: "400px",
              textAlign: "center",
              padding: "0 20px",
              marginBottom: "16px"
            }}
          >
            We’ve sent you a verification code via SMS, please enter the code
            below.
          </div>
        </>
      )}
      {form}
      {isQuestionnaireModal && (
        <div className="otp-description" css={{ textAlign: "center" }}>
          We&apos;ve moved to One-Time Password (OTP) authentication for
          enhanced security and a smoother experience. An OTP is a unique,
          single-use code that provides an extra layer of account protection.
        </div>
      )}
    </>
  );
};
