import { ReactNode, useMemo, useState, useRef } from "react";
import { DisplayHeading } from "@mh/components";

import { Flex } from "../Flex";

import Arrows from "./Arrows";
import IndexPoints, { IndexPoint } from "./IndexPoints";
import "./styles.scss";

export interface ScrollableContainerProps<Item> {
  /** The title to appear at the top of the container */
  title?: ReactNode;
  /** Objects whose details will be rendered */
  items: Item[];
  /** Can this container be manually scrolled? */
  scrollable?: boolean;
  /** How an item should render */
  renderItem: (item: Item) => ReactNode;
}

// eslint-disable-next-line comma-spacing
export const ScrollableContainer = <Item,>(
  props: ScrollableContainerProps<Item>
) => {
  // The index of the currently active item
  const [activePointIndex, setActivePointIndex] = useState<number>(0);
  // The div containing the rendered list items
  const contentsRef = useRef<HTMLDivElement | null>(null);
  // If this component is undergoing a manual scroll, this will be the position it's scrolling to
  const scrollingToPosition = useRef<number | null>(null);

  const points = useMemo<IndexPoint[]>(
    () => props.items.map((_, i) => ({ active: i === activePointIndex })),
    [activePointIndex, props.items]
  );

  /** Finds the item element at the given index */
  const findElement = (index: number): Element | null => {
    if (!contentsRef.current) return null;
    return contentsRef.current.children[index];
  };

  /**
   * Checks if the container div has reached its manual scroll destination
   *
   * @returns Whether the container is still scrolling
   */
  const isStillScrolling = (): boolean => {
    // No container element or controlled scroll, therefore it can't be scrolling
    if (contentsRef.current === null || scrollingToPosition.current === null) {
      return false;
    }

    // Has the scroll reached its target?
    const isAtTarget =
      contentsRef.current.scrollLeft === scrollingToPosition.current;

    const { width } = contentsRef.current.getBoundingClientRect();

    // Scrolling left
    if (
      // The target scroll goes off the left edge of the container
      scrollingToPosition.current <= 0 &&
      // The container has scrolled all the way to the let
      contentsRef.current.scrollLeft <= 0
    ) {
      // The intended scroll goes off the left edge of the container. Therefore it can't scroll further
      return false;
    }

    // Scrolling right
    if (
      // Target scroll is to the right of the current position
      scrollingToPosition.current > contentsRef.current.scrollLeft &&
      // The target scroll goes off the right edge of the container
      scrollingToPosition.current >= contentsRef.current.scrollWidth - width &&
      // The element is already at the right edge of the container
      contentsRef.current.scrollLeft >= contentsRef.current.scrollWidth - width
    ) {
      // The intended scroll goes off the right edge of the container. Therefore it can't scroll further
      return false;
    }

    // If the scroll is still within the bounds of the container and hasn't hit an edge, then return whether it hasn't
    // hit its target
    return !isAtTarget;
  };

  /**
   * Sets a given index as current, and jumps to its item
   * @param index The index to jump to
   */
  const setAndJumpToIndex = (index: number): void => {
    const element = findElement(index);

    if (!contentsRef.current || !element) {
      console.warn("Trying to scroll to non-existent element");
      return;
    }

    const { left: containerLeft } = contentsRef.current.getBoundingClientRect();
    const { left: elementLeft } = element.getBoundingClientRect();

    setActivePointIndex(index);
    scrollingToPosition.current =
      contentsRef.current.scrollLeft + elementLeft - containerLeft;

    contentsRef.current.scroll({
      behavior: "smooth",
      left: scrollingToPosition.current
    });
  };

  /**
   * Gets the leftmost element that will be visible after a given container has been scrolled to a new position
   *
   * @param elements Child elements to search over to find the new leftmost-positioned element
   * @param containerLeft The left position of the container element which is being scrolled
   * @param scrollLeft The new scrollLeft position the contianer is being scrolled to
   * @returns The index of the leftmost element after the new scroll position, or -1 if none are found
   */
  const getLeftmostElementIndex = (
    elements: Element[],
    containerLeft: number,
    scrollLeft: number
  ): number =>
    elements.findIndex((element) => {
      const { left } = element.getBoundingClientRect();
      return (
        left + contentsRef.current!.scrollLeft - containerLeft >= scrollLeft
      );
    });

  /**
   * Scrolls the container right by its width, or to the start if it hasn't been fully scrolled one width's amount to the
   * right.
   */
  const handleLeftArrowClicked = (): void => {
    if (contentsRef.current === null) return;

    const { width: containerWidth, left: containerLeft } =
      contentsRef.current.getBoundingClientRect();

    const scrollLeft = Math.max(
      contentsRef.current.scrollLeft - containerWidth,
      0
    );

    let newIndex: number;

    if (scrollLeft <= 0) {
      // Scrolling off the left edge of the container, so default to the first element
      newIndex = 0;
    } else {
      // Find the first element that is to the left of the current scroll position
      newIndex = getLeftmostElementIndex(
        [...contentsRef.current.children],
        containerLeft,
        scrollLeft
      );
    }

    if (newIndex === -1) return;

    scrollingToPosition.current = scrollLeft;
    contentsRef.current.scroll({ behavior: "smooth", left: scrollLeft });
    setActivePointIndex(newIndex);
  };

  /**
   * Scrolls the container left by its width, or to the ebd if it hasn't been fully scrolled one width's amount to the
   * left.
   */
  const handleRightArrowClicked = (): void => {
    if (contentsRef.current === null) return;

    const { width: containerWidth, left: containerLeft } =
      contentsRef.current.getBoundingClientRect();

    const scrollLeft = Math.min(
      contentsRef.current.scrollLeft + containerWidth,
      contentsRef.current.scrollWidth
    );

    let newIndex: number;

    if (scrollLeft >= contentsRef.current.scrollWidth - containerWidth) {
      // Scrolling off the right edge of the container, so default to the last element
      newIndex = props.items.length - 1;
    } else {
      // Find the first element that is to the right of the current scroll position
      newIndex = getLeftmostElementIndex(
        [...contentsRef.current.children],
        containerLeft,
        scrollLeft
      );
    }

    if (newIndex === -1) return;

    scrollingToPosition.current = scrollLeft;
    contentsRef.current.scroll({ behavior: "smooth", left: scrollLeft });
    setActivePointIndex(newIndex);
  };

  /** Fires when a navigation point is clicked
   * @param index The index of the point that was clicked
   */
  const handlePointClick = (index: number): void => {
    // Don't scroll again while mid-scroll
    if (activePointIndex === index) return;

    setAndJumpToIndex(index);
  };

  const handleContentsScroll = (e: React.UIEvent): void => {
    // Don't scroll if the container is unscrollable, it hasn't rendered or is still undertaking a manual scroll
    if (!props.scrollable || !contentsRef.current || isStillScrolling()) return;

    // Check if this scroll event was initiated by a manual scroll
    const wasScrolling = scrollingToPosition.current !== null;

    // Mark that the container is no longer scrolling
    scrollingToPosition.current = null;

    // The last scroll from a manual scroll will fire this event, so we still want to mark that the contanier has
    // finished scrolling, but not perform any of the new index calculations since the index will have been already set
    if (wasScrolling) return;

    const { scrollLeft } = e.currentTarget;

    const rect = contentsRef.current.getBoundingClientRect();

    const newElementIndex = getLeftmostElementIndex(
      [...contentsRef.current.children],
      rect.left,
      scrollLeft
    );

    if (scrollLeft >= contentsRef.current.scrollWidth - rect.width) {
      setActivePointIndex(props.items.length - 1);
    } else if (scrollLeft === 0) {
      setActivePointIndex(0);
    } else if (newElementIndex > -1) {
      setActivePointIndex(newElementIndex);
    }
  };

  return (
    <Flex
      flexDirection="column"
      className="scrollable-container"
      marginBetween="24px"
      css={(theme) => ({
        backgroundColor: theme.color.backgroundLight
      })}
    >
      {props.title && (
        <Flex flexDirection="row" width="100%" justifyContent="space-between">
          <DisplayHeading>{props.title}</DisplayHeading>
          <Arrows
            onLeftClick={handleLeftArrowClicked}
            onRightClick={handleRightArrowClicked}
          />
        </Flex>
      )}
      <Flex
        ref={contentsRef}
        flex="1"
        width="100%"
        className="scrollable-container-content"
        onScroll={handleContentsScroll}
      >
        {props.items.map(props.renderItem)}
      </Flex>

      <Flex
        className="scrollable-container-index-points"
        justifyContent="center"
        marginBetween="4px"
        width="100%"
      >
        <IndexPoints points={points} onPointClick={handlePointClick} />
      </Flex>
    </Flex>
  );
};
