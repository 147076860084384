import { ComponentPropsWithoutRef } from "react";

export const IconQuestion = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 15C11.8438 15 15 11.875 15 8C15 4.15625 11.8438 1 8 1C4.125 1 1 4.15625 1 8C1 11.875 4.125 15 8 15ZM7.5 10.75C7.90625 10.75 8.25 11.0938 8.25 11.5C8.25 11.9375 7.90625 12.25 7.5 12.25C7.0625 12.25 6.75 11.9375 6.75 11.5C6.75 11.0938 7.0625 10.75 7.5 10.75ZM8.90625 4C10.0625 4 11 4.9375 11 6.09375C11 6.84375 10.5625 7.53125 9.90625 7.90625L8.03125 8.84375C8 8.875 8 8.90625 8 8.9375V9.5C8 9.78125 7.75 10 7.5 10C7.21875 10 7 9.78125 7 9.5V8.9375C7 8.53125 7.21875 8.15625 7.5625 7.96875L9.4375 7.03125C9.78125 6.84375 10 6.46875 10 6.09375C10 5.5 9.5 5 8.90625 5H7.0625C6.46875 5 6 5.5 6 6.09375V6.375C6 6.65625 5.75 6.875 5.5 6.875C5.21875 6.875 5 6.65625 5 6.375V6.09375C5 4.9375 5.90625 4 7.0625 4H8.90625Z"
      fill="currentColor"
    />
  </svg>
);
