import { useLayoutEffect, useMemo, useRef } from "react";
import {
  type ProductAvailability as IProductAvailability,
  type Product,
  type Price,
  asyncIsSuccess
} from "@mh/api";
import { SafeImg, ProductAvailability, Spinner } from "@mh/components";
import { useBasket, getProductStatus, type ProductStatus } from "@mh/basket";
import { ProductPrice } from "./ProductPrice";

interface ShopProductProps {
  /** The {@link Product} to show information for */
  product: Product;
  price: Price | null;
  availability: IProductAvailability | null;
}

export const ShopProduct = ({
  product,
  price,
  availability
}: ShopProductProps): JSX.Element => {
  const {
    lines,
    addingProducts,
    removingLines,
    setQuantity,
    addItem,
    removeItem
  } = useBasket();
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);

  useLayoutEffect(() => {
    if (!descriptionRef.current) {
      return;
    }

    // This is a hacky way of hiding any empty description paragraphs. Many description paragraphs imported via the OTC
    // bulk import are blank. While they are blank however, they contain the text "&nbsp;" and will appear as a blank
    // row. As a result, attempting to hide them via a CSS rule like "> p:not(:has(*))" (hide any paragraphs with no
    // content) will also hide paragraphs which actually contain text.
    //
    // This will check if a paragraph is effectively empty, and give it a "display: none;" style

    // Get all paragraphs with no children, and empty text
    const emptyChildren = [
      ...descriptionRef.current.querySelectorAll("p")
    ].filter(
      (child) => child.children.length === 0 && !child.textContent?.trim()
    );

    for (const child of emptyChildren) {
      child.style.display = "none";
    }
  }, []);

  const { line, isInBasket, isPending } = useMemo<ProductStatus>(
    () => getProductStatus({ lines, addingProducts, removingLines }, product),
    [lines, addingProducts, removingLines, product]
  );

  const handleSetQuantity = (quantity: number): void => {
    if (!isInBasket) {
      // Product does not exist inside the basket and cannot have its quantity changed
      return;
    }

    /** Find the index of this basket line */
    const index =
      line && asyncIsSuccess(lines)
        ? lines.data.findIndex((l) => l.id === line.id)
        : -1;

    if (index > -1) {
      setQuantity(index, quantity);
    }
  };

  /**
   * Adds a product to the basket, or removes it if it already exists
   * @param quantity If adding a product, the quantity of it to add
   */
  const handleAddOrRemoveProduct = (quantity: number): void => {
    if (line && isInBasket) {
      // Product already exists inside the basket, remove it
      removeItem(line.id);
      return;
    }

    addItem(product, quantity);
  };

  return (
    <div
      css={(theme) => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "16px",
        [theme.mq.md]: { flexDirection: "row", height: "56dvh" }
      })}
    >
      <div
        css={(theme) => ({
          flex: "1",
          height: "400px",
          "& > img": {
            objectFit: "contain",
            height: "400px",
            width: "100%",
            [theme.mq.md]: { height: "100%" }
          }
        })}
      >
        <SafeImg
          imagePath={
            product.images.length ? product.images[0].original : undefined
          }
        />
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100%",
          gap: "12px"
        }}
      >
        <div>
          {availability ? (
            <ProductAvailability availability={availability} />
          ) : (
            <Spinner size="sm" />
          )}
        </div>
        <h1>{product.title}</h1>
        <p
          ref={descriptionRef}
          dangerouslySetInnerHTML={{
            __html: product.description // Strings come from our own server
          }}
          css={(theme) => ({
            [theme.mq.md]: {
              overflowY: "auto"
            }
          })}
        />
        <ProductPrice
          price={price}
          isPending={isPending}
          isInBasket={isInBasket}
          maxQuantity={product.max_repeats}
          onQuantityChange={handleSetQuantity}
          onAddOrRemove={handleAddOrRemoveProduct}
        />
      </div>
    </div>
  );
};
