import type { ComponentPropsWithoutRef } from "react";

export const IconSearch = ({
  fill,
  ...props
}: ComponentPropsWithoutRef<"svg">): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.7656 22.7344C24.0469 23.0156 24.0469 23.5312 23.7656 23.8125C23.625 23.9531 23.4375 24 23.25 24C23.0156 24 22.8281 23.9531 22.6406 23.8125L16.0312 17.1562C14.2969 18.6562 12.0938 19.5 9.70312 19.5C4.35938 19.5 0 15.1406 0 9.75C0 4.40625 4.3125 0 9.70312 0C15.0469 0 19.4531 4.40625 19.4531 9.75C19.4531 12.1875 18.6094 14.3906 17.1094 16.125L23.7656 22.7344ZM9.75 18C14.2969 18 18 14.3438 18 9.75C18 5.20312 14.2969 1.5 9.75 1.5C5.15625 1.5 1.5 5.20312 1.5 9.75C1.5 14.2969 5.15625 18 9.75 18Z"
      fill={fill ?? "#737373"}
    />
  </svg>
);
