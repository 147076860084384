import React, { createContext, useEffect, useState } from "react";

import { ConcreteAPI } from "../api";

export interface ApiResourceContextValue<DataType extends Record<string, any>> {
  isFetching: boolean;
  data: DataType | null;
  response: Response | null;
  error: unknown | null;
  get: () => Promise<void>;
  update: (v: Partial<DataType>) => Promise<void | Partial<DataType>>;
}

export const createApiResourceContext = <DataType extends Record<string, any>>(
  defaultValues: ApiResourceContextValue<DataType>
) => createContext(defaultValues);

export interface ApiResourceContextProviderProps {
  children: React.ReactNode;
  api: ConcreteAPI;
}

export const createApiResourceContextProvider =
  <DataType extends Record<string, any>>(
    context: React.Context<ApiResourceContextValue<DataType>>
  ) =>
  ({ children, api }: ApiResourceContextProviderProps) => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [response, setResponse] = useState<Response | null>(null);
    const [error, setError] = useState<unknown | null>(null);
    const [data, setData] = useState<DataType | null>(null);

    useEffect(() => {
      const updateData = async () => {
        if (response) {
          try {
            setData(await response.json());
          } catch (e) {
            setData(null);
          }
        } else {
          setData(null);
        }
      };
      updateData();
    }, [response]);

    const reset = () => {
      setIsFetching(false);
      setResponse(null);
      setError(null);
      setData(null);
    };

    const get = async () => {
      reset();
      setIsFetching(true);
      try {
        setResponse(await api.get());
        setError(null);
      } catch (e) {
        setResponse(null);
        setError(e);
      }
      setIsFetching(false);
    };

    const update = async (body: Partial<DataType>) => {
      // make a copy to refill a form on expected errors
      const currentData = { ...data };

      reset();
      setIsFetching(true);
      try {
        const response = await api.body(body).patch();
        if (response.ok) {
          setResponse(response);
          setError(null);
        } else if (response.status === 400) {
          return await response.json();
        } else if ([404, 503].includes(response.status)) {
          setError(await response.text());
          // @ts-ignore
          setData(currentData);
        }
      } catch (e) {
        setResponse(null);
        setError(e);
      } finally {
        setIsFetching(false);
      }
    };

    return (
      <context.Provider
        value={{ isFetching, response, error, data, get, update }}
      >
        {children}
      </context.Provider>
    );
  };
