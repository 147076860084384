import { API } from "../api";
import { StripeSetupIntent } from "./types";

export class StripeAPI {
  /** Returns a setup intent the client can use for Stripe payments
   * @returns A {@link StripeSetupIntent} whose client_secret which can be used for Stripe setup
   */
  static getSetupIntent = async (): Promise<StripeSetupIntent> =>
    API.url("/shop/api/secret_intent/")
      .get()
      .then((r) => r.json());
}
