import { type ComponentPropsWithoutRef } from "react";
import { Flex } from "../Flex";
import { IconSearch } from "../icons";

interface Props extends ComponentPropsWithoutRef<"input"> {}

export const Search = (props: Props): JSX.Element => (
  <Flex
    alignItems="center"
    marginBetween="8px"
    css={{
      height: "48px",
      marginBottom: "16px",
      padding: "12px 0 12px 16px",
      borderRadius: "4px",
      color: "#737373",
      backgroundColor: "#F8F8F8"
    }}
  >
    <IconSearch />
    <input
      type="text"
      placeholder="Search"
      css={{
        flex: 1,
        color: "inherit",
        backgroundColor: "inherit",
        border: "none",
        ":focus-visible": { outline: "none" }
      }}
      data-testid="search"
      {...props}
    />
  </Flex>
);
