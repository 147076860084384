import { useState, useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { Validation } from "@mh/core";

import { useQContext } from "@mh/questionnaire/src/hooks";

import { QuestionnaireContentComponentProps } from ".";

export const QCName = (props: QuestionnaireContentComponentProps) => {
  const { setLocalResponseValidationError } = useQContext();
  const { first_name, last_name } = props.initialValue ?? {};

  const [firstName, setFirstName] = useState<string>(first_name ?? "");
  const [lastName, setLastName] = useState<string>(last_name ?? "");

  useEffect(() => {
    const validation =
      Validation.name({ name: "First name" }).validate(firstName) ||
      Validation.name({ name: "Last name" }).validate(lastName);

    if (firstName === "" && lastName === "") {
      setLocalResponseValidationError(null);
      props.onChange(undefined);
    } else if (validation !== null) {
      setLocalResponseValidationError(validation);
      props.onChange(undefined);
    } else {
      setLocalResponseValidationError(null);
      props.onChange({
        first_name: firstName,
        last_name: lastName
      });
    }
  }, [firstName, lastName]);

  return (
    <Row>
      <Form.Control
        onChange={(event) => setFirstName(event.target.value)}
        placeholder="First Name"
        value={firstName}
        autoFocus
      />
      <span style={{ height: 10, width: 10 }} />
      <Form.Control
        onChange={(event) => setLastName(event.target.value)}
        placeholder="Last Name"
        value={lastName}
      />
    </Row>
  );
};
