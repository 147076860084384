import React from "react";
import { Form, FormControlProps } from "react-bootstrap";

import { EditableBase, EditableBaseProps } from "./EditableBase";

export type EditableFormCheckboxProps = Omit<FormControlProps, "value"> & {
  value?: boolean;
} & EditableBaseProps;

const Checkbox = (
  props: Pick<EditableFormCheckboxProps, "onChange" | "value"> & {
    disabled: boolean;
  }
) => {
  const { disabled, onChange, value } = props;
  return (
    <Form.Check.Input disabled={disabled} onChange={onChange} checked={value} />
  );
};

export const EditableFormCheckbox = (props: EditableFormCheckboxProps) => {
  const { isEditing, nonEditableComponent, ...formControlProps } = props;
  return (
    <EditableBase
      isEditing={isEditing}
      nonEditableComponent={
        nonEditableComponent || (
          <Checkbox {...formControlProps} disabled={true} />
        )
      }
    >
      <Checkbox {...formControlProps} disabled={false} />
    </EditableBase>
  );
};
