import { Category } from "@mh/api";
import { Link } from "react-router-dom";

interface Props {
  category: Category;
}

export default function QuestionnaireDisabledMessage({ category }: Props) {
  // if we have a disabled_reason from the backend, use it, otherwise return a simple message
  const disabledReason =
    category.disabled_reason ??
    "This questionnaire is currently not available.";

  return (
    <>
      <div className="text-center">{disabledReason}</div>
      <Link to="/treatments">
        <button className="btn btn-primary w-100 mt-2" type="button">
          Back to the Portal
        </button>
      </Link>
    </>
  );
}
