import { useState } from "react";
import { useTheme } from "@emotion/react";
import { IconMembership, MembershipPanel } from "@mh/components";

import { MemberBenefits } from "./MemberBenefits";
import { PaymentTypeOption } from "./PaymentTypeOption";
import { useMembership } from "../../../../packages/hooks";

interface CheckoutPaymentTypeProps {
  type: string;
  onChange: (value: string) => void;
  membershipFee: string;
  memberSaving: string;
}

export const CheckoutPaymentType = ({
  type,
  onChange,
  membershipFee,
  memberSaving
}: CheckoutPaymentTypeProps) => {
  const theme = useTheme();
  const membershipsStatus = useMembership();
  const [expanded, setExpanded] = useState<boolean>(false);

  return membershipsStatus === "active" ? (
    <MembershipPanel
      isCheckout={true}
      text={`As a hubPass member, you are saving $${memberSaving} on this order!`}
    />
  ) : membershipsStatus === "offer" ? (
    <PaymentTypeOption
      id="membership-fee-monthly"
      value={type}
      title={`Save $${memberSaving} with hubPass`}
      feeDescription="Monthly fee (cancel anytime)"
      fee={membershipFee}
      subtitle="Get free unlimited consults and exclusive prices on your medication."
      optionCss={(theme) => ({
        backgroundColor: theme.color.background,
        borderTop: `2px solid ${theme.color.primary}`
      })}
      onChange={onChange}
      icon={
        <IconMembership width={40} height={40} fill={theme.color.primary} />
      }
      expanded={expanded}
      inputType="checkbox"
    >
      <MemberBenefits
        expanded={expanded}
        onExpandClick={() => setExpanded(!expanded)}
        membershipFee={membershipFee || "0"}
      />
    </PaymentTypeOption>
  ) : (
    <></>
  );
};
