import { ComponentPropsWithoutRef } from "react";

export const IconArrowLeft = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8877 9.33203C14.44 9.33203 14.8877 8.88431 14.8877 8.33203C14.8877 7.77975 14.44 7.33203 13.8877 7.33203L13.8877 9.33203ZM1.18059 7.62492C0.790064 8.01545 0.790064 8.64861 1.18059 9.03914L7.54455 15.4031C7.93507 15.7936 8.56824 15.7936 8.95876 15.4031C9.34929 15.0126 9.34929 14.3794 8.95876 13.9889L3.30191 8.33203L8.95876 2.67518C9.34929 2.28465 9.34929 1.65149 8.95876 1.26096C8.56824 0.870438 7.93507 0.870439 7.54455 1.26096L1.18059 7.62492ZM13.8877 7.33203L1.8877 7.33203L1.8877 9.33203L13.8877 9.33203L13.8877 7.33203Z"
      fill={props.fill || "white"}
    />
  </svg>
);
