import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import { BRAND } from "@mh/core";
import { Clinician } from "@mh/api";

interface ClinicianLayoutProps {
  clinician?: Clinician;
  isHeader: boolean;
}

interface ClinicianDropBoxProps {
  availableClinicians?: Clinician[];
  clinicianId?: number;
  setClinicianId: (id: number) => void;
}

export const ClinicianLayout = (props: ClinicianLayoutProps) => {
  const commonInfoTextStyle = {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    color: "black"
  };
  return (
    <div className="d-flex flex-row">
      {
        // @ts-ignore
        props.clinician?.id > -1 && (
          <div className="d-flex flex-column" css={{ marginRight: "8px" }}>
            <img
              width={32}
              height={32}
              src={
                props.clinician?.profile_image ||
                "/assets/images/com/doctor.png"
              }
              css={{ borderRadius: "50%" }}
            />
          </div>
        )
      }
      <div className="d-flex flex-column">
        <div
          css={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "135%",
            wordBreak: "break-all",
            textWrap: "wrap"
          }}
        >
          {props.clinician?.formal_name || "All clinicians"}
        </div>
        {
          // @ts-ignore
          props.clinician?.id > -1 && props.clinician?.qualifications && (
            <div
              css={{
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                textTransform: "uppercase",
                marginBottom: props.isHeader ? "0px" : "8px"
              }}
            >
              {
                // @ts-ignore
                props.clinician?.qualifications
              }
            </div>
          )
        }
        {!props.isHeader &&
          // @ts-ignore
          props.clinician?.id > -1 &&
          // @ts-ignore
          (props.clinician?.first_availability ? (
            <div className="d-flex flex-column">
              <div css={commonInfoTextStyle}>Appointments available from</div>
              <div
                css={(theme) => ({
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                  color: theme.color.primary
                })}
              >
                {moment(
                  moment
                    // @ts-ignore
                    .utc(props?.clinician?.first_availability)
                    .tz(moment.tz.guess())
                ).format("ddd, MMM D, h:mm A")}
              </div>
            </div>
          ) : (
            <div css={commonInfoTextStyle}>No appointments available</div>
          ))}
      </div>
    </div>
  );
};

const AllClinicians = [
  {
    id: -1
  }
];

export const ClinicianDropBox = (props: ClinicianDropBoxProps) => {
  const newClinician =
    props?.availableClinicians && props?.availableClinicians[0]?.formal_name
      ? [...AllClinicians, ...props.availableClinicians]
      : AllClinicians;

  const items = newClinician.map((clinician, index) => (
    <Dropdown.Item
      css={(theme) => ({
        "&.disabled": {
          background: "#e9ecef !important"
        },
        "::-webkit-scrollbar": {
          position: "absolute",
          right: "-10px",
          width: "100px"
        },
        padding: "12px",
        color: "#000000",
        lineHeight: "normal",
        borderBottom:
          "0.5px solid var(--Components-Divider-Divider-Background, #D9D9D9)",
        "&:hover": {
          backgroundColor: "#F0F0F0"
        },
        "&:active": {
          color: "#FFFFFF",
          backgroundColor: theme.color.primary
        }
      })}
      key={index}
      onClick={() => {
        // @ts-ignore
        props.setClinicianId(clinician.id);
      }}
    >
      {/* @ts-ignore */}
      <ClinicianLayout clinician={clinician} isHeader={false} />
    </Dropdown.Item>
  ));

  // @ts-ignore
  const currentClinician = newClinician.find((c) => c.id === props.clinicianId);

  return (
    <div className="clinician-select">
      <Dropdown
        css={(theme) => ({
          button: {
            justifyContent: "space-between",
            alignItems: "center",
            [theme.mq.md]: {
              width: "300px"
            },
            "&:after": {
              position: "absolute",
              fontSize: "20px",
              right: "12px",
              top: "50%",
              transform: "translateY(-50%)",
              [theme.mq.md]: {
                right: "15px"
              }
            },
            "&.show": {
              "&:after": {
                transform: "translateY(-25%) rotate(180deg)"
              }
            },
            "&.show, &:hover, &:active": {
              background: "unset !important",
              color: "unset !important",
              border: "0.5px #737373 solid"
            }
          }
        })}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          css={(theme) => ({
            position: "relative",
            width: "100%",
            padding: "12px",
            background:
              // @ts-ignore
              currentClinician?.id === -1 ? theme.color.third : "#FFFFFF",
            // @ts-ignore
            color:
              currentClinician?.id === -1 && BRAND !== "hubhealth"
                ? "#FFFFFF"
                : "#000000",
            border: "unset",
            borderRadius: "50px",
            boxShadow: "none",
            "&:hover, &:focus": {
              border: "1px solid #808285 !important",
              boxShadow: "none"
            },
            [theme.mq.md]: {
              borderWidth: "0.5px"
            }
          })}
        >
          <div
            css={{
              textAlign: "left"
            }}
          >
            {/* @ts-ignore */}
            <ClinicianLayout clinician={currentClinician} isHeader={true} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          /* @ts-ignore */
          placement="top"
          /* @ts-ignore */
          dropupauto="false"
          data-dropup-auto="false"
          css={(theme) => ({
            top: "0px !important",
            width: "100%",
            paddingBottom: "0px",
            maxHeight: "400px",
            overflowY: "auto",
            [theme.mq.md]: {
              maxHeight: "406px"
            }
          })}
        >
          {items}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
