import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { PatientAPI, generateEscript } from "@mh/api";
import { Validation } from "@mh/core";
import { Button, Modal, IconTriangleExclamation, Toast } from "@mh/components";

interface IHIModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  setShowAccesseScript?: (show: boolean) => void;
  refreshPage?: boolean;
  consultationId?: number | null | undefined;
  from?: "afterHoursBooking";
}

export const IHIModal = ({
  show,
  setShow,
  setShowAccesseScript = () => {},
  refreshPage,
  consultationId,
  from
}: IHIModalProps) => {
  const [showsAfterSveModal, setShowAfterSaveModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [ihi, setIhi] = useState<string | null>(null);

  const navigate = useNavigate();

  return (
    <>
      {!showsAfterSveModal && (
        <Modal show={show} variant="success" onHide={() => setShow(false)}>
          <Modal.Title
            buttonCss={{ padding: "0px" }}
            onClose={() => setShow(false)}
          >
            Individual Healthcare Identifier (IHI)
          </Modal.Title>
          <div
            css={() => ({
              display: "flex",
              flexDirection: "column"
            })}
          >
            <div
              css={{
                fontWeight: "400",
                paddingBottom: "24px"
              }}
            >
              {from === "afterHoursBooking"
                ? "Please provide a valid IHI to request an after-hours doctors consultation."
                : "To generate an eScript you will need to provide a valid IHI."}
            </div>
            <a
              css={{
                fontWeight: "400",
                color: "#000",
                paddingBottom: "16px"
              }}
              href="https://www.nib.com.au/the-checkup/the-individual-health-identifier-ihi"
              rel="noreferrer"
              target="_blank"
            >
              Learn how to access or request your IHI.
            </a>
            <div>
              <label
                className="noAsteriskPadding"
                css={{
                  paddingBottom: "4px",
                  fontSize: "14px",
                  fontWeight: "500"
                }}
                htmlFor="systolic"
              >
                Enter IHI number
              </label>
              <input
                css={{
                  maxWidth: "100%",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  height: "40px",
                  marginBottom: "0px"
                }}
                required
                id="ihinumber"
                type="text"
                placeholder="IHI number"
                className="input"
                onChange={async (e) => {
                  setIhi(e.target?.value);
                }}
              />
            </div>
          </div>
          <Modal.Actions>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                setIsSuccess(false);
                if (ihi && !Validation.ihi().validate(ihi)) {
                  const isSuccess = await PatientAPI.updateIHINumber(ihi);
                  setIsSuccess(isSuccess);
                  setShowAccesseScript(!isSuccess);
                  if (isSuccess && consultationId) {
                    const result = await generateEscript(consultationId);
                    if (result?.message?.includes("eScripts successfully")) {
                      Toast.success(
                        "You can now access your eScript by selecting the “View documents” button."
                      );
                    } else {
                      Toast.success(
                        result?.message ??
                          "Not all of your scripts could be generated, contact patient support or try again later."
                      );
                    }
                  }
                  if (isSuccess && refreshPage) {
                    setShowAccesseScript(false);
                    setTimeout(() => {
                      navigate(0);
                    }, 5000);
                  }
                }
                setShowAfterSaveModal(true);
              }}
              variant="primary"
              css={(theme) => ({
                width: "100% !important",
                marginBottom: "24px",
                [theme.mq.md]: {
                  marginBottom: "0x"
                }
              })}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {showsAfterSveModal ? (
        isSuccess ? (
          Toast.success(
            "Your IHI is successfully verified. You can now access your eScript by selecting the “View Documents” button."
          )
        ) : (
          <Modal show={showsAfterSveModal} onHide={() => setShow(false)}>
            <Modal.Title buttonCss={{ padding: "0px" }}>
              <div
                css={{
                  fontSize: "20px",
                  fontWeight: "500"
                }}
              >
                <IconTriangleExclamation
                  css={(theme) => ({
                    color: theme.color.danger
                  })}
                  width={32}
                  height={32}
                />
                <span
                  css={{
                    paddingLeft: "16px",
                    display: "inline-block",
                    verticalAlign: "middle"
                  }}
                >
                  IHI provided did not match
                </span>
              </div>
            </Modal.Title>
            <>
              <div
                css={() => ({
                  display: "flex",
                  flexDirection: "column"
                })}
              >
                <div
                  css={{
                    fontWeight: "400",
                    paddingTop: "16px"
                  }}
                >
                  The IHI provided did not match. Please ensure that the IHI
                  number entered is correct and matches the associated details,
                  such as your name and date of birth.
                </div>
              </div>
              <div
                className="d-flex flex-column flex-md-row"
                css={(theme) => ({
                  paddingTop: "48px",
                  paddingBottom: "48px !important",
                  [theme.mq.md]: {
                    paddingTop: "24px",
                    paddingBottom: "24px !important"
                  }
                })}
              >
                <div css={{ width: "100%" }}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAfterSaveModal(false);
                    }}
                    variant="primary"
                    css={(theme) => ({
                      marginBottom: "16px",
                      width: "100% !important",
                      borderColor: `${theme.color.primary} !important`,
                      background: "white",
                      input: {
                        accentColor: "white",
                        background: "white",
                        color: "black"
                      },
                      color: "#000",
                      "&:hover, &:active, &.active, &.checked-bg": {
                        color: "#fff",
                        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${theme.color.primary}`,
                        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                        "input[type='checkbox']": {
                          accentColor: theme.color.secondary,
                          background: "white",
                          color: "black",
                          [theme.mq.md]: {
                            accentColor: "white"
                          }
                        }
                      },
                      [theme.mq.md]: {
                        marginBottom: "0px"
                      }
                    })}
                  >
                    Re-enter IHI number
                  </Button>
                </div>
                <div
                  css={{
                    width: "16px"
                  }}
                ></div>
                <div css={{ width: "100%" }}>
                  <NavLink to={"/profile"}>
                    <Button
                      css={{
                        width: "100%"
                      }}
                    >
                      Review personal details
                    </Button>
                  </NavLink>
                </div>
              </div>
            </>
          </Modal>
        )
      ) : (
        ""
      )}
    </>
  );
};
