import { useState } from "react";
import { type UseGenericStep as IUseGenericStep } from "@mh/api";
import { Button, Spinner } from "@mh/components";

import type { TreatmentAcceptanceStepProps } from "../types";
import { WizardStep } from "./WizardStep";

interface UseGenericStepProps extends TreatmentAcceptanceStepProps {
  step: IUseGenericStep;
}

export const UseGenericStep = ({ step, onContinue }: UseGenericStepProps) => {
  const { generic_product: genericProduct } = step.data;

  /** If not null, denotes whether "Yes" or "No" have been selected. Used to show a spinner on the correct button */
  const [isSubmittingOption, setIsSubmittingOption] = useState<
    "yes" | "no" | null
  >(null);

  /** True if this step is submitting */
  const isSubmitting = !!isSubmittingOption;

  /** Stop double submission as that is not epic */
  const isNextDisabled = isSubmitting;

  return (
    <WizardStep
      heading="Are you happy with a generic medication?"
      footer={
        <>
          <Button
            variant="primary"
            disabled={isNextDisabled}
            css={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              [theme.mq.md]: {
                height: "54px",
                width: "120px",
                borderRadius: "4px"
              }
            })}
            onClick={() => {
              setIsSubmittingOption("yes");

              try {
                onContinue({ use_generic: true });
              } catch (e) {
                setIsSubmittingOption(null);
              }
            }}
          >
            {isSubmittingOption === "yes" ? <Spinner /> : "Yes"}
          </Button>
          <Button
            variant="primary-outline"
            disabled={isNextDisabled}
            css={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              [theme.mq.md]: {
                height: "54px",
                width: "120px",
                borderRadius: "4px"
              }
            })}
            onClick={() => {
              setIsSubmittingOption("no");

              try {
                onContinue({ use_generic: false });
              } catch (e) {
                setIsSubmittingOption(null);
              }
            }}
          >
            {isSubmittingOption === "no" ? <Spinner /> : "No"}
          </Button>
        </>
      }
    >
      <div
        css={(theme) => ({
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
          width: "100%",
          gap: "16px",
          [theme.mq.md]: {
            justifyContent: "flex-start",
            gap: "80px"
          }
        })}
      >
        {genericProduct && (
          <div
            css={(theme) => ({
              display: "flex",
              flexFlow: "column",
              gap: "16px",
              backgroundColor: theme.color.backgroundFaint,
              borderRadius: "4px",
              padding: "16px 12px"
            })}
          >
            <div
              css={{
                display: "flex",
                flexFlow: "column",
                gap: "8px"
              }}
            >
              <b>{genericProduct.title}</b>
              <span>Active ingredient: {genericProduct.parent_title}</span>
            </div>
            <span>
              Generic medications are available at a lower cost and contain the
              same active ingredients as a branded option. Select
              &lsquo;Yes&lsquo; if you want to proceed with the lowest cost
              medication available.
            </span>
          </div>
        )}
      </div>
    </WizardStep>
  );
};
