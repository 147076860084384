import { useState } from "react";
import { useTheme } from "@emotion/react";
import { IconClock, IconMembership, MembershipPanel } from "@mh/components";

import { MemberBenefits } from "./MemberBenefits";
import { PaymentTypeOption } from "./PaymentTypeOption";
import { useMembership } from "../../../hooks";

interface QuestionnairePaymentTypeProps {
  type: string;
  onChange: (value: string) => void;
  consultFee: string;
  membershipFee: string;
  hidePickConsultTime?: boolean;
}

const pickConsultTime = (
  <div
    css={(theme) => ({
      display: "flex",
      width: "97%",
      backgroundColor: "#FBFBFB",
      margin: "0px 5px 16px",
      padding: "16px",
      gap: "8px",
      borderRadius: "10px",
      [theme.mq.md]: {
        width: "100%"
      }
    })}
  >
    <div>
      <IconClock
        width={32}
        height={32}
        css={(theme) => ({ color: theme.color.primary })}
      />
    </div>
    <span css={{ fontSize: "16px", fontWeight: "600", color: "#404040" }}>
      Once you submit payment, you will be able to pick a time for your
      consultation.
    </span>
  </div>
);

export const QuestionnairePaymentType = ({
  type,
  onChange,
  consultFee,
  membershipFee,
  hidePickConsultTime
}: QuestionnairePaymentTypeProps) => {
  const theme = useTheme();
  const membershipsStatus = useMembership();

  const [expanded, setExpanded] = useState<boolean>(false);

  return membershipsStatus === "active" ? (
    <div className="row">
      <MembershipPanel text="This consultation is included as a benefit of your hubPass membership - no payment required." />
    </div>
  ) : membershipsStatus === "offer" ? (
    <div className="row">
      <PaymentTypeOption
        id="pay"
        value={type}
        inputType="radio"
        title="Pay for your consultation"
        feeDescription="Medical Consultation"
        fee={consultFee || "0"}
        onChange={onChange}
      />
      <PaymentTypeOption
        id="membership-fee-monthly"
        value={type}
        inputType="radio"
        title="Save with hubPass"
        feeDescription="Monthly fee (cancel anytime)"
        fee={membershipFee || "0"}
        subtitle="Get free unlimited consults and exclusive prices on your medication."
        optionCss={(theme) => ({
          backgroundColor: theme.color.background,
          borderTop: `2px solid ${theme.color.primary}`
        })}
        onChange={onChange}
        icon={<IconMembership fill={theme.color.primary} />}
        expanded={expanded}
      >
        <MemberBenefits
          expanded={expanded}
          onExpandClick={() => setExpanded(!expanded)}
          membershipFee={membershipFee || "0"}
        />
      </PaymentTypeOption>
      {!hidePickConsultTime && pickConsultTime}
    </div>
  ) : (
    <></>
  );
};
