import { useEffect, useState } from "react";
import { User } from "@mh/api";

import Notificationtemplate from "./notificationtemplate";
import Base from "./basenotification";
import { Toast } from "@mh/components";

export default function NewNotifications(props) {
  const [data_, dataSet] = useState();

  const repeatsQuestionnaire = (param) => async (e) => {
    let url = `/questionnairerepeats/${
      param.product.toLowerCase() === "hair loss"
        ? "HL"
        : param.product.toLowerCase()
    }/${param.id}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    async function fetchMyAPI() {
      if (!User.loggedIn()) {
        Toast.error("Please log in to access this page.");
        User.logout();
        return false;
      }

      const response = await Base.getNotification(
        "newnotifications->useEffect"
      );
      let datarray = [];

      if (response === "logout") {
        User.logout();
      }
      if (!response || response.iserror || response === "logout") {
        return false;
      }

      for (const key of Object.keys(response)) {
        if (response[key] && Array.isArray(response[key])) {
          for (let i = 0; i < response[key].length; i++) {
            response[key][i].type = key;
            datarray.push(response[key][i]);
          }
        }
      }

      if (datarray.length > 0) {
        dataSet(
          datarray.reverse().map((obj, i) => (
            <div key={i} className="outter-box">
              <Notificationtemplate
                data={{ obj }}
                fns={{
                  repeatsQuestionnaire,
                  isActiveRepeat: Base.isActiveRepeat
                }}
              />
            </div>
          ))
        );
      }
    }
    fetchMyAPI();
  }, []);

  return (
    <div className="my-notifications page">
      {data_}
      {!data_ && (
        <div className="nodata-box">
          <h4>Notifications will appear here when you have any</h4>
        </div>
      )}
    </div>
  );
}
