import { ComponentPropsWithoutRef, FC } from "react";

export const IconCart: FC<ComponentPropsWithoutRef<"svg">> = (props) => (
  <svg
    viewBox="0 0 29 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.20833 0.166464C4.55469 0.166464 4.85156 0.463339 4.95052 0.809693L5.19792 1.7498H27.0182C28.0573 1.7498 28.849 2.78886 28.5521 3.77844L25.8802 13.2784C25.6823 13.9712 25.0885 14.4165 24.3464 14.4165H8.06771L8.76042 17.5831H24.7917C25.1875 17.5831 25.5833 17.979 25.5833 18.3748C25.5833 18.8201 25.1875 19.1665 24.7917 19.1665H8.16667C7.77083 19.1665 7.47396 18.9191 7.375 18.5727L3.5651 1.7498H1.04167C0.596355 1.7498 0.250001 1.40344 0.250001 0.958131C0.250001 0.562298 0.596355 0.166464 1.04167 0.166464H4.20833ZM5.54427 3.33313L7.67188 12.8331H24.3464L27.0182 3.33313H5.54427ZM6.58333 22.729C6.58333 21.2446 7.82031 19.9581 9.35417 19.9581C10.8385 19.9581 12.125 21.2446 12.125 22.729C12.125 24.2628 10.8385 25.4998 9.35417 25.4998C7.82031 25.4998 6.58333 24.2628 6.58333 22.729ZM9.35417 23.9165C9.9974 23.9165 10.5417 23.4217 10.5417 22.729C10.5417 22.0857 9.9974 21.5415 9.35417 21.5415C8.66146 21.5415 8.16667 22.0857 8.16667 22.729C8.16667 23.4217 8.66146 23.9165 9.35417 23.9165ZM25.5833 22.729C25.5833 24.2628 24.2969 25.4998 22.8125 25.4998C21.2786 25.4998 20.0417 24.2628 20.0417 22.729C20.0417 21.2446 21.2786 19.9581 22.8125 19.9581C24.2969 19.9581 25.5833 21.2446 25.5833 22.729ZM22.8125 21.5415C22.1198 21.5415 21.625 22.0857 21.625 22.729C21.625 23.4217 22.1198 23.9165 22.8125 23.9165C23.4557 23.9165 24 23.4217 24 22.729C24 22.0857 23.4557 21.5415 22.8125 21.5415Z"
      fill="currentColor"
    />
  </svg>
);
