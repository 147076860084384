import { PhoneField } from "@mh/components";

import { ProfileField, ProfileForm } from "../ProfileForm";

export interface EmergencyContactNewProps {
  values: any;
  setValues: any;
  error: any;
  isEditing: any;
  id: any;
  isFetching: any;
}

export const EmergencyContactNew = ({
  values,
  setValues,
  error,
  isEditing,
  id,
  isFetching
}: EmergencyContactNewProps) => {
  return (
    <div className="emergency-contact">
      <div className="d-flex flex-row">
        <div className="w-100 d-flex flex-row">
          <ProfileForm.Control
            label="First name"
            fieldClass="first-name-field"
            data-testid={`${id}-firstname`}
            disabled={isFetching}
            error={error?.ec_first_name}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({ ...values, ec_first_name: e.currentTarget.value })
            }
            value={values.ec_first_name}
          />
          <ProfileForm.Control
            label="Last name"
            fieldClass="last-name-field"
            data-testid={`${id}-lastname`}
            disabled={isFetching}
            error={error?.ec_last_name}
            isEditing={isEditing}
            onChange={(e) =>
              setValues({ ...values, ec_last_name: e.currentTarget.value })
            }
            value={values.ec_last_name}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row"
        css={(theme) => ({
          [theme.mq.md]: {
            span: {
              width: "155px !important"
            }
          }
        })}
      >
        <ProfileField
          label={"Phone"}
          fieldClass="phone-field"
          error={error?.ec_phone}
        >
          <PhoneField
            readOnly={!isEditing}
            inputTestId={`${id}-phone`}
            onChange={(value) =>
              setValues({ ...values, ec_phone: value ?? "" })
            }
            value={values.ec_phone}
            className={error?.ec_phone !== undefined ? "is-invalid" : undefined}
          />
        </ProfileField>
      </div>
      <div
        className="w-100"
        css={(theme) => ({
          height: "1px",
          backgroundColor: "#D9D9D9",
          marginTop: "8px",
          [theme.mq.md]: {
            marginTop: "16px"
          }
        })}
      />
    </div>
  );
};
