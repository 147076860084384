export type Brand = "hubhealth" | "stagger" | "youly";

export enum Mode {
  DEV,
  STAGING,
  LIVE
}

const modeFromStr = (s: string): Mode =>
  s === "0" ? Mode.DEV : s === "1" ? Mode.STAGING : Mode.LIVE;

export const ENV: Mode = import.meta.env.VITE_STATE
  ? modeFromStr(import.meta.env.VITE_STATE)
  : Mode.LIVE;

export const HOST: string = import.meta.env.VITE_API_URL!;

export const BRAND = import.meta.env.VITE_TYPE! as Brand;

/* Path to the brand's logo image. */
export const LOGO: string = import.meta.env.VITE_LOGO!;

/* AusPost DigitalID configuration. */
export const DIGITAL_ID = {
  clientId: import.meta.env.VITE_DIGITALID_CLIENT_ID as string,
  sdk: import.meta.env.VITE_DIGITALID_SDK as string
};

export const BRAND_TO_NAME = {
  hubhealth: "hub.health",
  stagger: "Stagger",
  youly: "Youly"
};

/** Properly formatted brand name. */
export const BRAND_NAME = BRAND_TO_NAME[BRAND];

export const BRAND_HELP_EMAIL = {
  hubhealth: "help@hub.health",
  stagger: "help@stagger.com.au ",
  youly: "help@youly.com.au "
}[BRAND];
