export const IconErrorMonitor = (props: { color: string }) => {
  return (
    <svg viewBox="0 0 534 476" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11241_83231)">
        <g
          style={{ mixBlendMode: "multiply" }}
          opacity="0.62"
          filter="url(#filter0_f_11241_83231)"
        >
          <path
            d="M267 470.27C414.46 470.27 534 466.955 534 462.865C534 458.776 414.46 455.461 267 455.461C119.54 455.461 0 458.776 0 462.865C0 466.955 119.54 470.27 267 470.27Z"
            fill="#F0F0F0"
          />
        </g>
        <path
          d="M6.5625 325.307V379.983C6.5625 385.225 10.8493 389.488 16.0623 389.488H517.964C523.204 389.488 527.464 385.225 527.464 379.983V325.307H6.5625Z"
          fill="#F0F0F0"
        />
        <path
          d="M527.437 23.3884C527.437 18.1724 523.177 13.8831 517.938 13.8831H16.0623C10.8493 13.8831 6.5625 18.1459 6.5625 23.3884V325.307H527.464V23.3884H527.437Z"
          fill="black"
        />
        <path
          d="M357.685 389.488H178.512V444.534H357.685V389.488Z"
          fill="#D6D6D6"
        />
        <path
          d="M398.277 444.507H135.458V470.27H398.277V444.507Z"
          fill="#F0F0F0"
        />
        <path
          d="M357.685 389.488H178.512V409.372H357.685V389.488Z"
          fill="#BDBDBD"
        />
        <path
          d="M500.605 40.9957H33.4741V297.453H500.605V40.9957Z"
          fill={props.color}
        />
        <path
          d="M217.49 78.3726L184.995 110.886L152.473 78.3726L139.48 91.3728L171.976 123.913L139.48 156.427L152.473 169.454L184.995 136.914L217.49 169.454L230.483 156.427L197.988 123.913L230.483 91.3728L217.49 78.3726Z"
          fill="white"
        />
        <path
          d="M394.52 91.3728L381.527 78.3726L349.005 110.886L316.51 78.3726L303.518 91.3728L336.013 123.913L303.518 156.427L316.51 169.454L349.005 136.914L381.527 169.454L394.52 156.427L362.025 123.913L394.52 91.3728Z"
          fill="white"
        />
        <path
          d="M336.648 248.912C267.847 201.385 198.173 248.435 197.485 248.912L188.461 235.832C189.255 235.276 267.979 182.137 345.671 235.832L336.648 248.912Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_11241_83231"
          x="-15"
          y="440.461"
          width="564"
          height="44.8087"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="7.5"
            result="effect1_foregroundBlur_11241_83231"
          />
        </filter>
        <clipPath id="clip0_11241_83231">
          <rect
            width="534"
            height="475"
            fill="white"
            transform="translate(0 0.953369)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
