import { ComponentPropsWithoutRef } from "react";

export const IconCaretRight = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.03125 0.770874L7.75 6.73962C7.875 6.89587 7.96875 7.08337 7.96875 7.27087C7.96875 7.45837 7.875 7.64587 7.75 7.77087L2.03125 13.7396C1.75 14.0521 1.25 14.0521 0.96875 13.7709C0.65625 13.4896 0.65625 13.0209 0.9375 12.7084L6.1875 7.23962L0.9375 1.80212C0.65625 1.52087 0.65625 1.02087 0.96875 0.739624C1.25 0.458374 1.75 0.458374 2.03125 0.770874Z"
      fill="currentColor"
    />
  </svg>
);
