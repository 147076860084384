import { ComponentPropsWithoutRef } from "react";

export const IconAboutyou = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.01172 12.5557C5.01172 12.2121 5.29027 11.9336 5.63387 11.9336H14.3662C14.7098 11.9336 14.9884 12.2121 14.9884 12.5557C14.9884 14.3713 13.5166 15.843 11.7011 15.843H8.29898C6.48348 15.843 5.01172 14.3713 5.01172 12.5557Z"
      stroke="#38524E"
      strokeWidth="1.5"
    />
    <path
      d="M12.1358 7.33011C12.1358 8.50049 11.187 9.44928 10.0166 9.44928C8.84625 9.44928 7.89746 8.50049 7.89746 7.33011C7.89746 6.15972 8.84625 5.21094 10.0166 5.21094C11.187 5.21094 12.1358 6.15972 12.1358 7.33011Z"
      stroke="#38524E"
      strokeWidth="1.5"
    />
  </svg>
);
