import { API } from "../api";

export class ThirdPartyAPI {
  static getNIBMembershipNumber = async (): Promise<number | undefined> =>
    API.v2()
      .url("/third-party/nib")
      .get()
      .then((response) =>
        response.status === 200 ? response.json() : undefined
      )
      .then((json) => json?.nib_membership_number);
}
