import type { FC } from "react";
import {
  Button,
  IconCaretOutlineLeft,
  IconCaretOutlineRight
} from "@mh/components";

interface Props {
  className?: string;
  onLeftClick: () => void;
  onRightClick: () => void;
}

const Arrows: FC<Props> = ({ className, onLeftClick, onRightClick }) => {
  return (
    <div className={className} css={{ whiteSpace: "nowrap" }}>
      <Button onClick={onLeftClick} size="sm" variant="primary-plain">
        <IconCaretOutlineLeft
          css={{
            opacity: 0.5,
            width: "24px",
            height: "24px"
          }}
        />
      </Button>
      <Button active onClick={onRightClick} size="sm" variant="secondary-plain">
        <IconCaretOutlineRight
          css={{
            width: "24px",
            height: "24px"
          }}
        />
      </Button>
    </div>
  );
};

export default Arrows;
