import { Spinner } from "react-bootstrap";
import { User } from "@mh/api";
import { Flex } from "@mh/components";
import { getHealthIcons } from "./EmployeeHealthCheckSplashPage";

interface ScoreProps {
  code: string;
  score: number;
  rating: string;
}

interface SubmitProps {
  isLoggedIn: boolean;
  type: string;
  skipConsult?: boolean;
  idSuffix?: string;
}

interface EmployeeHealthCheckScorePageProps {
  firstName: string;
  totalScore: number;
  totalRating: string;
  scores: ScoreProps[];
  submit: (e: any, param: SubmitProps) => void;
}

const ScoreList = [
  {
    title: "Lifestyle",
    icon: "IconLifestyle",
    color: "#F19895",
    code: "lifestyle"
  },
  {
    title: "Nutrition",
    icon: "IconNutrition",
    color: "#90AEB2",
    code: "nutrition"
  },
  {
    title: "Physical Health",
    icon: "IconPhysicalHealth",
    color: "#BF835E",
    code: "physical_health"
  },
  {
    title: "Emotional Wellbeing",
    icon: "IconEmotionalWellbeing",
    color: "#9983A7",
    code: "emotional_wellbeing"
  }
];

const convertScoreRating = (rating: string, isHeader?: boolean) => {
  switch (rating) {
    case "excellent":
      return `Excellent${isHeader ? "!" : ""}`;
    case "good":
      return `Good${isHeader ? "!" : ""}`;
    case "could_be_improved":
      return "Could be improved";
    default:
      return "N/A";
  }
};

const SubmitBtStyle = () => (theme: any) => ({
  background: "#DD8E76",
  border: "none",
  borderRadius: "4px",
  color: "white",
  marginRight: "auto",
  marginLeft: "auto",
  padding: "16px 24px",
  gap: "16px",
  width: "100%",
  fontSize: "16px",
  lineHeight: "140%",
  [theme.mq.lg]: {
    width: "344px"
  }
});

const SubmitBtOutLineStyle = () => ({
  color: "#DD8E76",
  border: "1px solid #DD8E76",
  background: "transparent"
});

const getGraph = (score: number, color: string) => {
  const percent = (score / 1000) * 100;
  return (
    <div
      className="progress"
      css={{
        height: "8px",
        borderRadius: "8px",
        backgroundColor: "#F6F6F6 !important"
      }}
    >
      <div
        className="progress-bar"
        css={{
          borderRadius: "8px",
          width: `${percent}%`,
          backgroundColor: `${color} !important`
        }}
      />
      <div
        className="progress-bar"
        css={{
          width: `${100 - percent}%`,
          backgroundColor: "#F6F6F6 !important"
        }}
      />
    </div>
  );
};

const getScoreBlocks = (
  section: any,
  index: number,
  score: ScoreProps | undefined
) => {
  return (
    <div
      key={`ScoreBlocks-${index}`}
      css={(theme) => ({
        color: "#3D3D3D",
        borderRadius: "12px",
        borderLeft: `solid 3px ${section.color}`,
        fontSize: "24px",
        fontWeight: "bold",
        padding: "8px 16px",
        marginBottom: "12px",
        background: "white",
        [theme.mq.lg]: {
          width: "410px"
        }
      })}
    >
      <Flex flexDirection="row">
        <div
          css={{
            lineHeight: "68%"
          }}
        >
          {getHealthIcons(section.icon)}
        </div>
        <div
          css={(theme) => ({
            fontWeight: "400",
            marginLeft: "8px",
            fontSize: "14px",
            [theme.mq.lg]: {
              fontSize: "16px"
            }
          })}
        >
          {section.title}
        </div>
        <div
          css={(theme) => ({
            marginLeft: "auto",
            fontWeight: "500",
            fontSize: "14px",
            [theme.mq.lg]: {
              fontSize: "16px",
              fontWeight: "600"
            }
          })}
        >
          {score?.score}
        </div>
      </Flex>
      <Flex
        flexDirection="column"
        css={{
          marginTop: "8px"
        }}
      >
        {(score?.score || score?.score === 0) &&
          getGraph(score.score, section.color)}
      </Flex>
      <Flex
        flexDirection="column"
        css={(theme) => ({
          color:
            score?.rating.toLowerCase() === "excellent"
              ? "#549C56 !important"
              : score?.rating?.toLowerCase() === "good"
              ? "#8FB282 !important"
              : "#DA7761 !important",
          marginTop: "8px",
          textAlign: "right",
          fontSize: "14px",
          [theme.mq.lg]: {
            fontSize: "16px"
          }
        })}
      >
        {score?.rating && convertScoreRating(score.rating)}
      </Flex>
    </div>
  );
};

export const EmployeeHealthCheckScorePage = (
  props: EmployeeHealthCheckScorePageProps
) => {
  return (
    <div
      className="d-flex flex-column"
      css={(theme) => ({
        maxWidth: "410px",
        textAlign: "center",
        [theme.mq.lg]: {
          marginLeft: "auto",
          marginRight: "auto"
        }
      })}
    >
      <div
        css={(theme) => ({
          color: "#454545",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "140%",
          paddingBottom: "4px",
          [theme.mq.lg]: {
            fontSize: "20px"
          }
        })}
      >
        {props.firstName}, your overall health{" "}
        {props.totalRating !== "could_be_improved" && "is"}
      </div>
      <div
        css={(theme) => ({
          color: "#454545",
          fontSize: "24px",
          fontWeight: "500",
          lineHeight: "140%",
          paddingBottom: "4px",
          [theme.mq.lg]: {
            fontSize: "40px",
            paddingBottom: "8px"
          }
        })}
      >
        {convertScoreRating(props.totalRating, true)}
      </div>
      <div
        css={(theme) => ({
          paddingTop: "10px",
          width: "296px",
          marginLeft: "auto",
          marginRight: "auto",
          [theme.mq.lg]: {
            paddingTop: "20px",
            width: "unset",
            marginLeft: "unset",
            marginRight: "unset"
          }
        })}
      >
        <div
          css={{
            position: "relative",
            paddingBottom: "43px"
          }}
        >
          <div
            css={(theme) => ({
              width: "90.3px",
              position: "absolute",
              background: "#4F4F4F",
              borderRadius: "0.4em",
              left: `${props.totalScore / 10 - 15}%`,
              [theme.mq.lg]: {
                left: `${props.totalScore / 10 - 13}%`
              },
              "&:after": {
                content: "''",
                position: "absolute",
                left: "50%",
                top: "100%",
                width: "0",
                height: "0",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderTop: "10px solid #4F4F4F",
                clear: "both",
                transform: "translateX(-50%)"
              }
            })}
          >
            <div
              css={{
                color: "white",
                fontSize: "24px !important",
                padding: "2.32px 5px"
              }}
            >
              {props.totalScore}
            </div>
          </div>
        </div>
        <div
          css={(theme) => ({
            position: "relative",
            paddingTop: "20px",
            paddingBottom: "20px",
            marginBottom: "45px",
            [theme.mq.lg]: {
              marginBottom: "unset"
            }
          })}
        >
          <div
            css={{
              position: "absolute",
              width: "25px",
              height: "25px",
              borderRadius: "25px",
              border: "5px solid #4C4C4C",
              top: "12px",
              background: "white",
              left: `${props.totalScore / 10 - 5}%`
            }}
          />
          <div
            className="progress"
            css={{
              height: "10px",
              borderRadius: "8px"
            }}
          >
            <div
              className="progress-bar"
              css={{
                width: "49.3%",
                backgroundColor: "#E7E7E7 !important"
              }}
            />
            <div
              className="progress-bar"
              css={{
                width: "0.7%",
                backgroundColor: "#F8F6F3 !important"
              }}
            />
            <div
              className="progress-bar"
              css={{
                width: "24.3%",
                backgroundColor: "#8FB282 !important"
              }}
            />
            <div
              className="progress-bar"
              css={{
                width: "0.7%",
                backgroundColor: "#F8F6F3 !important"
              }}
            />
            <div
              className="progress-bar"
              css={{
                width: "25%",
                backgroundColor: "#549C56 !important"
              }}
            />
          </div>
        </div>
      </div>
      <div
        css={(theme) => ({
          [theme.mq.lg]: {
            paddingTop: "40px",
            marginLeft: "auto",
            marginRight: "auto"
          }
        })}
      >
        {ScoreList.map((section, index) =>
          getScoreBlocks(
            section,
            index,
            props.scores.find((score) => score.code === section.code)
          )
        )}
      </div>
      <div
        css={{
          paddingTop: "32px"
        }}
      >
        <button
          css={[SubmitBtStyle, SubmitBtOutLineStyle]}
          id="submit"
          onClick={(e) => {
            e.preventDefault();
            props.submit(e, {
              isLoggedIn: User.loggedIn() ?? false,
              type: "signup",
              skipConsult: true
            });
          }}
        >
          <span
            css={{
              fontWeight: "600",
              color: "black"
            }}
          >
            Continue without consult
          </span>
          <span css={{ paddingLeft: "8px", paddingBottom: "2px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              css={{
                marginBottom: "2px"
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7574 6.40131L20.5303 11.1743C20.8232 11.4672 20.8232 11.942 20.5303 12.2349L15.7574 17.0079C15.4645 17.3008 14.9896 17.3008 14.6967 17.0079C14.4038 16.715 14.4038 16.2401 14.6967 15.9473L18.1893 12.4546H4V10.9546H18.1893L14.6967 7.46197C14.4038 7.16908 14.4038 6.6942 14.6967 6.40131C14.9896 6.10842 15.4645 6.10842 15.7574 6.40131Z"
                fill="#262626"
              />
            </svg>
          </span>
        </button>
        <button
          id="loading"
          style={{ display: "none" }}
          css={[SubmitBtStyle, SubmitBtOutLineStyle]}
          disabled={true}
        >
          <Spinner animation="border" className="loading" size="sm" />
        </button>
      </div>
      <div
        css={{
          paddingTop: "16px"
        }}
      >
        <button
          css={[SubmitBtStyle]}
          id="submit2nd"
          onClick={(e) => {
            e.preventDefault();
            props.submit(e, {
              isLoggedIn: User.loggedIn() ?? false,
              type: "signup",
              idSuffix: "2nd"
            });
          }}
        >
          <span css={{ paddingRight: "4px" }}>
            <img
              css={{
                height: "24px",
                paddingBottom: "4px"
              }}
              src="/assets/images/com/book-calendar-icon.svg"
            />
          </span>
          <span
            css={{
              fontWeight: "600"
            }}
          >
            Book a telehealth consult
          </span>
        </button>
        <button
          id="loading2nd"
          style={{ display: "none" }}
          css={[SubmitBtStyle]}
          disabled={true}
        >
          <Spinner animation="border" className="loading" size="sm" />
        </button>
      </div>
    </div>
  );
};
