import moment from "moment";
import {
  OscarAPI,
  LinkedProduct,
  BulkPriceResponse,
  ClinicalDetail
} from "@mh/api";
import { Sentry } from "@mh/core";
import {
  ALCOHOL_STATUS_FIELD_LABEL,
  ALLERGIES_FIELD_LABEL,
  CURRENT_MEDICATIONS_FIELD_LABEL,
  HEIGHT_FIELD_LABEL,
  MEDICAL_CONDITIONS_FIELD_LABEL,
  SMOKING_STATUS_FIELD_LABEL,
  WEIGHT_FIELD_LABEL,
  GOAL_WEIGHT_FIELD_LABEL
} from "../forms/ClinicalInfo";

export type LoadedProduct = Omit<
  LinkedProduct,
  "children" | "brand_name" | "directions" | "pharmacy_preferred" | "parent_id"
>;

export async function getCategory(categoryName: string) {
  // Load all categories
  const categories = await OscarAPI.getCategories(
    undefined,
    { forceAll: true, nameLike: categoryName },
    undefined
  );

  // We used the nameLike query param so categories should be an array with one element
  return categories[0];
}

/**
 * Retrieves the child products for a parent ID and hydrates each of their availabilities.
 * @param {number} parentId ID of the parent product to fetch the children on.
 * @returns {LoadedProduct[]}
 */
const getChildProductsWithAvailability = async (
  parentId: number
): Promise<LoadedProduct[]> => {
  const parentProduct = await OscarAPI.getProduct(parentId);
  // @ts-ignore
  const childProducts: LoadedProduct[] = await Promise.all(
    parentProduct.children.map(async (childProduct) => ({
      ...childProduct,
      availability: await OscarAPI.getProductAvailability(childProduct.id)
    }))
  );
  return childProducts;
};

/** Loads all Oscar products from a given category
 *
 * @param categoryName The name of a {@link Category} whose categories are to be loaded
 * @param createSpinner If true, shows the spinner icon over the document while loading
 * @param showPrice If true, shows price of the product
 * @param contraindications An optional array of strings which are passed as a "contraindications" query param to
 * product retrieval
 * @param  parentId for searching products
 * @returns An array of all products under the given category
 */
export async function loadProducts(
  categoryName: string,
  createSpinner?: boolean,
  showPrice?: boolean,
  parentId?: number,
  contraindications: string[] = []
): Promise<LoadedProduct[]> {
  const loader = document.getElementById("cover-spin");

  if (createSpinner && loader) loader.className = "show";

  const category = await getCategory(categoryName);

  // Load all products associated with that category
  const products = parentId
    ? await getChildProductsWithAvailability(parentId)
    : await OscarAPI.getProducts(
        undefined,
        {
          category: category?.id,
          structure: ["child", "standalone"],
          // get products from backend in alphabetical order.  We should display to the user in this order.
          orderByTitle: true,
          contraindications
        },
        undefined
      );

  if (showPrice && products.length > 0) {
    const productIds = products.map((product) => product.id);
    let productPrices: BulkPriceResponse[];
    try {
      productPrices = await OscarAPI.getProductPricesBulk(productIds);
    } catch (e) {
      Sentry.captureException(e);
      productPrices = [];
    }
    const productIdToPriceInclTax = Object.fromEntries(
      productPrices.map((productPrice) => [
        productPrice.id,
        productPrice.price.incl_tax
      ])
    );
    products.forEach((product) => {
      // if we can't find the price, leave it as undefined and the UI will not show it
      product.price = productIdToPriceInclTax[product.id];
    });
  }

  if (createSpinner && loader) {
    loader.className = "";
  }

  return products;
}

export function objectIsProduct(object: {}): object is LinkedProduct {
  return "id" in object && "upc" in object;
}

/**
 * Transforms an address from Addressr/AusPost format into one for geolocatr
 * i.e.
 *    Office 1,
 *    15 Prospect Street
 *
 * into
 *
 *    1/15 Prospect Street
 * @param address The address string to format
 * @returns The formatted address in geolocatr-compliatn format
 */
export function splitAddressString(address: string): string {
  // Find the first section of the address that has a word followed by a digit, i.e. "unit 1"
  const index = /\w+ \d+,/.exec(address.toLowerCase());

  if (index === null) {
    // If there's no word + digit match, don't bother checking for any unit or office number
    return address;
  }

  // Find the unit or office number
  const digit = /\d/.exec(index[0]);

  if (digit) {
    // Get the unit number and format the address from "Unit 1, 1 Fake Street" to "1/1 Fake Street"
    const unitStreetDelimiter = address.indexOf(",");
    return `${digit}/${address.slice(unitStreetDelimiter + 1)}`;
  }

  // Invalid string format, simply return what was passed in
  return address;
}

/**
 * Return the message to display after a questionnaire is submitted.
 *
 * Message has two parts: title and subtitle
 *
 * @param productCode
 * @returns Array of [title, subtitle]
 */
export const getQuestionnaireSubmittedMessage = (
  productCode: string
): string[] => {
  const isPharmacyOnlyProduct = ["morning_after_pill", "thrush"].includes(
    productCode
  );

  if (isPharmacyOnlyProduct) {
    return [
      "You're all done, we'll be in touch real soon.",
      "You can now relax, we have it all under control! Our team will review your details and let you " +
        "know if any further info is required (likely not though)."
    ];
  }

  return [
    "Thanks for completing your online assessment!",
    "There are just a couple of steps to complete before we can review your request - " +
      "you can head over to your portal now to get started."
  ];
};

/**
 * Return array of questions for clinical details.
 *
 * Clinical details is one step in the questionnaire, but we want to break it out into separate questions
 * before submitting the questionnaire to the backend.
 */
export const breakClinicalDetailsIntoSeparateQuestions = (
  questionId: number,
  clinicalDetails: ClinicalDetail,
  slug: string | null
): object[] => {
  const clinicalDetailInfo = [
    {
      id: questionId,
      next: false,
      depth: 0,
      title: HEIGHT_FIELD_LABEL,
      response: [clinicalDetails.height]
    },
    {
      id: questionId,
      next: false,
      depth: 0,
      title: WEIGHT_FIELD_LABEL,
      response: [clinicalDetails.weight]
    },
    {
      id: questionId,
      next: false,
      depth: 0,
      title: SMOKING_STATUS_FIELD_LABEL,
      response: [clinicalDetails.smoking_status]
    },
    {
      id: questionId,
      next: false,
      depth: 0,
      title: ALCOHOL_STATUS_FIELD_LABEL,
      response: [clinicalDetails.alcohol_status]
    },
    {
      id: questionId,
      next: false,
      depth: 0,
      title: ALLERGIES_FIELD_LABEL,
      response: [clinicalDetails.allergies]
    },
    {
      id: questionId,
      next: false,
      depth: 0,
      title: CURRENT_MEDICATIONS_FIELD_LABEL,
      response: [clinicalDetails.current_medications]
    },
    {
      id: questionId,
      next: false,
      depth: 0,
      title: MEDICAL_CONDITIONS_FIELD_LABEL,
      response: [clinicalDetails.medical_conditions]
    }
  ];

  if (slug === "weight_management") {
    clinicalDetailInfo.splice(2, 0, {
      id: questionId,
      next: false,
      depth: 0,
      title: GOAL_WEIGHT_FIELD_LABEL,
      response: [clinicalDetails.patients_goal_weight]
    });
  }

  return clinicalDetailInfo;
};

interface FieldAndValueProps {
  currentQuestion: {
    field?: string;
    showcond?: string;
  };
  value?: string | string[] | undefined;
}

export const setFieldAndValue = ({
  currentQuestion,
  value
}: FieldAndValueProps): {} | boolean => {
  if (
    Array.isArray(value) &&
    value.length > 0 &&
    currentQuestion &&
    currentQuestion.field &&
    !currentQuestion.showcond
  ) {
    let returnValue: string = "";
    if (value.length > 1) {
      const lowcaseValue = value.map((val) => val.toLowerCase());
      returnValue = `${lowcaseValue
        .slice(0, -1)
        .join(", ")} and ${lowcaseValue.slice(-1)}`;
    } else {
      returnValue = value[0].toLowerCase();
    }
    return {
      [currentQuestion.field]: returnValue
    };
  }
  return false;
};

interface TimeMap {
  year: number;
  month: number;
  day: number;
  result: number;
}

interface OptionConditionMap {
  gaId: string;
  default: number | string;
  conditions: TimeMap[];
}

export const OptionConditions: OptionConditionMap[] = [
  {
    gaId: "MC0300",
    default: 20,
    conditions: [
      {
        year: 2023,
        month: 12,
        day: 24,
        result: 17
      },
      {
        year: 2023,
        month: 12,
        day: 25,
        result: 17
      },
      {
        year: 2023,
        month: 12,
        day: 31,
        result: 17
      },
      {
        year: 2024,
        month: 1,
        day: 1,
        result: 17
      }
    ]
  }
];

/**
 * Return the condition data for questionnaire option to check whether show or hide the option.
 *
 * @param gaId
 * @param type
 * @returns condition data (number: time, string: other condition except time, undefined: if no condition is matched)
 */
export const getOptionConditionData = (
  gaId: string,
  type: string
): number | undefined => {
  const condition: OptionConditionMap | undefined = OptionConditions.find(
    (cond) => cond.gaId === gaId
  );
  if (!condition) return undefined;
  if (type === "time") {
    const SydneyTime = moment.tz(moment(), "Australia/Sydney");
    const timeResult = condition.conditions.find(
      (optionDate: TimeMap) =>
        SydneyTime.year() === optionDate.year &&
        SydneyTime.month() + 1 === optionDate.month &&
        SydneyTime.date() === optionDate.day
    )?.result;
    // @ts-ignore
    return timeResult ?? condition.default;
  }
  return undefined;
};

interface NextButtonDisabledProps {
  btLoading: string | undefined;
  type: string | undefined;
  subtype: string | undefined;
  subtypeNew: string | undefined;
  brandPillId: string | undefined;
  brandPillIdNew: string | undefined;
  brandPillTitle: string | undefined;
  isStock: string | undefined;
  scriptOnly: string | undefined;
  isAddressValid: string | boolean | undefined;
  eScriptName: string | undefined;
  medicationList: [] | undefined;
  ph: string | undefined;
  isClinicalDetails: boolean;
}

export const isNextButtonDisabled = ({
  btLoading,
  type,
  subtype,
  subtypeNew,
  brandPillId,
  brandPillIdNew,
  brandPillTitle,
  isStock,
  scriptOnly,
  isAddressValid,
  eScriptName,
  medicationList,
  ph,
  isClinicalDetails
}: NextButtonDisabledProps): boolean => {
  if (
    btLoading ||
    !isClinicalDetails ||
    (type === "mobile" && ph === null) ||
    (type === "medication" &&
      (!brandPillIdNew || (isStock === "no" && !scriptOnly))) ||
    (type === "address" && !isAddressValid) ||
    (type === "newMedicationDetail" &&
      ((!brandPillTitle && !eScriptName) ||
        (eScriptName &&
          !brandPillTitle &&
          medicationList &&
          medicationList?.length > 0))) ||
    (subtypeNew === "selectbox" && !brandPillId) ||
    (subtype === "productSearch" && !brandPillId)
  )
    return true;
  return false;
};
