import { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { Validation } from "@mh/core";

import { useQContext } from "@mh/questionnaire/src/hooks";

import { QuestionnaireContentComponentProps } from ".";

export const QCInteger = (props: QuestionnaireContentComponentProps) => {
  const { setLocalResponseValidationError } = useQContext();
  const [value, setValue] = useState<string>(props.initialValue ?? "");

  useEffect(() => {
    const validation = Validation.int().validate(value);

    if (value === "") {
      setLocalResponseValidationError(null);
      props.onChange(undefined);
    } else if (validation !== null) {
      setLocalResponseValidationError(validation);
      props.onChange(undefined);
    } else {
      setLocalResponseValidationError(null);
      props.onChange(value);
    }
  }, [value]);

  return (
    <Row>
      <Form.Control
        onChange={(event) => setValue(event.target.value)}
        value={value}
        autoFocus={true}
        placeholder={props.question.placeholder}
      />
    </Row>
  );
};
