import { useEffect, useState } from "react";
import moment from "moment";
import { getWaitTime, WaitDate } from "@mh/api";
import { checkWithinOperatingHours, IconClock } from "@mh/components";

interface Props {
  arhi?: boolean;
  nibTeleHealth?: boolean;
  nibMembershipNumber?: number;
}

const titleStyle = {
  fontSize: "14px",
  lineHeight: "135%"
};

const valueStyle = (theme: any) => ({
  color: theme.color.primary,
  fontWeight: "600",
  lineHeight: "150%",
  marginBottom: "8px"
});

const arhiStyle = {
  paddingLeft: "40px"
};

const isShowAppointmentsAvailableFrom = (availableDate: string): boolean => {
  try {
    const diffAvailableHours = moment(availableDate).diff(
      moment().format("YYYY-MM-DD HH:mm"),
      "hours"
    );
    return diffAvailableHours >= 0 && diffAvailableHours <= 48;
  } catch (e) {
    return false;
  }
};

export const QueueAppointmentTime = ({
  arhi,
  nibTeleHealth,
  nibMembershipNumber
}: Props) => {
  const [showQueue, setShowQueue] = useState<boolean>(false);
  const [waitTime, setWaitTime] = useState<number>(0);
  const [availableDate, setAvailableDate] = useState<string>("");

  useEffect(() => {
    const fetchWaitDate = async () => {
      let isValid = false;

      const { expected_wait_time, earliest_available_booking_slot }: WaitDate =
        await getWaitTime();

      if (expected_wait_time) {
        const { nib_general_telehealth: nibGeneralTelehealthWaitTime } =
          expected_wait_time;

        let calculatedWaitTime = 5;

        if (nibTeleHealth && (nibMembershipNumber || arhi)) {
          calculatedWaitTime = nibGeneralTelehealthWaitTime;
        }

        setWaitTime(calculatedWaitTime);
        isValid = true;
      }

      if (
        earliest_available_booking_slot &&
        moment(earliest_available_booking_slot).isValid()
      ) {
        const convert = moment
          .utc(earliest_available_booking_slot)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD HH:mm");
        setAvailableDate(convert);
        isValid = true;
      }
      if (isValid) setShowQueue(true);
    };
    fetchWaitDate();
  }, [showQueue, nibTeleHealth, nibMembershipNumber, arhi]);

  return (
    <div
      css={(theme) => ({
        background: arhi ? "#F8F8F8" : "inherit",
        width: "97%",
        margin: "0px 5px",
        marginBottom: arhi ? "24px" : "0px",
        padding: "0px 16px",
        paddingBottom: arhi ? "8px" : "0px",
        gap: "16px",
        textAlign: arhi ? "left" : "center",
        borderRadius: "10px",
        [theme.mq.md]: {
          width: "100%"
        }
      })}
    >
      {arhi && (
        <div
          className="d-flex flex-row"
          css={{
            paddingTop: "16px"
          }}
        >
          <div
            css={{
              marginRight: "8px"
            }}
          >
            <IconClock
              width={32}
              height={32}
              css={(theme) => ({ color: theme.color.primary })}
            />
          </div>
          <span
            css={{ fontSize: "16px", fontWeight: "600", lineHeight: "150%" }}
          >
            Once you submit payment, you can book a time for your consultation
            or join the queue for an immediate call at the next step.
          </span>
        </div>
      )}
      {checkWithinOperatingHours() && showQueue && (
        <>
          <div>
            <hr
              css={{
                width: arhi ? "45px" : "250px",
                color: "#D9D9D9",
                margin: arhi ? "8px 0 8px 40px" : "8px auto"
              }}
            />
          </div>
          {waitTime && waitTime > 0 && (
            <>
              <div css={[titleStyle, arhi && arhiStyle]}>Queue wait time:</div>
              <div css={[valueStyle, arhi && arhiStyle]}>
                {waitTime} minutes
              </div>
            </>
          )}
          {arhi && (
            <div>
              <hr
                css={{
                  width: "45px",
                  color: "#D9D9D9",
                  margin: "8px 0 8px 40px"
                }}
              />
            </div>
          )}
          {availableDate && isShowAppointmentsAvailableFrom(availableDate) && (
            <>
              <div css={[titleStyle, arhi && arhiStyle]}>
                Appointments available from:
              </div>
              <div css={[valueStyle, arhi && arhiStyle]}>
                {moment(availableDate).format("dddd D MMM, hh:mm a")}{" "}
              </div>
            </>
          )}
          {!arhi && (
            <div>
              <hr
                css={{
                  width: "250px",
                  color: "#D9D9D9",
                  margin: "0 auto"
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
