import { ComponentPropsWithoutRef } from "react";

export const IconLifestyle = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6668 14.0894C16.6668 14.6788 16.4327 15.244 16.016 15.6608C15.5992 16.0775 15.034 16.3116 14.4446 16.3116C13.8552 16.3116 13.29 16.0775 12.8733 15.6608C12.4565 15.244 12.2224 14.6788 12.2224 14.0894C12.2224 13.5 12.4565 12.9348 12.8733 12.5181C13.29 12.1013 13.8552 11.8672 14.4446 11.8672C15.034 11.8672 15.5992 12.1013 16.016 12.5181C16.4327 12.9348 16.6668 13.5 16.6668 14.0894ZM5.55572 11.8672C4.96635 11.8672 4.40112 12.1013 3.98437 12.5181C3.56762 12.9348 3.3335 13.5 3.3335 14.0894C3.3335 14.6788 3.56762 15.244 3.98437 15.6608C4.40112 16.0775 4.96635 16.3116 5.55572 16.3116C6.14509 16.3116 6.71032 16.0775 7.12707 15.6608C7.54381 15.244 7.77794 14.6788 7.77794 14.0894C7.77794 13.5 7.54381 12.9348 7.12707 12.5181C6.71032 12.1013 6.14509 11.8672 5.55572 11.8672Z"
      stroke="#F19895"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5117 4.74328H12.0242C12.1997 4.74012 12.3706 4.79947 12.5064 4.91073C12.6421 5.02198 12.7339 5.17789 12.7652 5.35057L14.2169 11.8128"
      stroke="#F19895"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.77881 14.0891L13.2395 8.33691"
      stroke="#F19895"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.0376 8.16324H8.20773C8.38102 8.15972 8.55006 8.21707 8.68544 8.32529C8.82082 8.43352 8.91398 8.58578 8.94871 8.75559L9.59298 11.9929"
      stroke="#F19895"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
