import type { FC } from "react";
import { Select } from "../Select";

export interface QuantitySelectorProps {
  /** The current value */
  value: number;
  /** Inclusive range of selectable values */
  range: [number, number];
  /** Fires when the value is changed */
  onChange: (value: number) => void;
}

export const QuantitySelector: FC<QuantitySelectorProps> = (props) => {
  const [minimum, maximum] = props.range;
  // The amount of options to render
  const delta: number = maximum - minimum;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = parseInt(e.currentTarget.value);
    props.onChange(value);
  };

  return (
    <Select
      value={props.value}
      onChange={handleChange}
      data-testid="quantity-selector"
    >
      {/* If the limit is [0, 5] then show options ranging from 0 to 5 */}
      {[...Array(delta + 1).keys()].map((value) => (
        <option key={value} value={value + minimum}>
          {value + minimum}
        </option>
      ))}
    </Select>
  );
};
