import {
  ViewedPrice,
  ViewPaymentPage,
  Purchase,
  QuestionnaireRedirect
} from "./types";
import { User } from "@mh/api";

interface CustomWindow extends Window {
  dataLayer: {
    push: (
      data: Record<
        string,
        string | number | ViewedPrice | ViewPaymentPage | Purchase | undefined
      >
    ) => void;
  };
}

declare const window: CustomWindow;

const createDataSet = (
  eventname: string,
  data: any
):
  | ViewedPrice
  | ViewPaymentPage
  | Purchase
  | QuestionnaireRedirect
  | undefined => {
  if (eventname === "viewed_price") {
    return data;
  } else if (eventname === "questionnaireRedirect") {
    const redirectData: QuestionnaireRedirect = {
      redirectFrom: data.from,
      redirectTo: data.to
    };
    return redirectData;
  } else if (eventname === "purchase") {
    const pt = User.info();
    const coupon =
      data.basket?.voucher_discounts &&
      Array.isArray(data.basket?.voucher_discounts)
        ? data.basket?.voucher_discounts
            .map((coupon: any) => {
              return coupon?.name;
            })
            ?.join(",")
        : undefined;
    const purchaseData: Purchase = {
      scriptType:
        !data.questionnaire || data.questionnaire?.category === "Otc"
          ? "Non-Prescription Treatments"
          : "Prescription Treatments",
      deliveryOption: "Online",
      purchaseType: "On Demand",
      advancedMatch: {
        em: pt?.email,
        // @ts-ignore
        fn: pt?.name?.first_name,
        // @ts-ignore
        ln: pt?.name?.last_name,
        // @ts-ignore
        ph: pt?.phone
      },
      ecommerce: {
        currencyCode: "AUD",
        purchase: {
          actionField: {
            id: data.order?.id,
            affiliation: "Portal Purchase",
            revenue: data.basket?.total_incl_tax,
            tax: data.basket?.total_tax,
            shipping: data.shipping?.price?.incl_tax
          },
          products: data.lines.map((item: any) => ({
            name:
              data.questionnaire?.category &&
              Array.isArray(item?.product?.categories) &&
              item.product.categories.findIndex(
                (cat: any) =>
                  cat?.name?.toLowerCase() ===
                  data.questionnaire.category?.name?.toLowerCase()
              ) > -1
                ? data.questionnaire.category?.slug
                : "otc",
            id: item.id,
            dimension6: item.title,
            dimension7: item.title,
            price: item?.price_incl_tax_excl_discounts,
            brand: item.brand,
            category:
              data.questionnaire?.category &&
              Array.isArray(item?.product?.categories) &&
              item.product.categories.findIndex(
                (cat: any) =>
                  cat?.name?.toLowerCase() ===
                  data.questionnaire.category?.name?.toLowerCase()
              ) > -1
                ? "Prescription Treatments"
                : "OTC",
            quantity: item.quantity
          }))
        }
      }
    };
    if (coupon && purchaseData?.ecommerce?.purchase?.actionField) {
      purchaseData.ecommerce.purchase.actionField.coupon = coupon;
    }
    if (data.questionnair?.id) {
      purchaseData.questionnaireId = data.questionnaire.id;
    }

    return purchaseData;
  }
};

export const sendDataLayer = (eventname: string, data: any): void => {
  // @ts-ignore
  const dataSet: ViewedPrice | ViewPaymentPage | Purchase | undefined =
    createDataSet(eventname, data);
  // @ts-ignore
  window.dataLayer?.push({
    event: eventname,
    ...dataSet
  });
};
