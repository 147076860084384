import { BRAND, Brand } from "@mh/core";
import { TreatmentCard } from "../..//components/TreatmentCard/TreatmentCard";

const BRAND_IMAGE_MAP: Record<Brand, string> = {
  hubhealth: "assets/images/hubhealth/hubhealth_what_we_treat_img.jpg",
  youly: "assets/images/youly/youly_what_we_treat_img.jpg",
  stagger: "assets/images/stagger/stagger_what_we_treat_img.jpg"
};

export const WhatWeTreat = () => {
  return (
    <div>
      <div
        css={{
          position: "relative"
        }}
      >
        <img
          src={BRAND_IMAGE_MAP[BRAND]}
          css={(theme) => ({
            display: "none",
            [theme.mq.md]: {
              display: "block",
              borderRadius: "8px",
              width: "100%",
              height: "100%"
            }
          })}
          alt="WhatWeTreat Banner"
        ></img>
      </div>
      <div
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: "100%",
          [theme.mq.md]: {
            padding: "24px 24px 32px 24px",
            position: "relative",
            top: "-96px"
          }
        })}
      >
        <TreatmentCard />
      </div>
    </div>
  );
};
