import React, { useEffect, useRef, useState } from "react";
import { readDataUrlFromFile, resizeImageFile, Sentry } from "@mh/core";
import { Button } from "../buttons";
import { IconFile } from "../icons";
import { Spinner } from "../loaders";
import { CategoryImageRequirement } from "@mh/api";

export interface ImageSelectorProps {
  /** The requirement that the upload image fulfils */
  requirement: CategoryImageRequirement;
  /** If true, disables controls in the image selector. */
  disabled?: boolean;
  /** An error string to render. */
  error?: string;
  /**
   * Optional image to load into the selector.
   * If given, shows remove controls.
   * If undefined, shows choose image controls.
   */
  image?: File[];
  /** Fires on failing to load or resize a selected image. */
  onError: (id: number, file?: File[]) => void;
  /** Fired when the remove button is clicked. */
  onRemove: (id: number) => void;
  /** Fired when a new image is chosen. */
  onSet: (
    id: number,
    image: File[],
    requirement: CategoryImageRequirement
  ) => void;
  /** An optional width/height to resize the selected image to. */
  resizeDimensions?: {
    width: number;
    height: number;
  };
}

export const ImageSelector = ({
  requirement,
  disabled,
  error,
  image,
  onError,
  onRemove,
  onSet,
  resizeDimensions
}: ImageSelectorProps) => {
  const [dataUrl, setDataUrl] = useState<(string | undefined)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const loadImage = async () => {
      if (!image) {
        setDataUrl([]);
        return;
      }

      setIsLoading(true);
      try {
        const imageUrls =
          image &&
          Array.from(image).map(async (img) => {
            const imageUrl = await readDataUrlFromFile(img);
            return imageUrl;
          });
        Promise.all(imageUrls).then((imageUrls) => setDataUrl(imageUrls));
      } catch {
        onError(requirement.id, image);
      } finally {
        setIsLoading(false);
      }
    };

    loadImage();
  }, [image]);

  const handleInputOnChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const imageFiles = e.target.files;
    if (!imageFiles) {
      return;
    }

    if (!resizeDimensions) {
      onSet(requirement.id, Array.from(imageFiles), requirement);
      return;
    }

    setIsLoading(true);
    const resizedImages = Array.from(imageFiles).map(async (file) => {
      const resizedImageFile = await resizeImageFile(
        file,
        resizeDimensions.width,
        resizeDimensions.height
      );
      return resizedImageFile;
    });
    try {
      Promise.all(resizedImages).then((resizedImages) => {
        onSet(requirement.id, resizedImages, requirement);
      });
    } catch (e) {
      Sentry.captureException(e);
      Promise.all(resizedImages).then((resizedImages) => {
        onError(requirement.id, resizedImages);
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        gap: "16px"
      }}
    >
      {isLoading && <Spinner />}
      {dataUrl.map((url, i) => {
        return (
          <img
            css={{
              objectFit: "contain",
              width: "96px",
              maxHeight: "96px",
              borderRadius: "4px"
            }}
            src={url}
            key={i}
          />
        );
      })}
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "start",
          overflow: "hidden"
        }}
      >
        <strong
          css={{
            fontSize: "12px",
            overflowWrap: "break-word",
            textTransform: "uppercase"
          }}
        >
          {requirement.name +
            (requirement.description ? ` (${requirement.description})` : "")}
        </strong>
        {image ? (
          <strong
            css={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            {image.map((img, i) => {
              return <div key={i}>{img.name}</div>;
            })}
          </strong>
        ) : (
          <span
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px"
            }}
          >
            <IconFile
              css={(theme) => ({ color: theme.color.primary })}
              width={16}
              height={16}
            />
            <span>No file chosen</span>
          </span>
        )}
        {error && (
          <span
            css={{
              color: "#E41D1D",
              fontSize: "10px"
            }}
          >
            {error}
          </span>
        )}
      </div>
      <div css={{ marginLeft: "auto" }}>
        {image ? (
          <Button
            disabled={disabled}
            onClick={() => onRemove(requirement.id)}
            size="sm"
            variant="primary-outline"
          >
            Remove
          </Button>
        ) : (
          <>
            <input
              accept="image/jpeg,image/png"
              multiple
              css={{
                display: "none"
              }}
              ref={inputRef}
              type="file"
              onChange={handleInputOnChange}
            />
            <Button
              disabled={disabled}
              onClick={() => inputRef.current?.click()}
              size="sm"
              variant="primary-outline"
            >
              Choose file
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
