import React, { PropsWithChildren } from "react";
import "./App.scss";
import TagManager from "react-gtm-module";
import Spinner from "react-bootstrap/Spinner";
import "font-awesome/css/font-awesome.min.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { FeaturesStore } from "@mh/api";
import { BRAND, sentryInit } from "@mh/core";
import { BrandThemeProvider, ToastContainer } from "@mh/components";

import Router from "./Router";

sentryInit();

const stripePromise = import.meta.env.VITE_SPK
  ? loadStripe(import.meta.env.VITE_SPK)
  : null;

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GID!
};

TagManager.initialize(tagManagerArgs);

/** Global contexts to be available throughout the app. */
const GlobalStore = ({ children }: PropsWithChildren) => (
  <BrandThemeProvider brand={BRAND}>
    <FeaturesStore>{children}</FeaturesStore>
  </BrandThemeProvider>
);

const App = () => (
  <GlobalStore>
    <Elements stripe={stripePromise}>
      <div id="cover-spin" />
      <div id="cover-spin-new">
        <div className="position-absolute cover-spin-new-inner">
          <div className="position-relative cover-spin-new-box">
            <Spinner animation="border" className="cover-spin-new-loading" />
            <span className="position-absolute" id="countdown"></span>
          </div>
        </div>
      </div>

      <div id="countdown-wapper">
        <div id="countdown-inner">
          <div id="countdown-msg" className="mb-2 text-center" />
          <div id="modalcountdown" className="text-center" />
        </div>
      </div>
      <Router />
      <ToastContainer />
    </Elements>
  </GlobalStore>
);

export default App;
