import { OexaScriptStatus } from "@mh/api";
import { Flex } from "@mh/components";

interface EscriptProductSelectorContentsProps {
  scriptToken: string;
  eScriptTokenStatus: string | undefined;
  name: string | undefined;
  eScriptRemaining: number | undefined;
}

const getStatus = (status: OexaScriptStatus | undefined) =>
  ({
    Found: "ACTIVE",
    Dispensed: "DISPENSED",
    Expired: "EXPIRED",
    Cancelled: "CANCELLED",
    Ceased: "CEASED",
    Disabled: "INVALID",
    Error: "INVALID",
    NotFound: "INVALID",
    NotAuthorized: "INVALID",
    InvalidRequest: "INVALID"
    // @ts-ignore
  })[status];

export const EscriptProductSelectorContents = (
  props: EscriptProductSelectorContentsProps
) => (
  <Flex
    flexDirection="column"
    css={(theme) => ({
      width: "unset",
      lineHeight: "normal",
      [theme.mq.sm]: {
        width: "431px"
      }
    })}
  >
    {props.eScriptTokenStatus === "Found" && (
      <Flex flexDirection="row">
        <Flex
          css={{
            width: "116px",
            fontSize: "14px",
            fontWeight: "500",
            paddingRight: "16px"
          }}
        >
          Medicine
        </Flex>
        <Flex
          css={{
            fontSize: "16px",
            fontWeight: "500"
          }}
        >
          {props.name ?? "N/A"}
        </Flex>
      </Flex>
    )}
    {props.eScriptTokenStatus === "Found" && (
      <Flex
        flexDirection="column"
        css={{
          width: "100%",
          marginTop: "12px",
          marginBottom: "12px",
          borderTop: "1px solid #737373"
        }}
      />
    )}
    <Flex flexDirection="row">
      <Flex
        css={{
          width: "116px",
          marginBottom: "12px",
          fontSize: "14px",
          fontWeight: "500",
          paddingRight: "16px"
        }}
      >
        Status
      </Flex>
      <Flex
        css={(theme) => ({
          fontSize: "16px",
          fontWeight: "600",
          color:
            props.eScriptTokenStatus === "Found"
              ? theme.color.success
              : theme.color.danger
        })}
      >
        {/* @ts-ignore */}
        {getStatus(props.eScriptTokenStatus) ?? "N/A"}
      </Flex>
    </Flex>
    {props.eScriptTokenStatus === "Found" && (
      <Flex flexDirection="row">
        <Flex
          css={{
            width: "116px",
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "12px",
            paddingRight: "16px"
          }}
        >
          Supplies
        </Flex>
        <Flex
          css={{
            fontSize: "16px",
            fontWeight: "600"
          }}
        >
          {props.eScriptRemaining} Remaining
        </Flex>
      </Flex>
    )}
    <Flex flexDirection="row">
      <Flex
        css={(theme) => ({
          width: "116px",
          fontSize: "14px",
          fontWeight: "500",
          paddingRight: "16px",
          marginBottom: props.eScriptTokenStatus === "Found" ? "24px" : "0px",
          [theme.mq.md]: {
            marginBottom: "0px"
          }
        })}
      >
        Token Code
      </Flex>
      <Flex
        css={{
          fontSize: "14px",
          fontWeight: "500"
        }}
      >
        {props.scriptToken}
      </Flex>
    </Flex>
    {props.eScriptTokenStatus !== "Found" && (
      <Flex
        flexDirection="column"
        css={(theme) => ({
          width: "100%",
          marginTop: "12px",
          marginBottom: "24px",
          borderTop: "1px solid #737373",
          [theme.mq.md]: {
            marginBottom: "32px"
          }
        })}
      />
    )}
  </Flex>
);
