import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import { Flex } from "@mh/components";

import { MedicationInformationDates } from "./MedicationInformationDates";
import { MedicationInformationDosage } from "./MedicationInformationDosage";
import { MedicationInformationSideEffects } from "./MedicationInformationSideEffects";
import { MedicationInformationIsEffective } from "./MedicationInformationIsEffective";

export interface MedicationInformationResult {
  medication_start: string;
  medication_end: string | null;
  dosage: string;
  has_side_effects: boolean | null;
  side_effects: string | null;
  is_effective: boolean | null;
  effective_description: string | null;
}

export const MedicationInformation = (props: {
  initial: MedicationInformationResult | undefined;
  next: (details: MedicationInformationResult) => void;
}) => {
  const [started, setStarted] = useState<string | null>(
    props.initial?.medication_start ?? null
  );
  const [finished, setFinished] = useState<string | null>(
    props.initial?.medication_end ?? null
  );
  const [dosage, setDosage] = useState<string>(props.initial?.dosage ?? "");

  const [hasSideEffects, setHasSideEffects] = useState<boolean | null>(
    props.initial?.has_side_effects ?? null
  );
  const [sideEffectDetails, setSideEffectDetails] = useState<string | null>(
    props.initial?.side_effects ?? null
  );

  const [isEffective, setIsEffective] = useState<boolean | null>(
    props.initial?.is_effective ?? null
  );
  const [effectiveDetails, setEffectiveDetails] = useState<string | null>(
    props.initial?.effective_description ?? null
  );

  const [validationError, setValidationError] = useState<null | string>(null);

  const getInfo = (): MedicationInformationResult => ({
    dosage: dosage,
    is_effective: isEffective,
    effective_description: effectiveDetails,
    medication_start: started!,
    medication_end: finished,
    has_side_effects: hasSideEffects,
    side_effects: sideEffectDetails
  });

  useEffect(() => {
    if (started === null) {
      setValidationError("Medication commencement is required");
    } else if (
      finished !== null &&
      new Date(started).getTime() > new Date(finished).getTime()
    ) {
      setValidationError("Medication commencement must be before finish");
    } else if (hasSideEffects === null) {
      setValidationError("Please indicate if you experienced side effects");
    } else if (
      (hasSideEffects && sideEffectDetails === null) ||
      sideEffectDetails === ""
    ) {
      setValidationError(
        "Please elaborate on the side effects you're experiencing"
      );
    } else if (dosage === "") {
      setValidationError("Please provide information on the medication dosage");
    } else if (isEffective === null) {
      setValidationError("Please indicate if the mediation was effective");
    } else if (
      (isEffective && effectiveDetails === null) ||
      effectiveDetails === ""
    ) {
      setValidationError(
        "Please elaborate on how the medication was effective"
      );
    } else {
      setValidationError(null);
    }
  }, [
    dosage,
    isEffective,
    effectiveDetails,
    started,
    finished,
    hasSideEffects,
    sideEffectDetails
  ]);

  return (
    <Flex flexDirection="column">
      <MedicationInformationDates
        setStarted={setStarted}
        started={started}
        setFinished={setFinished}
        finished={finished}
      />
      <MedicationInformationSideEffects
        setHasSideEffects={setHasSideEffects}
        hasSideEffects={hasSideEffects}
        setSideEffectDetails={setSideEffectDetails}
        sideEffectDetails={sideEffectDetails}
      />
      <MedicationInformationDosage setDosage={setDosage} dosage={dosage} />
      <MedicationInformationIsEffective
        isEffective={isEffective}
        setIsEffective={setIsEffective}
        effectiveDetails={effectiveDetails}
        setEffectiveDetails={setEffectiveDetails}
      />
      {validationError && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {validationError}
        </Form.Control.Feedback>
      )}
      <Flex width="100%" justifyContent="space-evenly">
        <Button
          style={{ width: "40%" }}
          onClick={() => props.next(getInfo())}
          disabled={validationError !== null}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};
