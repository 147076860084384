import {
  useState,
  useRef,
  useEffect,
  type Dispatch,
  type SetStateAction,
  type RefObject
} from "react";
import { type Category } from "@mh/api";

interface UseCategories {
  /** The ID of the currently active category, or -1 if none is specified */
  activeCategoryId: number;
  /** Sets the active category ID */
  setActiveCategoryId: Dispatch<SetStateAction<number>>;
  /** Has this hook already checked if an initial category index was provided? */
  hasDoneInitialCategoryCheck: RefObject<boolean>;
}

export const useCategories = (
  initialCategoryIndex: number | undefined,
  categories: Category[] | undefined
): UseCategories => {
  /**
   * Which category is currently selected. If none is selected, then the value will be -1. This is also the case
   * when categories are not passed as props
   */
  const [activeCategoryId, setActiveCategoryId] = useState<number>(-1);
  // If no initial category index is provided, don't bother checking for the initial category
  const hasDoneInitialCategoryCheck = useRef<boolean>(
    initialCategoryIndex === undefined
  );

  useEffect(() => {
    if (hasDoneInitialCategoryCheck.current) {
      // Don't set the initial category more than once
      return;
    }

    // A defined, positive category index was given
    const isValidIndex =
      initialCategoryIndex !== undefined && initialCategoryIndex > -1;

    // Categories were specified with a non-zero length
    const hasCategories = !!categories?.length;

    if (isValidIndex && hasCategories) {
      const category = categories.at(initialCategoryIndex);

      if (category) {
        setActiveCategoryId(category.id);
      }
    }

    hasDoneInitialCategoryCheck.current = true;
  }, [initialCategoryIndex, categories]);

  return {
    activeCategoryId,
    setActiveCategoryId,
    hasDoneInitialCategoryCheck
  };
};
