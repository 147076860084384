type DebouncedFn<Fn extends (...args: any) => void> = (
  ...args: Parameters<Fn>
) => void;

/**
 * Debounces a given function with the given time. Calling the resulting function multiple times within the delay will
 * only call the original function once.
 *
 * Note that the debounced function will return void.
 *
 * @param fn The function to debounce
 * @param delay The debounce delay, in milliseconds
 * @returns The debounced function
 */
export const debounce = <Fn extends (...args: any) => any>(
  fn: Fn,
  delay: number
): DebouncedFn<Fn> => {
  // eslint-disable-next-line no-undef
  let timeout: NodeJS.Timeout | null = null;

  const debouncedFunction: DebouncedFn<Fn> = (...args: Parameters<Fn>) => {
    // eslint-disable-next-line no-invalid-this
    const functionCall = () => fn.apply(this, args);

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(functionCall, delay);
  };

  return debouncedFunction;
};
