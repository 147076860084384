import QRCode from "react-qr-code";
import { Flex } from "@mh/components";

interface EscriptProductSelectorQrCodeProps {
  scriptToken: string | undefined;
}
export const EscriptProductSelectorQrCode = (
  props: EscriptProductSelectorQrCodeProps
) => (
  <Flex
    flexDirection="column"
    css={(theme) => ({
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      width: "167.002px",
      marginBottom: "24px",
      lineHeight: "0px",
      [theme.mq.sm]: {
        textAlign: "left",
        width: "127.399px",
        marginLeft: "0px",
        marginRight: "24px",
        marginBottom: "0px"
      }
    })}
  >
    <Flex>
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        // @ts-ignore
        value={props.scriptToken}
        viewBox={"0 0 256 256"}
      />
    </Flex>
    <Flex
      css={(theme) => ({
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "12px",
        marginTop: "24px",
        marginBottom: "24px",
        [theme.mq.sm]: {
          marginBottom: "32px"
        }
      })}
    >
      {props.scriptToken ?? "N/A"}
    </Flex>
  </Flex>
);
