import { FC } from "react";
import { Banner, BannerProps } from "./Banner";
import { ScrollableContainer } from "../ScrollableContainer";
import { BRAND, BRAND_NAME } from "@mh/core";

interface BannerListProps {
  banners: any[];
}

export const BannerList: FC<BannerListProps> = ({ banners }) => {
  const renderBanner = (banner: BannerProps) => (
    <Banner
      key={`bannerBox-${banner.id}`}
      type="rightimg"
      classes="banner-treatment"
      start={banner?.start ?? ""}
      end={banner?.end ?? ""}
      left={banner.left}
      right={banner.right}
      replace={[
        { from: "[[from]]", to: BRAND },
        {
          from: "[[brand]]",
          to: BRAND_NAME
        }
      ]}
      brand={BRAND}
    />
  );

  return banners.length > 1 ? (
    <ScrollableContainer<BannerProps>
      items={banners}
      scrollable
      renderItem={renderBanner}
    />
  ) : (
    renderBanner(banners[0])
  );
};
