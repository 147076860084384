import { FC, useEffect, useState } from "react";
import { asyncIsSuccess, BasketAPI } from "@mh/api";
import {
  Button,
  DisplayHeading,
  InfoDrawer,
  InfoDrawerProps,
  PriceDisplay,
  Await,
  Flex
} from "@mh/components";
import { useBasket } from "../../hooks";
import { BasketItems } from "../BasketItems";

import "./styles.scss";

interface Props extends InfoDrawerProps {
  onCheckout: () => void;
}

const BasketDrawer: FC<Props> = (props) => {
  const basket = useBasket();
  const [isApplyingDiscount, setIsApplyingDiscount] = useState<boolean>(false);
  const [discountError, setDiscountError] = useState<string | null>(null);

  useEffect(() => {
    if (discountError !== null) {
      setTimeout(() => setDiscountError(null), 5000);
    }
  }, [discountError]);

  const handleDiscountCodeApplied = async (code: string): Promise<void> => {
    setIsApplyingDiscount(true);
    if (asyncIsSuccess(basket.basket.async)) {
      const voucherResponse = await BasketAPI.addVoucher({ vouchercode: code });

      if (voucherResponse.isFailure) {
        setDiscountError(voucherResponse.failure);
      }

      basket.basket.refresh();
    }
    setIsApplyingDiscount(false);
  };

  return (
    <InfoDrawer
      isOpen={props.isOpen}
      title="Your Cart"
      onToggle={props.onToggle}
    >
      <Flex
        flexDirection="column"
        width="100%"
        marginBetween="2rem"
        className="basket-drawer"
      >
        <DisplayHeading>Order Summary</DisplayHeading>
        {/* @ts-ignore */}
        <BasketItems />
        <Await asyncs={[basket.basket.async]}>
          {([basket]) => (
            <>
              <PriceDisplay
                subtotal={parseFloat(basket.total_incl_tax_excl_discounts)}
                tax={parseFloat(basket.total_tax)}
                total={parseFloat(basket.total_incl_tax)}
                onDiscountCodeApplied={handleDiscountCodeApplied}
                voucherDiscounts={basket.voucher_discounts}
                isApplyingDiscount={isApplyingDiscount}
                discountError={discountError}
              />
            </>
          )}
        </Await>
        <Flex justifyContent="space-between" width="100%" marginBetween="16px">
          <Button
            fullWidth
            onClick={() => props.onToggle(false)}
            variant="primary-outline"
          >
            Continue shopping
          </Button>
          <Button fullWidth onClick={props.onCheckout}>
            Checkout
          </Button>
        </Flex>
      </Flex>
    </InfoDrawer>
  );
};

export default BasketDrawer;
