import {
  type ChangeEvent,
  type KeyboardEvent,
  useEffect,
  useState,
  useRef,
  useLayoutEffect
} from "react";
import { IconCaretLeft, IconCaretRight } from "../icons";
import { Select } from "../Select";

interface Props {
  /** The total count of items, which we are viewing a page of */
  count: number;
  /** The previous page number, or null if there is no previous page */
  previous: number | null;
  /** The next page number, or null if there is no next page */
  next: number | null;
  /** The current page number */
  page: number;
  /** The maximum amount of items which can appear for a page */
  limit: number;
  /**
   * Event fired when the page is changed
   *
   * @param page The new page number
   */
  onPageChange: (page: number) => void;
  /**
   *  Event fired when the limit is changed
   *
   *  @param limit The new pagination page limit
   */
  onLimitChange: (limit: number) => void;
}

export const PaginationController = ({
  count,
  previous,
  next,
  page,
  limit,
  onPageChange,
  onLimitChange
}: Props): JSX.Element => {
  const [controlledCount, setControlledCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!count) {
      // Don't update the count if it's 0, undefined or null for some reason
      return;
    }

    // Only update the total count of items when it's updated to a non-zero value. When the paginated items are
    // changed, this will temporarily be 0 but we don't want to display that, since the total count won't actually
    // have changed
    setControlledCount(count);

    const totalPages = Math.ceil(count / limit);

    if (!isNaN(totalPages)) {
      // When the component controlling <PaginationController> updates its paginated API call, there will be a time
      // when the loading state is pending, and resultsCount can be 0. We don't want to update in this case because the
      // resulting division will result in NaN, i.e. 1000 / 0
      setTotalPages(totalPages);
    }
  }, [count, limit]);

  useLayoutEffect(() => {
    if (!pageInputRef.current) {
      return;
    }

    if (pageInputRef.current.valueAsNumber !== page) {
      pageInputRef.current.value = page.toString();
    }
  }, [page]);

  const handleLeftClick = (): void => {
    if (previous) {
      onPageChange(page - 1);
    }
  };

  const handleRightClick = (): void => {
    if (next) {
      onPageChange(page + 1);
    }
  };

  const handleLimitChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      onLimitChange(value);
    }
  };

  const handleManualPageKeyDown = (
    e: KeyboardEvent<HTMLInputElement>
  ): void => {
    const value = e.currentTarget.valueAsNumber;
    if (e.key === "Enter") {
      if (value && !isNaN(value)) {
        onPageChange(value);
      }
    }

    if (value > totalPages) {
      e.currentTarget.value = totalPages.toString();
    }
  };

  const handleManualPageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.valueAsNumber;

    if (value > totalPages) {
      // Cap the page number at the total amount of pages
      e.target.value = totalPages.toString();
    }

    if (value < 1) {
      // Don't let pages be negative
      e.target.value = "1";
    }
  };

  return (
    <div
      css={(theme) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.mq.md]: {
          gap: "40px",
          justifyContent: "center"
        }
      })}
    >
      <span>
        Total {controlledCount} item
        {(controlledCount === 0 || controlledCount > 1) && "s"}
      </span>
      <div css={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <IconCaretLeft
          height={12}
          width={12}
          css={{
            opacity: previous ? undefined : "0.5",
            cursor: previous ? "pointer" : "default"
          }}
          onClick={handleLeftClick}
          data-testid="pagination-controller-previous"
        />
        <span css={{ display: "flex", gap: "4px", whiteSpace: "nowrap" }}>
          <input
            ref={pageInputRef}
            type="number"
            defaultValue={page}
            min={1}
            max={totalPages}
            css={(theme) => ({
              width: "24px",
              border: "0.5px solid #737373",
              borderRadius: "4px",
              textAlign: "center",
              [theme.mq.md]: { width: "48px" }
            })}
            onKeyDown={handleManualPageKeyDown}
            onChange={handleManualPageChange}
            data-testid="pagination-controller-page"
          />
          of {totalPages}
        </span>
        <IconCaretRight
          height={12}
          width={12}
          css={{
            opacity: next ? undefined : "0.5",
            cursor: next ? "pointer" : "default"
          }}
          onClick={handleRightClick}
          data-testid="pagination-controller-next"
        />
      </div>
      <Select
        defaultValue={limit}
        css={{ width: "auto" }}
        onChange={handleLimitChange}
        data-testid="pagination-controller-limit"
      >
        {[10, 25, 50].map((value) => (
          <option key={value} value={value}>
            {value} / page
          </option>
        ))}
      </Select>
    </div>
  );
};
