import { Form } from "react-bootstrap";
import type { BasketLine } from "@mh/api";
import { Tooltip } from "@mh/components";

interface LineSummaryProps {
  /** The basket line to summarise */
  line: BasketLine;
  orderOnDemand?: {
    checked: boolean;
    setChecked: (value: boolean) => void;
  };
}

/**
 * Shows the product and pricing summary of a basket line.
 */
export const LineSummary = ({ line, orderOnDemand }: LineSummaryProps) => (
  <div
    css={(theme) => ({
      display: "flex",
      flexFlow: "column",
      gap: "8px",
      flex: 1,
      paddingLeft: "16px",
      paddingRight: "16px",
      [theme.mq.md]: {
        paddingLeft: "24px",
        paddingRight: "24px"
      }
    })}
  >
    <div className="d-flex flex-row justify-content-between">
      <div
        css={(theme) => ({
          color: "#262626",
          fontSize: "14px",
          fontWeight: "600",
          [theme.mq.md]: {
            fontSize: "16px"
          }
        })}
      >
        {line.product.title}
      </div>
      <div
        css={{
          color: "#262626",
          fontSize: "18px",
          fontWeight: "600"
        }}
      >
        ${line.price_incl_tax}
      </div>
    </div>
    {orderOnDemand && (
      <>
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            fontSize: "14px"
          }}
        >
          <span>
            Automatically send repeats
            <Tooltip
              infoIcon={true}
              iconWidth="16px"
              iconColor="#454545"
              maxWidth="450px"
              text="Turn this on if you would like us to automatically deliver your repeats when they are due."
            />
          </span>
          <Form.Switch
            checked={orderOnDemand.checked}
            onChange={(e) => {
              orderOnDemand.setChecked(e.target.checked);
            }}
            css={(theme) => ({
              marginTop: "auto",
              marginBottom: "auto",
              ".form-check-input": {
                width: "45.14px !important",
                height: "22.54px !important",
                marginTop: "0px",
                boxShadow: "unset",
                "&:checked": {
                  borderColor: theme.color.success,
                  backgroundColor: theme.color.success,
                  "--bs-form-check-bg": theme.color.success
                }
              }
            })}
            data-testid={`line-summary-switch-${line.id}`}
          />
        </div>
        {orderOnDemand.checked && (
          <div
            css={{
              fontSize: "12px",
              fontWeight: "500",
              color: "#262626"
            }}
          >
            Ships every {line.product.duration} days
          </div>
        )}
      </>
    )}
  </div>
);
