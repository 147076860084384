import { Button, IconArrowRight, Toast } from "@mh/components";
import { useNavigate } from "react-router-dom";

import "./HeroProductBanner.scss";
import { useContext } from "react";
import { NIBDetailPatientContext, PatientContext } from "@mh/api";
import { nibCategories } from "@mh/core/src/constants";
import { setNIBSearchParams } from "@mh/core";

interface HeroProduct {
  age?: boolean;
  file: string;
  title: React.ReactNode;
  titleYoung?: React.ReactNode;
  slug: string;
  slugYoung?: string;
  checkForExistingTreatment: boolean;
}

const femaleProducts: HeroProduct[] = [
  {
    age: true,
    file: "speak-to-dr.png",
    slug: "finelineswrinkles",
    slugYoung: "acne",
    checkForExistingTreatment: false,
    title: (
      <>
        Revitalise <i>Skin</i>
      </>
    ),
    titleYoung: <>Get smooth skin</>
  },
  {
    file: "lose-weight.png",
    slug: "weight_management",
    checkForExistingTreatment: true,
    title: (
      <>
        Lose <i>Weight</i>
      </>
    )
  },
  {
    file: "birth-control.png",
    slug: "contraceptive-pill",
    checkForExistingTreatment: true,
    title: (
      <>
        Find birth <i>control</i>
      </>
    )
  },
  {
    file: "medical-certificates.png",
    slug: "medicalcertificates",
    checkForExistingTreatment: false,
    title: (
      <>
        Medical <i>certificates</i>
      </>
    )
  }
];

const maleProducts: HeroProduct[] = [
  {
    age: true,
    file: "speak-to-dr-male.png",
    slug: "hair-loss",
    slugYoung: "acne",
    checkForExistingTreatment: false,
    title: (
      <>
        Grow amazing <i>hair</i>
      </>
    ),
    titleYoung: <>Get smooth skin</>
  },
  {
    file: "lose-weight-male.png",
    slug: "weight_management",
    checkForExistingTreatment: true,
    title: (
      <>
        Lose <i>Weight</i>
      </>
    )
  },
  {
    file: "premature-ejaculation.png",
    slug: "pe",
    checkForExistingTreatment: true,
    title: (
      <>
        Have longer <i>sex</i>
      </>
    )
  },
  {
    file: "medical-certificates-male.png",
    slug: "medicalcertificates",
    checkForExistingTreatment: false,
    title: (
      <>
        Medical <i>certificates</i>
      </>
    )
  }
];

interface HeroProductBannerProps {
  existingTreatmentCategorySlugs: string[];
}

export const HeroProductBanner = ({
  existingTreatmentCategorySlugs
}: HeroProductBannerProps) => {
  const navigate = useNavigate();
  const { data: patient } = useContext(PatientContext);
  const { data: nibData } = useContext(NIBDetailPatientContext);

  if (!patient) return null;

  const timeDiff = Math.abs(Date.now() - new Date(patient.dob).getTime());
  const isYoung = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25) < 30;

  const products = patient.gender === "male" ? maleProducts : femaleProducts;

  const onClick = (product: HeroProduct, isYoung: boolean) => {
    const slug = product.age && isYoung ? product?.slugYoung : product.slug;

    if (!slug) {
      return;
    }

    if (
      product.checkForExistingTreatment &&
      slug &&
      existingTreatmentCategorySlugs.includes(slug)
    ) {
      Toast.warning(
        "It looks like you’ve already accessed this treatment. Please go to your Treatments page to view next steps."
      );
      return;
    }

    const params = new URLSearchParams({ product: slug });

    if (nibData?.nib_membership_number && nibCategories.includes(slug)) {
      setNIBSearchParams(params, slug);
    }

    navigate(`/questionnaire/?${params.toString()}`, {
      state: {
        isNavLink: true,
        from: window.location.pathname
      }
    });
  };

  return (
    <div className="hero-product-banner">
      {products.map((product, index) => (
        <div
          key={index}
          className="product"
          css={{ background: `url('/assets/images/com/${product.file}')` }}
        >
          <span>
            {product[`${product.age && isYoung ? "titleYoung" : "title"}`]}
          </span>
          <Button onClick={() => onClick(product, isYoung)}>
            <span>Get Started</span>
            <IconArrowRight width={16} />
          </Button>
        </div>
      ))}
    </div>
  );
};
