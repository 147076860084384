import Common from "../common/common";

class Basenotification extends Common {
  constructor() {
    super();
    this.getNotification = this.getNotification.bind(this);
  }

  async getNotification(from = null) {
    this.logger(
      this.Config().STATE,
      "Basenotification",
      "getNotification",
      `Start`
    );

    let result_ = [];

    const result1_ = await this.Fetch(
      `${this.Config().HOST}/${
        this.Config().API
      }/getpatientnotifications/?brand=${this.capitalizeFirstLetter(
        this.Config().FROM
      ).trim()}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        })
      },
      `${from ? from + " ->" : ""}Basenotification`,
      "getNotification",
      false,
      false,
      null,
      false,
      true
    );

    const result1 = this.getResult(result1_, "getpatientnotifications");

    if (result1 && result1.iserror && result1.notoken) {
      result_ = "logout";
      return result_;
    }

    this.logger(
      this.Config().STATE,
      "Basenotification",
      "getNotification",
      `End`
    );
    return result1.getpatientnotifications;
  }
}

export default new Basenotification();
