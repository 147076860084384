export function classNames(...names: (string | boolean | undefined)[]): string {
  return names.filter((n) => !!n).join(" ");
}

export const sortArrayByFieldsAndDescending = (
  arrayToSort: [],
  sortFields: string[],
  descending: boolean[]
) => {
  return arrayToSort.sort((first, second) => {
    for (let i = 0; i < sortFields.length; i++) {
      const field = sortFields[i];
      const desc = descending[i];
      if (first[field] === second[field]) continue;
      if (first[field] < second[field]) {
        return desc ? 1 : -1;
      } else if (first[field] > second[field]) {
        return desc ? -1 : 1;
      }
    }
    return 0;
  });
};
