import * as React from "react";
import type { Address } from "./types";
import { CompleteLocation } from "@mh/components";

interface LocationInputLineProps {
  name: string;
  placeHolder: string;
  initialValue: string;
  onChange: (s: string) => void;
  required?: boolean;
  rowClass?: string | undefined;
  inputStyle?: (theme: any) => any | undefined;
}

// @ts-ignore
const inputCss = {
  fontSize: "14px",
  borderRadius: "12px !important",
  width: "100% !important",
  padding: "8px !important",
  marginLeft: "auto",
  marginRight: "auto",
  lineHeight: "135% !important",
  minHeight: "35px !important",
  height: "unset !important"
};

const labelClass = {
  fontSize: "14px",
  fontWeight: "500",
  paddingBottom: "4px",
  color: "var(--Body-Text-Text-Color, #000)"
};

class LocationInputLine extends React.Component<LocationInputLineProps, {}> {
  render = () => (
    <div className={this.props.rowClass} css={{ marginBottom: "24px" }}>
      <label htmlFor={this.props.name} className="asterisk" css={[labelClass]}>
        {this.props.placeHolder}
      </label>
      <div css={this.props.inputStyle}>
        <input
          css={[inputCss]}
          name={this.props.name}
          placeholder={this.props.placeHolder}
          id={this.props.name}
          required={this.props.required}
          value={this.props.initialValue}
          onChange={(e) => this.props.onChange(e.target.value)}
        />
      </div>
    </div>
  );
}

class StateInput extends React.Component<LocationInputLineProps, {}> {
  states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

  render = () => (
    <div className="d-flex flex-column fullwidth">
      <label htmlFor={this.props.name} className="asterisk" css={[labelClass]}>
        State
      </label>
      <div>
        <select
          name={this.props.name}
          id={this.props.name}
          className="fullwidth"
          css={{
            borderRadius: "12px",
            lineHeight: "135% !important",
            height: "35px",
            fontSize: "14px",
            padding: "8px",
            border: "1px solid var(--Theme-Secondary, #6C757D)"
          }}
          required={this.props.required}
          value={this.props.initialValue.toUpperCase()}
          onChange={(e) => this.props.onChange(e.target.value.toUpperCase())}
        >
          <option value=""></option>
          {this.states.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

interface LocationInputProps {
  id?: string;
  editable?: boolean;
  initiallyHidden?: boolean;
  onChange?: (newLocation: Address) => void;
  onFieldsFilledChange: (filled: boolean) => void;
  onFieldsCleared?: () => void;
  address: Address;
}

interface LocationInputState extends Address {
  fieldsFilled: boolean;
  autofilled: boolean;
  hasManualEdit: boolean;
}

class LocationInput extends React.Component<
  LocationInputProps,
  LocationInputState
> {
  constructor(props: LocationInputProps) {
    super(props);
    this.state = {
      addressLine1: props.address.addressLine1,
      addressLine2: props.address.addressLine2,
      suburb: props.address.suburb,
      state: props.address.state,
      postcode: props.address.postcode,
      fieldsFilled: false,
      autofilled: false,
      hasManualEdit: false
    };
  }

  handleAutofill = (newAddress: CompleteLocation, callback: () => void) => {
    this.setState(
      {
        addressLine1: newAddress.address_line1,
        addressLine2: newAddress.address_line2,
        suburb: newAddress.suburb,
        postcode: newAddress.postcode,
        state: newAddress.state?.toUpperCase(),
        autofilled: true,
        hasManualEdit: false
      },
      () => {
        this.props.onFieldsFilledChange(true);
        callback();
      }
    );
  };

  componentDidUpdate(_prevProps: LocationInputProps) {
    if (
      JSON.stringify(_prevProps.address) !== JSON.stringify(this.props.address)
    ) {
      this.setState({
        addressLine1: this.props.address.addressLine1,
        addressLine2: this.props.address.addressLine2,
        suburb: this.props.address.suburb,
        state: this.props.address.state?.toUpperCase(),
        postcode: this.props.address.postcode
      });
    }
    const fieldsFilledManually =
      !!this.state.addressLine1 &&
      !!this.state.suburb &&
      !!this.state.state &&
      !!this.state.postcode;
    const allFieldsFilled = fieldsFilledManually || this.state.autofilled;

    if (allFieldsFilled !== this.state.fieldsFilled) {
      this.setState({ fieldsFilled: allFieldsFilled }, () => {
        this.props.onFieldsFilledChange(allFieldsFilled);
      });
    }
  }

  updateState = (update: Partial<Address>, manualEdit = true) => {
    const newState = {
      ...this.state,
      ...update,
      hasManualEdit: manualEdit,
      autofilled: !manualEdit
    };
    this.setState(newState, () => {
      const isComplete =
        !!newState.addressLine1 &&
        !!newState.suburb &&
        !!newState.state &&
        !!newState.postcode;
      this.props.onFieldsFilledChange(isComplete);
      if (this.props.onChange) this.props.onChange(newState);
    });
  };

  containerClass = "inner-content row";

  render() {
    const { id, initiallyHidden } = this.props;

    return (
      <div
        id={id}
        className={this.containerClass}
        style={{ display: initiallyHidden ? "none" : undefined }}
      >
        <LocationInputLine
          name="addressLine1"
          placeHolder="Address Line 1"
          initialValue={this.state.addressLine1}
          onChange={(value) => this.updateState({ addressLine1: value })}
          required
        />
        <LocationInputLine
          name="addressLine2"
          initialValue={this.state.addressLine2}
          placeHolder="Address Line 2"
          onChange={(value) => this.updateState({ addressLine2: value })}
        />
        <LocationInputLine
          name="suburb"
          initialValue={this.state.suburb}
          placeHolder="Suburb"
          onChange={(value) => this.updateState({ suburb: value })}
          required
        />
        <div className="d-flex flex-lg-row flex-column">
          <LocationInputLine
            inputStyle={(theme: any) => ({
              [theme.mq.lg]: {
                paddingRight: "24px"
              }
            })}
            rowClass="d-flex flex-column fullwidth"
            name="postcode"
            initialValue={this.state.postcode}
            placeHolder="Postcode"
            onChange={(value) => this.updateState({ postcode: value })}
            required
          />
          <StateInput
            name="address_state"
            initialValue={this.state.state}
            placeHolder="State"
            onChange={(value) => this.updateState({ state: value })}
            required
          />
        </div>
      </div>
    );
  }
}

export default LocationInput;
