import { useRef } from "react";
import { Spinner } from "react-bootstrap";
import { OTPMethod, OTPRequestContent } from "@mh/api";
import { Button } from "../buttons";

interface LoginFormProps {
  loading: boolean;
  otpRequest: OTPRequestContent;
  onChange: (otpRequest: OTPRequestContent) => void;
  onSubmit: (method: OTPMethod) => void;
  userReentry?: boolean;
}

export const LoginForm = ({
  loading,
  otpRequest,
  onChange,
  onSubmit,
  userReentry
}: LoginFormProps) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit("sms");
  };

  const requestEmailHandler = () => {
    const form = formRef.current;
    if (form && !form.checkValidity()) {
      form.reportValidity();
    } else {
      onSubmit("email");
    }
  };

  const submitButton = (
    <Button variant="primary" css={{ margin: "8px 0" }} disabled={loading}>
      {loading ? (
        <span className="loadingouter position-relative">
          <Spinner animation="border" className="loading" size="sm" />
        </span>
      ) : (
        "Send code via SMS"
      )}
    </Button>
  );

  const form = (
    <form
      onSubmit={handleSubmit}
      className={userReentry ? "form reentryModal" : "form"}
      ref={formRef}
    >
      <label className="small asterisk" htmlFor="email">
        Email
      </label>
      <input
        required
        id="email"
        type="email"
        placeholder="Email"
        className="input"
        value={otpRequest?.email}
        onChange={(e) => onChange({ ...otpRequest, email: e.target.value })}
      />
      {submitButton}
    </form>
  );

  const help = (
    <div className={userReentry ? "notes reentryModal" : "notes"}>
      <div className="text-reentry">
        <span>Prefer to get a code via email? </span>
        <a className="link" onClick={requestEmailHandler}>
          Click here
        </a>
      </div>
      <div className="otp-description">
        We&apos;ve moved to One-Time Password (OTP) authentication for enhanced
        security and a smoother experience. An OTP is a unique, single-use code
        that provides an extra layer of account protection.
      </div>
    </div>
  );

  return (
    <>
      <div className={userReentry ? "title reentryModal" : "title"}>Login</div>
      {form}
      {help}
    </>
  );
};
