import { IconTruckFast } from "../icons";
import { BrandBanner } from "./BrandBanner";

interface Props {
  /** The discount to display. This could be a literal price i.e. "$10" or a percentage i.e. "10%" */
  discount: string;
}

export const OrderDiscountBanner = ({ discount }: Props) => (
  <BrandBanner>
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: "8px",
        fontSize: "20px"
      }}
    >
      <IconTruckFast
        css={(theme) => ({
          margin: "8px 0px",
          width: "40px",
          [theme.mq.md]: {
            width: "24px"
          }
        })}
      />
      <span css={{ flex: 1 }}>
        Get {discount} off your order when you add a recommended product
      </span>
    </div>
  </BrandBanner>
);
