import React, { useState } from "react";

import { API, ConcreteAPI } from "../api";
import { PatientNIBDetail } from "../patient";
import {
  createApiResourceContext,
  createApiResourceContextProvider
} from "./ApiResourceContext";

export const NIBDetailPatientContext =
  createApiResourceContext<PatientNIBDetail>({
    isFetching: false,
    data: null,
    response: null,
    error: null,
    get: async () => {},
    update: async () => {}
  });

const ContextProvider = createApiResourceContextProvider<PatientNIBDetail>(
  NIBDetailPatientContext
);

export const NIBDetailPatientContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [api] = useState<ConcreteAPI>(API.v2().url("/third-party/nib"));
  return <ContextProvider api={api}>{children}</ContextProvider>;
};
