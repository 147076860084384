import { type ComponentPropsWithoutRef } from "react";
import { type ProductAvailability as IProductAvailability } from "@mh/api";
import { Flex } from "../Flex";

interface Props extends ComponentPropsWithoutRef<"div"> {
  /** The product availability, of type {@link IProductAvailability} */
  availability: IProductAvailability;
}

export const ProductAvailability = ({
  availability,
  ...props
}: Props): JSX.Element | null =>
  availability.message === "Available" ? null : (
    <Flex
      css={(theme) => ({
        height: "20px",
        padding: "4px 8px",
        borderRadius: "10px",
        backgroundColor: "#F8F9FA",
        color: availability.is_available_to_buy
          ? theme.color.success
          : theme.color.danger,
        fontSize: "12px",
        width: "fit-content"
      })}
      alignItems="center"
      marginBetween="4px"
      {...props}
    >
      <span>{availability.message}</span>
    </Flex>
  );
