import React from "react";
import moment from "moment";

import "./Banner.scss";

interface ReplaceTxt {
  from: string;
  to: string;
}

interface Contents {
  type: string | undefined;
  tag: string | undefined;
  classes: string | undefined;
  text: string | undefined;
  img: string | undefined;
  path: string | undefined;
  txtGroup: Contents[] | undefined;
  backgroundImage?: boolean;
  excludeBrands?: string[];
}

interface ContentOuter {
  type: string;
  classes: string | undefined;
  contents: Contents[];
}

export interface BannerProps {
  id?: number;
  type: string;
  classes: string | undefined;
  left: ContentOuter | undefined;
  right: ContentOuter | undefined;
  replace: ReplaceTxt[] | undefined;
  start: string | undefined;
  end: string | undefined;
  brand: string;
}

let REPLACE: ReplaceTxt[] | undefined;

const createInnerContent = (
  innerContents: JSX.Element[],
  index: number,
  content: Contents,
  isTxtGroup: boolean,
  brand: string
) => {
  if (content.excludeBrands && content.excludeBrands.includes(brand)) {
    return;
  }

  REPLACE?.forEach((replace) => {
    if (content?.text?.includes("[[")) {
      content.text = content.text?.replace(replace.from, replace.to);
    }
    if (content?.path?.includes("[[")) {
      content.path = content.path?.replace(replace.from, replace.to);
    }
  });

  const ClassNames = content?.classes
    ?.replace("[[brand]]", brand === "hubhealth" ? "secondary" : "primary")
    .replace("[[HeaderFont]]", `${brand}HeaderFont`)
    .replace("[[BrandName]]", `${brand}`);

  if (content.type === "txtgroup") {
    const txtGroup: JSX.Element[] = [];
    content.txtGroup?.forEach((content, index) => {
      createInnerContent(txtGroup, index, content, true, brand);
    });
    innerContents.push(
      React.createElement(
        content?.tag ?? "div",
        { key: `txtgroup-${index}`, className: ClassNames },
        txtGroup
      )
    );
  } else if (content.type === "img") {
    if (isTxtGroup) {
      innerContents.push(
        <span>
          <img key={`img-${index}`} className={ClassNames} src={content.path} />
        </span>
      );
    } else {
      if (content.backgroundImage) {
        const imgUrl = `url(${content.path})`;
        innerContents.push(
          <div
            key={`img-bg-${index}`}
            className={ClassNames}
            style={{ backgroundImage: imgUrl }}
          />
        );
      } else {
        innerContents.push(
          <img key={`img-${index}`} className={ClassNames} src={content.path} />
        );
      }
    }
  } else if (content.type === "link") {
    if (isTxtGroup) {
      innerContents.push(
        <span key={`link-${index}`}>
          <a
            target="_blank"
            rel="noreferrer"
            className={ClassNames}
            href={content.path}
          >
            {content.text}
          </a>
        </span>
      );
    } else {
      innerContents.push(
        <a
          target="_blank"
          rel="noreferrer"
          key={`link-${index}`}
          className={ClassNames}
          href={content.path}
        >
          {content.text}
        </a>
      );
    }
  } else {
    innerContents.push(
      React.createElement(
        isTxtGroup ? "span" : content?.tag ?? "div",
        { key: `div-${index}`, className: ClassNames },
        content.text
      )
    );
  }
};

const getContent = (
  type: string,
  content: ContentOuter,
  extraClass: string,
  brand: string
) => {
  const innerContents: JSX.Element[] = [];
  content.contents.forEach((content, ix) => {
    createInnerContent(innerContents, ix, content, false, brand);
  });

  return (
    <div
      className={`inner ${extraClass} ${
        type === "left" || type === "right"
          ? `${type}Full`
          : type === "rightimg"
          ? content.type === "right"
            ? "rightimg"
            : "leftContent"
          : "fullWidth"
      } ${content.classes}`}
    >
      {innerContents}
    </div>
  );
};

export const Banner: React.FC<BannerProps> = (props) => {
  const TYPE = props.type;
  const LEFT: ContentOuter | undefined =
    TYPE !== "right" ? props.left : undefined;
  const RIGHT: ContentOuter | undefined =
    TYPE !== "left" ? props.right : undefined;
  REPLACE = props.replace ?? undefined;

  if (
    (props.start &&
      moment(props.start).isValid() &&
      moment().isBefore(props.start)) ||
    (props.end && moment(props.end).isValid() && moment().isAfter(props.end))
  ) {
    return <div className="d-none"></div>;
  }

  const left = LEFT
    ? getContent(
        TYPE,
        LEFT,
        TYPE === "left" ? "left-only" : "full-left",
        props.brand
      )
    : undefined;
  const right = RIGHT
    ? getContent(
        TYPE,
        RIGHT,
        TYPE === "right" ? "right-only" : "full-right",
        props.brand
      )
    : undefined;

  return (
    <div
      className={`d-flex flex-lg-row flex-column banner-box ${props.classes}`}
    >
      {left}
      {right}
    </div>
  );
};
