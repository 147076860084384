import { ComponentPropsWithoutRef } from "react";

export const IconClock = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 3.5C7.5 3.25 7.71875 3 8 3C8.25 3 8.5 3.25 8.5 3.5V7.75L11.25 9.59375C11.5 9.75 11.5625 10.0625 11.4062 10.2812C11.25 10.5312 10.9375 10.5938 10.7188 10.4375L7.71875 8.4375C7.5625 8.34375 7.46875 8.1875 7.46875 8L7.5 3.5ZM8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM1 8C1 11.875 4.125 15 8 15C11.8438 15 15 11.875 15 8C15 4.15625 11.8438 1 8 1C4.125 1 1 4.15625 1 8Z"
      fill="currentColor"
    />
  </svg>
);
