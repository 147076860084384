import React, { forwardRef } from "react";

import CommonRow from "../../components/common/commrow";
import COMMTXT from "../common/commontext";

const Notificationtemplate = forwardRef((props, ref) => {
  const commontxt = COMMTXT.newnotifications;

  function getHeader(type, data, fns) {
    let result = "";
    if (type === "repeats") {
      let showRepeatBt = fns.isActiveRepeat
        ? fns.isActiveRepeat(data.last_repeat_questionnaire?.active_date)
        : "";
      result = (
        <div className="header row mb-2 border-bottom">
          <div className="name left col-12 col-md-6 text-sm-center text-md-start">
            <h2 className="frequent-font-color">{data.product}</h2>
          </div>
          {showRepeatBt && (
            <div className="date right col-12 col-md-6 text-lg-end v-center frequent-font-color">
              <button
                className="btn btn-primary fullwidth-sm mb-2 useraction"
                title={"renew"}
                subtype="link"
                onClick={fns.repeatsQuestionnaire(data)}
              >
                Renew treatment
              </button>
            </div>
          )}
        </div>
      );
    } else if (type === "noncurrent") {
      result = (
        <div className="header row mb-2 border-bottom">
          <div className="name left col-12 col-md-6 text-md-start text-sm-center">
            <h2 className="frequent-font-color">{data.product}</h2>
          </div>
          <div className="date right col-12 col-md-6 text-lg-end v-center frequent-font-color">
            <a className="btn btn-primary long useraction" href={`/team`}>
              Please contact our customer support team
            </a>
          </div>
        </div>
      );
    }
    return result;
  }

  function getContent(type, data, fns) {
    let result = "";
    if (type === "repeats") {
      result = (
        <div>
          <CommonRow
            _class={"col-12 column"}
            _classsub={"v-center"}
            left={"img"}
            small={true}
            img={`/assets/images/com/doctor.png`}
          >
            <CommonRow
              _class={"col-12 column"}
              _classsub={"v-center"}
              _imgclass={"half"}
              small={true}
              left={"img"}
              img={`assets/images/com/pill.png`}
            >
              {data.brand_pill}
            </CommonRow>
            <div className="mt-2"></div>
            {data.stage ? (
              data.stage === 1 ? (
                <p>{commontxt.repeats.stage1}</p>
              ) : data.stage === 2 ? (
                <p>{commontxt.repeats.stage2}</p>
              ) : data.stage === 3 ? (
                <p>{commontxt.repeats.stage3}</p>
              ) : data.stage === 4 ? (
                data.expiry_date ? (
                  <p>
                    {" "}
                    {commontxt.repeats.stage4_1_1} {data.expiry_date}!
                  </p>
                ) : (
                  <p>{commontxt.repeats.stage4_1_2}</p>
                )
              ) : (
                ""
              )
            ) : (
              <div>
                <p>
                  {commontxt.repeats.stage4_2.replace(
                    "[data.product]",
                    data.product
                  )}
                </p>
                <p>{commontxt.repeats.stage4_3}</p>
                <p>{commontxt.repeats.stage4_4}</p>
                <p>{commontxt.repeats.stage4_5}</p>
                <p>{commontxt.repeats.stage4_6}</p>
              </div>
            )}
            {data.stage ? (
              <div>
                <p>{commontxt.repeats.stageall_1}</p>
                <p>{commontxt.repeats.stageall_2}</p>
                <p>{commontxt.repeats.stageall_3}</p>
                <p>{commontxt.repeats.stageall_4}</p>
              </div>
            ) : (
              ""
            )}
          </CommonRow>
        </div>
      );
    } else if (type === "noncurrent") {
      result = (
        <div>
          <CommonRow
            _class={"col-12 column"}
            _classsub={"v-center"}
            left={"img"}
            small={true}
            img={`/assets/images/com/doctor.png`}
          >
            <CommonRow
              _class={"col-12 column"}
              _classsub={"v-center"}
              _imgclass={"half"}
              small={true}
              left={"img"}
              img={`assets/images/com/pill.png`}
            >
              {data.brand_pill}
            </CommonRow>
            <p className="mt-2">{commontxt.noncurrent.desc}</p>
          </CommonRow>
        </div>
      );
    } else if (type === "script_upload") {
      result = (
        <div>
          <CommonRow
            _class={"col-12 column"}
            _classsub={"v-center"}
            left={"img"}
            small={true}
            img={`/assets/images/com/doctor.png`}
          >
            <CommonRow
              _class={"col-12 column"}
              _classsub={"v-center"}
              _imgclass={"half"}
              small={true}
              left={"img"}
              img={`assets/images/com/pill.png`}
            >
              {data.brand_pill}
            </CommonRow>
            <p className="mt-2">{commontxt.uploadscript.desc}</p>
          </CommonRow>
        </div>
      );
    }
    return <div className="body row">{result}</div>;
  }

  return (
    <div className="inner-box">
      {getHeader(props.data.obj.type, props.data.obj, props.fns)}
      {getContent(props.data.obj.type, props.data.obj, props.fns)}
    </div>
  );
});

export default Notificationtemplate;
