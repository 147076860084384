import { ComponentPropsWithoutRef, PropsWithChildren, ReactNode } from "react";
import { Interpolation } from "@emotion/react";
import { IconCheckCircle, IconCircleExclamation } from "../icons";
import { Theme } from "../theme";

interface StatusProps extends ComponentPropsWithoutRef<"div"> {
  variant: Extract<keyof Theme["color"], "success" | "danger">;
  css?: Interpolation<Theme>;
}

const ICON_MAP: Record<StatusProps["variant"], ReactNode> = {
  success: <IconCheckCircle css={{ width: "24px", height: "24px" }} />,
  danger: <IconCircleExclamation css={{ width: "24px", height: "24px" }} />
};

export const TitleStatus = ({
  children,
  css,
  variant,
  ...props
}: PropsWithChildren<StatusProps>) => (
  <div
    css={[
      (theme) => ({
        display: "flex",
        alignItems: "center",
        gap: "8px",
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "uppercase",
        color: theme.color[variant]
      }),
      css
    ]}
    {...props}
  >
    {ICON_MAP[variant]}
    {children}
  </div>
);
