import { useState } from "react";
import { Button } from "react-bootstrap";
import { Conversation, MessagingAPI } from "@mh/api";
import { SendMessageIcon } from "./icons";

interface Props {
  conversation: Conversation;
  loadSelectedConversation: (conversation: Conversation) => void;
}

export function SendMessageBar({
  conversation,
  loadSelectedConversation
}: Props) {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>("");

  const sendMessage = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!newMessage) {
      return;
    }
    setIsSending(true);
    const response = await MessagingAPI.sendMessage(conversation.id, {
      content: newMessage
    });
    if (response.ok) {
      loadSelectedConversation(conversation);
      setNewMessage("");
    }
    setIsSending(false);
  };

  return (
    <div className="py-4 px-4 w-100" data-testid="send-message-bar">
      <form
        onSubmit={sendMessage}
        className="d-flex flex-row"
        aria-label="send-message-form"
      >
        <input
          id="new-message"
          className="flex-fill p-4 border-0 bg-light rounded"
          css={(theme) => ({
            "&:focus": {
              outline: "none !important",
              border: `2px solid ${theme.color.primary} !important`
            }
          })}
          placeholder="Type a message..."
          value={newMessage}
          autoComplete="off"
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <div className="ps-4">
          <Button
            type="submit"
            variant="white"
            disabled={!newMessage || isSending}
            className="w-100 py-3 border-0"
            data-testid="send-message-button"
          >
            <SendMessageIcon />
          </Button>
        </div>
      </form>
    </div>
  );
}
