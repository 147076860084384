import { ComponentPropsWithoutRef } from "react";

export const IconCaretOutlineLeft = (
  props: ComponentPropsWithoutRef<"svg">
) => (
  <svg
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0156 6.50537C13.7344 6.22412 13.2188 6.22412 12.9375 6.50537L7.6875 11.7554C7.54688 11.896 7.5 12.0835 7.5 12.271C7.5 12.5054 7.54688 12.6929 7.6875 12.8335L12.9375 18.0835C13.2188 18.3647 13.7344 18.3647 14.0156 18.0835C14.2969 17.8022 14.2969 17.2866 14.0156 17.0054L9.28125 12.271L14.0156 7.5835C14.2969 7.30225 14.2969 6.78662 14.0156 6.50537ZM12 0.270996C5.34375 0.270996 0 5.66162 0 12.271C0 18.9272 5.34375 24.271 12 24.271C18.6094 24.271 24 18.9272 24 12.271C24 5.66162 18.6094 0.270996 12 0.270996ZM12 22.771C6.1875 22.771 1.5 18.0835 1.5 12.271C1.5 6.50537 6.1875 1.771 12 1.771C17.7656 1.771 22.5 6.50537 22.5 12.271C22.5 18.0835 17.7656 22.771 12 22.771Z"
      fill="currentColor"
    />
  </svg>
);
