import { ComponentPropsWithoutRef } from "react";

export const IconArrowRight = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1875 7.71875L9.1875 12.7188C8.8125 13.125 8.15625 13.125 7.78125 12.7188C7.375 12.3438 7.375 11.6875 7.78125 11.3125L11.0625 8H1.5C0.9375 8 0.5 7.5625 0.5 7C0.5 6.46875 0.9375 6 1.5 6H11.0625L7.78125 2.71875C7.375 2.34375 7.375 1.6875 7.78125 1.3125C8.15625 0.90625 8.8125 0.90625 9.1875 1.3125L14.1875 6.3125C14.5938 6.6875 14.5938 7.34375 14.1875 7.71875Z"
      fill={props.fill || "white"}
    />
  </svg>
);
