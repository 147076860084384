import { useEffect, useState } from "react";
import { API, Country } from "@mh/api";
import { Validation } from "@mh/core";

import {
  ProfileForm,
  ProfileFormError,
  ProfileFormValues
} from "../../../../src/components/forms";
import { ShippingAddressNew } from "../../../../src/components/forms/QuestionnaireRepeat";

import { LocationAutocomplete, CompleteLocation } from "@mh/components";

// Australia only
export const COUNTRY_CODE_AUSTRALIA = "AU";

export const ADDRESS_STATE_OPTIONS: [string, string][] = [
  ["act", "ACT"],
  ["nsw", "NSW"],
  ["nt", "NT"],
  ["qld", "QLD"],
  ["sa", "SA"],
  ["tas", "TAS"],
  ["vic", "VIC"],
  ["wa", "WA"]
];

// see: https://postcode.auspost.com.au/free_display.html?id=1
// note as per that document, all three digit postcodes are prefixed with a zero
// const VALID_POSTCODES = {
//   nsw: [
//     [1000, 2599],
//     [2620, 2899],
//     [2921, 2999]
//   ],
//   vic: [
//     [3000, 3999],
//     [8000, 8999]
//   ],
//   qld: [
//     [4000, 4999],
//     [9000, 9999]
//   ],
//   sa: [[5000, 5999]],
//   wa: [[6000, 6999]],
//   tas: [[7000, 7999]],
//   act: [
//     [200, 299],
//     [2600, 2619],
//     [2900, 2920]
//   ],
//   nt: [[800, 999]]
// };

export interface ShippingAddressValues extends ProfileFormValues {
  line1: string;
  line2: string;
  suburb: string;
  state: string;
  postcode: string;
}

export interface ShippingAddressProps {
  /* Optional callback function.  If provided, function will be called when the isEditing state changes. */
  isEditingCallback?: (isEditing: boolean) => void;
  /* value is true if it is from questionnaire */
  isQuestionnaire?: boolean | undefined;
  /* Optional flag to render the component in a compact view used in the checkout page */
  compactView?: boolean;
  /* when selecet address, will save automatically without clicking save button */
  autoSave?: boolean;
}

export const setShippingAddressFromAddressAutoComplete = (
  location: CompleteLocation,
  updateFn: (v: ShippingAddressValues) => void,
  update?: ((v: ShippingAddressValues) => void) | null,
  setIsEditing?: ((v: boolean) => void) | null
) => {
  updateFn({
    line1: location.address_line1 || "",
    line2: location.address_line2 || "",
    suburb: location.suburb || "",
    state: location.state || "",
    postcode: location.postcode || ""
  });

  if (update) {
    update({
      line1: location.address_line1 || "",
      line2: location.address_line2 || "",
      suburb: location.suburb || "",
      state: location.state || "",
      postcode: location.postcode || ""
    });
  }
  if (setIsEditing) {
    setIsEditing(false);
  }
};

export const ShippingAddress = ({
  isEditingCallback,
  isQuestionnaire,
  compactView,
  autoSave
}: ShippingAddressProps) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [address, setAddress] = useState<ShippingAddressValues>();
  const [country, setCountry] = useState<Country>();
  const [showManualAddress, setShowManualAddress] = useState<boolean>(false);

  const get = async () => {
    setIsFetching(true);
    const [addressResponse, countryResponse] = await Promise.all([
      API.url("/shop/api/useraddresses/").get(),
      API.url(`/shop/api/countries/${COUNTRY_CODE_AUSTRALIA}/`).get()
    ]);
    if (addressResponse.ok) {
      const data = await addressResponse.json();
      if (data.count) {
        setAddress(data.results[0]);
      }
    }
    if (countryResponse.ok) {
      setCountry(await countryResponse.json());
    }
    setIsFetching(false);
  };

  const update = async (newAddress: ShippingAddressValues) => {
    let response = null;
    if (address) {
      response = await API.url(`/shop/api/useraddresses/${address.id}/`)
        .body(newAddress)
        .patch();
    } else {
      response = await API.url("/shop/api/useraddresses/")
        .body({ ...newAddress, country: country?.url })
        .post();
    }

    if (response.ok) {
      setAddress(await response.json());
      setShowManualAddress(false);
    }
  };

  useEffect(() => {
    get();
  }, []);

  const renderManualEditForm = (
    values: ShippingAddressValues,
    setValues: (v: ShippingAddressValues) => void,
    error: ProfileFormError<ShippingAddressValues> | null,
    isEditing: boolean,
    id: string
  ) => (
    <>
      <ProfileForm.Control
        label="Address Line 1"
        data-testid={`${id}-address-line-1`}
        disabled={isFetching}
        isEditing={isEditing}
        error={error?.line1}
        value={values.line1}
        onChange={(e) => setValues({ ...values, line1: e.currentTarget.value })}
      />
      <ProfileForm.Control
        label="Address Line 2"
        data-testid={`${id}-address-line-2`}
        disabled={isFetching}
        isEditing={isEditing}
        error={error?.line2}
        value={values.line2}
        onChange={(e) => setValues({ ...values, line2: e.currentTarget.value })}
      />
      <ProfileForm.Control
        label="Suburb"
        data-testid={`${id}-suburb`}
        disabled={isFetching}
        isEditing={isEditing}
        error={error?.suburb}
        value={values.suburb}
        onChange={(e) =>
          setValues({ ...values, suburb: e.currentTarget.value })
        }
      />
      <ProfileForm.Select
        label="State"
        data-testid={`${id}-state`}
        disabled={isFetching}
        isEditing={isEditing}
        error={error?.state}
        value={values?.state}
        onChange={(e) => setValues({ ...values, state: e.currentTarget.value })}
      >
        <option disabled value=""></option>
        {ADDRESS_STATE_OPTIONS.map(([value, display], index) => (
          <option key={`${index}_${value}`} value={value}>
            {display}
          </option>
        ))}
      </ProfileForm.Select>
      <ProfileForm.Control
        label="Postcode"
        data-testid={`${id}-postcode`}
        disabled={isFetching}
        isEditing={isEditing}
        error={error?.postcode}
        value={values?.postcode}
        onChange={(e) =>
          setValues({ ...values, postcode: e.currentTarget.value })
        }
      />
    </>
  );

  const renderLinearAddress = (values: ShippingAddressValues) =>
    `${values.line1}, ${values.line2 && `${values.line2}, `}
      ${values.suburb}, ${values.state}, ${values.postcode}`;

  return (
    <ProfileForm<ShippingAddressValues>
      title="Shipping Address"
      id="shipping-address"
      initialValues={{
        line1: address?.line1 || "",
        line2: address?.line2 || "",
        suburb: address?.suburb || "",
        state: address?.state || "",
        postcode: address?.postcode || ""
      }}
      isFetching={isFetching}
      onSave={async (values) => await update(values)}
      onCancel={() => setShowManualAddress(false)}
      validate={(values) => ({
        line1: Validation.required().validate(values.line1),
        line2: null,
        suburb: Validation.required().validate(values.suburb),
        state: Validation.required().validate(values.state),
        postcode: Validation.required()
          .regex(/^\d\d\d\d$/, {
            message: "Postcode must be four digits long."
          })
          .validate(values.postcode)
      })}
      isEditingCallback={isEditingCallback}
      isQuestionnaire={isQuestionnaire}
    >
      {({ values, setValues, error, isEditing, id, setIsEditing }) => (
        <>
          {isEditing && (
            <div style={{ marginBottom: 20 }}>
              <LocationAutocomplete
                style={{ width: "100%", color: "black" }}
                onSelectedLocation={(location) =>
                  setShippingAddressFromAddressAutoComplete(
                    location,
                    setValues,
                    autoSave ? update : null,
                    autoSave ? setIsEditing : null
                  )
                }
              />
              <a
                className="manual-link"
                onClick={() => setShowManualAddress(!showManualAddress)}
              >
                Enter your address manually
              </a>
            </div>
          )}
          {!isQuestionnaire ? (
            !isEditing && compactView ? (
              <div className="addressText">{renderLinearAddress(values)}</div>
            ) : (
              (!isEditing || showManualAddress) &&
              renderManualEditForm(values, setValues, error, isEditing, id)
            )
          ) : (
            <>
              <ShippingAddressNew
                values={values}
                setValues={setValues}
                error={error}
                isEditing={isEditing}
                id={id}
                isFetching={isFetching}
                showManualAddress={showManualAddress}
              />
            </>
          )}
        </>
      )}
    </ProfileForm>
  );
};
