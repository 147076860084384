export default [
  {
    id: 10001,
    title: "What is your name?",
    subtitle: `This will enable us to personalise your {"type": "cond", "cond": "eq", "field": "isCert", "nok": "treatment plan", "ok": "medical certificate (note that this is the name that will be shown on the certificate)"}`,
    type: "name",
    questionType: "common",
    ga_id: "A0002",
    skipForNib: true
  },
  {
    id: 10000,
    title: "What is your email address?",
    subtitle: `This is how we will notify you about any updates to your {"type": "cond", "cond": "eq", "field": "isCert", "nok": "treatment plan", "ok": "medical certificate"}`,
    type: "singleEmail",
    questionType: "common",
    ga_id: "A0001",
    skipForNib: true
  },
  {
    id: 10002,
    title: "What is your Date of Birth?",
    type: "dob",
    questionType: "common",
    ga_id: "A0004",
    skipForNib: true
  },
  {
    id: 10003,
    title: "What is your address?",
    type: "address",
    questionType: "common",
    ga_id: "A0005"
  }
];
