import { API } from "@mh/api";
import {
  Condition,
  ConversationUpdateRequest,
  MessageRequest,
  UnreadMessagesResponse
} from "./types";

export class MessagingAPI {
  /* Loads all conversations */
  static getConversations = async (): Promise<Response> =>
    API.v2().url("/message-broker/conversations").get();

  /** Loads specific conversation */
  static getConversation = async (conversationId: number): Promise<Response> =>
    API.v2().url(`/message-broker/conversations/${conversationId}`).get();

  /** Send a message to the converstation given by conversationId */
  static sendMessage = async (
    conversationId: number,
    data: MessageRequest
  ): Promise<Response> =>
    API.v2()
      .url(`/message-broker/conversations/${conversationId}/messages`)
      .json()
      .body(data)
      .post();

  /** Create a conversation */
  static createConversation = async (
    subject: string,
    message: { content: string },
    condition: Condition
  ): Promise<Response> =>
    API.v2()
      .url("/message-broker/conversations")
      .json()
      .body({
        subject: subject,
        initial_message: message,
        condition: {
          questionnaire_id: condition.questionnaire_id,
          questionnaire_type: condition.type
        }
      })
      .post();

  static updateConversation = async (
    conversationId: number,
    conversationStatus: ConversationUpdateRequest
  ): Promise<Response> =>
    API.v2()
      .url(`/message-broker/conversations/${conversationId}`)
      .json()
      .body(conversationStatus)
      .patch();

  static createZendeskRequest = async (
    subject: string,
    description: string
  ): Promise<Response> =>
    API.v2()
      .url("/message-broker/support-requests")
      .json()
      .body({
        subject: subject,
        description: description
      })
      .post();

  static getUnreadMessagesCount = async (): Promise<UnreadMessagesResponse> =>
    API.v2()
      .url("/message-broker/conversations/unread-messages-count")
      .get()
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      });
}
