import { ComponentProps, ReactNode } from "react";

import { TitleStatus } from "../../status";

interface StatusProps {
  /** A set of actions (usually buttons) to render under the status content. */
  actions?: ReactNode;
  /** Often a text description of the status. */
  children: ReactNode;
  /** Title of the status. */
  title?: string;
  /** Title variant. */
  titleVariant?: ComponentProps<typeof TitleStatus>["variant"];
}

export const Status = ({
  actions,
  children,
  title,
  titleVariant = "success"
}: StatusProps) => (
  <div
    css={{
      width: "100%",
      display: "flex",
      flexFlow: "column",
      gap: "12px"
    }}
  >
    <div>
      {title && (
        <TitleStatus css={{ marginBottom: "8px" }} variant={titleVariant}>
          {title}
        </TitleStatus>
      )}
      {children}
    </div>
    <div css={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      <div
        css={(theme) => ({
          width: "100%",
          display: "flex",
          flexFlow: "column",
          gap: "16px",
          justifyContent: "end",
          alignItems: "end",
          ">*": {
            width: "100%"
          },
          [theme.mq.md]: {
            width: "auto",
            display: "inline-grid",
            gridAutoColumns: "1fr",
            gap: "24px",
            ">*": {
              gridRow: 1
            }
          }
        })}
      >
        {actions}
      </div>
    </div>
  </div>
);
