import {
  ComponentPropsWithoutRef,
  forwardRef,
  SyntheticEvent,
  useEffect,
  useId
} from "react";
import { createPortal } from "react-dom";
import { Global } from "@emotion/react";

export interface OverlayProps
  extends Omit<ComponentPropsWithoutRef<"div">, "onKeyDown"> {
  /**
   * Optional, whether to disable the desktop background content from scrolling or not.
   */
  disableBackgroundScrollDesktop?: boolean;
  /**
   * Optional, whether to disable the mobile background content from scrolling or not.
   */
  disableBackgroundScrollMobile?: boolean;
  /**
   * Optional mouse click event handler. Only fires if the overlay is clicked,
   * not any of it's children.
   */
  onClick?: (e: SyntheticEvent) => void;
  /**
   * Optional keyboard event handler, allows the caller to listen for events
   * such as the "Escape" key to hide the overlay.
   */
  onKeyDown?: (e: KeyboardEvent) => void;
  /** Whether to show or hide the overlay. */
  show: boolean;
  /**
   * The target container for the overlay to render over. Defaults to `document.body`.
   */
  targetContainer?: Element;
}

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      children,
      disableBackgroundScrollDesktop = true,
      disableBackgroundScrollMobile = true,
      onClick,
      onKeyDown,
      show,
      targetContainer,
      ...props
    },
    ref
  ) => {
    const noScrollClassName = `overlay-noscroll-${useId().replace(/:/g, "")}`;
    const container = targetContainer || document.body;

    const handleOnClick = (e: SyntheticEvent) => {
      // Only fire when the overlay itself is clicked
      if (onClick && e.currentTarget === e.target) {
        onClick(e);
      }
    };

    useEffect(() => {
      if (!onKeyDown) {
        return;
      }

      window.addEventListener("keydown", onKeyDown);

      return () => {
        window.removeEventListener("keydown", onKeyDown);
      };
    }, [onKeyDown]);

    /** Disable scrolling on the target container when the overlay is shown. */
    useEffect(() => {
      if (show) {
        container.classList.add(noScrollClassName);
      } else {
        container.classList.remove(noScrollClassName);
      }

      return () => {
        container.classList.remove(noScrollClassName);
      };
    }, [show]);

    return createPortal(
      <>
        <Global
          styles={(theme) => ({
            [`.${noScrollClassName}`]: {
              overflow: disableBackgroundScrollMobile ? "hidden" : "auto",
              [theme.mq.md]: {
                overflow: disableBackgroundScrollDesktop ? "hidden" : "auto"
              }
            }
          })}
        />
        <div
          css={{
            transform: show ? "none" : "scaleY(0)",
            position: "fixed",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            backgroundColor: "rgb(0, 0, 0, 0.75)",
            zIndex: 9999
          }}
          onClick={handleOnClick}
          data-testid={show ? "overlay-show" : "overlay-hide"}
          ref={ref}
          {...props}
        >
          {children}
        </div>
      </>,
      container
    );
  }
);
