let Afteruserquestions = [
  {
    id: 5000,
    skip: [
      "I would like my medication to be delivered to my door via Express Post"
    ],
    skipgid: "RS0100",
    title: "Do you have a Medicare card?",
    subtitle:
      "This enables us to verify your identity and process your order faster",
    type: "medicare",
    ga_id: "A0009",
    questionType: "common"
  }
];

export default Afteruserquestions;
