const end = [
  {
    name: "done_script_only",
    titletag: "h2",
    title: "Almost done!",
    subtitletag: "p",
    subtitleclass_: "sub-title font-18 pb-6 pt-6",
    subtitle:
      "Once payment has been completed, our medical team will review your request. Prescriptions for approved requests are typically sent to you within 2 business hours (8am-8pm AEST).",
    contentsclass: "row",
    contentclasstop: "row bggray btlr-08 btrr-08",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: 'Pay ${"type":"text", "field": "yes", "data": "consultfee"} consultation fee',
      class_: "submitbtcolor submitcolor"
    },
    signup: {
      centerClass: " col-lg-9"
    },
    createaccount: {
      label: {
        class_: " newgraycolor"
      }
    },
    contents: [
      {
        class_: "col-1 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-10",
        type: "card",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-1 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      }
    ]
  },
  {
    name: "nibinternational",
    titletag: "h1",
    titleclass_: "top-title strong pt-3 pb-2 d-none",
    title: " ",
    undertitleclass_: "extra-title",
    undertitleDefault: {
      logintext:
        "Thanks for answering your health assessment. Our medical team will review your answers and be in touch within 24 hours.",
      text: "Finish creating your account, and your clinician will review your answers and be in touch within 24 hours."
    },
    undertitle: [
      {
        positionLogin: "bottom",
        product: "medicalcertificates",
        logintext:
          "Thanks for answering your health assessment. Our medical team will review your request. Medical certificates for approved requests are typically emailed to you within 2 business hours (8am-8pm AEST)",
        text: "Thanks for answering your health assessment. Once your account has been created, our medical team will review your request. Medical certificates for approved requests are typically emailed to you within 2 business hours (8am-8pm AEST)"
      },
      {
        positionLogin: "top",
        product: "telehealth_consult",
        logintext: "Thanks for completing your online health assessment.",
        text: "Thanks for completing your online health assessment."
      }
    ],
    subtitleclass_: "newSubtitle",
    subtitle: "WHAT’S NEXT?",
    headercontentsclass: " row header-content pt-6",
    contentsclass: " row content-box",
    noPromo: true,
    nopayment: true,
    type: "grid",
    nextButton: true,
    useButtonText: true,
    buttonText: {
      text: "Create Account",
      loginText: "Continue"
    },
    promo: {
      btcolor: "white"
    },
    signup: {
      first: true,
      class_: " ",
      signupBlockClass: " bggray",
      noLeft: true,
      signupHeaderClass: " text-center strong signupHeader",
      signupHeaderDefault:
        "Create your account below to access your treatment plan once ready.",
      signupHeader: [
        {
          product: "medicalcertificates",
          title:
            "Create your account to confirm your request for a medical certificate."
        },
        {
          product: "telehealth_consult",
          title:
            "Create your account to confirm your request for a telehealth consultation.",
          subTitle:
            "You can book a time for your consultation or join the queue for an immediate call at the next step."
        }
      ],
      loginHeader: [
        {
          product: "medicalcertificates",
          title:
            "Create your account to confirm your request for a medical certificate."
        },
        {
          product: "telehealth_consult",
          title: "Confirm your request for a telehealth consultation.",
          subTitle:
            "You can book a time for your consultation or join the queue for an immediate call at the next step."
        }
      ],
      mobileClass: " pt-6 pb-6"
    },
    titlecontents: [
      {
        class_: "col-12 col-md mb-3 content-block",
        outerclass_: " row inner-block",
        content: [
          {
            class_: " col-12 roof-txt",
            outerclass_:
              " roof-inner bblr-08 bbrr-08 position-relative white strong",
            type: "text",
            text: 'Save ${"type":"text", "field": "yes", "data": "consultfee"}'
          },
          {
            class_: " col-md-12 empty-col",
            type: "text",
            text: ""
          },
          {
            class_: " col-2 col-md-12 img-col text-start align-md-bottom",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/consultsubmit_nib-black.svg',
            excludeproduct: ["medicalcertificates", "telehealth_consult"]
          },
          {
            class_: " col-2 col-md-12 img-col text-start align-md-bottom",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/medcert-black.svg',
            product: "medicalcertificates"
          },
          {
            class_: " col-2 col-md-12 img-col text-start align-md-bottom",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/medcert-black.svg',
            product: "telehealth_consult"
          },
          {
            class_:
              "p-md-0 col-10 col-md-12 text-start align-md-bottom txt-block",
            type: "text",
            text: "Medical consultation - no out-of-pocket cost for nib OSHC members",
            excludeproduct: ["medicalcertificates", "telehealth_consult"]
          },
          {
            class_:
              "p-md-0 col-10 col-md-12 text-start align-md-bottom txt-block",
            type: "text",
            text: "Medical certificate - no out-of-pocket cost for nib OSHC members",
            product: "medicalcertificates"
          },
          {
            class_:
              "p-md-0 col-10 col-md-12 text-start align-md-bottom txt-block",
            type: "text",
            text: "Get convenient access to telehealth appointments - no out-of-pocket cost for nib OSHC members",
            product: "telehealth_consult"
          }
        ]
      },
      {
        class_: "col-12 col-md mb-3 content-block",
        outerclass_: " row inner-block",
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        content: [
          {
            class_: " col-12 roof-txt",
            outerclass_:
              " roof-inner bblr-08 bbrr-08 position-relative white strong",
            type: "text",
            text: "Save $10"
          },
          {
            class_: " col-md-12 empty-col",
            type: "text",
            text: ""
          },
          {
            class_: " col-2 col-md-12 img-col text-start align-md-bottom",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/deliverysubmit_nib-blck.svg'
          },
          {
            class_:
              "p-md-0 col-10 col-lg-12 text-start align-md-bottom txt-block",
            type: "text",
            text: "Delivery - no additional fee for nib OSHC members"
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "col-12 col-md mb-3 pt-sm-3-cs content-block",
        outerclass_: " row inner-block nib-logo-block",
        content: [
          {
            class_: " col-md-12 roof-txt noheight-sm",
            type: "text",
            text: ""
          },
          {
            class_: "col-md-12 empty-col",
            type: "text",
            text: ""
          },
          {
            class_: "col-md-12 img-col noheight-sm",
            type: "text",
            text: ""
          },
          {
            class_:
              "p-md-0 col-8 col-md-12 text-start align-md-bottom txt-block",
            type: "text",
            text: "working in partnership with"
          },
          {
            class_:
              "col-4 col-md-12 img-col text-start nib-logo align-md-bottom",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/nib-logo.svg'
          }
        ]
      },
      {
        excludeproduct: ["contraceptive_pill"],
        class_: "col-12 col-md mb-3 pt-sm-3-cs content-block",
        outerclass_: " row inner-block nib-logo-block",
        content: [
          {
            class_: " col-md-12 roof-txt noheight-sm",
            type: "text",
            text: ""
          },
          {
            class_: "col-md-12 empty-col",
            type: "text",
            text: ""
          },
          {
            class_: "col-md-12 img-col noheight-sm",
            type: "text",
            text: ""
          },
          {
            class_: "col-8 text-start prata-font align-md-bottom txt-block",
            type: "text",
            text: "working in partnership with"
          },
          {
            class_: "col-4 img-col text-start nib-logo align-md-bottom",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/nib-logo.svg'
          }
        ]
      }
    ],
    contents: [
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "col-lg-12 pb-5 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "btlr-08 bblr-08 bggray col-3 col-lg-3 pt-3 pb-3",
        content: [
          {
            class_: " fullwidth-img-sm-cs text-center",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "bggray btrr-08-sm col-9 col-lg-0",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_:
          "btrr-08 bbrr-08 bblr-08-sm bggray col-12 col-lg-9 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Review your treatment plan"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Our medical team will speak to you about your treatment and create a personalised treatment plan for your review. You can book a time for your consultation at the next step."
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "col-lg-12 pb-5 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "btlr-08 bblr-08 bggray col-3 col-lg-3 pt-3 pb-3",
        content: [
          {
            class_: " fullwidth-img-sm-cs text-center",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "bggray btrr-08-sm col-9 col-lg-0",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_:
          "btrr-08 bbrr-08 bblr-08-sm bggray ol-12 col-lg-9 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Delivered fast and at no extra cost."
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Simply head back to your portal and pay everyday pharmacy prices for your medication! We will deliver your medication direct to your door via Express Post at no extra cost."
          }
        ]
      },
      {
        excludeproduct: ["medicalcertificates", "telehealth_consult"],
        class_: "col-lg-12 pb-5 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      }
    ]
  },
  {
    name: "acceptonce_new",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    contentsclass: "row ",
    contentclasstop: "row bggray btlr-08 btrr-08",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: "Place order",
      class_: "submitbtcolor submitcolor"
    },
    promo: {
      btcolor: "white"
    },
    contents: [
      {
        class_: "col-12 col-lg-2 pt-3 v-center",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/pay.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-10 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "price__"} for your medication',
            product: "thrush"
          },
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "price__"} every month',
            product: "fertility"
          },
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "price__"} for the Morning After Pill.',
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: "Pay $50.00 for the Morning After Pill",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "yes" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " strong newgraycolor my-3",
            type: "text",
            text: "$30 + $20 same day delivery",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "yes" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "totalprice__"} ({"type":"text", "field": "yes", "data": "price__"} + {"type":"text", "field": "yes", "data": "shipCost"} postage & handling) for Emergency Contraception.',
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "yes" }
            ]
          }
        ]
      },
      {
        class_: "col-2 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-10",
        type: "card",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Your assigned pharmacist will issue your medication "
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "After payment, we will give you access to valuable information on how to best use your pack.",
            product: "fertility"
          },
          {
            class_: " newgraycolor",
            text: "You’ll receive a text & email when the pharmacist issues your medication. Your assigned pharmacist will reach out via the portal if they need further information.",
            excludeproduct: "fertility"
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Your medication is delivered direct to your door",
            product: "thrush"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Get your medication delivered to your door via Express Post.",
            product: "thrush"
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Your medication is delivered direct to your door",
            product: "fertility"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Get your medication delivered to your door via Express Post.",
            product: "fertility"
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Delivered same day",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "yes" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Same Day Shipping",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "We will ship your medication to you via our delivery partner Sherpa.",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Same Day Shipping",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "yes" }
            ]
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "We will ship your medication to you via our delivery partner Chemist2U.",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "yes" }
            ]
          }
        ]
      }
    ]
  },
  {
    name: "accept_repeat",
    title: "Thanks for completing your online assessment",
    subtitle: "What’s next?",
    subtitleclass_: "newSubtitle",
    contentsclass: "row",
    contentclasstop: "row bggray btlr-08 btrr-08",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: 'Pay ${"type":"text", "field": "yes", "data": "consultfee"} consultation fee',
      class_: "submitbtcolor submitcolor"
    },
    promo: {
      btcolor: "white"
    },
    contents: [
      {
        class_: "col-12 col-lg-2 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " w-md-img-75px pl-24px",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/pay.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-10 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Pay for your consultation"
          },
          {
            class_: " newgraycolor",
            text: "You can book a time for your consultation or join the queue at the next step."
          }
        ]
      },
      {
        class_: "col-2 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-10",
        type: "card",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new btlr-08 bggray btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px pl-24px",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Review your treatment plan"
          },
          {
            class_: " newgraycolor",
            text: "Our medical team will speak to you about your treatment and create a personalised treatment plan for your review."
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px pl-24px",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Get your medication delivered to your door"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Simply head back to your portal and pay everyday pharmacy prices for your medication. We will deliver your medication direct to your door via express post.",
            excludeproduct: "uti"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "We will ship your medication to you via our delivery partner Chemist2U.",
            product: "uti"
          }
        ]
      }
    ]
  },
  {
    name: "accept_new",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    contentsclass: "row",
    contentclasstop: "row bggray btlr-08 btrr-08",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: 'Pay ${"type":"text", "field": "yes", "data": "consultfee"} consultation fee',
      class_: "submitbtcolor submitcolor"
    },
    promo: {
      btcolor: "white"
    },
    contents: [
      {
        class_: "col-12 col-lg-2 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/pay.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-10 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: "Pay for your consultation"
          },
          {
            class_: " newgraycolor",
            text: "You can book a time for your consultation or join the queue at the next step."
          }
        ]
      },
      {
        class_: "col-2 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-10",
        type: "card",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Review your treatment plan"
          },
          {
            class_: " newgraycolor",
            text: "Our medical team will speak to you about your treatment and create a personalised treatment plan for your review."
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Get your medication delivered to your door"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Simply head back to your portal and pay everyday pharmacy prices for your medication. We will deliver your medication direct to your door via express post.",
            excludeproduct: "uti"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "We will ship your medication to you via our delivery partner Chemist2U.",
            product: "uti"
          }
        ]
      }
    ]
  },
  {
    name: "done_paymentonce_new",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    contentsclass: "row",
    contentclasstop: "row bggray btlr-08 btrr-08",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: "Place order"
    },
    promo: {
      btcolor: "white"
    },
    createaccount: {
      label: {
        class_: " newgraycolor"
      }
    },
    contents: [
      {
        class_: "col-12 col-lg-2 pt-3 v-center",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/pay.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-10 pt-3 v-center",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "price__"} for your medication',
            product: "thrush"
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "price__"} every month',
            product: "fertility"
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "price__"} for the Morning After Pill.',
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: "Pay $50.00 for the Morning After Pill",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "yes" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong newgraycolor my-3",
            type: "text",
            tag_: "h3",
            text: "$30 + $20 same day delivery",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "yes" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: 'Pay ${"type":"text", "field": "yes", "data": "totalprice__"} ({"type":"text", "field": "yes", "data": "price__"} + {"type":"text", "field": "yes", "data": "shipCost"} postage & handling) for Emergency Contraception.',
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "yes" }
            ]
          }
        ]
      },
      {
        class_: "col-2 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-10",
        type: "card",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Your assigned pharmacist will issue your medication"
          },
          {
            class_: " newgraycolor",
            text: "After payment, we will give you access to valuable information on how to best use your pack.",
            product: "fertility"
          },
          {
            class_: " newgraycolor",
            text: "You’ll receive a text & email when the pharmacist issues your medication. Your assigned pharmacist will reach out via the portal if they need further information.",
            excludeproduct: "fertility"
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Your medication is delivered direct to your door",
            product: "thrush"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Get your medication delivered to your door via Express Post.",
            product: "thrush"
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Your medication is delivered direct to your door",
            product: "fertility"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Get your medication delivered to your door via Express Post.",
            product: "fertility"
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Delivered same day",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "yes" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Same Day Shipping",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "We will ship your medication to you via our delivery partner Sherpa.",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "no" }
            ]
          },
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Same Day Shipping",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "yes" }
            ]
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "We will ship your medication to you via our delivery partner Chemist2U.",
            product: "morning after pill",
            extracond: [
              { name: "issameday", value: "no" },
              { name: "isChemist2u", value: "yes" }
            ]
          }
        ]
      }
    ]
  },
  {
    name: "done_payment_new",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    contentsclass: "row",
    contentclasstop: "row bggray btlr-08 btrr-08",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: 'Pay ${"type":"text", "field": "yes", "data": "consultfee"} consultation fee',
      class_: "submitbtcolor submitcolor"
    },
    promo: {
      btcolor: "white"
    },
    createaccount: {
      label: {
        class_: " newgraycolor"
      }
    },
    contents: [
      {
        class_: "col-12 col-lg-2 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/pay.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-10 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: "Pay for your consultation"
          },
          {
            class_: " newgraycolor",
            text: "You can book a time for your consultation or join the queue at the next step."
          }
        ]
      },
      {
        class_: "col-2 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-10",
        type: "card",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Review your treatment plan"
          },
          {
            class_: " newgraycolor",
            text: "Our medical team will speak to you about your treatment and create a personalised treatment plan for your review."
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-2 pt-3 pb-3 v-center-new bggray btlr-08 btrr-08 btrr-lg-0 bblr-lg-08",
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        class_:
          "col-12 col-lg-10 pt-3 pb-3 v-center-new bggray bblr-08 bbrr-08 btrr-lg-08",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Get your medication delivered to your door"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Simply head back to your portal and pay everyday pharmacy prices for your medication. We will deliver your medication direct to your door via express post.",
            excludeproduct: "uti"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "We will ship your medication to you via our delivery partner Chemist2U.",
            product: "uti"
          }
        ]
      }
    ]
  },
  {
    name: "generalTelehealth",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    contentsclass: "row",
    contentclasstop: "row bggray btlr-08 btrr-08",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: 'Pay ${"type":"text", "field": "yes", "data": "consultfee"} consultation fee',
      class_: "submitbtcolor submitcolor"
    },
    promo: {
      btcolor: "white"
    },
    createaccount: {
      label: {
        class_: " newgraycolor"
      }
    },
    contents: [
      {
        class_: "col-12 col-lg-2 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " w-md-img-75px w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/pay.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-10 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: "Pay for your consultation"
          },
          {
            class_: " newgraycolor",
            text: "You can book a time for your consultation or join the queue at the next step."
          }
        ]
      },
      {
        class_: "col-lg-12 pb-6",
        content: []
      }
    ]
  },
  {
    name: "fill_a_script_medication",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    type: "grid",
    nextButton: true,
    buttonText: {
      class_: "submitbtcolor submitcolor",
      text: "Next"
    },
    promo: {
      btcolor: "white"
    },
    createaccount: {
      label: {
        class_: " newgraycolor"
      }
    },
    contents: [
      {
        class_: "col-3 col-lg-2 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " fullwidth-img-sm-cs w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-8 pt-3 v-center",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Confirm your order and pay"
          },
          {
            class_: " newgraycolor",
            text: "Pay with our easy to use checkout."
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-3 col-lg-2 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " fullwidth-img-sm-cs w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-8 pt-3 v-center",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Receive your medication"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Get your treatments delivered to your door via express post."
          }
        ]
      }
    ]
  },
  {
    name: "fill_a_script_no_medication",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    type: "grid",
    nextButton: true,
    buttonText: {
      class_: "submitbtcolor submitcolor",
      text: "Next"
    },
    promo: {
      btcolor: "white"
    },
    createaccount: {
      label: {
        class_: " newgraycolor"
      }
    },
    contents: [
      {
        class_: "col-3 col-lg-2 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " fullwidth-img-sm-cs w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/review.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-8 pt-3 v-center",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Our pharmacist reviews your ePrescription & issues your medication"
          },
          {
            class_: " newgraycolor",
            text: "You will receive an SMS and email notifying you that your medication is ready for purchase via your patient portal."
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-3 col-lg-2 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " fullwidth-img-sm-cs w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/delivery.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-8 pt-3 v-center",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Receive your medication"
          },
          {
            class_: " newgraycolor",
            type: "text",
            text: "Get your treatments delivered to your door via express post."
          }
        ]
      }
    ]
  },
  {
    name: "medicalcertificates",
    title: "Thanks for completing your online assessment",
    subtitleclass_: "newSubtitle",
    subtitle: "What’s next?",
    type: "grid",
    nextButton: true,
    buttonText: {
      text: 'pay ${"type":"text", "field": "yes", "data": "consultfee"} consultation fee',
      class_: "submitbtcolor submitcolor"
    },
    promo: {
      btcolor: "white"
    },
    createaccount: {
      label: {
        class_: " newgraycolor"
      }
    },
    contents: [
      {
        class_: "col-3 col-lg-2 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " fullwidth-img-sm-cs w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/pay.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-8 pt-3 v-center",
        hideMembership: true,
        content: [
          {
            class_: " h3-sm strong my-3",
            type: "text",
            tag_: "h3",
            text: 'Pay the one-off ${"type":"text", "field": "yes", "data": "consultfee"} consultation fee'
          },
          {
            class_: " newgraycolor",
            text: "You'll receive a full refund on your consultation fee if we can't help you."
          }
        ]
      },
      {
        class_: "col-2 pt-3 v-center",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-10",
        type: "card",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-lg-12 pb-12 bgwhite",
        content: [
          {
            class_: "",
            text: " "
          }
        ]
      },
      {
        class_: "col-3 col-lg-2 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " fullwidth-img-sm-cs w-80p-img",
            type: "img",
            path: '/assets/images/{"type":"text", "field": "yes", "data": "from"}/medcert-colour.svg'
          }
        ]
      },
      {
        class_: "col-12 col-lg-8 pt-3 pb-3 v-center-new",
        content: [
          {
            class_: " h3-sm strong",
            type: "text",
            tag_: "h3",
            text: "Get your medical certificate"
          },
          {
            class_: " newgraycolor",
            text:
              "Once you have processed your payment, our medical team will give you a quick call to " +
              "review your request - you can book a time for this call at the next step. " +
              "Medical certificates for approved requests are typically emailed to you within 2 business hours (8am-8pm AEST)."
          }
        ]
      }
    ]
  }
];

export default end;
