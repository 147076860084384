import React from "react";

import {
  ProductSearchParams,
  Question,
  QuestionContentType
} from "@mh/questionnaire/src/types";

import { QCAddress } from "./QCAddress";
import { QCBoolean } from "./QCBoolean";
import { QCDate } from "./QCDate";
import { QCEmail } from "./QCEmail";
import { QCFloat } from "./QCFloat";
import { QCInfo } from "./QCInfo";
import { QCInteger } from "./QCInteger";
import { QCLongText } from "./QCLongText";
import { QCMedicare } from "./QCMedicare";
import { QCMobile } from "./QCMobile";
import { QCName } from "./QCName";
import { QCQuestionnairePayment } from "./QCQuestionnairePayment";
import { QCShortText } from "./QCShortText";
import { MedicationSearch } from "@mh/components";
import { QCSingleList, QCMultiList } from "./QCList";
import { QCDVA } from "./QCDVA";
import { QCSplash } from "./QCSplash/QCSplash";
import { QCMedicationSelection } from "./QCMedicationSelection/QCMedicationSelection";

export interface QuestionnaireContentComponentProps {
  question: Question;
  initialValue: any;
  onChange: (value: any, update?: boolean) => void;
}

/**
 * a (mostly) alphabetically sorted list mapping the type of the question
 * to the component that should be used to render it.
 */
export const ComponentContentTypes: Record<
  QuestionContentType,
  React.FC<QuestionnaireContentComponentProps>
> = {
  [QuestionContentType.ADDRESS]: (props) => <QCAddress {...props} />,
  [QuestionContentType.BOOLEAN]: (props) => <QCBoolean {...props} />,
  [QuestionContentType.DATE]: (props) => <QCDate {...props} />,
  [QuestionContentType.DVA]: (props) => <QCDVA {...props} />,
  [QuestionContentType.EMAIL]: (props) => <QCEmail {...props} />,
  [QuestionContentType.FLOAT]: (props) => <QCFloat {...props} />,
  [QuestionContentType.FULL_NAME]: (props) => <QCName {...props} />,
  [QuestionContentType.INFO]: (props) => <QCInfo {...props} />,
  [QuestionContentType.INTEGER]: (props) => <QCInteger {...props} />,
  [QuestionContentType.SINGLE_SELECTION_LIST]: (props) => (
    <QCSingleList {...props} />
  ),
  [QuestionContentType.MULTI_SELECTION_LIST]: (props) => (
    <QCMultiList {...props} />
  ),
  [QuestionContentType.LONG_TEXT]: (props) => <QCLongText {...props} />,
  [QuestionContentType.MEDICARE]: (props) => <QCMedicare {...props} />,
  [QuestionContentType.MOBILE]: (props) => <QCMobile {...props} />,
  [QuestionContentType.QUESTIONNAIRE_PAYMENT]: (props) => (
    <QCQuestionnairePayment {...props} />
  ),
  [QuestionContentType.PRODUCT_SEARCH]: (props) => (
    <MedicationSearch
      {...props}
      isScriptOnly={false}
      categoryId={
        (props!.question!.type_params as ProductSearchParams).category
      }
    />
  ),
  [QuestionContentType.SHORT_TEXT]: (props) => <QCShortText {...props} />,
  [QuestionContentType.SPLASH]: (props) => <QCSplash {...props} />,
  // A dead end is just an info without a next/back button, which gets handled
  // above the question content component
  [QuestionContentType.DEAD_END]: (props) => <QCInfo {...props} />,
  [QuestionContentType.MEDICATION_SELECTION]: (props) => (
    <QCMedicationSelection {...props} />
  )
};
