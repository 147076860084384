import { useEffect } from "react";
import { Alert } from "react-bootstrap";

interface CustomWindow extends Window {
  digitalId: any;
  digitalIdAsyncInit: () => any;
}

declare const window: CustomWindow;

interface DigitalIDProps {
  /** The DigitalID client ID. */
  clientId: string;
  /** String to be rendered on error. */
  error?: string;
  /**
   * Function called on completion of the verification process.
   * The `code` argument is returned from the DigitalID popup.
   */
  onComplete: (code?: string) => void;
  /** URL to the DigitalID SDK, dynamically loaded into a <script />. */
  sdk: string;
}

export const DigitalID = ({
  clientId,
  error,
  onComplete,
  sdk
}: DigitalIDProps) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = sdk;
    script.async = true;

    window.digitalIdAsyncInit = () =>
      window.digitalId.init({
        clientId,
        uxMode: "popup",
        onComplete: ({ code }: { code: string }) => onComplete(code)
      });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div
        id="digitalid-verify"
        css={{
          button: {
            ":not(:first-of-type)": {
              display: "none"
            }
          }
        }}
      />
      {error && (
        <Alert css={{ marginTop: "8px" }} variant="danger">
          {error}
        </Alert>
      )}
    </div>
  );
};
