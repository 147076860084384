import { ComponentPropsWithoutRef } from "react";

export const IconNolock = (props: ComponentPropsWithoutRef<"svg">) => (
  <svg
    viewBox="0 0 25 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.15175 10.0238H5.49103C3.80585 10.0238 2.44043 11.3559 2.44043 13V22.5238C2.44043 24.1679 3.80585 25.5 5.49103 25.5H17.6934C19.3786 25.5 20.744 24.1679 20.744 22.5238V21.3333C20.744 21.0047 20.4707 20.7381 20.1339 20.7381C19.7971 20.7381 19.5238 21.0047 19.5238 21.3333V22.5238C19.5238 23.5107 18.7037 24.3095 17.6934 24.3095H5.49103C4.48072 24.3095 3.66067 23.5107 3.66067 22.5238V13C3.66067 12.0143 4.48072 11.2143 5.49103 11.2143H9.15175C9.48856 11.2143 9.76187 10.9476 9.76187 10.619C9.76187 10.2904 9.48856 10.0238 9.15175 10.0238Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7441 17.1666V13C20.7441 11.3559 19.3786 10.0238 17.6935 10.0238H13.4226C13.0858 10.0238 12.8125 10.2904 12.8125 10.619C12.8125 10.9476 13.0858 11.2143 13.4226 11.2143H17.6935C18.7038 11.2143 19.5238 12.0143 19.5238 13V17.1666C19.5238 17.4952 19.7971 17.7619 20.1339 17.7619C20.4708 17.7619 20.7441 17.4952 20.7441 17.1666Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.88184 7.04759V10.619C4.88184 10.9476 5.15515 11.2143 5.49196 11.2143H9.15268C9.48949 11.2143 9.7628 10.9476 9.7628 10.619C9.7628 10.2904 9.48949 10.0238 9.15268 10.0238H6.10208V7.04759C6.10208 6.71899 5.82877 6.45235 5.49196 6.45235C5.15515 6.45235 4.88184 6.71899 4.88184 7.04759Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5045 11.2143H17.6936C18.0304 11.2143 18.3037 10.9476 18.3037 10.619V5.85711C18.3037 4.43691 17.7253 3.07377 16.6955 2.069C15.6656 1.06426 14.2684 0.5 12.8127 0.5H10.3722C8.91649 0.5 7.51927 1.06426 6.48939 2.069C6.14647 2.40355 5.85486 2.77612 5.61691 3.17858C5.44856 3.46308 5.54856 3.82739 5.84018 3.99163C6.13179 4.15587 6.50521 4.05831 6.67355 3.77381C6.85907 3.46076 7.08595 3.17019 7.35203 2.91071C8.15378 2.12973 9.23979 1.69047 10.3721 1.69047H12.8126C13.9449 1.69047 15.031 2.12973 15.8327 2.91071C16.6332 3.6929 17.0834 4.75243 17.0834 5.85714V10.0238H13.5045C13.1677 10.0238 12.8944 10.2905 12.8944 10.6191C12.8944 10.9477 13.1677 11.2143 13.5045 11.2143L13.5045 11.2143Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9824 14.7857V20.1429C10.9824 20.4714 11.2557 20.7381 11.5925 20.7381C11.9294 20.7381 12.2027 20.4714 12.2027 20.1429V14.7857C12.2027 14.4571 11.9294 14.1905 11.5925 14.1905C11.2557 14.1905 10.9824 14.4571 10.9824 14.7857Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.17846 2.70711L22.1428 24.1357C22.382 24.3679 22.7676 24.3679 23.0067 24.1357C23.2446 23.9036 23.2446 23.5262 23.0067 23.294L1.04234 1.8654C0.803155 1.63205 0.41753 1.63205 0.17846 1.8654C-0.0594867 2.09754 -0.0594867 2.47497 0.17846 2.70711Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
  </svg>
);
