import { Flex } from "@mh/components";

interface EmailAndNameQuestionProps {
  currentQuestion: any;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export const EmailAndNameQuestion = (props: EmailAndNameQuestionProps) => {
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <div className="d-flex flex-sm-row flex-column">
          <Flex
            flexDirection="column"
            width="100%"
            css={(theme) => ({
              [theme.mq.sm]: {
                paddingRight: "16px"
              }
            })}
          >
            <label
              className="asterisk"
              css={{
                paddingBottom: "4px",
                fontSize: "14px",
                fontWeight: "500"
              }}
              htmlFor="first_name"
            >
              First name
            </label>
            <input
              key="first_name"
              name="first_name"
              autoComplete="off"
              id="first_name"
              placeholder="First Name"
              defaultValue={props?.firstName}
              css={(theme) => ({
                borderRadius: "12px",
                lineHeight: "135% !important",
                padding: "8px",
                fontSize: "14px",
                border:
                  "1px solid var(--components-divider-divider-background, #737373)",
                marginBottom: "16px",
                [theme.mq.sm]: {
                  marginBottom: "24px"
                },
                [theme.mq.md]: {
                  borderWidth: "0.5px"
                }
              })}
              autoFocus={true}
            />
          </Flex>
          <Flex flexDirection="column" width="100%">
            <label
              className="asterisk"
              css={{
                paddingBottom: "4px",
                fontSize: "14px",
                fontWeight: "500"
              }}
              htmlFor="last_name"
            >
              Last name
            </label>
            <input
              key="last_name"
              name="last_name"
              placeholder="Last Name"
              autoComplete="off"
              id="last_name"
              defaultValue={props?.lastName}
              css={(theme) => ({
                borderRadius: "12px",
                lineHeight: "135% !important",
                padding: "8px",
                fontSize: "14px",
                border:
                  "1px solid var(--components-divider-divider-background, #737373)",
                marginBottom: "16px",
                [theme.mq.sm]: {
                  marginBottom: "24px"
                },
                [theme.mq.md]: {
                  borderWidth: "0.5px"
                }
              })}
            />
          </Flex>
        </div>
      </Flex>
      <Flex
        flexDirection="column"
        css={(theme) => ({
          paddingBottom: "4px",
          [theme.mq.sm]: {
            paddingBottom: "0px"
          }
        })}
      >
        <label
          className="asterisk"
          css={{ paddingBottom: "4px", fontSize: "14px", fontWeight: "500" }}
          htmlFor="systolic"
        >
          Email
        </label>
        <input
          key="userEmail"
          name="user_email"
          id="useremail"
          type="email"
          required
          placeholder="email"
          defaultValue={props?.email}
          css={(theme) => ({
            borderRadius: "12px",
            lineHeight: "135% !important",
            padding: "8px",
            fontSize: "14px",
            border:
              "1px solid var(--components-divider-divider-background, #737373)",
            [theme.mq.sm]: {
              marginBottom: "12px"
            },
            [theme.mq.md]: {
              borderWidth: "0.5px"
            }
          })}
        />
      </Flex>
    </Flex>
  );
};
